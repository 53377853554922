import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import {Link as RouteLink} from 'react-router-dom';

import HomeIcon from '@material-ui/icons/Home';

import {useTranslation} from 'react-i18next';






function Breadcrumb(props) {
  const {t} = useTranslation('teacher');

    
        let data = [];

        if(props.data !== undefined) {
          data = props.data;
        }
        return (
          <div>
            <Breadcrumbs aria-label="breadcrumb">

            <Link color="inherit" href="/" className={"flex items-center"} style={{color:"#fff"}}>
              <RouteLink to={"/"} style={{color:"#fff"}}>
                  <div className="flex items-center">
                    <HomeIcon style={{color:"#fff"}} style={{width:20}} className={"breadcrumb-icon"} />
                    <div style={{color:"#fff"}}>{t("Home")}</div>
                  </div>
              
              </RouteLink>
              
            </Link>

              {data.map(function(item) {
                if(item.link !== "") {
                  return(<Link color="inherit" href={item.link}  className={"flex items-center"}>
                                  <RouteLink style={{color:"#fff"}} to={item.link}>

                  {item.title} </RouteLink>
                </Link>);
                } else {
                  return(<Typography color="textPrimary" className={"flex items-center"} style={{color:"#fff"}}>
                  <span>{item.title}</span>
                </Typography>);
                }
              })}
           
        
          </Breadcrumbs>
          </div>)
    
}
export default Breadcrumb;