import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import Formsy from "formsy-react";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";
import clsx from 'clsx';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';

import Backdrop from "@material-ui/core/Backdrop";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SaveIcon from "@material-ui/icons/Save";
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "Games",
    link: `${process.env.REACT_APP_FRONT_URL}games/list`,
  },
  {
    title: "Levels",
    link: `${process.env.REACT_APP_FRONT_URL}games/levelDiffs`,
  },
  {
    title: "Add",
    link: "",
  },
];
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  divDiff: {

  }
}));
class Add extends Component {
  state = {
    formValid: false,
    disabled: false,
    originalImage: '',
    differentImage: '',
    flagbooimage: false,
    progressshow: false,
    positionRight: '10',
    positionTop: '10',
    ideffl: 1,
    differences: []



  };
  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
  };


  handleSubmit = (model) => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    let differencesAll2 = [];
    let differencesAll = [];
    // console.log(document.getElementById(event.target.id).getBoundingClientRect())
    // console.log(mousePosition)

    // console.log(event.target.id)
    // this.setState({
    //   differences: this.state.differences.concat(
    //     {
    //       id: "diff_" + event.target.id,
    //       w: '50px',
    //       h: '50px',
    //       x: (mousePosition.x + offset[0]) + 'px',
    //       y: (mousePosition.y + offset[1]) + 'px'

    //     })

    // })

    this.state.differences.map((item) => {
      differencesAll2.push({
        DOMRect: {
          left: document.getElementById(item.id).getBoundingClientRect().left - 336,
          top: document.getElementById(item.id).getBoundingClientRect().top - 336,
          width: document.getElementById(item.id).getBoundingClientRect().width,
          height: document.getElementById(item.id).getBoundingClientRect().height,
        }
      });
      // let top = document.getElementById(item.id).getBoundingClientRect().top < 0 ? 0 : document.getElementById(item.id).getBoundingClientRect().top - 13;

      // document.getElementById(item.id).getBoundingClientRect().top = top;
      differencesAll.push(document.getElementById(item.id).getBoundingClientRect());
    })
    console.log(differencesAll2)
    console.log(differencesAll)


    let go = true;
    if (this.state.originalImage == '' || this.state.differentImage == '') {
      go = false;
    }

    if (go) {
      this.setState({
        progressshow: true,
        flagbooimage: false,
      })
      formData.append("titleEn", this.state.titleEn);
      formData.append("titleAr", this.state.titleAr);
      formData.append("originalImage", this.state.originalImage);
      formData.append("differentImage", this.state.differentImage);
      formData.append("differences", JSON.stringify(differencesAll));
      formData.append("differences2", JSON.stringify(differencesAll2));

      axios({
        url: `${backend_url}/games/levelDiff/add`,
        data: formData,
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        if (res.data.message) {
          this.setState({
            progressshow: false,
          })
          this.props.history.push("/games/levelDiffs");
        }

      });
    }
    else {
      this.setState({
        flagbooimage: true,
      })
    }

  };


  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };

  fileUpload2 = (e, id) => {
    let image = document.getElementById(id);
    image.src = URL.createObjectURL(e.target.files[0]);


    this.setState({
      [id]: e.target.files[0]
    })

    // console.log(image)
  };


  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="px-24">
            <h2>Add Level</h2>
          </div>
        }
        content={
          <div className="p-24">
            <div className="max-w-lg">
              <Formsy
                onValidSubmit={this.handleSubmit}
                onValid={this.enableButton}
                onInvalid={this.disableButton}
                ref={"cateogry_add"}
                className="flex flex-col p6-12"
              >

                <div className="flex flex-row justify-between py-20">
                  <TextFieldFormsy
                    className="w-5/12"
                    type="text"
                    name="titleEn"
                    label="Title EN"
                    onChange={(e) => {
                      this.handleChange(e, "titleEn");
                    }}
                    required
                    variant="outlined"
                  />
                  <TextFieldFormsy
                    className="w-5/12"
                    type="text"
                    name="titleAr"
                    label="Title AR"
                    required
                    variant="outlined"
                    onChange={(e) => {
                      this.handleChange(e, "titleAr");
                    }}
                  />
                </div>
                {this.state.flagbooimage && (
                  <Alert variant="filled" severity="error">
                    2 images are required
                  </Alert>
                )}


                <div>
                  <Typography variant="button" display="block" gutterBottom>
                    Original picture
                  </Typography>
                  <div className="flex flex-row">
                    <img className="max-w-none w-auto " id="originalImage" src="frontend/assets/images/custom/empty-image-thumb.png" />

                  </div>

                  <div className="flex flex-row pt-20">
                    <div
                      role="button"
                      tabIndex={0}
                      className={clsx(
                        classes.productImageItem,
                        'flex row items-center justify-center relative w-128 h-28 rounded-4 mx-8 mb-16 overflow-hidden cursor-pointer shadow-1  hover:shadow-5',
                        'featured'
                      )}
                    >
                      <input accept="image/*" style={{ display: 'none' }}

                        onChange={(e) => {
                          this.fileUpload2(e, "originalImage");
                        }}
                        id="originalImage1" type="file" />
                      <label htmlFor="originalImage1">
                        <IconButton color="primary" aria-label="upload picture" component="span">
                          <PhotoCamera />
                          <br></br>
                          <h6>Select Image</h6>
                        </IconButton>
                      </label>
                    </div>

                  </div>
                </div>
                <hr className="m-24"
                  style={{
                    border: 0,
                    height: '1px',
                    backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0))'
                  }}
                ></hr>
                <div>
                  <Typography variant="button" display="block" gutterBottom>
                    Different picture &nbsp;	&nbsp;
                    <Button variant="contained" size="small" color="primary" className="mb-12"

                      onClick={(e) => {
                        var mousePosition;
                        var offset = [0, 0];
                        var div;
                        var isDown = false;
                        var levelsize = 1;
                        div = document.createElement("div");
                        div.id = "diff_" + this.state.ideffl;
                        div.style.position = "absolute";
                        div.style.left = "0px";
                        div.style.top = "0px";
                        div.style.width = "50px";
                        div.style.height = "50px";
                        div.style.border = "red 4px solid";
                        div.style.borderRadius = "10px";
                        div.style.color = "blue";
                        div.style.cursor = "pointer"
                        document.getElementById('imagediff').appendChild(div);

                        this.setState({
                          differences: this.state.differences.concat(
                            {
                              id: "diff_" + this.state.ideffl,
                              w: '50px',
                              h: '50px',
                              x: '0',
                              y: '0'

                            }),
                          ideffl: (this.state.ideffl + 1)

                        })
                        div.addEventListener('mousedown', function (e) {
                          isDown = true;
                          offset = [
                            div.offsetLeft - e.clientX,
                            div.offsetTop - e.clientY
                          ];
                        }, true);

                        div.addEventListener('dblclick', function (e) {
                          isDown = true;

                          if (levelsize == 1) {
                            div.style.width = "100px";
                            div.style.height = "100px";
                            levelsize = 2
                          }
                          if (levelsize == 2) {
                            div.style.width = "150px";
                            div.style.height = "150px";
                            levelsize = 3
                          }
                          else if (levelsize == 3) {
                            div.style.width = "200px";
                            div.style.height = "200px";
                            levelsize = 4
                          }
                          else if (levelsize == 4) {
                            div.style.width = "200px";
                            div.style.height = "100px";
                            levelsize = 5
                          }
                          else if (levelsize == 5) {
                            div.style.width = "50px";
                            div.style.height = "50px";
                            levelsize = 1
                          }

                        }, true);

                        document.addEventListener('mouseup', function () {
                          isDown = false;
                        }, true);

                        document.addEventListener('mousemove', function (event) {
                          event.preventDefault();
                          if (isDown) {
                            mousePosition = {

                              x: event.clientX,
                              y: event.clientY

                            };
                            div.style.left = (mousePosition.x + offset[0]) + 'px';
                            div.style.top = (mousePosition.y + offset[1]) + 'px';



                          }
                        }, true);
                      }}
                    >
                      Add difference
                    </Button>
                  </Typography>
                  <div
                    style={{
                      position: "relative"
                    }}
                    id="imagediff"
                  >
                    <img className="max-w-none w-auto " id="differentImage" src="frontend/assets/images/custom/empty-image-thumb.png"
                      style={{
                        cursor: 'pointer',
                        width: '800px',
                        height: '800px'
                      }}
                      onClick={(e) => {

                      }}
                    />


                  </div>

                  <div className="flex flex-row pt-20">
                    <div
                      role="button"
                      tabIndex={0}
                      className={clsx(
                        classes.productImageItem,
                        'flex row items-center justify-center relative w-128 h-28 rounded-4 mx-8 mb-16 overflow-hidden cursor-pointer shadow-1  hover:shadow-5',
                        'featured'
                      )}
                    >
                      <input accept="image/*" style={{ display: 'none' }}

                        onChange={(e) => {
                          this.fileUpload2(e, "differentImage");
                        }}
                        id="differentImage1" type="file" />
                      <label htmlFor="differentImage1">
                        <IconButton color="primary" aria-label="upload picture" component="span">
                          <PhotoCamera />
                          <br></br>
                          <h6>Select Image</h6>
                        </IconButton>
                      </label>
                    </div>

                  </div>
                </div>


                <Backdrop
                  className={classes.backdrop}
                  open={this.state.progressshow}
                  style={{
                    zIndex: 9999,
                    color: "#fff",
                  }}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  className="ml-auto my-16"
                  disabled={!this.state.formValid}
                  type="submit"
                  variant="contained"
                >
                  Add
                </Button>
              </Formsy>
            </div>
          </ div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(Add);
