import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";

import TextField from "@material-ui/core/TextField";
import Formsy from "formsy-react";
import Breadcrumb from "./../components/Breadcrumb";
import axios from "axios";

import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from "@material-ui/icons/Save";

import { RadioGroupFormsy } from "@fuse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from '@material-ui/core/Switch';

import Icon from '@material-ui/core/Icon';

import clsx from 'clsx';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Alert from '@material-ui/lab/Alert';

const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "Shows",
    link: `${process.env.REACT_APP_FRONT_URL}shows/list`,
  },
  {
    title: "Add",
    link: "",
  },
];

class Add extends Component {
  state = {
    formValid: false,
    titleEn: "",
    titleAr: "",
    coverImg: "",
    categoryId: "none",
    categoryData: [],
    singerId: "none",
    singerData: [],
    authorsId: "none",
    authorsData: [],
    composersId: "none",
    composersData: [],
    genresId: "none",
    genreData: [],
    agerateId: "none",
    agerateData: [],
    tagsId: [],
    tagsData: [],
    loadingProgress: false,
    subtitleEn: "",
    subtitleAr: "",
    descriptionen: "",
    descriptionar: "",
    isFree: false,
    isPaid: false,
    ErrorMsg1: '',
    showErrorMsg1: false,
    banner: ''
  };
  handleChange = (e, name) => {
    if (name == 'isFree' || name == 'isPaid')
      this.setState({
        [name]: e.target.checked,
      });
    else {
      this.setState({
        [name]: e.target.value,
      });
    }
  };
  handleChangetags = (val, name) => {
    let ids = val.map((item) => {
      return item._id
    })
    this.setState({
      [name]: ids,
    });

  };
  handleSubmit = (model) => {
    if (this.state.coverImg == "") {
      this.setState({
        ErrorMsg1: 'You did not choose an image!',
        showErrorMsg1: true
      })
    }
    else {
      this.setState({
        ErrorMsg1: '',
        showErrorMsg1: false
      });
    }


    if (!this.state.showErrorMsg1) {

      let backend_url = process.env.REACT_APP_BACKEND_URL;
      let formData = new FormData();

      formData.append("titleEn", this.state.titleEn);
      formData.append("titleAr", this.state.titleAr);
      formData.append("categoryId", this.state.categoryId);
      formData.append("singerId", this.state.singerId);
      formData.append("authorsId", this.state.authorsId);
      formData.append("composersId", this.state.composersId);
      formData.append("genresId", this.state.genresId);
      formData.append("agerateId", this.state.agerateId);
      formData.append("tagsId", this.state.tagsId);
      formData.append("coverImg", this.state.coverImg);
      formData.append("subtitleEn", this.state.subtitleEn);
      formData.append("subtitleAr", this.state.subtitleAr);
      formData.append("descriptionen", this.state.descriptionen);
      formData.append("descriptionar", this.state.descriptionar);
      formData.append("banner", this.state.banner);
      formData.append("isFree", this.state.isFree);
      formData.append("isPaid", this.state.isPaid);
      formData.append("Status", "Published");

      axios({
        url: `${backend_url}/shows/add`,
        data: formData,
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        this.props.history.push("/frontend/shows/list");
      });

    }


  };
  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };

  fileUpload = (e, id) => {
    this.setState({
      ErrorMsg1: '',
      showErrorMsg1: false
    });
    let image = document.getElementById(id);
    image.src = URL.createObjectURL(e.target.files[0]);


    if (id == 'banner-image')
      this.setState({
        banner: e.target.files[0],
      });

    else
      this.setState({
        coverImg: e.target.files[0],
      });


  };

  //////
  fileUpload2 = (e) => {
    // console.log(e.target.files[0])
    if ((e.target.files[0].type).toString().substring(0, 5) == 'video') {
      this.setState({
        fileSrc: e.target.files[0],
        displayDatFile: "block",
        ErrorMsg2: '',
        showErrorMsg2: false,
        typeInput: 'Video'
      });
    }
    else if ((e.target.files[0].type).toString().substring(0, 5) == 'audio') {
      this.setState({
        fileSrc: e.target.files[0],
        displayDatFile: "block",
        ErrorMsg2: '',
        showErrorMsg2: false,
        typeInput: 'Audio'
      });
    }
    else {
      this.setState({
        ErrorMsg2: 'The file type is wrong',
        showErrorMsg2: true,
        fileSrc: '',
        displayDatFile: "none",
      })
    }


  };
  uploadfiles3 = () => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();

    formData.append("fileSrc", this.state.fileSrc);
    formData.append("typeInput", this.state.typeInput);
    formData.append("titleEn", this.state.titleEn);

    this.setState({
      loadingProgress: true,
    });
    axios({
      url: `${backend_url}/uploadfiletos3`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      // console.log(res.data.result);
      this.setState({
        s3Information: res.data.result,
        loadingProgress: false,
        fileSrc: res.data.namefile,
        successMsg: res.data.message,
        displayBtnFile: "none",
      });
      if (res.data.message == 'Uploaded successfully') {
        this.handleSubmit();
      }
    });
  };
  componentDidMount() {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    axios({
      url: `${backend_url}/category/list`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        categoryData: res.data.result,
      });
    });
    //////////
    axios({
      url: `${backend_url}/singer/list`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        singerData: res.data.result,
      });
    });
    //////////
    axios({
      url: `${backend_url}/authors/list`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        authorsData: res.data.result,
      });
    });
    //////////
    axios({
      url: `${backend_url}/Composers/list`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        composersData: res.data.result,
      });
    });
    //////////
    axios({
      url: `${backend_url}/genre/list`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        genreData: res.data.result,
      });
    });
    //////////
    axios({
      url: `${backend_url}/agerate/list`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        agerateData: res.data.result,
      });
    });
    axios({
      url: `${backend_url}/tags/list`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        tagsData: res.data.result,
      });
    });
    //////////////end
  }

  ////
  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="px-24">
            <h2>Add Show</h2>
          </div>
        }
        content={
          <div className="p-24">
            <div className="max-w-lg">
              <Formsy
                onValidSubmit={this.handleSubmit}
                onValid={this.enableButton}
                onInvalid={this.disableButton}
                ref={"song_add"}
                className="flex flex-col p6-12"
              >
                <div className="flex flex-row justify-between py-20">
                  <TextFieldFormsy
                    className="w-5/12"
                    label="Title En"
                    autoFocus
                    id="titleEn"
                    name="titleEn"
                    variant="outlined"
                    value={this.state.titleEn}
                    onChange={(e) => {
                      this.handleChange(e, "titleEn");
                    }}
                    required
                  />
                  <TextFieldFormsy
                    className="w-5/12"
                    label="Title Ar"
                    id="titleAr"
                    name="titleAr"
                    variant="outlined"
                    value={this.state.titleAr}
                    onChange={(e) => {
                      this.handleChange(e, "titleAr");
                    }}
                    required
                  />
                </div>

                <div className="flex flex-row justify-between py-10">
                  <TextFieldFormsy
                    className="w-5/12"
                    label="Subtitle En"
                    id="subtitleEn"
                    name="subtitleEn"
                    variant="outlined"
                    value={this.state.subtitleEn}
                    onChange={(e) => {
                      this.handleChange(e, "subtitleEn");
                    }}

                  />

                  <TextFieldFormsy
                    className="w-5/12"
                    label="Subtitle Ar"
                    id="subtitleAr"
                    name="subtitleAr"
                    variant="outlined"
                    value={this.state.subtitleAr}
                    onChange={(e) => {
                      this.handleChange(e, "subtitleAr");
                    }}

                  />
                </div>


                <div className="flex flex-row justify-between py-10">
                  <TextFieldFormsy
                    id="outlined-multiline-static"
                    label="Description En"
                    multiline
                    rows={4}
                    value={this.state.descriptionen}
                    variant="outlined"
                    className="w-5/12"
                    id="descriptionen"
                    name="descriptionen"
                    onChange={(e) => {
                      this.handleChange(e, "descriptionen");
                    }}
                    required
                  />
                  <TextFieldFormsy
                    id="outlined-multiline-static"
                    label="Description Ar"
                    multiline
                    rows={4}
                    value={this.state.descriptionar}
                    variant="outlined"
                    className="w-5/12"
                    id="descriptionar"
                    name="descriptionar"
                    onChange={(e) => {
                      this.handleChange(e, "descriptionar");
                    }}
                    required
                  />
                </div>

                <div className="flex flex-row justify-between py-10">
                  <SelectFormsy
                    className="w-5/12"
                    name="artist"
                    label="Artist"
                    value={this.state.authorsId}
                    onChange={(e) => {
                      this.handleChange(e, "authorsId");
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                    {this.state.authorsData.map((item) => {
                      return <MenuItem value={item._id}>{item.nameEn}</MenuItem>;
                    })}
                  </SelectFormsy>
                  <SelectFormsy
                    className="w-5/12"
                    name="singer"
                    label="Singer"
                    value={this.state.singerId}
                    onChange={(e) => {
                      this.handleChange(e, "singerId");
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                    {this.state.singerData.map((item) => {
                      return <MenuItem value={item._id}>{item.nameEn}</MenuItem>;
                    })}
                  </SelectFormsy>
                </div>


                <div className="flex flex-row justify-between py-10">
                  <SelectFormsy
                    className="w-5/12"
                    name="composer"
                    label="Composer"
                    value={this.state.composersId}
                    onChange={(e) => {
                      this.handleChange(e, "composersId");
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                    {this.state.composersData.map((item) => {
                      return <MenuItem value={item._id}>{item.nameEn}</MenuItem>;
                    })}
                  </SelectFormsy>

                  <Autocomplete
                    className="w-5/12"
                    multiple
                    id="tags-outlined"
                    options={this.state.genreData}
                    filterSelectedOptions
                    getOptionLabel={(option) => option.titleEn}
                    onChange={(event, value) =>
                      this.handleChangetags(value, "genresId")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Genre"
                        placeholder="Genre"

                      />
                    )}
                  />
                </div>

                <div className="flex flex-row justify-between py-10">
                  <SelectFormsy
                    className="w-5/12"
                    name="category"
                    label="Category"
                    value={this.state.categoryId}
                    onChange={(e) => {
                      this.handleChange(e, "categoryId");
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="none">None</MenuItem>
                    {this.state.categoryData.map((item) => {
                      return <MenuItem value={item._id}>{item.titleEn}</MenuItem>;
                    })}
                  </SelectFormsy>
                  <SelectFormsy
                    className="w-5/12"
                    name="ageRate"
                    label="Age Rate"
                    value={this.state.agerateId}
                    onChange={(e) => {
                      this.handleChange(e, "agerateId");
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                    {this.state.agerateData.map((item) => {
                      return <MenuItem value={item._id}>{item.titleEn}</MenuItem>;
                    })}
                  </SelectFormsy>
                </div>



                <div className="flex flex-row justify-between ">
                  <div className="w-full"
                    style={{
                      marginBottom: '2vw'
                    }}>
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={this.state.tagsData}
                      filterSelectedOptions
                      getOptionLabel={(option) => option.titleEn}
                      onChange={(event, value) =>

                        this.handleChangetags(value, "tagsId")

                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Tags"
                          placeholder="Tags"

                        />
                      )}
                    />
                  </div>
                </div>

                <div className="flex flex-row center py-20">
                  <FormControlLabel
                    control={<Switch checked={this.state.isFree}

                      color="primary"
                      onChange={(e) => {
                        this.handleChange(e, "isFree");
                      }}
                      name="freev" />}
                    label="Is Free"
                    labelPlacement="top"

                  />

                  <FormControlLabel
                    control={<Switch checked={this.state.isPaid}

                      color="primary"
                      onChange={(e) => {
                        this.handleChange(e, "isPaid");
                      }}
                      name="freev" />}
                    label="Exclusive"
                    labelPlacement="top"

                  />
                </div>

                <div className="flex flex-row justify-between py-20 mb-20"
                  style={{
                    backgroundColor: "#f8f8f8",
                    padding: '2vw',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    borderRadius: '5px',
                    boxShadow: '0 0 5px #e7e7e7'

                  }}
                >
                  <b>Top banner</b>
                  <div className="flex justify-between items-center w-8/12">
                    <div className="flex justify-between items-center w-8/12">
                      <Button variant="contained" component="label">
                        Choose image
                      <input
                          type="file"
                          onChange={(e) => {
                            this.fileUpload(e, "banner-image");
                          }}
                          style={{ display: "none" }}
                        />
                      </Button>

                    </div>
                    <div
                      role="button"
                      className={clsx(
                        classes.productImageItem,
                        'flex items-center justify-center relative w-128 h-128 rounded-4 mx-8 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5',

                      )}
                    >
                      <img id="banner-image" className="max-w-none w-auto h-full" src="frontend/assets/images/custom/empty-image-thumb.png" />
                    </div>


                  </div>

                  <div style={{ color: 'red' }}>*Acceptable image dimensions, width: 1920 and height:300 </div>
                </div>


                <div className="flex flex-row justify-between py-20"
                  style={{
                    backgroundColor: "#f8f8f8",
                    padding: '2vw',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    borderRadius: '5px',
                    boxShadow: '0 0 5px #e7e7e7'

                  }}
                >
                  <b>Thumbnails</b>
                  <div className="flex justify-between items-center w-8/12">
                    <div className="flex justify-between items-center w-8/12">
                      <Button variant="contained" component="label">
                        Choose image
                      <input
                          type="file"
                          onChange={(e) => {
                            this.fileUpload(e, "cover-image");
                          }}
                          style={{ display: "none" }}
                        />
                      </Button>

                    </div>
                    <div
                      role="button"
                      className={clsx(
                        classes.productImageItem,
                        'flex items-center justify-center relative w-128 h-128 rounded-4 mx-8 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5',

                      )}
                    >
                      <img id="cover-image" className="max-w-none w-auto h-full" src="frontend/assets/images/custom/empty-image-thumb.png" />
                    </div>


                  </div>

                  <div style={{ color: 'red' }}>*Acceptable image dimensions, width: 550 and height:330</div>
                </div>

                {this.state.showErrorMsg1 && (
                  <Alert severity="error">{this.state.ErrorMsg1}</Alert>
                )}
                <hr style={{
                  border: 0,
                  backgroundColor: '#fff',
                  borderTop: '2px dotted #8c8c8c'
                }}></hr>





                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  className="ml-auto my-16"
                  disabled={!this.state.formValid}
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
              </Formsy>
            </div>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(Add);
