
import List from "./List";

export const logsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [

    {
      path: `${process.env.REACT_APP_FRONT_URL}logs/list`,
      component: List,
    },
  ],
};
