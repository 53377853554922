import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from "@material-ui/core/Paper";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";
import Popup from "../components/popup";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Audiotrack';
import FavoriteIcon from '@material-ui/icons/ViewColumn';
import PersonPinIcon from '@material-ui/icons/Slideshow';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/Delete';
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import { Alert, AlertTitle } from '@material-ui/lab';
const styles = (theme) => ({
  layoutRoot: {},
  margintopandb: {
    marginBottom: 20,
    marginTop: 20
  }
});
const useStyles = makeStyles({
  root: {
    marginBottom: 20,
    marginTop: 20
  },
});
const breadCurmbData = [
  {
    title: "App settings",
    link: "",
  },
];
class view extends Component {
  state = {
    open: false,
    formValid: false,
    rowsPerPage: 5,
    page: 0,
    data: [],
    diplaymgs: 'none',
    widthmsg: '10vw',
    categoryData: [],
    setCategoryData: [],
    showRows: false,
    opacityVal: 0,
    Interests: true,
    latestReleases: false,
    editorChoice: false,
    songsData: [],
    songsData2: [],
    setSongsData: [],
    setSongsData2: [],
    showsData: [],
    showsData2: [],
    setShowsData: [],
    setShowsData2: [],
    storesData: [],
    storesData2: [],
    setStoresData: [],
    setStoresData2: [],
    activeTab: 0,
    activeTab2: 0,
    openpopupDelete: false,
    itemDelete: '',
    arraynamedelete: '',
    arraynamedelete2: '',
    arrayDataDelete: [],
    categoriesList: false,
    setSongsDatadb: [],
    setSongsDataEditordb: [],
    setShowsDatadb: [],
    setShowsDataEditordb: [],
    setStoresDatadb: [],
    setStoresDataEditordb: [],

  };
  handleChange = (e, name) => {
    if (name == 'showRows') {
      if (e.target.checked)
        this.setState({
          [name]: e.target.checked,
          opacityVal: 1
        });
      else
        this.setState({
          [name]: e.target.checked,
          opacityVal: 0
        });
    }
    else {
      this.setState({
        [name]: e.target.checked,
      });
    }
  };

  changeTab = (val) => {

    this.setState({
      activeTab: val
    })
  }

  choiseCategory = (index, valActive) => {
    this.state.setCategoryData[index].activeCategory = valActive;
    this.forceUpdate()

  }
  backAddItem = () => {
    let sta = this.state;
    ///add form 
    let name = this.state.arraynamedelete;
    let name2 = this.state.arraynamedelete2;
    let obj = this.state.arrayDataDelete;

    this.setState({
      [name2]: sta[name2].concat([obj])
    })

    this.setState({
      [name]: sta[name].filter(item => item._id != obj._id),
      openpopupDelete: false,
    })
  }

  addtoarry = (value, name, name2) => {
    // console.log(value._id)
    let sta = this.state;
    ///delete form old
    this.setState({
      [name2]: sta[name2].filter(item => item._id != value._id)
    })
    ///add form old
    this.setState({
      [name]: sta[name].concat([value])
    })
  }


  prettyDate(date) {
    var d = new Date(date);
    return d.toDateString() + " ";
  }

  submitFunction = (e) => {

    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();

    let songsId = this.state.setSongsData.map((item) => {
      return item._id
    })
    let songsId2 = this.state.setSongsData2.map((item) => {
      return item._id
    })
    let showsId = this.state.setShowsData.map((item) => {
      return item._id
    })
    let showsId2 = this.state.setShowsData2.map((item) => {
      return item._id
    })
    let storesId = this.state.setStoresData.map((item) => {
      return item._id
    })
    let storesId2 = this.state.setStoresData2.map((item) => {
      return item._id
    })

    formData.append("showRows", this.state.showRows);
    formData.append("latestReleases", this.state.latestReleases);
    formData.append("interests", this.state.Interests);
    formData.append("editorChoice", this.state.editorChoice);
    formData.append("categoriesList", this.state.categoriesList);

    formData.append("setSongsData", JSON.stringify(songsId));
    formData.append("setSongsDataEditor", JSON.stringify(songsId2));
    formData.append("setShowsData", JSON.stringify(showsId));
    formData.append("setShowsDataEditor", JSON.stringify(showsId2));
    formData.append("setStoresData", JSON.stringify(storesId));
    formData.append("setStoresDataEditor", JSON.stringify(storesId2));
    formData.append("setCategoryData", JSON.stringify(this.state.setCategoryData));

    axios({
      url: `${backend_url}/mainScreen/add`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.setState({
        diplaymgs: 'block',
        widthmsg: '50vw'
      })
    });

  };

  componentDidMount() {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    axios({
      url: `${backend_url}/songs/list`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        songsData: res.data.result,
        songsData2: res.data.result,
      });
    });
    //////////
    axios({
      url: `${backend_url}/shows/list`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        showsData: res.data.result,
        showsData2: res.data.result,
      });
    });
    //////////
    //////////
    axios({
      url: `${backend_url}/stories/list`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        storesData: res.data.result,
        storesData2: res.data.result,
      });
    });
    //////////
    //////////
    axios({
      url: `${backend_url}/category/list`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        categoryData: res.data.result,
      });
      res.data.result.map((option) => {
        this.setState({
          setCategoryData: this.state.setCategoryData.concat([{ idCategory: option._id, activeCategory: false }])

        });
      })

    });
    //////////
    //////////
    axios({
      url: `${backend_url}/mainScreen/view`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      // / opacityVal: 1 
      this.state.songsData.map((item) => {
        JSON.parse(res.data.result[0].setSongsData).map((id) => {
          if (id === item._id) {
            this.setState({
              setSongsData: this.state.setSongsData.concat([item])
            })
          }
        })
        return item;
      })
      /////
      this.state.songsData2.map((item) => {
        JSON.parse(res.data.result[0].setSongsDataEditor).map((id) => {
          if (id === item._id) {
            this.setState({
              setSongsData2: this.state.setSongsData2.concat([item])
            })
          }
        })
        return item;
      })
      /////
      this.state.showsData.map((item) => {
        JSON.parse(res.data.result[0].setShowsData).map((id) => {
          if (id === item._id) {
            this.setState({
              setShowsData: this.state.setShowsData.concat([item])
            })
          }
        })
        return item
      })
      /////
      this.state.showsData2.map((item) => {
        JSON.parse(res.data.result[0].setShowsDataEditor).map((id) => {
          if (id === item._id) {
            this.setState({
              setShowsData2: this.state.setShowsData2.concat([item])
            })
          }
        })
        return item
      })
      /////
      this.state.storesData.map((item) => {
        JSON.parse(res.data.result[0].setStoresData).map((id) => {
          if (id === item._id) {
            this.setState({
              setStoresData: this.state.setStoresData.concat([item])
            })
          }
        })
        return item
      })
      /////
      this.state.storesData.map((item) => {
        JSON.parse(res.data.result[0].setStoresDataEditor).map((id) => {
          if (id === item._id) {
            this.setState({
              setStoresData2: this.state.setStoresData2.concat([item])
            })
          }
        })
        return item
      })
      if (res.data.result[0].showRows) {
        this.setState({
          showRows: res.data.result[0].showRows,
          Interests: res.data.result[0].interests,
          editorChoice: res.data.result[0].editorChoice,
          latestReleases: res.data.result[0].latestReleases,
          setCategoryData: JSON.parse(res.data.result[0].setCategoryData),
          categoriesList: res.data.result[0].categoriesList,
          opacityVal: 1
        });
      }
      else
        this.setState({
          showRows: res.data.result[0].showRows,
          Interests: res.data.result[0].interests,
          editorChoice: res.data.result[0].editorChoice,
          latestReleases: res.data.result[0].latestReleases,
          setCategoryData: JSON.parse(res.data.result[0].setCategoryData),
          categoriesList: res.data.result[0].categoriesList,
          opacityVal: 0
        });

    });

    //////////////end
  }



  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="w-full flex items-center justify-between p-24">
            <h2>App Section Content</h2>
          
            <div className="flex items-center">
              <Button color="primary" variant="contained"
                onClick={(e) => {
                  this.submitFunction(e)
                }}
              >
                Save
                </Button>
            </div>
          </div>
        }
        content={
          <div className="p-24" style={{
            flex: 1,
          }}>
            <Dialog
              open={this.state.openpopupDelete}
              onClose={() => {
                this.setState({
                  openpopupDelete: false
                })
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete it?"}</DialogTitle>
              <DialogContent>

              </DialogContent>
              <DialogActions>
                <Button onClick={() => {
                  this.setState({
                    openpopupDelete: false
                  })
                }} color="primary">
                  Disagree
          </Button>
                <Button onClick={() => {
                  this.backAddItem()
                }} color="primary" autoFocus>
                  Agree
          </Button>
              </DialogActions>
            </Dialog>

            <Alert severity="success" style={{
              display: this.state.diplaymgs
            }}>Settings saved successfully</Alert>


            <FormControlLabel control={
              <Switch
                checked={this.state.showRows}
                onChange={(e) => {
                  this.handleChange(e, "showRows");
                }}
              />}
              label="Show Rows"
              labelPlacement="start"
            />

            <div style={{
              padding: 10,
              translate: 'opacity 4s',
              opacity: this.state.opacityVal
            }}>
              <Grid item xs={12} sm={12}>
                <Paper style={{
                  padding: 20
                }}>
                  <FormControlLabel control={
                    <Switch
                      checked={this.state.Interests}
                      onChange={(e) => {
                        this.handleChange(e, "Interests");
                      }}
                    />}
                    label="Interests"
                    style={{
                      color: '#000'
                    }}
                    labelPlacement="start"
                  />
                  <hr style={{
                    border: '0',
                    height: '1px',
                    backgroundColor: '#c6c6c6',
                    marginTop: 20,
                    marginBottom: 20
                  }} />

                  <FormControlLabel control={
                    <Switch
                      checked={this.state.latestReleases}
                      onChange={(e) => {
                        this.handleChange(e, "latestReleases");
                      }}
                    />}
                    label="Latest releases"
                    style={{
                      color: '#000'
                    }}
                    labelPlacement="start"
                  />


                  {this.state.latestReleases && (
                    <div className={classes.root}>
                      <AppBar position="static" color="default" style={{
                        marginTop: '2vw'
                      }}>
                        <Tabs
                          value={this.state.activeTab}
                          variant="scrollable"
                          scrollButtons="on"
                          indicatorColor="primary"
                          textColor="primary"
                          aria-label="scrollable force tabs example"
                        >
                          <Tab label="Songs" icon={<PhoneIcon />}
                            onClick={() => {
                              this.setState({
                                activeTab: 0
                              });
                            }} />
                          <Tab label="shows" icon={<FavoriteIcon />} onClick={() => {
                            this.setState({
                              activeTab: 1
                            });
                          }} />
                          <Tab label="Stories" icon={<PersonPinIcon />} onClick={() => {
                            this.setState({
                              activeTab: 2
                            });
                          }} />
                        </Tabs>
                      </AppBar>

                      {(this.state.activeTab === 0) && (
                        <div>
                          <Autocomplete
                            freeSolo
                            id="free-solo-2-demo"
                            disableClearable
                            options={this.state.songsData.map((option) => {
                              return (option)
                            })}

                            onChange={(event, value) => {
                              this.addtoarry(value, 'setSongsData', 'songsData')
                            }}
                            getOptionLabel={(option) => {
                              return option.titleEn
                            }}
                            renderInput={(params) => {
                              // console.log(params);
                              return (<TextField
                                {...params}
                                label="Search.."
                                margin="normal"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search' }}
                              />);
                            }}
                          />

                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap'
                          }}>
                            {
                              this.state.setSongsData.map((option) =>

                                <Card style={
                                  {
                                    width: '20vw',
                                    padding: '2vw',
                                    marginRight: '2vw',
                                    marginBottom: '2vw'
                                  }
                                }>
                                  <CardHeader
                                     
                                    action={
                                      <div>
                                        <IconButton aria-label="settings" onClick={() => {
                                          this.setState({
                                            openpopupDelete: true,
                                            arraynamedelete: 'setSongsData',
                                            arrayDataDelete: option,
                                            arraynamedelete2: 'songsData'
                                          })
                                        }} >
                                          <MoreVertIcon />
                                        </IconButton>
                                      </div>


                                    }
                                    titleTypographyProps={{ variant: 'h5' }}
                                    title={option.titleEn} 
                                  />

                                  <ListItemAvatar style={{ padding: "10px" }}>
                                    <img
                                      className="w-full block rounded"
                                      style={{
                                        width: 200,
                                        height: 100,
                                        display: 'flex',
                                        flexDirection: "row",
                                        flexFlow: "center",
                                        justifyContent: 'center',
                                        alignItems: "center"
                                      }}
                                      alt={option.titleEn}
                                      src={
                                        process.env.REACT_APP_BACKEND_URL +
                                        "/public/" +
                                        option.coverImg
                                      }
                                    />
                                  </ListItemAvatar>

                                </Card>

                              )
                            }



                          </div>

                        </div>
                      )}
                      {(this.state.activeTab === 1) && (
                        <div>
                          <Autocomplete
                            freeSolo
                            id="free-solo-2-demo"
                            disableClearable
                            options={this.state.showsData.map((option) => {
                              return (option)
                            })}

                            onChange={(event, value) => {
                              this.addtoarry(value, 'setShowsData', 'showsData')
                            }}
                            getOptionLabel={(option) => {
                              return option.titleEn
                            }}
                            renderInput={(params) => {
                              // console.log(params);
                              return (<TextField
                                {...params}
                                label="Search.."
                                margin="normal"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search' }}
                              />);
                            }}
                          />

                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap'
                          }}>
                            {
                              this.state.setShowsData.map((option) =>
                                <Card style={
                                  {
                                    width: '20vw',
                                    padding: '2vw',
                                    marginRight: '2vw',
                                    marginBottom: '2vw'
                                  }
                                }>
                                  <CardHeader
                                    
                                    action={
                                      <div>
                                        <IconButton aria-label="settings" onClick={() => {
                                          this.setState({
                                            openpopupDelete: true,
                                            arraynamedelete: 'setShowsData',
                                            arrayDataDelete: option,
                                            arraynamedelete2: 'showsData'
                                          })
                                        }} >
                                          <MoreVertIcon />
                                        </IconButton>
                                      </div>


                                    }
                                    titleTypographyProps={{ variant: 'h5' }}
                                    title={option.titleEn} 
                                  />

                                  <ListItemAvatar style={{ padding: "10px" }}>
                                    <img
                                      className="w-full block rounded"
                                      style={{
                                        width: 200,
                                        height: 100,
                                        display: 'flex',
                                        flexDirection: "row",
                                        flexFlow: "center",
                                        justifyContent: 'center',
                                        alignItems: "center"
                                      }}
                                      alt={option.titleEn}
                                      src={
                                        process.env.REACT_APP_BACKEND_URL +
                                        "/public/" +
                                        option.coverImg
                                      }
                                    />
                                  </ListItemAvatar>

                                </Card>
                              )
                            }
                          </div>

                        </div>
                      )}
                      {(this.state.activeTab === 2) && (
                        <div>
                          <Autocomplete
                            freeSolo
                            id="free-solo-2-demo"
                            disableClearable
                            options={this.state.storesData.map((option) => {
                              return (option)
                            })}

                            onChange={(event, value) => {
                              this.addtoarry(value, 'setStoresData', 'storesData')
                            }}
                            getOptionLabel={(option) => {
                              return option.titleEn
                            }}
                            renderInput={(params) => {
                              // console.log(params);
                              return (<TextField
                                {...params}
                                label="Search.."
                                margin="normal"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search' }}
                              />);
                            }}
                          />

                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap'
                          }}>
                            {
                              this.state.setStoresData.map((option) =>
                                <Card style={
                                  {
                                    width: '20vw',
                                    padding: '2vw',
                                    marginRight: '2vw',
                                    marginBottom: '2vw'
                                  }
                                }>
                                  <CardHeader
                                   
                                    action={
                                      <div>
                                        <IconButton aria-label="settings" onClick={() => {
                                          this.setState({
                                            openpopupDelete: true,
                                            arraynamedelete: 'setStoresData',
                                            arrayDataDelete: option,
                                            arraynamedelete2: 'storesData'
                                          })
                                        }} >
                                          <MoreVertIcon />
                                        </IconButton>
                                      </div>


                                    }
                                    titleTypographyProps={{ variant: 'h5' }}
                                    title={option.titleEn} 
                                  />

                                  <ListItemAvatar style={{ padding: "10px" }}>
                                    <img
                                      className="w-full block rounded"
                                      style={{
                                        width: 200,
                                        height: 100,
                                        display: 'flex',
                                        flexDirection: "row",
                                        flexFlow: "center",
                                        justifyContent: 'center',
                                        alignItems: "center"
                                      }}
                                      alt={option.titleEn}
                                      src={
                                        process.env.REACT_APP_BACKEND_URL +
                                        "/public/" +
                                        option.coverImg
                                      }
                                    />
                                  </ListItemAvatar>

                                </Card>
                              )
                            }



                          </div>

                        </div>
                      )}




                    </div>
                  )}





                  <hr style={{
                    border: '0',
                    height: '1px',
                    backgroundColor: '#c6c6c6',
                    marginTop: 20,
                    marginBottom: 20
                  }} />
                  <FormControlLabel control={
                    <Switch
                      checked={this.state.editorChoice}
                      onChange={(e) => {
                        this.handleChange(e, "editorChoice");
                      }}
                    />}
                    label="Editor Choice"
                    style={{
                      color: '#000'
                    }}
                    labelPlacement="start"
                  />
                  {this.state.editorChoice && (
                    <div className={classes.root}>
                      <AppBar position="static" color="default" style={{
                        marginTop: '2vw'
                      }}>
                        <Tabs
                          value={this.state.activeTab2}
                          variant="scrollable"
                          scrollButtons="on"
                          indicatorColor="primary"
                          textColor="primary"
                          aria-label="scrollable force tabs example"
                        >
                          <Tab label="Songs" icon={<PhoneIcon />}
                            onClick={() => {
                              this.setState({
                                activeTab2: 0
                              });
                            }} />
                          <Tab label="shows" icon={<FavoriteIcon />} onClick={() => {
                            this.setState({
                              activeTab2: 1
                            });
                          }} />
                          <Tab label="Stories" icon={<PersonPinIcon />} onClick={() => {
                            this.setState({
                              activeTab2: 2
                            });
                          }} />
                        </Tabs>
                      </AppBar>

                      {(this.state.activeTab2 === 0) && (
                        <div>
                          <Autocomplete
                            freeSolo
                            id="free-solo-2-demo"
                            disableClearable
                            options={this.state.songsData2.map((option) => {
                              return (option)
                            })}

                            onChange={(event, value) => {
                              this.addtoarry(value, 'setSongsData2', 'songsData2')
                            }}
                            getOptionLabel={(option) => {
                              return option.titleEn
                            }}
                            renderInput={(params) => {
                              // console.log(params);
                              return (<TextField
                                {...params}
                                label="Search.."
                                margin="normal"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search' }}
                              />);
                            }}
                          />

                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap'
                          }}>
                            {
                              this.state.setSongsData2.map((option) =>
                                <Card style={
                                  {
                                    width: '20vw',
                                    padding: '2vw',
                                    marginRight: '2vw',
                                    marginBottom: '2vw'
                                  }
                                }>
                                  <CardHeader
                                     
                                    action={
                                      <div>
                                        <IconButton aria-label="settings" onClick={() => {
                                          this.setState({
                                            openpopupDelete: true,
                                            arraynamedelete: 'setSongsData2',
                                            arrayDataDelete: option,
                                            arraynamedelete2: 'songsData2'
                                          })
                                        }} >
                                          <MoreVertIcon />
                                        </IconButton>
                                      </div>


                                    }
                                    titleTypographyProps={{ variant: 'h5' }}
                                    title={option.titleEn}
                                  />

                                  <ListItemAvatar style={{ padding: "10px" }}>
                                    <img
                                      className="w-full block rounded"
                                      style={{
                                        width: 200,
                                        height: 100,
                                        display: 'flex',
                                        flexDirection: "row",
                                        flexFlow: "center",
                                        justifyContent: 'center',
                                        alignItems: "center"
                                      }}
                                      alt={option.titleEn}
                                      src={
                                        process.env.REACT_APP_BACKEND_URL +
                                        "/public/" +
                                        option.coverImg
                                      }
                                    />
                                  </ListItemAvatar>

                                </Card>
                              )
                            }



                          </div>

                        </div>
                      )}
                      {(this.state.activeTab2 === 1) && (
                        <div>
                          <Autocomplete
                            freeSolo
                            id="free-solo-2-demo"
                            disableClearable
                            options={this.state.showsData2.map((option) => {
                              return (option)
                            })}

                            onChange={(event, value) => {
                              this.addtoarry(value, 'setShowsData2', 'showsData2')
                            }}
                            getOptionLabel={(option) => {
                              return option.titleEn
                            }}
                            renderInput={(params) => {
                              // console.log(params);
                              return (<TextField
                                {...params}
                                label="Search.."
                                margin="normal"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search' }}
                              />);
                            }}
                          />

                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap'
                          }}>
                            {
                              this.state.setShowsData2.map((option) =>
                                <Card style={
                                  {
                                    width: '20vw',
                                    padding: '2vw',
                                    marginRight: '2vw',
                                    marginBottom: '2vw'
                                  }
                                }>
                                  <CardHeader
                                   
                                    action={
                                      <div>
                                        <IconButton aria-label="settings" onClick={() => {
                                          this.setState({
                                            openpopupDelete: true,
                                            arraynamedelete: 'setShowsData2',
                                            arrayDataDelete: option,
                                            arraynamedelete2: 'showsData2'
                                          })
                                        }} >
                                          <MoreVertIcon />
                                        </IconButton>
                                      </div>


                                    }
                                    titleTypographyProps={{ variant: 'h5' }}
                                    title={option.titleEn} 
                                  />

                                  <ListItemAvatar style={{ padding: "10px" }}>
                                    <img
                                      className="w-full block rounded"
                                      style={{
                                        width: 200,
                                        height: 100,
                                        display: 'flex',
                                        flexDirection: "row",
                                        flexFlow: "center",
                                        justifyContent: 'center',
                                        alignItems: "center"
                                      }}
                                      alt={option.titleEn}
                                      src={
                                        process.env.REACT_APP_BACKEND_URL +
                                        "/public/" +
                                        option.coverImg
                                      }
                                    />
                                  </ListItemAvatar>

                                </Card>
                              )
                            }
                          </div>

                        </div>
                      )}
                      {(this.state.activeTab2 === 2) && (
                        <div>
                          <Autocomplete
                            freeSolo
                            id="free-solo-2-demo"
                            disableClearable
                            options={this.state.storesData2.map((option) => {
                              return (option)
                            })}

                            onChange={(event, value) => {
                              this.addtoarry(value, 'setStoresData2', 'storesData2')
                            }}
                            getOptionLabel={(option) => {
                              return option.titleEn
                            }}
                            renderInput={(params) => {
                              // console.log(params);
                              return (<TextField
                                {...params}
                                label="Search.."
                                margin="normal"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search' }}
                              />);
                            }}
                          />

                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap'
                          }}>
                            {
                              this.state.setStoresData2.map((option) =>
                                <Card style={
                                  {
                                    width: '20vw',
                                    padding: '2vw',
                                    marginRight: '2vw',
                                    marginBottom: '2vw'
                                  }
                                }>
                                  <CardHeader
                                   
                                    action={
                                      <div>
                                        <IconButton aria-label="settings" onClick={() => {
                                          this.setState({
                                            openpopupDelete: true,
                                            arraynamedelete: 'setStoresData2',
                                            arrayDataDelete: option,
                                            arraynamedelete2: 'storesData2'
                                          })
                                        }} >
                                          <MoreVertIcon />
                                        </IconButton>
                                      </div>


                                    }
                                    titleTypographyProps={{ variant: 'h5' }}
                                    title={option.titleEn} 
                                  />

                                  <ListItemAvatar style={{ padding: "10px" }}>
                                    <img
                                      className="w-full block rounded"
                                      style={{
                                        width: 200,
                                        height: 100,
                                        display: 'flex',
                                        flexDirection: "row",
                                        flexFlow: "center",
                                        justifyContent: 'center',
                                        alignItems: "center"
                                      }}
                                      alt={option.titleEn}
                                      src={
                                        process.env.REACT_APP_BACKEND_URL +
                                        "/public/" +
                                        option.coverImg
                                      }
                                    />
                                  </ListItemAvatar>

                                </Card>
                              )
                            }



                          </div>

                        </div>
                      )}




                    </div>
                  )}
                  <hr style={{
                    border: '0',
                    height: '1px',
                    backgroundColor: '#c6c6c6',
                    marginTop: 20,
                    marginBottom: 20
                  }} />

                  <FormControlLabel control={
                    <Switch
                      checked={this.state.categoriesList}
                      onChange={(e) => {
                        this.handleChange(e, "categoriesList");
                      }}
                    />}
                    label="Categories List"
                    style={{
                      color: '#000'
                    }}
                    labelPlacement="start"
                  />
                  {this.state.categoriesList && (
                    <div style={{
                      padding: '2vw'
                    }}>
                      {
                        this.state.categoryData.map((option, index) =>
                          //this.state.setCategoryData[index].activeCategory
                          <Chip
                            avatar={<Avatar alt="Natacha" src={process.env.REACT_APP_BACKEND_URL +
                              "/public/" +
                              option.imgUpload} />}
                            label={option.titleEn}
                            clickable
                            color="primary"
                            Icon={<DoneIcon />}
                            variant="outlined"
                            style={this.state.setCategoryData[index].activeCategory ? {
                              margin: '2px',
                              backgroundColor: '#61dafb',
                              fontSize: '18px',
                              color: '#fff'
                            } : {
                                margin: '2px',
                                fontSize: '18px',
                                backgroundColor: '#fff',
                                color: '#000'
                              }}
                            onClick={() => {
                              console.log(this.state.setCategoryData[index])
                              this.choiseCategory(index, (!this.state.setCategoryData[index].activeCategory))
                            }}
                          />

                        )}
                    </div>
                  )}
                </Paper>
              </Grid>

            </div>
            <Popup />
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(view);
