import List from './List';
import Add from './Add';
import AudioEdit from './AudioEdit'
import QuizData from './QuizData';
import BookOrder from './BookOrder';
import { authRoles } from 'app/auth';
import Edit from './Edit';
import List4 from './List4';
import BookData from './BookData';
import Import from './Import';
import ImportUpdate from './ImportUpdate';
import Freebooks from './freeBooks';

export const BookConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	// auth: authRoles.admin,
	routes: [
		{
			path: `${process.env.REACT_APP_FRONT_URL}books/v2/list`,
			component: List
		},
		{
			path: `${process.env.REACT_APP_FRONT_URL}books/list`,
			component: List4
		},
		{
			path: `${process.env.REACT_APP_FRONT_URL}book/import`,
			component: Import
		},
		{
			path: `${process.env.REACT_APP_FRONT_URL}book/update/import`,
			component: ImportUpdate
		},
		{
			path: `${process.env.REACT_APP_FRONT_URL}book/:id/edit_audio`,
			component: AudioEdit
		},
		{
			path: `${process.env.REACT_APP_FRONT_URL}book/:id/quizdata`,
			component: QuizData
		},
		{
			path: `${process.env.REACT_APP_FRONT_URL}book/:id/order`,
			component: BookOrder
		},
		{
			path: `${process.env.REACT_APP_FRONT_URL}book/add`,
			component: Add
		},
		{
			path: `${process.env.REACT_APP_FRONT_URL}book/:id/edit`,
			component: Edit
		},
		{
			path: `${process.env.REACT_APP_FRONT_URL}book/:id/read`,
			component: BookData
			//component: () => <Read loggedInUser={reduce.loggedInUser} userProgress={reduce.userProgress} currentBookProgressSaved={reduce.currentBookProgressSaved} appLanguage={reduce.appLanguage} vocabularyModal={reduce.vocabularyModal} dictionaryModal={reduce.dictionaryModal} newVocabularyData={reduce.newVocabularyData} />
		},
		{
			path: `${process.env.REACT_APP_FRONT_URL}freebooks`,
			component: Freebooks
		}
	]
};

export default BookConfig;
