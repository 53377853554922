import view from "./view";
import listAppMessages from "./listAppMessages";
import editListAppMessages from "./editListAppMessages";
import rowsapp from "./rowsapp";
import rowsadd from "./addrow";
import editRow from "./editRow";
import details from "./details";
import { authRoles } from 'app/auth';


export const appsettings = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: `${process.env.REACT_APP_FRONT_URL}appsettings/view`,
      component: view,
    }, {
      path: `${process.env.REACT_APP_FRONT_URL}appsettings/listAppMessages`,
      component: listAppMessages,
    }, {
      path: `${process.env.REACT_APP_FRONT_URL}appsettings/:id/editListAppMessages`,
      component: editListAppMessages,
    }, {
      path: `${process.env.REACT_APP_FRONT_URL}appsettings/rowsapp`,
      component: rowsapp,
    }, {
      path: `${process.env.REACT_APP_FRONT_URL}appsettings/addrows`,
      component: rowsadd,
    }, {
      path: `${process.env.REACT_APP_FRONT_URL}appsettings/:id/edit`,
      component: editRow,
    }, {
      path: `${process.env.REACT_APP_FRONT_URL}appsettings/:id/details`,
      component: details,
    }
  ],
};
