import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";

import Paper from "@material-ui/core/Paper";
import Breadcrumb from "./../components/Breadcrumb";
import axios from "axios";
import { Link } from "react-router-dom";
import Popup from "./../components/popup";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Lock";
import BlockIcon from "@material-ui/icons/Block";
import AccountBox from "@material-ui/icons/AccountBox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { red } from "@material-ui/core/colors";
import _ from '@lodash';


import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { MenuItem, Select, TextField } from "@material-ui/core";
import { set } from "date-fns";
const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "Users List",
    link: "",
  },
];
class List extends Component {
  state = {
    open: false,
    formValid: false,
    rowsPerPage: 30,
    page: 0,
    data: [],
    categoryData: [],
    Data2: [],
    Data3: [],
    idremove: '0',
    idremove2: '0',
    panelactive: 0,
    statusAction: 0,
    restPasswordshow: false,
    idResetUser: '',
    namerest: '',
    emailreset: '',
    opendel: false,
    bundles: [],
    msgsushow: false,
    masgsu: '',
    subscriptionInfo: [],
    CompetitionInfo: [],
    subscriptionOpen: false,
    subscriptionOpen2: false,
    keyword: '',
    planName: 'plan_1',
  };

  handleSubmit = (model) => { };
  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };
  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };
  cahngepanel = (event, id) => {
    console.log(event)
    this.setState({
      panelactive: id,
    });
  }
  resetPassword = () => {
    // this.setState({
    //   idResetUser: '',
    //   restPasswordshow: false
    // })

    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    formData.append("id", this.state.idResetUser);
    formData.append("email", this.state.emailreset);
    formData.append("displayName", this.state.namerest);
    this.setState({
      restPasswordshow: false,
    });
    axios({
      url: `${backend_url}/user/resetpassword`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.setState({
        namerest: '',
        emailreset: '',
        idResetUser: '',
      });
    });

  };
  sortData = (name) => {
    let data = this.state.data;

    data = data.sort(function (a, b) {
      if (a[name] < b[name]) {
        return -1;
      }
      if (a[name] > b[name]) {
        return 1;
      }
      return 0;
    });

    this.setState({
      data: data,
    });
  };
  componentDidMount() {//
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    //countQuery
    let formData = new FormData();
    formData.append("countQuery", 0);
    formData.append("keyword", this.state.keyword);

    axios({
      url: `${backend_url}/user/list`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        categoryData: res.data.result,
      });
    });

    axios({
      url: `${backend_url}/user/listUserApp`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        Data2: res.data.result,
        Data3: res.data.result,
      });
    });




  }


  getDataMethod(value) {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    //countQuery
    let formData = new FormData();
    formData.append("countQuery", 0);
    formData.append("keyword", value);
    formData.append("planSearch", 1);
 

    axios({
      url: `${backend_url}/user/listUserApp`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        Data2: res.data.result,
        Data3: res.data.result,
      });
    });
  }
  
  getsubscription = (id) => {

    let backend_url = process.env.REACT_APP_BACKEND_URL;

    let formData = new FormData();
    formData.append("id", id);

    axios({
      url: `${backend_url}/user/getSubscription`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      if (res.data.resp == false) {
        alert('No subscription was selected')
      }
      else {
        this.setState({
          subscriptionOpen: true,
          subscriptionInfo: res.data.resp
        })

      }
    });

  }


  getCompetition = (id) => {

    let backend_url = process.env.REACT_APP_BACKEND_URL;

    let formData = new FormData();
    formData.append("id", id);

    axios({
      url: `${backend_url}/competitionsApp/get`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.setState({
        CompetitionInfo: res.data.result,
        subscriptionOpen2: true,
      })
    });

  }


  handleDelete = () => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    formData.append("idBlocked", this.state.idremove);
    formData.append("statusAction", this.state.statusAction);
    axios({
      url: `${backend_url}/user/blockuserapp`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.componentDidMount();
      this.setState({
        open: false,
      });
    });
  };


  handleDelete2 = () => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    formData.append("id", this.state.idremove2);
    axios({
      url: `${backend_url}/user/deleteuser`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.componentDidMount();
      this.setState({
        opendel: false,
        msgsushow: true,
        masgsu: 'User has been successfully deleted!'
      });
    });
  };
  prettyDate(date) {

    var d = new Date(date);

    return d.toDateString() + " ";
  }

  prettyDate2(date) {

    var d = new Date(date);

    return d.toDateString() + " - " + d.toLocaleTimeString();
  }
  checkIfInputMatches = (input, desc) => input.toLowerCase().split(" ").some(o => desc.toLowerCase().includes(o))

  searchBy(t, v) {
    // this.setState({
    //   Data2: _.filter(this.state.Data2, item => {
    //     return item.fname != undefined ? item.fname.includes(v) : item[t] != undefined ? item[t].includes(v) : ''
    //   })
    // })
    // if (v.length < 2) {
    //   this.setState({
    //     Data2: this.state.Data3
    //   })
    // }
    this.setState({ keyword: v })
    this.componentDidMount()
  }
  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="w-full flex items-center justify-between p-24">
            <h2>Users List</h2>

            <Snackbar open={this.state.msgsushow} autoHideDuration={6000} onClose={() => this.setState({ msgsushow: !this.state.msgsushow })}>
              <Alert onClose={() => this.setState({ msgsushow: !this.state.msgsushow })} severity="success">
                {this.state.masgsu}
              </Alert>
            </Snackbar>
          </div>
        }
        content={

          <div clsassName="p-24">


            <Dialog onClose={() => {
              this.setState({
                subscriptionOpen: false
              })
            }} aria-labelledby="customized-dialog-title" open={this.state.subscriptionOpen}>
              <DialogTitle id="customized-dialog-title" onClose={() => {
                this.setState({
                  subscriptionOpen: false
                })
              }}>
                Subscription information
              </DialogTitle>
              <DialogContent dividers >
                <Typography variant="subtitle1" gutterBottom>
                  Subscription Name: <b>{this.state.subscriptionInfo.subscriptionName}</b>
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Number of devices allowed:  <b>{this.state.subscriptionInfo.swn}</b>
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Subscription expires: <b>{this.prettyDate(this.state.subscriptionInfo.expiryDate)}</b>
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={
                  () => {
                    this.setState({
                      subscriptionOpen: false
                    })
                  }
                } color="primary">
                  close
                </Button>
              </DialogActions>
            </Dialog>


            <Dialog onClose={() => {
              this.setState({
                subscriptionOpen2: false
              })
            }} aria-labelledby="customized-dialog-title" open={this.state.subscriptionOpen2}>
              <DialogTitle id="customized-dialog-title" onClose={() => {
                this.setState({
                  subscriptionOpen2: false
                })
              }}>
                Competition information
              </DialogTitle>
              <DialogContent dividers >
                {this.state.CompetitionInfo != null ?
                  <div>
                    <Typography variant="subtitle1" gutterBottom>
                      Full name: <b>{this.state.CompetitionInfo.name}</b>
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      Mobile:  <b>{this.state.CompetitionInfo.mobileNumber}</b>
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      platform:  <b>{this.state.CompetitionInfo.platform}</b>
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      Date of joining: <b>{this.prettyDate(this.state.CompetitionInfo.created)}</b>
                    </Typography>
                  </div>
                  :
                  "There is no contest information for this user!"
                }

              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={
                  () => {
                    this.setState({
                      subscriptionOpen2: false
                    })
                  }
                } color="primary">
                  close
                </Button>
              </DialogActions>
            </Dialog>




            <AppBar position="static">
              <Tabs
                value={this.state.panelactive}
                aria-label="simple tabs example"

              >
                <Tab label="System admins" onClick={(e) => {
                  this.cahngepanel(e, 0);
                }} />
                {/* <Tab label="School Admin" onClick={(e) => {
                  this.cahngepanel(e, 1);
                }} /> */}
                <Tab label="Individual Users" onClick={(e) => {
                  this.cahngepanel(e, 2);
                }} />
              </Tabs>
            </AppBar>
            <div
              role="tabpanel"
              hidden={1 !== 1}
              id={`simple-tabpanel-${1}`}
              aria-labelledby={`simple-tab-${1}`}
            >
              {this.state.panelactive === 0 && (
                <Box p={this.state.panelactive}>
                  <div className="w-full flex items-center justify-between p-12" style={{

                    backgroundColor: '#d9d9d9'
                  }}>
                    <h2>System admins</h2>
                    <div className="flex items-center">
                      <Link to={`${process.env.REACT_APP_FRONT_URL}users/add`}>
                        <Fab color="primary" aria-label="add">
                          <AddIcon />
                        </Fab>
                      </Link>
                    </div>
                  </div>
                  <hr style={
                    {
                      content: '',
                      display: 'block',
                      marginTop: '2px',
                      borderTop: '1px solid #8c8c8c',
                      borderBottom: '1px solid #fff',
                    }
                  }></hr>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell onClick={() => this.sortData("name")}>
                            Full Name
                          </TableCell>
                          <TableCell align="center">Email</TableCell>
                          <TableCell align="center">Role</TableCell>
                          <TableCell align="center">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.categoryData
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                          )
                          .map((row) => (
                            <TableRow key={row._id}>
                              <TableCell component="th" scope="row">
                                {row.displayName}
                              </TableCell>
                              <TableCell align="center">{row.email}</TableCell>
                              <TableCell align="center">{row.Role}</TableCell>
                              <TableCell
                                align="center"
                                className=""
                              >
                                <Link
                                  to={`${process.env.REACT_APP_FRONT_URL}users/${row._id}/edit`}
                                >
                                  <IconButton
                                    aria-label="delete"
                                    className={classes.margin}
                                  >
                                    <EditIcon fontSize="large" />
                                  </IconButton>
                                </Link>
                                <Dialog
                                  open={this.state.restPasswordshow}
                                  aria-labelledby="draggable-dialog-title"
                                >
                                  <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                                    Are you sure?
                                  </DialogTitle>
                                  <DialogContent>
                                    <DialogContentText>
                                      The password will be reset, and the new password will be sent to the user email!
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button autoFocus
                                      onClick={
                                        () => {
                                          this.setState({
                                            idResetUser: '',
                                            restPasswordshow: false
                                          })
                                        }
                                      }
                                      color="primary">
                                      No
                                    </Button>
                                    <Button onClick={() => {
                                      this.resetPassword()
                                    }} color="primary">
                                      Yes
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                                <IconButton
                                  aria-label="delete"
                                  className={classes.margin}
                                  onClick={
                                    () => {
                                      this.setState({
                                        idResetUser: row._id,
                                        namerest: row.displayName,
                                        emailreset: row.email,
                                        restPasswordshow: true
                                      })
                                    }
                                  }
                                >
                                  <RefreshIcon fontSize="large" />
                                </IconButton>


                                <IconButton
                                  aria-label="delete"
                                  className="text-14"
                                  onClick={() => {
                                    this.setState({
                                      opendel: true,
                                      idremove2: row._id,
                                    });
                                  }}
                                >
                                  <DeleteIcon fontSize="large" />

                                </IconButton>
                                <Dialog
                                  open={this.state.opendel}
                                  keepMounted
                                  aria-labelledby="alert-dialog-slide-title"
                                  aria-describedby="alert-dialog-slide-description"
                                >
                                  <DialogTitle id="alert-dialog-slide-title">
                                    <DeleteIcon fontSize="large" />
                                    {"Are you sure to delete this user?"}
                                  </DialogTitle>
                                  <DialogActions>
                                    <Button
                                      onClick={() => {
                                        this.setState({
                                          opendel: false,
                                        });
                                      }}
                                      color="primary"
                                    >
                                      No
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        this.handleDelete2();
                                      }}
                                    >
                                      Yes
                                    </Button>
                                  </DialogActions>
                                </Dialog>

                                <Dialog
                                  open={this.state.open}
                                  keepMounted
                                  aria-labelledby="alert-dialog-slide-title"
                                  aria-describedby="alert-dialog-slide-description"
                                >
                                  <DialogTitle id="alert-dialog-slide-title">
                                    <DeleteIcon fontSize="large" />{" "}
                                    {" Are you sure you want to delete it?"}
                                  </DialogTitle>
                                  <DialogActions>
                                    <Button
                                      onClick={() => {
                                        this.setState({
                                          open: false,
                                        });
                                      }}
                                      color="primary"
                                    >
                                      No
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        this.handleDelete()
                                      }}
                                    >
                                      OK
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      component="div"
                      count={this.state.categoryData.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      backIconButtonProps={{
                        "aria-label": "previous page",
                      }}
                      nextIconButtonProps={{
                        "aria-label": "next page",
                      }}
                      onChangePage={this.handleChangePage}
                    />
                  </TableContainer>

                  <Popup />


                </Box>

              )
              }
              {this.state.panelactive === 1 && (
                <Box p={this.state.panelactive}>
                  <h2
                    style={{
                      padding: '1vw 1vw',
                      backgroundColor: '#d9d9d9'
                    }}
                  >School Admin</h2>
                  <hr style={
                    {
                      content: '',
                      display: 'block',
                      marginTop: '2px',
                      borderTop: '1px solid #8c8c8c',
                      borderBottom: '1px solid #fff',
                    }
                  }></hr>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell onClick={() => this.sortData("name")}>
                            Full Name
                          </TableCell>
                          <TableCell align="center">Email</TableCell>
                          <TableCell align="center">Role</TableCell>
                          <TableCell align="center">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>

                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      component="div"
                      count={this.state.categoryData.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      backIconButtonProps={{
                        "aria-label": "previous page",
                      }}
                      nextIconButtonProps={{
                        "aria-label": "next page",
                      }}
                      onChangePage={this.handleChangePage}
                    />
                  </TableContainer>

                  <Popup />


                </Box>

              )
              }
              {this.state.panelactive === 2 && (
                <Box p={this.state.panelactive}>
                  <div className="w-full flex items-center justify-between p-12" style={{

                    backgroundColor: '#d9d9d9'
                  }}>
                    <h2>Individual users</h2>
                    <TextField id="outlined-basic" label="Search in all fields" variant="outlined"
                      onChange={(e) => this.searchBy('_id', e.target.value)}
                      fullWidth
                      style={{ backgroundColor: '#fff', width: '50%' }}
                    />
                    <div className="flex items-center">
                      <Link to={`${process.env.REACT_APP_FRONT_URL}users/app/add`}>
                        <Fab color="primary" aria-label="add">
                          <AddIcon />
                        </Fab>
                      </Link>
                    </div>
                  </div>

                  <hr style={
                    {
                      content: '',
                      display: 'block',
                      marginTop: '2px',
                      borderTop: '1px solid #8c8c8c',
                      borderBottom: '1px solid #fff',
                    }
                  }></hr>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell onClick={() => this.sortData("_id")}>
                            ID
                          </TableCell>
                          <TableCell align="center">Email/Account Id
                          </TableCell>
                          <TableCell align="center">Register Type
                          </TableCell>
                          <TableCell align="center">Phone
                          </TableCell>
                          <TableCell align="center">Created</TableCell>
                          <TableCell align="center">language
                          </TableCell>
                          <TableCell align="center">Country/City  </TableCell>
                          <TableCell align="center">Subscription
                            {/* <br />
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Plan"
                              onChange={(e) => {
                                this.getDataMethod(e.target.value)
                              }}
                            >
                              <MenuItem value={'plan_1'}>Free</MenuItem>
                              <MenuItem value={'plan_2'}>Basic</MenuItem>
                              <MenuItem value={'plan_3'}>Premium</MenuItem>
                            </Select> */}
                          </TableCell>
                          <TableCell align="center">Active </TableCell>
                          <TableCell align="center">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.Data2
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                          )
                          .map((row) => (
                            <TableRow key={row._id}>
                              <TableCell component="th" scope="row">
                                {row._id}
                              </TableCell>

                              <TableCell align="left">{row.type == 'email' ? row.email : (row.facebookEmail == undefined ? row.accountId : row.facebookEmail) + '/' + row.accountName}</TableCell>
                              <TableCell align="center">{row.type}</TableCell>
                              <TableCell align="center">{row.phone}</TableCell>
                              <TableCell align="center">{this.prettyDate2(row.dataInsert)}</TableCell>
                              <TableCell align="center">{row.lang.toUpperCase()}</TableCell>


                              <TableCell align="center">

                                {row.geo != undefined ? row.geo.country : ''} /  {row.geo != undefined ? row.geo.timezone.split('/')[1] : ''}
                              </TableCell>


                              <TableCell align="center">
                                {row.productId}
                                <Button
                                  type="button"
                                  variant="contained"
                                  color="primary"
                                  className="ml-auto my-16"
                                  onClick={() => {
                                    this.getsubscription(row._id)
                                  }}
                                >
                                  subscription
                                </Button></TableCell>
                              <TableCell align="center">{row.active == 1 ? 'Active user' : 'Blocked'}</TableCell>

                              <TableCell
                                align="center"
                                className="flex justify-around"
                              >
                                <Link
                                  to={`${process.env.REACT_APP_FRONT_URL}users/${row._id}/profile`}
                                >
                                  <IconButton
                                    aria-label="Edit"
                                    className={classes.margin}
                                  >
                                    <AccountBox fontSize="large" />
                                  </IconButton>
                                </Link>

                                <IconButton
                                  aria-label="Block"
                                  className={classes.margin}
                                  onClick={() => {
                                    this.setState({
                                      open: true,
                                      idremove: row._id,
                                      statusAction: row.active
                                    });
                                  }}
                                  color={row.active == 1 ? '' : 'secondary'}
                                >
                                  <BlockIcon fontSize="large" />
                                </IconButton>
                                <Dialog
                                  open={this.state.open}
                                  keepMounted
                                  aria-labelledby="alert-dialog-slide-title"
                                  aria-describedby="alert-dialog-slide-description"
                                >
                                  <DialogTitle id="alert-dialog-slide-title">
                                    <BlockIcon fontSize="large" />{" "}
                                    {"Are you sure you want to block?"}
                                  </DialogTitle>
                                  <DialogActions>
                                    <Button
                                      onClick={() => {
                                        this.setState({
                                          open: false,
                                        });
                                      }}
                                      color="primary"
                                    >
                                      No
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        this.handleDelete()
                                      }}
                                    >
                                      OK
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      component="div"
                      count={this.state.Data2.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      backIconButtonProps={{
                        "aria-label": "previous page",
                      }}
                      nextIconButtonProps={{
                        "aria-label": "next page",
                      }}
                      onChangePage={this.handleChangePage}
                    />
                  </TableContainer>

                  <Popup />


                </Box>

              )
              }
            </div>

          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(List);
