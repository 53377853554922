import { useForm } from '@fuse/hooks';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider'; 
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';





function LinearProgressWithLabel(props) {
	return (
		<Box display="flex" alignItems="center">
			<Box width="100%" mr={1}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box minWidth={35}>
				<Typography variant="body2" color="textSecondary">{`${Math.round(
					props.value,
				)}%`}</Typography>
			</Box>
		</Box>
	);
}

LinearProgressWithLabel.propTypes = {
	/**
	 * The value of the progress indicator for the determinate and buffer variants.
	 * Value between 0 and 100.
	 */
	value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
	root: {
	  padding: '2px 4px',
	  display: 'flex',
	  alignItems: 'center',
	  width: 400,
	},
	input: {
	  marginLeft: theme.spacing(1),
	  flex: 1,
	},
	iconButton: {
	  padding: 10,
	},
	divider: {
	  height: 28,
	  margin: 4,
	},
  }));
function ContactDialog(props) {

    const [openList, setOpenList] = React.useState(props.openList);
	const [deleteItem, setDeleteItem] = React.useState(false);
	const [data, setData] = React.useState([]);
	const [data2, setData2] = React.useState([]);
	const [search, setSearch] = React.useState("");

	const inputRef = useRef(null);
	const classes = useStyles();



	const closeComposeDialog = () => {
		props.setOpenInfo(false)
	}

	const handleDelete = (id) => {
		let backend_url = process.env.REACT_APP_BACKEND_URL;
		let formData = new FormData();
		formData.append("id", id);
		axios({
			url: `${backend_url}/fileManger/delete`,
			data: formData,
			method: "post",
			headers: { "Content-Type": "multipart/form-data" },
		}).then((res) => {
			setDeleteItem(false)
			props.setOpenInfo(false);
			props.setRefreshKey(oldKey => oldKey + 1)
		});
	}
	React.useEffect(() => {
        setOpenList(props.openList);
		axios({
			url: `${process.env.REACT_APP_BACKEND_URL}/fileManger/list`,
			method: "post",
			headers: { "Content-Type": "application/json" },
		}).then((res) => {
			setData(res.data.result)
			setData2(res.data.result)
		});
	}, [props.openList]);
	return (
		<Dialog open={openList} onClose={()=>{ 
			setOpenList(!props.openList)
		}} aria-labelledby="form-dialog-title">
			<AppBar position="static">
				<Toolbar className="flex w-full">
					<Typography variant="subtitle1" color="inherit" style={{ textTransform: 'uppercase' }}>
						Media library
					</Typography>
				</Toolbar>
			</AppBar>

			<form noValidate onSubmit={'handleSubmit'} className="flex flex-col">
				<DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>
					<Paper component="form" className={classes.root}>
					 
						<InputBase
							className={classes.input}
							placeholder="Search"
							inputProps={{ 'aria-label': 'search' }}
							onChange={e => {
								const test = data2.filter(item => {
								  return item.name.toLowerCase().includes(e.target.value.toLowerCase());
								}); 
								setData(test);
								setSearch(e.target.value);
							  }}
							  value={search}
						/>
						<IconButton type="button" className={classes.iconButton} aria-label="search">
							<SearchIcon />
						</IconButton>
 				 
					</Paper>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell className="text-center hidden sm:table-cell">Size</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>

							{Object.entries(data).map(([key, n]) => {
								return (
									<TableRow
										key={n._id}
										hover
										onClick={() => {
											props.setFile(n)
										}}
										selected={n._id === 1}
										className="cursor-pointer"
									>

										<TableCell>{n.name}</TableCell>
										<TableCell className="text-center hidden sm:table-cell">
											{n.size === '' ? '-' : n.size} MB
								</TableCell>

									</TableRow>
								);
							})}

						</TableBody>
					</Table>




				</DialogContent>


			</form>
		</Dialog>
	);
}

export default ContactDialog;
