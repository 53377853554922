import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { Link as RouteLink } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";

class Breadcrumb extends Component {
  handleClick = (e) => {
    e.preventDefault();
    console.info("You clicked a breadcrumb.");
  };
  render() {
    let data = [];

    if (this.props.data != undefined) {
      data = this.props.data;
    }
    return (
      <div >
        <Breadcrumbs aria-label="breadcrumb" >
          <Link href="/" className={"flex items-center"} >
            <RouteLink to={process.env.REACT_APP_FRONT_URL} style={{color:'#fff', fontWeight: 'bold'}}>
              <HomeIcon className={"breadcrumb-icon"}  style={{color:'#fff'}}/>
              Home
            </RouteLink>
          </Link>

          {data.map(function (item) {
            if (item.link != "") {
              return (
                <Link
                  color="inherit"
                  href={item.link}
                  className={"flex items-center"}
                >
                  <RouteLink to={item.link} style={{color:'#fff', fontWeight: 'bold'}}>{item.title} </RouteLink>
                </Link>
              );
            } else {
              return (
                <Typography color="textPrimary" className={"flex items-center"}>
                  {item.title}
                </Typography>
              );
            }
          })}
        </Breadcrumbs>
      </div>
    );
  }
}
export default Breadcrumb;
