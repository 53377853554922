import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import Formsy from "formsy-react";
import Breadcrumb from "./../components/Breadcrumb";
import axios from "axios";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "User Edit",
    link: `${process.env.REACT_APP_FRONT_URL}users/list`,
  },
  {
    title: "Edit",
    link: "",
  },
];

class Edit extends Component {
  state = {
    formValid: false,
    info: [],
    RoleData: ['Super admin', 'Editor'],
    Role: '',
    disabled: false,
    error: "",
    displayerror: "none",
    showPopu: false,
    masgsu: ''
  };
  handleChange = (e, name) => {
    //{this.state.password}
    this.setState({
      [name]: e.target.value,
    });
  };
  handleSubmit = (model) => {
    if (this.state.password == this.state.oldpassword) {
      this.setState({
        error: "",
        displayerror: "none",
      });

      let backend_url = process.env.REACT_APP_BACKEND_URL;
      let formData = new FormData();

      formData.append("Role", this.state.Role);
      formData.append("id", this.props.match.params.id);

      axios({
        url: `${backend_url}/user/update`,
        data: formData,
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        this.setState({ 
          showPopu: true, 
        }); 
      });
    } else {
      this.setState({
        error: "The old password does not match!",
        displayerror: "block",
      });
    }
  };
  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };

  componentDidMount() {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let id = this.props.match.params.id;

    let data = {
      id: id,
    };

    axios({
      url: `${backend_url}/user/get`,
      data: data,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        info: res.data.result,
        Role: res.data.result.Role
      });
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="px-24">
            <h2>Edit User | {this.state.info.displayName} ({this.state.info.email})</h2>
          </div>
        }
        content={
          <div className="p-24">
            <div className="max-w-lg">
            <Dialog
                                    open={this.state.showPopu}
                                    // onClose={}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">{"User has been modified successfully!"}</DialogTitle>
                                    <DialogContent  >
                                        
                                        <Button variant="outlined" size="large" style={{
                                            margin: '1vw auto',
                                            display: 'block'

                                        }} color="primary" className={classes.margin}
                                            onClick={() => {
                                                this.setState({
                                                    showPopu: false,
                                                });

                                                this.props.history.push("/frontend/users/list");
                                            }}

                                        >
                                            close
        </Button>
                                    </DialogContent>

                                </Dialog>
              <Formsy
                onValidSubmit={this.handleSubmit}
                onValid={this.enableButton}
                onInvalid={this.disableButton}
                ref={"cateogry_add"}
                className="flex flex-col p6-12"
              >
                <div
                  className="flex flex-row justify-between py-20"
                  style={{
                    color: "#000",
                    backgroundColor: "#EDCEC7",
                    padding: "10px",
                    fontSize: "15px",
                    display: this.state.displayerror,
                  }}
                >
                  {this.state.error}
                </div>


                <div className="flex flex-row justify-between py-10">



                  <SelectFormsy
                    className="w-5/12"
                    name="Role"
                    label="Role"
                    value={this.state.Role}
                    onChange={(e) => {
                      this.handleChange(e, "Role");
                    }}
                    variant="outlined"
                  >

                    {this.state.RoleData.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </SelectFormsy>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="ml-auto my-16"
                    aria-label="Add"
                    disabled={!this.state.formValid}
                  >
                    Edit
                </Button>
                </div>


              </Formsy>
            </div>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(Edit);
