import React, { Component } from "react";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { darken } from "@material-ui/core/styles/colorManipulator";
import { FuseAnimate } from "@fuse";
import { Link, Redirect } from "react-router-dom";
import * as authActions from "../../auth/store/actions";
import axios from "axios";

import { connect } from "react-redux";
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      "linear-gradient(to right, " +
      theme.palette.primary.dark +
      " 0%, " +
      darken(theme.palette.primary.dark, 0.5) +
      " 100%)",
    color: theme.palette.primary.contrastText,
  },
}));

class Login extends Component {
  state = {
    email: "",
    password: "",
    formValid: true,
    restPasswordshow: false,
    restPasswordshowError: false,
  };
  handleChange = (name, e) => {
    if (e.target.value.length > 3) {
      this.setState({
        formValid: false,
      });
    } else {
      this.setState({
        formValid: true,
      });
    }
    this.setState({
      [name]: e.target.value,
    });
  };
  handleSubmit() {
    this.setState({
      restPasswordshowError: false,
      restPasswordshow: false,
    });
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    formData.append("email", this.state.email);

    axios({
      url: `${backend_url}/user/resetpassword2`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      // console.log(res.data.result)
      if (res.data.result.lastErrorObject.n == 1) {
        this.setState({
          restPasswordshow: true,
        });
      }
      else {
        this.setState({
          restPasswordshowError: true,
        });
      }

    });
  }

  render() {
    return (
      <div
        className={
          "flex flex-col flex-auto flex-shrink-0 p-24 md:flex-row md:p-0 bg-black"
        }
      >
        {this.props.auth.user.role.length > 0 ? (
          <Redirect to={`${process.env.REACT_APP_FRONT_URL}dashboard`} />
        ) : (
            ""
          )}

        <div className="flex flex-col flex-grow-0 items-center text-white p-16 text-center md:p-128 md:items-start md:flex-shrink-0 md:flex-1 md:text-left">
          <FuseAnimate animation="transition.expandIn">
            <img
              className="w-128 mb-32"
              src={`https://royakids.tv/frontend/assets/images/logos/royaTvLogo.png`}
              alt="logo"
            />
          </FuseAnimate>

          <FuseAnimate animation="transition.slideUpIn" delay={300}>
            <Typography variant="h3" color="inherit" className="font-light">
              Roya Kids
            </Typography>
          </FuseAnimate>
        </div>

        <FuseAnimate animation={{ translateX: [0, "100%"] }}>
          <Card className="w-full max-w-400 mx-auto m-16 md:m-0" square>
            <CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 ">
              <Typography variant="h6" className="md:w-full mb-32">
                Please enter your email address.
              </Typography>
              <Alert severity="success" style={{ display: this.state.restPasswordshow ? 'block' : 'none' }}>
                <AlertTitle>                The new password has been sent to your email!
</AlertTitle>
              </Alert>


              <Alert severity="error" style={{ display: this.state.restPasswordshowError ? 'block' : 'none' }}>
                <AlertTitle>                There is no account with that email address.
</AlertTitle>
              </Alert>
               
              <Button
                variant="contained"
                color="primary"
                className="w-full mx-auto mt-16"
                aria-label="Go to the login page"
                type="button"
                onClick={() => this.props.history.push('/frontend')}
                style={{ display: this.state.restPasswordshow ? 'block' : 'none' }}
              >
                Go to the login page
                </Button>
              <br></br>

              <form
                name="loginForm"
                noValidate
                className="flex flex-col justify-center w-full"

                method="post"
                style={{ display: this.state.restPasswordshow ? 'none' : 'black' }}
              >
                <TextField
                  className="mb-16"
                  label="Email"
                  autoFocus
                  type="email"
                  name="email"
                  value={this.state.email}
                  onChange={(e) => {
                    this.handleChange("email", e);
                  }}
                  variant="outlined"
                  required
                  fullWidth
                />

                <div className="flex items-center justify-between"></div>

                <Button
                  variant="contained"
                  color="primary"
                  className="w-full mx-auto mt-16"
                  aria-label="LOG IN"
                  disabled={this.state.formValid}
                  type="button"
                  onClick={() => this.handleSubmit()}
                >
                  Get new password
                </Button>
              </form>
            </CardContent>
          </Card>
        </FuseAnimate>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}
export default connect(mapStateToProps)(Login);
