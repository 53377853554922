import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio, Select } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import Checkbox from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Formsy from "formsy-react";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";

const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "Focus on Homepage ",
    link: ``,
  },
];

class Add extends Component {
  state = {
    formValid: false,
    disabled: false,
    headerType: 'color',
    hexcode: '#fff',
    hexcode2: '#fff',
    headerImage: '',
    bodyImage: '',
    alertOpen: false,
    bodyType: 'color',
    focus: 'songs' // stories, shows, games
  };
  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
  };
  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.checked,
    });
  };
  handleSubmit = (model) => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
 
    formData.append("focus", this.state.focus); 

    axios({
      url: `${backend_url}/focus/update`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      if (res.data.message)
        this.setState({ alertOpen: true })

      setTimeout(() => {
        this.setState({ alertOpen: false })
      }, 3000);
    });
  };

  componentDidMount() {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    axios({
      url: `${backend_url}/focus/get`,
      method: "get",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.setState({
        focus: res.data.value,
      }) 
    });
  }
  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };

  fileUpload = (e, id) => {
    let image = document.getElementById(id);
    image.src = URL.createObjectURL(e.target.files[0]);
    this.setState({
      [id]: e.target.files[0],
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="w-full flex items-center justify-between p-24">
            <h2>Focus on Homepage</h2>
            <div className="flex items-center">

            </div>
          </div>


        }
        content={
          <div className="p-24">
            <div className="max-w-lg">
              <Formsy
                onValidSubmit={this.handleSubmit}
                onValid={this.enableButton}
                onInvalid={this.disableButton}
                ref={"cateogry_add"}
                className="flex flex-col p6-12"
              >

                <div style={{
                  color: '#000',
                  backgroundColor: '#ddffdd',
                  border: '1px solid #ccc',
                  padding: '1vw',
                  visibility: this.state.alertOpen ? 'visible' : 'hidden',
                  opacity: this.state.alertOpen ? '1' : '0',
                  transition: 'opacity 1s'

                }}>
                  <h3>Saved successfully!</h3>
                </div>



                <div className="flex flex-row justify-between py-10">
                  <SelectFormsy
                    className="w-5/12"
                    name="Focus"
                    label="Focus on Homepage"
                    value={this.state.focus}
                    onChange={(e) => {
                      this.setState({
                        focus: e.target.value,
                      });
                      console.log(this.state.hexcode);
                    }}
                    variant="outlined" >
                    <MenuItem value="songs">Songs</MenuItem>
                    <MenuItem value="stories">Stories</MenuItem>
                    <MenuItem value="shows">Shows</MenuItem>
                    <MenuItem value="games">Games</MenuItem>

                  </SelectFormsy>

                </div>
 



                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="ml-auto my-16"
                  aria-label="Add"
                  disabled={!this.state.formValid}
                >
                  Save
                </Button>


              </Formsy>
            </ div>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(Add);
