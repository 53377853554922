import Dashboard from './dashboard';

export const DashboardConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [ 
        {
            path     : `${process.env.REACT_APP_FRONT_URL}dashboard`,
            component: Dashboard
        }
    ]
};