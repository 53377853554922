import React, { Component } from 'react'
// import { connect } from "react-redux";
import axios from 'axios'
// import Router, { useRouter, withRouter } from "next/router";
import moment from 'moment'
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Dialog from '@material-ui/core/Dialog';
// import BookHighlights from '../../../components/BookReader/BookHighlights'
// import BookAudios from '../../../components/BookReader/BookAudios'
import Slider from '@material-ui/core/Slider';
import { isSafari, isEdge } from "react-device-detect";
// import { i18n } from '../../../i18n/i18n';
// import VocabularyModal from '../../../components/BookReader/VocabularyModal';
// import DictionaryModal from '../../../components/BookReader/DictionaryModal';
// import DiscardRecordModal from '../../../components/BookReader/DiscardRecordModal'
import _ from 'lodash'
// import finishedAnimation from '../../../assets/book_finished.json'
// import notfinishedAnimation from '../../../assets/book_notfinished.json'
//import Lottie from 'react-lottie';
// import reloaderAnimation from '../../../assets/preloader.json';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

const screenWidth = "";

class Read extends Component {
  constructor() {

    super()
    
    this.state = {
      book: {},
      bookPagesFlipped: 0,
      bookData: {},
      bookMarginTop: 0,
      bookMarginLeft: 0,
      showMenu: true,
      currentPage: 0,
      currentPageUrl: '',
      buttonIcon: 'microphone-alt',
      bookBaseUrl: '',
      scale: 1,
      menuActive: false,
      drawing: false,
      bookFinished: false,
      currentMode: null,
      showSelectionPopup: false,
      showColorPopup: false,
      numberOfPages: null,
      message: '',
      textMode: true,
      selectionPopupHavePlayBtn: false,
      selectedPolygonId: '',
      selectedPolygonHtml: '',
      showColorPopup: false,
      selectedHighlightId: '',
      selectedHighlighthtml: '',
      selectedHighlightPage: '',
      selectionPopupHaveShareBtn: false,
      selectionPopupShareBtnEnable: false,
      studentReadingTimer: null,
      originalReadingTimer: null,
      originalModeFinished: false,
      studentModeFinished: false,
      selectionPopupPlaying: false,
      popupType: 'text',
      currentTime: 0,
      drawingCurrentTime: 0,
      currentOrientation: 'landscape',
      highlightsPopupOpen: false,
      recordsPopupOpen: false,
      mainRecordExist: 'noMainFile',
      mainRecordPlayButton: 'play',
      mainRecordCurrentPlay: 0,
      playForMeMode: false,
      currentDrawingAudioData: '',
      allowMic: false,
      recordingStatus: 'notStarted',
      recordingMode: false,
      showMainRecorder: false,
      bookLoaded: false,
      bookMedia: [],
      showMainNoteDialog: false,
      bookTop: 0,
      bookLeft: 0,
      popup: {
        x: 0,
        y: 0
      },
      popupPosition: 'bottom',
      finishedProgress: {},
      bookOrigin: '',
      pagesProgress: '0%',
      shareMainRecordLoader: 'none',
      vocabularyShared: false,
      showVocabularies: true,
      enableVocabulary: true,
      enableHighlight: true,

      currentSelectedText: '',
      shareMainRecordLoader: 'none',
      shareHighlightLoader: 'none',
      shareVocabularyLoader: 'none',
      layoutChanged: false,
      discardRecordModal: false,
      notSharedModal: false,
      fontSize: 18,
      fontSizePX: '18px',
      reflowableCurrentProgress: 0,
      reflowableFinished: false,
      micDisabledDialog: false,
      historyNumber: null,
      lang:"en"
    }
    this.mediaRecorder = '';
    this.mainRecordPlayer = ''
    this.drawingRecorder = '';
    this.iframeWrapper = '';
    this.reflowableRendition = '';
    this.reflowableBook = null;
  }
  componentWillMount() {
    // let currentMode = this.props.router.query.mode
    // this.setState({
    //   currentMode
    // })


  }

  componentWillUnmount() {
    document.querySelector('.page-wrapper').classList.remove('book-page');
    window.removeEventListener('message', this._onMessage);
  }



  componentDidUpdate(prevProps, prevState) {

    if (prevState.currentPage != this.state.currentPage) {
      console.log('1111111111', this.state.currentPage + 1, this.state.numberOfPages)
      this.setState({
        pagesProgress: ((this.state.currentPage + 1) / this.state.numberOfPages * 100) + '%'
      })
    }
  }
  _onResize = () => {
    console.log('resize')
  }
  componentDidMount() {

    document.querySelector('.page-wrapper').classList.add('book-page');

    let message = {
      status: false
    }
    // this.props.dispatch({ type: "TOGGLE_CURRENT_BOOK_PROGRESS_SAVED", message });



    this.setState({
      currentMode:'original'
    })


    const formData1 = new FormData();
    formData1.append('id', this.props.router.query.bookId);
    this.setState({
      historyNumber: window.history.length
    })


    axios.post('https://school.kutubee.com:4000/book/app/get', formData1)
      .then(res => {
        const bookData = res.data[0];

        if(res.data[0].language !== undefined) {
          this.setState({
            lang:res.data[0].language
          })
        }

        console.log('ssssssssss', res.data[0])
        if (res.data[0].bookSource != 'reflowable') {
          window.addEventListener('message', this._onMessage);
          let dataJsonUrl = 'https://school.kutubee.com:4000/tmp/' + this.props.router.query.bookId + '_tmp/OEBPS/data.json'

          fetch(dataJsonUrl).then(res => res.json()).then((resdata =>{
            let dataJson = {};
            dataJson.data = resdata; 
          // }))
          // axios.get(dataJsonUrl,{
          //   headers:{
          //     Accept:"*/*",
          //     Connection:"keep-alive",
          
          //   }
          // })
          //   .then(dataJson => {
              const formData = new FormData();
              formData.append('id', this.props.router.query.bookId);

              let bookStorageData = localStorage.getItem(this.props.loggedInUser.userData._id + '_' + this.props.router.query.bookId);

              if (bookStorageData == undefined || bookStorageData == null) {
                let pagesHighlight = [];
                dataJson.data.pages.forEach(page => {
                  pagesHighlight.push({ highlights: [], drawings: [], vocabularies: [], });
                });
                dataJson.data.mainRecord = {}
                dataJson.data.mainNote = ''
                dataJson.data.generalVocabularies = []
                localStorage.setItem(this.props.loggedInUser.userData._id + '_' + this.props.router.query.bookId, JSON.stringify({ pages: pagesHighlight, mainNote: '', mainRecord: {}, generalVocabularies: [], }));
                this.setState({
                  bookMedia: { pages: pagesHighlight, mainNote: '', mainRecord: {}, generalVocabularies: [], }
                })
              }

              if (bookStorageData != undefined || bookStorageData != null) {
                bookStorageData = JSON.parse(bookStorageData)
                dataJson.data.pages.forEach((page, index) => {
                  console.log('bookStorageData.pages[index].highlights', bookStorageData.pages[index].highlights)
                  page.highlights = bookStorageData.pages[index].highlights
                  page.drawings = bookStorageData.pages[index].drawings
                  page.vocabularies = bookStorageData.pages[index].vocabularies
                })
                console.log('sssdddddd22222', dataJson.data)
                dataJson.data.mainRecord = {}
                bookStorageData.pages.forEach(page => {
                  if (page.vocabularies == undefined) {
                    page.vocabularies = []
                  }
                  page.drawings.forEach((drawing, index) => {
                    delete drawing.record
                    delete drawing.saveDate
                    delete drawing.sharedDate
                    delete drawing.shared
                    delete drawing.duration
                    drawing.audioAttached = false
                  })
                })
                dataJson.data.mainNote = bookStorageData.mainNote
                if (bookStorageData.generalVocabularies == undefined) {
                  bookStorageData.generalVocabularies = []
                }
                dataJson.data.generalVocabularies = bookStorageData.generalVocabularies


                this.setState({
                  bookMedia: bookStorageData,
                })
              }
              console.log(this.bookWrapper.offsetWidth,this.bookWrapper.offsetHeight)
              this.setState({
                bookData: bookData,
                book: dataJson.data,
                numberOfPages: dataJson.data.pages.length,
                currentPageUrl: `https://school.kutubee.com:4000/tmp/${this.props.router.query.bookId}_tmp/OEBPS${dataJson.data.pages[this.state.currentPage].pagePath}`,
              }, this.getScale(bookData), this.bookWrapper.offsetWidth, this.bookWrapper.offsetHeight)
              this.setState({
                studentReadingTimer: 0,
                originalReadingTimer: 0,
              })
              let bookProgressData = this.props.userProgress.find(book => book.bookId == this.props.router.query.bookId);
              if (bookProgressData != undefined) {
                this.setState({
                  bookPagesFlipped: bookProgressData.data.pagesFlipped,
                  studentReadingTimer: bookProgressData.data.studentModeTime,
                  originalReadingTimer: bookProgressData.data.originalModeTime,
                  originalModeFinished: bookProgressData.data.originalModeFinished,
                  studentModeFinished: bookProgressData.data.studentModeFinished,
                })
              }
              // this._bookTimer();
//              this.voiceRecord()
              this.setState({
                bookLoaded: true
              })
            }))
        } else {
          // let bookStorageData = localStorage.getItem(this.props.router.query.bookId);

          // if (bookStorageData == undefined || bookStorageData == null) {
          //   localStorage.setItem(this.props.router.query.bookId, JSON.stringify({ pages: [], mainNote: '', mainRecord: {}, generalVocabularies: [], }));
          //   this.setState({
          //     bookMedia: { pages: [], mainNote: '', mainRecord: {}, generalVocabularies: [], }
          //   })
          // }



        }
      })
  }
  _bookTimer() {
   // let self = this
    // if (this.state.currentMode == 'student') {
    //   this.timer = setInterval(() => {
    //     self.setState({
    //       studentReadingTimer: self.state.studentReadingTimer + 1
    //     })
    //   }, 1000);
    // } else if (this.state.currentMode == 'original') {
    //   this.timer = setInterval(() => {
    //     self.setState({
    //       originalReadingTimer: self.state.originalReadingTimer + 1
    //     })
    //   }, 1000);
    // }
  }
  increaseFontSize() {
    if (this.state.fontSize <= 35) {
      this.setState({
        fontSize: this.state.fontSize + 1,
        fontSizePX: `${this.state.fontSize + 1}px`
      })
      this.reflowableRendition.themes.fontSize(`${this.state.fontSize + 1}px`);
    }
  }
  decreaseFontSize() {
    if (this.state.fontSize >= 14) {
      this.setState({
        fontSize: this.state.fontSize - 1,
        fontSizePX: `${this.state.fontSize - 1}px`
      })
      this.reflowableRendition.themes.fontSize(`${this.state.fontSize - 1}px`);
    }
  }
  getScale(bookData) {
    console.log('ssss',this.bookWrapper.offsetHeight,this.bookWrapper.offsetWidth)
    var bookWidth = parseInt(bookData.pageWidth);
    var bookHeight = parseInt(bookData.pageHeight);
    var iframeHeight = (this.bookWrapper.offsetHeight);
    var iframeWidth = this.bookWrapper.offsetWidth;
    var scale = iframeHeight / bookHeight;

    var widthRatio = iframeWidth / (bookWidth * 2);
    var heightRatio = iframeHeight / bookHeight;
    var min = Math.min(widthRatio, heightRatio);
    // console.log('min',min);
    var newwidth = Math.max(bookWidth * 2, iframeWidth / min);
    var newheight = Math.max(bookHeight, iframeHeight / min);
    var bookMarginTop = 0
    var bookMarginLeft = 0
    var bookLeft = 0;
    var bookTop = 0;
    var bookOrigin = ''
    if (widthRatio > heightRatio) {
      if (min < 1) {
        // console.log('widthRatiobigger than heightRatio',widthRatio,heightRatio,(1 - min) * newwidth / 2)
        bookMarginLeft = (((min - 1) * newwidth / 2) * min) / 2;
        bookLeft = (iframeWidth - ((bookWidth * 2) * min)) / 2
        bookTop = 0
        bookOrigin = 'top right'
      } else {
        // min=1
        bookMarginLeft = 0
        bookWidth = '100%'
        bookHeight = '100%'
        bookOrigin = 'center center'
      }
    } else {
      // console.log('h than wRatio')
      // if(min<1){
      // bookMarginTop = (((1 - min) * newheight / 2) * min )/2
      // }else{
      // bookMarginTop=0
      // }
      // if(iframeWidth >= bookWidth*2){
      //   bookWidth = '100%'
      // }
      if (min < 1) {
        // // console.log('widthRatiobigger than heightRatio',widthRatio,heightRatio,(1 - min) * newwidth / 2)
        bookMarginTop = (((1 - min) * newheight) * min) / 2
        bookLeft = 0
        bookTop = (iframeHeight - ((bookHeight) * min)) / 2
        bookOrigin = 'top right'
      } else {
        // min=1
        bookMarginLeft = 0
        bookWidth = '100%'
        bookHeight = '100%'
        bookOrigin = 'center'
      }
    }


    // if(scale>1){
    //   min =1;
    //   bookWidth='100%'
    //   bookHeight='100%'
    // }
    // if(scale<1){
    //   min =1;
    // }
    this.setState({
      scale: min,
      bookWidth,
      bookHeight,
      bookMarginLeft,
      bookMarginTop,
      bookTop,
      bookLeft,
      bookOrigin
    })
  }
  _nextPage() {
    if (this.state.currentPage > 0) {
      if (this.state.currentOrientation == 'landscape') {
        if (this.state.currentPage == this.state.book.pages.length - 1) {
          if (this.state.currentPage % 2 != 0) {
            this.setState({
              currentPage: this.state.currentPage - 1,
              currentPageUrl: `https://school.kutubee.com:4000/tmp/${this.props.router.query.bookId}_tmp/OEBPS${this.state.book.pages[this.state.currentPage - 1].pagePath}`,
              // currentPageAudios:this.state.book.pages[this.state.currentPage-1].smilAudios,
              scale: false,
              showSelectionPopup: false,
              selectionPopupHaveShareBtn: false,
              showColorPopup: false
            })
            this.setState({
              currentPageAudios: {
                page1: this.state.book.pages[this.state.currentPage - 2].smilAudios,
                page2: this.state.book.pages[this.state.currentPage - 1].smilAudios,
              },
              currentPageAudiosMode: {
                page1: this.state.book.pages[this.state.currentPage - 2].pageAudioType,
                page2: this.state.book.pages[this.state.currentPage - 1].pageAudioType,
              }
            })
          } else {
            this.setState({
              currentPage: this.state.currentPage - 2,
              currentPageUrl: `https://school.kutubee.com:4000/tmp/${this.props.router.query.bookId}_tmp/OEBPS${this.state.book.pages[this.state.currentPage - 2].pagePath}`,
              // currentPageAudios:this.state.book.pages[this.state.currentPage-2].smilAudios,
              scale: false,
              showSelectionPopup: false,
              selectionPopupHaveShareBtn: false,
              showColorPopup: false
            })
            this.setState({
              currentPageAudios: {
                page1: this.state.book.pages[this.state.currentPage - 3].smilAudios,
                page2: this.state.book.pages[this.state.currentPage - 2].smilAudios,
              },
              currentPageAudiosMode: {
                page1: this.state.book.pages[this.state.currentPage - 3].pageAudioType,
                page2: this.state.book.pages[this.state.currentPage - 2].pageAudioType,
              }
            })
          }
        } else {
          if (this.state.book.pages[this.state.currentPage - 2] != undefined) {
            this.setState({
              currentPage: this.state.currentPage - 2,
              currentPageUrl: `https://school.kutubee.com:4000/tmp/${this.props.router.query.bookId}_tmp/OEBPS${this.state.book.pages[this.state.currentPage - 2].pagePath}`,
              // currentPageAudios:this.state.book.pages[this.state.currentPage-2].smilAudios,
              scale: false,
              showSelectionPopup: false,
              selectionPopupHaveShareBtn: false,
              showColorPopup: false
            })
            // console.log('sdsdsd',this.state.currentPage-2)

            if (this.state.currentPage - 2 != 0) {
              this.setState({
                currentPageAudios: {
                  page1: this.state.book.pages[this.state.currentPage - 3].smilAudios,
                  page2: this.state.book.pages[this.state.currentPage - 2].smilAudios,
                },
                currentPageAudiosMode: {
                  page1: this.state.book.pages[this.state.currentPage - 3].pageAudioType,
                  page2: this.state.book.pages[this.state.currentPage - 2].pageAudioType,
                }
              })
            }
          }
        }
      } else {
        // if(screenWidth > this.props.bookData.pageWidth){
        //   if(this.state.currentOrientation == 'portrait'){
        //     this.setState({
        //       scale:true,
        //     })
        //   }else{
        //     this.setState({
        //       scale:true,
        //     })
        //   }
        // }else{
        //   this.setState({
        //     scale:false,
        //   })
        // }
        this.setState({
          currentPage: this.state.currentPage - 1,
          currentPageUrl: `https://school.kutubee.com:4000/tmp/${this.props.router.query.bookId}_tmp/OEBPS${this.state.book.pages[this.state.currentPage - 1].pagePath}`,
          // currentPageAudios:this.state.book.pages[this.state.currentPage-1].smilAudios,
          showSelectionPopup: false,
          selectionPopupHaveShareBtn: false,
          showColorPopup: false
        })
        if (this.state.currentPage - 1 == this.state.book.pages.length - 1) {
          this.setState({
            currentPageAudios: {
              page1: this.state.book.pages[this.state.currentPage].smilAudios,
              page2: null,
            }
          })
        } else {
          if ((this.state.currentPage - 1) % 2 == 0) {
            if (this.state.currentPage - 1 == 0) {
              this.setState({
                currentPageAudios: {
                  page1: this.state.book.pages[this.state.currentPage - 1].smilAudios,
                  page2: null,
                },
                currentPageAudiosMode: {
                  page1: this.state.book.pages[this.state.currentPage - 1].pageAudioType,
                  page2: null,
                }
              })
            } else {
              this.setState({
                currentPageAudios: {
                  page1: this.state.book.pages[this.state.currentPage - 2].smilAudios,
                  page2: this.state.book.pages[this.state.currentPage - 1].smilAudios,
                },
                currentPageAudiosMode: {
                  page1: this.state.book.pages[this.state.currentPage - 2].pageAudioType,
                  page2: this.state.book.pages[this.state.currentPage - 1].pageAudioType,
                }
              })
            }
          } else {
            this.setState({
              currentPageAudios: {
                page1: this.state.book.pages[this.state.currentPage - 1].smilAudios,
                page2: this.state.book.pages[this.state.currentPage].smilAudios,
              },
              currentPageAudiosMode: {
                page1: this.state.book.pages[this.state.currentPage - 1].pageAudioType,
                page2: this.state.book.pages[this.state.currentPage].pageAudioType,
              }
            })
          }
        }
      }
    }
  }
  _prevPage() {
    // this.setState({
    //   currentPage:this.state.currentPage-2,
    //   currentPageUrl:`https://school.kutubee.com:4000/tmp/${this.props.router.query.bookId}_tmp/OEBPS${this.state.book.pages[this.state.currentPage-2].pagePath}`
    // })
    if (this.state.currentPage < this.state.book.pages.length - 1) {
      if (this.state.currentOrientation == 'landscape') {
        if (this.state.book.pages[this.state.currentPage + 2] != undefined) {
          this.setState({
            currentPage: this.state.currentPage + 2,
            currentPageUrl: `https://school.kutubee.com:4000/tmp/${this.props.router.query.bookId}_tmp/OEBPS${this.state.book.pages[this.state.currentPage + 2].pagePath}`,
            // currentPageAudios:this.state.book.pages[this.state.currentPage+2].smilAudios,
            scale: false,
            showSelectionPopup: false,
            showColorPopup: false,
            selectionPopupHaveShareBtn: false,
          })
          this.setState({
            currentPageAudios: {
              page1: this.state.book.pages[this.state.currentPage + 1].smilAudios,
              page2: this.state.book.pages[this.state.currentPage + 2].smilAudios,
            },
            currentPageAudiosMode: {
              page1: this.state.book.pages[this.state.currentPage + 1].pageAudioType,
              page2: this.state.book.pages[this.state.currentPage + 2].pageAudioType,
            }
          })
        } else {
          this.setState({
            currentPage: this.state.currentPage + 1,
            currentPageUrl: `https://school.kutubee.com:4000/tmp/${this.props.router.query.bookId}_tmp/OEBPS${this.state.book.pages[this.state.currentPage + 1].pagePath}`,
            // currentPageAudios:this.state.book.pages[this.state.currentPage+1].smilAudios,
            scale: false,
            showSelectionPopup: false,
            showColorPopup: false,
            selectionPopupHaveShareBtn: false,
          })
          this.setState({
            currentPageAudios: {
              page1: this.state.book.pages[this.state.currentPage + 1].smilAudios,
              page2: null,
            },
            currentPageAudiosMode: {
              page1: this.state.book.pages[this.state.currentPage + 1].pageAudioType,
              page2: null,
            }
          })
        }
      } else {
        if (screenWidth > this.props.bookData.pageWidth) {
          if (this.state.currentOrientation == 'portrait') {
            this.setState({
              scale: true,
            })
          } else {
            this.setState({
              scale: true,
            })
          }
        } else {
          this.setState({
            scale: false,
          })
        }
        this.setState({
          currentPage: this.state.currentPage + 1,
          currentPageUrl: `https://school.kutubee.com:4000/tmp/${this.props.router.query.bookId}_tmp/OEBPS${this.state.book.pages[this.state.currentPage + 1].pagePath}`,
          // currentPageAudios:this.state.book.pages[this.state.currentPage+1].smilAudios,
          showSelectionPopup: false,
          showColorPopup: false,
          selectionPopupHaveShareBtn: false,
        })
        if (this.state.currentPage + 1 == this.state.book.pages.length - 1) {
          this.setState({
            currentPageAudios: {
              page1: this.state.book.pages[this.state.currentPage].smilAudios,
              page2: null,
            },
            currentPageAudiosMode: {
              page1: this.state.book.pages[this.state.currentPage].pageAudioType,
              page2: null,
            }
          })
        } else {
          if ((this.state.currentPage + 1) % 2 == 0) {
            this.setState({
              currentPageAudios: {
                page1: this.state.book.pages[this.state.currentPage].smilAudios,
                page2: this.state.book.pages[this.state.currentPage + 1].smilAudios,
              },
              currentPageAudiosMode: {
                page1: this.state.book.pages[this.state.currentPage].pageAudioType,
                page2: this.state.book.pages[this.state.currentPage + 1].pageAudioType,
              }
            })
          } else {
            this.setState({
              currentPageAudios: {
                page1: this.state.book.pages[this.state.currentPage + 1].smilAudios,
                page2: this.state.book.pages[this.state.currentPage].smilAudios,
              },
              currentPageAudiosMode: {
                page1: this.state.book.pages[this.state.currentPage + 1].pageAudioType,
                page2: this.state.book.pages[this.state.currentPage].pageAudioType,
              }
            })
          }
        }

      }
    } else if (this.state.currentPage >= this.state.book.pages.length - 1) {
      
    }
  }
  _loadPageData() {
    if (this.state.currentPage == 0) {
      this.sendMessage(JSON.stringify({
        message: 'audiosJson', smilAudios: {
          page1: this.state.book.pages[this.state.currentPage].smilAudios,
          page2: []
        },
        modes: {
          page1: this.state.book.pages[this.state.currentPage].pageAudioType,
          page2: ''
        }
      }))
      this.sendMessage(JSON.stringify({
        message: 'addSvgs', svgs: {
          page1: this.state.bookMedia.pages[this.state.currentPage].drawings,
          page2: []
        }
      }))
      this.sendMessage(JSON.stringify({
        message: 'addHighlights',
        highlights: {
          page1: this.state.bookMedia.pages[this.state.currentPage].highlights,
          page2: []
        },
        vocabularies: {
          page1: this.state.bookMedia.pages[this.state.currentPage].vocabularies,
          page2: []
        }
      }))
    } else if (this.state.currentPage % 2 == 0 && this.state.currentPage != 0 && this.state.currentPage != this.state.book.pages.length - 1) {
      this.sendMessage(JSON.stringify({
        message: 'audiosJson', smilAudios: {
          page1: this.state.book.pages[this.state.currentPage - 1].smilAudios,
          page2: this.state.book.pages[this.state.currentPage].smilAudios
        },
        modes: {
          page1: this.state.book.pages[this.state.currentPage - 1].pageAudioType,
          page2: this.state.book.pages[this.state.currentPage].pageAudioType,
        }
      }))
      this.sendMessage(JSON.stringify(
        {
          message: 'addSvgs',
          svgs: {
            page1: this.state.bookMedia.pages[this.state.currentPage - 1].drawings,
            page2: this.state.bookMedia.pages[this.state.currentPage].drawings
          }
        }
      ))
      this.sendMessage(JSON.stringify({
        message: 'addHighlights',
        highlights: {
          page1: this.state.bookMedia.pages[this.state.currentPage - 1].highlights,
          page2: this.state.bookMedia.pages[this.state.currentPage].highlights,
        },
        vocabularies: {
          page1: this.state.bookMedia.pages[this.state.currentPage - 1].vocabularies,
          page2: this.state.bookMedia.pages[this.state.currentPage].vocabularies
        }
      }))
    } else if (this.state.currentPage % 2 != 0 && this.state.currentPage != 0 && this.state.currentPage != this.state.book.pages.length - 1) {
      this.sendMessage(JSON.stringify({
        message: 'audiosJson', smilAudios: {
          page1: this.state.book.pages[this.state.currentPage].smilAudios,
          page2: this.state.book.pages[this.state.currentPage + 1].smilAudios
        },
        modes: {
          page1: this.state.book.pages[this.state.currentPage].pageAudioType,
          page2: this.state.book.pages[this.state.currentPage + 1].pageAudioType,
        }
      }))
      this.sendMessage(JSON.stringify(
        {
          message: 'addSvgs',
          svgs: {
            page1: this.state.bookMedia.pages[this.state.currentPage].drawings,
            page2: this.state.bookMedia.pages[this.state.currentPage + 1].drawings
          }
        }
      ))
      this.sendMessage(JSON.stringify({
        message: 'addHighlights',
        highlights: {
          page1: this.state.bookMedia.pages[this.state.currentPage].highlights,
          page2: this.state.bookMedia.pages[this.state.currentPage + 1].highlights,
        },
        vocabularies: {
          page1: this.state.bookMedia.pages[this.state.currentPage].vocabularies,
          page2: this.state.bookMedia.pages[this.state.currentPage + 1].vocabularies
        }
      }))
    } else if (this.state.currentPage == this.state.book.pages.length - 1) {
      if (this.state.currentPage % 2 == 0) {
        this.sendMessage(JSON.stringify({
          message: 'audiosJson', smilAudios: {
            page1: this.state.book.pages[this.state.currentPage - 1].smilAudios,
            page2: this.state.book.pages[this.state.currentPage].smilAudios
          },
          modes: {
            page1: this.state.book.pages[this.state.currentPage - 1].pageAudioType,
            page2: this.state.book.pages[this.state.currentPage].pageAudioType,
          }
        }))
        this.sendMessage(JSON.stringify(
          {
            message: 'addSvgs',
            svgs: {
              page1: this.state.bookMedia.pages[this.state.currentPage - 1].drawings,
              page2: this.state.bookMedia.pages[this.state.currentPage].drawings
            }
          }
        ))
        this.sendMessage(JSON.stringify({
          message: 'addHighlights',
          highlights: {
            page1: this.state.bookMedia.pages[this.state.currentPage - 1].highlights,
            page2: this.state.bookMedia.pages[this.state.currentPage].highlights,
          },
          vocabularies: {
            page1: this.state.bookMedia.pages[this.state.currentPage - 1].vocabularies,
            page2: this.state.bookMedia.pages[this.state.currentPage].vocabularies
          }
        }))
      } else {
        this.sendMessage(JSON.stringify({
          message: 'audiosJson', smilAudios: {
            page1: this.state.book.pages[this.state.currentPage].smilAudios,
            page2: []
          },
          modes: {
            page1: this.state.book.pages[this.state.currentPage].pageAudioType,
            page2: '',
          }
        }))
        this.sendMessage(JSON.stringify(
          {
            message: 'addSvgs',
            svgs: {
              page1: this.state.bookMedia.pages[this.state.currentPage].drawings,
              page2: []
            }
          }
        ))
        this.sendMessage(JSON.stringify({
          message: 'addHighlights',
          highlights: {
            page1: this.state.bookMedia.pages[this.state.currentPage].highlights,
            page2: []
          },
          vocabularies: {
            page1: this.state.bookMedia.pages[this.state.currentPage].vocabularies,
            page2: []
          }
        }))
      }
    }
  }

  _closeRecordingMode() {
    this.setState({
      recordingMode: false,
      showMenu: false,
      menuActive: false,
      showSelectionPopup: false,
      showMainRecorder: false,
      showColorPopup: false,
      selectionPopupHaveShareBtn: false,
      recordingStatus: 'notStarted'
    });
  }

  _onPageLoad() {

    console.log("_onPageLoad")
    // // console.log(this.iframe.contentWindow);
    this.sendMessage(JSON.stringify({ message: 'currentPageNumber', page: this.state.currentPage, lastPage: this.state.book.pages.length - 1 }))
    this.sendMessage(JSON.stringify({ message: 'currentMode', mode: this.state.currentMode }))
    if (this.state.bookData.language != undefined) {
      this.sendMessage(JSON.stringify({ message: 'language', bookLang: this.state.bookData.language }))
    }
    this.sendMessage(JSON.stringify({ message: 'drawingModeActive', drawingMode: this.state.drawing }))
    if (this.props.loggedInUser.userData.type != 'individual') {
      this.sendMessage(JSON.stringify({ message: 'textModeActive', textMode: this.state.textMode }))
    }
    this._loadPageData()
    if (this.state.bookPagesFlipped <= this.state.currentPage + 1) {
      this.setState({
        bookPagesFlipped: this.state.currentPage + 1
      })
    }
    if (this.state.playForMeMode && this.state.currentMode == 'original') {
      if (this.state.currentPage != 0) {

        setTimeout(() => {
          this.sendMessage(JSON.stringify({ message: 'play', smilAudios: this.state.currentPageAudios }))
        }, 300);
      } else {
        setTimeout(() => {
          this.sendMessage(JSON.stringify({ message: 'play', smilAudios: this.state.currentPageAudios }))
        }, 1000);
      }


    }
  }
  sendMessage(message) {
    console.log(this.iframe);
    if (this.iframe != null) {
      this.iframe.contentWindow.postMessage(message, '*')
    } else {
      // console.log('else message')
      if (document.getElementById('book-iframe') != null) {
        document.getElementById('book-iframe').contentWindow.postMessage(message, '*')
      }
    }
  }
  _goBack() {
    console.log('ahmad',)


    if (this.state.mainRecordExist == 'mainFileExist' && this.state.bookMedia.mainRecord.shared != true) {
      if (this.props.loggedInUser.userData != undefined) {
        console.log('wwwwwwwwwwwwwwwwwww', this.props.loggedInUser.userData)
        if (this.props.loggedInUser.userData.type != 'individual') {
          this.setState({
            notSharedModal: true
          })
        } else {
          if (window.history.length != this.state.historyNumber) {
            window.history.go(this.state.historyNumber - window.history.length - 1)
          } else {
            // Router.back()
          }
        }
      } else {
        if (window.history.length != this.state.historyNumber) {
          window.history.go(this.state.historyNumber - window.history.length - 1)
        } else {
        //   Router.back()
        }
      }

    } else {
      if (window.history.length != this.state.historyNumber) {
        window.history.go(this.state.historyNumber - window.history.length - 1)
      } else {
        // Router.back()
      }
    }

  }
  _toggleDrawing() {
    this.sendMessage(JSON.stringify({ message: 'showSvg' }))

    this.setState({
      drawing: !this.state.drawing,
      showMenu: false,
      selectionPopupHaveShareBtn: false,
      showSelectionPopup: false,
      showColorPopup: false,
    });
  }

  _playMainAudio() {
    let self = this
    self.setState({
      mainRecordPlayButton: 'pause'
    })
    // console.log(self.mainRecordPlayer)
    self.mainRecordPlayer.play()

    self.mainRecordPlayer.onended = function () {
      self.setState({
        mainRecordPlayButton: 'play'
      })
    }
  }

  _pauseMainAudio() {
    this.mainRecordPlayer.pause();
    this.setState({
      mainRecordPlayButton: 'play'
    })
  }

  _onMessage = (e) => {
    if (e.origin !== "https://school.kutubee.com:4000")
      return

    let message = JSON.parse(e.data);
    // console.log(message);
    if (message.message == 'finishDrawing') {
      this.setState({
        selectedPolygonId: message.data.polygon.id,
        selectedPolygonHtml: message.data.polygon.html
      })
      let polygonPage = message.data.polygon.page
      let bookMedia = this.state.bookMedia
      if (this.state.currentPage == 0) {
        if (polygonPage == 1) {
          bookMedia.pages[this.state.currentPage].drawings.push({ polygonId: message.data.polygon.id, polygonHtml: message.data.polygon.html, audioAttached: false })
        }
      } else if (this.state.currentPage == bookMedia.pages.length - 1) {
        if (polygonPage == 1) {
          bookMedia.pages[this.state.currentPage].drawings.push({ polygonId: message.data.polygon.id, polygonHtml: message.data.polygon.html, audioAttached: false })
        }
      } else {
        if (this.state.currentPage % 2 != 0) {
          if (polygonPage == 1) {
            bookMedia.pages[this.state.currentPage].drawings.push({ polygonId: message.data.polygon.id, polygonHtml: message.data.polygon.html, audioAttached: false })
          } else if (polygonPage == 2) {
            if (bookMedia.pages[this.state.currentPage + 1] != undefined) {
              bookMedia.pages[this.state.currentPage + 1].drawings.push({ polygonId: message.data.polygon.id, polygonHtml: message.data.polygon.html, audioAttached: false })
            }
          }
        } else {
          if (polygonPage == 1) {
            bookMedia.pages[this.state.currentPage - 1].drawings.push({ polygonId: message.data.polygon.id, polygonHtml: message.data.polygon.html, audioAttached: false })
          } else if (polygonPage == 2) {
            if (bookMedia.pages[this.state.currentPage] != undefined) {
              bookMedia.pages[this.state.currentPage].drawings.push({ polygonId: message.data.polygon.id, polygonHtml: message.data.polygon.html, audioAttached: false })
            }
          }
        }
      }
      this.saveBookMedia(bookMedia, 'addDrawing')
      this.setState({
        bookMedia
      })
    } else if (message.message == 'showSelectionMenu') {
      if (this.state.currentMode == 'student') {
        if (message.data.type == 'polygon') {
          console.log('pressss', message.data)
          var popup = {}
          if (message.data.x <= 0) {
            popup.x = 0
          } else {
            popup.x = (message.data.x - 46) + (message.data.width / 2)
          }

          if (message.data.y - 60 <= 0) {
            popup.y = 0
          } else {
            popup.y = message.data.y - 60
          }
          if (((message.data.y - 60) - message.data.pageTop) <= 0) {
            popup.y = message.data.pageTop
          }
          if (message.data.audioAttached == true) {
            popup.x = (message.data.x - 60) + (message.data.width / 2)
            this.setState({
              selectionPopupHavePlayBtn: true,
              selectionPopupHaveShareBtn: true,
            })
            if (message.data.polygonId != undefined) {
              this.setState({
                selectedPolygonId: message.data.polygonId,
                selectedPolygonPage: message.data.page
              });
            }

            this._playAttachedAudio()
            let bookMedia = this.state.bookMedia
            let currentPageNumber;
            let self = this
            if (this.state.currentOrientation == 'landscape') {
              if (this.state.currentPage == 0) {
                currentPageNumber = this.state.currentPage
              } else if (this.state.currentPage == bookMedia.pages.length - 1) {
                if (this.state.currentPage % 2 != 0) {
                  if (message.data.page == 1) {
                    currentPageNumber = this.state.currentPage
                  } else {
                    currentPageNumber = this.state.currentPage + 1
                  }
                } else {
                  currentPageNumber = this.state.currentPage
                }
              } else {
                if (this.state.currentPage % 2 != 0) {
                  if (message.data.page == 1) {
                    currentPageNumber = this.state.currentPage
                  } else {
                    currentPageNumber = this.state.currentPage + 1
                  }
                } else {
                  if (message.data.page == 1) {
                    currentPageNumber = this.state.currentPage - 1
                  } else {
                    currentPageNumber = this.state.currentPage
                  }
                }
              }
            }
            let drawingIndex = bookMedia.pages[currentPageNumber].drawings.findIndex(function (drawing) {
              return drawing.polygonId == message.data.polygonId
            })
            if (drawingIndex != -1) {
              if (bookMedia.pages[currentPageNumber].drawings[drawingIndex].shared) {
                this.setState({
                  selectionPopupShareBtnEnable: false
                })
              } else {
                this.setState({
                  selectionPopupShareBtnEnable: true
                })

              }
            }

          } else if (message.data.audioAttached == false) {
            this.setState({
              selectionPopupHavePlayBtn: false,
              selectionPopupHaveShareBtn: false,
            })
          }
          this.setState({
            popup: popup,
            showSelectionPopup: true,
            popupType: message.data.type,
          })
          if (message.data.polygonId != undefined) {
            this.setState({
              selectedPolygonId: message.data.polygonId,
              selectedPolygonPage: message.data.page
            });
          }

          // setTimeout(() => {
          //   // console.log(this.state.selectionPopupLayout)
          //   // console.log(popup)
          //   this.setState({
          //     popup:popup,
          //   })
          // }, 200);
        } else {

          if (message.data.highlightId != undefined) {
            if (message.data.type == 'text') {
              console.log('teeeeeeeeeeeext', message)
              this.setState({
                selectedHighlightId: message.data.highlightId,
                selectedHighlightPage: message.data.page,
                selectionPopupHaveShareBtn: true,
                showColorPopup: true,
                vocabularyShared: false
              })
              var bookMedia = this.state.bookMedia;
              var selectedHighlight, highlightPage;
              highlightPage = message.data.page;

              if (this.state.currentPage == 0) {
                if (highlightPage == 1) {
                  selectedHighlight = bookMedia.pages[this.state.currentPage].highlights.find(highlight => {
                    return highlight.highlightId == this.state.selectedHighlightId
                  })
                  // console.log('firstpage highlightPage',highlightPage,selectedHighlight)
                }
              } else if (this.state.currentPage == bookMedia.pages.length - 1) {
                if (highlightPage == 1) {
                  selectedHighlight = bookMedia.pages[this.state.currentPage].highlights.find(highlight => {
                    return highlight.highlightId == this.state.selectedHighlightId
                  })
                }
              } else {
                if (this.state.currentPage % 2 != 0) {
                  if (highlightPage == 1) {
                    selectedHighlight = bookMedia.pages[this.state.currentPage].highlights.find(highlight => {
                      return highlight.highlightId == this.state.selectedHighlightId
                    })
                  } else if (highlightPage == 2) {
                    if (bookMedia.pages[this.state.currentPage + 1] != undefined) {
                      selectedHighlight = bookMedia.pages[this.state.currentPage + 1].highlights.find(highlight => {
                        return highlight.highlightId == this.state.selectedHighlightId
                      })
                    }
                  }
                } else {
                  // console.log(highlightPage,'highlgihtasd')
                  if (highlightPage == 1) {
                    selectedHighlight = bookMedia.pages[this.state.currentPage - 1].highlights.find(highlight => {
                      return highlight.highlightId == this.state.selectedHighlightId
                    })
                  } else if (highlightPage == 2) {
                    if (bookMedia.pages[this.state.currentPage] != undefined) {
                      selectedHighlight = bookMedia.pages[this.state.currentPage].highlights.find(highlight => {
                        return highlight.highlightId == this.state.selectedHighlightId
                      })
                    }
                  }
                }
              }
              console.log('qqqqqqqqqqqqq', selectedHighlight)
              if (selectedHighlight != undefined) {
                if (selectedHighlight.shared == false) {
                  this.setState({
                    selectionPopupShareBtnEnable: true,
                    enableVocabulary: true,
                    enableHighlight: true
                  })
                } else {
                  this.setState({
                    selectionPopupShareBtnEnable: false,
                    enableVocabulary: false,
                    enableHighlight: true
                  })
                }
              }
            } else if (message.data.type == 'vocabulary') {
              this.setState({
                selectedHighlightId: message.data.highlightId,
                selectedHighlightPage: message.data.page,
                selectionPopupHaveShareBtn: true,
                selectionPopupShareBtnEnable: true,
                showColorPopup: true
                // showSelectionPopup: true
              })

              var bookMedia = this.state.bookMedia;
              var selectedHighlight, highlightPage;
              highlightPage = message.data.page;

              if (this.state.currentPage == 0) {
                if (highlightPage == 1) {
                  selectedHighlight = bookMedia.pages[this.state.currentPage].highlights.find(highlight => {
                    return highlight.highlightId == this.state.selectedHighlightId
                  })
                  // console.log('firstpage highlightPage',highlightPage,selectedHighlight)
                }
              } else if (this.state.currentPage == bookMedia.pages.length - 1) {
                if (highlightPage == 1) {
                  selectedHighlight = bookMedia.pages[this.state.currentPage].highlights.find(highlight => {
                    return highlight.highlightId == this.state.selectedHighlightId
                  })
                }
              } else {
                if (this.state.currentPage % 2 != 0) {
                  if (highlightPage == 1) {
                    selectedHighlight = bookMedia.pages[this.state.currentPage].highlights.find(highlight => {
                      return highlight.highlightId == this.state.selectedHighlightId
                    })
                  } else if (highlightPage == 2) {
                    if (bookMedia.pages[this.state.currentPage + 1] != undefined) {
                      selectedHighlight = bookMedia.pages[this.state.currentPage + 1].highlights.find(highlight => {
                        return highlight.highlightId == this.state.selectedHighlightId
                      })
                    }
                  }
                } else {
                  // console.log(highlightPage,'highlgihtasd')
                  if (highlightPage == 1) {
                    selectedHighlight = bookMedia.pages[this.state.currentPage - 1].highlights.find(highlight => {
                      return highlight.highlightId == this.state.selectedHighlightId
                    })
                  } else if (highlightPage == 2) {
                    if (bookMedia.pages[this.state.currentPage] != undefined) {
                      selectedHighlight = bookMedia.pages[this.state.currentPage].highlights.find(highlight => {
                        return highlight.highlightId == this.state.selectedHighlightId
                      })
                    }
                  }
                }
              }
              if (selectedHighlight != undefined) {
                console.log('hhhhhhhhhhhhhh', selectedHighlight)
                if (selectedHighlight.shared == true) {
                  this.setState({
                    vocabularyShared: true,
                    enableVocabulary: true,
                    enableHighlight: false
                  })
                } else {
                  this.setState({
                    vocabularyShared: false,
                    enableVocabulary: true,
                    enableHighlight: true
                  })
                }
              }
            }
          } else {
            console.log('mmmmmmmmmmmmessage', message)
            let showVocabularies = true
            if (message.data.textString != undefined) {
              if ((message.data.textString.trim()).includes(' ') || message.data.textString.includes('↵')) {
                console.log('there is a spaaaaace')
                showVocabularies = false
              } else {
                console.log('no spaaaaace')
              }
            } else {
              showVocabularies = false
            }

            this.setState({
              showVocabularies,
              showColorPopup: true,
              showSelectionPopup: false,
              selectionPopupShareBtnEnable: true,
              currentSelectedText: message.data.textString != undefined ? message.data.textString : '',
              vocabularyShared: false,
              enableVocabulary: true,
              enableHighlight: true,
            })
            // this.setState({
            //   showColorPopup: true,
            //   showSelectionPopup: false
            // })
          }
          if (message.data.audioAttached != undefined) {
            this.setState({
              selectionPopupHavePlayBtn: true,
            })
          }
          var popup = {}
          if (message.data.x <= 0) {
            popup.x = 0
          } else {
            // if (this.state.showColorPopup == true) {
            popup.x = (message.data.x - 50) + (message.data.width / 2)
            // } else {
            let showVocabularies = true
            if (message.data.textString != undefined) {
              if ((message.data.textString.trim()).includes(' ') || message.data.textString.includes('↵')) {
                console.log('there is a spaaaaace')
                showVocabularies = false
              } else {
                console.log('no spaaaaace')
              }
            } else {
              showVocabularies = false
            }

            var showVocabulariesHeight = showVocabularies ? 66 : 33
            if ((message.data.y + message.data.height + 15) >= (parseInt(this.state.bookData.pageHeight) + message.data.pageTop) - showVocabulariesHeight) {
              this.setState({
                popupPosition: 'top'
              })
              popup.y = message.data.y - (message.data.height + 15 + showVocabulariesHeight)
              // popup.y = (parseInt(this.state.bookData.pageHeight) + message.data.pageTop - ((showVocabulariesHeight) + (message.data.height)))
            } else {
              this.setState({
                popupPosition: 'bottom'
              })
              popup.y = message.data.y + message.data.height + 15
            }

            // }

          }
          // popup.y = message.data.y - 60
          this.setState({
            popup: popup,
            popupType: message.data.type,
            selectionPopupHavePlayBtn: false,
          })

        }
      }
    } else if (message.message == 'hideSelectionMenu') {
      this.setState({
        showSelectionPopup: false,
        selectionPopupHavePlayBtn: false,
        selectedHighlightId: '',
        selectionPopupHaveShareBtn: false,
        showColorPopup: false,
      })
    } else if (message.message == 'newTextHighlight') {
      if (message.data.highlightType == 'highlight') {
        console.log('aaaaaaaaaahmad', message)
        let book = this.state.book
        let highlightPage = message.data.page;
        let bookMedia = this.state.bookMedia
        if (this.state.currentPage == 0) {
          // console.log('first page new highlight');
          if (highlightPage == 1) {
            // book.pages[this.state.currentPage].highlights.push(
            //   {
            //     textString:message.data.textString,
            //     highlightId:message.data.highlightId,
            //     highlightHtml:message.data.highlightHtml,
            //     shared:false,
            //     sharedDate:null,
            //     saveDate:moment().format('D.MM.YYYY'),
            //     audioAttached:false,
            //     color:message.data.color
            //   }
            // );
            bookMedia.pages[this.state.currentPage].highlights.push(
              {
                textString: message.data.textString,
                highlightId: message.data.highlightId,
                highlightHtml: message.data.highlightHtml,
                highlightBackendId: message.data.highlightId + '-' + this.state.currentPage,
                shared: false,
                sharedDate: null,
                saveDate: moment().format('D.MM.YYYY'),
                audioAttached: false,
                color: message.data.color
              }
            )
          }
        } else if (this.state.currentPage == this.state.book.pages.length - 1) {
          // console.log('last page new highlight');
          if (highlightPage == 1) {
            // book.pages[this.state.currentPage].highlights.push(
            //   {
            //     textString:message.data.textString,
            //     highlightId:message.data.highlightId,
            //     highlightHtml:message.data.highlightHtml,
            //     shared:false,
            //     sharedDate:null,
            //     saveDate:moment().format('D.MM.YYYY'),
            //     audioAttached:false,
            //     color:message.data.color
            //   }
            // );
            bookMedia.pages[this.state.currentPage].highlights.push(
              {
                textString: message.data.textString,
                highlightId: message.data.highlightId,
                highlightHtml: message.data.highlightHtml,
                highlightBackendId: message.data.highlightId + '-' + this.state.currentPage,
                shared: false,
                sharedDate: null,
                saveDate: moment().format('D.MM.YYYY'),
                audioAttached: false,
                color: message.data.color
              }
            );
          }
        } else {
          if (this.state.currentPage % 2 != 0) {
            // console.log('between page even new highlight');
            if (highlightPage == 1) {
              // book.pages[this.state.currentPage].highlights.push(
              //   {
              //     textString:message.data.textString,
              //     highlightId:message.data.highlightId,
              //     highlightHtml:message.data.highlightHtml,
              //     shared:false,
              //     sharedDate:null,
              //     saveDate:moment().format('D.MM.YYYY'),
              //     audioAttached:false,
              //     color:message.data.color
              //   }
              // );
              bookMedia.pages[this.state.currentPage].highlights.push(
                {
                  textString: message.data.textString,
                  highlightId: message.data.highlightId,
                  highlightHtml: message.data.highlightHtml,
                  highlightBackendId: message.data.highlightId + '-' + this.state.currentPage,
                  shared: false,
                  sharedDate: null,
                  saveDate: moment().format('D.MM.YYYY'),
                  audioAttached: false,
                  color: message.data.color
                }
              )
            } else if (highlightPage == 2) {
              if (bookMedia.pages[this.state.currentPage + 1] != undefined) {
                // book.pages[this.state.currentPage+1].highlights.push(
                //   {
                //     textString:message.data.textString,
                //     highlightId:message.data.highlightId,
                //     highlightHtml:message.data.highlightHtml,
                //     shared:false,
                //     sharedDate:null,
                //     saveDate:moment().format('D.MM.YYYY'),
                //     audioAttached:false,
                //     color:message.data.color
                //   }
                // );
                bookMedia.pages[this.state.currentPage + 1].highlights.push(
                  {
                    textString: message.data.textString,
                    highlightId: message.data.highlightId,
                    highlightHtml: message.data.highlightHtml,
                    highlightBackendId: message.data.highlightId + '-' + this.state.currentPage,
                    shared: false,
                    sharedDate: null,
                    saveDate: moment().format('D.MM.YYYY'),
                    audioAttached: false,
                    color: message.data.color
                  }
                )
              }
            }
          } else {
            // console.log('between page odd new highlight');
            if (highlightPage == 1) {
              // book.pages[this.state.currentPage-1].highlights.push(
              //   {
              //     textString:message.data.textString,
              //     highlightId:message.data.highlightId,
              //     highlightHtml:message.data.highlightHtml,
              //     shared:false,
              //     sharedDate:null,
              //     saveDate:moment().format('D.MM.YYYY'),
              //     audioAttached:false,
              //     color:message.data.color
              //   }
              // );
              bookMedia.pages[this.state.currentPage - 1].highlights.push(
                {
                  textString: message.data.textString,
                  highlightId: message.data.highlightId,
                  highlightHtml: message.data.highlightHtml,
                  highlightBackendId: message.data.highlightId + '-' + this.state.currentPage,
                  shared: false,
                  sharedDate: null,
                  saveDate: moment().format('D.MM.YYYY'),
                  audioAttached: false,
                  color: message.data.color
                }
              )
            } else if (highlightPage == 2) {
              if (bookMedia.pages[this.state.currentPage] != undefined) {
                // book.pages[this.state.currentPage].highlights.push(
                //   {
                //     textString:message.data.textString,
                //     highlightId:message.data.highlightId,
                //     highlightHtml:message.data.highlightHtml,
                //     shared:false,
                //     sharedDate:null,
                //     saveDate:moment().format('D.MM.YYYY'),
                //     audioAttached:false,
                //     color:message.data.color
                //   }
                // );
                bookMedia.pages[this.state.currentPage].highlights.push(
                  {
                    textString: message.data.textString,
                    highlightId: message.data.highlightId,
                    highlightHtml: message.data.highlightHtml,
                    highlightBackendId: message.data.highlightId + '-' + this.state.currentPage,
                    shared: false,
                    sharedDate: null,
                    saveDate: moment().format('D.MM.YYYY'),
                    audioAttached: false,
                    color: message.data.color
                  }
                )
              }
            }
          }
        }
        // console.log(bookMedia.pages[this.state.currentPage])
        this.setState({
          selectedHighlightId: message.data.highlightId
        })
        // let jsonPath = RNFS.DocumentDirectoryPath + '/books/' + this.props.bookID + '/OEBPS/data.json'
        let popup = this.state.popup
        // popup.x = popup.x + 24
        this.setState({
          bookMedia,
          popup: popup,
          // showSelectionPopup: true,
          selectedHighlightId: message.data.highlightId,
          selectedHighlightPage: message.data.page,
          selectionPopupHaveShareBtn: true,
          selectionPopupShareBtnEnable: true,
          enableVocabulary: false,
          showColorPopup: true
        })
        this.saveBookMedia(bookMedia, 'highlight');
        // this.sendMessage(JSON.stringify({ message: 'clearSelection' }))
        this._shareHighlight()
      } else if (message.data.highlightType == 'vocabulary') {
        console.log('aaaaaaaaaahmad', message)
        let book = this.state.book
        let highlightPage = message.data.page;
        let bookMedia = this.state.bookMedia
        if (this.state.currentPage == 0) {
          // console.log('first page new highlight');
          if (highlightPage == 1) {
            // book.pages[this.state.currentPage].highlights.push(
            //   {
            //     textString:message.data.textString,
            //     highlightId:message.data.highlightId,
            //     highlightHtml:message.data.highlightHtml,
            //     shared:false,
            //     sharedDate:null,
            //     saveDate:moment().format('D.MM.YYYY'),
            //     audioAttached:false,
            //     color:message.data.color
            //   }
            // );
            bookMedia.pages[this.state.currentPage].vocabularies.push(
              {
                textString: message.data.textString,
                withHighlight: true,
                highlightId: message.data.highlightId,
                highlightHtml: message.data.highlightHtml,
                highlightBackendId: message.data.highlightId + '-' + this.state.currentPage,
                shared: false,
                sharedDate: null,
                saveDate: moment().format('D.MM.YYYY'),
                audioAttached: false,
                color: message.data.color
              }
            )
          }
        } else if (this.state.currentPage == this.state.book.pages.length - 1) {
          // console.log('last page new highlight');
          if (highlightPage == 1) {
            // book.pages[this.state.currentPage].highlights.push(
            //   {
            //     textString:message.data.textString,
            //     highlightId:message.data.highlightId,
            //     highlightHtml:message.data.highlightHtml,
            //     shared:false,
            //     sharedDate:null,
            //     saveDate:moment().format('D.MM.YYYY'),
            //     audioAttached:false,
            //     color:message.data.color
            //   }
            // );
            bookMedia.pages[this.state.currentPage].vocabularies.push(
              {
                textString: message.data.textString,
                withHighlight: true,
                highlightId: message.data.highlightId,
                highlightHtml: message.data.highlightHtml,
                highlightBackendId: message.data.highlightId + '-' + this.state.currentPage,
                shared: false,
                sharedDate: null,
                saveDate: moment().format('D.MM.YYYY'),
                audioAttached: false,
                color: message.data.color
              }
            );
          }
        } else {
          if (this.state.currentPage % 2 != 0) {
            // console.log('between page even new highlight');
            if (highlightPage == 1) {
              // book.pages[this.state.currentPage].highlights.push(
              //   {
              //     textString:message.data.textString,
              //     highlightId:message.data.highlightId,
              //     highlightHtml:message.data.highlightHtml,
              //     shared:false,
              //     sharedDate:null,
              //     saveDate:moment().format('D.MM.YYYY'),
              //     audioAttached:false,
              //     color:message.data.color
              //   }
              // );
              bookMedia.pages[this.state.currentPage].vocabularies.push(
                {
                  textString: message.data.textString,
                  withHighlight: true,
                  highlightId: message.data.highlightId,
                  highlightHtml: message.data.highlightHtml,
                  highlightBackendId: message.data.highlightId + '-' + this.state.currentPage,
                  shared: false,
                  sharedDate: null,
                  saveDate: moment().format('D.MM.YYYY'),
                  audioAttached: false,
                  color: message.data.color
                }
              )
            } else if (highlightPage == 2) {
              if (bookMedia.pages[this.state.currentPage + 1] != undefined) {
                // book.pages[this.state.currentPage+1].highlights.push(
                //   {
                //     textString:message.data.textString,
                //     highlightId:message.data.highlightId,
                //     highlightHtml:message.data.highlightHtml,
                //     shared:false,
                //     sharedDate:null,
                //     saveDate:moment().format('D.MM.YYYY'),
                //     audioAttached:false,
                //     color:message.data.color
                //   }
                // );
                bookMedia.pages[this.state.currentPage + 1].vocabularies.push(
                  {
                    textString: message.data.textString,
                    withHighlight: true,
                    highlightId: message.data.highlightId,
                    highlightHtml: message.data.highlightHtml,
                    highlightBackendId: message.data.highlightId + '-' + this.state.currentPage,
                    shared: false,
                    sharedDate: null,
                    saveDate: moment().format('D.MM.YYYY'),
                    audioAttached: false,
                    color: message.data.color
                  }
                )
              }
            }
          } else {
            // console.log('between page odd new highlight');
            if (highlightPage == 1) {
              // book.pages[this.state.currentPage-1].highlights.push(
              //   {
              //     textString:message.data.textString,
              //     highlightId:message.data.highlightId,
              //     highlightHtml:message.data.highlightHtml,
              //     shared:false,
              //     sharedDate:null,
              //     saveDate:moment().format('D.MM.YYYY'),
              //     audioAttached:false,
              //     color:message.data.color
              //   }
              // );
              bookMedia.pages[this.state.currentPage - 1].vocabularies.push(
                {
                  textString: message.data.textString,
                  withHighlight: true,
                  highlightId: message.data.highlightId,
                  highlightHtml: message.data.highlightHtml,
                  highlightBackendId: message.data.highlightId + '-' + this.state.currentPage,
                  shared: false,
                  sharedDate: null,
                  saveDate: moment().format('D.MM.YYYY'),
                  audioAttached: false,
                  color: message.data.color
                }
              )
            } else if (highlightPage == 2) {
              if (bookMedia.pages[this.state.currentPage] != undefined) {
                // book.pages[this.state.currentPage].highlights.push(
                //   {
                //     textString:message.data.textString,
                //     highlightId:message.data.highlightId,
                //     highlightHtml:message.data.highlightHtml,
                //     shared:false,
                //     sharedDate:null,
                //     saveDate:moment().format('D.MM.YYYY'),
                //     audioAttached:false,
                //     color:message.data.color
                //   }
                // );
                bookMedia.pages[this.state.currentPage].vocabularies.push(
                  {
                    textString: message.data.textString,
                    withHighlight: true,
                    highlightId: message.data.highlightId,
                    highlightHtml: message.data.highlightHtml,
                    highlightBackendId: message.data.highlightId + '-' + this.state.currentPage,
                    shared: false,
                    sharedDate: null,
                    saveDate: moment().format('D.MM.YYYY'),
                    audioAttached: false,
                    color: message.data.color
                  }
                )
              }
            }
          }
        }
        // console.log(bookMedia.pages[this.state.currentPage])
        this.setState({
          selectedHighlightId: message.data.highlightId
        })
        // let jsonPath = RNFS.DocumentDirectoryPath + '/books/' + this.props.bookID + '/OEBPS/data.json'
        // let popup = this.state.popup
        // popup.x = popup.x + 24
        this.setState({
          bookMedia,
          // popup: popup,
          // showSelectionPopup: true,
          selectedHighlightId: message.data.highlightId,
          selectedHighlightPage: message.data.page,
          selectionPopupHaveShareBtn: true,
          selectionPopupShareBtnEnable: true,
          showColorPopup: true
        })
        this.saveBookMedia(bookMedia, 'vocabulary');
        // this.sendMessage(JSON.stringify({ message: 'clearSelection' }))
        // this._shareHighlight()
        // showVocabularyModal(selectedHighlight, this.props.bookID, 'highlight', 'old')

        // this.props.dispatch({
        //   type: 'CHANGE_VOCABULARY_MODAL_DATA', message: {
        //     currentText: this.state.currentSelectedText,
        //     bookId: this.props.router.query.bookId,
        //     highlightOrGeneral: 'highlight',
        //     oldOrNew: 'new',
        //   }
        // })

        // this.props.dispatch({
        //   type: 'TOGGLE_VOCABULARY_MODAL', message: {
        //     vocabularyModal: true
        //   }
        // })
      }

    } else if (message.message == 'nextPagePlay') {
      setTimeout(() => {
        this._prevPage()
      }, 1000);
      // setTimeout(() => {
      //   this.webdiv.postMessage(JSON.stringify({message:'play',smilAudios:this.state.currentPageAudios}),'*');
      // }, 500);
    } else if (message.message == 'playButtonFalse') {
      this.setState({
        playForMeMode: false
      })
    }
  }
  saveBookMedia(bookMedia, type) {
    bookMedia.mainRecord = {}
    bookMedia.pages[this.state.currentPage].drawings.forEach((drawing, index) => {
      // if(drawing.audioAttached && (type != 'addDrawing' || type != 'addDrawing')){
      delete drawing.record
      delete drawing.saveDate
      delete drawing.sharedDate
      delete drawing.shared
      delete drawing.duration
      drawing.audioAttached = false
      // }
    })
    console.log('bookMedia.pages[this.state.currentPage].drawings', bookMedia.pages[this.state.currentPage].drawings)
    localStorage.setItem(this.props.loggedInUser.userData._id + '_' + this.props.router.query.bookId, JSON.stringify(bookMedia))

  }
  _toggleText() {
    this.sendMessage(JSON.stringify({ message: 'textMode' }))
    this.setState({
      textMode: !this.state.textMode,
      showMenu: false,
      selectionPopupHaveShareBtn: false,
      showColorPopup: false,
    });
  }
  _closeDrawingMode() {
    this.sendMessage(JSON.stringify({ message: 'showSvg' }))
    // this.actionbutton.animateButton()
    this.setState({
      drawing: !this.state.drawing,
      showMenu: true,
      menuActive: true,
      showSelectionPopup: false,
      showColorPopup: false,
      selectionPopupHaveShareBtn: false,
      // recordingStatus:'notStarted'
    });

  }
  _closeTextMode() {
    this.sendMessage(JSON.stringify({ message: 'textMode' }))
    this.setState({
      textMode: !this.state.textMode,
      showMenu: true,
      menuActive: true,
      showSelectionPopup: false,
      showColorPopup: false,
      selectionPopupHaveShareBtn: false,
    });
    this.sendMessage(JSON.stringify({ message: 'clearSelection' }))
  }
  _closeRecordingMode() {
    this.setState({
      recordingMode: !this.state.recordingMode,
      showMenu: true,
      menuActive: true,
      showSelectionPopup: false,
      showMainRecorder: false,
      showColorPopup: false,
      selectionPopupHaveShareBtn: false,
      // recordingStatus:'notStarted'
    });
  }

  _updateVocabulary() {
    let bookMedia = this.state.bookMedia
    let self = this
    let currentPageNumber;
    if (this.state.currentOrientation == 'landscape') {
      if (this.state.currentPage == 0) {
        currentPageNumber = this.state.currentPage
      } else if (this.state.currentPage == bookMedia.pages.length - 1) {
        if (this.state.currentPage % 2 != 0) {
          if (this.state.selectedHighlightPage == 1) {
            currentPageNumber = this.state.currentPage
          } else {
            currentPageNumber = this.state.currentPage + 1
          }
        } else {
          currentPageNumber = this.state.currentPage
        }
      } else {
        if (this.state.currentPage % 2 != 0) {
          if (this.state.selectedHighlightPage == 1) {
            currentPageNumber = this.state.currentPage
          } else {
            currentPageNumber = this.state.currentPage + 1
          }
        } else {
          if (this.state.selectedHighlightPage == 1) {
            currentPageNumber = this.state.currentPage - 1
          } else {
            currentPageNumber = this.state.currentPage
          }
        }
      }
    }
    let highlightIndex = bookMedia.pages[currentPageNumber].vocabularies.findIndex((highlight) => {
      return highlight.highlightId == this.props.newVocabularyData.highlightId
    })
    console.log(highlightIndex)
    // console.log(book_1.pages[this.state.currentPage].highlights[highlightIndex])
    bookMedia.pages[currentPageNumber].vocabularies[highlightIndex] = {
      ...bookMedia.pages[currentPageNumber].vocabularies[highlightIndex],
      definition: this.props.newVocabularyData.definition,
      sentence: this.props.newVocabularyData.sentence,
      selectVocabularyType: this.props.newVocabularyData.selectVocabularyType
    }

    this.setState({
      bookMedia,
      showSelectionPopup: false,
      showColorPopup: false,
      selectedHighlightId: ''
    })
    this.saveBookMedia(bookMedia, 'vocabulary');
    const formData = new FormData();
    formData.append('user_id', this.props.loggedInUser.userData._id);
    formData.append('book_id', this.props.router.query.bookId);
    formData.append('vocabulary_id', this.props.newVocabularyData.highlightBackendId);
    formData.append('sentence', this.props.newVocabularyData.sentence);
    formData.append('definition', this.props.newVocabularyData.definition);
    formData.append('vocabulary_type', this.props.newVocabularyData.selectVocabularyType);
    axios.post('https://school.kutubee.com:4000/share/dictionary/update', formData)
      .then(response => {
        console.log('update highlight', response, formData)
        let message = null
        // this.props.dispatch({ type: 'NEW_VOCABULARY_DATA', message })
      })
  }
  _updateGeneralVocabulary() {
    let bookMedia = this.state.bookMedia
    let self = this
    let highlightIndex = bookMedia.generalVocabularies.findIndex((highlight) => {
      return highlight.highlightBackendId == this.props.newVocabularyData.highlightBackendId
    })

    bookMedia.generalVocabularies[highlightIndex] = {
      ...bookMedia.generalVocabularies[highlightIndex],
      definition: this.props.newVocabularyData.definition,
      sentence: this.props.newVocabularyData.sentence,
      selectVocabularyType: this.props.newVocabularyData.selectVocabularyType,
      vocabularyText: this.props.newVocabularyData.vocabularyText
    }
    this.setState({
      bookMedia,
      showSelectionPopup: false,
      showColorPopup: false,
      selectedHighlightId: ''
    })
    const formData = new FormData();
    formData.append('user_id', this.props.loggedInUser.userData._id);
    formData.append('book_id', this.props.router.query.bookId);
    formData.append('vocabulary_id', this.props.newVocabularyData.highlightBackendId);
    formData.append('sentence', this.props.newVocabularyData.sentence);
    formData.append('definition', this.props.newVocabularyData.definition);
    formData.append('vocabulary_type', this.props.newVocabularyData.selectVocabularyType);
    formData.append('vocabulary_text', this.props.newVocabularyData.vocabularyText);
    axios.post('https://school.kutubee.com:4000/share/dictionary/update', formData)
      .then(response => {
        console.log('update highlight', response, formData)
        let message = null
        // this.props.dispatch({ type: 'NEW_VOCABULARY_DATA', message })
      })
  }

  _removeGeneralVocabulary() {
    this.sendMessage(JSON.stringify({ message: 'removeHighlight', highlightId: this.props.newVocabularyData.highlightId }));
    let bookMedia = this.state.bookMedia
    let self = this

    let highlightIndex = bookMedia.generalVocabularies.findIndex((highlight) => {
      return highlight.highlightBackendId == this.props.newVocabularyData.highlightBackendId
    })
    bookMedia.generalVocabularies.splice(highlightIndex, 1)
    this.setState({
      bookMedia,
    })
    this.saveBookMedia(bookMedia, 'highlight');
    const formData = new FormData();
    formData.append('user_id', this.props.loggedInUser.userData._id);
    formData.append('book_id', this.props.router.query.bookId);
    formData.append('vocabulary_id', this.props.newVocabularyData.highlightBackendId);

    axios.post('https://school.kutubee.com:4000/share/dictionary/delete', formData)
      .then(response => {
        console.log('delete highlight', response, formData)
        let message = null
        // this.props.dispatch({ type: 'NEW_VOCABULARY_DATA', message })
      })
  }
  _removeVocabulary() {
    this.sendMessage(JSON.stringify({ message: 'removeHighlight', highlightId: this.props.newVocabularyData.highlightId }));
    let bookMedia = this.state.bookMedia
    let self = this
    let currentPageNumber;
    if (this.state.currentOrientation == 'landscape') {
      if (this.state.currentPage == 0) {
        currentPageNumber = this.state.currentPage
      } else if (this.state.currentPage == bookMedia.pages.length - 1) {
        if (this.state.currentPage % 2 != 0) {
          if (this.state.selectedHighlightPage == 1) {
            currentPageNumber = this.state.currentPage
          } else {
            currentPageNumber = this.state.currentPage + 1
          }
        } else {
          currentPageNumber = this.state.currentPage
        }
      } else {
        if (this.state.currentPage % 2 != 0) {
          if (this.state.selectedHighlightPage == 1) {
            currentPageNumber = this.state.currentPage
          } else {
            currentPageNumber = this.state.currentPage + 1
          }
        } else {
          if (this.state.selectedHighlightPage == 1) {
            currentPageNumber = this.state.currentPage - 1
          } else {
            currentPageNumber = this.state.currentPage
          }
        }
      }
    }
    let highlightIndex = bookMedia.pages[currentPageNumber].vocabularies.findIndex((highlight) => {
      return highlight.highlightId == this.props.newVocabularyData.highlightId
    })
    bookMedia.pages[currentPageNumber].vocabularies.splice(highlightIndex, 1)
    this.setState({
      bookMedia,
      showSelectionPopup: false,
      showColorPopup: false,
      selectedHighlightId: ''
    })
    this.saveBookMedia(bookMedia, 'highlight');
    const formData = new FormData();
    formData.append('user_id', this.props.loggedInUser.userData._id);
    formData.append('book_id', this.props.router.query.bookId);
    formData.append('vocabulary_id', this.props.newVocabularyData.highlightBackendId);

    axios.post('https://school.kutubee.com:4000/share/dictionary/delete', formData)
      .then(response => {
        let message = null
        // this.props.dispatch({ type: 'NEW_VOCABULARY_DATA', message })
      })
  }

  _deleteVocabulary(highlightOrNot) {
    if (highlightOrNot) {
      this.sendMessage(JSON.stringify({ message: 'removeHighlight', highlightId: this.state.selectedHighlightId }));
      let bookMedia = this.state.bookMedia
      let self = this
      let currentPageNumber;
      if (this.state.currentOrientation == 'landscape') {
        if (this.state.currentPage == 0) {
          currentPageNumber = this.state.currentPage
        } else if (this.state.currentPage == bookMedia.pages.length - 1) {
          if (this.state.currentPage % 2 != 0) {
            if (this.state.selectedHighlightPage == 1) {
              currentPageNumber = this.state.currentPage
            } else {
              currentPageNumber = this.state.currentPage + 1
            }
          } else {
            currentPageNumber = this.state.currentPage
          }
        } else {
          if (this.state.currentPage % 2 != 0) {
            if (this.state.selectedHighlightPage == 1) {
              currentPageNumber = this.state.currentPage
            } else {
              currentPageNumber = this.state.currentPage + 1
            }
          } else {
            if (this.state.selectedHighlightPage == 1) {
              currentPageNumber = this.state.currentPage - 1
            } else {
              currentPageNumber = this.state.currentPage
            }
          }
        }
      }
      let highlightIndex = bookMedia.pages[currentPageNumber].vocabularies.findIndex(function (highlight) {
        return highlight.highlightId == self.state.selectedHighlightId
      })
      if (highlightIndex != -1) {
        bookMedia.pages[currentPageNumber].vocabularies.splice(highlightIndex, 1)
        this.setState({
          bookMedia,
          showSelectionPopup: false,
          showColorPopup: false,
          selectedHighlightId: ''
        })
        bookMedia.mainRecord = {}


        localStorage.setItem(this.props.loggedInUser.userData._id + '_' + this.props.router.query.bookId, JSON.stringify(bookMedia));
        // })
      }
    }
  }
  _deleteHighlight() {
    this.sendMessage(JSON.stringify({ message: 'removeHighlight', highlightId: this.state.selectedHighlightId }));
    let bookMedia = this.state.bookMedia
    let self = this
    let currentPageNumber;
    if (this.state.currentOrientation == 'landscape') {
      if (this.state.currentPage == 0) {
        currentPageNumber = this.state.currentPage
      } else if (this.state.currentPage == bookMedia.pages.length - 1) {
        if (this.state.currentPage % 2 != 0) {
          if (this.state.selectedHighlightPage == 1) {
            currentPageNumber = this.state.currentPage
          } else {
            currentPageNumber = this.state.currentPage + 1
          }
        } else {
          currentPageNumber = this.state.currentPage
        }
      } else {
        if (this.state.currentPage % 2 != 0) {
          if (this.state.selectedHighlightPage == 1) {
            currentPageNumber = this.state.currentPage
          } else {
            currentPageNumber = this.state.currentPage + 1
          }
        } else {
          if (this.state.selectedHighlightPage == 1) {
            currentPageNumber = this.state.currentPage - 1
          } else {
            currentPageNumber = this.state.currentPage
          }
        }
      }
    }
    let highlightIndex = bookMedia.pages[currentPageNumber].highlights.findIndex(function (highlight) {
      return highlight.highlightId == self.state.selectedHighlightId
    })
    // // console.log(highlightIndex)
    // // console.log(book_1.pages[this.state.currentPage].highlights[highlightIndex])
    // if(bookMedia.pages[currentPageNumber].highlights[highlightIndex].audioAttached == true){
    //   let recordIndex = bookMedia.pages[currentPageNumber].voiceRecords.findIndex(record=>{
    //     return record.highlightId == bookMedia.pages[currentPageNumber].highlights[highlightIndex].highlightId
    //   })
    //   let audioUrl = bookMedia.pages[currentPageNumber].voiceRecords[recordIndex].url
    //   RNFS.unlink(audioUrl)
    //   .then(() => {
    //     // console.log('FILE DELETED');
    //   })
    //   .catch((err) => {
    //     // console.log(err.message);
    //   });
    //   bookMedia.pages[currentPageNumber].voiceRecords.splice(recordIndex,1)
    // }
    bookMedia.pages[currentPageNumber].highlights.splice(highlightIndex, 1)
    // RNFS.writeFile(this.state.bookJsonPath, JSON.stringify(bookMedia), 'utf8')
    // .then((success) => {

    this.setState({
      bookMedia,
      showSelectionPopup: false,
      selectedHighlightId: ''
    })
    this.saveBookMedia(bookMedia, 'sss')
    // })

  }
  _shareHighlight() {
    let bookMedia = this.state.bookMedia;
    let currentPageNumber;
    if (this.state.currentOrientation == 'landscape') {
      if (this.state.currentPage == 0) {
        currentPageNumber = this.state.currentPage
      } else if (this.state.currentPage == bookMedia.pages.length - 1) {
        if (this.state.currentPage % 2 != 0) {
          if (this.state.selectedHighlightPage == 1) {
            currentPageNumber = this.state.currentPage
          } else {
            currentPageNumber = this.state.currentPage + 1
          }
        } else {
          currentPageNumber = this.state.currentPage
        }
      } else {
        if (this.state.currentPage % 2 != 0) {
          if (this.state.selectedHighlightPage == 1) {
            currentPageNumber = this.state.currentPage
          } else {
            currentPageNumber = this.state.currentPage + 1
          }
        } else {
          if (this.state.selectedHighlightPage == 1) {
            currentPageNumber = this.state.currentPage - 1
          } else {
            currentPageNumber = this.state.currentPage
          }
        }
      }
    }
    let selcectedHighlightData = bookMedia.pages[currentPageNumber].highlights.find(highlight => highlight.highlightId == this.state.selectedHighlightId);
    console.log(currentPageNumber, 'this.state.selectedHighlightPage', this.state.selectedHighlightPage, bookMedia.pages, currentPageNumber)

    this.setState({
      shareHighlightLoader: 'sending'
    })
    const formData = new FormData();
    formData.append('user_id', this.props.loggedInUser.userData._id);
    formData.append('color', selcectedHighlightData.color);
    formData.append('text_highlight', selcectedHighlightData.textString);
    formData.append('page_no', this.state.currentPage + 1);
    formData.append('book_id', this.props.router.query.bookId);
    formData.append('shareType', 'text_highlight');
    // // console.log(formData);
    axios.post('https://school.kutubee.com:4000/share/user', formData)
      .then(res => {
        console.log("highlightshared", res.data, formData);
        if (res.data.message == 'success') {
          bookMedia.pages[currentPageNumber].highlights.map(highlight => {
            if (highlight.highlightId == this.state.selectedHighlightId) {
              highlight.shared = true;
              highlight.sharedDate = moment().format('D.MM.YYYY');
            }
          });
          this.saveBookMedia(bookMedia, 'highlight');
          this.setState({
            bookMedia,
            shareHighlightLoader: 'sent'
          })
          setTimeout(() => {
            this.setState({
              selectionPopupShareBtnEnable: false,
              shareHighlightLoader: 'none'
            })
          }, 1000);
          // let message = {
          //   showNotificationBox: true,
          //   notificationText: i18n.t('boxNotification.highlightShared')
          // }
          // this.props.dispatch({ type: 'TOGGLE_NOTIFICATION_BOX', message })
        }
      })
  }
  _shareMainAudio() {
    let book = this.state.book;

    // RNFS.readFile(booksPath + this.props.bookID + '/records/main-record.acc','base64')
    // .then(base64Audio=>{
    let bookMedia = this.state.bookMedia
    const formData = new FormData();
    formData.append('user_id', this.props.loggedInUser.userData._id);
    formData.append('book_id', this.props.router.query.bookId);
    formData.append('audioData', bookMedia.mainRecord.record);
    formData.append('shareType', 'book_audio');

    if (isSafari) {
      formData.append('type', 'wav');
    } else {
      formData.append('type', 'ogg');
    }
    axios.post('https://school.kutubee.com:4000/share/user', formData)
      .then(res => {
        // console.log(res);
        if (res.data.message == 'success') {
          bookMedia.mainRecord.shared = true
          bookMedia.mainRecord.sharedDate = moment().format('D.MM.YYYY')
          this.setState({
            bookMedia,
          })

          // localStorage.setItem(this.props.router.query.bookId,JSON.stringify(bookMedia));
          // openNotificationBox(this.props.componentId,'تم إرسال الملف الصوتي للمعلم')
          this.setState({
            shareMainRecordLoader: 'sent'
          })
          setTimeout(() => {
            // Navigation.dismissOverlay('LoadingBox')
            // openNotificationBox(this.props.componentId, I18t.t('boxNotification.audioShared'))
            this.setState({
              shareMainRecordLoader: 'none'
            })
          }, 1000);
        }
      })
    // })
  }
  _deletePolygon() {
    this.sendMessage(JSON.stringify({ message: 'removeSvg', polygonId: this.state.selectedPolygonId }))

    let bookMedia = this.state.bookMedia
    let self = this
    let currentPageNumber;
    if (this.state.currentOrientation == 'portrait') {
      currentPageNumber = this.state.currentPage
    } else if (this.state.currentOrientation == 'landscape') {
      if (this.state.currentPage == 0) {
        currentPageNumber = this.state.currentPage
      } else if (this.state.currentPage == bookMedia.pages.length - 1) {
        if (this.state.currentPage % 2 != 0) {
          if (this.state.selectedPolygonPage == 1) {
            currentPageNumber = this.state.currentPage
          } else {
            currentPageNumber = this.state.currentPage + 1
          }
        } else {
          currentPageNumber = this.state.currentPage
        }
      } else {
        if (this.state.currentPage % 2 != 0) {
          if (this.state.selectedPolygonPage == 1) {
            currentPageNumber = this.state.currentPage
          } else {
            currentPageNumber = this.state.currentPage + 1
          }
        } else {
          if (this.state.selectedPolygonPage == 1) {
            currentPageNumber = this.state.currentPage - 1
          } else {
            currentPageNumber = this.state.currentPage
          }
        }
      }
    }
    let drawingIndex = bookMedia.pages[currentPageNumber].drawings.findIndex(function (drawing) {
      return drawing.polygonId == self.state.selectedPolygonId
    })

    bookMedia.pages[currentPageNumber].drawings.splice(drawingIndex, 1)
    // RNFS.writeFile(this.state.bookJsonPath, JSON.stringify(book_1), 'utf8')
    // .then((success) => {
    //   this.setState({
    //     book:book_1,
    //     showSelectionPopup:false
    //   })
    // })
    // .catch((err) => {
    //   // console.log(err.message);
    // });
    this.saveBookMedia(bookMedia, 'deleteDrawing')
    if (this.drawingRecordPlayer != null && this.drawingRecordPlayer != undefined) {
      this.drawingRecordPlayer.pause();
      this.drawingRecordPlayer.currentTime = 0
    }
    this.setState({
      bookMedia,
      showSelectionPopup: false
    })
  }
  _popupRecordButton() {
    let currentPageNumber;
    if (this.state.currentOrientation == 'portrait') {
      currentPageNumber = this.state.currentPage
    } else if (this.state.currentOrientation == 'landscape') {
      if (this.state.currentPage == 0) {
        currentPageNumber = this.state.currentPage
      } else if (this.state.currentPage == this.state.book.pages.length - 1) {
        if (this.state.currentPage % 2 != 0) {
          if (this.state.selectedPolygonPage == 1) {
            currentPageNumber = this.state.currentPage
          } else {
            currentPageNumber = this.state.currentPage + 1
          }
        } else {
          currentPageNumber = this.state.currentPage
        }
      } else {
        if (this.state.currentPage % 2 != 0) {
          if (this.state.selectedPolygonPage == 1) {
            currentPageNumber = this.state.currentPage
          } else {
            currentPageNumber = this.state.currentPage + 1
          }
        } else {
          if (this.state.selectedPolygonPage == 1) {
            currentPageNumber = this.state.currentPage - 1
          } else {
            currentPageNumber = this.state.currentPage
          }
        }
      }
    }
    this.setState({
      recordingStatus: 'recording',
      buttonIcon: 'stop',
      // showSelectionPopup:false,
      // showRecorder:true,
      // showMenu:false,
    })
  }
  emptyAudioFromDrawing(drawing) {
    console.log('ssss', drawing)
  }

  _stopDrawingRecorder() {
    clearInterval(this.drawingCurrentTime);
    this.drawingRecorder.stop();
  }
  _stopAttachedAudio() {
    this.setState({
      selectionPopupPlaying: false,
      // selectionPopupHavePlayBtn:true,
    })
    if (this.drawingRecordPlayer != null && this.drawingRecordPlayer != undefined) {
      this.drawingRecordPlayer.pause();
      this.drawingRecordPlayer.currentTime = 0
    }
  }
  _playAttachedAudio() {
    let bookMedia = this.state.bookMedia;
    let currentPageNumber;
    if (this.state.currentOrientation == 'landscape') {
      if (this.state.currentPage == 0) {
        currentPageNumber = this.state.currentPage
      } else if (this.state.currentPage == this.state.book.pages.length - 1) {
        if (this.state.currentPage % 2 != 0) {
          if (this.state.selectedPolygonPage == 1) {
            currentPageNumber = this.state.currentPage
          } else {
            currentPageNumber = this.state.currentPage + 1
          }
        } else {
          currentPageNumber = this.state.currentPage
        }
      } else {
        if (this.state.currentPage % 2 != 0) {
          if (this.state.selectedPolygonPage == 1) {
            currentPageNumber = this.state.currentPage
          } else {
            currentPageNumber = this.state.currentPage + 1
          }
        } else {
          if (this.state.selectedPolygonPage == 1) {
            currentPageNumber = this.state.currentPage - 1
          } else {
            currentPageNumber = this.state.currentPage
          }
        }
      }
    }
    let drawingIndex = bookMedia.pages[currentPageNumber].drawings.findIndex(drawing => {
      return drawing.polygonId == this.state.selectedPolygonId
    })
    // console.log('drawingIndex',bookMedia.pages[currentPageNumber].drawings[drawingIndex])
    if (drawingIndex != -1) {
      this.setState({
        currentDrawingAudioData: bookMedia.pages[currentPageNumber].drawings[drawingIndex].record,
        selectionPopupPlaying: true,
        // selectionPopupHavePlayBtn:true,
      })
      let self = this
      setTimeout(() => {
        console.log('drawingRecordPlayer', this.drawingRecordPlayer, bookMedia.pages[currentPageNumber].drawings[drawingIndex].record)
        try {
          this.drawingRecordPlayer.play();
          this.drawingRecordPlayer.onended = function () {
            self.setState({
              selectionPopupPlaying: false,
            })
          }
        } catch (error) {
          console.log(error)
        }

      }, 300);
    }
    // this.setState({
    //   selectionPopupHavePlayBtn:true,
    //   selectionPopupPlaying:true
    // })
  }


  _playForMeButton = () => {
    if (this.state.playForMeMode) {
      this.sendMessage(JSON.stringify({ message: 'pausePlay' }), '*');
      this.setState({
        playForMeMode: false
      });
    } else {
      this.sendMessage(JSON.stringify({ message: 'play' }), '*');
      this.setState({
        playForMeMode: true
      });
    }
  }


  render() {
    return (
      // <div onPress={()=>this.profile()} style={{alignItems:'center'}}>
      <div style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        margin: 0
      }}>
          
        <div ref={(ref) => this.bookWrapper = ref} id="book-reader-page" className={"book-" + this.state.lang} >
          <div
            className='go-back-2'
            onClick={() => { this._prevPage() }}>
            <svg width={15} height={15 / (32.37 / 57.75)} viewBox="0 0 32.37 57.75">
              <path
                fill="none"
                stroke="#fff"
                strokeWidth={7}
                strokeLinecap="round"
                strokeLinejoin="round"

                strokeMiterlimit={10}
                d="M28.87 54.25L3.5 28.87 28.87 3.5"
              />
            </svg>
          </div>

          <div
            className='go-back-3'
            onClick={() => { this._nextPage() }}>
            <svg width={15} height={15 / (32.37 / 57.75)} viewBox="0 0 32.37 57.75">
              <path
                fill="none"
                stroke="#fff"
                strokeWidth={7}
                strokeLinecap="round"
                strokeLinejoin="round"

                strokeMiterlimit={10}
                d="M28.87 54.25L3.5 28.87 28.87 3.5"
              />
            </svg>
          </div>
          <div className="actions-bottom">
            
              <div className="play-icon" onClick={this._playForMeButton}>
                {this.state.playForMeMode === true?<PauseIcon/>:<PlayArrowIcon/>}
                
              </div>
          </div>
          {this.state.currentPageUrl != '' && !this.state.bookFinished ?
            <div className="book-wrapper">
              <div className="iframe-wrapper"
                ref={ref => this.iframeWrapper = ref}
                scrolling="no" style={{
                  position: 'absolute',
                  top: this.state.bookTop,
                  right: this.state.bookLeft,
                  transform: `scale(${this.state.scale})`,
                  transformOrigin: this.state.bookOrigin,
                  width: this.state.bookWidth * 2,
                  height: this.state.bookHeight == '100%' ? this.state.bookHeight : parseInt(this.state.bookHeight),
                }}>
                {/* <div id="nardine" style={{
                position: 'absolute',
                zIndex:151511,
                backgroundColor:'red',
                left:0,
                top:0
              }}>nardine</div> */}
                {this.state.bookData.bookSource != 'reflowable' ?
                  <iframe id="book-iframe" allow="autoplay" style={{
                  }} onLoad={() => this._onPageLoad()} ref={(ref) => this.iframe = ref} src={this.state.currentPageUrl} />
                  :
                  <div
                    id="reflowable-book-wrapper"
                    allow="autoplay"
                    ref={(ref) => this.iframe = ref}
                  />
                }

                
                
              </div>

            </div>
            :
            ''
          }
         

         
        </div>
       




        

      </div >
      //  </div> 
    );
  }
}
function mapStateToProps(state) {
  return {
    loggedInUser: state.homeReducer.loggedInUser,
    userProgress: state.homeReducer.userProgress,
    currentBookProgressSaved: state.homeReducer.currentBookProgressSaved,
    appLanguage: state.homeReducer.appLanguage,
    vocabularyModal: state.homeReducer.vocabularyModal,
    dictionaryModal: state.homeReducer.dictionaryModal,
    newVocabularyData: state.homeReducer.newVocabularyData
    // showDialog:state.homeReducer.showDialog
  }
}
export default Read;