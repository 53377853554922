import React from 'react';
import BookForm from '../../forms/book';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Redirect } from 'react-router-dom';
//import {baseLink,frontLink} from "./../../componets/helper";
import FusePageCarded from '@fuse/core/FusePageCarded';
import Breadcrumb from '../components/Breadcrumb';

const baseLink = process.env.REACT_APP_BACKEND_URL;
const frontLink = process.env.REACT_APP_URL;
const breadCurmbData = [
    {
        title: "Books",
        link: `${process.env.REACT_APP_FRONT_URL}book/list`,
    },
    {
        title: "Add",
        link: "",
    },
];

class bookAdd extends React.Component {
    state = { open: false, open2: false, direct: false }
    render() {
        return (
            <FusePageCarded
                classes={{
                    root: ""
                }}
                header={
                    <div className="p-24">
                        <Breadcrumb data={breadCurmbData} />
                    </div>
                }
                contentToolbar={
                    <div className="px-24">
                        <h2>Add book</h2>
                    </div>
                }

                content={
                    <div className="form-style"><BookForm mode="add" submit={this.submit} pageTitle={"Add Book"} obj={this} />
                        {this.state.direct === true && <Redirect to="/books/list" />}
                        <Dialog
                            open={this.state.open}>
                            <DialogContent><CircularProgress /></DialogContent>
                        </Dialog>

                        <Dialog
                            open={this.state.open2}>
                            <DialogContent>Book Added Successfully</DialogContent>
                        </Dialog>
                    </div>}
            />)
    }
    submit = async (state) => {

        var bodyFormData = new FormData();

        let bookBackUrl = "/book/add";

        if (state.bookSource === "reflowable") {
            bookBackUrl = "/book/add_ref";
        }
       
 
        bodyFormData.append("categoryId", state.categoryId);
        bodyFormData.append("agerateId", state.agerateId); 
        bodyFormData.append("tags", JSON.stringify(state.tags));
        bodyFormData.append("genresId", JSON.stringify(state.genresId)); 
        bodyFormData.append("ageGroup", state.ageGroup);  
        bodyFormData.append("descriptionen", state.descriptionen);
        bodyFormData.append("descriptionar", state.descriptionar);
        bodyFormData.append("language", state.language);
        bodyFormData.append("minTimeSpent", state.minTimeSpent);
        bodyFormData.append("nameEn", state.nameEn);
        bodyFormData.append("nameAr", state.nameAr);
        bodyFormData.append("pagesNo", state.pagesNo);
        bodyFormData.append("pointsNo", state.pointsNo);
        bodyFormData.append("coverPhoto", state.coverPhoto);
        bodyFormData.append("epubFile", state.epubFile);
        bodyFormData.append("screenshots", state.screenshots);
        bodyFormData.append("publisher", state.publisher);
        bodyFormData.append("publisherName", state.publisherName);
        bodyFormData.set("opf_file", state.opf_file);
        bodyFormData.set("html_file", state.html_file);
        bodyFormData.set("firstPageurl", state.firstPageurl);
        bodyFormData.append("coverPhoto", state.coverPhoto);  
        bodyFormData.append("bookSource", state.bookSource);
        bodyFormData.append("orignalFile", state.orignalFile);
        bodyFormData.append("status", state.status);  

        // bodyFormData.append("audioLength",state.audioLength);
 
        bodyFormData.append("type", state.type);
        bodyFormData.set("imgTabletLand", state.imgTabletLand);
        bodyFormData.set("imgTabletPort", state.imgTabletPort);

        bodyFormData.set("imgMobilePort", state.imgMobilePort);
        bodyFormData.set("imgMobileLand", state.imgMobileLand);


        this.setState({
            open: true
        });
        //  console.log(baseLink + bookBackUrl)
        /* axios({
             method: 'post',
             url: baseLink + bookBackUrl,
             data: bodyFormData,
             headers: {'Content-Type': 'multipart/form-data' }
             }).then( (res) => { */

        let res = await axios({
            method: 'POST',
            url: baseLink + bookBackUrl,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' }
        });

        /*  fetch(baseLink + bookBackUrl,{
              method:"post",
              body:bodyFormData,
              redirect: 'manual'
          }).then((res) => {*/


        //  setTimeout(() => {
        if (res.data.message === "done") {
            if (state.type === "video" || state.type === "audio") {
                this.setState({
                    open: false,
                    open2: true
                    // direct:true
                });

                setTimeout(() => {
                    this.setState({
                        //  open:false,
                        // open2:false,
                        direct: true
                    });
                }, 3000);
                // window.location.href="/books/list"; 

            }
            else if (state.bookSource === "reflowable") {

                this.setState({
                    open: false,
                    open2: false,
                    direct: true
                });
                setTimeout(() => {
                    window.location.href = "/frontend/books/list";
                }, 3000);

            }
            else if (state.bookSource === "Build in audio") {
                this.builtInAudio1(res.data.opf_file, res.data.id);


            }
            else {
                this.checkSpans(res.data.opf_file, res.data.id);
            }
        }
        // }, 5000);


        //            });



    }

    builtInAudio1 = (opf_link, bookId) => {
        let currentLink = frontLink + opf_link;
        let currentLink1 = currentLink.replace("content.opf", "");
        let opf_link2 = opf_link.replace("content.opf", "");
        let th = this;

        axios.get(currentLink).then(function (res) {
            let opf_data = new DOMParser().parseFromString(res.data, "text/xml");
            let spine_list = opf_data.querySelectorAll("spine itemref");
            let manifest_list = opf_data.querySelectorAll("manifest item");
            let page_ids = [];
            let pages = {};
            let smil_list = {};
            let audios = {};
            let MainObj = {};

            MainObj.bookId = bookId;
            MainObj.title = "story1";
            MainObj.version = "";

            spine_list.forEach(function (itemref) {
                //console.log(itemref.getAttribute("idref"));
                page_ids.push(itemref.getAttribute("idref"));
            });
            manifest_list.forEach(function (itemref) {
                let id = itemref.getAttribute("id");
                let media_type = itemref.getAttribute("media-type");
                if (media_type === "application/smil+xml") {
                    smil_list[id] = itemref.getAttribute("href");
                }
                if (media_type === "audio/mpeg") {
                    let href = itemref.getAttribute("href").split("/");
                    let audioLink = href[href.length - 1];
                    audios[audioLink] = itemref.getAttribute("href");
                }
                if (page_ids.indexOf(id) !== -1) {
                    pages[id] = {};
                    pages[id].pagePath = "/" + itemref.getAttribute("href");

                    if (itemref.getAttribute("media-overlay") !== null) {
                        pages[id].smilId = itemref.getAttribute("media-overlay");
                    }
                }
            });
            pages = Object.values(pages);
            let pages_span_ids = [];
            th.builtInEditPage(0, pages, pages_span_ids, currentLink1, smil_list, opf_link2, MainObj, bookId)

        });
    }

    builtInEditPage = (indx, pages, pages_span_ids, currentLink1, smil_list, opf_link2, MainObj, bookId) => {
        let th = this;

        if (indx < pages.length) {
            pages[indx].pageId = indx;
            pages[indx].smilAudios = [];

            pages[indx].voiceRecords = [];
            pages[indx].notes = [];
            pages[indx].pageAudioType = "builtInAudio";
            pages[indx].drawings = [];
            pages[indx].highlights = [];
            pages_span_ids[indx] = [];


            pages[indx]['smilAudios'][0] = {};
            pages[indx]['smilAudios'][0].spans = []


            if (pages[indx].smilId !== undefined) {
                let audios = [];
                // console.log(currentLink1 + smil_list[pageItem.smilId]);
                axios.get(currentLink1 + smil_list[pages[indx].smilId]).then(function (res) {
                    let doc3 = new DOMParser().parseFromString(res.data, "text/xml");
                    let smilLink = opf_link2 + smil_list[pages[indx].smilId];
                    let points = {};
                    points.points = [];

                    doc3.querySelectorAll("par").forEach(function (item, idx) {

                        let select_id = item.querySelector("text").getAttribute("src").split("#")[1];
                        let page_id = item.querySelector("text").getAttribute("src").split("#")[0];
                        item.querySelector("text").setAttribute("src", page_id + "#" + select_id + "-page" + indx);
                        let audio_src1 = item.querySelector("audio").getAttribute("src");
                        let audio_src = item.querySelector("audio").getAttribute("src").split("/");
                        let audio_start = item.querySelector("audio").getAttribute("clipBegin");
                        let audio_end = item.querySelector("audio").getAttribute("clipEnd");
                        let selected_audio = audio_src[audio_src.length - 1];
                        let obj = {}
                        pages[indx]['smilAudios'][0].audioLink = audios[selected_audio];
                        obj.start = audio_start;
                        obj.end = audio_end;
                        obj.textId = select_id + "-page" + indx;

                        pages_span_ids[indx].push(select_id);

                        if (audios.indexOf(audio_src1) === -1) {
                            audios.push(audio_src1);
                        }
                        points.points.push(obj);


                        delete pages[indx].smilId;
                    });
                    pages[indx]['smilAudios'][0].spans.push(points);
                    let smilHtml = doc3.querySelector("smil").outerHTML;
                    smilHtml = '<?xml version="1.0" encoding="utf-8"?>' + smilHtml;

                    axios.get(currentLink1 + pages[indx].pagePath).then(function (res) {
                        let htmlDom = new DOMParser().parseFromString(res.data, "text/xml");
                        let page_audios = document.createElement("div");
                        let viewport = htmlDom.querySelector("head meta[name=viewport]").content;

                        let PageWidth = viewport.split(",")[0].trim();
                        let PageHeight = viewport.split(",")[1].trim();
                        PageWidth = PageWidth.replace("width=", "");
                        PageHeight = PageHeight.replace("height=", "");

                        page_audios.id = "page-audios";
                        if (htmlDom.getElementsByTagName("audio").length === 0) {

                            pages_span_ids[indx].forEach(function (spanItem) {
                                let spanDom = htmlDom.getElementById(spanItem);
                                spanDom.id = spanDom.id + "-page" + indx;
                            });
                            audios.forEach(function (item) {
                                let audioElem = document.createElement("audio");
                                audioElem.src = item;
                                page_audios.appendChild(audioElem);
                            });
                            htmlDom.getElementsByTagName("body")[0].appendChild(page_audios);
                            var bodyFormData = new FormData();
                            let html = htmlDom.getElementsByTagName("html")[0].outerHTML;
                            html = '<?xml version="1.0" encoding="utf-8"?><!DOCTYPE html>' + html;
                            var bodyFormData = new FormData();
                            bodyFormData.set("html", html);
                            bodyFormData.set("html_path", opf_link2 + pages[indx].pagePath);
                            bodyFormData.set("pageWidth", PageWidth);
                            bodyFormData.set("pageHeight", PageHeight);
                            bodyFormData.set("id", bookId);
                            bodyFormData.set("smill_url", smilLink);
                            bodyFormData.set("smill_html", smilHtml);



                            axios({
                                method: 'post',
                                url: baseLink + '/builtin_upload_audios',
                                data: bodyFormData,
                                config: { headers: { 'Content-Type': 'multipart/form-data' } }
                            }).then(function (res) {

                                indx++;
                                th.builtInEditPage(indx, pages, pages_span_ids, currentLink1, smil_list, opf_link2, MainObj, bookId);

                            });


                        }
                    });
                });
            } else {
                indx++;
                th.builtInEditPage(indx, pages, pages_span_ids, currentLink1, smil_list, opf_link2, MainObj, bookId)
            }
        } else {
            MainObj.pages = pages;
            let bodyFormData = new FormData();
            bodyFormData.set("base_path", opf_link2);
            bodyFormData.set("book", JSON.stringify(MainObj));

            axios({
                method: 'post',
                url: baseLink + '/book/update_json',
                data: bodyFormData,
                config: { headers: { 'Content-Type': 'multipart/form-data' } }
            }).then(function (res) {
                th.setState({
                    open2: true,
                    open: false
                });
                setTimeout(() => {
                    th.setState({
                        open: false,
                        open2: false,
                        direct: true
                    });
                    window.location.href = "/frontend/books/list";
                }, 2000);
            });

        }
    }
    checkSpans = (opf_link, bookId) => {
        let th = this;
        let currentLink1 = opf_link.replace("content.opf", "");
        axios.get(frontLink + opf_link).then(function (res) {
            let opf_data = new DOMParser().parseFromString(res.data, "text/xml");
            let spine_list = opf_data.querySelectorAll("spine itemref");
            let manifest_list = opf_data.querySelectorAll("manifest item");
            let page_ids = [];
            let pages = {};


            spine_list.forEach(function (itemref) {
                //console.log(itemref.getAttribute("idref"));
                page_ids.push(itemref.getAttribute("idref"));
            });
            manifest_list.forEach(function (itemref) {
                let id = itemref.getAttribute("id");
                if (page_ids.indexOf(id) !== -1) {
                    pages[id] = {};
                    pages[id].pagePath = itemref.getAttribute("href");
                }
            });

            var arabic = /[\u0600-\u06FF]/;
            let page1 = Object.values(pages);
            //if(arabic.test(decodeURIComponent(page1[0].pagePath)) === true) {
            page_ids.forEach(function (itemref, itemindx) {
                opf_data.querySelector(`manifest item[id=${itemref}]`).setAttribute("href", `Text/page_${itemindx}.xhtml`);
            });
            var bodyFormData = new FormData();
            bodyFormData.set("html", opf_data.querySelector("package").outerHTML);
            bodyFormData.set("link", currentLink1 + "content.opf");

            axios({
                method: 'post',
                url: baseLink + '/save_opf',
                data: bodyFormData,
                config: { headers: { 'Content-Type': 'multipart/form-data' } }
            }).then(function (res) {
                th._checkPages(Object.values(pages), currentLink1, 0, bookId);
            });
            //  }


        });

    }
    _checkPages = (pages, currentLink1, count, bookId) => {
        let th = this;
        //  console.log(frontLink + currentLink1 + pages[count].pagePath);return;
        axios.get(frontLink + currentLink1 + pages[count].pagePath).then(function (res) {

            let doc = new DOMParser().parseFromString(res.data, "text/xml");

            let spans = doc.querySelectorAll("span");
            // let pTags = doc.querySelectorAll("p");
            // let divs = doc.querySelectorAll("div");
            /* pTags.forEach(function(pTag) {
                 if(pTag.innerText.trim().length === 0) {
                     pTag.remove();
                 }
             });*/
            /* divs.forEach(function(div) {
                 if(div.innerText.trim().length === 0) {
                     div.remove();
                 }
             });*/
            spans.forEach(function (span) {
                if (span.innerText.trim().length === 0) {
                    span.remove();
                }
            });


            let html = doc.querySelectorAll("html")[0].outerHTML;
            let viewport = doc.querySelector("head meta[name=viewport]").content;

            let PageWidth = viewport.split(",")[0].trim();
            let PageHeight = viewport.split(",")[1].trim();
            PageWidth = PageWidth.replace("width=", "");
            PageHeight = PageHeight.replace("height=", "");

            var bodyFormData = new FormData();
            // let fullpath = currentLink1.replace("http://")
            bodyFormData.set("html", html);
            bodyFormData.set("link", currentLink1 + pages[count].pagePath);
            bodyFormData.set("count", count);
            bodyFormData.set("pageCount", pages.length);
            if (count === 0) {
                bodyFormData.set("pageWidth", PageWidth);
                bodyFormData.set("pageHeight", PageHeight);
                bodyFormData.set("id", bookId);
            }
            axios({
                method: 'post',
                url: baseLink + '/save_html',
                data: bodyFormData,
                config: { headers: { 'Content-Type': 'multipart/form-data' } }
            }).then(function (res) {
                if (count === pages.length - 1) {
                    th.setState({
                        open2: true,
                        open: false
                    });
                    setTimeout(() => {
                        th.setState({
                            open: false,
                            open2: false,
                            direct: true
                        });
                        window.location.href = "/books/list";

                    }, 3500);
                }
                else {
                    count++;
                    th._checkPages(pages, currentLink1, count, bookId);
                }
            });




        });
    }

    builtInAudio = (opf_link) => {
        let currentLink = frontLink + opf_link;
        let currentLink1 = currentLink.replace("content.opf", "");
        let opf_link2 = opf_link.replace("content.opf", "");
        let th = this;

        axios.get(currentLink).then(function (res) {
            let opf_data = new DOMParser().parseFromString(res.data, "text/xml");
            let spine_list = opf_data.querySelectorAll("spine itemref");
            let manifest_list = opf_data.querySelectorAll("manifest item");
            let page_ids = [];
            let pages = {};
            let smil_list = {};
            let audios = {};
            let MainObj = {};

            MainObj.bookId = "story1";
            MainObj.title = "story1";
            MainObj.version = "";

            spine_list.forEach(function (itemref) {
                //console.log(itemref.getAttribute("idref"));
                page_ids.push(itemref.getAttribute("idref"));
            });
            manifest_list.forEach(function (itemref) {
                let id = itemref.getAttribute("id");
                let media_type = itemref.getAttribute("media-type");
                if (media_type === "application/smil+xml") {
                    smil_list[id] = itemref.getAttribute("href");
                }
                if (media_type === "audio/mpeg") {
                    let href = itemref.getAttribute("href").split("/");
                    let audioLink = href[href.length - 1];
                    audios[audioLink] = itemref.getAttribute("href");
                }
                if (page_ids.indexOf(id) !== -1) {
                    pages[id] = {};
                    pages[id].pagePath = itemref.getAttribute("href");

                    if (itemref.getAttribute("media-overlay") !== null) {
                        pages[id].smilId = itemref.getAttribute("media-overlay");
                    }
                }
            });
            pages = Object.values(pages);
            pages.forEach(function (pageItem, indx) {
                pages[indx].pageId = indx;
                pages[indx].smilAudios = {};

                pages[indx].voiceRecords = [];
                pages[indx].notes = [];
                pages[indx].pageAudioType = "builtInAudio";
                pages[indx].drawings = [];
                pages[indx].highlights = [];


                pages[indx]['smilAudios']['spans'] = [];



                if (pageItem.smilId !== undefined) {
                    axios.get(currentLink1 + smil_list[pageItem.smilId]).then(function (res) {
                        let doc3 = new DOMParser().parseFromString(res.data, "text/xml");
                        doc3.querySelectorAll("par").forEach(function (item) {
                            let select_id = item.querySelector("text").getAttribute("src").split("#")[1];
                            let audio_src = item.querySelector("audio").getAttribute("src").split("/");
                            let audio_start = item.querySelector("audio").getAttribute("clipBegin");
                            let audio_end = item.querySelector("audio").getAttribute("clipEnd");

                            let selected_audio = audio_src[audio_src.length - 1];
                            let obj = {}
                            pages[indx]['smilAudios'].audioLink = audios[selected_audio];
                            obj.start = audio_start;
                            obj.end = audio_end;
                            obj.textId = select_id;


                            pages[indx]['smilAudios']['spans'].push(obj);
                            delete pages[indx].smilId;
                        });
                    });
                }
                if (indx === pages.length - 1) {

                    MainObj.pages = pages;
                    setTimeout(() => {
                        let bodyFormData = new FormData();
                        bodyFormData.set("base_path", opf_link2);
                        bodyFormData.set("book", JSON.stringify(MainObj));

                        axios({
                            method: 'post',
                            url: baseLink + '/book/update_json',
                            data: bodyFormData,
                            config: { headers: { 'Content-Type': 'multipart/form-data' } }
                        }).then(function (res) {
                            th.setState({
                                open2: true,
                                open: false
                            });
                            setTimeout(() => {
                                th.setState({
                                    open: false,
                                    open2: false,
                                    direct: true
                                });
                                //  window.location.href="/books/list";  
                            }, 1500);
                        });

                    }, 1000);

                }
            });

        });
    }
}
export default bookAdd;