import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FusePageCarded } from '@fuse'
import { MenuItem, Button, Radio, Box, Typography } from '@material-ui/core'
import { TextFieldFormsy, SelectFormsy } from '@fuse'
import Formsy from 'formsy-react'
import Breadcrumb from './../components/Breadcrumb'
import axios from 'axios'
import FileList from '../file-manager/FileList'

import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Alert from '@material-ui/lab/Alert'

import { DateTimePicker } from '@material-ui/pickers'
import moment from 'moment'
import { PermMedia } from '@material-ui/icons'

const styles = theme => ({
  layoutRoot: {}
})

const breadCurmbData = [
  {
    title: 'Stories',
    link: `${process.env.REACT_APP_FRONT_URL}stories/list`
  },
  {
    title: 'Edit',
    link: ''
  }
]

class Edit extends Component {
  state = {
    formValid: false,
    titleEn: '',
    titleAr: '',
    coverImg: '',
    fileSrc: '',
    categoryId: 'none',
    categoryData: [],
    singerId: 'none',
    singerData: [],
    authorsId: 'none',
    authorsData: [],
    composersId: 'none',
    composersData: [],
    genresId: 'none',
    genreData: [],
    agerateId: 'none',
    agerateData: [],
    tagsId: [],
    tagsData: [],
    typeInput: 'Video',
    displayDatFile: 'none',
    loadingProgress: false,
    successMsg: 'Uploaded successfully',
    displayBtnFile: 'block',
    subtitleEn: '',
    subtitleAr: '',
    descriptionen: '',
    descriptionar: '',
    s3Information: [],
    isFree: false,
    isPaid: false,
    ErrorMsg1: '',
    showErrorMsg1: false,
    ErrorMsg2: '',
    showErrorMsg2: false,
    tagsData2: [],
    genreSelected: [],
    tagsSelected: [],
    fontScale: '',
    schedulingShow: false,
    scheduling: moment(new Date(), 'MM/DD/YYYY'),
    epubShow: false,
    videoShow: true,
    order: 0,
    level: 0
  }
  getTags = () => {
    return ['intersections']
  }
  handleChange = (e, name) => {
    if (name == 'isFree' || name == 'isPaid')
      this.setState({
        [name]: e.target.checked
      })
    else if (name == 'schedulingShow')
      this.setState({
        [name]: e.target.checked
      })
    else {
      this.setState({
        [name]: e.target.value
      })
    }
  }
  handleChangetags = (val, name) => {
    let ids = val.map(item => {
      return item._id
    })
    if (name == 'genreSelected')
      this.setState({
        genresId: ids,
        [name]: val
      })
    else
      this.setState({
        tagsId: ids,
        [name]: val
      })
  }

  handleChange2 = e => {
    this.setState({
      [e.target.name]: e.target.checked
    })
  }

  handleSubmit = model => {
    let backend_url = process.env.REACT_APP_BACKEND_URL
    let formData = new FormData()

    if (!this.state.showErrorMsg2 && !this.state.showErrorMsg1) {
      if (this.state.successMsg == '') {
        if ((this.state.fileSrc.size / (1024 * 1024)).toFixed(2) > 0) {
          this.uploadfiles3()
        }
      } else {
        formData.append('titleEn', this.state.titleEn)
        formData.append('titleAr', this.state.titleAr)
        formData.append('subtitleEn', this.state.subtitleEn)
        formData.append('subtitleAr', this.state.subtitleAr)
        formData.append('descriptionen', this.state.descriptionen)
        formData.append('descriptionar', this.state.descriptionar)
        formData.append('authorsId', this.state.authorsId)
        formData.append('categoryId', this.state.categoryId)
        formData.append('singerId', this.state.singerId)
        formData.append('composersId', this.state.composersId)
        formData.append('agerateId', this.state.agerateId)
        formData.append('tagsId', this.state.tagsId)
        formData.append('isFree', this.state.isFree)
        formData.append('isPaid', this.state.isPaid)
        formData.append('genresId', this.state.genresId)
        formData.append('coverImg', this.state.coverImg)
        formData.append('fileSrc', this.state.fileSrc)
        formData.append('fontScale', this.state.fontScale)
        formData.append('order', parseInt(this.state.order))
        formData.append('level', parseInt(this.state.level))

        formData.append('epubShow', this.state.epubShow)
        formData.append('videoShow', this.state.videoShow)

        formData.append(
          's3Information',
          JSON.stringify(this.state.s3Information)
        )
        formData.append('id', this.props.match.params.id)
        formData.append('schedulingStatus', this.state.schedulingShow)
        formData.append('scheduling', this.state.scheduling)

        axios({
          url: `${backend_url}/stories/update`,
          data: formData,
          method: 'post',
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(res => {
          this.props.history.push('/frontend/stories/list')
        })
      }
    }
  }
  enableButton = () => {
    this.setState({
      formValid: true
    })
  }

  disableButton = () => {
    this.setState({
      formValid: false
    })
  }

  fileUpload = (e, id) => {
    let image = document.getElementById(id)
    image.src = URL.createObjectURL(e.target.files[0])
    this.setState({
      coverImg: e.target.files[0]
    })
  }
  //////
  fileUpload2 = e => {
    // console.log(e.target.files[0])
    if (e.target.files[0].type.toString().substring(0, 5) == 'video') {
      this.setState({
        fileSrc: e.target.files[0],
        displayDatFile: 'block',
        ErrorMsg2: '',
        showErrorMsg2: false,
        typeInput: 'Video',
        successMsg: ''
      })
    } else if (e.target.files[0].type.toString().substring(0, 5) == 'audio') {
      this.setState({
        fileSrc: e.target.files[0],
        displayDatFile: 'block',
        ErrorMsg2: '',
        showErrorMsg2: false,
        typeInput: 'Audio',
        successMsg: ''
      })
    } else {
      this.setState({
        ErrorMsg2: 'The file type is wrong',
        showErrorMsg2: true,
        fileSrc: '',
        displayDatFile: 'none'
      })
    }
  }
  uploadfiles3 = () => {
    let backend_url = process.env.REACT_APP_BACKEND_URL
    let formData = new FormData()

    formData.append('fileSrc', this.state.fileSrc)
    formData.append('typeInput', this.state.typeInput)
    formData.append('titleEn', this.state.titleEn)

    this.setState({
      loadingProgress: true
    })
    axios({
      url: `${backend_url}/uploadfiletos3`,
      data: formData,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      // console.log(res.data.result);
      this.setState({
        s3Information: res.data.result,
        loadingProgress: false,
        fileSrc: res.data.namefile,
        successMsg: res.data.message,
        displayBtnFile: 'none'
      })
      if (res.data.message == 'Uploaded successfully') {
        this.handleSubmit()
      }
    })
  }

  handleSelect = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  componentDidMount () {
    let backend_url = process.env.REACT_APP_BACKEND_URL
    let id = this.props.match.params.id

    let data = {
      id: id
    }

    axios({
      url: `${backend_url}/stories/get`,
      data: data,
      method: 'post',
      headers: { 'Content-Type': 'application/json' }
    }).then(res => {
      this.setState(res.data.result)
      //{process.env.REACT_APP_BACKEND_URL+'/public/'+this.state.singerImg}

      if (res.data.result.schedulingStatus) {
        this.setState({
          schedulingShow: res.data.result.schedulingStatus === 'true',
          scheduling: res.data.result.scheduling
        })
      }

      this.setState({
        genresId: JSON.stringify(res.data.result.genresId)
      })
      let image = document.getElementById('cover-image')
      image.src =
        process.env.REACT_APP_BACKEND_URL +
        '/public/' +
        res.data.result.coverImg
      let data2 = {
        genresId: res.data.result.genresId
      }
      let data3 = {
        tagsId: res.data.result.tagsId
      }

      console.log({
        videoShow: res.data.result.videoShow === 'true',
        epubShow: res.data.result.epubShow === 'true'
      })
      this.setState({
        videoShow: res.data.result.videoShow === 'true',
        epubShow: res.data.result.epubShow === 'true'
      })

      axios({
        url: `${backend_url}/genres/getgenres`,
        data: data2,
        method: 'post',
        headers: { 'Content-Type': 'application/json' }
      }).then(res2 => {
        console.log('res2.data')
        console.log(res2.data)
        this.setState({
          genreSelected: res2.data.items
        })
      })

      axios({
        url: `${backend_url}/tags/gettags`,
        data: data3,
        method: 'post',
        headers: { 'Content-Type': 'application/json' }
      }).then(res2 => {
        this.setState({
          tagsSelected: res2.data.items
        })
      })
    })

    ////

    axios({
      url: `${backend_url}/category/list`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' }
    }).then(res => {
      this.setState({
        categoryData: res.data.result
      })
    })
    //////////
    axios({
      url: `${backend_url}/singer/list`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' }
    }).then(res => {
      this.setState({
        singerData: res.data.result
      })
    })
    //////////
    axios({
      url: `${backend_url}/authors/list`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' }
    }).then(res => {
      this.setState({
        authorsData: res.data.result
      })
    })
    //////////
    axios({
      url: `${backend_url}/Composers/list`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' }
    }).then(res => {
      this.setState({
        composersData: res.data.result
      })
    })
    //////////
    axios({
      url: `${backend_url}/genre/list`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' }
    }).then(res => {
      this.setState({
        genreData: res.data.result
      })
    })
    //////////
    axios({
      url: `${backend_url}/agerate/list`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' }
    }).then(res => {
      this.setState({
        agerateData: res.data.result
      })
    })
    axios({
      url: `${backend_url}/tags/list`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' }
    }).then(res => {
      this.setState({
        tagsData: res.data.result
      })
    })
    //////////////end
  }
  render () {
    const { classes } = this.props
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot
        }}
        header={
          <div className='p-24'>
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className='px-24'>
            <h2>Edit story</h2>
          </div>
        }
        content={
          <div className='p-24'>
            <div className='max-w-lg'>
              <Formsy
                onValidSubmit={this.handleSubmit}
                onValid={this.enableButton}
                onInvalid={this.disableButton}
                ref={'song_edit'}
                className='flex flex-col p6-12'
              >
                <div className='flex flex-row justify-between py-20'>
                  <TextFieldFormsy
                    className='w-5/12'
                    label='Title En'
                    autoFocus
                    id='titleEn'
                    name='titleEn'
                    variant='outlined'
                    value={this.state.titleEn}
                    onChange={e => {
                      this.handleChange(e, 'titleEn')
                    }}
                    required
                  />
                  <TextFieldFormsy
                    className='w-5/12'
                    label='Title En'
                    id='titleAr'
                    name='titleAr'
                    variant='outlined'
                    value={this.state.titleAr}
                    onChange={e => {
                      this.handleChange(e, 'titleAr')
                    }}
                    required
                  />
                </div>

                <div className='flex flex-row justify-between py-10'>
                  <TextFieldFormsy
                    className='w-5/12'
                    label='Subtitle En'
                    autoFocus
                    id='subtitleEn'
                    name='subtitleEn'
                    variant='outlined'
                    value={this.state.subtitleEn}
                    onChange={e => {
                      this.handleChange(e, 'subtitleEn')
                    }}
                  />

                  <TextFieldFormsy
                    className='w-5/12'
                    label='Subtitle Ar'
                    id='subtitleAr'
                    name='subtitleAr'
                    variant='outlined'
                    value={this.state.subtitleAr}
                    onChange={e => {
                      this.handleChange(e, 'subtitleAr')
                    }}
                  />
                </div>

                <div className='flex flex-row justify-between py-10'>
                  <TextFieldFormsy
                    label='Description En'
                    multiline
                    rows={4}
                    value={this.state.descriptionen}
                    variant='outlined'
                    className='w-5/12'
                    id='descriptionen'
                    name='descriptionen'
                    onChange={e => {
                      this.handleChange(e, 'descriptionen')
                    }}
                    required
                  />
                  <TextFieldFormsy
                    label='Description Ar'
                    multiline
                    rows={4}
                    value={this.state.descriptionar}
                    variant='outlined'
                    className='w-5/12'
                    id='descriptionar'
                    name='descriptionar'
                    onChange={e => {
                      this.handleChange(e, 'descriptionar')
                    }}
                    required
                  />
                </div>

                <div className='flex flex-row justify-between py-10'>
                  <SelectFormsy
                    className='w-5/12'
                    name='artist'
                    label='Artist'
                    value={this.state.authorsId}
                    onChange={e => {
                      this.handleChange(e, 'authorsId')
                    }}
                    variant='outlined'
                  >
                    <MenuItem value='none'>
                      <em>None</em>
                    </MenuItem>
                    {this.state.authorsData.map(item => {
                      return <MenuItem value={item._id}>{item.nameEn}</MenuItem>
                    })}
                  </SelectFormsy>
                  <SelectFormsy
                    className='w-5/12'
                    name='singer'
                    label='Singer'
                    value={this.state.singerId}
                    onChange={e => {
                      this.handleChange(e, 'singerId')
                    }}
                    variant='outlined'
                  >
                    <MenuItem value='none'>
                      <em>None</em>
                    </MenuItem>
                    {this.state.singerData.map(item => {
                      return <MenuItem value={item._id}>{item.nameEn}</MenuItem>
                    })}
                  </SelectFormsy>
                </div>

                <div className='flex flex-row justify-between py-10'>
                  <SelectFormsy
                    className='w-5/12'
                    name='composer'
                    label='Composer'
                    value={this.state.composersId}
                    onChange={e => {
                      this.handleChange(e, 'composersId')
                    }}
                    variant='outlined'
                  >
                    <MenuItem value='none'>
                      <em>None</em>
                    </MenuItem>
                    {this.state.composersData.map(item => {
                      return <MenuItem value={item._id}>{item.nameEn}</MenuItem>
                    })}
                  </SelectFormsy>
                  <Autocomplete
                    className='w-5/12'
                    multiple
                    id='tags-outlined'
                    filterSelectedOptions
                    options={this.state.genreData}
                    value={this.state.genreSelected}
                    getOptionLabel={option =>
                      option.titleEn + ' - ' + option.titleAr
                    }
                    onChange={(event, value) => {
                      this.handleChangetags(value, 'genreSelected')
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant='outlined'
                        label='Genre'
                        placeholder=''
                      />
                    )}
                  />
                </div>

                <div className='flex flex-row justify-between py-10'>
                  <SelectFormsy
                    className='w-5/12'
                    name='category'
                    label='Category'
                    value={this.state.categoryId}
                    onChange={e => {
                      this.handleChange(e, 'categoryId')
                    }}
                    variant='outlined'
                  >
                    <MenuItem value='none'>None</MenuItem>
                    {this.state.categoryData.map(item => {
                      return (
                        <MenuItem value={item._id}>{item.titleEn}</MenuItem>
                      )
                    })}
                  </SelectFormsy>
                  <SelectFormsy
                    className='w-5/12'
                    name='ageRate'
                    label='Age Rate'
                    value={this.state.agerateId}
                    onChange={e => {
                      this.handleChange(e, 'agerateId')
                    }}
                    variant='outlined'
                  >
                    <MenuItem value='none'>
                      <em>None</em>
                    </MenuItem>
                    {this.state.agerateData.map(item => {
                      return (
                        <MenuItem value={item._id}>{item.titleEn}</MenuItem>
                      )
                    })}
                  </SelectFormsy>
                </div>

                <div className='flex flex-row justify-between '>
                  <div
                    className='w-full'
                    style={{
                      marginBottom: '2vw'
                    }}
                  >
                    <Autocomplete
                      multiple
                      id='tags-outlined'
                      filterSelectedOptions
                      options={this.state.tagsData}
                      value={this.state.tagsSelected}
                      getOptionLabel={option =>
                        option.titleEn + ' - ' + option.titleAr
                      }
                      onChange={(event, value) => {
                        this.handleChangetags(value, 'tagsSelected')
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant='outlined'
                          label='Tags'
                          placeholder=''
                        />
                      )}
                    />
                  </div>
                </div>

                <div className='flex flex-row justify-between '>
                  <div
                    className='w-full'
                    style={{
                      marginBottom: '2vw'
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.schedulingShow}
                          color='primary'
                          onChange={e => {
                            this.handleChange(e, 'schedulingShow')
                          }}
                          name='Scheduling'
                        />
                      }
                      label='Scheduling'
                      labelPlacement='left'
                    />
                    {this.state.schedulingShow && (
                      <DateTimePicker
                        label='Scheduling'
                        inputVariant='outlined'
                        value={this.state.scheduling}
                        onChange={date => this.setState({ scheduling: date })}
                        className='mt-8 mb-16 w-full'
                        minDate={this.state.scheduling}
                      />
                    )}
                  </div>
                </div>

                <div className='flex flex-row center py-20'>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.isFree}
                        color='primary'
                        onChange={e => {
                          this.handleChange(e, 'isFree')
                        }}
                        name='freev'
                      />
                    }
                    label='Is Free'
                    labelPlacement='top'
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.isPaid}
                        color='primary'
                        onChange={e => {
                          this.handleChange(e, 'isPaid')
                        }}
                        name='freev'
                      />
                    }
                    label='Exclusive'
                    labelPlacement='top'
                  />
                </div>

                <div
                  className='flex flex-row justify-between py-20'
                  style={{
                    backgroundColor: '#f8f8f8',
                    padding: '2vw',
                    display: 'flex',
                    justifyContent: 'space-around',
                    borderRadius: '5px',
                    boxShadow: '0 0 5px #e7e7e7'
                  }}
                >
                  <div className='flex justify-between items-center w-8/12'>
                    <Button variant='contained' component='label'>
                      Choose other image
                      <input
                        type='file'
                        onChange={e => {
                          this.fileUpload(e, 'cover-image')
                        }}
                        style={{ display: 'none' }}
                      />
                    </Button>
                    <img
                      alt=''
                      id='cover-image'
                      title='cover-image'
                      style={{ maxWidth: '50%' }}
                      src=''
                    />
                  </div>
                </div>
                {this.state.showErrorMsg1 && (
                  <Alert severity='error'>{this.state.ErrorMsg1}</Alert>
                )}
                <div
                  style={{
                    backgroundColor: '#f8f8f8',
                    padding: '2vw',
                    display: 'flex',
                    justifyContent: 'space-around',
                    borderRadius: '5px',
                    boxShadow: '0 0 5px #e7e7e7',
                    flexDirection: 'column',
                    marginTop: '2vw'
                  }}
                >
                  <TextFieldFormsy
                    label='File Src:'
                    value={this.state.fileSrc}
                    variant='outlined'
                    className='w-full mb-12'
                    id='File Src'
                    name='File Src'
                  />
                  <video
                    ref='vidRef'
                    controls
                    src={this.state.s3Information?.Location}
                  />

                  <div
                    className='flex justify-between items-center w-5/12'
                    style={{ paddingTop: '10px' }}
                  >
                    <Button
                      variant='contained'
                      component='label'
                      style={{ display: this.state.displayBtnFile }}
                    >
                      <CloudUploadIcon />
                      &nbsp; &nbsp; Choose other file
                      <input
                        type='file'
                        onChange={e => {
                          this.fileUpload2(e)
                        }}
                        style={{ display: 'none' }}
                      />
                    </Button>
                  </div>

                  <Backdrop
                    className={classes.backdrop}
                    open={this.state.loadingProgress}
                    style={{
                      zIndex: 9999,
                      color: '#fff'
                    }}
                  >
                    <CircularProgress color='inherit' />
                  </Backdrop>

                  <div
                    className='flex justify-between items-center w-5/12'
                    style={{ paddingTop: '10px' }}
                  >
                    <h3 style={{ display: this.state.displayDatFile }}>
                      <div style={{ display: this.state.displayBtnFile }}>
                        File Name: {this.state.fileSrc.name}
                        <br></br>
                        File Size:{' '}
                        {(this.state.fileSrc.size / (1024 * 1024)).toFixed(
                          2
                        )}{' '}
                        MB
                      </div>
                      <h2>{this.state.successMsg}</h2>
                    </h3>
                  </div>
                  {this.state.showErrorMsg2 && (
                    <Alert severity='error'>{this.state.ErrorMsg2}</Alert>
                  )}
                </div>

                <div className='flex flex-row justify-between py-20'>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.videoShow}
                        color='primary'
                        onChange={e => {
                          this.handleChange2(e)
                        }}
                        name='videoShow'
                      />
                    }
                    label='Video'
                    labelPlacement='left'
                  />
                </div>

                {this.state.videoShow ? (
                  <>
                    <div
                      className='flex justify-between items-center'
                      style={{ paddingTop: '10px' }}
                    >
                      <Button
                        variant='contained'
                        component='label'
                        style={{ display: this.state.displayBtnFile }}
                      >
                        <CloudUploadIcon />
                        &nbsp; &nbsp; Upload Meida file
                        <input
                          accept='video/mp4'
                          type='file'
                          onChange={e => {
                            this.fileUpload2(e)
                          }}
                          style={{ display: 'none' }}
                        />
                      </Button>
                      <Button
                        variant='contained'
                        component='label'
                        onClick={() => {
                          this.setState({
                            openList: this.state.openList ? false : true
                          })
                        }}
                      >
                        <PermMedia />
                        &nbsp; &nbsp; Choose from library
                      </Button>
                    </div>
                    <br></br>
                    <h2>{this.state.successMsg}</h2>
                    <FileList
                      openList={this.state.openList}
                      setFile={this.setFile}
                    />

                    <Backdrop
                      className={classes.backdrop}
                      open={this.state.loadingProgress}
                      style={{
                        zIndex: 999999,
                        color: '#fff'
                      }}
                    >
                      <Box position='relative' display='inline-flex'>
                        <CircularProgress color='secondary' />

                        <Box
                          top={0}
                          left={0}
                          bottom={0}
                          right={0}
                          position='absolute'
                          display='flex'
                          alignItems='center'
                          justifyContent='center'
                        >
                          <Typography
                            variant='caption'
                            component='div'
                            color='textSecondary'
                          >
                            {this.state.progVal}%
                          </Typography>
                        </Box>
                      </Box>
                    </Backdrop>
                    <div
                      className='flex justify-between items-center w-5/12'
                      style={{ paddingTop: '10px' }}
                    >
                      <h3 style={{ display: this.state.displayDatFile }}>
                        <div style={{ display: this.state.displayBtnFile }}>
                          File Name: {this.state.fileSrc.name}
                          <br></br>
                          File Size:{' '}
                          {(this.state.fileSrc.size / (1024 * 1024)).toFixed(
                            2
                          )}{' '}
                          MB
                        </div>
                        <h2>{this.state.successMsg}</h2>
                      </h3>
                    </div>
                  </>
                ) : (
                  ''
                )}

                {this.state.showErrorMsg2 && (
                  <Alert severity='error'>{this.state.ErrorMsg2}</Alert>
                )}

                <div className='flex flex-row justify-between py-20'>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.epubShow}
                        color='primary'
                        onChange={e => {
                          this.handleChange2(e)
                        }}
                        name='epubShow'
                      />
                    }
                    label='Epub'
                    labelPlacement='left'
                  />
                </div>

                {this.state.epubShow ? (
                  <>
                    <div className='form-item w-1/2 p-12'>
                      <SelectFormsy
                        label='Book source'
                        onChange={e => {
                          this.handleSelect(e)
                        }}
                        name='bookSource'
                        value={
                          this.state.bookSource == ''
                            ? 'in design'
                            : this.state.bookSource
                        }
                        variant='outlined'
                        className='w-full'
                        required
                      >
                        <MenuItem value={'in design'}>In design</MenuItem>
                        <MenuItem value={'reflowable'}>Reflowable</MenuItem>
                        <MenuItem value={'Build in audio'}>
                          Build in audio
                        </MenuItem>
                      </SelectFormsy>
                    </div>

                    <div className='form-item w-1/2 p-12'>
                      <SelectFormsy
                        label='Language'
                        onChange={e => {
                          this.handleSelect(e)
                        }}
                        name='language'
                        value={this.state.language}
                        variant='outlined'
                        className='w-full'
                      >
                        <MenuItem value={'en'}>En</MenuItem>
                        <MenuItem value={'ar'}>Ar</MenuItem>
                      </SelectFormsy>
                    </div>
                    <div className='form-item w-1/2 p-12'>
                      <TextFieldFormsy
                        className='w-full'
                        label='Font Scale'
                        id='fontScale'
                        name='fontScale'
                        variant='outlined'
                        value={this.state.fontScale}
                        onChange={e => {
                          this.handleChange(e, 'fontScale')
                        }}
                      />
                    </div>
                    <div className='form-item upload-item w-1/2 p-12'>
                      <div className='upload-item-container'>
                        <div className='upload-item-container2'>
                          <Button
                            variant='contained'
                            component='label'
                            style={{ display: this.state.displayBtnFile }}
                          >
                            <CloudUploadIcon />
                            &nbsp; &nbsp; Upload Epub book
                            <input
                              type='file'
                              id='epub_file'
                              onChange={this.uploadEpub2}
                              style={{ display: 'none' }}
                            />
                          </Button>
                          <br />
                          {this.state.epubFile && (
                            <div>
                              File Name: {this.state?.epubFile?.name}
                              <br></br>
                              File Size:{' '}
                              {(
                                this.state?.epubFile?.size /
                                (1024 * 1024)
                              ).toFixed(2)}{' '}
                              MB
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}

                <div className='flex flex-row justify-between py-10'>
                  <TextFieldFormsy
                    className='w-2/12'
                    label='Order'
                    id='order'
                    name='order'
                    variant='outlined'
                    value={this.state.order}
                    onChange={e => {
                      this.handleChange(e, 'order')
                    }}
                  />

                  <TextFieldFormsy
                    className='w-2/12'
                    label='Level'
                    id='level'
                    name='level'
                    variant='outlined'
                    value={this.state.level}
                    onChange={e => {
                      this.handleChange(e, 'level')
                    }}
                  />
                </div>

                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  className='ml-auto my-16'
                  aria-label='Add'
                  disabled={!this.state.formValid}
                >
                  Save
                </Button>
              </Formsy>
            </div>
          </div>
        }
      />
    )
  }
}

export default withStyles(styles, { withTheme: true })(Edit)
