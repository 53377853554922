import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import Formsy from "formsy-react";
import Breadcrumb from "./../components/Breadcrumb";
import axios from "axios";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Icon from '@material-ui/core/Icon';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/Delete';
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link, useParams } from 'react-router-dom';
import { hr } from "date-fns/locale";


import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from '@material-ui/core/Switch';


const styles = (theme) => ({
    layoutRoot: {},
});


class Add extends Component {
    state = {
        formValid: false,
        titleEn: "",
        titleAr: "",
        categoryData: [],
        tabIndex: 0,
        type: "",
        typeContent: "",
        songsData2: [],
        setSongsData2: [],
        Quantity: 1,
        tagsId: [],
        status: false

    };
    handleChange = (e, name) => {
        this.setState({
            [name]: e.target.value,
        });
        if (name == 'typeContent') {
            let backend_url = process.env.REACT_APP_BACKEND_URL;

            let formData = new FormData();

            formData.append("keyword", '');
            formData.append("indexing", 999999);

            axios({
                url: `${backend_url}/${e.target.value}/list`,
                data: formData,
                method: "post",
                headers: { "Content-Type": "application/json" },
            }).then((res) => {
                this.setState({
                    songsData2: res.data.result,
                    setSongsData2: []
                });
            });
        }
        //////

        if (name == 'Quantity') {
            if (e.target.value < 0) {
                this.setState({
                    [name]: 1,
                });
            }
        }
    };

    enableButton = () => {
        this.setState({
            formValid: true,
        });
    };

    disableButton = () => {
        this.setState({
            formValid: false,
        });
    };


    addtoarry = (value, name, name2) => {
        // console.log(value._id)
        let sta = this.state;
        ///delete form old
        this.setState({
            [name2]: sta[name2].filter(item => item._id != value._id)
        })
        ///add form old
        this.setState({
            [name]: sta[name].concat([value])
        })
    }

    backAddItem = (val) => {
        let sta = this.state;
        ///add form 
        let name = 'setSongsData2';
        let name2 = 'songsData2';
        let obj = val;

        this.setState({
            [name2]: sta[name2].concat([obj])
        })

        this.setState({
            [name]: sta[name].filter(item => item._id != obj._id),
            openpopupDelete: false,
        })
    }
    handleChangetags = (val, name) => {
        let ids = val.map((item) => {
            return item._id
        })
        this.setState({
            [name]: ids,
        });

    };

    submitAdd = () => {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();
        let showsId2 = this.state.setSongsData2.map((item) => {
            return item._id
        })
        formData.append("titleEn", this.state.titleEn);
        formData.append("titleAr", this.state.titleAr);
        formData.append("typeContent", this.state.typeContent);
        formData.append("type", this.state.type);
        formData.append("status", this.state.status);
        ///// content of latestReleases & editorChoice
        if (this.state.type == 'latestReleases' || this.state.type == 'editorChoice')
            formData.append("contentRow", JSON.stringify(showsId2));
        ///// quantity of categories
        if (this.state.type == 'categories') {
            formData.append("quantity", this.state.Quantity);
            formData.append("Categories", JSON.stringify(this.state.tagsId));
        }

        axios({
            url: `${backend_url}/mainScreen/addorw`,
            data: formData,
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            if (res.data.message) {
                this.props.history.push('/frontend/appsettings/rowsapp');
            }

        });
    }

    componentDidMount() {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        axios({
            url: `${backend_url}/category/list`,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            this.setState({
                tagsData: res.data.result,
            });
        });
    }
    render() {
        const { classes } = this.props;
        return (
            <FusePageCarded
                classes={{
                    root: classes.layoutRoot,
                }}
                header={
                    <div className="flex flex-1 w-full items-center justify-between">
                        <div className="flex flex-col items-start max-w-full">
                            <Typography
                                className="normal-case flex items-center sm:mb-12"
                                component={Link}
                                role="button"
                                to="/frontend/appsettings/rowsapp"
                                color="inherit"
                            >
                                <Icon className="text-20">
                                    {'ltr' === 'ltr' ? 'arrow_back' : 'arrow_forward'}
                                </Icon>
                                <span className="mx-4">App Section Content</span>
                            </Typography>

                            <div className="flex items-center max-w-full">

                                <div className="flex flex-col min-w-0 mx-8 sm:mc-16">
                                    <Typography className="text-16 sm:text-20 truncate">
                                        New Row

										</Typography>
                                </div>
                            </div>
                        </div>
                        <Button
                            className="whitespace-no-wrap normal-case"
                            variant="contained"
                            color="secondary"
                            disabled={!this.state.formValid}
                            onClick={() => {
                                this.submitAdd();
                            }}
                        >
                            Add
							</Button>
                    </div>
                }
                contentToolbar={
                    <Tabs
                        value={this.state.tabIndex}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        classes={{ root: 'w-full h-64' }}
                    >
                        <Tab className="h-64 normal-case" label="Basic Info" onClick={() => {
                            this.setState({
                                tabIndex: 0
                            })
                        }} />
                        {
                            (this.state.type == 'latestReleases' || this.state.type == 'editorChoice') && (
                                <Tab className="h-64 normal-case" label="Row Content" onClick={() => {
                                    this.setState({
                                        tabIndex: 1
                                    })
                                }} />
                            )
                        }

                    </Tabs>
                }
                content={


                    <div className="p-16 sm:p-24 max-w-2xl">
                        {this.state.tabIndex === 0 && (
                            <div>
                                <Formsy
                                    onValidSubmit={this.handleSubmit}
                                    onValid={this.enableButton}
                                    onInvalid={this.disableButton}
                                    ref={"cateogry_add"}
                                    className="flex flex-col p6-12"
                                >
                                    <div className="w-full" >
                                        <FormControlLabel
                                            control={<Switch checked={this.state.status}

                                                color="primary"
                                                onChange={(e) => {
                                                    this.setState({
                                                        status: e.target.checked,
                                                    });
                                                }}
                                                name="status" />}
                                            label={this.state.status ? 'Enable' : 'Disable'}
                                            labelPlacement="left"

                                        />

                                        <Divider />
                                    </div>
                                    <div className="flex flex-row justify-between py-20">



                                        <TextFieldFormsy
                                            className="w-5/12"
                                            type="text"
                                            name="nameEn"
                                            label="Title EN"
                                            onChange={(e) => {
                                                this.handleChange(e, "titleEn");
                                            }}
                                            value={this.state.titleEn}
                                            variant="outlined"
                                            required
                                        />
                                        <TextFieldFormsy
                                            className="w-5/12"
                                            type="text"
                                            name="nameAr"
                                            label="Title AR"
                                            required
                                            variant="outlined"
                                            value={this.state.titleAr}
                                            onChange={(e) => {
                                                this.handleChange(e, "titleAr");
                                            }}
                                        />

                                    </div>

                                    <div className="flex flex-row justify-between py-20">
                                        <SelectFormsy
                                            className="w-5/12"
                                            name="artist"
                                            label="Content Type"
                                            value={this.state.typeContent}
                                            onChange={(e) => {
                                                this.handleChange(e, "typeContent");
                                            }}
                                            variant="outlined"
                                            required
                                        >
                                            <MenuItem value="songs">
                                                Songs
                                        </MenuItem>
                                            <MenuItem value="shows">
                                                Shows
                                        </MenuItem>
                                            <MenuItem value="stories">
                                                Stories
                                        </MenuItem>

                                        </SelectFormsy>

                                        {this.state.typeContent != '' && (
                                            <SelectFormsy
                                                className="w-5/12"
                                                name="artist"
                                                label="Type"
                                                value={this.state.type}
                                                onChange={(e) => {
                                                    this.handleChange(e, "type");
                                                }}
                                                variant="outlined"
                                                required
                                            >
                                                <MenuItem value="interests">
                                                    Interests
                                                </MenuItem>
                                                <MenuItem value="latestReleases">
                                                    Latest releases
                                                </MenuItem>
                                                <MenuItem value="editorChoice">
                                                    Editor Choice
                                                </MenuItem>
                                                <MenuItem value="categories">
                                                    Categories
                                                </MenuItem>
                                            </SelectFormsy>
                                        )}

                                    </div>
                                    {this.state.type == 'categories' && (

                                        <div >
                                            <Paper className="rounded-0 shadow-none lg:rounded-8 lg:shadow-1">
                                                <div className="p-24 flex items-center">

                                                    <TextFieldFormsy
                                                        className="mt-8 mb-16"
                                                        label="Quantity"
                                                        id="quantity"
                                                        name="quantity"
                                                        variant="outlined"
                                                        type="number"
                                                        value={this.state.Quantity}
                                                        onChange={(e) => {
                                                            this.handleChange(e, "Quantity");
                                                        }}
                                                    />
                                                </div>
                                                {/* <Divider /> */}
                                                <div className="w-full" >
                                                    <Autocomplete
                                                        multiple
                                                        id="tags-outlined"
                                                        options={this.state.tagsData}
                                                        filterSelectedOptions
                                                        getOptionLabel={(option) => option.titleEn}
                                                        onChange={(event, value) =>

                                                            this.handleChangetags(value, "tagsId")

                                                        }
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Categories"
                                                                placeholder="Categories"

                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </Paper>

                                        </div>
                                    )}


                                </Formsy>
                            </div>
                        )}
                        {this.state.tabIndex === 1 && (

                            <div>
                                <Autocomplete
                                    freeSolo
                                    id="free-solo-2-demo"
                                    disableClearable
                                    options={this.state.songsData2.map((option) => {
                                        return (option)
                                    })}

                                    onChange={(event, value) => {
                                        this.addtoarry(value, 'setSongsData2', 'songsData2')
                                    }}
                                    getOptionLabel={(option) => {
                                        return option.titleEn + '-' + option.titleAr
                                    }}
                                    renderInput={(params) => {
                                        // console.log(params);
                                        return (<TextField
                                            {...params}
                                            label="Search.."
                                            margin="normal"
                                            variant="outlined"
                                            InputProps={{ ...params.InputProps, type: 'search' }}
                                        />);
                                    }}

                                />

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap'
                                }}>
                                    {
                                        this.state.setSongsData2.map((option) =>
                                            <Card style={
                                                {
                                                    width: '15vw',
                                                    padding: '2vw',
                                                    marginRight: '2vw',
                                                    marginBottom: '2vw'
                                                }
                                            }>
                                                <CardHeader

                                                    action={
                                                        <div>
                                                            <IconButton aria-label="settings" onClick={() => {
                                                                this.backAddItem(option)
                                                            }} >
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                        </div>


                                                    }
                                                    titleTypographyProps={{ variant: 'h6' }}
                                                    title={option.titleEn + '-' + option.titleAr}
                                                />

                                                <ListItemAvatar style={{ padding: "10px" }}>
                                                    <img
                                                        className="w-full block rounded"
                                                        style={{
                                                            width: 'auto',
                                                            height: 'auto',
                                                            display: 'flex',
                                                            flexDirection: "row",
                                                            flexFlow: "center",
                                                            justifyContent: 'center',
                                                            alignItems: "center"
                                                        }}
                                                        alt={option.titleEn}
                                                        src={
                                                            process.env.REACT_APP_BACKEND_URL +
                                                            "/public/" +
                                                            option.coverImg
                                                        }
                                                    />
                                                </ListItemAvatar>

                                            </Card>
                                        )
                                    }



                                </div>

                            </div>
                        )}



                    </div>



                }
            />
        );
    }
}

export default withStyles(styles, { withTheme: true })(Add);
