import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";

import TextField from "@material-ui/core/TextField";
import Formsy from "formsy-react";
import Breadcrumb from "./../components/Breadcrumb";
import axios from "axios";

import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from "@material-ui/icons/Save";

import { RadioGroupFormsy } from "@fuse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from '@material-ui/core/Switch';

import FileList from '../file-manager/FileList';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PermMedia from '@material-ui/icons/PermMedia';
import Alert from '@material-ui/lab/Alert';
const styles = (theme) => ({
    layoutRoot: {},
});

const breadCurmbData = [
    {
        title: "Songs",
        link: `${process.env.REACT_APP_FRONT_URL}songs/list`,
    },
    {
        title: "Add",
        link: "",
    },
];

class Add extends Component {
    state = {
        formValid: false,
        titleEn: "",
        titleAr: "",
        coverImg: "",
        fileSrc: "",
        categoryId: "none",
        categoryData: [],
        singerId: "none",
        singerData: [],
        authorsId: "none",
        authorsData: [],
        composersId: "none",
        composersData: [],
        genresId: "none",
        genreData: [],
        agerateId: "none",
        agerateData: [],
        tagsId: [],
        tagsData: [],
        typeInput: "Video",
        displayDatFile: "none",
        loadingProgress: false,
        successMsg: "",
        displayBtnFile: "block",
        subtitleEn: "",
        subtitleAr: "",
        descriptionen: "",
        descriptionar: "",
        s3Information: [],
        isFree: false,
        isPaid: false,
        ErrorMsg1: '',
        showErrorMsg1: false,
        ErrorMsg2: '',
        showErrorMsg2: false,
        openList: false,
        progVal: 0,
        scheduling: moment(new Date(), 'MM/DD/YYYY'),
        schedulingShow: false
    };


    setFile = (val) => {
        this.setState({
            fileSrc: val.fileSrc,
            s3Information: val.s3Information,
            openList: false,
            successMsg: "The chosen file name: " + val.name

        })
    }







    handleChange = (e, name) => {
        if (name == 'isFree' || name == 'isPaid')
            this.setState({
                [name]: e.target.checked,
            });
        else if (name == 'schedulingShow')
            this.setState({
                [name]: e.target.checked,
            });
        else {
            this.setState({
                [name]: e.target.value,
            });
        }
    };

    handleChangetags = (val, name) => {
        let ids = val.map((item) => {
            return item._id
        })
        this.setState({
            [name]: ids,
        });

    };
    handleSubmit = (model) => {
        if (this.state.coverImg == "") {
            this.setState({
                ErrorMsg1: 'You did not choose an image!',
                showErrorMsg1: true
            })
        }
        else {
            this.setState({
                ErrorMsg1: '',
                showErrorMsg1: false
            });
        }


        if (this.state.fileSrc == "") {
            this.setState({
                ErrorMsg2: 'You not choose a file (video / audio)',
                showErrorMsg2: true
            })
        }
        else {
            this.setState({
                ErrorMsg2: '',
                showErrorMsg2: false
            });
        }
        if (!this.state.showErrorMsg2 && !this.state.showErrorMsg1) {
            if (this.state.successMsg == '') {

                if ((this.state.fileSrc.size / (1024 * 1024)).toFixed(2) > 0) {
                    this.uploadfiles3()
                }
            }
            else {
                let backend_url = process.env.REACT_APP_BACKEND_URL;
                let formData = new FormData();

                formData.append("titleEn", this.state.titleEn);
                formData.append("titleAr", this.state.titleAr);
                formData.append("categoryId", this.state.categoryId);
                formData.append("singerId", this.state.singerId);
                formData.append("authorsId", this.state.authorsId);
                formData.append("composersId", this.state.composersId);
                formData.append("genresId", this.state.genresId);
                formData.append("agerateId", this.state.agerateId);
                formData.append("tagsId", this.state.tagsId);
                formData.append("fileSrc", this.state.fileSrc);
                formData.append("typeInput", this.state.typeInput);
                formData.append("coverImg", this.state.coverImg);
                formData.append("subtitleEn", this.state.subtitleEn);
                formData.append("subtitleAr", this.state.subtitleAr);
                formData.append("descriptionen", this.state.descriptionen);
                formData.append("descriptionar", this.state.descriptionar);
                formData.append("s3Information", JSON.stringify(this.state.s3Information));
                formData.append("isFree", this.state.isFree);
                formData.append("isPaid", this.state.isPaid);
                formData.append("Status", "Published");
                formData.append("scheduling", this.state.scheduling);
                formData.append("schedulingStatus", this.state.schedulingShow);

                axios({
                    url: `${backend_url}/songs/add`,
                    data: formData,
                    method: "post",
                    headers: { "Content-Type": "multipart/form-data" },
                }).then((res) => {
                    this.props.history.push("/frontend/songs/list");
                });
            }

        }


    };
    enableButton = () => {
        this.setState({
            formValid: true,
        });
    };

    disableButton = () => {
        this.setState({
            formValid: false,
        });
    };

    fileUpload = (e, id) => {
        let img = new Image()
        img.src = window.URL.createObjectURL(e.target.files[0])

        let img2 = e.target.files[0];
        //Validate the File Height and Width.
        img.onload = function () {
            var height = this.height;
            var width = this.width;
            if (height != 330 || width != 550) {
                alert("The allowed image to be uploaded is 550 * 330!");
                return false;
            }
            let image = document.getElementById(id);
            image.src = URL.createObjectURL(img2);

            return true;
        };
        this.setState({
            ErrorMsg1: '',
            showErrorMsg1: false,
            coverImg: e.target.files[0],
        });
    };




    //////
    fileUpload2 = (e) => {
        // console.log(e.target.files[0])
        if ((e.target.files[0].type).toString().substring(0, 5) == 'video') {
            this.setState({
                fileSrc: e.target.files[0],
                displayDatFile: "block",
                ErrorMsg2: '',
                showErrorMsg2: false,
                typeInput: 'Video'
            });
        }
        else if ((e.target.files[0].type).toString().substring(0, 5) == 'audio') {
            this.setState({
                fileSrc: e.target.files[0],
                displayDatFile: "block",
                ErrorMsg2: '',
                showErrorMsg2: false,
                typeInput: 'Audio'
            });
        }
        else {
            this.setState({
                ErrorMsg2: 'The file type is wrong',
                showErrorMsg2: true,
                fileSrc: '',
                displayDatFile: "none",
            })
        }


    };
    uploadfiles3 = () => {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();

        formData.append("fileSrc", this.state.fileSrc);
        formData.append("typeInput", this.state.typeInput);
        formData.append("titleEn", this.state.titleEn);

        this.setState({
            loadingProgress: true,
        });


        axios.post(`${backend_url}/uploadfiletos3`, formData, {
            onUploadProgress: (progressEvent) => {
                const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                console.log("onUploadProgress", totalLength);
                if (totalLength !== null) {
                    this.progressData = Math.round((progressEvent.loaded * 100) / totalLength);

                    this.setState({
                        progVal: this.progressData
                    })
                }
            },
            onDownloadProgress: (progressEvent) => {
                const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                console.log("onDownloadProgress", totalLength);
                if (totalLength !== null) {
                    this.progressData = Math.round((progressEvent.loaded * 100) / totalLength);
                    console.log("onUploadProgress", this.progressData);

                }
            },
        }).then((res) => {
            console.log(res);
            this.setState({
                s3Information: res.data.result,
                loadingProgress: false,
                fileSrc: res.data.namefile,
                successMsg: res.data.message,
                displayBtnFile: "none",
            });
            if (res.data.message == 'Uploaded successfully') {
                this.handleSubmit();
            }
        });


        // axios({
        //     url: `${backend_url}/uploadfiletos3`,
        //     data: formData,
        //     method: "post",
        //     headers: { "Content-Type": "multipart/form-data" },
        // }).then((res) => {
        //     // console.log(res.data.result);

        // });



    };
    componentDidMount() {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        axios({
            url: `${backend_url}/category/list`,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            this.setState({
                categoryData: res.data.result,
            });
        });
        //////////
        axios({
            url: `${backend_url}/singer/list`,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            this.setState({
                singerData: res.data.result,
            });
        });
        //////////
        axios({
            url: `${backend_url}/authors/list`,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            this.setState({
                authorsData: res.data.result,
            });
        });
        //////////
        axios({
            url: `${backend_url}/Composers/list`,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            this.setState({
                composersData: res.data.result,
            });
        });
        //////////
        axios({
            url: `${backend_url}/genre/list`,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            this.setState({
                genreData: res.data.result,
            });
        });
        //////////
        axios({
            url: `${backend_url}/agerate/list`,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            this.setState({
                agerateData: res.data.result,
            });
        });
        axios({
            url: `${backend_url}/tags/list`,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            this.setState({
                tagsData: res.data.result,
            });
        });
        //////////////end
    }

    ////
    render() {
        const { classes } = this.props;
        return (
            <FusePageCarded
                classes={{
                    root: classes.layoutRoot,
                }}
                header={
                    <div className="p-24">
                        <Breadcrumb data={breadCurmbData} />
                    </div>
                }
                contentToolbar={
                    <div className="px-24">
                        <h2>Add Song</h2>
                    </div>
                }
                content={
                    <div className="p-24">
                        <div className="max-w-lg">
                            <Formsy
                                onValidSubmit={this.handleSubmit}
                                onValid={this.enableButton}
                                onInvalid={this.disableButton}
                                ref={"song_add"}
                                className="flex flex-col p6-12"
                            >
                                <div className="flex flex-row justify-between py-20">
                                    <TextFieldFormsy
                                        className="w-5/12"
                                        label="Title En"
                                        autoFocus
                                        id="titleEn"
                                        name="titleEn"
                                        variant="outlined"
                                        value={this.state.titleEn}
                                        onChange={(e) => {
                                            this.handleChange(e, "titleEn");
                                        }}
                                        required
                                    />
                                    <TextFieldFormsy
                                        className="w-5/12"
                                        label="Title Ar"
                                        id="titleAr"
                                        name="titleAr"
                                        variant="outlined"
                                        value={this.state.titleAr}
                                        onChange={(e) => {
                                            this.handleChange(e, "titleAr");
                                        }}
                                        required
                                    />
                                </div>

                                <div className="flex flex-row justify-between py-10">
                                    <TextFieldFormsy
                                        className="w-5/12"
                                        label="Subtitle En"
                                        id="subtitleEn"
                                        name="subtitleEn"
                                        variant="outlined"
                                        value={this.state.subtitleEn}
                                        onChange={(e) => {
                                            this.handleChange(e, "subtitleEn");
                                        }}

                                    />

                                    <TextFieldFormsy
                                        className="w-5/12"
                                        label="Subtitle Ar"
                                        id="subtitleAr"
                                        name="subtitleAr"
                                        variant="outlined"
                                        value={this.state.subtitleAr}
                                        onChange={(e) => {
                                            this.handleChange(e, "subtitleAr");
                                        }}

                                    />
                                </div>


                                <div className="flex flex-row justify-between py-10">
                                    <TextFieldFormsy
                                        id="outlined-multiline-static"
                                        label="Description En"
                                        multiline
                                        rows={4}
                                        value={this.state.descriptionen}
                                        variant="outlined"
                                        className="w-5/12"
                                        id="descriptionen"
                                        name="descriptionen"
                                        onChange={(e) => {
                                            this.handleChange(e, "descriptionen");
                                        }}
                                        required
                                    />
                                    <TextFieldFormsy
                                        id="outlined-multiline-static"
                                        label="Description Ar"
                                        multiline
                                        rows={4}
                                        value={this.state.descriptionar}
                                        variant="outlined"
                                        className="w-5/12"
                                        id="descriptionar"
                                        name="descriptionar"
                                        onChange={(e) => {
                                            this.handleChange(e, "descriptionar");
                                        }}
                                        required
                                    />
                                </div>

                                <div className="flex flex-row justify-between py-10">
                                    <SelectFormsy
                                        className="w-5/12"
                                        name="artist"
                                        label="Artist"
                                        value={this.state.authorsId}
                                        onChange={(e) => {
                                            this.handleChange(e, "authorsId");
                                        }}
                                        variant="outlined"
                                    >
                                        <MenuItem value="none">
                                            <em>None</em>
                                        </MenuItem>
                                        {this.state.authorsData.map((item) => {
                                            return <MenuItem value={item._id}>{item.nameEn}</MenuItem>;
                                        })}
                                    </SelectFormsy>
                                    <SelectFormsy
                                        className="w-5/12"
                                        name="singer"
                                        label="Singer"
                                        value={this.state.singerId}
                                        onChange={(e) => {
                                            this.handleChange(e, "singerId");
                                        }}
                                        variant="outlined"
                                    >
                                        <MenuItem value="none">
                                            <em>None</em>
                                        </MenuItem>
                                        {this.state.singerData.map((item) => {
                                            return <MenuItem value={item._id}>{item.nameEn}</MenuItem>;
                                        })}
                                    </SelectFormsy>
                                </div>


                                <div className="flex flex-row justify-between py-10">
                                    <SelectFormsy
                                        className="w-5/12"
                                        name="composer"
                                        label="Composer"
                                        value={this.state.composersId}
                                        onChange={(e) => {
                                            this.handleChange(e, "composersId");
                                        }}
                                        variant="outlined"
                                    >
                                        <MenuItem value="none">
                                            <em>None</em>
                                        </MenuItem>
                                        {this.state.composersData.map((item) => {
                                            return <MenuItem value={item._id}>{item.nameEn}</MenuItem>;
                                        })}
                                    </SelectFormsy>

                                    <Autocomplete
                                        className="w-5/12"
                                        multiple
                                        id="tags-outlined"
                                        options={this.state.genreData}
                                        filterSelectedOptions
                                        getOptionLabel={(option) => option.titleEn}
                                        onChange={(event, value) =>
                                            this.handleChangetags(value, "genresId")
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Genre"

                                            />
                                        )}
                                    />
                                </div>

                                <div className="flex flex-row justify-between py-10">
                                    <SelectFormsy
                                        className="w-5/12"
                                        name="category"
                                        label="Category"
                                        value={this.state.categoryId}
                                        onChange={(e) => {
                                            this.handleChange(e, "categoryId");
                                        }}
                                        variant="outlined"
                                    >
                                        <MenuItem value="none">None</MenuItem>
                                        {this.state.categoryData.map((item) => {
                                            return <MenuItem value={item._id}>{item.titleEn}</MenuItem>;
                                        })}
                                    </SelectFormsy>
                                    <SelectFormsy
                                        className="w-5/12"
                                        name="ageRate"
                                        label="Age Rate"
                                        value={this.state.agerateId}
                                        onChange={(e) => {
                                            this.handleChange(e, "agerateId");
                                        }}
                                        variant="outlined"
                                    >
                                        <MenuItem value="none">
                                            <em>None</em>
                                        </MenuItem>
                                        {this.state.agerateData.map((item) => {
                                            return <MenuItem value={item._id}>{item.titleEn}</MenuItem>;
                                        })}
                                    </SelectFormsy>
                                </div>



                                <div className="flex flex-row justify-between ">
                                    <div className="w-full"
                                        style={{
                                            marginBottom: '2vw'
                                        }}>
                                        <Autocomplete
                                            multiple
                                            id="tags-outlined"
                                            options={this.state.tagsData}
                                            filterSelectedOptions
                                            getOptionLabel={(option) => option.titleEn}
                                            onChange={(event, value) =>

                                                this.handleChangetags(value, "tagsId")

                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Tags"

                                                />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-row justify-between ">
                                    <div className="w-full"
                                        style={{
                                            marginBottom: '2vw'
                                        }}>

                                        <FormControlLabel
                                            control={<Switch checked={this.state.schedulingShow}

                                                color="primary"
                                                onChange={(e) => {
                                                    this.handleChange(e, "schedulingShow");
                                                }}
                                                name="Scheduling" />}
                                            label="Scheduling"
                                            labelPlacement="left"

                                        />
                                        {this.state.schedulingShow && (
                                            <DateTimePicker
                                                label="Scheduling"
                                                inputVariant="outlined"
                                                value={this.state.scheduling}
                                                onChange={date => this.setState({ scheduling: date })}


                                                className="mt-8 mb-16 w-full"
                                                minDate={this.state.scheduling}

                                            />
                                        )}




                                    </div>
                                </div>


 
                                <div className="flex flex-row center py-20">
                                    <FormControlLabel
                                        control={<Switch checked={this.state.isFree}

                                            color="primary"
                                            onChange={(e) => {
                                                this.handleChange(e, "isFree");
                                            }}
                                            name="freev" />}
                                        label="Is Free"
                                        labelPlacement="top"

                                    />

                                    <FormControlLabel
                                        control={<Switch checked={this.state.isPaid}

                                            color="primary"
                                            onChange={(e) => {
                                                this.handleChange(e, "isPaid");
                                            }}
                                            name="freev" />}
                                        label="Exclusive"
                                        labelPlacement="top"

                                    />
                                </div>

                                <RadioGroupFormsy
                                    className="my-16"
                                    name="Type"
                                    label="Type"
                                    value={this.state.typeInput}
                                    onChange={(e) => {
                                        this.handleChange(e, "typeInput");
                                    }}
                                >
                                    <FormControlLabel
                                        value="Video"
                                        control={<Radio color="primary" />}
                                        label="Video"
                                    />
                                    <FormControlLabel
                                        value="Audio"
                                        control={<Radio color="primary" />}
                                        label="Audio"
                                    />
                                </RadioGroupFormsy>









                                <div className="flex flex-row justify-between py-20"
                                    style={{
                                        backgroundColor: "#f8f8f8",
                                        padding: '2vw',
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        borderRadius: '5px',
                                        boxShadow: '0 0 5px #e7e7e7'

                                    }}
                                >
                                    <div className="flex justify-between items-center w-8/12">
                                        <Button variant="contained" component="label">
                                            Choose image
                      <input
                                                accept="image/x-png,image/gif,image/jpeg"
                                                type="file"
                                                onChange={(e) => {
                                                    this.fileUpload(e, "cover-image");
                                                }}
                                                style={{ display: "none" }}
                                            />
                                        </Button>
                                        <img
                                            alt=""
                                            id="cover-image"
                                            title="cover-image"
                                            style={{ maxWidth: "50%" }}
                                            src="frontend/assets/images/custom/empty-image-thumb.png"
                                        />
                                    </div>
                                </div>
                                <div style={{ color: 'red' }}>*Acceptable image dimensions, width: 550 and height:330</div>
                                {this.state.showErrorMsg1 && (
                                    <Alert severity="error">{this.state.ErrorMsg1}</Alert>
                                )}
                                <hr style={{
                                    border: 0,
                                    backgroundColor: '#fff',
                                    borderTop: '2px dotted #8c8c8c'
                                }}></hr>
                                <div
                                    className="flex justify-around items-center"
                                    style={{ paddingTop: "10px" }}
                                >
                                    <Button variant="contained" component="label" style={{ display: this.state.displayBtnFile }}>
                                        <CloudUploadIcon />&nbsp;	&nbsp;	Upload Meida file
                  <input
                                            type="file"
                                            accept="video/mp4"
                                            onChange={(e) => {
                                                this.fileUpload2(e);
                                            }}
                                            style={{ display: "none" }}
                                        />
                                    </Button>

                                    <Button variant="contained" component="label"
                                        onClick={() => {
                                            this.setState({
                                                openList: this.state.openList ? false : true
                                            })

                                        }}
                                    >
                                        <PermMedia />&nbsp;	&nbsp;	Choose from library

                                    </Button>



                                </div>
                                <br></br>
                                <h2>{this.state.successMsg}</h2>
                                <FileList openList={this.state.openList} setFile={this.setFile} />
                                <Backdrop
                                    className={classes.backdrop}
                                    open={this.state.loadingProgress}
                                    style={{
                                        zIndex: 999999,
                                        color: "#fff",
                                    }}
                                >

                                    <Box position="relative" display="inline-flex">
                                        <CircularProgress color="secondary" />

                                        <Box
                                            top={0}
                                            left={0}
                                            bottom={0}
                                            right={0}
                                            position="absolute"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography variant="caption" component="div" color="textSecondary">{this.state.progVal}%</Typography>
                                        </Box>
                                    </Box>

                                </Backdrop>
                                <div
                                    className="flex justify-between items-center w-5/12"
                                    style={{ paddingTop: "10px" }}
                                >
                                    <h3 style={{ display: this.state.displayDatFile }}>
                                        <div style={{ display: this.state.displayBtnFile }}>
                                            File Name: {(this.state.fileSrc.name)}
                                            <br></br>
                                             File Size: {(this.state.fileSrc.size / (1024 * 1024)).toFixed(2)} MB

                                        </div>
                                        <h2>{this.state.successMsg}</h2>


                                    </h3>

                                </div>
                                {this.state.showErrorMsg2 && (
                                    <Alert severity="error">{this.state.ErrorMsg2}</Alert>
                                )}


                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.button}
                                    startIcon={<SaveIcon />}
                                    className="ml-auto my-16"
                                    disabled={!this.state.formValid}
                                    type="submit"
                                    variant="contained"
                                >
                                    Save
                </Button>
                            </Formsy>
                        </div>
                    </div>
                }
            />
        );
    }
}

export default withStyles(styles, { withTheme: true })(Add);
