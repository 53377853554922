import React from 'react';
import Read from './bookComponents/read2';
import reduce from './reduce'

function BookData (props) {

    let bookId = props.match.params.id;

    let router = {};
    router.query = {};
    router.query.bookId = bookId;
    //return(<div>{bookId}</div>)
    return(<div className="page-wrapper"><Read router={router} loggedInUser={reduce.loggedInUser} userProgress={reduce.userProgress} currentBookProgressSaved={reduce.currentBookProgressSaved} appLanguage={reduce.appLanguage} vocabularyModal={reduce.vocabularyModal} dictionaryModal={reduce.dictionaryModal} newVocabularyData={reduce.newVocabularyData} /></div>)
}
export default BookData;