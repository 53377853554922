import Add from "./Add";
import Edit from "./Edit";
import Android from "./Android";
import IOS from "./Ios";

export const PromoCodeConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${process.env.REACT_APP_FRONT_URL}promoCode/add`,
      component: Add,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}promoCode/:id/edit`,
      component: Edit,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}promoCode/android`,
      component: Android,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}promoCode/ios`,
      component: IOS,
    },
  ],
};
