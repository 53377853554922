import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl'; 
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';


const BootstrapInput = withStyles((theme) => ({
	root: {
	  'label + &': {
		marginTop: theme.spacing(3),
	  },
	},
	input: {
	  borderRadius: 4,
	  position: 'relative',
	  backgroundColor: theme.palette.background.paper,
	  border: '1px solid #ced4da',
	  fontSize: 16,
	  padding: '10px 26px 10px 12px',
	  transition: theme.transitions.create(['border-color', 'box-shadow']),
	  // Use the system font instead of the default Roboto font.
	  fontFamily: [
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	  ].join(','),
	  '&:focus': {
		borderRadius: 4,
		borderColor: '#80bdff',
		boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
	  },
	},
  }))(InputBase);
  const useStyles = makeStyles((theme) => ({
	margin: {
	  margin: theme.spacing(1),
	},
  }));
  
function Widget7(props) {
	const [currentRange, setCurrentRange] = useState(props.widget.currentRange);

	function handleChangeRange(ev) {
		setCurrentRange(ev.target.value);
	}

	return (
		<Paper className="w-full rounded-8 shadow-1 border-1">
			<div className="flex items-center justify-between px-16 h-64 border-b-1">
				<Typography className="h1 font-500">{props.widget.title}
					<FormControl>
 						<Select
							labelId="demo-customized-select-label"
							id="demo-customized-select"
							value={props.favoriteInput}
							onChange={(e)=>props.setFavoriteInput(e.target.value)}
							input={<BootstrapInput />}
						>
					 
							<MenuItem value={'songs'}>songs</MenuItem>
							<MenuItem value={'shows'}>shows</MenuItem>
							<MenuItem value={'stories'}>stories</MenuItem>
						</Select>
					</FormControl>
				</Typography>

			</div>
			<List style={{
				width: '100%',
				position: 'relative',
				overflow: 'auto',
				maxHeight: 400,
			}}>
				{props.widget.schedule.All[0].map(item => (
					<ListItem key={item.id}>
						<ListItemText primary={item.titleEn} secondary={item.titleAr} />
						<ListItemSecondaryAction>
							<IconButton aria-label="more">
								{item.count}
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
				))}
			</List>
		</Paper>
	);
}

export default React.memo(Widget7);
