import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import Button from "@material-ui/core/Button";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from '@material-ui/core/Switch';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';

import Snackbar from '@material-ui/core/Snackbar';
import Paper from "@material-ui/core/Paper";
import Breadcrumb from "./../components/Breadcrumb";
import axios from "axios";
import { Link } from "react-router-dom";
import Popup from "./../components/popup";

import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";


import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
const styles = (theme) => ({
    layoutRoot: {},
});

const breadCurmbData = [
    {
        title: "App Section Content",
        link: "",
    },
];
class List extends Component {
    state = {
        open: false,
        formValid: false,
        rowsPerPage: 50,
        page: 0,
        data: [],
        genreData: [],
        idremove: '0',
        filterCategories: 'all',
        filterCategories2: 'all',
        actionEnableOrDispale: false,
        valenable: ''
    };

    handleSubmit = (model) => { };
    enableButton = () => {
        this.setState({
            formValid: true,
        });
    };

    disableButton = () => {
        this.setState({
            formValid: false,
        });
    };
    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };
    handleChangeRowsPerPage = () => { };
    sortData = (name) => {
        let data = this.state.genreData;

        data = data.sort(function (a, b) {
            if (a[name] < b[name]) {
                return -1;
            }
            if (a[name] > b[name]) {
                return 1;
            }
            return 0;
        });
        this.setState({
            genreData: data,
        });
    };

    componentDidMount() {
        let backend_url = process.env.REACT_APP_BACKEND_URL;

        axios({
            url: `${backend_url}/mainScreen/view`,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {

            this.setState({
                genreData: res.data.result,
                data: res.data.result,
            });


            const { data } = this.state;
            // console.log(data)
            if (this.state.filterCategories == 'all') {
                this.setState({ genreData: data });
            }
            else {
                const filterbydrop = data.filter((list) => list.typeContent === this.state.filterCategories);
                this.setState({ genreData: filterbydrop });
            }
        });
    }
    handleChange = (e, name) => {
        this.setState({
            [name]: e.target.value,
        });
        if (name == 'filterCategories') {
            const { data } = this.state;
            // console.log(data)
            if (e.target.value == 'all') {
                this.setState({ genreData: data });
            }
            else {
                const filterbydrop = data.filter((list) => list.typeContent === e.target.value);
                this.setState({ genreData: filterbydrop });
            }

        }
        if (name == 'filterCategories2') {
            const { data } = this.state;
            // console.log(data)
            if (e.target.value == 'all') {
                this.setState({ genreData: data });
            }
            else {
                const filterbydrop = data.filter((list) => list.type === e.target.value);
                this.setState({ genreData: filterbydrop });
            }

        }
    };
    handleDelete = () => {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();
        formData.append("id", this.state.idremove);
        axios({
            url: `${backend_url}/mainScreen/delete`,
            data: formData,
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            if (res.data.message) {
                this.componentDidMount();
                this.setState({
                    open: false, page: 0
                });
            }

        });
    };
    actionEnableOrDispaleMethod = (val, id) => {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();
        formData.append("_id", id);
        formData.append("status", val);
        axios({
            url: `${backend_url}/mainScreen/EnableOrDisable`,
            data: formData,
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            this.componentDidMount();
            this.setState({
                actionEnableOrDispale: true,
                valenable: !val ? 'Enabled' : 'Disabled'
            });
        });
    }

    upwardMethod = (id, order, backID, backOrder) => {

        console.log(id, order, backID, backOrder)
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();
        formData.append("id", id);
        formData.append("order", order + 1);
        formData.append("backID", backID);
        formData.append("backOrder", backOrder - 1);
        axios({
            url: `${backend_url}/mainScreen/upward`,
            data: formData,
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            this.componentDidMount();


        });
    }



    downwardMethod = (id, order, nextID, nextOrder) => {
        console.log(id, order, nextID, nextOrder)
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();
        formData.append("id", id);
        formData.append("order", order - 1);
        formData.append("nextID", nextID);
        formData.append("nextOrder", nextOrder + 1);
        axios({
            url: `${backend_url}/mainScreen/downward`,
            data: formData,
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            this.componentDidMount();
        });
    }

    pluscount = (index) => {
        let newindex = index + 1;
        return newindex;
    }
    minuscount = (index) => {
        let bacindex = index - 1;
        return bacindex;
    }
    ////////////////////////////////
    render() {
        const { classes } = this.props;
        return (
            <FusePageCarded
                classes={{
                    root: classes.layoutRoot,
                }}
                header={
                    <div className="p-24">
                        <Breadcrumb data={breadCurmbData} />
                    </div>
                }
                contentToolbar={
                    <div className="w-full flex items-center justify-between p-24">
                        <h2>App Section Content</h2>
                        <div className="flex items-center">
                            <Link to={`${process.env.REACT_APP_FRONT_URL}appsettings/addrows`}>
                                <Button color="primary" variant="contained">
                                    Add
                </Button>
                            </Link>
                        </div>
                    </div>
                }
                content={

                    <div className="p-24">
                        <div className="flex flex-col flex-shrink-0 sm:flex-row items-center justify-between py-24">
                            <FormControl className="flex w-full sm:w-320 mx-16" variant="outlined">
                                <InputLabel htmlFor="category-label-placeholder"> Type </InputLabel>
                                <Select
                                    value={this.state.filterCategories2}
                                    onChange={(e) => {
                                        this.handleChange(e, "filterCategories2");
                                    }}
                                    input={
                                        <OutlinedInput
                                            labelWidth={'category'.length * 9}
                                            name="category"
                                            id="category-label-placeholder"
                                        />
                                    }
                                >
                                    <MenuItem value="all">
                                        <em> All </em>
                                    </MenuItem>
                                    <MenuItem value='interests' key={1}>Interests</MenuItem>
                                    <MenuItem value='latestReleases' key={2}>Latest Releases</MenuItem>
                                    <MenuItem value='editorChoice' key={3}>Editor Choice</MenuItem>
                                    <MenuItem value='categories' key={4}>Categories</MenuItem>

                                </Select>

                            </FormControl>
                            <FormControl className="flex w-full sm:w-320 mx-16" variant="outlined">
                                <InputLabel htmlFor="category-label-placeholder"> Contant Type </InputLabel>
                                <Select
                                    value={this.state.filterCategories}
                                    onChange={(e) => {
                                        this.handleChange(e, "filterCategories");
                                    }}
                                    input={
                                        <OutlinedInput
                                            labelWidth={'category'.length * 9}
                                            name="category"
                                            id="category-label-placeholder"
                                        />
                                    }
                                >
                                    <MenuItem value="all">
                                        <em> All </em>
                                    </MenuItem>
                                    <MenuItem value='songs' key={1}>Songs</MenuItem>
                                    <MenuItem value='shows' key={2}>Shows</MenuItem>
                                    <MenuItem value='stories' key={3}>Stories</MenuItem>

                                </Select>

                            </FormControl>

                        </div>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>

                                        <TableCell align="left">ID</TableCell>
                                        <TableCell align="left">Type</TableCell>
                                        <TableCell align="left">Contant Type</TableCell>
                                        <TableCell align="left">Title EN</TableCell>
                                        <TableCell align="left">Title AR</TableCell>
                                        <TableCell align="left">Content number</TableCell>
                                        <TableCell align="left">Details</TableCell>
                                        <TableCell align="left">Enable/Disable</TableCell>
                                        <TableCell align="left">Edit Title</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.genreData
                                        .slice(
                                            this.state.page * this.state.rowsPerPage,
                                            this.state.page * this.state.rowsPerPage +
                                            this.state.rowsPerPage
                                        )
                                        .map((row, index, ahmad) => (
                                            <TableRow key={row._id}>
                                                <TableCell align="left">{(index + 1)} &nbsp;

                                                    {(index + 1) > 1 && (
                                                        <ArrowUpward style={{
                                                            cursor: 'pointer'
                                                        }} fontSize="small"
                                                            onClick={() => {
                                                                let backValId = index == 0 ? 0 : ahmad[this.minuscount(index)]._id
                                                                let backValOrder = index == 0 ? 0 : ahmad[this.minuscount(index)].order
                                                                this.upwardMethod(row._id, row.order, backValId, backValOrder)
                                                            }}
                                                        />
                                                    )}

                                                    {(index + 1) != Object.keys(this.state.genreData).length && (
                                                        <ArrowDownward style={{
                                                            cursor: 'pointer'
                                                        }} fontSize="small"
                                                            onClick={() => {
                                                                let nextValId = (index + 1) == Object.keys(this.state.genreData).length ? 0 : ahmad[this.pluscount(index)]._id
                                                                let nextValOrder = (index + 1) == Object.keys(this.state.genreData).length ? 0 : ahmad[this.pluscount(index)].order
                                                                this.downwardMethod(row._id, row.order, nextValId, nextValOrder)
                                                            }}
                                                        />
                                                    )}

                                                </TableCell>

                                                <TableCell align="left">{row.type}</TableCell>
                                                <TableCell align="left">{row.typeContent}</TableCell>
                                                <TableCell align="left">{row.titleEn}</TableCell>
                                                <TableCell align="left">{row.titleAr}</TableCell>
                                                <TableCell align="left">
                                                    {(row.type == 'latestReleases' || row.type == 'editorChoice') ? Object.keys(row.contentRow).length : (row.type == 'categories') ? row.quantity : 'Unlimited'}
                                                </TableCell>
                                                <TableCell align="left">

                                                    {(row.type == 'latestReleases' || row.type == 'editorChoice') && (


                                                        <Typography
                                                            className="normal-case flex items-center sm:mb-12"
                                                            component={Link}
                                                            role="button"
                                                            to={'/frontend/appsettings/' + row._id + '/details'}
                                                            color="inherit"
                                                        >
                                                            <Button variant="contained" color="primary">
                                                                Details </Button>
                                                        </Typography>

                                                    )}

                                                    {(row.type == 'categories' || row.type == 'interests') &&
                                                        <Button variant="contained" disabled="true" color="primary">
                                                            Details </Button>
                                                    }



                                                </TableCell>
                                                <TableCell align="left">
                                                    <Snackbar
                                                        open={this.state.actionEnableOrDispale}
                                                        autoHideDuration={600}
                                                        message={this.state.valenable}
                                                        action={
                                                            <Button color="inherit" size="small"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        actionEnableOrDispale: false
                                                                    })
                                                                }}
                                                            >
                                                                ×
                                                          </Button>
                                                        }
                                                        className={classes.snackbar}
                                                    />

                                                    <FormControlLabel
                                                        control={<Switch checked={row.status}

                                                            color="primary"
                                                            onChange={(e) => {
                                                                this.actionEnableOrDispaleMethod(row.status, row._id);
                                                            }}
                                                            name="freev" />}
                                                        label={row.status ? 'Enable' : 'Disable'}
                                                        labelPlacement="bottom"

                                                    />

                                                    {/* {row.status ? <Button variant="contained" color="primary"
                                                        onClick={() => {
                                                            this.actionEnableOrDispaleMethod(row.status, row._id);
                                                        }}
                                                    >
                                                        Enable
                                                    </Button> :

                                                        <Button variant="outlined" color="secondary"
                                                            onClick={() => {
                                                                this.actionEnableOrDispaleMethod(row.status, row._id);
                                                            }}
                                                        >disable
                                                        </Button>} */}



                                                </TableCell>

                                                <TableCell align="left">
                                                    <Typography
                                                        className="normal-case flex items-center sm:mb-12"
                                                        component={Link}
                                                        role="button"
                                                        to={'/frontend/appsettings/' + row._id + '/edit'}
                                                        color="inherit"
                                                    >

                                                        <IconButton
                                                            aria-label="Edit"
                                                            className={classes.margin}
                                                        >
                                                            <EditIcon fontSize="large" />
                                                        </IconButton>
                                                    </Typography>
                                                </TableCell>

                                                <TableCell align="left">
                                                    <IconButton
                                                        aria-label="delete"
                                                        className={classes.margin}
                                                        onClick={() => {
                                                            this.setState({
                                                                open: true,
                                                                idremove: row._id,
                                                            });
                                                        }}
                                                    >
                                                        <DeleteIcon fontSize="large" />
                                                    </IconButton>
                                                    <Dialog
                                                        open={this.state.open}
                                                        keepMounted
                                                        aria-labelledby="alert-dialog-slide-title"
                                                        aria-describedby="alert-dialog-slide-description"
                                                    >
                                                        <DialogTitle id="alert-dialog-slide-title">
                                                            <DeleteIcon fontSize="large" />{" "}
                                                            {" Are you sure you want to delete?"}
                                                        </DialogTitle>
                                                        <DialogActions>
                                                            <Button
                                                                onClick={() => {
                                                                    this.setState({
                                                                        open: false,
                                                                    });
                                                                }}
                                                                color="primary"
                                                            >
                                                                No
                                                            </Button>
                                                            <Button
                                                                color="primary"
                                                                onClick={() => {
                                                                    this.handleDelete();
                                                                }}
                                                            >
                                                                OK
                                                         </Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </TableCell>


                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[10]}
                                component="div"
                                count={this.state.genreData.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                backIconButtonProps={{
                                    "aria-label": "previous page",
                                }}
                                nextIconButtonProps={{
                                    "aria-label": "next page",
                                }}
                                onChangePage={this.handleChangePage}
                            />
                        </TableContainer>

                        <Popup />
                    </div>
                }
            />
        );
    }
}

export default withStyles(styles, { withTheme: true })(List);
