const fuseDark = {
    50: '#e3e6e8',
    100: '#bac0c5',
    200: '#8c969f',
    300: '#5e6c78',
    400: '#3c4d5b',
    500: '#3b8dbc',
    600: '#162838',
    700: '#2c567b',
    800: '#20415d',
    900: '#083651',
    A100: '#5b9aff',
    A200: '#287bff',
    A400: '#005ef4',
    A700: '#0054da',
};

export default fuseDark;
