const data  = {
      internetAvailable: false,
      accessCode: '',
      accessCodeType: '',
      registerUserInfo: {},
      showError: false,
      errorMessage: '',
      deviceInfo: {},
      loggedInUser: {
        userData: {
          _id: 'user_1382',
          fname: 'محمد',
          lname: 'احمد',
          username: 'محمد احمد',
          password: '1234',
          gender: 'm',
          email: 'moh@test.co',
          sectionId: 'section_4',
          classId: 'class_3',
          school_id: 'school_1',
          level: 'grade1',
          type: 'student',
          userLevel: 'د',
          fcmToken: 'fLsxSS8eokM:APA91bGpOOYdQZ1OeZzk0jT7S2gS4-5W6_8jLyAFFtrMnFw9hvXFq8nWbbx_ikRPJiYEqDDzeIKM0phCntp7Is1L9KUHDBVq0Gw8jw68Rwo3PQJQFyfxVIGPZHulteBQGUbk5whFlE8b',
          sessionToken: 'c11d42f87c6b62b6be463c9dfd5bcff4',
          avatarData: {
            currentType: 'human',
            currentHairColor: {
              primaryColor: '#FFEA95',
              secondaryColor: '#DBC567'
            },
            currentEyesColor: {
              primaryColor: '#42A4DD',
              secondaryColor: '#42A4DD'
            },
            human: {
              faceIndex: 0,
              face: 'face_1',
              hair: 'hair_m_5',
              eye: 'eyes_2',
              mouth: 'mouth_1',
              cloth: 'cloth_m_2',
              accessory: null
            },
            monster: {
              body: '',
              arms: '',
              legs: '',
              eyes: '',
              mouth: ''
            }
          },
          avatarlink: 'https://school.kutubee.com:4000/avatars/1603965085392.png',
          status: 'active',
          userLevelFr: 'N1',
          bookCoins: [
            'book_1470',
            'book_1248'
          ],
          coins: 30,
          indiviualLevel: 1,
          lastLogin: 1610875424,
          userLevelEn: 'Level 4',
          schoolName: 'Kutubee School',
          className: 'الصف الأول الأساسي',
          sectionName: 'ب'
        },
        userLevel: {
          _id: 'cat_54',
          nameEn: 'د',
          parent: 'cat_50',
          descEn: '',
          nameAr: 'د',
          parentAr: 'cat_50',
          descAr: '',
          coverUrl: 'category/1599565788640.jpg',
          level: '3',
          arabic21: true,
          arabic21level: 'grade1',
          bookNo: '30',
          order: 3,
          englishLevel: false,
          frenchLevel: false,
          individual: 'false',
          individualNameAr: '',
          individualNameEn: '',
          individualUrl: '',
          interset: 'false',
          related_cat: '',
          related_cat2: '',
          status: true
        },
        nextLevel: {
          _id: 'cat_55',
          nameEn: 'ه',
          parent: 'cat_50',
          descEn: '',
          nameAr: 'ه',
          parentAr: 'cat_50',
          descAr: '',
          coverUrl: 'category/1599565797366.jpg',
          level: '3',
          arabic21: true,
          arabic21level: 'grade1',
          bookNo: '30',
          order: 4,
          englishLevel: false,
          frenchLevel: false,
          individual: 'false',
          individualNameAr: '',
          individualNameEn: '',
          individualUrl: '',
          interset: 'false',
          related_cat: '',
          related_cat2: '',
          status: true
        },
        userLevelEn: {
          _id: 'cat_143',
          nameEn: 'Level 4',
          nameAr: 'Level 4',
          parent: 'cat_153',
          coverUrl: 'category/1598521365453.jpg',
          level: '2',
          arabic21: false,
          englishLevel: true,
          frenchLevel: false,
          status: true,
          arabic21level: 'grade1',
          bookNo: '25',
          individualNameAr: '',
          individualNameEn: '',
          related_cat: 'cat_153',
          interset: 'false',
          individualUrl: '',
          individual: 'false',
          order: 3,
          related_cat2: ''
        },
        nextLevelEn: {
          _id: 'cat_144',
          nameEn: 'Level 5',
          nameAr: 'Level 5',
          parent: 'cat_153',
          coverUrl: 'category/1598521379347.jpg',
          level: '2',
          arabic21: false,
          englishLevel: true,
          frenchLevel: false,
          status: true,
          arabic21level: 'grade1',
          bookNo: '15',
          individualNameAr: '',
          individualNameEn: '',
          related_cat: 'cat_153',
          interset: 'false',
          individualUrl: '',
          individual: 'false',
          order: 4,
          related_cat2: ''
        },
        userLevelFr: {
          _id: 'cat_150',
          nameEn: 'N1',
          nameAr: 'N1',
          parent: 'cat_149',
          coverUrl: 'category/1600082917246.jpg',
          level: '2',
          arabic21: false,
          englishLevel: false,
          frenchLevel: true,
          status: true,
          arabic21level: 'kg_1',
          bookNo: '4',
          individualNameAr: '',
          individualNameEn: '',
          related_cat: '',
          interset: 'false',
          individualUrl: '',
          individual: 'false',
          order: 0,
          related_cat2: ''
        },
        nextLevelFr: {
          _id: 'cat_151',
          nameEn: 'N2',
          nameAr: 'N2',
          parent: 'cat_149',
          coverUrl: 'category/1600255712865.jpg',
          level: '2',
          arabic21: false,
          englishLevel: false,
          frenchLevel: true,
          status: true,
          arabic21level: 'kg_2',
          bookNo: '3',
          individualNameAr: '',
          individualNameEn: '',
          related_cat: '',
          interset: 'false',
          individualUrl: '',
          individual: 'false',
          order: 1,
          related_cat2: ''
        }
      },
      favBooks: [],
      finishedBooks: [],
      finishedBooksByLevel: 0,
      earnedBadges: [
        {
          user_id: 'user_1382',
          badge_id: 'badge_1',
          badgeData: {
            _id: 'badge_1',
            name: 'إنهاء قراءة أول كتاب',
            bookNo: '1',
            fromDate: 'NaN',
            toDate: 'NaN',
            taskName: 'Finish a book',
            taskId: 'badgeTask_1',
            coverUrl: 'https://school.kutubee.com:4000/badge_cover/1569486303222.png',
            description: '1st book'
          }
        },
        {
          user_id: 'user_1382',
          badge_id: 'badge_11',
          badgeData: {
            _id: 'badge_11',
            name: 'الحصول على أول علامة كاملة في اختبار',
            bookNo: '1',
            quizNo: '1',
            fromDate: 'NaN',
            toDate: 'NaN',
            taskName: 'Finish quiz with full mark',
            taskId: 'badgeTask_3',
            coverUrl: 'https://school.kutubee.com:4000/badge_cover/1571204924549.png',
            description: 'Full mark first quiz'
          }
        },
        {
          user_id: 'user_1382',
          badge_id: 'badge_2',
          badgeData: {
            _id: 'badge_2',
            name: 'إنهاء قراءة 5 كتب',
            bookNo: '5',
            fromDate: 'NaN',
            toDate: 'NaN',
            taskName: 'Finish a book',
            taskId: 'badgeTask_1',
            coverUrl: 'https://school.kutubee.com:4000/badge_cover/1569486314452.png',
            description: 'gbge'
          }
        }
      ],
      currentBookQuiz: null,
      downloadedBooks: [],
      badges: [
        {
          _id: 'badge_1',
          name: 'إنهاء قراءة أول كتاب',
          bookNo: '1',
          quizNo: '',
          daysNo: '',
          categoryId: '',
          arabic21: '',
          fromDate: 'NaN',
          toDate: 'NaN',
          taskName: 'Finish a book',
          taskId: 'badgeTask_1',
          coverUrl: 'badge_cover/1569486303222.png',
          description: '1st book'
        },
        {
          _id: 'badge_11',
          name: 'الحصول على أول علامة كاملة في اختبار',
          bookNo: '1',
          quizNo: '1',
          daysNo: '',
          categoryId: '',
          arabic21: '',
          fromDate: 'NaN',
          toDate: 'NaN',
          taskName: 'Finish quiz with full mark',
          taskId: 'badgeTask_3',
          coverUrl: 'badge_cover/1571204924549.png',
          description: 'Full mark first quiz'
        },
        {
          _id: 'badge_12',
          name: 'الحصول على ٥٠ علامة كاملة في الاختبار',
          bookNo: '50',
          quizNo: '50',
          daysNo: '',
          categoryId: '',
          arabic21: '',
          fromDate: '1571205437',
          toDate: '1571205437',
          taskName: 'Finish quiz with full mark',
          taskId: 'badgeTask_3',
          coverUrl: 'badge_cover/1571205429139.png',
          description: '50 quizzes'
        },
        {
          _id: 'badge_13',
          name: 'الحصول على ٢٥ علامة كاملة في اختبار',
          bookNo: '25',
          quizNo: '25',
          daysNo: '',
          categoryId: '',
          arabic21: '',
          fromDate: '1571205772',
          toDate: '1571205772',
          taskName: 'Finish quiz with full mark',
          taskId: 'badgeTask_3',
          coverUrl: 'badge_cover/1571205771505.png',
          description: '25 quizzes'
        },
        {
          _id: 'badge_14',
          name: 'تقييم 25 كتاب',
          bookNo: '25',
          quizNo: '',
          daysNo: '',
          categoryId: '',
          arabic21: '',
          fromDate: 'NaN',
          toDate: 'NaN',
          taskName: 'Rate a book',
          taskId: 'badgeTask_2',
          coverUrl: 'badge_cover/1571207963389.png',
          description: 'rate 25 books'
        },
        {
          _id: 'badge_15',
          name: 'تقييم 50 كتاب',
          bookNo: '50',
          quizNo: '',
          daysNo: '',
          categoryId: '',
          arabic21: '',
          fromDate: '1571207942',
          toDate: '1571207942',
          taskName: 'Rate a book',
          taskId: 'badgeTask_2',
          coverUrl: 'badge_cover/1571207667790.png',
          description: 'rate 50 books'
        },
        {
          _id: 'badge_2',
          name: 'إنهاء قراءة 5 كتب',
          bookNo: '5',
          quizNo: '',
          daysNo: '',
          categoryId: '',
          arabic21: '',
          fromDate: 'NaN',
          toDate: 'NaN',
          taskName: 'Finish a book',
          taskId: 'badgeTask_1',
          coverUrl: 'badge_cover/1569486314452.png',
          description: 'gbge'
        },
        {
          _id: 'badge_3',
          name: 'إنهاء قراءة 25 كتاب',
          bookNo: '25',
          quizNo: '',
          daysNo: '',
          categoryId: '',
          arabic21: '',
          fromDate: 'NaN',
          toDate: 'NaN',
          taskName: 'Finish a book',
          taskId: 'badgeTask_1',
          coverUrl: 'badge_cover/1570612967657.png',
          description: '25'
        },
        {
          _id: 'badge_4',
          name: 'إنهاء قراءة 50 كتاب',
          bookNo: '50',
          quizNo: '',
          daysNo: '',
          categoryId: '',
          arabic21: '',
          fromDate: 'NaN',
          toDate: 'NaN',
          taskName: 'Finish a book',
          taskId: 'badgeTask_1',
          coverUrl: 'badge_cover/1571208732767.png',
          description: '50'
        },
        {
          _id: 'badge_7',
          name: 'إنهاء قراءة 100 كتاب',
          bookNo: '100',
          quizNo: '',
          daysNo: '',
          categoryId: '',
          arabic21: '',
          fromDate: 'NaN',
          toDate: 'NaN',
          taskName: 'Finish a book',
          taskId: 'badgeTask_1',
          coverUrl: 'badge_cover/1570612000680.png',
          description: 'baxciaixha'
        },
        {
          _id: 'badge_9',
          name: 'تقييم أول كتاب',
          bookNo: '1',
          quizNo: '',
          daysNo: '',
          categoryId: '',
          arabic21: '',
          fromDate: 'NaN',
          toDate: 'NaN',
          taskName: 'Rate a book',
          taskId: 'badgeTask_2',
          coverUrl: 'badge_cover/1571207622375.png',
          description: 'Rate first book'
        },
        {
          _id: 'badge_16',
          name: 'إنهاء قراءة 200 كتاب',
          bookNo: '200',
          quizNo: '',
          daysNo: '',
          categoryId: '',
          arabic21: '',
          fromDate: '1573118440',
          toDate: '1573118440',
          taskName: 'Finish a book',
          taskId: 'badgeTask_1',
          coverUrl: 'badge_cover/1573118438416.png',
          description: 'إنهاء قراءة 200 كتاب'
        }
      ],
      categories: [],
      showMenu: false,
      assignments: [
        {
          _id: 'assignment_13991',
          name: 'decembers',
          users: [
            'user_1382',
            'user_14075',
            'user_16466'
          ],
          dueDate: 1610521620,
          startDate: 1609884000,
          worksheets: [],
          description: 'record your voice ',
          author: 'user_3',
          school_id: 'school_1',
          created: 1609884000,
          books: [
            {
              label: 'هيا الشجرة',
              value: 'book_1261',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1571143826552.jpg'
            },
            {
              label: 'الحفلة التنكرية',
              value: 'book_1245',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1570712484584.jpg'
            }
          ],
          authorName: 'Yassmin  Algemiai',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1603263999982.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_9288',
          name: 'test18',
          users: [
            'user_1382'
          ],
          dueDate: 1604143260,
          startDate: 1601236800,
          worksheets: [],
          description: 'test18',
          author: 'user_10',
          school_id: 'school_1',
          created: 1601236800,
          books: [
            {
              label: 'What Will You Wear?',
              value: 'book_1000',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563446733338.jpg'
            }
          ],
          authorName: 'kutubee teacher8',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1604414894151.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_9287',
          name: 'test17',
          users: [
            'user_13',
            'user_1382',
            'user_1'
          ],
          dueDate: 1603970400,
          startDate: 1601236800,
          worksheets: [],
          description: 'test17',
          author: 'schoolAdmin_1',
          school_id: 'school_1',
          created: 1601236800,
          books: [
            {
              label: 'هل تستطيع أن تحزر؟',
              value: 'book_1007',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563705369886.jpg'
            },
            {
              label: 'An Earful',
              value: 'book_1006',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563447913873.jpg'
            }
          ],
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_10195',
          name: 'october',
          users: [
            'user_1382',
            'user_14073',
            'user_14075',
            'user_16466',
            'user_21246',
            'user_21250',
            'user_22537',
            'user_24980',
            'user_24981',
            'user_24982',
            'user_24983',
            'user_24984',
            'user_26968',
            'user_27752',
            'user_29254',
            'user_29866',
            'user_29875',
            'user_29876',
            'user_29878',
            'user_29881',
            'user_29883',
            'user_30496',
            'user_30497',
            'user_30503',
            'user_30504',
            'user_30599',
            'user_30565',
            'user_30566',
            'user_30567',
            'user_30568',
            'user_30714',
            'user_30715',
            'user_30717',
            'user_30698',
            'user_30514',
            'user_30578',
            'user_30716',
            'user_30722',
            'user_31729',
            'user_34018',
            'user_36003',
            'user_38378',
            'user_39236',
            'user_39496',
            'user_40',
            'user_53826',
            'user_61344'
          ],
          dueDate: 1603188300,
          startDate: 1602709200,
          worksheets: [],
          description: 'dfsFf',
          author: 'user_3',
          school_id: 'school_1',
          created: 1602709200,
          books: [
            {
              label: 'cartwheels and Butterflies',
              value: 'book_1008',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563711828975.jpg'
            },
            {
              label: 'Winter Is Coming!',
              value: 'book_1002',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563446824376.jpg'
            }
          ],
          authorName: 'Yassmin  Algemiai',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1603263999982.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_9271',
          name: 'test15',
          users: [
            'user_1',
            'user_10543',
            'user_13',
            'user_1382',
            'user_14',
            'user_14075',
            'user_15',
            'user_16',
            'user_16455',
            'user_16466',
            'user_16487',
            'user_2',
            'user_20562',
            'user_21246',
            'user_21249',
            'user_21250',
            'user_22537',
            'user_24992',
            'user_24980',
            'user_24981',
            'user_24982',
            'user_24983',
            'user_24984',
            'user_24985',
            'user_24986',
            'user_24987',
            'user_24988',
            'user_24989',
            'user_26968',
            'user_27553',
            'user_27563',
            'user_27748',
            'user_27752',
            'user_29254',
            'user_29875',
            'user_29876',
            'user_29878',
            'user_29881',
            'user_29883',
            'user_30496',
            'user_30497',
            'user_30503',
            'user_30504',
            'user_30599',
            'user_30565',
            'user_30566',
            'user_30567',
            'user_30568',
            'user_30714',
            'user_30715',
            'user_30717',
            'user_30698',
            'user_30514',
            'user_30578',
            'user_30716',
            'user_30722',
            'user_31729',
            'user_34018',
            'user_36003',
            'user_38',
            'user_38378',
            'user_39',
            'user_38377',
            'user_39236',
            'user_39237',
            'user_39254',
            'user_39496',
            'user_40',
            'user_45607',
            'user_47872',
            'user_48721',
            'user_48483',
            'user_5031',
            'user_50682',
            'user_50683',
            'user_50754',
            'user_53525',
            'user_53526',
            'user_53544',
            'user_53826',
            'user_55393',
            'user_61344',
            'user_63077',
            'user_65815'
          ],
          dueDate: 1602827640,
          startDate: 1601272445,
          worksheets: [],
          description: 'test15',
          author: 'user_10',
          school_id: 'school_1',
          created: 1601272445,
          books: [
            {
              label: 'What Will You Wear?',
              value: 'book_1000',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563446733338.jpg'
            },
            {
              label: 'Who\'s There?',
              value: 'book_1001',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563446779346.jpg'
            }
          ],
          authorName: 'kutubee teacher8',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1604414894151.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_9270',
          name: 'test4',
          users: [
            'user_1382'
          ],
          dueDate: 1602741120,
          startDate: 1601272325,
          worksheets: [],
          description: 'tesc',
          author: 'user_10',
          school_id: 'school_1',
          created: 1601272325,
          books: [
            {
              label: 'Where is Taffy',
              value: 'book_1',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561537678041.jpg'
            },
            {
              label: 'The Skateboarder',
              value: 'book_100',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561275810655.jpg'
            }
          ],
          authorName: 'kutubee teacher8',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1604414894151.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_9284',
          name: 'mohadtest1',
          users: [
            'user_1382'
          ],
          dueDate: 1602667800,
          startDate: 1601285401,
          worksheets: [],
          description: 'mohadtest1',
          author: 'user_10',
          school_id: 'school_1',
          created: 1601285401,
          books: [
            {
              label: 'The Skateboarder',
              value: 'book_100',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561275810655.jpg'
            }
          ],
          authorName: 'kutubee teacher8',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1604414894151.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_9245',
          name: 'فضاء',
          users: [
            'user_13',
            'user_1382',
            'user_14',
            'user_14075',
            'user_16466',
            'user_2',
            'user_21246',
            'user_21249',
            'user_21250',
            'user_22537',
            'user_24980',
            'user_24981',
            'user_24982',
            'user_24983',
            'user_24984',
            'user_26968',
            'user_27752',
            'user_29254',
            'user_29875',
            'user_29876',
            'user_29878',
            'user_29881',
            'user_29883',
            'user_30496',
            'user_30497',
            'user_30503',
            'user_30504',
            'user_30599',
            'user_30565',
            'user_30566',
            'user_30567',
            'user_30568',
            'user_30714',
            'user_30715',
            'user_30717',
            'user_30698',
            'user_30514',
            'user_30578',
            'user_30716',
            'user_30722',
            'user_31729',
            'user_34018',
            'user_35465',
            'user_36003',
            'user_38',
            'user_38378',
            'user_39',
            'user_39236',
            'user_40',
            'user_53809',
            'user_53525',
            'user_53544',
            'user_53826',
            'user_54255',
            'user_55394',
            'user_55395',
            'user_55396',
            'user_55398',
            'user_55399',
            'user_61344'
          ],
          dueDate: 1602273600,
          startDate: 1601210270,
          worksheets: [],
          description: 'ضع خط تحت الافعا المضارعة في ص 5-7',
          author: 'user_61343',
          school_id: 'school_1',
          created: 1601210270,
          books: [
            {
              label: 'طفل الفضاء: كيف نسأل؟',
              value: 'book_16',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561634813333.jpg'
            },
            {
              label: 'سريع وأسرع',
              value: 'book_1213',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1569050470155.jpg'
            }
          ],
          authorName: 'Layal Souki',
          type: 'teacher',
          avatarlink: '',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_9282',
          name: 'واجب شهر سبتمبر',
          users: [
            'user_13',
            'user_1382',
            'user_14',
            'user_14075',
            'user_16466',
            'user_2',
            'user_21246',
            'user_21249',
            'user_21250',
            'user_22537',
            'user_24980',
            'user_24981',
            'user_24982',
            'user_24983',
            'user_24984',
            'user_26968',
            'user_27752',
            'user_29254',
            'user_29875',
            'user_29876',
            'user_29878',
            'user_29881',
            'user_29883',
            'user_30496',
            'user_30497',
            'user_30503',
            'user_30504',
            'user_30599',
            'user_30565',
            'user_30566',
            'user_30567',
            'user_30568',
            'user_30714',
            'user_30715',
            'user_30717',
            'user_30698',
            'user_30514',
            'user_30578',
            'user_30716',
            'user_30722',
            'user_31729',
            'user_34018',
            'user_36003',
            'user_38',
            'user_38378',
            'user_39',
            'user_39236',
            'user_40',
            'user_53525',
            'user_53544',
            'user_53826',
            'user_61344'
          ],
          dueDate: 1602146220,
          startDate: 1601282253,
          worksheets: [],
          description: 'يرجى قراءة القصة التالية وتسجيل صوتك ومشاركته مع المعلمة',
          author: 'user_3',
          school_id: 'school_1',
          created: 1601282253,
          books: [
            {
              label: 'العائلة',
              value: 'book_1057',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1564997054079.jpg'
            },
            {
              label: 'المشاعر',
              value: 'book_1055',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1564996666609.jpg'
            }
          ],
          authorName: 'Yassmin  Algemiai',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1603263999982.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_9622',
          name: 'test524',
          users: [
            'user_1382'
          ],
          dueDate: 1601886397,
          startDate: 1601845200,
          worksheets: [],
          description: 'ko',
          author: 'user_10',
          school_id: 'school_1',
          created: 1601845200,
          books: [
            {
              label: 'The Skateboarder',
              value: 'book_100',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561275810655.jpg'
            }
          ],
          authorName: 'kutubee teacher8',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1604414894151.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_9332',
          name: 'sept homework',
          users: [
            'user_1',
            'user_10543',
            'user_13',
            'user_1382',
            'user_1384',
            'user_14',
            'user_14073',
            'user_14075',
            'user_15',
            'user_16',
            'user_16455',
            'user_16466',
            'user_16487',
            'user_2',
            'user_20562',
            'user_21246',
            'user_21248',
            'user_21249',
            'user_21250',
            'user_21247',
            'user_22537',
            'user_24931',
            'user_24992',
            'user_24993',
            'user_24980',
            'user_24981',
            'user_24982',
            'user_24983',
            'user_24984',
            'user_24985',
            'user_24986',
            'user_24987',
            'user_24988',
            'user_24989',
            'user_26944',
            'user_26968',
            'user_27553',
            'user_27556',
            'user_27558',
            'user_27562',
            'user_27563',
            'user_27660',
            'user_27661',
            'user_27748',
            'user_27752',
            'user_29254',
            'user_29866',
            'user_29875',
            'user_29876',
            'user_29878',
            'user_29881',
            'user_29883',
            'user_30496',
            'user_30497',
            'user_30503',
            'user_30504',
            'user_30599',
            'user_30565',
            'user_30566',
            'user_30567',
            'user_30568',
            'user_30714',
            'user_30715',
            'user_30717',
            'user_30698',
            'user_30514',
            'user_30578',
            'user_30716',
            'user_30722',
            'user_30723',
            'user_31729',
            'user_327',
            'user_34018',
            'user_350',
            'user_35526',
            'user_36003',
            'user_38018',
            'user_38',
            'user_38378',
            'user_39',
            'user_38377',
            'user_39236',
            'user_39237',
            'user_39254',
            'user_39490',
            'user_39492',
            'user_39496',
            'user_39601',
            'user_40',
            'user_43728',
            'user_43729',
            'user_45133',
            'user_45607',
            'user_46280',
            'user_48153',
            'user_48154',
            'user_47872',
            'user_48721',
            'user_48722',
            'user_49210',
            'user_48483',
            'user_5031',
            'user_50682',
            'user_50683',
            'user_50693',
            'user_50753',
            'user_50754',
            'user_51449',
            'user_53759',
            'user_53525',
            'user_53526',
            'user_53544',
            'user_53826',
            'user_55329',
            'user_55330',
            'user_583',
            'user_6487',
            'user_55393',
            'user_61342',
            'user_61344',
            'user_63077',
            'user_63282',
            'user_63284',
            'user_65115',
            'user_65815',
            'user_70964'
          ],
          dueDate: 1601877120,
          startDate: 1601413200,
          worksheets: [],
          description: 'record ',
          author: 'user_3',
          school_id: 'school_1',
          created: 1601413200,
          books: [
            {
              label: 'My S Words',
              value: 'book_1430',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1599130489128.jpg'
            },
            {
              label: 'قطارات قديمة',
              value: 'book_710',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561378940669.jpg'
            },
            {
              label: 'الدفع والسحب',
              value: 'book_706',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561290773273.jpg'
            }
          ],
          authorName: 'Yassmin  Algemiai',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1603263999982.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_9289',
          name: 'test19',
          users: [
            'user_1382'
          ],
          dueDate: 1601292161,
          startDate: 1601236800,
          worksheets: [],
          description: 'test19',
          author: 'user_10',
          school_id: 'school_1',
          created: 1601236800,
          books: [
            {
              label: 'Where is Taffy',
              value: 'book_1',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561537678041.jpg'
            }
          ],
          authorName: 'kutubee teacher8',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1604414894151.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_8918',
          name: 'Animals',
          users: [
            'user_1',
            'user_10543',
            'user_13',
            'user_1382',
            'user_1384',
            'user_14',
            'user_14073',
            'user_14075',
            'user_15',
            'user_16',
            'user_16455',
            'user_16466',
            'user_16487',
            'user_2',
            'user_20562',
            'user_21246',
            'user_21248',
            'user_21249',
            'user_21250',
            'user_21247',
            'user_22537',
            'user_24931',
            'user_24992',
            'user_24993',
            'user_24980',
            'user_24981',
            'user_24982',
            'user_24983',
            'user_24984',
            'user_24985',
            'user_24986',
            'user_24987',
            'user_24988',
            'user_24989',
            'user_26944',
            'user_26968',
            'user_27553',
            'user_27556',
            'user_27558',
            'user_27562',
            'user_27563',
            'user_27660',
            'user_27661',
            'user_27748',
            'user_27752',
            'user_29254',
            'user_29866',
            'user_29875',
            'user_29876',
            'user_29878',
            'user_29881',
            'user_29883',
            'user_30496',
            'user_30497',
            'user_30503',
            'user_30504',
            'user_30599',
            'user_30565',
            'user_30566',
            'user_30567',
            'user_30568',
            'user_30714',
            'user_30715',
            'user_30717',
            'user_30698',
            'user_30514',
            'user_30578',
            'user_30716',
            'user_30722',
            'user_30723',
            'user_31729',
            'user_327',
            'user_34018',
            'user_350',
            'user_35526',
            'user_36003',
            'user_38018',
            'user_38',
            'user_38378',
            'user_39',
            'user_38377',
            'user_39236',
            'user_39237',
            'user_39254',
            'user_39490',
            'user_39492',
            'user_39496',
            'user_39601',
            'user_40',
            'user_43728',
            'user_43729',
            'user_45133',
            'user_45607',
            'user_46280',
            'user_48153',
            'user_48154',
            'user_47872',
            'user_48721',
            'user_48722',
            'user_49210',
            'user_48483',
            'user_5031',
            'user_50682',
            'user_50683',
            'user_50693',
            'user_50753',
            'user_50754',
            'user_51449',
            'user_53759',
            'user_53525',
            'user_53526',
            'user_53544',
            'user_53826',
            'user_55329',
            'user_55330',
            'user_583',
            'user_6487',
            'user_55393',
            'user_61342',
            'user_61344',
            'user_63077',
            'user_63282',
            'user_63284',
            'user_65115',
            'user_65815'
          ],
          dueDate: 1601109600,
          startDate: 1600245607,
          worksheets: [
            'https://school.kutubee.com:4000/worksheets/1600245692519.pdf'
          ],
          description: 'read and record your dum',
          author: 'user_3',
          school_id: 'school_1',
          created: 1600245607,
          books: [
            {
              label: 'Where is Taffy',
              value: 'book_1',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561537678041.jpg'
            },
            {
              label: 'An Earful',
              value: 'book_1006',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563447913873.jpg'
            }
          ],
          authorName: 'Yassmin  Algemiai',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1603263999982.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_9128',
          name: 'test',
          users: [
            'user_1382'
          ],
          dueDate: 1600954610,
          startDate: 1600954610,
          worksheets: [],
          description: 'sss  ds',
          author: 'user_10',
          school_id: 'school_1',
          created: 1600954610,
          books: [
            {
              label: 'A Friend like Vole',
              value: 'book_1004',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563446985845.jpg'
            }
          ],
          authorName: 'kutubee teacher8',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1604414894151.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_9009',
          name: 'test',
          users: [
            'user_1382'
          ],
          dueDate: 1600584506,
          startDate: 1600584506,
          worksheets: [],
          description: 'test',
          author: 'schoolAdmin_1',
          school_id: 'school_1',
          created: 1600584506,
          books: [
            {
              label: 'The Skateboarder',
              value: 'book_100',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561275810655.jpg'
            },
            {
              label: 'What Will You Wear?',
              value: 'book_1000',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563446733338.jpg'
            }
          ],
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_8989',
          name: 'test',
          users: [
            'user_1382',
            'user_14075',
            'user_16487'
          ],
          dueDate: 1600510720,
          startDate: 1600510720,
          worksheets: [],
          description: 'test',
          author: 'user_10',
          school_id: 'school_1',
          created: 1600510720,
          books: [
            {
              label: 'The Skateboarder',
              value: 'book_100',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561275810655.jpg'
            },
            {
              label: 'What Will You Wear?',
              value: 'book_1000',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563446733338.jpg'
            }
          ],
          authorName: 'kutubee teacher8',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1604414894151.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_8846',
          name: 'assign1',
          users: [
            'user_1382',
            'user_38',
            'user_327'
          ],
          dueDate: 1598868840,
          startDate: 1597745640,
          worksheets: [],
          description: 'desc',
          author: 'schoolAdmin_1',
          school_id: 'school_1',
          books: [
            {
              label: 'Where is Taffy',
              value: 'book_1',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561537678041.jpg'
            },
            {
              label: 'The Skateboarder',
              value: 'book_100',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561275810655.jpg'
            },
            {
              label: 'What Will You Wear?',
              value: 'book_1000',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563446733338.jpg'
            }
          ],
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_7032',
          name: 'assignment1',
          users: [
            'user_1382'
          ],
          school_id: 'school_1',
          dueDate: 1589490000,
          startDate: 1588140912,
          worksheets: [
            'https://school.kutubee.com:4000/worksheets/1588140938545.png'
          ],
          description: 'desc',
          author: 'user_10',
          books: [
            {
              label: 'What Will You Wear?',
              value: 'book_1000',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563446733338.jpg'
            },
            {
              label: 'The Skateboarder',
              value: 'book_100',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561275810655.jpg'
            }
          ],
          authorName: 'kutubee teacher8',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1604414894151.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_7639',
          name: 'april room',
          users: [
            'user_1',
            'user_10543',
            'user_13',
            'user_1382',
            'user_1384',
            'user_14',
            'user_14073',
            'user_14074',
            'user_14075',
            'user_15',
            'user_16',
            'user_16455',
            'user_16466',
            'user_16487',
            'user_17',
            'user_2',
            'user_20562',
            'user_21246',
            'user_21247',
            'user_21248',
            'user_21249',
            'user_21250',
            'user_327',
            'user_350',
            'user_38',
            'user_39',
            'user_40',
            'user_5031',
            'user_583',
            'user_6487',
            'user_22537',
            'user_24931',
            'user_24980',
            'user_24981',
            'user_24982',
            'user_24983',
            'user_24984',
            'user_24985',
            'user_24986',
            'user_24987',
            'user_24988',
            'user_24989',
            'user_24992',
            'user_24993',
            'user_26944',
            'user_26968',
            'user_27553',
            'user_27556',
            'user_27558',
            'user_27562',
            'user_27563',
            'user_27660',
            'user_27661',
            'user_27748',
            'user_27752',
            'user_29254',
            'user_29866',
            'user_29872',
            'user_29875',
            'user_29876',
            'user_29878',
            'user_29881',
            'user_29883',
            'user_30496',
            'user_30497',
            'user_30503',
            'user_30504',
            'user_30514',
            'user_30565',
            'user_30566',
            'user_30567',
            'user_30568',
            'user_30578',
            'user_30599',
            'user_30698',
            'user_30706',
            'user_30714',
            'user_30715',
            'user_30716',
            'user_30717',
            'user_30722',
            'user_30723',
            'user_31726',
            'user_31729',
            'user_34018',
            'user_35526',
            'user_36003',
            'user_38018',
            'user_38377',
            'user_38378',
            'user_39236',
            'user_39237',
            'user_39254',
            'user_39490',
            'user_39492',
            'user_39496',
            'user_39601',
            'user_43728',
            'user_43729',
            'user_45133'
          ],
          school_id: 'school_1',
          dueDate: 1589230800,
          startDate: 1589091066,
          worksheets: [],
          description: 'read and record ',
          author: 'user_3',
          books: [
            {
              label: 'النزاعات',
              value: 'book_1061',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1569050446193.jpg'
            },
            {
              label: 'الإنترنت الآمن',
              value: 'book_1060',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1564997408875.jpg'
            }
          ],
          authorName: 'Yassmin  Algemiai',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1603263999982.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_7528',
          name: 'april home work ',
          users: [
            'user_1',
            'user_10543',
            'user_13',
            'user_1382',
            'user_1384',
            'user_14',
            'user_14073',
            'user_14074',
            'user_14075',
            'user_15',
            'user_16',
            'user_16455',
            'user_16466',
            'user_16487',
            'user_17',
            'user_2',
            'user_20562',
            'user_21246',
            'user_21247',
            'user_21248',
            'user_21249',
            'user_21250',
            'user_327',
            'user_350',
            'user_38',
            'user_39',
            'user_40',
            'user_5031',
            'user_583',
            'user_6487',
            'user_22537',
            'user_24931',
            'user_24980',
            'user_24981',
            'user_24982',
            'user_24983',
            'user_24984',
            'user_24985',
            'user_24986',
            'user_24987',
            'user_24988',
            'user_24989',
            'user_24992',
            'user_24993',
            'user_26944',
            'user_26968',
            'user_27553',
            'user_27556',
            'user_27558',
            'user_27562',
            'user_27563',
            'user_27660',
            'user_27661',
            'user_27748',
            'user_27752',
            'user_29254',
            'user_29866',
            'user_29872',
            'user_29875',
            'user_29876',
            'user_29878',
            'user_29881',
            'user_29883',
            'user_30496',
            'user_30497',
            'user_30503',
            'user_30504',
            'user_30514',
            'user_30565',
            'user_30566',
            'user_30567',
            'user_30568',
            'user_30578',
            'user_30599',
            'user_30698',
            'user_30706',
            'user_30714',
            'user_30715',
            'user_30716',
            'user_30717',
            'user_30722',
            'user_30723',
            'user_31726',
            'user_31729',
            'user_34018',
            'user_35526',
            'user_36003',
            'user_38018',
            'user_38377',
            'user_38378',
            'user_39236',
            'user_39237',
            'user_39254',
            'user_39490',
            'user_39492',
            'user_39496',
            'user_39601',
            'user_43728',
            'user_43729'
          ],
          school_id: 'school_1',
          dueDate: 1589144400,
          startDate: 1588833457,
          worksheets: [],
          description: 'read and record ',
          author: 'user_3',
          books: [
            {
              label: 'Trek Learns to Fly',
              value: 'book_1175',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1566120886736.jpg'
            }
          ],
          authorName: 'Yassmin  Algemiai',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1603263999982.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_4423',
          name: 'read the story ',
          users: [
            'user_1',
            'user_1382',
            'user_14073',
            'user_16',
            'user_24992'
          ],
          school_id: 'school_1',
          dueDate: 1584741600,
          startDate: 1584348212,
          worksheets: [],
          description: 'read the 2erfqevs',
          author: 'user_3',
          books: [
            {
              label: 'Wetland Birds',
              value: 'book_101',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561275827253.jpg'
            }
          ],
          authorName: 'Yassmin  Algemiai',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1603263999982.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_1765',
          name: 'استخراج كلمات بها تاء مربوطة ',
          users: [
            'user_1',
            'user_1382',
            'user_14073',
            'user_16466',
            'user_15'
          ],
          school_id: 'school_1',
          dueDate: 1576098000,
          startDate: 1576098000,
          worksheets: [],
          description: 'برجاء تظليل الكلمات باللون الأصفر ',
          author: 'user_3',
          books: [
            {
              label: 'أنا والبيضة!',
              value: 'book_165',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1566714011544.jpg'
            },
            {
              label: 'سبعة فروع: أيام الأسبوع',
              value: 'book_175',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561633316752.jpg'
            }
          ],
          authorName: 'Yassmin  Algemiai',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1603263999982.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_930',
          name: 'test',
          users: [
            'user_1',
            'user_10543',
            'user_1382',
            'user_16',
            'user_16466',
            'user_16487',
            'user_21249',
            'user_41',
            'user_14'
          ],
          school_id: 'school_1',
          dueDate: 1573855200,
          startDate: 1573368447,
          worksheets: [],
          description: 'test',
          author: 'user_10',
          books: [
            {
              label: 'حصان حزين',
              value: 'book_132',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1564397384075.jpg'
            },
            {
              label: 'عبير: ع',
              value: 'book_134',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1564397418129.jpg'
            },
            {
              label: 'نور: ن',
              value: 'book_114',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1564397254209.jpg'
            },
            {
              label: 'أسد أحمر',
              value: 'book_129',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1564397341751.jpg'
            }
          ],
          authorName: 'kutubee teacher8',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1604414894151.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_154',
          name: 'test4',
          users: [
            'user_1382'
          ],
          school_id: 'school_1',
          dueDate: 1572472800,
          startDate: 1570050000,
          worksheets: [],
          description: 'desc',
          author: 'user_10',
          books: [
            {
              label: 'Where is Taffy',
              value: 'book_1',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561537678041.jpg'
            }
          ],
          authorName: 'kutubee teacher8',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1604414894151.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_155',
          name: 'schooltest3',
          users: [
            'user_1382'
          ],
          school_id: 'school_1',
          dueDate: 1572472800,
          startDate: 1569939525,
          worksheets: [],
          description: 'dasdasdasdasdasdasdasd',
          author: 'user_10',
          books: [
            {
              label: 'The Skateboarder',
              value: 'book_100',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561275810655.jpg'
            },
            {
              label: 'What Will You Wear?',
              value: 'book_1000',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563446733338.jpg'
            }
          ],
          authorName: 'kutubee teacher8',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1604414894151.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_153',
          name: 'test',
          users: [
            'user_1382'
          ],
          school_id: 'school_1',
          dueDate: 1572386400,
          startDate: 1569939250,
          worksheets: [],
          description: 'test',
          author: 'user_10',
          books: [
            {
              label: 'The Skateboarder',
              value: 'book_100',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561275810655.jpg'
            },
            {
              label: 'What Will You Wear?',
              value: 'book_1000',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563446733338.jpg'
            }
          ],
          authorName: 'kutubee teacher8',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1604414894151.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_156',
          name: 'schooltest3',
          users: [
            'user_1382',
            'user_1'
          ],
          school_id: 'school_1',
          dueDate: 1571950800,
          startDate: 1569939571,
          worksheets: [],
          description: 'dsadasd',
          author: 'user_10',
          books: [
            {
              label: 'The Skateboarder',
              value: 'book_100',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561275810655.jpg'
            },
            {
              label: 'What Will You Wear?',
              value: 'book_1000',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563446733338.jpg'
            }
          ],
          authorName: 'kutubee teacher8',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1604414894151.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_218',
          name: 'gmhnf',
          users: [
            'user_2',
            'user_14',
            'user_17',
            'user_40',
            'user_1382',
            'user_14073',
            'user_14074',
            'user_14075',
            'user_16466'
          ],
          school_id: 'school_1',
          dueDate: 1571173200,
          startDate: 1570604956,
          worksheets: [],
          description: 'hfmujthf ku',
          author: 'user_3',
          feedback: 'test',
          books: [
            {
              label: 'من فرخ إلى دجاجة',
              value: 'book_1210',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1569050130977.jpg'
            }
          ],
          authorName: 'Yassmin  Algemiai',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1603263999982.png',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_140',
          name: 'assg23',
          users: [
            'user_1',
            'user_1382',
            'user_2'
          ],
          school_id: 'school_1',
          dueDate: 1570654800,
          startDate: 1569843568,
          worksheets: [],
          description: 'desc',
          author: 'school_1',
          books: [
            {
              label: 'The Skateboarder',
              value: 'book_100',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561275810655.jpg'
            },
            {
              label: 'What Will You Wear?',
              value: 'book_1000',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563446733338.jpg'
            }
          ],
          authorName: 'Kutubee School',
          type: 'school',
          avatarlink: '',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_143',
          name: 'ttes',
          users: [
            'user_1382',
            'user_1',
            'user_2'
          ],
          school_id: 'school_1',
          dueDate: 1570654800,
          startDate: 1569844427,
          worksheets: [],
          description: 'test',
          author: 'school_1',
          books: [
            {
              label: 'The Skateboarder',
              value: 'book_100',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1561275810655.jpg'
            },
            {
              label: 'What Will You Wear?',
              value: 'book_1000',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563446733338.jpg'
            }
          ],
          authorName: 'Kutubee School',
          type: 'school',
          avatarlink: '',
          assignmentStatus: 'finished'
        },
        {
          _id: 'assignment_125',
          name: 'test4',
          users: [
            'user_16',
            'user_39',
            'user_1382'
          ],
          school_id: 'school_1',
          dueDate: 1569790800,
          startDate: 1569565447,
          worksheets: [],
          description: 'desc1',
          author: 'user_10',
          feedback: 'test',
          feedbackAuthor: 'kutubee teacher8',
          books: [
            {
              label: 'An Earful',
              value: 'book_1006',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563447913873.jpg'
            },
            {
              label: 'هل تستطيع أن تحزر؟',
              value: 'book_1007',
              coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563705369886.jpg'
            }
          ],
          authorName: 'kutubee teacher8',
          type: 'teacher',
          avatarlink: 'https://school.kutubee.com:4000/avatars/1604414894151.png',
          assignmentStatus: 'finished'
        }
      ],
      isUserLogged: true,
      schoolClasses: [],
      currentRegStep: 'registration',
      userProgress: [
        {
          _id: '5fcf3457a3c5817c3632d5ae',
          userId: 'user_1382',
          bookId: 'book_616',
          data: {
            studentModeTime: 146,
            originalModeTime: 0,
            pagesFlipped: 31,
            studentModeFinished: false,
            originalModeFinished: false,
            minTimeSpent: '180',
            date: 1607414871,
            datetime: '2020-12-08T08:07:51.822Z',
            bookId: 'book_616',
            numberOfPages: 31,
            bookLang: 'ar'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'ar',
          updateDate: 1607415057,
          bookName: 'الوحش المرعب في المرآة',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1566728467879.jpg',
          numberOfPages: 31,
          category: [
            {
              name: 'Level 14',
              id: 'cat_118'
            },
            {
              name: 'م',
              id: 'cat_63'
            },
            {
              name: ' 9-11',
              id: 'cat_28'
            },
            {
              name: 'Stories',
              id: 'cat_13'
            },
            {
              name: 'Living Creatures',
              id: 'cat_5'
            }
          ],
          bookQuizExist: true
        },
        {
          _id: '5fa101cb19ebed455502f9d2',
          userId: 'user_1382',
          bookId: 'book_1619',
          data: {
            bookId: 'book_1619',
            studentModeTime: 391,
            originalModeTime: 0,
            pagesFlipped: 7,
            studentModeFinished: false,
            originalModeFinished: false,
            minTimeSpent: '',
            numberOfPages: 25,
            bookLang: 'en',
            date: 1604387275,
            datetime: '2020-11-03T07:07:55.492Z'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'en',
          updateDate: 1607345058,
          bookName: 'The Birthday Presents',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1596535530421.jpg',
          numberOfPages: 25,
          category: [
            {
              name: 'How We Express ourselves',
              id: 'cat_46'
            },
            {
              name: '6-8',
              id: 'cat_92'
            },
            {
              name: 'Family & Society',
              id: 'cat_38'
            },
            {
              name: 'Stories',
              id: 'cat_43'
            }
          ],
          bookQuizExist: false
        },
        {
          _id: '5fa101ae19ebed455502f9d0',
          userId: 'user_1382',
          bookId: 'book_1618',
          data: {
            bookId: 'book_1618',
            studentModeTime: 162,
            originalModeTime: 29,
            pagesFlipped: 11,
            studentModeFinished: false,
            originalModeFinished: false,
            minTimeSpent: '',
            numberOfPages: 25,
            bookLang: 'en',
            date: 1604387246,
            datetime: '2020-11-03T07:07:26.542Z'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'en',
          updateDate: 1605081878,
          bookName: 'Rabbit\'s Wish',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1596534122945.jpg',
          numberOfPages: 25,
          category: [
            {
              name: 'Who We Are',
              id: 'cat_44'
            },
            {
              name: '6-8',
              id: 'cat_92'
            },
            {
              name: 'Family & Society',
              id: 'cat_38'
            },
            {
              name: 'Stories',
              id: 'cat_43'
            }
          ],
          bookQuizExist: false
        },
        {
          _id: '5fa102ec19ebed455502f9f7',
          userId: 'user_1382',
          bookId: 'book_637',
          data: {
            bookId: 'book_637',
            studentModeTime: 0,
            originalModeTime: 290,
            pagesFlipped: 13,
            studentModeFinished: false,
            originalModeFinished: false,
            minTimeSpent: '180',
            numberOfPages: 22,
            bookLang: 'ar',
            date: 1604387564,
            datetime: '2020-11-03T07:12:44.426Z'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'ar',
          updateDate: 1604387675,
          bookName: 'رحلة سلحفاة',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1564923035711.jpg',
          numberOfPages: 22,
          category: [
            {
              name: 'م',
              id: 'cat_63'
            },
            {
              name: 'How The World Works',
              id: 'cat_23'
            },
            {
              name: ' 9-11',
              id: 'cat_28'
            },
            {
              name: 'Stories',
              id: 'cat_13'
            },
            {
              name: 'Living Creatures',
              id: 'cat_5'
            }
          ],
          bookQuizExist: true
        },
        {
          _id: '5fa0220f9e62f1352c2ac803',
          userId: 'user_1382',
          bookId: 'book_593',
          data: {
            studentModeTime: 0,
            originalModeTime: 141,
            pagesFlipped: 16,
            studentModeFinished: false,
            originalModeFinished: true,
            minTimeSpent: '67',
            date: 1604329999,
            datetime: '2020-11-02T15:13:19.878Z',
            bookId: 'book_593',
            numberOfPages: 16,
            bookLang: 'ar'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'ar',
          updateDate: 1604387368,
          readDate: 1604329999,
          bookName: 'الرجل وابنه والحمار',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1564915783910.jpg',
          numberOfPages: 16,
          category: [
            {
              name: 'ل',
              id: 'cat_62'
            },
            {
              name: ' 9-11',
              id: 'cat_28'
            },
            {
              name: 'Stories',
              id: 'cat_13'
            }
          ],
          bookQuizExist: true
        },
        {
          _id: '5fa021339e62f1352c2ac7b2',
          userId: 'user_1382',
          bookId: 'book_166',
          data: {
            bookId: 'book_166',
            studentModeTime: 0,
            originalModeTime: 93,
            pagesFlipped: 11,
            studentModeFinished: false,
            originalModeFinished: false,
            minTimeSpent: '18',
            numberOfPages: 11,
            bookLang: 'ar',
            date: 1604329779,
            datetime: '2020-11-02T15:09:39.267Z'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'ar',
          updateDate: 1604387325,
          bookName: 'الصديقتان',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1566212007774.jpg',
          numberOfPages: 11,
          category: [
            {
              name: 'ج',
              id: 'cat_53'
            },
            {
              name: 'Who We Are',
              id: 'cat_20'
            },
            {
              name: 'How The World Works',
              id: 'cat_23'
            },
            {
              name: ' 6-8',
              id: 'cat_19'
            },
            {
              name: 'Living Creatures',
              id: 'cat_5'
            },
            {
              name: 'Family & Society',
              id: 'cat_8'
            },
            {
              name: 'الحيوانات',
              id: 'cat_121'
            },
            {
              name: 'الأسرة والأصدقاء',
              id: 'cat_135'
            }
          ],
          bookQuizExist: true
        },
        {
          _id: '5fa1018c19ebed455502f9ce',
          userId: 'user_1382',
          bookId: 'book_1607',
          data: {
            bookId: 'book_1607',
            studentModeTime: 0,
            originalModeTime: 47,
            pagesFlipped: 13,
            studentModeFinished: false,
            originalModeFinished: false,
            minTimeSpent: '',
            numberOfPages: 13,
            bookLang: 'ar',
            date: 1604387212,
            datetime: '2020-11-03T07:06:52.186Z'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'ar',
          updateDate: 1604387212,
          bookName: 'اِسْتِحْمامُ بَطَلٍ خارِقٍ',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1596968428285.jpg',
          numberOfPages: '13',
          category: [
            {
              name: 'عائلة ومجتمع',
              id: 'cat_8'
            },
            {
              name: 'قصص',
              id: 'cat_13'
            },
            {
              name: 'من نحن',
              id: 'cat_20'
            },
            {
              name: 'ي',
              id: 'cat_60'
            },
            {
              name: ' 6-8',
              id: 'cat_19'
            }
          ],
          bookQuizExist: true
        },
        {
          _id: '5fa1014f19ebed455502f9c6',
          userId: 'user_1382',
          bookId: 'book_1605',
          data: {
            bookId: 'book_1605',
            studentModeTime: 0,
            originalModeTime: 132,
            pagesFlipped: 13,
            studentModeFinished: false,
            originalModeFinished: false,
            minTimeSpent: '',
            numberOfPages: 13,
            bookLang: 'ar',
            date: 1604387151,
            datetime: '2020-11-03T07:05:51.373Z'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'ar',
          updateDate: 1604387151,
          bookName: 'الجَميعُ ضِدَّ الصَّيّادين',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1596968271042.jpg',
          numberOfPages: '13',
          category: [
            {
              name: 'كائنات حية',
              id: 'cat_5'
            },
            {
              name: 'نتشارك الكوكب',
              id: 'cat_24'
            },
            {
              name: 'ي',
              id: 'cat_60'
            },
            {
              name: ' 6-8',
              id: 'cat_19'
            }
          ],
          bookQuizExist: true
        },
        {
          _id: '5f9fbab49b6ae43a596abc18',
          userId: 'user_1382',
          bookId: 'book_1596',
          data: {
            bookId: 'book_1596',
            studentModeTime: 2,
            originalModeTime: 214,
            pagesFlipped: 13,
            studentModeFinished: false,
            originalModeFinished: true,
            minTimeSpent: '',
            numberOfPages: 13,
            bookLang: 'ar',
            date: 1604303540,
            datetime: '2020-11-02T07:52:20.140Z'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'ar',
          updateDate: 1604386996,
          readDate: 1604386996,
          bookName: 'المدير الجديد',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1596967550283.jpg',
          numberOfPages: '13',
          category: [
            {
              name: 'عائلة ومجتمع',
              id: 'cat_8'
            },
            {
              name: 'من نحن',
              id: 'cat_20'
            },
            {
              name: 'ط',
              id: 'cat_59'
            },
            {
              name: ' 6-8',
              id: 'cat_19'
            }
          ],
          bookQuizExist: true
        },
        {
          _id: '5fa0ffca19ebed455502f99e',
          userId: 'user_1382',
          bookId: 'book_1574',
          data: {
            bookId: 'book_1574',
            studentModeTime: 0,
            originalModeTime: 28,
            pagesFlipped: 5,
            studentModeFinished: false,
            originalModeFinished: false,
            minTimeSpent: '',
            numberOfPages: 14,
            bookLang: 'en',
            date: 1604386762,
            datetime: '2020-11-03T06:59:22.459Z'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'en',
          updateDate: 1604386762,
          bookName: 'Kip Gets Fit',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1599340019824.jpg',
          numberOfPages: 14,
          category: [
            {
              name: '6-8',
              id: 'cat_92'
            },
            {
              name: 'Language',
              id: 'cat_39'
            },
            {
              name: 'Stories',
              id: 'cat_43'
            },
            {
              name: 'Level 6',
              id: 'cat_145'
            }
          ],
          bookQuizExist: false
        },
        {
          _id: '5fa0ffa819ebed455502f99a',
          userId: 'user_1382',
          bookId: 'book_1565',
          data: {
            bookId: 'book_1565',
            studentModeTime: 0,
            originalModeTime: 57,
            pagesFlipped: 9,
            studentModeFinished: false,
            originalModeFinished: false,
            minTimeSpent: '',
            numberOfPages: 11,
            bookLang: 'en',
            date: 1604386728,
            datetime: '2020-11-03T06:58:48.019Z'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'en',
          updateDate: 1604386727,
          bookName: 'Hide and Seek',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1599338573203.jpg',
          numberOfPages: 11,
          category: [
            {
              name: 'How We Organise ourselves',
              id: 'cat_48'
            },
            {
              name: '3-5',
              id: 'cat_91'
            },
            {
              name: 'Living Creatures',
              id: 'cat_35'
            },
            {
              name: 'Level 3',
              id: 'cat_142'
            }
          ],
          bookQuizExist: false
        },
        {
          _id: '5fa0ff6819ebed455502f98e',
          userId: 'user_1382',
          bookId: 'book_1400',
          data: {
            studentModeTime: 0,
            originalModeTime: 38,
            pagesFlipped: 10,
            studentModeFinished: false,
            originalModeFinished: true,
            minTimeSpent: '',
            date: 1604386664,
            datetime: '2020-11-03T06:57:44.125Z'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'en',
          updateDate: 1604386663,
          readDate: 1604386664,
          bookName: 'A Cat and a Hat',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1592900919292.jpg',
          numberOfPages: 10,
          category: [
            {
              name: '6-8',
              id: 'cat_92'
            },
            {
              name: 'Level 2B',
              id: 'cat_145'
            },
            {
              name: 'Language',
              id: 'cat_39'
            }
          ],
          bookQuizExist: false
        },
        {
          _id: '5fa0ff3819ebed455502f987',
          userId: 'user_1382',
          bookId: 'book_1314',
          data: {
            bookId: 'book_1314',
            studentModeTime: 0,
            originalModeTime: 14,
            pagesFlipped: 3,
            studentModeFinished: false,
            originalModeFinished: false,
            minTimeSpent: '46',
            numberOfPages: 19,
            bookLang: 'ar',
            date: 1604386616,
            datetime: '2020-11-03T06:56:56.303Z'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'ar',
          updateDate: 1604386616,
          bookName: 'كالنحلة ز ز ز!',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1576744774627.jpg',
          numberOfPages: 19,
          category: [
            {
              name: 'ح',
              id: 'cat_58'
            },
            {
              name: 'Who We Are',
              id: 'cat_20'
            },
            {
              name: ' 6-8',
              id: 'cat_19'
            },
            {
              name: 'Stories',
              id: 'cat_13'
            },
            {
              name: 'Family & Society',
              id: 'cat_8'
            }
          ],
          bookQuizExist: true
        },
        {
          _id: '5fa0ff2319ebed455502f982',
          userId: 'user_1382',
          bookId: 'book_1132',
          data: {
            bookId: 'book_1132',
            studentModeTime: 0,
            originalModeTime: 64,
            pagesFlipped: 11,
            studentModeFinished: false,
            originalModeFinished: false,
            minTimeSpent: 526.8844959999999,
            numberOfPages: 23,
            bookLang: 'en',
            date: 1604386595,
            datetime: '2020-11-03T06:56:35.982Z'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'en',
          updateDate: 1604386595,
          bookName: 'Living Dinosaurs',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1565266460681.jpg',
          numberOfPages: 23,
          category: [
            {
              name: 'How The World Works',
              id: 'cat_47'
            },
            {
              name: '6-8',
              id: 'cat_92'
            },
            {
              name: 'Living Creatures',
              id: 'cat_35'
            }
          ],
          bookQuizExist: false
        },
        {
          _id: '5fa0fece19ebed455502f971',
          userId: 'user_1382',
          bookId: 'book_1120',
          data: {
            bookId: 'book_1120',
            studentModeTime: 0,
            originalModeTime: 71,
            pagesFlipped: 25,
            studentModeFinished: false,
            originalModeFinished: false,
            minTimeSpent: '',
            numberOfPages: 31,
            bookLang: 'en',
            date: 1604386510,
            datetime: '2020-11-03T06:55:10.144Z'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'en',
          updateDate: 1604386509,
          bookName: 'Chocolate: From Bean to Bar',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1565263410653.jpg',
          numberOfPages: 31,
          category: [
            {
              name: '6-8',
              id: 'cat_92'
            },
            {
              name: 'How The World Works',
              id: 'cat_47'
            },
            {
              name: 'Technology and space',
              id: 'cat_69'
            },
            {
              name: 'Hobbies',
              id: 'cat_42'
            },
            {
              name: 'How The World Works',
              id: 'cat_47'
            }
          ],
          bookQuizExist: false
        },
        {
          _id: '5fa0fe8119ebed455502f96a',
          userId: 'user_1382',
          bookId: 'book_1117',
          data: {
            bookId: 'book_1117',
            studentModeTime: 0,
            originalModeTime: 150,
            pagesFlipped: 17,
            studentModeFinished: false,
            originalModeFinished: false,
            minTimeSpent: '',
            numberOfPages: 23,
            bookLang: 'en',
            date: 1604386433,
            datetime: '2020-11-03T06:53:53.349Z'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'en',
          updateDate: 1604386433,
          bookName: 'What Is CGI?',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1565263095341.jpg',
          numberOfPages: 23,
          category: [
            {
              name: '6-8',
              id: 'cat_92'
            },
            {
              name: 'How We Organise ourselves',
              id: 'cat_48'
            },
            {
              name: 'How We Express ourselves',
              id: 'cat_46'
            },
            {
              name: 'Technology and space',
              id: 'cat_69'
            },
            {
              name: 'How We Organise ourselves',
              id: 'cat_48'
            }
          ],
          bookQuizExist: false
        },
        {
          _id: '5fa0fde419ebed455502f936',
          userId: 'user_1382',
          bookId: 'book_1108',
          data: {
            bookId: 'book_1108',
            studentModeTime: 0,
            originalModeTime: 213,
            pagesFlipped: 21,
            studentModeFinished: false,
            originalModeFinished: false,
            minTimeSpent: '',
            numberOfPages: 23,
            bookLang: 'en',
            date: 1604386276,
            datetime: '2020-11-03T06:51:16.609Z'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'en',
          updateDate: 1604386276,
          bookName: 'Brilliant Bridges',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1565259654349.jpg',
          numberOfPages: 23,
          category: [
            {
              name: '6-8',
              id: 'cat_92'
            },
            {
              name: 'How We Organise ourselves',
              id: 'cat_48'
            },
            {
              name: 'How The World Works',
              id: 'cat_47'
            },
            {
              name: 'Technology and space',
              id: 'cat_69'
            },
            {
              name: 'History and Civilizations',
              id: 'cat_72'
            },
            {
              name: 'How We Organise ourselves',
              id: 'cat_48'
            }
          ],
          bookQuizExist: false
        },
        {
          _id: '5fa0fd0519ebed455502f91d',
          userId: 'user_1382',
          bookId: 'book_1061',
          data: {
            bookId: 'book_1061',
            studentModeTime: 0,
            originalModeTime: 16,
            pagesFlipped: 3,
            studentModeFinished: false,
            originalModeFinished: false,
            minTimeSpent: '64',
            numberOfPages: 29,
            bookLang: 'ar',
            date: 1604386053,
            datetime: '2020-11-03T06:47:33.461Z'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'ar',
          updateDate: 1604386053,
          bookName: 'النزاعات',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1569050446193.jpg',
          numberOfPages: 29,
          category: [
            {
              name: 'ك',
              id: 'cat_61'
            },
            {
              name: 'How We Express ourselves',
              id: 'cat_22'
            },
            {
              name: ' 6-8',
              id: 'cat_19'
            },
            {
              name: 'Family & Society',
              id: 'cat_8'
            }
          ],
          bookQuizExist: true
        },
        {
          _id: '5fa0fce919ebed455502f913',
          userId: 'user_1382',
          bookId: 'book_1059',
          data: {
            bookId: 'book_1059',
            studentModeTime: 0,
            originalModeTime: 171,
            pagesFlipped: 19,
            studentModeFinished: false,
            originalModeFinished: false,
            minTimeSpent: '64',
            numberOfPages: 29,
            bookLang: 'ar',
            date: 1604386025,
            datetime: '2020-11-03T06:47:05.471Z'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'ar',
          updateDate: 1604386025,
          bookName: 'المنافسة المتهورة',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1564997288542.jpg',
          numberOfPages: 29,
          category: [
            {
              name: 'ك',
              id: 'cat_61'
            },
            {
              name: 'Who We Are',
              id: 'cat_20'
            },
            {
              name: 'How We Express ourselves',
              id: 'cat_22'
            },
            {
              name: ' 6-8',
              id: 'cat_19'
            },
            {
              name: 'Hobbies',
              id: 'cat_12'
            },
            {
              name: 'Family & Society',
              id: 'cat_8'
            }
          ],
          bookQuizExist: true
        },
        {
          _id: '5fa0172cfca2dd107ff78429',
          userId: 'user_1382',
          bookId: 'book_1046',
          data: {
            bookId: 'book_1046',
            studentModeTime: 0,
            originalModeTime: 469,
            pagesFlipped: 18,
            studentModeFinished: false,
            originalModeFinished: true,
            minTimeSpent: '190.09349999999995',
            numberOfPages: 18,
            bookLang: 'en',
            date: 1604327212,
            datetime: '2020-11-02T14:26:52.725Z'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'en',
          updateDate: 1604385836,
          readDate: 1604385836,
          bookName: 'Caring for a Pet',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1564648425253.jpg',
          numberOfPages: 18,
          category: [
            {
              name: 'How The World Works',
              id: 'cat_47'
            },
            {
              name: '6-8',
              id: 'cat_92'
            },
            {
              name: 'Living Creatures',
              id: 'cat_35'
            }
          ],
          bookQuizExist: false
        },
        {
          _id: '5f840c8cc290d45174fac646',
          userId: 'user_1382',
          bookId: 'book_1221',
          data: {
            bookId: 'book_1221',
            studentModeTime: 33,
            originalModeTime: 0,
            pagesFlipped: 2,
            studentModeFinished: false,
            originalModeFinished: true,
            minTimeSpent: '36',
            quizOpened: false,
            quizFullMark: false,
            currentMode: 'original',
            date: 1602489484,
            datetime: '2020-10-12T07:58:04.204Z',
            numberOfPages: 15,
            bookLang: 'ar'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'ar',
          updateDate: 1602490535,
          readDate: 1602489484,
          bookName: 'عالم واحد',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1569050535001.jpg',
          numberOfPages: '15',
          category: [
            {
              name: 'Level 8',
              id: 'cat_112'
            },
            {
              name: 'د',
              id: 'cat_54'
            },
            {
              name: 'How The World Works',
              id: 'cat_23'
            },
            {
              name: 'Sharing The Planet',
              id: 'cat_24'
            },
            {
              name: ' 6-8',
              id: 'cat_19'
            },
            {
              name: 'Natural Sciences',
              id: 'cat_10'
            },
            {
              name: 'في العطلة',
              id: 'cat_130'
            },
            {
              name: 'الأماكن',
              id: 'cat_136'
            },
            {
              name: 'البيئة',
              id: 'cat_137'
            }
          ],
          bookQuizExist: true
        },
        {
          _id: '5da5b9a56db02023cd2483b0',
          userId: 'user_1382',
          bookId: 'book_1248',
          data: {
            bookId: 'book_1248',
            studentModeTime: 113,
            originalModeTime: 135,
            pagesFlipped: 25,
            studentModeFinished: true,
            originalModeFinished: true,
            minTimeSpent: '36',
            numberOfPages: 25,
            bookLang: 'ar',
            date: 1571142053,
            quizOpened: false,
            quizFullMark: true,
            currentMode: 'original'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'ar',
          updateDate: 1601985730,
          readDate: 1601985688,
          bookName: 'رسالة إلى والدي',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1570712921510.jpg',
          numberOfPages: '25',
          category: [
            {
              name: 'د',
              id: 'cat_54'
            },
            {
              name: 'Who We Are',
              id: 'cat_20'
            },
            {
              name: 'How We Express ourselves',
              id: 'cat_22'
            },
            {
              name: ' 6-8',
              id: 'cat_19'
            },
            {
              name: 'Family & Society',
              id: 'cat_8'
            }
          ],
          bookQuizExist: true
        },
        {
          _id: '5f3e928f78c21c4896be4778',
          userId: 'user_1382',
          bookId: 'book_114',
          data: {
            bookId: 'book_114',
            studentModeTime: 3,
            originalModeTime: 48,
            pagesFlipped: 8,
            studentModeFinished: false,
            originalModeFinished: true,
            minTimeSpent: '7',
            numberOfPages: 8,
            bookLang: 'ar',
            date: 1597936271,
            datetime: '2020-08-20T15:11:11.024Z',
            quizOpened: true,
            quizFullMark: true,
            currentMode: 'original'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'ar',
          updateDate: 1601985300,
          readDate: 1601985274,
          bookName: 'نور: ن',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1564397254209.jpg',
          numberOfPages: '8',
          category: [
            {
              name: 'المرحلة الأولى مبتدىء',
              id: 'cat_76'
            },
            {
              name: 'أ',
              id: 'cat_51'
            },
            {
              name: 'How We Express ourselves',
              id: 'cat_22'
            },
            {
              name: ' 3-5',
              id: 'cat_18'
            },
            {
              name: 'Living Creatures',
              id: 'cat_5'
            },
            {
              name: 'Language',
              id: 'cat_9'
            },
            {
              name: 'اللغة وحروفها',
              id: 'cat_123'
            }
          ],
          bookQuizExist: true
        },
        {
          _id: '5da706b6a51eec7be3e1986d',
          userId: 'user_1382',
          bookId: 'book_220',
          data: {
            bookId: 'book_220',
            studentModeTime: 168,
            originalModeTime: 13,
            pagesFlipped: 2,
            studentModeFinished: false,
            originalModeFinished: false,
            minTimeSpent: '31',
            numberOfPages: 23,
            bookLang: 'ar',
            date: 1571227318
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'ar',
          updateDate: 1601546692,
          bookName: 'وقت النوم',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563367818584.jpg',
          numberOfPages: '23',
          category: [
            {
              name: 'ه',
              id: 'cat_55'
            },
            {
              name: 'Who We Are',
              id: 'cat_20'
            },
            {
              name: 'How We Express ourselves',
              id: 'cat_22'
            },
            {
              name: ' 6-8',
              id: 'cat_19'
            },
            {
              name: 'Stories',
              id: 'cat_13'
            },
            {
              name: 'Family & Society',
              id: 'cat_8'
            },
            {
              name: 'جسمي',
              id: 'cat_124'
            },
            {
              name: 'بيتي ومدرستي',
              id: 'cat_129'
            }
          ],
          bookQuizExist: true
        },
        {
          _id: '5da5ba176db02023cd2483b5',
          userId: 'user_1382',
          bookId: 'book_310',
          data: {
            bookId: 'book_310',
            studentModeTime: 56,
            originalModeTime: 178,
            pagesFlipped: 20,
            studentModeFinished: false,
            originalModeFinished: true,
            minTimeSpent: '36',
            numberOfPages: 20,
            bookLang: 'ar',
            date: 1571142167
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'ar',
          updateDate: 1601472472,
          readDate: 1601472472,
          bookName: 'يوم على شاطىء البحر',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1565469401912.jpg',
          numberOfPages: '20',
          category: [
            {
              name: 'د',
              id: 'cat_54'
            },
            {
              name: 'Who We Are',
              id: 'cat_20'
            },
            {
              name: ' 6-8',
              id: 'cat_19'
            },
            {
              name: 'Human Body and Health',
              id: 'cat_6'
            },
            {
              name: 'الحيوانات',
              id: 'cat_121'
            },
            {
              name: 'أنا وأسرتي',
              id: 'cat_83'
            },
            {
              name: 'في العطلة',
              id: 'cat_130'
            },
            {
              name: 'الأماكن',
              id: 'cat_136'
            }
          ],
          bookQuizExist: true
        },
        {
          _id: '5f59bd179b276845a07c8294',
          userId: 'user_1382',
          bookId: 'book_1470',
          data: {
            bookId: 'book_1470',
            studentModeTime: 53,
            originalModeTime: 0,
            pagesFlipped: 16,
            studentModeFinished: true,
            originalModeFinished: true,
            minTimeSpent: '',
            quizOpened: true,
            quizFullMark: false,
            currentMode: 'student',
            date: 1599716631,
            datetime: '2020-09-10T05:43:51.260Z',
            numberOfPages: 16,
            bookLang: 'en'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'en',
          updateDate: 1599720745,
          bookName: 'All About Me',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1599132284274.jpg',
          numberOfPages: 16,
          category: [
            {
              name: 'Who We Are',
              id: 'cat_44'
            },
            {
              name: '3-5',
              id: 'cat_91'
            },
            {
              name: 'Human Body and Health',
              id: 'cat_36'
            },
            {
              name: 'Family & Society',
              id: 'cat_38'
            },
            {
              name: 'Level 1',
              id: 'cat_139'
            }
          ],
          bookQuizExist: false
        },
        {
          _id: '5f3d52b386e6404e6f7383ce',
          userId: 'user_1382',
          bookId: 'book_1007',
          data: {
            bookId: 'book_1007',
            studentModeTime: 109,
            originalModeTime: 239,
            pagesFlipped: 17,
            studentModeFinished: false,
            originalModeFinished: true,
            minTimeSpent: '31',
            numberOfPages: 17,
            bookLang: 'ar',
            date: 1597854387,
            datetime: '2020-08-19T16:26:27.101Z',
            quizOpened: true,
            quizFullMark: true,
            currentMode: 'student',
            bookCompleted: true,
            quizTaken: true,
            quizResult: 5,
            quizQuestionsNumber: 6
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'ar',
          bookName: 'هل تستطيع أن تحزر؟',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1563705369886.jpg',
          numberOfPages: 17,
          category: [
            {
              name: 'ه',
              id: 'cat_55'
            },
            {
              name: 'How The World Works',
              id: 'cat_23'
            },
            {
              name: 'Sharing The Planet',
              id: 'cat_24'
            },
            {
              name: ' 9-11',
              id: 'cat_28'
            },
            {
              name: 'Living Creatures',
              id: 'cat_5'
            },
            {
              name: 'Environment and Pollution',
              id: 'cat_7'
            }
          ],
          bookQuizExist: true
        },
        {
          _id: '5edc94ee4fc4550785b2e03f',
          userId: 'user_1382',
          bookId: 'book_1231',
          data: {
            bookId: 'book_1231',
            studentModeTime: 7,
            originalModeTime: 0,
            pagesFlipped: 1,
            studentModeFinished: false,
            originalModeFinished: false,
            minTimeSpent: '64',
            numberOfPages: 23,
            bookLang: 'ar',
            date: 1591514350
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'ar',
          bookName: 'بيتر والذئب: مأخوذة عن العمل الأصلي لسيرج بروكوفييف',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1570358226939.jpg',
          numberOfPages: '23',
          category: [
            {
              name: 'Level 12',
              id: 'cat_116'
            },
            {
              name: 'ك',
              id: 'cat_61'
            },
            {
              name: 'How We Express ourselves',
              id: 'cat_22'
            },
            {
              name: ' 9-11',
              id: 'cat_28'
            },
            {
              name: 'Hobbies',
              id: 'cat_12'
            },
            {
              name: 'Stories',
              id: 'cat_13'
            }
          ],
          bookQuizExist: true
        },
        {
          _id: '5d9256fd049f6e1377d47140',
          userId: 'user_1382',
          bookId: 'book_187',
          data: {
            bookId: 'book_187',
            studentModeTime: 3,
            originalModeTime: 126,
            pagesFlipped: 15,
            studentModeFinished: false,
            originalModeFinished: true,
            minTimeSpent: '120.92081500000002',
            numberOfPages: 15,
            bookLang: 'ar',
            date: 1569871613,
            quizOpened: false,
            currentMode: 'original'
          },
          classId: 'class_3',
          sectionId: 'section_4',
          school_id: 'school_1',
          language: 'ar',
          bookName: 'ليس عندي ما أفعله',
          coverPhoto: 'https://school.kutubee.com:4000/cover_photo/1566476019229.jpg',
          numberOfPages: '15',
          category: [
            {
              name: 'Level 7',
              id: 'cat_111'
            },
            {
              name: 'د',
              id: 'cat_54'
            },
            {
              name: 'How The World Works',
              id: 'cat_23'
            },
            {
              name: ' 6-8',
              id: 'cat_19'
            },
            {
              name: 'Living Creatures',
              id: 'cat_5'
            },
            {
              name: 'الحيوانات',
              id: 'cat_121'
            },
            {
              name: 'الأسرة والأصدقاء',
              id: 'cat_135'
            }
          ],
          bookQuizExist: true
        }
      ],
      showNewBadge: false,
      newEarnedBadgesDialog: [],
      notificationText: '',
      showNotificationBox: false,
      currentBookTags: [],
      currentBookLevels: [],
      showNewLevelDialog: false,
      currentBookProgressSaved: false,
      showFullmarkQuizDialog: false,
      activeBooksMainFilter: 'cat_3',
      blurredBody: false,
      appLanguage: 'ar',
      dictionaryModal: false,
      vocabularyModal: false,
      vocabularyModalData: null,
      newVocabularyData: null,
      newGeneralVocabularyData: null,
      selectedBooksLanguage: 'en',
      currentArProgress: 1,
      currentEnProgress: 0,
      currentFrProgress: 0,
      discardRecordModal: false,
      subAccounts: [],
      mainAccount: {},
      individualRegistrationNew: {}
    
  }

  export default data;