import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import Formsy from "formsy-react";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";

import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "Push notification",
    link: '',
  },

];

class Add extends Component {
  state = {
    formValid: false,
    title: "",
    messageEn: "",
    messageAr: "",
    disabled: false,
    categoryData: [],
    SnackbarBoolean: false,
    conentType: '',
    showType: 'shows',
    notificationType: false,
    dataSet: [],
    contentId: ''
  };


  getconent = (t) => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    formData.append("conentType", t);
    axios({
      url: `${backend_url}/notification/getContent`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({ dataSet: res.data.items })
    });
  }


  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
    this.getconent(e.target.value)
  };



  handleChangetags = (val, name) => {
    if (val != null)
      this.setState({
        [name]: val._id,
      });
    else
      this.setState({
        [name]: '',
      });
  };


  handleSubmit = (model) => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();

    formData.append("title", this.state.title);
    formData.append("messageEn", this.state.messageEn);
    formData.append("messageAr", this.state.messageAr);


    if (this.state.notificationType) {
      if (this.state.conentType == 'shows')
        formData.append("conentType", this.state.showType);
      else
        formData.append("conentType", this.state.conentType);

      formData.append("conentId", this.state.contentId);
    }

    axios({
      url: `${backend_url}/notification/add`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      // console.log(res)
      this.setState({
        SnackbarBoolean: true,
        title: '',
        messageEn: '',
        messageAr: '',
        conentType: '',
        showType: 'shows',
        notificationType: false,
        dataSet: [],
        contentId: ''

      })
    });

  };
  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };



  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="w-full flex items-center justify-between p-24">
            <h2>Notification push</h2>
            <div className="flex items-center">
              <Link to={`${process.env.REACT_APP_FRONT_URL}notification/list`}>
                <Button color="primary" variant="contained">
                  List
                </Button>
              </Link>
            </div>
          </div>
        }
        content={
          <div className="p-24">
            <div className="max-w-lg">
              <Formsy
                onValidSubmit={this.handleSubmit}
                onValid={this.enableButton}
                onInvalid={this.disableButton}
                ref={"cateogry_add"}
                className="flex flex-col p6-12"
              >
                {/* <div className="flex flex-row justify-between py-20">
                  <TextFieldFormsy
                    className="w-6/12"
                    type="text"
                    name="title"
                    label="Message"
                    variant="outlined"
                    value={this.state.message}
                    onChange={(e) => {
                      this.handleChange(e, "message");
                    }}
                    required
                  />

                </div> */}

                <div className="flex flex-row justify-between py-20">


                  <TextFieldFormsy
                    id="outlined-multiline-static"
                    label="Message"
                    className="w-6/12"
                    name="message"
                    value={this.state.messageEn}
                    multiline
                    rows={4}
                    variant="outlined"
                    onChange={(e) => {
                      this.handleChange(e, "messageEn");
                    }}
                    required
                  />



                </div>
                <div className="flex flex-row justify-between py-20">
                  <TextFieldFormsy
                    id="outlined-multiline-static"
                    label="رسالة"
                    className="w-6/12"
                    name="message"
                    value={this.state.messageAr}
                    multiline
                    rows={4}
                    variant="outlined"
                    onChange={(e) => {
                      this.handleChange(e, "messageAr");
                    }}
                    required
                  />
                </div>
                <div className="flex flex-row justify-between py-20">
                  <FormControlLabel
                    control={<Switch checked={this.state.notificationType} onChange={''} name="gilad" />}
                    label='Deep link'
                    onClick={(e) => {
                      this.setState({ notificationType: !this.state.notificationType })
                    }}
                  />
                </div>
                {this.state.notificationType && (
                  <div>
                    <div className="flex flex-row flex-start py-20">

                      <SelectFormsy
                        className="w-3/12 mr-1"
                        name="conentType"
                        label="Conent Type"
                        value={this.state.conentType}
                        onChange={(e) => {
                          this.handleChange(e, "conentType");
                        }}
                        variant="outlined"
                      >
                        <MenuItem value="songs">Songs</MenuItem>
                        <MenuItem value="stories">Stories</MenuItem>
                        <MenuItem value="shows">Shows</MenuItem>
                      </SelectFormsy>
                      {this.state.conentType == 'shows' && (
                        <SelectFormsy
                          className="w-3/12"
                          name="showType"
                          label="Show Type"
                          value={this.state.showType}
                          onChange={(e) => {
                            this.handleChange(e, "showType");
                          }}
                          variant="outlined"
                        >
                          <MenuItem value="shows">Full Show</MenuItem>
                          <MenuItem value="season">Season</MenuItem>
                          <MenuItem value="episodes">Episode</MenuItem>
                        </SelectFormsy>
                      )}

                    </div>


                    {this.state.conentType != '' && (

                      <div className="flex flex-row justify-between ">
                        <Autocomplete
                          className="w-6/12"
                          id="tags-outlined"
                          options={this.state.dataSet}
                          filterSelectedOptions
                          getOptionLabel={(option) => option.titleEn + '-' + option.titleAr}
                          onChange={(event, value) =>

                            this.handleChangetags(value, "contentId")

                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Search for content.."
                              inputProps={{ ...params.inputProps, maxLength: 1 }}
                            />
                          )}
                        />
                      </div>
                    )}

                  </div>

                )}


                <div className="flex flex-row justify-between py-20">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="w-6/12"
                    aria-label="Add"
                    disabled={!this.state.formValid}
                  >
                    Send
                </Button>
                </div>

                <Snackbar open={this.state.SnackbarBoolean} autoHideDuration={6000}
                  onClose={() => {
                    this.setState({
                      SnackbarBoolean: false
                    })
                  }}>
                  <Alert onClose={() => {
                    this.setState({
                      SnackbarBoolean: false
                    })
                  }} severity="success">
                    Sent successfully!
        </Alert>
                </Snackbar>
              </Formsy>
            </div>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(Add);
