import React, { createElement } from 'react';
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.js"
import { connect } from 'react-redux';
import axios from 'axios';
//import {baseLink} from "./helper";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { EWOULDBLOCK } from 'constants';

const baseLink = process.env.REACT_APP_BACKEND_URL;

var wavesurfer = "";

class PageAudio extends React.Component {
    state = {open4:false,open5:false,open6:false}
    regionCount = 0;
    spansCount = 0;
    speedHandle = (e) => {
        let rate = parseFloat(e.target.value);
        wavesurfer.setPlaybackRate(rate);
    }
    componentDidUpdate(prevProps, prevState) {
        
        if(prevProps.page_count != this.props.page_count) {
            wavesurfer.empty();
            wavesurfer.clearRegions();

            this.viewSlicing();
        }
        if(this.props.page_count == 0) {
            if(prevProps.book != this.props.book) {
                wavesurfer.empty();
                wavesurfer.clearRegions();
                this.viewSlicing();
            }
        }
        if(this.props.clearSlicing == 1) {
            wavesurfer.empty();
            wavesurfer.clearRegions();
            this.props.dispatch({type:"update_slicing",clearSlicing:0});
        }
        if(this.props.clearSlicing1 == 1) {
            this.savePage();
            this.props.dispatch({type:"update_slicing1",clearSlicing1:0});
        }
    }
    viewSlicing = () => {
        let opf_dir = this.props.opf_dir;
        let page_count = this.props.page_count;
        let book = this.props.book;
        let pageType = book.pages[page_count].pageAudioType;
        let smilAudios = book.pages[page_count].smilAudios;
        let spans = "";

        let region_count = 0;

        if(pageType == "oneAudio") {
            let audio = opf_dir + smilAudios.audioLink;
            wavesurfer.load(audio);
            //wavesurfer.clearRegions();

           /* spans = smilAudios.spans;
            spans.forEach(function(span) {
                let mainId = span.id;
                let points = span.points;
                points.forEach(function(singlePoint) {
                        let r = Math.random() * (120 - 0) + 0;
                        let g = Math.random() * (120 - 0) + 0;
                        let b = Math.random() * (120 - 0) + 0;
        
                        wavesurfer.addRegion({
                            id: singlePoint.textId,
                            start: singlePoint.start, // time in seconds
                            end: singlePoint.end, // time in seconds
                            //color: 'rgba('+ r +','+ g +','+ b + ',0.3)',
                            color: 'rgba('+ r +','+ g +','+ b + ',0.35)',
                            // Note: we are preventing the first region (green) from being moved or resized.
                            drag: true,
                            resize: true
                        });
                        region_count++;
                        console.log(region_count);
                });

            });*/
        }
        else if(pageType == "paragraphs") {
            wavesurfer.empty();
            wavesurfer.clearRegions();
        }
        else {
        }
    }

    editLine = (e) => {
        let th = this;
        let id = e.target.id;
        id = id.replace("img-","");
        let book = this.props.book;
        let page_count = this.props.page_count;
        let spans = book.pages[page_count].smilAudios.spans;
        wavesurfer.clearRegions();
        spans.forEach(function(item,spanIndx) {
            if(item.id == id) {
                let currentTime = 0; 
                item.points.forEach(function(point,pointIndx) {
                    let r = Math.random() * (120 - 0) + 0;
                    let g = Math.random() * (120 - 0) + 0;
                    let b = Math.random() * (120 - 0) + 0;
                    wavesurfer.addRegion({
                        id: "c" + pointIndx,
                        start: point.start, // time in seconds
                        end: point.end, // time in seconds
                        //color: 'rgba('+ r +','+ g +','+ b + ',0.3)',
                        color: 'rgba('+ r +','+ g +','+ b + ',0.4)',
                        // Note: we are preventing the first region (green) from being moved or resized.
                        drag: false,
                        resize: true
                    });
                    currentTime = point.end;
                   // th.props.dispatch({type:"point_selected",spanIndx:spanIndx});
                });
                th.props.dispatch({type:"point_selected",spanIndx:spanIndx});
                th.props.dispatch({type:"edit_line",editLineText:true})
                th.props.dispatch({type:"update_point_index",pointIndex:false});
                th.props.dispatch({type:"add_region",startTime:currentTime});
            }
        });
    }
    addRegions = () => {
        let opf_dir = this.props.opf_dir;
        let page_count = this.props.page_count;
        let book = this.props.book;
        let pageType = book.pages[page_count].pageAudioType;
        let smilAudios = book.pages[page_count].smilAudios;
        let spans = "";
        let region_count = 0;
        let th = this;

        if(pageType == "oneAudio") {
            spans = smilAudios.spans;

           if(spans == undefined) {
                return;
            }
            spans.forEach(function(span) {
                let mainId = span.id;                
                if(document.getElementById(span.id) != null || document.getElementById(span.id) != undefined) {
                    document.getElementById(span.id).innerHTML += "<a class='edit-text' href='#' id='img-" + mainId +"'>edit</a>";
                }
                
                var elems = document.getElementsByClassName("edit-text");
                for (var i = 0; i < elems.length; i++) {
                    elems[i].onclick = th.editLine;
                 }

                let points = span.points;
                points.forEach(function(singlePoint) {


                            let r = Math.random() * (120 - 0) + 0;
                            let g = Math.random() * (120 - 0) + 0;
                            let b = Math.random() * (120 - 0) + 0;
       

        
                        wavesurfer.addRegion({
                            id: singlePoint.textId,
                            start: singlePoint.start, // time in seconds
                            end: singlePoint.end, // time in seconds
                            //color: 'rgba('+ r +','+ g +','+ b + ',0.3)',
                            color: 'rgba('+ r +','+ g +','+ b + ',0.4)',
                            // Note: we are preventing the first region (green) from being moved or resized.
                            drag: false,
                            resize: true
                        });
                        region_count++;
                });
    
            });
    
        }
        else if(pageType == "paragraphs") {
           // console.log(book.pages[page_count]);
        }
    }
    componentDidMount() {

       
        let th = this;


        wavesurfer = WaveSurfer.create({
            container: '#waveform',
            waveColor: '#6CCEFB',
            progressColor: '#6CCEFB',
            currentTime:0,
           // backend: 'MediaElement',
            audioRate:1,
            plugins: [
                RegionsPlugin.create({
                    // plugin options ...
                }),
                TimelinePlugin.create({
                    container :"#wave-timeline",
                    timeInterval:0.05,
                    fontSize:16
                })
            ]
        });
        document.querySelector('#slider').oninput = function () {
            wavesurfer.zoom(Number(this.value));
            //wavesurfer.params.audioRate = 0.2;
           // console.log(wavesurfer);
        };

        
        wavesurfer.disableDragSelection();
        wavesurfer.on('ready', function () {
            th.addRegions();
            //wavesurfer.enableDragSelection({});
            // wavesurfer.play(0, 4);
            /*
            wavesurfer.on('region-updated', function(region, e) {
                let list = Object.values(wavesurfer.regions.list);
                let keys = Object.keys(wavesurfer.regions.list);
                let regionId = region.id; 
                regionId = regionId.replace("r","");
                let nextregion = parseInt(regionId);
                let prevregion = parseInt(regionId);
                nextregion++;
                prevregion--;
                let current_key = keys.indexOf(regionId);

                if(current_key != -1) {
                    let next_key= current_key + 1;
                    let prev_key= current_key - 1;
                    console.log(prev_key);
                    console.log(current_key);
                    console.log(next_key);

                    if(prev_key != -1) {
                        wavesurfer.regions.list[keys[prev_key]].end = wavesurfer.regions.list[keys[current_key]].start;
                    }
                    if(next_key != list.length) {
                        wavesurfer.regions.list[keys[next_key]].start = wavesurfer.regions.list[keys[current_key]].end;
                    }
                }
            });*/
            
            wavesurfer.on('region-update-end', function(region, e) {
                
                let id = region.id;
                
                let keys = Object.keys(wavesurfer.regions.list); 
                let values = Object.values(wavesurfer.regions.list);
                let indx = keys.indexOf(id);
                // let curRegion = wavesurfer.regions.list.indexOf()
                
                if((indx - 1) == -1) {
                    return;
                }
                 values[indx - 1].update({ 
                     end:region.start 
                 })
                let startEnd = values[values.length - 1].end;
                th.props.dispatch({type : "add_region",startTime:startEnd});
                let editLineText = th.props.editLineText;
                if(document.querySelectorAll(".links-right select")[0].disabled == true && editLineText == true) {
                    let book = th.props.book;
                    let page_count = th.props.page_count;
                    let spanIndx = th.props.spanIndx;
                    let smilAudiosSpans = book.pages[page_count].smilAudios.spans[spanIndx];
                    let selectedId = book.pages[page_count].smilAudios.spans[spanIndx].id;
                    book.pages[page_count].smilAudios.spans[spanIndx].points = [];
                    values.forEach(function(item,indx) {
                        if(book.pages[page_count].smilAudios.spans[spanIndx].points[indx] == undefined) {
                            book.pages[page_count].smilAudios.spans[spanIndx].points[indx] = {};
                        }
                        book.pages[page_count].smilAudios.spans[spanIndx].points[indx].start = item.start;
                        book.pages[page_count].smilAudios.spans[spanIndx].points[indx].end = item.end;
                        book.pages[page_count].smilAudios.spans[spanIndx].points[indx].textId = selectedId + "-" + indx;
                    });
                    th.props.dispatch({type:"update_book2",book:book});
                    /*if(book.pages[page_count].pageAudioType == "oneAudio") {
                        let smilAudiosSpans = book.pages[page_count].smilAudios.spans;
                        let split = id.lastIndexOf("-");
                        let mainId = id.substring(0,split);
                        let idIndx = parseInt(id.substring(split + 1));
                        smilAudiosSpans.forEach(function(spanItem,indx) {
                            if(spanItem.id == mainId) {

                                book.pages[page_count].smilAudios.spans[indx].points[idIndx].start = region.start;
                                book.pages[page_count].smilAudios.spans[indx].points[idIndx].end = region.end;
                                
                                if(spanItem.points[idIndx - 1] != undefined) {
                                    //spanItem.points[idIndx - 1].end = region.start;
                                    book.pages[page_count].smilAudios.spans[indx].points[idIndx - 1].end = region.start;
                                }
                                if(spanItem.points[idIndx + 1] != undefined) {
                                   // spanItem.points[idIndx + 1].start = region.end;
                                    book.pages[page_count].smilAudios.spans[indx].points[idIndx + 1].start = region.end;
                                }
                            }
                        });
                        th.props.dispatch({type:"update_book2",book:book})
                    }
                    */
                   
                }
                
            });

            wavesurfer.on('region-click', function(region, e) {
               // console.log(region.start); 
                //console.log(region.end); 
                
                if(document.getElementById(region.id) != null) {
                    document.querySelectorAll(".yellow-hilight2").forEach(function(item) {
                        item.classList.remove("yellow-hilight2");
                    });
                  document.getElementById(region.id).classList.add("yellow-hilight2");

                }
               /* region.update({
                    color:"rgba(0,0,0,0.35)"
                });*/
               // region.
                e.stopPropagation(); 
                th.setState({
                    selectedRegion:region
                });
                wavesurfer.play(region.start, region.end);
            });
            wavesurfer.on('pause', function(region, e) {
                document.querySelectorAll(".yellow-hilight2").forEach(function(item) {
                    item.classList.remove("yellow-hilight2");
                });
            });
        });
        


         
        document.addEventListener('keydown',function(e) {
            //space_bar_code
            if(e.keyCode == 46) {
                let regionList = Object.values(wavesurfer.regions.list);
                let endTime = regionList[regionList.length - 1].end;
                let selectedRegion = th.state.selectedRegion;
                if(selectedRegion != "") {
                    selectedRegion.remove();
                    th.setState({
                        selectedRegion:""
                    });
                }
                th.props.dispatch({type : "add_region",startTime:endTime});


                let editLineText = th.props.editLineText;
                let keys = Object.keys(wavesurfer.regions.list); 
                let values = Object.values(wavesurfer.regions.list);

                if(document.querySelectorAll(".links-right select")[0].disabled == true && editLineText == true) {
                    let book = th.props.book;
                    let page_count = th.props.page_count;
                    let spanIndx = th.props.spanIndx;
                    let smilAudiosSpans = book.pages[page_count].smilAudios.spans[spanIndx];
                    let selectedId = book.pages[page_count].smilAudios.spans[spanIndx].id;
                    book.pages[page_count].smilAudios.spans[spanIndx].points = [];
                    values.forEach(function(item,indx) {
                        if(book.pages[page_count].smilAudios.spans[spanIndx].points[indx] == undefined) {
                            book.pages[page_count].smilAudios.spans[spanIndx].points[indx] = {};
                        }
                        book.pages[page_count].smilAudios.spans[spanIndx].points[indx].start = item.start;
                        book.pages[page_count].smilAudios.spans[spanIndx].points[indx].end = item.end;
                        book.pages[page_count].smilAudios.spans[spanIndx].points[indx].textId = selectedId + "-" + indx;
                    });
                    th.props.dispatch({type:"update_book2",book:book});
                    let currentTime = 0;
                    if(values.length != 0) {
                        currentTime = values[values.length - 1].end;
                    }

                    th.props.dispatch({type:"add_region",startTime:currentTime});
                }
                e.preventDefault();

            }
            if(e.keyCode == 32) {
                let startTime = th.props.startTime;
                let endTime = wavesurfer.getCurrentTime();
                let regionCount = th.regionCount;


                let r = Math.random() * (120 - 0) + 0;
                let g = Math.random() * (120 - 0) + 0;
                let b = Math.random() * (120 - 0) + 0;

                wavesurfer.addRegion({
                    id: "r" + regionCount,
                    start: startTime, // time in seconds
                    end: endTime, // time in seconds
                    color: 'rgba('+ r +','+ g +','+ b + ',0.4)',
                    // Note: we are preventing the first region (green) from being moved or resized.
                    drag: false,
                    resize: true
                });
                regionCount++;
                th.regionCount = regionCount;

                th.props.dispatch({type : "add_region",startTime:endTime});

                e.preventDefault();
                //aud.pause(); 

                let editLineText = th.props.editLineText;

                if(document.querySelectorAll(".links-right select")[0].disabled == true && editLineText == true) {
                    let values = Object.values(wavesurfer.regions.list);    
                    let book = th.props.book;
                    let page_count = th.props.page_count;
                    let spanIndx = th.props.spanIndx;
                    let smilAudiosSpans = book.pages[page_count].smilAudios.spans[spanIndx];
                    let selectedId = book.pages[page_count].smilAudios.spans[spanIndx].id;
                    book.pages[page_count].smilAudios.spans[spanIndx].points = [];
                    values.forEach(function(item,indx) {
                        if(book.pages[page_count].smilAudios.spans[spanIndx].points[indx] == undefined) {
                            book.pages[page_count].smilAudios.spans[spanIndx].points[indx] = {};
                        }
                        book.pages[page_count].smilAudios.spans[spanIndx].points[indx].start = item.start;
                        book.pages[page_count].smilAudios.spans[spanIndx].points[indx].end = item.end;
                        book.pages[page_count].smilAudios.spans[spanIndx].points[indx].textId = selectedId + "-" + indx;
                    });
                    th.props.dispatch({type:"update_book2",book:book});
                }
            }
        });
    }
    selectText2 = (e) => {  
        
    }
    uploadAudio = (e) => {
        let th = this;

        if(e.target.files.length == 0) {
            return;

        }

        th.setState({
            open4:true
        });



       
        /*this.setState({
            uploadAudio:e.target.files[0],
            audioName:e.target.files[0].name
        });*/
       // this.uploadAudioFile = e.target.files[0];
        //this.props.audioName = e.target.files[0].name;
        /*this.setState({
            audioName:e.target.files[0].name
        })*/
      //this.props.audioName = e.target.files[0].name;
      if(e.target.files[0] == undefined) {
          return;
      }
      this.props.dispatch({type:"update_point_index",pointIndex:false});

       this.props.dispatch({type : "select_audio",audioObj:e.target.files[0],audioName:e.target.files[0].name,selectedPara:1});
       setTimeout(() => {
            this.saveUpdates(e);
            document.getElementById("file-upload").value  = "";
        }, 500);
    }
    saveUpdates = (e) => {
        let th = this;
        //let opf_dir = localStorage.getItem("opf_dir");
        if(th.props.pageType == "oneAudio") {
        }


        var url_string = window.location.href;
        var url = new URL(url_string);
        let opf_link = url.searchParams.get("opf_file");
        let opf_dir = opf_link.replace("content.opf","");
        var bodyFormData = new FormData();

        bodyFormData.set('sampleFile', this.props.audioObj);
        bodyFormData.set('base_path',opf_dir); 
        axios({
            method: 'post',
            url: baseLink+ '/upload_audio',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(function (res) {
                let data = res.data;
                let audioSrc = data.file;
                let audio2 = data.file2;
                wavesurfer.load(audioSrc);
                wavesurfer.clearRegions();
                th.props.dispatch({type:"add_region",startTime:0})

                th.setState({
                    audioDuration:data.duration,
                    audioSrc:audioSrc,
                    audio2:audio2
                });
                let spansList = th.props.spansList;
                let page_count = th.props.page_count;
                let selectedSpans = spansList[page_count];
              //  console.log(selectedSpans);
              //  console.log(selectedSpans[0].id)
                if(th.props.pageType == "oneAudio") {
                    th.spansCount =  0;
                    let selectedId = selectedSpans[th.spansCount].id;
                    document.getElementById(selectedId).style.backgroundColor = "red";    
                    th.props.dispatch({type:"update_txt",selectid:selectedId})
                }
                else {
                    document.getElementById("root").style.userSelect = "none";
                    th.props.selectedIds.forEach(function(item,indx) {
                        if(indx == 0) {
//                            document.getElementById(item.id).style.backgroundColor = "yellow";
                            document.getElementById(item.id).classList.add("yellow-hilight");        
                        }
                        else {
                            document.getElementById(item.id).classList.add("red-hilight");        

                        }
                    });
                }
                

                setTimeout(() => {
                    th.setState({
                        open4:false
                    });
                }, 2000);
            })
            .catch(function (response) {
            });
    }
    playAll = (e) => {
       /* this.setState({
            startTime:wavesurfer.getCurrentTime()
        });*/
        wavesurfer.play();
        document.getElementsByClassName("play-bt")[0].style.display = "none";
        document.getElementsByClassName("pause-bt")[0].style.display = "block";
    }
    pauseAll = (e) => {
        /* this.setState({
             startTime:wavesurfer.getCurrentTime()
         });*/
         wavesurfer.pause();
         document.getElementsByClassName("play-bt")[0].style.display = "block";
         document.getElementsByClassName("pause-bt")[0].style.display = "none";
     }
     skipText = (e) => {
        let th = this;
        let selectid = this.props.selectid;
        let page_count = this.props.page_count;
        let spansList = this.props.spansList;
        let selectedIds= this.props.selectedIds;
        let selectedIndex = this.props.selectedIndex;

        if(this.props.pageType == "paragraphs") {
            selectid = selectedIds[selectedIndex].id;
            selectedIndex++;
            this.props.dispatch({type:"update_select",selectedIndex:selectedIndex});


                let selectedIndex3 = th.props.selectedIndex;
                selectedIndex3++;

                    document.getElementById(th.props.selectedIds[selectedIndex3 - 1].id).classList.add("red-hilight");
                    document.getElementById(th.props.selectedIds[selectedIndex3 - 1].id).classList.remove("yellow-hilight");

                if(th.props.selectedIds[selectedIndex3] != undefined) {
                    document.getElementById(th.props.selectedIds[selectedIndex3].id).classList.add("yellow-hilight");
                    document.getElementById(th.props.selectedIds[selectedIndex3].id).classList.remove("red-hilight");    
                }
                if(selectedIndex3 == th.props.selectedIds.length) {
                    document.getElementById("root").style.userSelect = "auto";
                    if(document.querySelectorAll(".red-hilight").length > 0 ) {
                        document.querySelectorAll(".red-hilight").forEach(function(item) {
                            item.classList.remove("red-hilight");
                        });
                    }
                    
                }

            
            return;
        }
        
            if(selectid == "") {
                return;
            }
        
        
        let selectedSpans = spansList[page_count];

    
        document.getElementById(selectid).style.backgroundColor = "";    
        this.spansCount++;
        if(selectedSpans[this.spansCount] !=undefined){
            let selectedId = selectedSpans[this.spansCount].id;
            document.getElementById(selectedId).style.backgroundColor = "red";    
            this.props.dispatch({type:"update_txt",selectid:selectedId});
        }
     }
    saveText = (e) => {
        let page_count = this.props.page_count;

        let audioName = this.props.audioName;
        let book = this.props.book;
        let selectid = this.props.selectid;
        let selectedIds = this.props.selectedIds;
    
        let spansList = this.props.spansList;
        let selectedSpans = spansList[page_count];
        let currentindex =  0;
        let th = this;




        if(selectid == "" && this.props.pageType == "oneAudio") {
            return;
        }

        if(selectedIds.length == 0 && this.props.pageType == "paragraphs") {
            return;
        }

        if(this.props.pageType == "paragraphs") {
            let selectedIds= this.props.selectedIds;
            let selectedIndex = this.props.selectedIndex;
            selectid = selectedIds[selectedIndex].id;
            selectedIndex++;
            this.props.dispatch({type:"update_select",selectedIndex:selectedIndex});
        }
      //  
      
      //console.log(document.getElementById(selectid).childNodes);
      let region_list = Object.values(wavesurfer.regions.list);

            if(this.props.pageType == "oneAudio") { 
                document.getElementById(selectid).style.backgroundColor = "";    
                this.spansCount++;
                if(selectedSpans[this.spansCount] != undefined) {
                    let selectedId = selectedSpans[this.spansCount].id;
                    document.getElementById(selectedId).style.backgroundColor = "red";    
                    this.props.dispatch({type:"update_txt",selectid:selectedId});
                }    
            }

      


            let pointIndex = this.props.pointIndex;
            let text_arr = {};
            let textPoints = [];
            text_arr.id = "id1";
            let startPoint = 0;
            let startPoint1 = 0;
            let endPoint = 0;
            let words = [];
            let checkPDf = false;
            
            if(document.querySelectorAll("#parent-p1").length != 0) {
                checkPDf = true;
                document.getElementById(selectid).childNodes.forEach(function(item) {
                    if(item.id.search("word") != -1) {
                        words.push(item.id);
                    }
                });
            }
            region_list.forEach(function(regionItem,index) {
                let textPoint = {};
                textPoint.start = regionItem.start;
                textPoint.end = regionItem.end;
                if(pointIndex == false) {
                    if(index == 0) {
                        startPoint = textPoint.start;
                    }
                    if(index == region_list.length - 1) {
                        endPoint = textPoint.end;
                    }
                    

                        if(checkPDf == true) { 
                            textPoint.textId = words[index];
                        }
                        else  {
                            textPoint.textId = selectid + "-" + index;
                        } 
                        
                    textPoints.push(textPoint);
                } else {
                   if(index == 0) {
                       startPoint1 = textPoint.start;
                   }
                    if(index == 1) {
                        startPoint = textPoint.start;
                    }
    
                    if(index == region_list.length - 1) {
                        endPoint = textPoint.end;
                    }
                    if(index != 0) {
                        textPoints.push(textPoint);
                    }
                    if(checkPDf == true) { 
                        textPoint.textId = words[index];
                    }
                    else  {
                        textPoint.textId = selectid + "-" + (index - 1);
                    } 
                }
            });
           // currentindex = book.pages[page_count]['smilAudios'].length;
            if(th.props.pageType == "oneAudio") { 
                //console.log(book.pages[page_count]['smilAudios']['spans']); 
                if(book.pages[page_count]['smilAudios']['spans'] != undefined) {
                    currentindex = book.pages[page_count]['smilAudios']['spans'].length;
                }

               /* if(book.pages[page_count]['smilAudios'].spans != []) {
                    currentindex = book.pages[page_count]['smilAudios'].spans.length;
                }*/
            }
            

            if(th.props.pageType == "paragraphs") {
                //document.getElementById("story-page").style.userSelect = "none";
               
               
                //let selected = th.props.selectedIds[selectedIndex];
                let selectedIndex3 = th.props.selectedIndex;
                selectedIndex3++; 

                    document.getElementById(th.props.selectedIds[selectedIndex3 - 1].id).classList.add("red-hilight");
                    document.getElementById(th.props.selectedIds[selectedIndex3 - 1].id).classList.remove("yellow-hilight");

                if(th.props.selectedIds[selectedIndex3] != undefined) {
                    document.getElementById(th.props.selectedIds[selectedIndex3].id).classList.add("yellow-hilight");
                    document.getElementById(th.props.selectedIds[selectedIndex3].id).classList.remove("red-hilight");    
                }
                if(selectedIndex3 == th.props.selectedIds.length) {
                    document.getElementById("root").style.userSelect = "auto";
                    if(document.querySelectorAll(".red-hilight").length > 0 ) {
                        document.querySelectorAll(".red-hilight").forEach(function(item) {
                            item.classList.remove("red-hilight");
                        });
                    }
                    
                }

            
            }


    
       // text_arr.points = textPoints;
       // textPoints['tex'] = text_arr;
        //console.log(book.pages[page_count].smilAudios);
        /*current_page.forEach(function(element,index) {
            if(element.id == selectid) {
                ind1 = index;
            }
        });
        
        if(ind1 == "") {
            ind1 = current_page.length;
        }*/

        
        //book.pages[page_count].smilAudios[ind1] = {}
 

        let spanObj = {};
        let spanObj2 = {};

        spanObj.spans = [];

        spanObj.spans[currentindex] = {};
        spanObj.duration = wavesurfer.getDuration();
        spanObj.audioLink = "/audio/" + audioName;
        
        let audios = th.props.audios;

        if(audios.indexOf("audio/" + audioName) == -1) {
            audios.push("audio/" + audioName);
            th.props.dispatch({type:"update_audios",audios:audios});    
        }

        spanObj.spans[currentindex].points = textPoints;
        spanObj.spans[currentindex].id = selectid;

        spanObj2.points = textPoints;
        spanObj2.id = selectid;

        if(th.props.pageType == "oneAudio") { 
            book.pages[page_count]['smilAudios'].duration = wavesurfer.getDuration();
            book.pages[page_count]['smilAudios'].audioLink = "/audio/" + audioName;

            if(book.pages[page_count]['smilAudios'].spans == undefined) {
                book.pages[page_count]['smilAudios'].spans = [];
            }
            book.pages[page_count]['smilAudios']['spans'][currentindex] = {};
            book.pages[page_count]['smilAudios']['spans'][currentindex].points = textPoints;
            book.pages[page_count]['smilAudios']['spans'][currentindex].id = selectid;
        } else {
            /*book.pages[page_count]['smilAudios'].spans[currentindex].duration = wavesurfer.getDuration();
            book.pages[page_count]['smilAudios'].spans[currentindex].audioLink =  "/audio/" + audioName;
            book.pages[page_count]['smilAudios']['duration'] = wavesurfer.getDuration();
            book.pages[page_count]['smilAudios']['audioLink'] = "/audio/" + audioName;*/
            let founded = false;
            let foundIndx = 0;
            book.pages[page_count]['smilAudios'].forEach(function(item,ind) {
                if(item.audioLink == spanObj.audioLink) {
                    founded = true;
                    foundIndx = ind;
                }
            });

            if(founded == true) {
                book.pages[page_count]['smilAudios'][foundIndx]['spans'].push(spanObj2);
            }
            else {
                book.pages[page_count]['smilAudios'].push(spanObj);
            }

        }


 


       /* if(selectedPara == 1) {
            
        }
        else {
            
            book.pages[page_count]['smilAudios'][]
        }*/
        th.props.dispatch({type:"update_book2",book:book});
        wavesurfer.clearRegions();
        let r = Math.random() * (120 - 0) + 0;
        let g = Math.random() * (120 - 0) + 0;
        let b = Math.random() * (120 - 0) + 0;

        wavesurfer.addRegion({
            id: "c1",
            start: startPoint1, // time in seconds
            end: endPoint, // time in seconds
            color: 'rgba('+ r +','+ g +','+ b + ',0.4)',
            // Note: we are preventing the first region (green) from being moved or resized.
            drag: false,
            resize: true
        });
        this.pauseAll();
        this.props.dispatch({type:"update_point_index",pointIndex:true});
        wavesurfer.setCurrentTime(endPoint);
        

        /*if(pages[page_count] == undefined) {
            pages[page_count] = [];
        }*/

    
        
        /*let page_audios = document.createElement("div");
        page_audios.id = "page-audios";
        let audios_list =[];
        let index = 0;

        book.pages[page_count].smilAudios.forEach(function(item) {
            let page_audio_list = document.createElement("audio");
            page_audio_list.src= item.audioLink;
            page_audio_list.setAttribute('data-for',item.id);
    
            page_audios.appendChild(page_audio_list);
            audios_list[index] = {};
            audios_list[index].audioLink = item.audioLink;
            audios_list[index].id = item.id;
            index++;
        });

        let opf_dir = localStorage.getItem("opf_dir");
        let page_ids = this.props.page_ids;
        
        
         axios.get(page_ids[page_count]).then(function(res) {
            let doc = new DOMParser().parseFromString(res.data, "text/xml");
            
            doc.querySelector("body").appendChild(page_audios);
            console.log(doc);
        });
       var bodyFormData = new FormData();
        

        bodyFormData.set('book', JSON.stringify(book));
        bodyFormData.set('base_path', opf_dir);
        bodyFormData.set('page_path', page_ids[page_count]);
        bodyFormData.set('page_audios',page_audios.innerHTML);
        axios({ 
            method: 'post',
            url: 'http://35.159.51.71:4000/upload_content',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(function (res) {
            })
            .catch(function (response) {
            });*/

    }
    savePage = (type) => {
        let book = this.props.book;
        let audios = this.props.audios;

        
        

        //let opf_dir = localStorage.getItem("opf_dir");

        var url_string = window.location.href;
        var url = new URL(url_string);
        let opf_link = url.searchParams.get("opf_file");

        let opf_dir = opf_link.replace("content.opf","");


        //console.log(window.location.pathname);
        let htmlDir = window.location.pathname;
        let currDir = htmlDir.replace(opf_dir,"").split("/");
        let audio_dirs = "";
        if(currDir.length > 1) {
            audio_dirs = "";
            currDir.forEach(function(idx) {
                if(idx == 0) {
                    return;
                }
                audio_dirs += "../";
            });
        }

        let page_ids = this.props.page_ids;
        let page_count = this.props.page_count;
        
        
        let page_audios = document.createElement("div");
       // let docSelect = this.props.pages2[page_count];
    
        if(document.querySelectorAll("#page-audios").length != 0) {
            document.getElementById("page-audios").remove();
        }
        page_audios.id = "page-audios";
        let coords = this.props.pagesWidth;
        let width = coords[page_count].width;
        let height = coords[page_count].height;

        let htmlSavePAge = "<body>" + this.props.pages2[page_count] + "</body>";
        if(width != "" && height != "") {
            htmlSavePAge = "<body style='width:"+ width +";height:" + height + ";'>" + this.props.pages2[page_count] + "</body>";
        }
    
        let audios_list =[];
        let index = 0;


        audios.forEach(function(audioItem) {
            let page_audio_list = document.createElement("audio");
            //let ca = book.pages[page_count]['smilAudios']['audioLink'].replace("/audio","audio");
            page_audio_list.src= audio_dirs + audioItem;
            page_audios.appendChild(page_audio_list);
        });
        
        //    page_audio_list.setAttribute('data-for',item.id);
    
            let th = this;

            th.setState({
                open4:true
            });

      //  axios.get(page_ids[page_count]).then(function(res) {

        //    let doc = new DOMParser().parseFromString(res.data, "text/xml");



        let doc = new DOMParser().parseFromString(htmlSavePAge,"text/html");
            if(doc.querySelectorAll("#page-audios").length != 0) {
                doc.getElementById("page-audios").remove();
            }
            doc.querySelector("body").appendChild(page_audios);

            
            let smils = book.pages[page_count]['smilAudios'];
            //let smil_type = typeof book.pages[page_count]['smilAudios'];
            if(smils == undefined) {
                setTimeout(() => {
                    th.setState({
                        open4:false
                    });
                }, 5000);

    
                return;
            }
            
            if(this.props.pageType == "oneAudio") {
                if(smils.spans == undefined) {
                    setTimeout(() => {
                        th.setState({
                            open4:false
                        });
                    }, 5000);
        
                    return;
                }
                smils.spans.forEach(function(item2) {
                    doc.querySelector("#" + item2.id).classList.add('audio-syncing');
                });
            }
            else  if(this.props.pageType == "paragraphs") {
                if(smils[0] == undefined) {
                    setTimeout(() => {
                        th.setState({
                            open4:false
                        });
                    }, 5000);
        
                    return;
                }

                smils.forEach(function(item) {
                    item.spans.forEach(function(item2) {
                            doc.querySelector("#" + item2.id).classList.add('audio-syncing');
                    });
                });   
            }
            
                 
            
            if(document.getElementById("script-codes") == null) {
                
            
            let scriptdiv = document.createElement("div");
            scriptdiv.id = "script-codes";
            let script = document.createElement("script");
            script.src = audio_dirs + "custom_frontend.js";
            
            scriptdiv.appendChild(script); 
            doc.querySelector("body").appendChild(scriptdiv);

        }

           // console.log(doc);return;

            let htmlpage = doc.getElementsByTagName("body")[0].innerHTML;

            htmlpage = htmlpage.replace(/(<img("[^"]*"|[^\/">])*)>/gi, "$1/>");



 
            //let htmlpage = "<body>" + th.props.pages2[page_count] + "</body>";
            
            let pageHeaders = th.props.pageHeaders[page_count];
            let htmlfinal = '<?xml version="1.0" encoding="utf-8"?><!DOCTYPE html><html xmlns="http://www.w3.org/1999/xhtml" xmlns:epub="http://www.idpf.org/2007/ops">'+ pageHeaders + "<body>" + htmlpage + "</body></html>";

            if(width != "" && height != "") {
                htmlfinal = '<?xml version="1.0" encoding="utf-8"?><!DOCTYPE html><html xmlns="http://www.w3.org/1999/xhtml" xmlns:epub="http://www.idpf.org/2007/ops">'+ pageHeaders + "<body style='width:"+ width +";height:" + height + ";'>" + htmlpage + "</body></html>";

            }
           // console.log(htmlfinal);
          // return;

          var url_string = window.location.href;
          var url = new URL(url_string);

          let bookId = url.searchParams.get("id");

           // console.log('<?xml version="1.0" encoding="utf-8"?><!DOCTYPE html><html xmlns="http://www.w3.org/1999/xhtml" xmlns:epub="http://www.idpf.org/2007/ops">' + th.props.pages2[page_count]+ '</html>');
            var bodyFormData = new FormData();
            bodyFormData.set("id",bookId);

            bodyFormData.set('book', JSON.stringify(book));
            bodyFormData.set('base_path', opf_dir);
            bodyFormData.set('page_path', page_ids[page_count]);
            bodyFormData.set("htmlfinal",htmlfinal);
            axios({ 
                method: 'post',
                url: baseLink + '/upload_content',
                data: bodyFormData,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
                }).then(function (res) {
                        document.getElementById("story-page-inner").appendChild(page_audios);
                        //console.log(document.querySelectorAll("page-audios audio"));

                        setTimeout(() => {
                            th.setState({
                                open4:false
                            });
                        }, 5000);
                        document.getElementById("root").style.userSelect = "";
                        document.querySelectorAll(".yellow-hilight").forEach(function(item) {
                            item.classList.remove("yellow-hilight"); 
                        });   
                        document.querySelectorAll(".red-hilight").forEach(function(item) {
                            item.classList.remove("red-hilight"); 
                        });     
          
                })
                .catch(function (response) {
                    //console.log(response);
                });
    

       // });
    }
    exportZip = (e) => {
        let th = this;
        th.setState({
            open4:true
        });
        var url_string = window.location.href;
        var url = new URL(url_string);

        let opf_link = url.searchParams.get("opf_file");
        let id = url.searchParams.get("id");

        opf_link = opf_link.replace("content.opf","");

        var bodyFormData = new FormData();
        bodyFormData.set("epub_dir",opf_link);
        bodyFormData.set("id",id);

        axios({
            method: 'post',
            url: baseLink+ '/compress_epub',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(function (res) {
                th.setState({
                    open4:false
                });
        
            });
    }
    render() {
        return (<div>
            <div id="waveform" style={{direction:'ltr'}}></div>
            <div id="wave-timeline"></div> 
            <div id="player-control">
            <div className="play-bt" onClick={this.playAll}><img src="/images/play-icon.png"/></div>
            <div className="pause-bt" onClick={this.pauseAll}><img src="/images/pause-button.png"/></div>
            <div className="upload-bt"><img src="/images/upload-symbol.png" /> <input type="file" onChange={this.uploadAudio} id="file-upload" /></div>
            <input data-action="zoom" id="slider" type="range" min="20" max="1000" />

            <select onChange={this.speedHandle}>
                        <option value="1">1</option>
                        <option value="0.9">0.9</option>
                        <option value="0.8">0.8</option>
                        <option value="0.7">0.7</option>
                        <option value="0.6">0.6</option>
                        <option value="0.5">0.5</option>
                        <option value="0.4">0.4</option>
                        <option value="0.3">0.3</option>
                        <option value="0.2">0.2</option>
                        <option value="0.1">0.1</option>
                    </select>
            </div>
            <div className="save-bt"><button className="main-bt" onClick={this.saveText}>Next Line</button>
            <button className="main-bt" onClick={this.savePage}>Finish book</button>
            
                <button className="main-bt" onClick={this.skipText}>skip line</button>
            </div>


            <Dialog
        open={this.state.open4}>
        <DialogContent>
        <CircularProgress />
        </DialogContent>
        </Dialog>

        <Dialog
        open={this.state.open5}>
        <DialogContent><p>Text save Successfully</p></DialogContent>
        </Dialog>

        <Dialog
        open={this.state.open6}>
        <DialogContent><p>Page save Successfully</p></DialogContent>
        </Dialog>

            </div>) 
    }
}
const mapStateToProps = state => ({
        audioLink: state.audioLink,
        audioObj:state.audioObj,
        audioName:state.audioName,
        startTime:state.startTime,
        selectid:state.selectid,
        book:state.book,
        page_count:state.page_count,
        page_ids:state.page_ids,
        pageType:state.pageType,
        spansList:state.spansList,
        cssLinks:state.cssLinks,
        pointIndex:state.pointIndex,
        selectedIds:state.selectedIds,
        selectedIndex:state.selectedIndex,
        pages2:state.pages2,
        pageHeaders:state.pageHeaders,
        pagesWidth:state.pagesWidth,
        audios:state.audios,
        selectedPara:state.selectedPara,
        opf_dir:state.opf_dir,
        clearSlicing:state.clearSlicing,
        clearSlicing1:state.clearSlicing1,
        htmlpage:state.htmlpage,
        waverColor:state.waverColor,
        spanIndx:state.spanIndx,
        editLineText:state.editLineText
});
export default connect(mapStateToProps) (PageAudio); 