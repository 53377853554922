import Add from "./Add";
import Edit from "./Edit";
import List from "./List";

export const notificationConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${process.env.REACT_APP_FRONT_URL}notification/push`,
      component: Add,
    },
    // {
    //   path: `${process.env.REACT_APP_FRONT_URL}tags/:id/edit`,
    //   component: Edit,
    // },
    {
      path: `${process.env.REACT_APP_FRONT_URL}notification/list`,
      component: List,
    },
  ],
};
