import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import Alert from '@material-ui/lab/Alert';

import TextField from "@material-ui/core/TextField";
import Formsy from "formsy-react";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";
import SaveIcon from "@material-ui/icons/Save";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import EmailCircle from '@material-ui/icons/Email';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from '@material-ui/core/Switch';

const styles = (theme) => ({
    layoutRoot: {},
});

const breadCurmbData = [
    {
        title: "Individual users",
        link: `${process.env.REACT_APP_FRONT_URL}users/list`,
    },
    {
        title: "Add",
        link: "",
    },
];

class Add extends Component {
    state = {
        formValid: false,
        Name: '',
        email: '',
        startEmail: '',
        Domain: '',
        password: '',
        Number: '',
        Role: 'plan_1',
        RoleData: [],
        DurationData: ['monthly', 'annualy'],
        Duration: 'monthly',
        showPopu: false,
        msgError: '',
        showError: false,
        OneUser: false,


    };
    handleChange = (e, name) => {
        if (name == 'OneUser')
            this.setState({
                OneUser: e.target.checked,
            });
        else
            this.setState({
                [name]: e.target.value,
            });
    };

    handleSubmit = (model) => {

        const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

        if (emailRegexp.test(this.state.email)) {
            this.setState({
                msgError: '',
                showError: true,
                loading: true
            })
            let backend_url = process.env.REACT_APP_BACKEND_URL;
            let formData = new FormData();

            formData.append("OneUser", this.state.OneUser);
            formData.append("Duration", this.state.Duration);
            formData.append("plan", this.state.Role);
            formData.append("name", this.state.Name);
            formData.append("email", this.state.email);
            formData.append("password", this.state.password);

            axios({
                url: `${backend_url}/userApp/add`,
                data: formData,
                method: "post",
                headers: { "Content-Type": "multipart/form-data" },
            }).then((res) => {
                console.log(res.data)
                if (res.data.message)
                    this.setState({
                        showPopu: true,
                        loading: false
                    })
                else
                    this.setState({
                        msgError: 'This Email is already in use!',
                        showError: true,
                        loading: false
                    })
            });
        }
        else {
            this.setState({
                msgError: 'Email is incorrectly!',
                showError: true
            })
        }




    };



    handleSubmit2 = (model) => {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();

        formData.append("OneUser", this.state.OneUser);
        formData.append("Duration", this.state.Duration);
        formData.append("plan", this.state.Role);
        formData.append("Domain", this.state.Domain);
        formData.append("Number", this.state.Number);
        formData.append("startEmail", this.state.startEmail);
        formData.append("password", this.state.password);

        axios({
            url: `${backend_url}/userApp/add`,
            data: formData,
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {


        });

        this.setState({ loading: true })
        setTimeout(() => {
            this.setState({ loading: false })
            this.props.history.push("/frontend/users/list");
        }, 3000);

    };



    enableButton = () => {
        this.setState({
            formValid: true,
        });
    };

    disableButton = () => {
        this.setState({
            formValid: false,
        });
    };


    componentDidMount() {
        let backend_url = process.env.REACT_APP_BACKEND_URL;

        axios({
            url: `${backend_url}/bundles/list`,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            this.setState({
                RoleData: res.data.result,
            });
        });
    }

    ////
    render() {
        const { classes } = this.props;
        return (
            <FusePageCarded
                classes={{
                    root: classes.layoutRoot,
                }}
                header={
                    <div className="p-24">
                        <Breadcrumb data={breadCurmbData} />
                    </div>
                }
                contentToolbar={
                    <div className="px-24">
                        <h2>Add Individual user</h2>
                    </div>
                }
                content={
                    <div className="p-24">
                        <div className="max-w-lg">

                            {this.state.msgError && (
                                <Alert variant="filled" severity="error">
                                    {this.state.msgError}
                                </Alert>
                            )}


                            <FormControlLabel
                                control={<Switch checked={this.state.OneUser}

                                    color="primary"
                                    onChange={(e) => {
                                        this.handleChange(e, "OneUser");
                                    }}
                                    name="freev" />}
                                label={!this.state.OneUser ? "One User" : "Multiple users"}
                                labelPlacement="top"

                            />
                            {this.state.OneUser ?
                                <div>
                                    <Formsy
                                        onValidSubmit={this.handleSubmit2}
                                        onValid={this.enableButton}
                                        onInvalid={this.disableButton}
                                        ref={"cateogry_add"}
                                        className="flex flex-col p6-12"
                                    >
                                        <div className="flex flex-row justify-between py-20">
                                            <TextFieldFormsy
                                                className="w-3/12"
                                                type="text"
                                                name="startEmail"
                                                label="Start email"
                                                onChange={(e) => {
                                                    this.handleChange(e, "startEmail");
                                                }}
                                                variant="outlined"
                                                required
                                            />
                                            <TextFieldFormsy
                                                className="w-3/12"
                                                type="text"
                                                name="Domain"
                                                label="Domain"
                                                onChange={(e) => {
                                                    this.handleChange(e, "Domain");
                                                }}
                                                variant="outlined"
                                                required
                                            />
                                            <TextFieldFormsy
                                                className="w-5/12"
                                                type="password"
                                                name="password"
                                                label="Password"
                                                onChange={(e) => {
                                                    this.handleChange(e, "password");
                                                }}
                                                variant="outlined"
                                                required
                                            />

                                        </div>

                                        <div className="flex flex-row justify-between py-20">
                                            <TextFieldFormsy
                                                className="w-2/12"
                                                type="number"
                                                name="Number"
                                                label="Number"
                                                required
                                                onChange={(e) => {
                                                    this.handleChange(e, "Number");
                                                }}
                                                variant="outlined"
                                            />
                                            <SelectFormsy
                                                className="w-5/12"
                                                name="Role"
                                                label="Plan"
                                                value={this.state.Role}
                                                onChange={(e) => {
                                                    this.handleChange(e, "Role");
                                                }}
                                                variant="outlined"
                                                required
                                            >

                                                {this.state.RoleData.map((item) => {
                                                    return <MenuItem value={item._id}>{item.titleEn}</MenuItem>;
                                                })}
                                            </SelectFormsy>
                                            {this.state.Role == 'plan_2' || this.state.Role == 'plan_3' ?
                                                <SelectFormsy
                                                    className="w-5/12"
                                                    name="Duration"
                                                    label="Duration"
                                                    value={this.state.Duration}
                                                    onChange={(e) => {
                                                        this.handleChange(e, "Duration");
                                                    }}
                                                    variant="outlined"
                                                    required
                                                >

                                                    {this.state.DurationData.map((item) => {
                                                        return <MenuItem value={item}>{item}</MenuItem>;
                                                    })}
                                                </SelectFormsy>
                                                : ''
                                            }
                                        </div>



                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className="ml-auto my-16"
                                            aria-label="Add"
                                            disabled={!this.state.formValid}
                                        >
                                            Add
                                        </Button>
                                    </Formsy>
                                </div>
                                :
                                <div>

                                    <Formsy
                                        onValidSubmit={this.handleSubmit}
                                        onValid={this.enableButton}
                                        onInvalid={this.disableButton}
                                        ref={"cateogry_add"}
                                        className="flex flex-col p6-12"
                                    >
                                        <div className="flex flex-row justify-between py-20">
                                            <TextFieldFormsy
                                                className="w-5/12"
                                                type="text"
                                                name="Email"
                                                label="E-mail"
                                                onChange={(e) => {
                                                    this.handleChange(e, "email");
                                                }}
                                                variant="outlined"
                                                required
                                            />
                                            <TextFieldFormsy
                                                className="w-5/12"
                                                type="password"
                                                name="password"
                                                label="Password"
                                                onChange={(e) => {
                                                    this.handleChange(e, "password");
                                                }}
                                                variant="outlined"
                                                required
                                            />

                                        </div>
                                        <div className="flex flex-row justify-between py-20">
                                            <TextFieldFormsy
                                                className="w-5/12"
                                                type="text"
                                                name="name"
                                                label="Name"
                                                required
                                                onChange={(e) => {
                                                    this.handleChange(e, "Name");
                                                }}
                                                variant="outlined"
                                            />
                                        </div>
                                        <div className="flex flex-row justify-between py-20">

                                            <SelectFormsy
                                                className="w-3/12"
                                                name="Role"
                                                label="Plan"
                                                value={this.state.Role}
                                                onChange={(e) => {
                                                    this.handleChange(e, "Role");
                                                }}
                                                variant="outlined"
                                                required
                                            >

                                                {this.state.RoleData.map((item) => {
                                                    return <MenuItem value={item._id}>{item.titleEn}</MenuItem>;
                                                })}
                                            </SelectFormsy>
                                            {this.state.Role == 'plan_2' || this.state.Role == 'plan_3' ?
                                                <SelectFormsy
                                                    className="w-5/12"
                                                    name="Duration"
                                                    label="Duration"
                                                    value={this.state.Duration}
                                                    onChange={(e) => {
                                                        this.handleChange(e, "Duration");
                                                    }}
                                                    variant="outlined"
                                                    required
                                                >

                                                    {this.state.DurationData.map((item) => {
                                                        return <MenuItem value={item}>{item}</MenuItem>;
                                                    })}
                                                </SelectFormsy>
                                                : ''
                                            }
                                        </div>



                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className="ml-auto my-16"
                                            aria-label="Add"
                                            disabled={!this.state.formValid}
                                        >
                                            Add
                                        </Button>
                                    </Formsy>
                                </div>
                            }
                            <div>

                            </div>




                            <Dialog
                                open={this.state.showPopu}
                                // onClose={}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"User created successfully!"}</DialogTitle>
                                <DialogContent  >
                                    <DialogContentText id="alert-dialog-description">
                                        Password has been sent to <b>{this.state.email}</b>

                                    </DialogContentText>
                                    <Button variant="outlined" size="large" style={{
                                        margin: '1vw auto',
                                        display: 'block'

                                    }} color="primary" className={classes.margin}
                                        onClick={() => {
                                            this.setState({
                                                showPopu: false,
                                            });

                                            this.props.history.push("/frontend/users/list");
                                        }}

                                    >
                                        close
                                    </Button>
                                </DialogContent>

                            </Dialog>

                            <Backdrop
                                className={classes.backdrop}
                                open={this.state.loading}
                                style={{
                                    zIndex: 999999,
                                    color: "#fff",
                                }}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>


                        </div>
                    </div>
                }
            />
        );
    }
}

export default withStyles(styles, { withTheme: true })(Add);
