import view from "./view";

export const aboutConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${process.env.REACT_APP_FRONT_URL}about/view`,
      component: view,
    },
  ],
};
