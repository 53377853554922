import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";

import TextField from "@material-ui/core/TextField";
import Formsy from "formsy-react";
import Breadcrumb from "./../components/Breadcrumb";
import axios from "axios";

import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from "@material-ui/icons/Save";

import { RadioGroupFormsy } from "@fuse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from '@material-ui/core/Switch';


import clsx from 'clsx';
import Alert from '@material-ui/lab/Alert';


import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import AppBar from '@material-ui/core/AppBar';


import Avatar from '@material-ui/core/Avatar';

import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import Popup from "./../components/popup";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/AddBox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import Icon from '@material-ui/core/Icon';
const styles = (theme) => ({
    layoutRoot: {},
});

const breadCurmbData = [
    {
        title: "Shows",
        link: `${process.env.REACT_APP_FRONT_URL}shows/list`,
    },
    {
        title: "Season",
        link: "",
    },

];

class Add extends Component {
    state = {
        formValid: false,
        titleEn: "",
        titleAr: "",
        disabled: false,
        coverImg: "",
        rowsPerPage: 10,
        page: 0,
        seasonsData: [],
        showData: [],
        titleEn_season: "",
        titleAr_season: "",
        descriptionEn_season: "",
        descriptionAr_season: "",
        addSeasionDev: false,
        episodesData: [],
        idSeason: '',
        ErrorMsg1: '',
        showErrorMsg1: false,
        EditSeasionDev: false,
        titleEn_edit: "",
        titleAr_edit: "",
        descriptionEn_edit: "",
        descriptionAr_edit: "",
        showIdEdit: '',
        coverImg_edit: "",
        id_edit: "",
        showName: ""

    };
    handleChange = (e, name) => {
        this.setState({
            [name]: e.target.value,
        });
    };

    backtoseason = () => {
        this.setState({
            stepactive: 1
        });
    };

    getLength = (ar) => {
        let count = 0;
        ar.map((item) => {
            if (item.deleted == false || item.deleted == undefined || item.deleted == null) {
                count = count + 1;
            }
        });
        return count;
    };


    changeStatus = (v, id) => {
        let st = v;
        if (st == "Published") {
            st = "Not Published";
        } else {
            st = "Published";
        }
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();
        formData.append("id", id);
        formData.append("Status", st);
        axios({
            url: `${backend_url}/shows/changestatusSeason`,
            data: formData,
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            this.componentDidMount();
        });
    };


    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };
    handleChangeRowsPerPage = () => { };
    sortData = (name) => {
        let data = this.state.categoryData;

        data = data.sort(function (a, b) {
            if (a[name] < b[name]) {
                return -1;
            }
            if (a[name] > b[name]) {
                return 1;
            }
            return 0;
        });

        this.setState({
            categoryData: data,
        });
    };

    handleChangetags = (val, name) => {
        let ids = val.map((item) => {
            return item._id
        })
        this.setState({
            [name]: ids,
        });

    };




    addSeason = () => {
        this.setState({
            addSeasionDev: !this.state.addSeasionDev
        });
    };


    handleSubmit = (model) => {
        if (this.state.coverImg == "") {
            this.setState({
                ErrorMsg1: 'You did not choose an image!',
                showErrorMsg1: true
            })
        }
        else {
            this.setState({
                ErrorMsg1: '',
                showErrorMsg1: false
            });
        }


        if (!this.state.showErrorMsg1) {

            let backend_url = process.env.REACT_APP_BACKEND_URL;
            let formData = new FormData();

            formData.append("showId", this.props.match.params.id);
            formData.append("titleEn", this.state.titleEn_season);
            formData.append("titleAr", this.state.titleAr_season);
            formData.append("descriptionEn", this.state.descriptionEn_season);
            formData.append("descriptionAr", this.state.descriptionAr_season);
            formData.append("coverImg", this.state.coverImg);
            formData.append("isFree", false);
            formData.append("isPaid", true);


            axios({
                url: `${backend_url}/shows/addseason`,
                data: formData,
                method: "post",
                headers: { "Content-Type": "multipart/form-data" },
            }).then((res) => {

                this.componentDidMount();
                this.setState({
                    titleEn_season: '',
                    titleAr_season: '',
                    descriptionEn_season: '',
                    descriptionAr_season: '',
                    coverImg2: '',
                    addSeasionDev: false
                })

            });

        }


    };

    handleDelete = () => {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();
        formData.append("id", this.state.idremove);
        formData.append("token", localStorage.getItem('jwt_access_token'));
        axios({
            url: `${backend_url}/shows/deleteseason`,
            data: formData,
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            this.componentDidMount();
            this.setState({
                open: false,
            });
        });
    };



    handleSubmitEdit = () => {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();
        formData.append("id", this.state.id_edit);
        formData.append("titleEn", this.state.titleEn_edit);
        formData.append("titleAr", this.state.titleAr_edit);
        formData.append("descriptionEn", this.state.descriptionEn_edit);
        formData.append("descriptionAr", this.state.descriptionAr_edit);
        formData.append("coverImg", this.state.coverImg_edit);
        formData.append("showId", this.state.showIdEdit);

        axios({
            url: `${backend_url}/shows/editseason`,
            data: formData,
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            this.setState({
                EditSeasionDev: false,
                titleEn_edit: '',
                titleAr_edit: '',
                descriptionEn_edit: '',
                descriptionAr_edit: '',
                coverImg_edit: '',
                id_edit: '',
            });
            this.componentDidMount()
        });



    }

    getEditSeason = (id) => {
        let backend_url = process.env.REACT_APP_BACKEND_URL;

        let data = {
            id: id,
        };

        axios({
            url: `${backend_url}/shows/getseasonone`,
            data: data,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            this.setState({
                EditSeasionDev: true,
                titleEn_edit: res.data.result.titleEn,
                titleAr_edit: res.data.result.titleAr,
                descriptionEn_edit: res.data.result.descriptionEn,
                descriptionAr_edit: res.data.result.descriptionAr,
                coverImg_edit: res.data.result.coverImg,
                showIdEdit: res.data.result.showId,
                id_edit: id,
            })
            let image = document.getElementById('cover-image-edit');
            image.src = process.env.REACT_APP_BACKEND_URL + '/public/' + this.state.coverImg_edit;

        });

    }
    enableButton = () => {
        this.setState({
            formValid: true,
        });
    };

    disableButton = () => {
        this.setState({
            formValid: false,
        });
    };

    fileUpload = (e, id) => {
        let image = document.getElementById(id);
        image.src = URL.createObjectURL(e.target.files[0]);
        if (id == 'cover-image-edit') {
            this.setState({
                coverImg_edit: e.target.files[0],
            });
        }
        else {
            this.setState({
                coverImg: e.target.files[0],
            });
        }

    };


    componentDidMount() {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let id = this.props.match.params.id;

        let data = {
            id: id,
        };

        axios({
            url: `${backend_url}/shows/getseason`,
            data: data,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            this.setState({
                seasonsData: res.data.result
            })

        });
        //showData
        let formData = new FormData();
        formData.append("keyword", '');
        formData.append("indexing", 0);

        axios({
            url: `${backend_url}/shows/list`,
            method: "post",
            data:formData,
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            this.setState({
                showData: res.data.result,
            });
        });
    }



    handleChangeisFreePaid = (e, id, type) => {
        ///shows/changeisFreePaid 
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();

        formData.append("id", id);
        formData.append("setValue", e.target.value);
        formData.append("type", type);
        formData.append("collectonName", 'season');
        axios({
            url: `${backend_url}/shows/changeisFreePaid`,
            data: formData,
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
        });


    }


    upwardMethod = (id, order, backID, backOrder) => {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();
        formData.append("id", id);
        if (order == undefined) {
            formData.append("order", '1');
        }
        else {
            formData.append("order", order);
        }
        formData.append("backID", backID);
        formData.append("backOrder", backOrder);
        axios({
            url: `${backend_url}/shows/season/upward`,
            data: formData,
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            this.componentDidMount();
        });
    }



    downwardMethod = (id, order, nextID, nextOrder) => {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();
        formData.append("id", id);
        if (order == undefined) {
            formData.append("order", '1');
        } else {
            formData.append("order", order);
        } formData.append("nextID", nextID);
        formData.append("nextOrder", nextOrder);
        axios({
            url: `${backend_url}/shows/season/downward`,
            data: formData,
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            this.componentDidMount();
        });
    }

    pluscount = (index) => {
        let newindex = index + 1;
        return newindex;
    }
    minuscount = (index) => {
        let bacindex = index - 1;
        return bacindex;
    }


    render() {
        const { classes } = this.props;
        return (
            <FusePageCarded
                classes={{
                    root: classes.layoutRoot,
                }}
                header={
                    <div className="p-24">
                        <Breadcrumb data={breadCurmbData} />

                    </div>
                }
                contentToolbar={
                    <div className="px-24">
                        <h2>{this.state.seasonsData.map((item, index) => (
                            (index == 0) && (
                                item.showName
                            )

                        ))}</h2>

                        <div
                        >

                        </div>
                    </div>
                }
                content={
                    <div className="p-24 max-w-lg">

                        <div style={{ width: '70vw' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                className="ml-auto my-16"
                                variant="contained"
                                onClick={() => {
                                    this.addSeason()
                                }}
                            >
                                Add season
                            </Button>
                            {this.state.addSeasionDev && (
                                <div style={{
                                    backgroundColor: '#f3f3f3',
                                    padding: '2vw',
                                    marginBottom: '2vw',
                                    borderRadius: '5px',
                                    boxShadow: '-1px 1px 3px #d0d0d0'
                                }} >
                                    <Formsy
                                        onValidSubmit={this.handleSubmit}
                                        onValid={this.enableButton}
                                        onInvalid={this.disableButton}
                                        ref={"song_add"}
                                        className="flex flex-col p6-12"
                                    >
                                        <div className="flex flex-row justify-between py-20">
                                            <TextFieldFormsy
                                                className="w-5/12"
                                                label="Title En"
                                                autoFocus
                                                id="titleEn"
                                                name="titleEn"
                                                variant="outlined"
                                                value={this.state.titleEn_season}
                                                onChange={(e) => {
                                                    this.handleChange(e, "titleEn_season");
                                                }}
                                                required
                                            />
                                            <TextFieldFormsy
                                                className="w-5/12"
                                                label="Title Ar"
                                                id="titleAr"
                                                name="titleAr"
                                                variant="outlined"
                                                value={this.state.titleAr_season}
                                                onChange={(e) => {
                                                    this.handleChange(e, "titleAr_season");
                                                }}
                                                required
                                            />
                                        </div>




                                        <div className="flex flex-row justify-between py-10">
                                            <TextFieldFormsy
                                                id="outlined-multiline-static"
                                                label="Description En"
                                                multiline
                                                rows={4}
                                                value={this.state.descriptionEn_season}
                                                variant="outlined"
                                                className="w-5/12"
                                                id="descriptionEn_season"
                                                name="descriptionEn_season"
                                                onChange={(e) => {
                                                    this.handleChange(e, "descriptionEn_season");
                                                }}
                                                required
                                            />
                                            <TextFieldFormsy
                                                id="outlined-multiline-static"
                                                label="Description Ar"
                                                multiline
                                                rows={4}
                                                value={this.state.descriptionAr_season}
                                                variant="outlined"
                                                className="w-5/12"
                                                id="descriptionAr_season"
                                                name="descriptionAr_season"
                                                onChange={(e) => {
                                                    this.handleChange(e, "descriptionAr_season");
                                                }}
                                                required
                                            />
                                        </div>
                                        <div className="flex justify-between items-center w-8/12">
                                            <div className="flex justify-between items-center w-8/12">
                                                <Button variant="contained" component="label">
                                                    Choose image
                                                    <input
                                                        type="file"
                                                        onChange={(e) => {
                                                            this.fileUpload(e, "cover-image");
                                                        }}
                                                        style={{ display: "none" }}
                                                    />
                                                </Button>

                                            </div>
                                            <div
                                                role="button"
                                                className={clsx(
                                                    classes.productImageItem,
                                                    'flex items-center justify-center relative w-128 h-128 rounded-4 mx-8 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5',

                                                )}
                                            >
                                                <img id="cover-image" className="max-w-none w-auto h-full" src="frontend/assets/images/custom/empty-image-thumb.png" />
                                            </div>


                                        </div>
                                        {this.state.showErrorMsg1 && (
                                            <Alert severity="error">{this.state.ErrorMsg1}</Alert>
                                        )}
                                        <hr style={{
                                            border: 0,
                                            backgroundColor: '#fff',
                                            borderTop: '2px dotted #8c8c8c'
                                        }}></hr>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className={classes.button}
                                            startIcon={<SaveIcon />}
                                            className="ml-auto my-16"
                                            disabled={!this.state.formValid}
                                            type="submit"
                                            variant="contained"
                                        >
                                            Add
                                        </Button>
                                    </Formsy>
                                </div>
                            )}





                            {this.state.EditSeasionDev && (
                                <div style={{
                                    backgroundColor: '#f3f3f3',
                                    padding: '2vw',
                                    marginBottom: '2vw',
                                    borderRadius: '5px',
                                    boxShadow: '-1px 1px 3px #d0d0d0'
                                }}
                                    className="p-24 max-w-lg">
                                    <Formsy
                                        onValidSubmit={this.handleSubmitEdit}
                                        onValid={this.enableButton}
                                        onInvalid={this.disableButton}
                                        ref={"song_add"}
                                        className="flex flex-col p6-12"
                                    >
                                        <div className="flex flex-row justify-between py-20">
                                            <TextFieldFormsy
                                                className="w-5/12"
                                                label="Title En"
                                                autoFocus
                                                id="titleEn"
                                                name="titleEn"
                                                variant="outlined"
                                                value={this.state.titleEn_edit}
                                                onChange={(e) => {
                                                    this.handleChange(e, "titleEn_edit");
                                                }}
                                                required
                                            />
                                            <TextFieldFormsy
                                                className="w-5/12"
                                                label="Title Ar"
                                                id="titleAr"
                                                name="titleAr"
                                                variant="outlined"
                                                value={this.state.titleAr_edit}
                                                onChange={(e) => {
                                                    this.handleChange(e, "titleAr_edit");
                                                }}
                                                required
                                            />
                                        </div>




                                        <div className="flex flex-row justify-between py-10">
                                            <TextFieldFormsy
                                                id="outlined-multiline-static"
                                                label="Description En"
                                                multiline
                                                rows={4}
                                                value={this.state.descriptionEn_edit}
                                                variant="outlined"
                                                className="w-5/12"
                                                id="descriptionEn_edit"
                                                name="descriptionEn_edit"
                                                onChange={(e) => {
                                                    this.handleChange(e, "descriptionEn_edit");
                                                }}
                                                required
                                            />
                                            <TextFieldFormsy
                                                id="outlined-multiline-static"
                                                label="Description Ar"
                                                multiline
                                                rows={4}
                                                value={this.state.descriptionAr_edit}
                                                variant="outlined"
                                                className="w-5/12"
                                                id="descriptionAr_edit"
                                                name="descriptionAr_edit"
                                                onChange={(e) => {
                                                    this.handleChange(e, "descriptionAr_edit");
                                                }}
                                                required
                                            />
                                        </div>

                                        <div className="flex flex-row justify-between py-10">
                                            <SelectFormsy
                                                className="w-5/12"
                                                name="show"
                                                label="Show Name"
                                                value={this.state.showIdEdit}
                                                onChange={(e) => {
                                                    this.handleChange(e, "showIdEdit");
                                                }}
                                                variant="outlined"
                                            >
                                                {/* <MenuItem value={this.state.showIdEdit}>000</MenuItem> */}
                                                {this.state.showData.map((item) => {
                                                    return <MenuItem value={item._id}>{item.titleEn}</MenuItem>;
                                                })}
                                            </SelectFormsy>

                                        </div>
                                        <div className="flex flex-row justify-between py-20"
                                            style={{
                                                backgroundColor: "#f8f8f8",
                                                padding: '2vw',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-around',
                                                borderRadius: '5px',
                                                boxShadow: '0 0 5px #e7e7e7'

                                            }}
                                        >
                                            <div className="flex justify-between items-center w-8/12">
                                                <div className="flex justify-between items-center w-8/12">
                                                    <Button variant="contained" component="label">
                                                        Choose image
                                                        <input
                                                            type="file"
                                                            onChange={(e) => {
                                                                this.fileUpload(e, "cover-image-edit");
                                                            }}
                                                            style={{ display: "none" }}
                                                        />
                                                    </Button>

                                                </div>
                                                <div
                                                    role="button"
                                                    className={clsx(
                                                        classes.productImageItem,
                                                        'flex items-center justify-center relative w-128 h-128 rounded-4 mx-8 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5',

                                                    )}
                                                >
                                                    <img id="cover-image-edit" className="max-w-none w-auto h-full" src="frontend/assets/images/custom/empty-image-thumb.png" />
                                                </div>


                                            </div>


                                        </div>
                                        {this.state.showErrorMsg1 && (
                                            <Alert severity="error">{this.state.ErrorMsg1}</Alert>
                                        )}
                                        <hr style={{
                                            border: 0,
                                            backgroundColor: '#fff',
                                            borderTop: '2px dotted #8c8c8c'
                                        }}></hr>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className={classes.button}
                                            startIcon={<SaveIcon />}
                                            className="ml-auto my-16"
                                            disabled={!this.state.formValid}
                                            type="submit"
                                            variant="contained"
                                        >
                                            Edit
                                        </Button>
                                    </Formsy>
                                </div>
                            )}






                            {!this.state.addSeasionDev && (
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell onClick={() => this.sortData("titleEn")}>
                                                    Title EN
                                                </TableCell>
                                                <TableCell align="center">Title AR</TableCell>
                                                <TableCell align="center">Episodes</TableCell>
                                                <TableCell align="center">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.seasonsData
                                                .slice(
                                                    this.state.page * this.state.rowsPerPage,
                                                    this.state.page * this.state.rowsPerPage +
                                                    this.state.rowsPerPage
                                                )
                                                .map((row, index, ahmad) => (
                                                    <TableRow key={row._id}>
                                                        <TableCell align="left">{(index + 1)} &nbsp;

                                                            {(index + 1) > 1 && (
                                                                <ArrowUpward style={{
                                                                    cursor: 'pointer'
                                                                }} fontSize="small"
                                                                    onClick={() => {
                                                                        let backValId = index == 0 ? 0 : ahmad[this.minuscount(index)]._id
                                                                        let backValOrder = index == 0 ? 0 : ahmad[this.minuscount(index)].created
                                                                        this.upwardMethod(row._id, row.created, backValId, backValOrder)
                                                                    }}
                                                                />
                                                            )}

                                                            {(index + 1) != Object.keys(this.state.seasonsData).length && (
                                                                <ArrowDownward style={{
                                                                    cursor: 'pointer'
                                                                }} fontSize="small"
                                                                    onClick={() => {
                                                                        let nextValId = (index + 1) == Object.keys(this.state.seasonsData).length ? 0 : ahmad[this.pluscount(index)]._id
                                                                        let nextValOrder = (index + 1) == Object.keys(this.state.seasonsData).length ? 0 : ahmad[this.pluscount(index)].created
                                                                        this.downwardMethod(row._id, row.created, nextValId, nextValOrder)
                                                                    }}
                                                                />
                                                            )}

                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Avatar alt={row.titleEn} src={process.env.REACT_APP_BACKEND_URL + '/public/' + row.coverImg} />

                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.titleEn}
                                                        </TableCell>
                                                        <TableCell align="center">{row.titleAr}</TableCell>
                                                        <TableCell align="center">
                                                            <Link
                                                                to={`${process.env.REACT_APP_FRONT_URL}shows/${row._id}/episodes/${this.props.match.params.id}`}
                                                            >
                                                                <Button variant="outlined" size="small" color="primary" className={classes.margin}>
                                                                    Episodes:
                                                                    <b className="px-8">{this.getLength(row.episode_id)}</b>
                                                                </Button>
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                        >

                                                            <IconButton
                                                                aria-label="VisibilityOff"
                                                                className={classes.margin}
                                                                onClick={() => {
                                                                    this.changeStatus(row.Status, row._id);
                                                                }}
                                                            >
                                                                {row.Status == "Published" ? (
                                                                    <Visibility fontSize="medium" />
                                                                ) : (
                                                                    <VisibilityOff fontSize="medium" />
                                                                )}
                                                            </IconButton>

                                                            <IconButton
                                                                aria-label="edit"
                                                                className={classes.margin}
                                                                onClick={() => {
                                                                    this.getEditSeason(row._id)
                                                                }}
                                                            >
                                                                <EditIcon fontSize="large" />
                                                            </IconButton>


                                                            <IconButton
                                                                aria-label="delete"
                                                                className={classes.margin}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        open: true,
                                                                        idremove: row._id
                                                                    });
                                                                }}
                                                            >
                                                                <DeleteIcon fontSize="large" />
                                                            </IconButton>
                                                            <FormControlLabel
                                                                control={<Switch checked={row.isPaid}
                                                                    value={row.isPaid}
                                                                    color="primary"
                                                                    onChange={(e) => {
                                                                        this.handleChangeisFreePaid(e, row._id, 2);

                                                                        // 1. Make a shallow copy of the items
                                                                        let items = [...this.state.seasonsData];
                                                                        // 2. Make a shallow copy of the item you want to mutate
                                                                        let item = { ...items[index] };
                                                                        // 3. Replace the property you're intested in
                                                                        item.isPaid = !item.isPaid;
                                                                        // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                                                                        items[index] = item;
                                                                        // 5. Set the state to our new copy
                                                                        this.setState({ seasonsData: items });

                                                                    }}
                                                                    name="isPaid" />}
                                                                label="Exclusive"
                                                                labelPlacement="left"

                                                            />

                                                            <FormControlLabel
                                                                control={<Switch checked={row.isFree}
                                                                    value={row.isFree}

                                                                    color="primary"
                                                                    onChange={(e) => {
                                                                        this.handleChangeisFreePaid(e, row._id, 1);
                                                                         // 1. Make a shallow copy of the items
                                                                         let items = [...this.state.seasonsData];
                                                                         // 2. Make a shallow copy of the item you want to mutate
                                                                         let item = { ...items[index] };
                                                                         // 3. Replace the property you're intested in
                                                                         item.isFree = !item.isFree;
                                                                         // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                                                                         items[index] = item;
                                                                         // 5. Set the state to our new copy
                                                                         this.setState({ seasonsData: items });
                                                                    }}
                                                                    name="IsFree" />}
                                                                label="Is Free"
                                                                labelPlacement="left"

                                                            />
                                                            <Dialog
                                                                open={this.state.open}
                                                                keepMounted
                                                                aria-labelledby="alert-dialog-slide-title"
                                                                aria-describedby="alert-dialog-slide-description"
                                                            >
                                                                <DialogTitle id="alert-dialog-slide-title">
                                                                    <DeleteIcon fontSize="large" />{" "}
                                                                    {"Are you sure to delete it will delete all episodes ?"}
                                                                </DialogTitle>
                                                                <DialogActions>
                                                                    <Button
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                open: false,
                                                                            });
                                                                        }}
                                                                        color="primary"
                                                                    >
                                                                        No
                                                                    </Button>
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            this.handleDelete()
                                                                        }}
                                                                    >
                                                                        OK
                                                                    </Button>
                                                                </DialogActions>
                                                            </Dialog>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[10]}
                                        component="div"
                                        count={this.state.seasonsData.length}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        backIconButtonProps={{
                                            "aria-label": "previous page",
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "next page",
                                        }}
                                        onChangePage={this.handleChangePage}
                                    />
                                </TableContainer>

                            )}




                            <Popup />
                        </div>

                    </div>
                }


            />
        );
    }
}

export default withStyles(styles, { withTheme: true })(Add);
