import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import Alert from '@material-ui/lab/Alert';

import TextField from "@material-ui/core/TextField";
import Formsy from "formsy-react";
import Breadcrumb from "./../components/Breadcrumb";
import axios from "axios";
import SaveIcon from "@material-ui/icons/Save";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import EmailCircle from '@material-ui/icons/Email';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
const styles = (theme) => ({
    layoutRoot: {},
});

const breadCurmbData = [
    {
        title: "System admins",
        link: `${process.env.REACT_APP_FRONT_URL}users/list`,
    },
    {
        title: "Add",
        link: "",
    },
];

class Add extends Component {
    state = {
        formValid: false,
        displayName: '',
        email: '',
        Role: 'Editor',
        RoleData: ['Super admin', 'Editor'],
        showPopu: false,
        msgError: '',
        showError: false,
        loading: false

    };
    handleChange = (e, name) => {
        this.setState({
            [name]: e.target.value,
        });
    };

    handleSubmit = (model) => {

        const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

        if (emailRegexp.test(this.state.email)) {
            this.setState({
                msgError: '',
                showError: true,
                loading: true
            })
            let backend_url = process.env.REACT_APP_BACKEND_URL;
            let formData = new FormData();

            formData.append("Role", this.state.Role);
            formData.append("displayName", this.state.displayName);
            formData.append("email", this.state.email);

            axios({
                url: `${backend_url}/user/add`,
                data: formData,
                method: "post",
                headers: { "Content-Type": "multipart/form-data" },
            }).then((res) => {
                if (res.data.message)
                    this.setState({
                        showPopu: true,
                        loading: false
                    })
                else
                    this.setState({
                        msgError: 'This Email is already in use!',
                        showError: true,
                        loading: false
                    })
            });
        }
        else {
            this.setState({
                msgError: 'Email is incorrectly!',
                showError: true
            })
        }




    };
    enableButton = () => {
        this.setState({
            formValid: true,
        });
    };

    disableButton = () => {
        this.setState({
            formValid: false,
        });
    };


    componentDidMount() {

    }

    ////
    render() {
        const { classes } = this.props;
        return (
            <FusePageCarded
                classes={{
                    root: classes.layoutRoot,
                }}
                header={
                    <div className="p-24">
                        <Breadcrumb data={breadCurmbData} />
                    </div>
                }
                contentToolbar={
                    <div className="px-24">
                        <h2>Add System admin</h2>
                    </div>
                }
                content={
                    <div className="p-24">
                        <div className="max-w-lg">
                            <Formsy
                                onValidSubmit={this.handleSubmit}
                                onValid={this.enableButton}
                                onInvalid={this.disableButton}
                                ref={"admin_add"}
                                className="flex flex-col p6-12"
                            >
                                {this.state.msgError && (
                                    <Alert variant="filled" severity="error">
                                        {this.state.msgError}
                                    </Alert>
                                )}


                                <div className="flex flex-row justify-between py-20">
                                    <TextField
                                        className="w-5/12"
                                        id="input-with-icon-textfield"
                                        label="DisplayName"
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AccountCircle />
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={this.state.displayName}
                                        onChange={(e) => {
                                            this.handleChange(e, "displayName");
                                        }}
                                        required
                                    />
                                    <TextField
                                        className="w-5/12"
                                        id="input-with-icon-textfield"
                                        label="Email"
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EmailCircle />
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={this.state.email}
                                        onChange={(e) => {
                                            this.handleChange(e, "email");
                                        }}
                                        required
                                    />
                                </div>




                                <div className="flex flex-row justify-between py-10">
                                    <SelectFormsy
                                        className="w-5/12"
                                        name="Role"
                                        label="Role"
                                        value={this.state.Role}
                                        onChange={(e) => {
                                            this.handleChange(e, "Role");
                                        }}
                                        variant="outlined"
                                    >

                                        {this.state.RoleData.map((item) => {
                                            return <MenuItem value={item}>{item}</MenuItem>;
                                        })}
                                    </SelectFormsy>

                                </div>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.button}
                                    startIcon={<SaveIcon />}
                                    className="ml-auto my-16"
                                    disabled={!this.state.formValid}
                                    type="submit"
                                    variant="contained"
                                >
                                    Add
                </Button>

                                <Dialog
                                    open={this.state.showPopu}
                                    // onClose={}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">{"User created successfully!"}</DialogTitle>
                                    <DialogContent  >
                                        <DialogContentText id="alert-dialog-description">
                                            Password has been sent to <b>{this.state.email}</b>

                                        </DialogContentText>
                                        <Button variant="outlined" size="large" style={{
                                            margin: '1vw auto',
                                            display: 'block'

                                        }} color="primary" className={classes.margin}
                                            onClick={() => {
                                                this.setState({
                                                    showPopu: false,
                                                });

                                                this.props.history.push("/frontend/users/list");
                                            }}

                                        >
                                            close
        </Button>
                                    </DialogContent>

                                </Dialog>

                                <Backdrop
                                    className={classes.backdrop}
                                    open={this.state.loading}
                                    style={{
                                        zIndex: 999999,
                                        color: "#fff",
                                    }}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>



                            </Formsy>
                        </div>
                    </div>
                }
            />
        );
    }
}

export default withStyles(styles, { withTheme: true })(Add);
