import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio, Switch, FormControlLabel, CircularProgress } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import Formsy from "formsy-react";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";
import clsx from 'clsx';
import Icon from '@material-ui/core/Icon';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SaveIcon from "@material-ui/icons/Save";

import TextField from '@material-ui/core/TextField';
const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "Games",
    link: `${process.env.REACT_APP_FRONT_URL}games/list`,
  },
  {
    title: "Jigsaw puzzle",
    link: `${process.env.REACT_APP_FRONT_URL}games/jigsaw/list`,
  },
  {
    title: "Add",
    link: "",
  },
];

class Add extends Component {
  imgEl = React.createRef();

  state = {
    formValid: false,
    disabled: false,
    loading: false,
    data: {
      levelNameEn: '',
      levelNameAr: '',
      rows: 0,
      columns: 0,
      thumbnail: null,
      pieces: [],
    }

  };
  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
  };
  handleSubmit = (model) => {
    this.setState({
      loading: true
    })
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    formData.append('data', JSON.stringify(this.state.data));
    axios({
      url: `${backend_url}/games/jigsaw/add`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.setState({
        loading: false
      })
      this.props.history.push("/frontend/games/jigsaw/list");
    });
  };
  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };

  fileUpload2 = (e, id) => {
    let image = document.getElementById(id);
    image.src = URL.createObjectURL(e.target.files[0]);
    this.setState({
      thumbnail: e.target.files[0],
    });
  };

  uploadImage = (e) => {
    return new Promise((resolve) => {
      this.setState({
        loading: true
      })
      // /uploadImage
      let backend_url = process.env.REACT_APP_BACKEND_URL;
      let formData = new FormData();
      formData.append("image", e.target.files[0]);
      formData.append("questionImage", "");
      formData.append("fileType", "image");
      axios({
        url: `${backend_url}/uploadImage`,
        data: formData,
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        this.setState({
          loading: false
        })
        resolve(res.data.dest)
      });
    })
  }

  onImgLoad({ target: img }, i, j) {
    console.log(img.offsetHeight, img.offsetWidth);

  }
  uploadAudio = async (e, id) => {
    return new Promise((resolve) => {
      this.setState({
        loading: true
      })
      // /uploadfiletos3
      let backend_url = process.env.REACT_APP_BACKEND_URL;
      let formData = new FormData();
      formData.append("fileSrc", e.target.files[0]);
      formData.append("typeInput", "Audio");
      axios({
        url: `${backend_url}/uploadfiletos3`,
        data: formData,
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        this.setState({
          loading: false
        })
        resolve(res.data.result.Location)
      });
    });
  };


  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="px-24">
            <h2>Add Level</h2>
          </div>
        }
        content={
          <div className="p-24">

            {
              this.state.loading &&
              <div className="flex flex-col items-center justify-center h-full" style={{
                position: 'fixed',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0,0,0,0.5)',
                zIndex: 9999

              }}>
                <CircularProgress className="w-64 h-64" color="secondary" />
              </div>
            }
            <div className="max-w-lg">
              <Formsy
                onValidSubmit={this.handleSubmit}
                onValid={this.enableButton}
                onInvalid={this.disableButton}
                ref={"cateogry_add"}
                className="flex flex-col p6-12"
              >
                <div className="flex flex-row justify-between py-20">
                  <TextFieldFormsy
                    className="w-5/12"
                    type="text"
                    value={this.state.data.levelNameEn}
                    name="levelNameEn"
                    label="Level Name"
                    onChange={(e) => {
                      let data = this.state.data;
                      data.levelNameEn = e.target.value;
                      this.setState({
                        data: data,
                      });
                    }}
                    required
                    variant="outlined"
                  />

                  <TextFieldFormsy
                    className="w-5/12"
                    type="text"
                    value={this.state.data.levelNameAr}
                    name="levelNameAr"
                    label="Level Name"
                    onChange={(e) => {
                      let data = this.state.data;
                      data.levelNameAr = e.target.value;
                      this.setState({
                        data: data,
                      });
                    }}
                    required
                    variant="outlined"
                  />

                </div>

                <div className="flex flex-row justify-between  items-center py-20">
                  <TextFieldFormsy
                    className="w-4/12"
                    type="text"
                    value={this.state.data.columns}
                    name="columns"
                    label="Columns No."
                    onChange={(e) => {
                      let data = this.state.data;
                      data.columns = e.target.value;
                      this.setState({
                        data: data,
                      });
                    }}
                    required
                    variant="outlined"
                  />
                  <div>X</div>
                  <TextFieldFormsy
                    className="w-4/12"
                    type="text"
                    value={this.state.data.rows}
                    name="Rows"
                    label="Rows No."
                    onChange={(e) => {
                      let data = this.state.data;
                      data.rows = e.target.value;
                      this.setState({
                        data: data,
                      });
                    }}
                    required
                    variant="outlined"
                  />

                </div>

                <div className="flex flex-row justify-between py-20">
                  <div className="px-24 font-bold">
                    <h3>Pieces</h3>
                  </div>
                </div>

                <div className="jigsaw">
                  {
                    //columns * rows
                    //rows
                    [...Array(parseInt(this.state.data.rows))].map((e, i) => {
                      return (
                        <div className="flex flex-row justify-between py-20"  >
                          {
                            //columns
                            [...Array(parseInt(this.state.data.columns))].map((e, j) => {
                              return (
                                <div className="flex flex-col justify-center items-center">
                                  <div className="flex flex-row justify-between items-center">
                                    <div className="flex flex-row justify-between items-center">

                                      <Button variant="contained" component="label">
                                        <img
                                          alt=""
                                          id="thumbnail"
                                          title="thumbnail"
                                          ref={el => this.imgEl = el}
 
                                          src={this.state.data.pieces[i] && this.state.data.pieces[i][j] && this.state.data.pieces[i][j].image
                                            ? (process.env.REACT_APP_BACKEND_URL + '/public/' + this.state.data?.pieces[i][j]?.image) : "https://royakids.tv:4000/public/def.png"}
                                        />
                                        <input
                                          type="file"
                                          name="file"
                                          id={`image${i}-${j}`}
                                          onChange={async (e) => {

                                            this.uploadImage(e).then((res) => {


                                              let data = this.state.data;
                                              if (data.pieces.length == 0) {
                                                data.pieces.push([]);
                                              }
                                              if (data.pieces[i] == undefined) {
                                                data.pieces.push([]);
                                              }
                                              data.pieces[i][j] = {
                                                image: res,
                                              }
                                              this.setState({
                                                data: data,
                                              });

                                            })
                                          }}
                                          style={{ display: "none" }}

                                        />
                                      </Button>


                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      )
                    })

                  }


                </div>

                <div className="flex flex-row justify-between py-20">
                  <div className="flex justify-between items-center w-5/12">
                    <Button variant="contained" component="label">
                      upload full thumbnail
                      <input
                        accept="image/png"
                        type="file"
                        required
                        onChange={async (e) => {
                          let url = await this.uploadImage(e)
                          let data = this.state.data;
                          data.thumbnail = url;
                          this.setState({
                            data: data
                          })
                        }}
                        style={{ display: "none" }}
                      />
                    </Button>
                    <img
                      alt=""
                      id="thumbnail"
                      title="thumbnail"
                      style={{ maxWidth: "40%" }}
                      src={this.state.data.thumbnail ? (process.env.REACT_APP_BACKEND_URL + '/public/' + this.state.data.thumbnail) : "frontend/assets/images/custom/empty-image-thumb.png"}
                    />
                  </div>
                </div>

                <Button
                  color="primary"
                  size="large"
                  startIcon={<SaveIcon />}
                  className="ml-auto my-16"
                  disabled={!this.state.formValid}
                  type="submit"
                  variant="contained"
                >
                  Add
                </Button>
              </Formsy>
            </ div>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(Add);
