import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio, Switch, FormControlLabel, CircularProgress } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import Formsy from "formsy-react";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";
import clsx from 'clsx';
import Icon from '@material-ui/core/Icon';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SaveIcon from "@material-ui/icons/Save";

import TextField from '@material-ui/core/TextField';
const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "Games",
    link: `${process.env.REACT_APP_FRONT_URL}games/list`,
  },
  {
    title: "Missing letters",
    link: `${process.env.REACT_APP_FRONT_URL}games/Missing_letters/list`,
  },
  {
    title: "Add",
    link: "",
  },
];

class Add extends Component {
  state = {
    formValid: false,
    disabled: false,
    loading: false,
    data: {
      levelName: '',
      question: '',
      thumbnail: null,
      questions: [
        {
          isArabic: false,
          isOneWord: false,
          img: null,
          answer: '',
          answerVo: null,
          items: [],
          arrChoices: []
        }
      ]
    }

  };
  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
  };
  handleSubmit = (model) => {
    this.setState({
      loading: true
    })
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    formData.append('data', JSON.stringify(this.state.data));
    axios({
      url: `${backend_url}/games/missing_letters/add`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.setState({
        loading: false
      })
      this.props.history.push("/frontend/games/Missing_letters/list");
    });
  };
  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };

  fileUpload2 = (e, id) => {
    let image = document.getElementById(id);
    image.src = URL.createObjectURL(e.target.files[0]);
    this.setState({
      thumbnail: e.target.files[0],
    });
  };

  uploadImage = (e) => {
    return new Promise((resolve) => {
      this.setState({
        loading: true
      })
      // /uploadImage
      let backend_url = process.env.REACT_APP_BACKEND_URL;
      let formData = new FormData();
      formData.append("image", e.target.files[0]);
      formData.append("questionImage", "");
      formData.append("fileType", "image");
      axios({
        url: `${backend_url}/uploadImage`,
        data: formData,
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        this.setState({
          loading: false
        })
        resolve(res.data.dest)
      });
    })
  }


  uploadAudio = async (e, id) => {
    return new Promise((resolve) => {
      this.setState({
        loading: true
      })
      // /uploadfiletos3
      let backend_url = process.env.REACT_APP_BACKEND_URL;
      let formData = new FormData();
      formData.append("fileSrc", e.target.files[0]);
      formData.append("typeInput", "Audio");
      axios({
        url: `${backend_url}/uploadfiletos3`,
        data: formData,
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        this.setState({
          loading: false
        })
        resolve(res.data.result.Location)
      });
    });
  };


  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="px-24">
            <h2>Add Level</h2>
          </div>
        }
        content={
          <div className="p-24">

            {
              this.state.loading &&
              <div className="flex flex-col items-center justify-center h-full" style={{
                position: 'fixed',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0,0,0,0.5)',
                zIndex: 9999

              }}>
                <CircularProgress className="w-64 h-64" color="secondary" />
              </div>
            }
            <div className=''>
              <Formsy
                onValidSubmit={this.handleSubmit}
                onValid={this.enableButton}
                onInvalid={this.disableButton}
                ref={"cateogry_add"}
                className="flex flex-col p6-12"
              >
                <div className="flex flex-row justify-between py-20">
                  <TextFieldFormsy
                    className="w-11/12"
                    type="text"
                    value={this.state.data.levelName}
                    name="levelName"
                    label="Level Name"
                    onChange={(e) => {
                      let data = this.state.data;
                      data.levelName = e.target.value;
                      this.setState({
                        data: data,
                      });
                    }}
                    required
                    variant="outlined"
                  />

                </div>


                <div className="flex flex-row justify-between py-20">
                  <TextFieldFormsy
                    label="Question Text"
                    multiline
                    rows={3}
                    value={this.state.data.question}
                    variant="outlined"
                    className="w-11/12"
                    id="question"
                    name="question"
                    onChange={(e) => {
                      let data = this.state.data;
                      data.question = e.target.value;
                      this.setState({
                        data: data,
                      });
                    }}
                    required
                  />

                </div>






                <div className="flex flex-row justify-between py-20">
                  <div className="px-24 font-bold">
                    <h3>Questions list</h3>
                  </div>
                </div>




                {
                  this.state.data.questions.map((item, index) => {
                    return (
                      <div className="flex flex-col justify-between py-20 border-1 p-12">
                        <h2>Question:{index + 1}</h2>
                        <div className="flex flex-row justify-between py-20">
                          <TextFieldFormsy
                            label="Answer"
                            value={item.answer}
                            variant="outlined"
                            className="w-11/12 full "
                            id="answer"
                            name="answer"
                            onChange={(e) => {
                              let data = this.state.data;
                              data.questions[index].answer = e.target.value;
                              this.setState({
                                data: data,
                              });
                            }}
                            required
                          />

                        </div>
                        <div className="flex flex-row justify-between py-20">
                          <Button variant="contained" component="label">
                            <input
                              accept="image/png"
                              type="file"
                              name={"img" + index}
                              id={"img" + index}
                              onChange={async (e) => {
                                let url = await this.uploadImage(e)
                                let data = this.state.data;
                                data.questions[index].img = url;
                                this.setState({
                                  data: data
                                })
                              }}
                              style={{ display: "none" }}
                            />
                            Upload Image
                          </Button>

                          {
                            item.img &&
                            <img src={process.env.REACT_APP_BACKEND_URL + '/public/' + item.img} alt="" className="  h-64" />
                          }
                        </div>

                        <div className="flex flex-row justify-between py-20">
                          <Button variant="contained" component="label">
                            <input
                              accept="audio/*"
                              type="file"
                              name={"audio" + index}
                              id={"audio" + index}
                              onChange={async (e) => {
                                let url = await this.uploadAudio(e, "audio" + index);
                                let data = this.state.data;
                                data.questions[index].answerVo = url;
                                this.setState({
                                  data: data,
                                });
                              }}
                              style={{ display: "none" }}
                            />
                            Upload Audio
                          </Button>

                          {item.answerVo && (
                            <audio controls>
                              <source src={item.answerVo} type="audio/mpeg" />
                            </audio>
                          )}


                        </div>

                        <div className="flex flex-row justify-between py-2">
                          <FormControlLabel
                            control={<Switch checked={item.isArabic}
                              color="primary"
                              onChange={(e) => {
                                let data = this.state.data;
                                data.questions[index].isArabic = e.target.checked;
                                this.setState({
                                  data: data,
                                });
                              }}
                              name="isArabic" />}
                            label="isArabic"
                            labelPlacement="top"
                          />
                        </div>

                        <div className="flex flex-row justify-between py-2">
                          <FormControlLabel
                            control={<Switch checked={item.isOneWord}
                              color="primary"
                              onChange={(e) => {
                                let data = this.state.data;
                                data.questions[index].isOneWord = e.target.checked;
                                this.setState({
                                  data: data,
                                });
                              }}
                              name="isOneWord" />}
                            label="isOneWord"
                            labelPlacement="top"
                          />
                        </div>

                        <label className="font-bold">Items</label>
                        {item.answer.replace(/\s/g, '').replace('َ', '').replace('ِ', '').replace('ُ', '').replace('ً', '').replace('ٍ', '').replace('ٌ', '').replace('ْ', '').replace('ّ', '').replace('ٓ', '').replace('ٔ', '').replace('ٰ', '').replace('ـ', '').split('').length}
                        <div className="flex flex-row justify-between py-20">

                          {
                            // items
                            item.answer.replace(/\s/g, '').replace('َ', '').replace('ِ', '').replace('ُ', '').replace('ً', '').replace('ٍ', '').replace('ٌ', '').replace('ْ', '').replace('ّ', '').replace('ٓ', '').replace('ٔ', '').replace('ٰ', '').replace('ـ', '').split('').map((item2, index2) => {
                              return (
                                <div className="flex flex-col py-20" key={item.items[index2]?._id}>
                                  <TextFieldFormsy
                                    label="Item"
                                    variant="outlined"
                                    className="w-11/12 mr-12"
                                    id="item"
                                    name={"Item" + index2}
                                    value={item.items[index2]?.value}
                                    onChange={(e) => {
                                      let data = this.state.data;
                                      if (data.questions[index].items[index2]) {
                                        data.questions[index].items[index2].value = e.target.value;
                                        this.setState({
                                          data: data,
                                        });
                                      }
                                      else {
                                        data.questions[index].items = data.questions[index].items || [];
                                        data.questions[index].items[index2] = { value: e.target.value };
                                        data.questions[index].items[index2]._id = 'item' + index2;
                                        data.questions[index].items[index2].index = index2;
                                        data.questions[index].items[index2].isBlank = false;

                                        this.setState({
                                          data: data,
                                        });

                                      }

                                    }}

                                  />
                                  <FormControlLabel
                                    control={<Switch
                                      color="primary"
                                      name={item.items[index2]?._id}
                                      onChange={(e) => {
                                        let data = this.state.data;
                                        if (data.questions[index].items[index2]) {
                                          data.questions[index].items[index2].isBlank = e.target.checked;
                                        }
                                        else {
                                          data.questions[index].items.push({
                                            _id: `item${index2}`,
                                            index: index2,
                                            value: "",
                                            isBlank: true
                                          })
                                        }
                                        this.setState({
                                          data: data,
                                        });
                                      }}
                                    />}
                                    label="isBlank"
                                    labelPlacement="left"
                                  />

                                </div>


                              )
                            })
                          }
                        </div>


                        <label className="font-bold">Choices</label>
                        <div className="flex flex-row justify-between py-20">
                          {
                            // items
                            item.answer.replace(/\s/g, '').replace('َ', '').replace('ِ', '').replace('ُ', '').replace('ً', '').replace('ٍ', '').replace('ٌ', '').replace('ْ', '').replace('ّ', '').replace('ٓ', '').replace('ٔ', '').replace('ٰ', '').replace('ـ', '').split('').map((item2, index2) => {
                              return (
                                <div className="flex flex-col py-20" key={item.items[index2]?._id}>

                                  <TextFieldFormsy
                                    label="Item"

                                    variant="outlined"
                                    // value={item.arrChoices[index2].value}

                                    className="w-11/12 mr-12"
                                    id="item"
                                    name="Item"
                                    onChange={(e) => {
                                      let data = this.state.data;
                                      if (data.questions[index].arrChoices[index2]) {
                                        data.questions[index].arrChoices[index2].value = e.target.value;
                                      }
                                      else {
                                        data.questions[index].arrChoices.push({
                                          _id: 'item' + index2,
                                          value: e.target.value,
                                          vo: null
                                        })
                                      }
                                      this.setState({
                                        data: data,
                                      });
                                    }}

                                  />


                                  <Button variant="contained" component="label"
                                    style={{ backgroundColor: '#634fc3', color: 'white', width: "150px", fontSize: "12px" }}
                                  >
                                    <input
                                      accept="audio/*"
                                      type="file"
                                      name={"answer" + index2}
                                      id={"answer" + index2}
                                      onChange={async (e) => {
                                        let url = await this.uploadAudio(e, "audio" + index2);
                                        let data = this.state.data;
                                        if (data.questions[index].arrChoices[index2]) {
                                          data.questions[index].arrChoices[index2].vo = url;
                                          this.setState({
                                            data: data,
                                          });
                                        }
                                        else {
                                          data.questions[index].arrChoices.push({
                                            _id: 'item' + index2,
                                            value: "",
                                            vo: url
                                          })
                                          this.setState({
                                            data: data,
                                          });
                                        }

                                      }}
                                      style={{ display: "none" }}
                                    />
                                    Upload Audio
                                  </Button>

                                  {item.arrChoices[index2] && (
                                    <audio controls style={{
                                      width: "150px",
                                    }}>
                                      <source src={item.arrChoices[index2].vo} type="audio/mpeg" />
                                    </audio>
                                  )}


                                </div>
                              )
                            })
                          }
                        </div>



                      </div>
                    )
                  })
                }

                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    className="w-5/12"
                    aria-label="Add"
                    onClick={() => {
                      let data = this.state.data;
                      data.questions.push({
                        answer: "",
                        isArabic: false,
                        isOneWord: false,
                        img: "",
                        answerVo: null,
                        items: [
                          {
                            _id: `item_${Math.floor(Math.random() * 50000)}`,
                            index: 0,
                            value: '',
                            isBlank: false
                          }
                        ],
                        arrChoices: [
                          {
                            _id: 0,
                            value: ""
                          }
                        ]
                      });
                      this.setState({
                        data: data
                      })
                    }}
                  >
                    Add Question
                  </Button>
                </div>


                <div className="flex flex-row justify-between py-20">
                  <div className="flex justify-between items-center w-5/12">
                    <Button variant="contained" component="label">
                      upload thumbnail
                      <input
                        accept="image/png"
                        type="file"
                        required
                        onChange={async (e) => {
                          let url = await this.uploadImage(e)
                          let data = this.state.data;
                          data.thumbnail = url;
                          this.setState({
                            data: data
                          })
                        }}
                        style={{ display: "none" }}
                      />
                    </Button>
                    <img
                      alt=""
                      id="thumbnail"
                      title="thumbnail"
                      style={{ maxWidth: "40%" }}
                      src={this.state.data.thumbnail ? (process.env.REACT_APP_BACKEND_URL + '/public/' + this.state.data.thumbnail) : "frontend/assets/images/custom/empty-image-thumb.png"}
                    />
                  </div>
                </div>

                <Button
                  color="primary"
                  size="large"
                  startIcon={<SaveIcon />}
                  className="ml-auto my-16"
                  disabled={!this.state.formValid}
                  type="submit"
                  variant="contained"
                >
                  Add
                </Button>
              </Formsy>
            </ div>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(Add);
