import List from "./List";
import Edit from "./Edit";
import profile from "./profile";
import add from "./add";
import addApp from "./app_add";
import { authRoles } from 'app/auth';

export const UsersConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: `${process.env.REACT_APP_FRONT_URL}users/list`,
      component: List,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}users/:id/edit`,
      component: Edit,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}users/:id/profile`,
      component: profile,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}users/add`,
      component: add,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}users/app/add`,
      component: addApp,
    }
  ],
};
