import React from "react";
import { Redirect } from "react-router-dom";
import { FuseUtils } from "@fuse";
import { SingersConfig } from "app/main/singers/SingersConfig";
import { DashboardConfig } from "app/main/dashboard/DashboardConfig";
import { UserConfig } from "app/main/user/UserConfig";
import { CategoryConfig } from "app/main/category/CategoryConfig";
import { genreConfig } from "app/main/genre/genreConfig";
import { ComposersConfig } from "app/main/composers/ComposersConfig";
import { authorsConfig } from "app/main/authors/authorsConfig";
import { ageRateConfig } from "app/main/ageRate/ageRateConfig";
import { bannersConfig } from "app/main/banners/bannersConfig";
import { BundlesConfig } from "app/main/bundles/BundlesConfig";
import { UsersConfig } from "app/main/users/UsersConfig";
import { tagsConfig } from "app/main/tags/tagsConfig";
import { avatarConfig } from "app/main/avatar/avatarConfig";
import { privacyPolicy } from "app/main/privacyPolicy/viewConfig";
import { songsConfig } from "app/main/songs/songsConfig";
import { storiesConfig } from "app/main/stories/storiesConfig";
import { notificationConfig } from "app/main/notification/notificationConfig";
import  FileManagerAppConfig  from "app/main/file-manager/FileManagerAppConfig";
import { GamesConfig } from "app/main/games/GamesConfig";
import { showsConfig } from "app/main/shows/showsConfig";
import { logsConfig } from "app/main/logs/logsConfig";
import { contactusConfig } from "app/main/contactus/contactusConfig";
import { PromoCodeConfig } from "app/main/promoCode/PromoCodeConfig";
import { BookConfig } from "app/main/books/BookConfig"; 

import { aboutConfig } from "app/main/about/viewConfig";
import { competitionsConfig } from "app/main/competitions/competitionsConfig";
import { appsettings } from "app/main/appsettings/appsettingsConfig";
import { termsConditions } from "app/main/termsConditions/viewConfig";
import { applicationBackgroundsConfig } from "app/main/applicationBackgrounds/applicationBackgroundsConfig";
import { resetpasswordApp } from "app/main/resetpassword/viewConfig";
import { useBasename } from 'history'

const routeConfigs = [
  genreConfig,
  SingersConfig,
  CategoryConfig,
  ComposersConfig,
  authorsConfig,
  ageRateConfig,
  bannersConfig,
  BundlesConfig,
  UsersConfig,
  tagsConfig,
  songsConfig,
  storiesConfig,
  privacyPolicy,
  termsConditions,
  BookConfig,
  avatarConfig,
  appsettings,
  showsConfig,
  GamesConfig,
  logsConfig,
  PromoCodeConfig,
  contactusConfig,
  FileManagerAppConfig,
  aboutConfig,
  competitionsConfig,
  notificationConfig,
  applicationBackgroundsConfig,
  resetpasswordApp,
  DashboardConfig,
  UserConfig,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: "/",
		component: () => <Redirect to="/" />
  },
];

export default routes;
