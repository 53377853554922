import React from 'react';
import Button from '@material-ui/core/Button';
//import { Formsy, TextFieldFormsy , SelectFormsy} from 'react-material-ui-form-validator';

import {
    CheckboxFormsy,
    FuseChipSelectFormsy,
    RadioGroupFormsy,
    SelectFormsy,
    TextFieldFormsy
} from '@fuse/core/formsy';
import Formsy from 'formsy-react';

import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
//import {getData,getPublisherList,getAgeGroupList,getTagsList,getTextGenreList,getGenreList} from '../model/category';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
//import {baseLink,frontLink} from "./../componets/helper";
import Select from 'react-select';
import { Checkbox, TextField } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { isThisQuarter } from 'date-fns';
import { Autocomplete } from '@material-ui/lab';

//import Breadcrumb from "./../componets/breadcrumb";

const baseLink = process.env.REACT_APP_BACKEND_URL;
const frontLink = process.env.REACT_APP_FRONTEND_URL;

const checkbox = {
    checkboxItem: {
        display: "flex",
        height: 40,
        alignItems: "center"
    },
    checkboxLabel: {
        paddingLeft: 12
    },
    checkboxInput: {
        width: 20,
        height: 20
    }
}

class bookForm extends React.Component {
    bookUpdated = 0;
    constructor(props) {
        super(props);
    }
    state = {
        categoryUpdated: 0,
        nameEn: "",
        nameAr: "",
        screenshots: [],
        genreData: [],
        genresId: [],
        open: false,
        language: "ar",
        category: "",
        ageGroup: "",
        categoryList: [],
        cover_photo: "",
        epub_file: "",
        pagesNo: "",
        isbn: "",
        pointsNo: "",
        categoryResult: [],
        parentArr: [],
        itemArrs: [],
        opf_file: "",
        html_file: "",
        firstPageurl: "",
        coverPhoto: "",
        bookType: "",
        type: "book",
        open4: false,
        bookSource: "",
        tags: [],
        coverImg: [],
        status: true,
        publisherList: [],
        tagsList: [],
        audioLength: 0,
        imgTabletPort: "",
        imgTabletLand: "",
        imgMobilePort: "",
        imgMobileLand: "",
        descriptionen: "",
        descriptionar: "",
        categoryId: 'none',
        categoryData: [],
        agerateData: [],
        agerateId: "none",
    }

    handleChangeCheck = (e) => {
        this.setState({
            status: e.target.checked
        });
    }
    //  classes = useStyles(this.props);
    handleChangetags = (val, name) => {

        this.setState({
            [name]: val,
        });

    };
    handleSelect = (value, name) => {

        if (name === "type" && (value === "video" || value === "audio")) {
            this.setState({
                epub_file: "",
                bookSource: ""
            })
        }
        this.setState({
            [name]: value
        });
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    fileChange = (e) => {
        this.setState({
            [e.target.id]: e.target.files[0]
        });
    }



    handleSubmit = (e) => {
        let th = this;
        let categoryNames = this.state.categoryNames;
        let data = {};

        data.agerateId = th.state.agerateId;
        data.categoryId = th.state.categoryId;
        //data.category = th.state.category;
        data.descriptionen = th.state.descriptionen;
        data.descriptionar = th.state.descriptionar;
        data.language = th.state.language;
        data.nameEn = th.state.nameEn;
        data.nameAr = th.state.nameAr;
        data.pagesNo = th.state.pagesNo;
        data.pointsNo = th.state.pointsNo;
        data.coverPhoto = th.state.cover_photo;
        data.epubFile = th.state.epubFile;
        data.bookType = th.state.bookType;
        data.bookSource = th.state.bookSource;
        data.status = th.state.status;
        data.imgTabletLand = th.state.imgTabletLand;
        data.imgTabletPort = th.state.imgTabletPort;


        let i = 0;
        let items = [];
        let itemsCategory = [];
        let count = 0;
        while (i < document.getElementsByClassName("check-items").length) {
            let checked = document.getElementsByClassName("check-items")[i].checked;

            if (checked) {
                let obj = {};
                obj.name = document.getElementsByClassName("check-items")[i].getAttribute("name");
                obj.id = document.getElementsByClassName("check-items")[i].value;
                items.push(obj);
                count++;
            }
            i++;
        }
        data.opf_file = th.state.opf_file;
        data.html_file = th.state.html_file;
        data.firstPageurl = th.state.firstPageurl;
        data.coverPhoto = th.state.coverPhoto;
        data.tags = th.state.tags.map(item => item._id);
        data.genresId = th.state.genresId.map(item => item._id);
        // data.screenshots = JSON.stringify(data.screenshots);
        data.orignalFile = th.state.orignalFile;
        data.type = th.state.type;

        this.props.obj.submit(data);



    }
    componentDidUpdate() {
        let th = this;
        let output = [];
        let output2 = [];
        setTimeout(() => {
            if (this.state.itemArrs !== [] && this.state.parentArr !== [] && th.state.categoryUpdated === 0) {
                let parentArr = this.state.parentArr;

                parentArr.forEach(function (item, index) {
                    output.push(<div className={"checkbox-item items-" + item.language}><div className={"level-" + item.level}>
                        <input type='checkbox' className="check-items" key={item.nameAr} name={item.nameAr} value={item._id} /><label>{item.nameAr}</label>

                    </div>{th.getCategoryChild(index)}</div>);

                    output2.push(<div className={"checkbox-item items-" + item.language}><div className={"level-" + item.level}>
                        <input type='checkbox' className="check-items" key={item.nameEn} name={item.nameEn} value={item._id} /><label>{item.nameEn}</label>

                    </div>{th.getCategoryChild2(index)}</div>);

                });
                this.setState({
                    output: output,
                    categoryUpdated: 1,
                    output2: output2
                });
            }

        }, 1500);

    }
    getCategoryChild = (index) => {
        let output = [];
        let itemArrs = this.state.itemArrs;

        itemArrs[index].forEach(function (item1, index1) {
            //let lv = parseInt(item1.level);
            //let curretIn = index + "-" + (lv-1);
            let lv = parseInt(item1.level);
            lv--;
            output.push(<div className={"parent-" + lv + " level-" + item1.level}>
                <input type='checkbox' className="check-items" key={item1.nameAr} name={item1.nameAr} value={item1._id} /><label>{item1.nameAr}</label>
            </div>);
        });
        return output;
    }
    getCategoryChild2 = (index) => {
        let output = [];
        let itemArrs = this.state.itemArrs;

        itemArrs[index].forEach(function (item1, index1) {
            //let lv = parseInt(item1.level);
            //let curretIn = index + "-" + (lv-1);
            let lv = parseInt(item1.level);
            lv--;
            output.push(<div className={"parent-" + lv + " level-" + item1.level}>
                <input type='checkbox' className="check-items" key={item1.nameEn} name={item1.nameEn} value={item1._id} /><label>{item1.nameEn}</label>
            </div>);
        });
        return output;
    }
    builtInAudio = (opf_link) => {
        let currentLink = frontLink + opf_link;
        let currentLink1 = currentLink.replace("content.opf", "");
        let opf_link2 = opf_link.replace("content.opf", "");

        axios.get(currentLink).then(function (res) {
            let opf_data = new DOMParser().parseFromString(res.data, "text/xml");
            let spine_list = opf_data.querySelectorAll("spine itemref");
            let manifest_list = opf_data.querySelectorAll("manifest item");
            let page_ids = [];
            let pages = {};
            let smil_list = {};
            let audios = {};
            let MainObj = {};

            MainObj.bookId = "story1";
            MainObj.title = "story1";
            MainObj.version = "";

            spine_list.forEach(function (itemref) {
                //console.log(itemref.getAttribute("idref"));
                page_ids.push(itemref.getAttribute("idref"));
            });
            manifest_list.forEach(function (itemref) {
                let id = itemref.getAttribute("id");
                let media_type = itemref.getAttribute("media-type");
                if (media_type === "application/smil+xml") {
                    smil_list[id] = itemref.getAttribute("href");
                }
                if (media_type === "audio/mpeg") {
                    let href = itemref.getAttribute("href").split("/");
                    let audioLink = href[href.length - 1];
                    audios[audioLink] = itemref.getAttribute("href");
                }
                if (page_ids.indexOf(id) !== -1) {
                    pages[id] = {};
                    pages[id].pagePath = itemref.getAttribute("href");

                    if (itemref.getAttribute("media-overlay") !== null) {
                        pages[id].smilId = itemref.getAttribute("media-overlay");
                    }
                }
            });
            pages = Object.values(pages);
            pages.forEach(function (pageItem, indx) {
                pages[indx].pageId = indx;
                pages[indx].smilAudios = {};

                pages[indx].voiceRecords = [];
                pages[indx].notes = [];
                pages[indx].pageAudioType = "";
                pages[indx].drawings = [];
                pages[indx].highlights = [];


                pages[indx]['smilAudios']['spans'] = [];



                if (pageItem.smilId !== undefined) {
                    axios.get(currentLink1 + smil_list[pageItem.smilId]).then(function (res) {
                        let doc3 = new DOMParser().parseFromString(res.data, "text/xml");
                        doc3.querySelectorAll("par").forEach(function (item) {
                            let select_id = item.querySelector("text").getAttribute("src").split("#")[1];
                            let audio_src = item.querySelector("audio").getAttribute("src").split("/");
                            let audio_start = item.querySelector("audio").getAttribute("clipBegin");
                            let audio_end = item.querySelector("audio").getAttribute("clipEnd");

                            let selected_audio = audio_src[audio_src.length - 1];
                            let obj = {}
                            pages[indx]['smilAudios'].audioLink = audios[selected_audio];
                            obj.start = audio_start;
                            obj.end = audio_end;
                            obj.textId = select_id;


                            pages[indx]['smilAudios']['spans'].push(obj);
                            delete pages[indx].smilId;
                        });
                    });
                }
                if (indx === pages.length - 1) {

                    MainObj.pages = pages;
                    setTimeout(() => {
                        let bodyFormData = new FormData();
                        bodyFormData.set("base_path", opf_link2);
                        bodyFormData.set("book", JSON.stringify(MainObj));

                        axios({
                            method: 'post',
                            url: baseLink + '/book/update_json',
                            data: bodyFormData,
                            config: { headers: { 'Content-Type': 'multipart/form-data' } }
                        }).then(function (res) {

                        });

                    }, 1000);

                }
            });

        });
    }

    componentDidMount() {
        let th = this;

        let mode = th.props.mode;

        let backend_url = process.env.REACT_APP_BACKEND_URL;

        axios({
            url: `${backend_url}/tags/list`,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            this.setState({
                tagsList: res.data.result,
            });
        });

        axios({
            url: `${backend_url}/genre/list`,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            this.setState({
                genreData: res.data.result,
            });
        });

        axios({
            url: `${backend_url}/category/list`,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            this.setState({
                categoryData: res.data.result,
            });
        });

        axios({
            url: `${backend_url}/agerate/list`,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            this.setState({
                agerateData: res.data.result,
            });
        });
        setTimeout(() => {

            if (mode === "edit") {
                let bodyFormData = new FormData();
                bodyFormData.set("id", th.props.id);
                axios({
                    method: 'post',
                    url: baseLink + '/book/get',
                    data: bodyFormData,
                    config: { headers: { 'Content-Type': 'multipart/form-data' } }
                }).then(function (res) {

                    let data = res.data;

                    let genereSuggest = th.state.genereSuggest;
                    let tagsSuggeste = th.state.tagsSuggeste;

                    let categoryArray = data[0].category;
                    
                      


                    if (data[0].coverPhoto !== "") {
                        data[0].coverImg = [];
                        data[0].coverImg.push(<img className="cover-im" src={baseLink + "/public/" + data[0].coverPhoto[0]} />);
                    }
                    if (data[0].orignalFile !== "" && data[0].orignalFile !== undefined) {
                        data[0].downloadEpub = [];
                        data[0].downloadEpub.push(<div className="download-epub1"><a href={baseLink + "/" + data[0].orignalFile}>Download</a></div>)
                    }
                  

                    th.setState(data[0]);


                });
            }
        }, 2000);

    }
    getChilds() {
        return (<div>test</div>);
    }
    uploadFile = (e) => {
        let th = this;
        let id = e.target.id;
        let file = e.target.files;
        let destFolder = "screenshots";
        id = id.replace("screenshot-", "");
        let screenshots = this.state.screenshots;

        if (file.length > 0) {

            var bodyFormData = new FormData();
            let th = this;
            bodyFormData.set("file", e.target.files[0]);
            bodyFormData.set("dest", destFolder);


            axios({
                method: 'post',
                url: baseLink + '/upload/file',
                data: bodyFormData,
                config: { headers: { 'Content-Type': 'multipart/form-data' } }
            }).then(function (res) {
                let path = res.data;
                screenshots[id] = path.fileurl;
                th.setState({
                    screenshots: screenshots
                });
            });
        }

    }
    addMore = (e) => {
        let screenshots = this.state.screenshots;
        screenshots[screenshots.length] = "";
        this.setState({
            screenshots: screenshots
        });
    }
    deleteIc = (e) => {
        let id = e.target.id;
        id = id.replace("delete-", "");
        id = parseInt(id);
        let screenshots = this.state.screenshots;
        screenshots.splice(id, 1);
        this.setState({
            screenshots: screenshots
        });

    }
    uploadCover = (e) => {
        let th = this;
        let bodyFormData = new FormData();
        bodyFormData.set("coverFile", e.target.files[0]);

        axios({
            method: 'post',
            url: baseLink + '/book/upload_cover',
            data: bodyFormData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        }).then(function (res) {
            if (res.data.message === "done") {
                th.setState({
                    coverPhoto: res.data.target
                });
            }

        });
    }

    uploadCoverV2 = (e, type) => {
        let th = this;
        let bodyFormData = new FormData();
        bodyFormData.set("coverFile", e.target.files[0]);

        axios({
            method: 'post',
            url: baseLink + '/book/upload_img_file',
            data: bodyFormData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        }).then(function (res) {
            if (res.data.message === "done") {
                th.setState({
                    [type]: res.data.target
                });
            }

        });

    }
    uploadEpub2 = (e) => {
        this.setState({
            epubFile: e.target.files[0]
        });
    }
    /* uploadEpub = (bookId,epubFile) => {
 
         let th = this;
         let bodyFormData = new FormData();
         bodyFormData.set("epubFile",epubFile);
         th.setState({
             open4:true
         });
 
         axios({
             method: 'post',
             url: baseLink + '/book/upload_book',
             data: bodyFormData,
             config: { headers: {'Content-Type': 'multipart/form-data' }}
             }).then(function (res) {
                 if(res.data.message === "done") {
                     th.setState({
                         opf_file:res.data.opf_file,
                         html_file:res.data.html_file,
                         firstPageurl:res.data.firstPageurl,
                         orignalFile:res.data.orignalFile
                     });
                     th.setState({
                         open4:false
                     });
                     if(th.state.bookSource === "Build in audio") {
                         th.builtInAudio(res.data.opf_file);
                     }
                 }
                 
             });
     }*/
    handleChange2 = (options) => {
        this.setState({
            tags: options
        });
    }
    handleChange3 = (options) => {
        this.setState({
            textGenre: options
        });
    }
    render() {
        let th = this;
        return (



            <div>


                <Formsy
                    //ref="form"
                    onValidSubmit={this.handleSubmit}
                    // onSubmit={(e,v,d) => { 
                    //     v.preventDefault();
                    // }}
                    //onError={errors => console.log(errors)}
                    className="flex flex-wrap w-9/12"
                >
                    <div className="form-item w-full p-12 flex justify-end">

                        <Button color="primary" variant="contained" type="submit">{this.props.mode}</Button>
                    </div>



                    {/* 
                <div className="form-item w-1/2 p-12">
                <SelectFormsy
                    label="Type"
                    onChange={(e) => {this.handleSelect(e.target.value,"type")}}
                    name="type"
                    value={this.state.type}
                    variant="outlined"
                    className="w-full"
                    required
                >
                <MenuItem value={"book"}>Book</MenuItem>
                <MenuItem value={"video"}>Video</MenuItem>
                <MenuItem value={"audio"}>Audio</MenuItem>

                </SelectFormsy>
                </div> */}




                    <div className="form-item w-1/2 p-12">
                        <TextFieldFormsy
                            label="Name EN"
                            className="w-full"
                            onChange={this.handleChange}
                            name="nameEn"
                            value={this.state.nameEn}
                            variant="outlined"
                            validators={['required']}
                            errorMessages={['Field required']}
                            required
                        />
                    </div>

                    <div className="form-item w-1/2 p-12">
                        <TextFieldFormsy
                            label="Name AR"
                            className="w-full"
                            onChange={this.handleChange}
                            name="nameAr"
                            value={this.state.nameAr}
                            variant="outlined"
                            validators={['required']}
                            errorMessages={['Field required']}
                            required
                        />
                    </div>

                    <div className="form-item w-1/2 p-12">
                        <TextFieldFormsy
                            label="Description En"
                            multiline
                            rows={4}
                            value={this.state.descriptionen}
                            variant="outlined"
                            className="w-full"
                            id="descriptionen"
                            name="descriptionen"
                            onChange={this.handleChange}
                            required
                        />
                    </div>


                    <div className="form-item w-1/2 p-12">
                        <TextFieldFormsy
                            label="Description Ar"
                            multiline
                            rows={4}
                            value={this.state.descriptionar}
                            variant="outlined"
                            className="w-full"
                            id="descriptionar"
                            name="descriptionar"
                            onChange={this.handleChange}
                            required
                        />
                    </div>



                    <div className="form-item w-1/2 p-12">
                        <SelectFormsy
                            className="w-full"
                            name="categoryId"
                            label="Category"
                            value={this.state.categoryId}
                            onChange={(e) => {
                                this.setState({
                                    categoryId: e.target.value
                                })
                            }}
                            variant="outlined"
                        >
                            <MenuItem value="none">None</MenuItem>
                            {this.state.categoryData.map((item) => {
                                return <MenuItem value={item._id}>{item.titleEn}</MenuItem>;
                            })}
                        </SelectFormsy>
                    </div>


                    {this.state.type === "book" &&
                        <div className="form-item w-1/2 p-12">
                            <SelectFormsy
                                label="Book source"
                                onChange={(e) => { this.handleSelect(e.target.value, "bookSource") }}
                                name="bookSource"
                                value={this.state.bookSource}
                                variant="outlined"
                                className="w-full"
                                required
                            >
                                <MenuItem value={"in design"}>In design</MenuItem>
                                <MenuItem value={"reflowable"}>Reflowable</MenuItem>
                                <MenuItem value={"Build in audio"}>Build in audio</MenuItem>

                            </SelectFormsy>
                        </div>
                    }






                    <div className="form-item upload-item w-1/2 p-12">
                        <div className="upload-item-container">
                            <div className="upload-item-container2">
                                <label style={{ paddingRight: "10px" }}>Epub book</label>
                                <input type="file" id="epub_file" onChange={this.uploadEpub2} />
                                {this.state.downloadEpub}
                            </div>
                        </div>
                    </div>
                    <div className="form-item upload-item w-1/2 p-12">
                        <div className="upload-item-container">
                            <div className="upload-item-container2">
                                <label style={{ paddingRight: "10px" }}>Cover photo</label>
                                <input type="file" id="cover_photo" onChange={this.uploadCover} />
                                {this.state.coverImg}
                            </div>
                        </div>
                    </div>




                    <div className="form-item tags-item w-1/2 p-12">
                        <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={this.state.tagsList}
                            filterSelectedOptions
                            value={this.state.tags}
                            getOptionLabel={(option) => option.titleEn}
                            onChange={(event, value) =>

                                this.handleChangetags(value, "tags")

                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Tags"

                                />
                            )}
                        />


                    </div>










                    <div className="form-item w-1/2 p-12">
                        <Autocomplete
                            className="w-full"
                            multiple
                            id="tags-outlined"
                            options={this.state.genreData}
                            value={this.state.genresId}
                            filterSelectedOptions
                            getOptionLabel={(option) => option.titleEn}
                            onChange={(event, value) =>
                                this.handleChangetags(value, "genresId")
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Genre"

                                />
                            )}
                        />


                    </div>


                    <div className="form-item w-1/2 p-12">
                        <SelectFormsy
                            label="Language"
                            onChange={(e) => { this.handleSelect(e.target.value, "language") }}

                            name="language"
                            value={this.state.language}
                            variant="outlined"
                            className="w-full"
                        >
                            <MenuItem value={"en"}>En</MenuItem>
                            <MenuItem value={"ar"}>Ar</MenuItem>
                        </SelectFormsy>
                    </div>


                    <div className="form-item w-1/2 p-12">

                        <SelectFormsy
                            className="w-full"
                            name="ageRate"
                            label="Age Rate"
                            value={this.state.agerateId}
                            onChange={(e) => {
                                this.setState({
                                    agerateId: e.target.value
                                })
                            }}
                            variant="outlined"
                        >
                            <MenuItem value="none">
                                <em>None</em>
                            </MenuItem>
                            {this.state.agerateData.map((item) => {
                                return <MenuItem value={item._id}>{item.titleEn}</MenuItem>;
                            })}
                        </SelectFormsy>
                    </div>



                    <div className="form-item w-1/2 p-12">



                        <FormControlLabel
                            value="top"
                            control={<Checkbox checked={this.state.status} color="primary" />}
                            label="Published"
                            className="w-full"
                        />



                    </div>



                    {/* 
                    <div className="form-item upload-item spread-item w-full p-12">
                        <div className="w-full">
                            <label>Screenshots</label>
                            {this.state.screenshots.map((item, index) =>
                                <div className="upload-item-container2 split-down flex w-full items-center justify-between">
                                    <div className="delete-ic" onClick={this.deleteIc} ><img id={"delete-" + index} src="/images/delete.png" /></div>
                                    <input type="file" onChange={this.uploadFile} id={"screenshot-" + index} className="screenshots" />
                                    <div className="preview">{(item !== "") ? <img src={baseLink + "/" + item} /> : ""}</div>
                                </div>
                            )}


                            <div className="add-more-bt"><Button variant="contained" onClick={this.addMore}>Add more</Button></div>

                        </div>
                    </div> */}

                    <div className="w-full flex flex-wrap">
                        {this.state.categoryList.map((item) => {
                            return (<div style={{ width: "50%", paddingLeft: 20, paddingBottom: 50 }}>
                                <div style={checkbox.checkboxItem}><input className="check-items" type="checkbox" style={checkbox.checkboxInput} value={item._id} name={item.name} /><label style={checkbox.checkboxLabel}>{item.name}</label></div>
                                {item.children.map((item2) => {
                                    return (<div style={{ marginLeft: 25 }}>
                                        <div style={checkbox.checkboxItem}><input className="check-items" style={checkbox.checkboxInput} type="checkbox" value={item2._id} name={item2.name} /><label style={checkbox.checkboxLabel}>{item2.name}</label></div>
                                        {item2.children.map((item3) => {
                                            return (<div style={{ marginLeft: 40 }}>
                                                <div style={checkbox.checkboxItem}><input className="check-items" style={checkbox.checkboxInput} type="checkbox" value={item3._id} name={item3.name} /><label style={checkbox.checkboxLabel}>{item3.name}</label></div>
                                                {item3.children.map((item4) => {
                                                    return (<div style={{ marginLeft: 60 }}>
                                                        <div style={checkbox.checkboxItem}><input className="check-items" style={checkbox.checkboxInput} type="checkbox" value={item4._id} name={item4.name} /><label style={checkbox.checkboxLabel}>{item4.name}</label></div>
                                                    </div>)
                                                })}
                                            </div>)
                                        })}
                                    </div>)
                                })}
                            </div>);
                        })}
                    </div>


                    <div className="form-item checkbox-items w-full">
                        {this.state.output2}

                    </div>
                    <div className="w-full">
                        <Button color="primary" variant="contained" type="submit">{this.props.mode}</Button>
                    </div>


                </Formsy>

                <Dialog
                    open={this.state.open4}>
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                </Dialog>


            </div>
        )
    }
}
export default bookForm; 