import Add from "./Add";
import Edit from "./Edit";
import List from "./List";

import { authRoles } from 'app/auth';

export const storiesConfig = {
  settings: {
    layout: {
      config: {},
    },
  }, 
  routes: [
    {
      path: `${process.env.REACT_APP_FRONT_URL}stories/add`,
      component: Add,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}stories/:id/edit`,
      component: Edit,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}stories/list`,
      component: List,
    },
  ],
};
