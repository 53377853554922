import React,{useState,useEffect} from 'react';

import FusePageCarded from '@fuse/core/FusePageCarded';
import Breadcrumb from './../../helper/Breadcrumb';
import {useTranslation} from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import axios from 'axios';
import Button from '@material-ui/core/Button';

const baseLink = process.env.REACT_APP_BACKEND_URL;
const baseLink2 = process.env.REACT_APP_FRONTEND_URL;
const useStyles = makeStyles(theme => ({
	layoutRoot: {}
}));


const breadCurmbData = [
    {
        title:"Free books",
        link:""
    }
];
const tabV = ["ar","en","fr"];

function FreeBooks(props) {     

	const classes = useStyles(props);
    const {t} = useTranslation('teacher');
    const [tabValue,setTab] = useState(0);
    const [data,setData] = useState([]);
    const [freeBookAr,setFreeBookAr] = useState([])
    const [freeBookEn,setFreeBookEn] = useState([])
    const [freeBookFr,setFreeBookFr] = useState([])
    const [updateData,setupdateData] = useState(true)

    useEffect(() => {
        setData([]);

        axios({
            url:`${baseLink}/age_group/books`,
            method:"post",
            headers: {'Content-Type': 'application/json' },
            data:{language:tabV[tabValue]},    
            }).then((res) => {
                setData(res.data)
            });
    },[tabValue])

    useEffect(() => {

        axios({
            url:`${baseLink}/app/settings/get`,
            method:"post",
            headers: {'Content-Type': 'application/json' },
            data:{language:tabV[tabValue]},    
            }).then((res) => {
                setFreeBookAr(res.data.freeBookAr);
                setFreeBookFr(res.data.freeBookFr);
                setFreeBookEn(res.data.freeBookEn);
            });
 
    },[])
    const handleTab = (e, currentTab) => {

        setTab(currentTab);

    }

    const updateSelectedBook = (bookId,checked) => {

        if(tabV[tabValue] === "ar") {
            if(checked === true) {
                setFreeBookAr([
                    ...freeBookAr,
                    bookId
                ])
            } else {
                setFreeBookAr(freeBookAr.filter((item) => item !== bookId));
            }

        }

        if(tabV[tabValue] === "en") {
            if(checked === true) {
                setFreeBookEn([
                    ...freeBookEn,
                    bookId
                ])
            } else {
                setFreeBookEn(freeBookEn.filter((item) => item !== bookId));
            }
        }

        if(tabV[tabValue] === "fr") {

            if(checked === true) {
                setFreeBookFr([
                    ...freeBookFr,
                    bookId
                ])
            } else {
                setFreeBookFr(freeBookFr.filter((item) => item !== bookId));
            }
        }



    }

    const saveData = (e) => {
        axios({
            url:`${baseLink}/app/settings/updateFreeBooks`,
            method:"post",
            headers: {'Content-Type': 'application/json' },
            data:{
                freeBookAr:freeBookAr,
                freeBookEn:freeBookEn,
                freeBookFr:freeBookFr
            },    
            }).then((res) => {
                alert("Data Saved")
            });
        
    }

    return( 
        <FusePageCarded

        header={
            <div className="p-24">
                <Breadcrumb data={breadCurmbData} />
            </div>
        }
        contentToolbar={
            <div className="flex justify-between w-full items-center">
            <Tabs value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleTab}
            classes={{root: "w-full h-64 hide-print"}}>   
                <Tab className="h-64 normal-case" label={t("Arabic")} />
                <Tab className="h-64 normal-case" label={t("English")} />
                <Tab className="h-64 normal-case" label={t("French")} />
            </Tabs>
            <div style={{marginRight:20}}>
                <Button color="primary" variant="contained" onClick={saveData}>Save</Button>
            </div>
            </div>

        }
        content={
            <div className="p-48">
                {data.map((item) => {
                    return(<div className="cat-list">
                        <h2 style={{marginBottom:20,marginTop:50}}>{item.catData[0].nameEn}</h2>
                        <div className="cat-book-list" style={{display:"flex",alignItems:"center",flexWrap:"wrap",gap:"1%"}}>
                        {updateData === true && item.bookData.map((subItem) => {
                            
                            let find = -1
                            
                            if(tabValue === 0) {
                                find = freeBookAr.indexOf(subItem._id)
                            }
                            if(tabValue === 1) {
                                find = freeBookEn.indexOf(subItem._id)
                            }
                            if(tabValue === 2) {
                                find = freeBookFr.indexOf(subItem._id)
                            }

                            return(<div style={{width:"19%",position:"relative",marginBottom:"1%"}}>
                                <div style={{position:"absolute",left:10,top:10,right:"auto"}}><input defaultChecked={find !== -1?true:false} style={{width:20,height:20}} type="checkbox" onChange={(e) => {
                                    updateSelectedBook(subItem._id,e.target.checked)
                                }} /></div>
                                <img src={"https://school.kutubee.com:4000/" + subItem.coverPhoto} style={{border:"3px solid #51c0e5"}} />
                            </div>)
                        })}
                        </div>
                    </div>)
                })}
                {/* {tabValue === 0 && <ArabicBooks />}
                {tabValue === 1 && <EnglishBooks />}
                {tabValue === 2 && <FrenchBooks />} */}
            </div>
        }
        />
    )   
}

export default FreeBooks;