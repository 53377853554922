import React from 'react';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { Collapse } from '@material-ui/core';
//import DownloadData from './export'
import FusePageCarded from '@fuse/core/FusePageCarded';
import UploadFile from './../../helper/UploadFile'
import {Redirect} from 'react-router-dom';

const baseLink = process.env.REACT_APP_BACKEND_URL;
const baseLink2 = process.env.REACT_APP_FRONTEND_URL;

let breadcrumbAdd = [
    {
        title:"Book",
        link:"/book/list"
    },
    {
        title:"List",
        link:""
    }
]
class bookList extends React.Component { 
    state = {
        fileData:"",
        filePreview:"",
        direct:false
    }

    componentDidMount() {
        
    }

    submitFile = (files) => {

        this.setState({
            fileData:files[0],
            filePreview:URL.createObjectURL(files[0])
        })
    }
    clearFile = () => {
        this.setState({
            fileData:"",
            filePreview:""
        })
    }

    submit = () => {
        var bodyFormData = new FormData();
        

        this.setState({
            openPopup:true
        })

        bodyFormData.set("csv_file",this.state.fileData);
        axios.post(baseLink+'/book/update/import',bodyFormData,
        { 
            headers: {
                'Content-Type': 'multipart/form-data' 
            }
        })
        .then(res=>{
            this.setState({
                open:true,
                openPopup:false
            })
            setTimeout(() => {
                this.setState({
                    openPopup:false,
                    open:false
                })

                setTimeout(() => {
                    this.setState({
                        direct:true
                    });

                }, 1000);
            }, 1000);
            
        });
    }

    render() {
           

    

            return(
            <FusePageCarded 

            contentToolbar={
                    <div className="flex justify-between w-full p-12">
                        <div>Import book update</div>
                        <div>


                        </div>

                    </div>     
            }
            content={
                <div className="table-style p-24 w-full">

                    <div className="w-1/2">
                    <UploadFile type={"file"} value={this.state.filePreview} submit={this.submitFile} clear={this.clearFile} />

                    </div>
                    <div className="w-1/2 pt-24" align="right">
                    <Button onClick={this.submit} color="primary" variant="contained" disabled={this.state.filePreview === ""?true:false}>Submit</Button>

                    </div>
                    
                    {this.state.direct === true && <Redirect to="/books/list" />}

  <Dialog
        open={this.state.open}>
        <DialogContent>
            <div>Book imported successfully<br/></div>
        </DialogContent>
        </Dialog>

        <Dialog
        open={this.state.openPopup}>
        <DialogContent><CircularProgress /></DialogContent>
        </Dialog>
        </div>
            }
            />
           );
        
    }
}
export default bookList;