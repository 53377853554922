import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import Formsy from "formsy-react";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";
import clsx from 'clsx';
import Icon from '@material-ui/core/Icon';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SaveIcon from "@material-ui/icons/Save";

const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "Games",
    link: `${process.env.REACT_APP_FRONT_URL}games/list`,
  },
  {
    title: "Levels",
    link: `${process.env.REACT_APP_FRONT_URL}games/levelMemory/`,
  },
  {
    title: "Edit",
    link: "",
  },
];

class Add extends Component {
  state = {
    formValid: false,
    disabled: false,
    contentNumber: 4,
    thumbnail: '',
    titleEn: '',
    categoryData: [],
    assets: '',
    titleAr: ''

  };
  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
  };
  handleSubmit = (model) => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();

    formData.append("titleEn", this.state.titleEn);
    formData.append("titleAr", this.state.titleAr);
    formData.append("thumbnail", this.state.thumbnail);
    formData.append("contentNumber", this.state.contentNumber);
    formData.append("assets", this.state.assets);
    formData.append("id", this.props.match.params.id);

    axios({
      url: `${backend_url}/games/level/edit`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.props.history.push("/frontend/games/levelMemory");
    });
  };
  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };

  fileUpload2 = (e, id) => {
    let image = document.getElementById(id);
    image.src = URL.createObjectURL(e.target.files[0]);
    this.setState({
      thumbnail: e.target.files[0],
    });
  };

  componentDidMount() {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let id = this.props.match.params.id;

    let data = {
      id: id,
    };

    axios({
      url: `${backend_url}/games/level/get`,
      data: data,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState(res.data.result);
      //{process.env.REACT_APP_BACKEND_URL+'/public/'+this.state.singerImg}

      let image = document.getElementById('thumbnail');
      image.src = process.env.REACT_APP_BACKEND_URL + '/public/' + this.state.thumbnail;
    });
 

    axios({
      url: `${backend_url}/games/listAsstesMomary`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        categoryData: res.data.result,
      });
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="px-24">
            <h2>Edit Level</h2>
          </div>
        }
        content={
          <div className="p-24">
            <div className="max-w-lg">
              <Formsy
                onValidSubmit={this.handleSubmit}
                onValid={this.enableButton}
                onInvalid={this.disableButton}
                ref={"cateogry_add"}
                className="flex flex-col p6-12"
              >
                <div className="flex flex-row justify-between py-20">
                  <TextFieldFormsy
                    className="w-5/12"
                    type="text"
                    name="titleEn"
                    label="Title EN"
                    value={this.state.titleEn}
                    onChange={(e) => {
                      this.handleChange(e, "titleEn");
                    }}
                    required
                    variant="outlined"
                  />
                  <TextFieldFormsy
                    className="w-5/12"
                    type="text"
                    name="titleAr"
                    label="Title AR"
                    required
                    value={this.state.titleAr}
                    variant="outlined"
                    onChange={(e) => {
                      this.handleChange(e, "titleAr");
                    }}
                  />
                </div>
                <div className="flex flex-row justify-between py-20">

                  <SelectFormsy
                    className="w-5/12"
                    name="number"
                    label="Content number"
                    value={this.state.contentNumber}
                    onChange={(e) => {
                      this.handleChange(e, "contentNumber");
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="4">4 </MenuItem>
                    <MenuItem value="6">6 </MenuItem>
                    <MenuItem value="8">8 </MenuItem>
                    <MenuItem value="10">10 </MenuItem>
                    <MenuItem value="12">12 </MenuItem>

                  </SelectFormsy>

                  <SelectFormsy
                    className="w-5/12"
                    name="Assets"
                    label="Assets"
                    value={this.state.assets}
                    onChange={(e) => {
                      this.handleChange(e, "assets");
                    }}
                    variant="outlined"
                  >
                    {this.state.categoryData.map(item =>
                      <MenuItem value={item._id}>{item.titleEn} - {item.titleAr}</MenuItem>
                    )}
                  </SelectFormsy>
                </div>
                <div className="flex flex-row justify-between py-20">
                  <div className="flex justify-between items-center w-5/12">
                    <Button variant="contained" component="label">
                      upload thumbnail
                      <input
                        accept="image/png"
                        type="file"
                        onChange={(e) => {
                          this.fileUpload2(e, "thumbnail");
                        }}
                        style={{ display: "none" }}
                      />
                    </Button>
                    <img
                      alt=""
                      id="thumbnail"
                      title="thumbnail"
                      style={{ maxWidth: "40%" }}
                      src="frontend/assets/images/custom/empty-image-thumb.png"
                    />
                  </div>
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  className="ml-auto my-16"
                  disabled={!this.state.formValid}
                  type="submit"
                  variant="contained"
                >
                  edit
                </Button>
              </Formsy>
            </div>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(Add);
