import Add from "./Add"; 
import Focus from "./Focus"; 

export const applicationBackgroundsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${process.env.REACT_APP_FRONT_URL}application-backgrounds`,
      component: Add,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}application-focus`,
      component: Focus,
    },
  ],
};
