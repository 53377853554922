import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio, Select } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import Checkbox from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Formsy from "formsy-react";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";

const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "Application Backgrounds",
    link: ``,
  },
];

class Add extends Component {
  state = {
    formValid: false,
    disabled: false,
    headerType: 'color',
    hexcode: '#fff',
    hexcode2: '#fff',
    headerImage: '',
    bodyImage: '',
    alertOpen: false,
    bodyType: 'color',
  };
  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
  };
  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.checked,
    });
  };
  handleSubmit = (model) => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();

    formData.append("headerType", this.state.headerType);
    formData.append("bodyType", this.state.bodyType);
    formData.append("focus", this.state.focus);

    if (this.state.headerType == 'color') {
      formData.append("headerColor", this.state.hexcode);
    }
    else {
      formData.append("headerImage", this.state.headerImage);
    }


    if (this.state.bodyType == 'color') {
      formData.append("bodyColor", this.state.hexcode2);
    }
    else {
      formData.append("bodyImage", this.state.bodyImage);
    }

    axios({
      url: `${backend_url}/applicationBackgrounds/update`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      if (res.data.message)
        this.setState({ alertOpen: true })

      setTimeout(() => {
        this.setState({ alertOpen: false })
      }, 3000);
    });
  };

  componentDidMount() {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    axios({
      url: `${backend_url}/applicationBackgrounds/get`,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.setState(res.data.message)
      let image1 = document.getElementById('bodyImage');
      let image2 = document.getElementById('headerImage');
      if (res.data.message.headerType == 'image') {
        image2.src = res.data.message.headerImage;
      }
      else {
        document.getElementById("hexcode").value = res.data.message.headerColor;
      }
      if (res.data.message.bodyType == 'image') {
        image1.src = res.data.message.bodyImage;
      }
    });
  }
  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };

  fileUpload = (e, id) => {
    let image = document.getElementById(id);
    image.src = URL.createObjectURL(e.target.files[0]);
    this.setState({
      [id]: e.target.files[0],
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="w-full flex items-center justify-between p-24">
            <h2>Application Backgrounds</h2>
            <div className="flex items-center">

            </div>
          </div>


        }
        content={
          <div className="p-24">
            <div className="max-w-lg">
              <Formsy
                onValidSubmit={this.handleSubmit}
                onValid={this.enableButton}
                onInvalid={this.disableButton}
                ref={"cateogry_add"}
                className="flex flex-col p6-12"
              >

                <div style={{
                  color: '#000',
                  backgroundColor: '#ddffdd',
                  border: '1px solid #ccc',
                  padding: '1vw',
                  visibility: this.state.alertOpen ? 'visible' : 'hidden',
                  opacity: this.state.alertOpen ? '1' : '0',
                  transition: 'opacity 1s'

                }}>
                  <h3>Saved successfully!</h3>
                </div>



                <div className="flex flex-row justify-between py-10">
                  <SelectFormsy
                    className="w-5/12"
                    name="artist"
                    label="Header background type"
                    value={this.state.headerType}
                    onChange={(e) => {
                      this.handleChange(e, "headerType");
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="color">Color</MenuItem>
                    <MenuItem value="image">Image</MenuItem>

                  </SelectFormsy>

                  {this.state.headerType == 'color' ?
                    <input
                      type="color"
                      value={this.state.hexcode || '#fff'}
                      id="hexcode"
                      onChange={(e) => {
                        this.handleChange(e, "hexcode");
                      }}
                    />

                    :
                    <Button variant="contained" component="label">
                      Choose other image
                      <input
                        accept="image/x-png,image/gif,image/jpeg"
                        type="file"
                        onChange={(e) => {
                          this.fileUpload(e, "headerImage");
                        }}
                        style={{ display: "none" }}
                      />
                    </Button>

                  }
                </div>

                {this.state.headerType == 'image' && (
                  <div className="flex flex-row justify-between py-20">
                    <img
                      alt=""
                      id="headerImage"
                      title="headerImage"
                      style={{ maxWidth: "50%" }}
                      src=""
                    />
                  </div>
                )}


                <div className="flex flex-row justify-between py-10">
                  <SelectFormsy
                    className="w-5/12"
                    name="artist"
                    label="Body background type"
                    value={this.state.bodyType}
                    onChange={(e) => {
                      this.handleChange(e, "bodyType");
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="color">Color</MenuItem>
                    <MenuItem value="image">Image</MenuItem>

                  </SelectFormsy>

                  {this.state.bodyType == 'color' ?
                    <input
                      type="color"
                      id="hexcode2"
                      value={this.state.hexcode2}
                      onChange={(e) => {
                        this.handleChange(e, "hexcode2");
                      }}
                    />

                    :
                    <Button variant="contained" component="label">
                      Choose other image
                      <input
                        accept="image/x-png,image/gif,image/jpeg"
                        type="file"
                        onChange={(e) => {
                          this.fileUpload(e, "bodyImage");
                        }}
                        style={{ display: "none" }}
                      />
                    </Button>

                  }
                </div>

                {this.state.bodyType == 'image' && (
                  <div className="flex flex-row justify-between py-20">
                    <img
                      alt=""
                      id="bodyImage"
                      title="bodyImage"
                      style={{ maxWidth: "50%" }}
                      src=""
                    />
                  </div>
                )}





                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="ml-auto my-16"
                  aria-label="Add"
                  disabled={!this.state.formValid}
                >
                  Save
                </Button>


              </Formsy>
            </ div>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(Add);
