import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded, FuseAnimateGroup } from "@fuse";
import Button from "@material-ui/core/Button";
import { TextFieldFormsy } from "@fuse";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from '@material-ui/core/Switch';

import Paper from "@material-ui/core/Paper";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";
import { Link } from "react-router-dom";
import Popup from "../components/popup";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FormatListNumbered from "@material-ui/icons/FormatListNumbered";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Avatar from "@material-ui/core/Avatar";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { MenuItem, Select, TextField } from "@material-ui/core";
const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "Games",
    link: `${process.env.REACT_APP_FRONT_URL}games/list`,
  },
  {
    title: "Coloring game",
    link: "",
  },
  {
    title: "Levels",
    link: "",
  },
];
class List extends Component {
  state = {
    open: false,
    formValid: false,
    rowsPerPage: 5,
    page: 0,
    data: [],
    categoryData: [],
    idremove: "0",
  };

  handleSubmit = (model) => { };
  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };
  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };
  handleChangeRowsPerPage = () => { };
  sortData = (name) => {
    let data = this.state.data;

    data = data.sort(function (a, b) {
      if (a[name] < b[name]) {
        return -1;
      }
      if (a[name] > b[name]) {
        return 1;
      }
      return 0;
    });

    this.setState({
      data: data,
    });
  };
  componentDidMount() {
    let backend_url = process.env.REACT_APP_BACKEND_URL;

    axios({
      url: `${backend_url}/games/coloring/list`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        categoryData: res.data.result,
      });
    });
  }

  handleDelete = () => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    formData.append("id", this.state.idremove);
    axios({
      url: `${backend_url}/games/coloring/delete`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.componentDidMount();
      this.setState({
        open: false,
      });
    });
  };
  handleChangeIspaid = (e, id) => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    formData.append("itemid", id);
    formData.append("type", 'coloring_levels');
    formData.append("isPaid", e.target.checked);

    axios({
      url: `${backend_url}/content/changeisPaid`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.componentDidMount();
    });

  };


  upwardMethod = (id, order, backID, backOrder) => {

    console.log(id, order, backID, backOrder)
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    formData.append("id", id);
    formData.append("order", order + 1);
    formData.append("backID", backID);
    formData.append("type", 'coloring_levels');
    formData.append("backOrder", backOrder - 1);
    axios({
      url: `${backend_url}/orderGames/upward`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.componentDidMount();


    });
  }



  downwardMethod = (id, order, nextID, nextOrder) => {
    console.log(id, order, nextID, nextOrder)
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    formData.append("id", id);
    formData.append("order", order - 1);
    formData.append("nextID", nextID);
    formData.append("type", 'coloring_levels');
    formData.append("nextOrder", nextOrder + 1);
    axios({
      url: `${backend_url}/orderGames/downward`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.componentDidMount();
    });
  }
  pluscount = (index) => {
    let newindex = index + 1;
    return newindex;
  }
  minuscount = (index) => {
    let bacindex = index - 1;
    return bacindex;
  }

  handleChangeCustomId = (e, id)=>{
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    formData.append("id", id); 
    formData.append("custom_id", e.target.value); 
    axios({
      url: `${backend_url}/games/changeCustomId `,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.componentDidMount();
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="w-full flex items-center justify-between p-24">
            <h2>Coloring Game Levels</h2>
            <div className="flex items-center">
              <Link to={`${process.env.REACT_APP_FRONT_URL}games/levelcoloring/add`}>
                <Fab color="primary" aria-label="add">
                  <AddIcon />
                </Fab>
              </Link>
            </div>
          </div>
        }
        content={
          <div className="p-24">
            <FuseAnimateGroup
              className="flex flex-wrap"
              enter={{
                animation: "transition.slideUpBigIn",
              }}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>

                      <TableCell align="left">Ordering</TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell align="left">Title EN</TableCell>
                      <TableCell align="left">Title AR</TableCell>
                      <TableCell align="left">thumbnail</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.categoryData
                      .slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                      )
                      .map((row, index, ahmad) => (
                        <TableRow key={row._id}>
                          <TableCell align="left">{(index + 1)} &nbsp;

                            {(index + 1) > 1 && (
                              <ArrowUpward style={{
                                cursor: 'pointer'
                              }} fontSize="small"
                                onClick={() => {
                                  let backValId = index == 0 ? 0 : ahmad[this.minuscount(index)]._id
                                  let backValOrder = index == 0 ? 0 : ahmad[this.minuscount(index)].created
                                  this.upwardMethod(row._id, row.created, backValId, backValOrder)
                                }}
                              />
                            )}

                            {(index + 1) != Object.keys(this.state.categoryData).length && (
                              <ArrowDownward style={{
                                cursor: 'pointer'
                              }} fontSize="small"
                                onClick={() => {
                                  let nextValId = (index + 1) == Object.keys(this.state.categoryData).length ? 0 : ahmad[this.pluscount(index)]._id
                                  let nextValOrder = (index + 1) == Object.keys(this.state.categoryData).length ? 0 : ahmad[this.pluscount(index)].created
                                  this.downwardMethod(row._id, row.created, nextValId, nextValOrder)
                                }}
                              />
                            )}

                          </TableCell>

                          <TableCell align="left">
                            <Select
                              className="w-8/12"
                              name="ageRate"
                              label="Age Rate"
                              value={row.custom_id != undefined ? row.custom_id :''}
                              onChange={(e) => {
                                this.handleChangeCustomId(e, row._id);
                              }}
                              variant="outlined"
                            >
                              <MenuItem value="Bear">Bear</MenuItem>
                              <MenuItem value="Koala">Koala</MenuItem>
                              <MenuItem value="Dinosaur">Dinosaur</MenuItem>
                              <MenuItem value="Fish">Fish</MenuItem>
                              <MenuItem value="Unicorn">Unicorn</MenuItem>
                              <MenuItem value="Elephant">Elephant</MenuItem>
                              <MenuItem value="CandyCity">CandyCity</MenuItem>
                              <MenuItem value="Builder">Builder</MenuItem>
                              <MenuItem value="Fruits">Fruits</MenuItem>
                              <MenuItem value="Snake">Snake</MenuItem>
                              
                            </Select>
                          </TableCell>
                          <TableCell align="left">{row.titleEn}</TableCell>
                          <TableCell align="left">{row.titleAr}</TableCell>
                          <TableCell align="left">
                            <img
                              style={
                                {
                                  width: '5vw',
                                }
                              }
                              src={
                                process.env.REACT_APP_BACKEND_URL +
                                "/public/" +
                                row.thumbnail
                              } />

                          </TableCell>
                          <TableCell align="left">
                            {/* <Link
                              to={`${process.env.REACT_APP_FRONT_URL}games/${row._id}/levelwhackamole`}
                            >
                              <IconButton
                                aria-label="delete"
                                className={classes.margin}
                              >
                                <EditIcon fontSize="large" />
                              </IconButton>
                            </Link> */}
                            <FormControlLabel
                              control={<Switch checked={row.isPaid}

                                color="primary"
                                onChange={(e) => {
                                  this.handleChangeIspaid(e, row._id);
                                }}
                                name="isPaid" />}
                              label="Exclusive"
                              labelPlacement="left"

                            />
                            <IconButton
                              aria-label="delete"
                              className={classes.margin}
                              onClick={() => {
                                this.setState({
                                  open: true,
                                  idremove: row._id,
                                  removeThum: row.thumbnail
                                });
                              }}
                            >
                              <DeleteIcon fontSize="large" />
                            </IconButton>



                            <Dialog
                              open={this.state.open}
                              keepMounted
                              aria-labelledby="alert-dialog-slide-title"
                              aria-describedby="alert-dialog-slide-description"
                            >
                              <DialogTitle id="alert-dialog-slide-title">
                                <DeleteIcon fontSize="large" />{" "}
                                {" Are you sure you want to delete it?"}
                              </DialogTitle>
                              <DialogActions>
                                <Button
                                  onClick={() => {
                                    this.setState({
                                      open: false,
                                    });
                                  }}
                                  color="primary"
                                >
                                  No
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    this.handleDelete();
                                  }}
                                >
                                  OK
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  component="div"
                  count={this.state.categoryData.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={this.handleChangePage}
                />
              </TableContainer>
            </FuseAnimateGroup>
            <Popup />
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(List);
