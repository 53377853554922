import FusePageCarded from '@fuse/core/FusePageCarded';
import withReducer from 'app/store/withReducer';
import FuseAnimate from '@fuse/core/FuseAnimate';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import axios from "axios";

import TableHead from './TableHead';
import { useParams } from "react-router-dom";

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';

import { makeStyles } from '@material-ui/core/styles';


import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { setDate } from 'date-fns';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));


const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});




function RowList() {
  let history = useHistory();
  const classes = useStyles();

  const dispatch = useDispatch();
  const { id } = useParams();

  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);
  const [dataF, setDataF] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = React.useState(false);

  const [typeInput, setTypeInput] = React.useState('');
  const [fileValue, setFileValue] = React.useState('');

  const [btnText, setbtnText] = React.useState('Seclect CSV file');


  const [textAlert, setTextAlert] = React.useState("");
  const [typeAlert, setTypeAlert] = React.useState("error");
  const [alertShow, setAlertShow] = React.useState(false);

  const [typeFilter, setTypeFilter] = useState('all');
  const [typeFilter2, setTypeFilter2] = useState('all');

  const [order, setOrder] = useState({
    direction: 'asc',
    id: null
  });

  function handleSelectedCategory(event) {
    setTypeFilter(event.target.value);
  }
  function handleSelectedCategory2(event) {
    setTypeFilter2(event.target.value);
  }

  useEffect(() => {
    function getFilteredArray() {
      let usedValue = typeFilter2 == 'used' ? true : false;
      if (typeFilter === 'all' && typeFilter2 === 'all') {
        return data;
      }

      return _.filter(data, item => {
        if (typeFilter !== 'all' && (item.type !== typeFilter || item.used !== usedValue)) {
          return false;
        }
        return item;
      });
    }

    if (data) {
      setDataF(getFilteredArray());
    }
  }, [data, typeFilter, typeFilter2]);




  useEffect(() => {
    loaddata()
  }, []);






  function loaddata() {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData(); 
    formData.append("platform", 'android');
    axios({
      url: `${backend_url}/promoCode/list`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setData(res.data.result)
    });

  }

  function handleRequestSort(event, property) {
    const id = property;
    let direction = 'desc';

    if (order.id === property && order.direction === 'desc') {
      direction = 'asc';
    }

    setOrder({
      direction,
      id
    });
  }


  function handleClick(item) {
    // props.history.push(`/apps/e-commerce/products/${item.id}/${item.handle}`);
  }

  function handleCheck(event, id) {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, value) {
    setPage(value);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
  }

  function impotFile() {
    if (typeInput.length != 0) {
      setLoading(true)
      let backend_url = process.env.REACT_APP_BACKEND_URL;
      let formData = new FormData();

      formData.append("fileCSV", fileValue);
      formData.append("typeInput", typeInput);
      formData.append("platform", 'android');


      axios({
        url: `${backend_url}/promoCode/import`,
        data: formData,
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        if (res.data.feedback) {
          setLoading(false)
          loaddata()
          setTextAlert('The file was imported successfully')
          setTypeAlert("success")
          setAlertShow(true)
          setOpen(false);
          setbtnText('Seclect CSV file')
        }
      });
    }
    else {
      setTextAlert('Please choose the type of file you want to Import')
      setTypeAlert("error")
      setAlertShow(true)
    }

  }

  function fileUpload(e) {
    setFileValue(e.target.files[0])
    setbtnText(e.target.files[0].name)


  }

  const handleChange = (e, name) => {
    setTypeFilter(e.target.value);

    if (name == 'filterCategories') {
      // console.log(data)
      if (e.target.value == 'all') {
        setDate(data)
      }
      else {
        const filterbydrop = data.filter((list) => list.typeContent === e.target.value);
        this.setState({ genreData: filterbydrop });
      }

    }
    if (name == 'filterCategories2') {
      const { data } = this.state;
      // console.log(data)
      if (e.target.value == 'all') {
        this.setState({ genreData: data });
      }
      else {
        const filterbydrop = data.filter((list) => list.type === e.target.value);
        this.setState({ genreData: filterbydrop });
      }

    }
  };


  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const prettyDate = (date) => {

    var d = new Date(date);

    return d.toDateString() + " - " + d.toLocaleTimeString();
  }
  const mainTheme = useSelector(({ fuse }) => fuse.settings.mainTheme);
  return (
    <FusePageCarded
      classes={{
        content: 'flex',
        header: 'min-h-72 h-72 sm:h-136 sm:min-h-136'
      }}
      header={
        <div className="flex flex-1 w-full items-center justify-between">
          <div className="flex items-center w-full ">
            <FuseAnimate animation="transition.expandIn" delay={300}>
              <Icon className="text-32">code</Icon>
            </FuseAnimate>
            <FuseAnimate animation="transition.slideLeftIn" delay={300}>
              <Typography className="hidden sm:flex mx-0 sm:mx-12" variant="h6">
                Promo Codes / Android
               </Typography>

            </FuseAnimate>

          </div>


          <div className="flex flex-1 w-full items-center justify-between">
            <FormControl className="flex w-full sm:w-320 mx-16" variant="outlined">
              <InputLabel htmlFor="category-label-placeholder"> Status </InputLabel>
              <Select
                value={typeFilter2}
                onChange={handleSelectedCategory2}
                input={
                  <OutlinedInput
                    labelWidth={'category'.length * 9}
                    name="category"
                    id="category-label-placeholder"
                  />
                }
              >
                <MenuItem value="all">
                  <em> All </em>
                </MenuItem>
                <MenuItem value='used' key={1}>Used</MenuItem>
                <MenuItem value='unused' key={2}>Unused</MenuItem>


              </Select>

            </FormControl>
            <FormControl className="flex w-full sm:w-320 mx-16" variant="outlined">
              <InputLabel htmlFor="category-label-placeholder"> Promo code Type </InputLabel>
              <Select
                value={typeFilter}
                onChange={handleSelectedCategory}
                input={
                  <OutlinedInput
                    labelWidth={'category'.length * 9}
                    name="category"
                    id="category-label-placeholder"
                  />
                }
              >
                <MenuItem value="all">
                  <em> All </em>
                </MenuItem>
                <MenuItem value='Basic-Monthly' key={1}>Basic-Monthly</MenuItem>
                <MenuItem value='Basic-Annualy' key={2}>Basic-Annualy</MenuItem>
                <MenuItem value='Premium-Monthly' key={3}>Premium-Monthly</MenuItem>
                <MenuItem value='Premium-Annualy' key={4}>Premium-Annualy</MenuItem>

              </Select>

            </FormControl>
          </div>
          <div>


            <FuseAnimate animation="transition.slideRightIn" delay={300}>
              <Button variant="contained" component="label" onClick={handleClickOpen}>
                Import
              </Button>
            </FuseAnimate>
          </div>


        </div>

      }
      content={
        <div className="w-full flex flex-col">

          <Snackbar open={alertShow} autoHideDuration={6000} onClose={() => setAlertShow(false)}>
            <Alert onClose={() => setAlertShow(false)} severity={typeAlert}>
              {textAlert}
            </Alert>
          </Snackbar>
          <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              </DialogTitle>
              <DialogContent dividers>

                <Typography gutterBottom>
                  <FormControl className="flex w-full sm:w-320 mx-16" variant="outlined">
                    <InputLabel htmlFor="category-label-placeholder"> Promo code Type </InputLabel>
                    <Select
                      value={typeInput}
                      onChange={(e) => {
                        setTypeInput(e.target.value);
                      }}
                      input={
                        <OutlinedInput
                          labelWidth={'category'.length * 9}
                          name="category"
                          id="category-label-placeholder"
                        />
                      }
                    >

                      <MenuItem value='Basic-Monthly' key={1}>Basic-Monthly</MenuItem>
                      <MenuItem value='Basic-Annualy' key={2}>Basic-Annualy</MenuItem>
                      <MenuItem value='Premium-Monthly' key={3}>Premium-Monthly</MenuItem>
                      <MenuItem value='Premium-Annualy' key={4}>Premium-Annualy</MenuItem>

                    </Select>
                    <br></br>
                    <Button variant="contained" component="label">
                      <CloudUploadIcon />&nbsp;	&nbsp;	{btnText}
                      <input
                        type="file"
                        accept=".csv"
                        onChange={(e) => {
                          fileUpload(e);
                        }}
                        style={{ display: "none" }}
                      />
                    </Button>

                    <br></br>
                    <Button variant="contained" component="label" onClick={(e) => impotFile()}>
                      Import
                    </Button>
                  </FormControl>
                </Typography>
              </DialogContent>

            </Dialog>
          </div>


          <Backdrop className={classes.backdrop} open={loading} onClick={' '}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <FuseScrollbars className="flex-grow overflow-x-auto">
            <Table className="min-w-xl" aria-labelledby="tableTitle">
              <TableHead
                numSelected={selected.length}
                order={order}
                onRequestSort={handleRequestSort}
                rowCount={dataF.length}
              />

              <TableBody>
                {_.orderBy(
                  dataF,
                  [
                    o => {
                      switch (dataF._id) {
                        case 'id': {
                          return o;
                        }
                        default: {
                          return o[dataF._id];
                        }
                      }
                    }
                  ],
                  [dataF._id]
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(n => {
                    const isSelected = selected.indexOf(n._id) !== -1;
                    return (
                      <TableRow
                        className="h-64 cursor-pointer"
                        hover
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={isSelected}
                        onClick={event => handleClick(n)}
                      >
                        <TableCell className="w-64 text-center" padding="none">

                        </TableCell>



                        <TableCell component="th" scope="row">

                          {
                            n.code
                          }
                        </TableCell>

                        <TableCell className="truncate" component="th" scope="row">
                          {
                            n.short
                          }
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {
                            n.used ? 'Used' : 'Unused'
                          }
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {
                            n.type
                          }
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {
                            prettyDate(n.create)
                          }
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {
                            n.used ? prettyDate(n.update) : 'no update'
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </FuseScrollbars>

          <TablePagination
            className="overflow-hidden"
            component="div"
            count={dataF.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />

        </div>


      }
      innerScroll
    />
  );
}

export default RowList;
