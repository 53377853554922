import FuseAnimateGroup from '@fuse/core/FuseAnimateGroup';
import FusePageSimple from '@fuse/core/FusePageSimple';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import withReducer from 'app/store/withReducer';
import clsx from 'clsx';
import _ from '@lodash';
import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import Android from '@material-ui/icons/Android';

import { useDispatch, useSelector } from 'react-redux';
// import * as Actions from './store/actions';
// import reducer from './store/reducers';
import Widget1 from './widgets/Widget1';
import Widget10 from './widgets/Widget10';
import Widget11 from './widgets/Widget11';
import Widget2 from './widgets/Widget2';
import Widget3 from './widgets/Widget3';
import Widget4 from './widgets/Widget4';
import Widget5 from './widgets/Widget5';
import Widget6 from './widgets/Widget6';
import Widget7 from './widgets/Widget7';
import ContentTop from './widgets/contentTop';
import Favorite from './widgets/Favorite';

import Widget8 from './widgets/Widget8';
import Widget9 from './widgets/Widget9';
import WidgetNow from './widgets/WidgetNow';
import WidgetCount from './widgets/WidgetCount';
import axios from "axios";
import LoadingDiv from "@fuse/core/FuseLoading/FuseLoading";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  content: {
    '& canvas': {
      maxHeight: '100%'
    }
  },
  selectedProject: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '8px 0 0 0'
  },
  projectMenuButton: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '0 8px 0 0',
    marginLeft: 1
  }
}));

function Dashboard() {

  const dispatch = useDispatch();
  // const widgets = useSelector(({ projectDashboardApp }) => projectDashboardApp.widgets);
  // const projects = useSelector(({ projectDashboardApp }) => projectDashboardApp.projects);
  const [counter, setCounter] = useState([]);
  const user = useSelector(({ auth }) => auth.user);
  const classes = useStyles();
  const pageLayout = useRef(null);
  const [tabValue, setTabValue] = useState(0);


  const [favoriteInput, setFavoriteInput] = useState('songs');
  const [downloadableInput, setdownloadableInput] = useState('songs');



  const [loadingAll, setLoadingAll] = React.useState(false);

  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedDate2, setSelectedDate2] = React.useState(null);



  const [selectedDateSubscribers, setSelectedDateSubscribers] = React.useState(null);
  const [selectedDateSubscribers2, setSelectedDateSubscribers2] = React.useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    enableFilter4(true);
  };






  const [filterByDate4, setFilterByDate4] = useState(false);

  const [gender, setGender] = useState([]);
  const [devicesinfo, setDevicesinfo] = useState([]);

  const [Typesofusers, setTypesofusers] = useState(null);
  const [bdage, setBdage] = useState([]);
  const [infoversion, setinfoversion] = useState([]);


  const [lang, setLang] = useState([]);

  const [topSongs, setTopSongs] = useState([]);
  const [topShows, setTopShows] = useState([]);
  const [topStories, setTopStories] = useState([]);
  const [games, setGames] = useState([]);

  const [favorite, setFavorite] = useState([]);

  const [downloadable, setdownloadable] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [playlist, setPlaylist] = useState(0);




  const [country, setCountry] = useState([]);
  const [topCities, settopCities] = useState([]);


  const [CompletedSongs, setCompletedSongs] = useState([]);
  const [CompletedShows, setCompletedShows] = useState([]);
  const [CompletedStories, setCompletedStories] = useState([]);
  const [screenview, setScreenview] = useState([]);

  const [platform, setPlatform] = useState([]);
  const [usersPlanCount, setusersPlanCount] = useState(null);
  const [usersPlanCount2, setusersPlanCount2] = useState(null);
  const [usersPlanCount3, setusersPlanCount3] = useState(null);
  const [usersPlanCount4, setusersPlanCount4] = useState(null);

  const [devicecategory, setDevicecategory] = useState([]);

  const [uninstalled, setUninstalled] = useState(0);
  const [clickOnHome, setClickOnHome] = useState(0);
  const [videoLocked, setVideoLocked] = useState(0);
  const [repeatButtonOn, setRepeatButtonOn] = useState(0);
  const [menuThreeDotsClicked, setmenuThreeDotsClicked] = useState(0);
  const [settingsButtonClicked, setsettingsButtonClicked] = useState(0);
  const [SubscriptionPlanEvents, setSubscriptionPlanEvents] = useState(0);

  const [selectedProject, setSelectedProject] = useState({
    id: 1,
    menuEl: null
  });



  //users
  useEffect(() => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    setLoading2(true)

    if (tabValue === 0) {
      //////11111

      axios({
        url: `${backend_url}/statistics/Typesofusers`,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        // console.log(res.data.result)
        setTypesofusers(res.data.result)
      });


      //////222222

      let regionandcountry2 = new FormData();
      regionandcountry2.append("type", 'region');
      axios({
        url: `${backend_url}/statistics/regionandcountry`,
        data: regionandcountry2,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        settopCities(res.data.result)
      });

      let regionandcountry = new FormData();
      regionandcountry.append("type", 'country');
      axios({
        url: `${backend_url}/statistics/regionandcountry`,
        data: regionandcountry,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setCountry(res.data.result)
        setLoading2(false)
      });



      axios({
        url: `${backend_url}/statistics/gender`,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        // console.log(res.data.result)
        setGender(res.data.result)
      });

      axios({
        url: `${backend_url}/statistics/getbdage`,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        // console.log(res.data.result)
        setBdage(res.data.result)
      });

      axios({
        url: `${backend_url}/statistics/lang`,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        // console.log(res.data.result)
        setLang(res.data.result)
      });
      ////////////////////////////end
    }

    else if (tabValue === 1) {
      let formData12 = new FormData();
      formData12.append("type", 'songs');
      formData12.append("type2", 'started');
      axios({
        url: `${backend_url}/bigquery/get`,
        data: formData12,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setTopSongs(res.data.result)
      });
      //////
      let formData1 = new FormData();
      formData1.append("type", 'shows');
      formData1.append("type2", 'started');
      axios({
        url: `${backend_url}/bigquery/get`,
        data: formData1,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setTopShows(res.data.result)
      });
      //////
      let formData2 = new FormData();
      formData2.append("type", 'stories');
      formData2.append("type2", 'started');
      axios({
        url: `${backend_url}/bigquery/get`,
        data: formData2,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setTopStories(res.data.result)
      });
      //////11111
      let formcompleted = new FormData();
      formcompleted.append("type", 'songs');
      formcompleted.append("type2", 'completed');
      axios({
        url: `${backend_url}/bigquery/get`,
        data: formcompleted,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setCompletedSongs(res.data.result)
      });
      ////
      let formcompleted1 = new FormData();
      formcompleted1.append("type", 'shows');
      formcompleted1.append("type2", 'completed');
      axios({
        url: `${backend_url}/bigquery/get`,
        data: formcompleted1,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setCompletedShows(res.data.result)
      });
      //////
      let formcompleted2 = new FormData();
      formcompleted2.append("type", 'stories');
      formcompleted2.append("type2", 'completed');
      axios({
        url: `${backend_url}/bigquery/get`,
        data: formcompleted2,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setCompletedStories(res.data.result)
        setLoading2(false)
      });
    }

    else if (tabValue === 2) {
      axios({
        url: `${backend_url}/statistics/gmaes`,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        // console.log(res.data.rows)
        setLoading2(false)
        setGames(res.data.rows)
      });
    }

    else if (tabValue === 3) {
      axios({
        url: `${backend_url}/statistics/devicecategory`,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setDevicecategory(res.data.result)
      });
      axios({
        url: `${backend_url}/statistics/uninstalled`,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setUninstalled(res.data.length)
      });
      axios({
        url: `${backend_url}/statistics/platform`,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        // console.log(res.data.result)
        setPlatform(res.data.rows)
      });

      axios({
        url: `${backend_url}/statistics/devicesinfo`,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setDevicesinfo(res.data.rows)
      });

      axios({
        url: `${backend_url}/statistics/infoversion`,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        // console.log(res.data.result)
        setinfoversion(res.data.result)
        setLoading2(false)
      });
    }
    else if (tabValue === 4) {
      let formfavorite = new FormData();
      formfavorite.append("type", favoriteInput);
      formfavorite.append("type2", 'AddToFavorite');
      axios({
        url: `${backend_url}/statistics/favorite`,
        data: formfavorite,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setFavorite(res.data.result)
        setLoading(false)
      });
      let formfavorite2 = new FormData();
      formfavorite2.append("type", downloadableInput);
      formfavorite2.append("type2", 'videoDownloaded');
      axios({
        url: `${backend_url}/statistics/favorite`,
        data: formfavorite2,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setdownloadable(res.data.result)
        setLoading(false)
        setLoadingAll(false)
      });


      axios({
        url: `${backend_url}/statistics/clickOnHome`,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setClickOnHome(res.data.sum)
      });
  
      let getCounter = new FormData();
      getCounter.append("event_name", 'videoLocked');
      axios({
        url: `${backend_url}/statistics/getCounterFrombig`,
        data: getCounter,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setVideoLocked(res.data.sum)
      });
      let getCounter2 = new FormData();
      getCounter2.append("event_name", 'repeatButtonOn');
      axios({
        url: `${backend_url}/statistics/getCounterFrombig`,
        data: getCounter2,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setRepeatButtonOn(res.data.sum)
      });
      let getCounter3 = new FormData();
      getCounter3.append("event_name", 'menuThreeDotsClicked');
      axios({
        url: `${backend_url}/statistics/getCounterFrombig`,
        data: getCounter3,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setmenuThreeDotsClicked(res.data.sum)
      });
      let getCounter4 = new FormData();
      getCounter4.append("event_name", 'settingsButtonClicked');
      axios({
        url: `${backend_url}/statistics/getCounterFrombig`,
        data: getCounter4,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setsettingsButtonClicked(res.data.sum)
        setLoading2(false)
      });
    }
  }, [tabValue])





  useEffect(() => {
    return
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    axios({
      url: `${backend_url}/statistics/playlist`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setPlaylist(res.data.sum)
    });
    let regionandcountry = new FormData();
    regionandcountry.append("type", 'country');
    axios({
      url: `${backend_url}/statistics/regionandcountry`,
      data: regionandcountry,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setCountry(res.data.result)
    });
    let regionandcountry2 = new FormData();
    regionandcountry2.append("type", 'region');

    axios({
      url: `${backend_url}/statistics/regionandcountry`,
      data: regionandcountry2,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      settopCities(res.data.result)
    });
    axios({
      url: `${backend_url}/statistics/clickOnHome`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setClickOnHome(res.data.sum)
    });

    let getCounter = new FormData();
    getCounter.append("event_name", 'videoLocked');
    axios({
      url: `${backend_url}/statistics/getCounterFrombig`,
      data: getCounter,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setVideoLocked(res.data.sum)
    });
    let getCounter2 = new FormData();
    getCounter2.append("event_name", 'repeatButtonOn');
    axios({
      url: `${backend_url}/statistics/getCounterFrombig`,
      data: getCounter2,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setRepeatButtonOn(res.data.sum)
    });
    let getCounter3 = new FormData();
    getCounter3.append("event_name", 'menuThreeDotsClicked');
    axios({
      url: `${backend_url}/statistics/getCounterFrombig`,
      data: getCounter3,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setmenuThreeDotsClicked(res.data.sum)
    });
    let getCounter4 = new FormData();
    getCounter4.append("event_name", 'settingsButtonClicked');
    axios({
      url: `${backend_url}/statistics/getCounterFrombig`,
      data: getCounter4,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setsettingsButtonClicked(res.data.sum)
    });

    let getCounter5 = new FormData();
    getCounter5.append("event_name", 'subscribeButtonClicked');
    axios({
      url: `${backend_url}/statistics/getCounterFrombig`,
      data: getCounter5,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setSubscriptionPlanEvents(res.data.sum)
    });
    // axios({
    //   url: `${backend_url}/d`,
    //   method: "post",
    //   headers: { "Content-Type": "application/json" },
    // }).then((res) => {
    //   setCounter(res.data.result)
    // });

    axios({
      url: `${backend_url}/statistics/devicesinfo`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setDevicesinfo(res.data.rows)
    });
    axios({
      url: `${backend_url}/statistics/screen_view`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setScreenview(res.data.rows)
    });
    axios({
      url: `${backend_url}/statistics/devicecategory`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setDevicecategory(res.data.result)
    });
    axios({
      url: `${backend_url}/statistics/uninstalled`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setUninstalled(res.data.length)
    });
    axios({
      url: `${backend_url}/statistics/platform`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      // console.log(res.data.result)
      setPlatform(res.data.rows)
    });
    axios({
      url: `${backend_url}/statistics/gender`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      // console.log(res.data.result)
      setGender(res.data.result)
    });

    axios({
      url: `${backend_url}/statistics/getbdage`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      // console.log(res.data.result)
      setBdage(res.data.result)
    });
    axios({
      url: `${backend_url}/statistics/infoversion`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      // console.log(res.data.result)
      setinfoversion(res.data.result)
    });
    axios({
      url: `${backend_url}/statistics/lang`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      // console.log(res.data.result)
      setLang(res.data.result)
    });
    axios({
      url: `${backend_url}/statistics/gmaes`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      // console.log(res.data.rows)
      setGames(res.data.rows)
    });
    ////////////////////////////////////////////////////
    // let formData12 = new FormData();
    // formData12.append("type", 'songs');
    // formData12.append("type2", 'started');
    // axios({
    //   url: `${backend_url}/bigquery/get`,
    //   data: formData12,
    //   method: "post",
    //   headers: { "Content-Type": "application/json" },
    // }).then((res) => {
    //   setTopSongs(res.data.result)
    // });
    // //////
    // let formData1 = new FormData();
    // formData1.append("type", 'shows');
    // formData1.append("type2", 'started');
    // axios({
    //   url: `${backend_url}/bigquery/get`,
    //   data: formData1,
    //   method: "post",
    //   headers: { "Content-Type": "application/json" },
    // }).then((res) => {
    //   setTopShows(res.data.result)
    // });
    // //////
    // let formData2 = new FormData();
    // formData2.append("type", 'stories');
    // formData2.append("type2", 'started');
    // axios({
    //   url: `${backend_url}/bigquery/get`,
    //   data: formData2,
    //   method: "post",
    //   headers: { "Content-Type": "application/json" },
    // }).then((res) => {
    //   setTopStories(res.data.result)
    // });
    // ////////////////////////////////////////////////////
    // ////////////////////////////////////////////////////
    // let formcompleted = new FormData();
    // formcompleted.append("type", 'songs');
    // formcompleted.append("type2", 'completed');
    // axios({
    //   url: `${backend_url}/bigquery/get`,
    //   data: formcompleted,
    //   method: "post",
    //   headers: { "Content-Type": "application/json" },
    // }).then((res) => {
    //   setCompletedSongs(res.data.result)
    // });
    //////
    // let formcompleted1 = new FormData();
    // formcompleted1.append("type", 'shows');
    // formcompleted1.append("type2", 'completed');
    // axios({
    //   url: `${backend_url}/bigquery/get`,
    //   data: formcompleted1,
    //   method: "post",
    //   headers: { "Content-Type": "application/json" },
    // }).then((res) => {
    //   setCompletedShows(res.data.result)
    // });
    // //////
    // let formcompleted2 = new FormData();
    // formcompleted2.append("type", 'stories');
    // formcompleted2.append("type2", 'completed');
    // axios({
    //   url: `${backend_url}/bigquery/get`,
    //   data: formcompleted2,
    //   method: "post",
    //   headers: { "Content-Type": "application/json" },
    // }).then((res) => {
    //   setCompletedStories(res.data.result)
    // });
    ////////////////////////////////////////////////////
    setLoading(true)
    let formfavorite = new FormData();
    formfavorite.append("type", favoriteInput);
    formfavorite.append("type2", 'AddToFavorite');
    axios({
      url: `${backend_url}/statistics/favorite`,
      data: formfavorite,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setFavorite(res.data.result)
      setLoading(false)
    });
    let formfavorite2 = new FormData();
    formfavorite2.append("type", downloadableInput);
    formfavorite2.append("type2", 'videoDownloaded');
    axios({
      url: `${backend_url}/statistics/favorite`,
      data: formfavorite2,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setdownloadable(res.data.result)
      setLoading(false)
      setLoadingAll(false)
    });

    ///////////////////////////////////////////////
  }, []);

  function setFavoite(dd) {
    let backend_url = process.env.REACT_APP_BACKEND_URL;

    setFavoriteInput(dd);
    setdownloadableInput(dd)
    setLoading(true)
    let formfavorite = new FormData();
    formfavorite.append("type", dd);
    formfavorite.append("type2", 'AddToFavorite');
    axios({
      url: `${backend_url}/statistics/favorite`,
      data: formfavorite,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setFavorite(res.data.result)
      setLoading(false)
    });
    let formfavorite2 = new FormData();
    formfavorite2.append("type", downloadableInput);
    formfavorite2.append("type2", 'videoDownloaded');
    axios({
      url: `${backend_url}/statistics/favorite`,
      data: formfavorite2,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setdownloadable(res.data.result)
      setLoading(false)
    });

  }

  function handleChangeTab(event, value) {
    setTabValue(value);
    if (value == 5) {
      let regionandcountry2 = new FormData();
      regionandcountry2.append("dateFilter", selectedDateSubscribers);
      regionandcountry2.append("dateFilter2", selectedDateSubscribers2);
      setLoading2(true)
      axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/statistics/getSubAndroid`,
        data: regionandcountry2,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res111) => {
        setusersPlanCount(res111.data)
        axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/statistics/getSubWeb`,
          data: regionandcountry2,
          method: "post",
          headers: { "Content-Type": "application/json" },
        }).then((res112) => {
          setusersPlanCount2(res112.data)
          axios({
            url: `${process.env.REACT_APP_BACKEND_URL}/statistics/getSubHuawei`,
            data: regionandcountry2,
            method: "post",
            headers: { "Content-Type": "application/json" },
          }).then((res113) => {
            setusersPlanCount3(res113.data)
            axios({
              url: `${process.env.REACT_APP_BACKEND_URL}/statistics/getSubIOS`,
              data: regionandcountry2,
              method: "post",
              headers: { "Content-Type": "application/json" },
            }).then((res114) => {
              setusersPlanCount4(res114.data)
              setLoading2(false)
            });
          });
        });

      });
    }
  }

  function handleChangeProject(id) {
    setSelectedProject({
      id,
      menuEl: null
    });
  }

  function handleOpenProjectMenu(event) {
    setSelectedProject({
      id: selectedProject.id,
      menuEl: event.currentTarget
    });
  }

  function handleCloseProjectMenu() {
    setSelectedProject({
      id: selectedProject.id,
      menuEl: null
    });
  }

  function enableFilter42() {
    if (true) {
      let backend_url = process.env.REACT_APP_BACKEND_URL;
      let dataform = new FormData();
      dataform.append("dateFilter", selectedDate);
      dataform.append("dateFilter2", selectedDate2);
      axios({
        url: `${backend_url}/statistics/gmaes`,
        data: dataform,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        // console.log(res.data.rows)
        setGames(res.data.rows)
      });


    }
  }

  function enableFilter43() {
    if (true) {
      let backend_url = process.env.REACT_APP_BACKEND_URL;


      let formData12 = new FormData();
      formData12.append("type", 'songs');
      formData12.append("type2", 'started');
      formData12.append("dateFilter", selectedDate);
      formData12.append("dateFilter2", selectedDate2);
      axios({
        url: `${backend_url}/bigquery/get`,
        data: formData12,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setTopSongs(res.data.result)
      });
      //////
      let formData1 = new FormData();
      formData1.append("type", 'shows');
      formData1.append("type2", 'started');
      formData1.append("dateFilter", selectedDate);
      formData1.append("dateFilter2", selectedDate2);
      axios({
        url: `${backend_url}/bigquery/get`,
        data: formData1,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setTopShows(res.data.result)
      });
      //////
      let formData2 = new FormData();
      formData2.append("type", 'stories');
      formData2.append("type2", 'started');
      formData2.append("dateFilter", selectedDate);
      formData2.append("dateFilter2", selectedDate2);
      axios({
        url: `${backend_url}/bigquery/get`,
        data: formData2,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setTopStories(res.data.result)
      });
      ////////////////////////////////////////////////////



      ////////////////////////////////////////////////////
      let formcompleted = new FormData();
      formcompleted.append("type", 'songs');
      formcompleted.append("type2", 'completed');
      formcompleted.append("dateFilter", selectedDate);
      formcompleted.append("dateFilter2", selectedDate2);
      axios({
        url: `${backend_url}/bigquery/get`,
        data: formcompleted,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setCompletedSongs(res.data.result)
      });
      //////
      let formcompleted1 = new FormData();
      formcompleted1.append("type", 'shows');
      formcompleted1.append("type2", 'completed');
      formcompleted1.append("dateFilter", selectedDate);
      formcompleted1.append("dateFilter2", selectedDate2);
      axios({
        url: `${backend_url}/bigquery/get`,
        data: formcompleted1,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setCompletedShows(res.data.result)
      });
      //////
      let formcompleted2 = new FormData();
      formcompleted2.append("type", 'stories');
      formcompleted2.append("type2", 'completed');
      formcompleted2.append("dateFilter", selectedDate);
      formcompleted2.append("dateFilter2", selectedDate2);
      axios({
        url: `${backend_url}/bigquery/get`,
        data: formcompleted2,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setCompletedStories(res.data.result)
      });

    }
  }

  function enableFilter4Plan() {
    let regionandcountry2 = new FormData();
    regionandcountry2.append("dateFilter", selectedDate);
    regionandcountry2.append("dateFilter2", selectedDate2);
    setLoading2(true)
    axios({
      url: `${process.env.REACT_APP_BACKEND_URL}/statistics/planusers`,
      data: regionandcountry2,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res111) => {
      console.log(res111)
      setusersPlanCount(res111.data.items[0])
      setLoading2(false)
    });
  }
  function enableFilter4() {
    if (true) {
      let backend_url = process.env.REACT_APP_BACKEND_URL;

      let dataform = new FormData();
      dataform.append("dateFilter", selectedDate);
      dataform.append("dateFilter2", selectedDate2);
      axios({
        url: `${backend_url}/statistics/playlist`,
        data: dataform,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setPlaylist(res.data.sum)
      });

      axios({
        url: `${backend_url}/statistics/clickOnHome`,
        data: dataform,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setClickOnHome(res.data.sum)
      });


      let dataform2 = new FormData();
      dataform2.append("dateFilter", selectedDate);
      dataform2.append("dateFilter2", selectedDate2);
      dataform2.append("event_name", 'videoLocked');
      axios({
        url: `${backend_url}/statistics/getCounterFrombig`,
        data: dataform2,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setVideoLocked(res.data.sum)
      });

      let dataform3 = new FormData();
      dataform3.append("dateFilter", selectedDate);
      dataform3.append("dateFilter2", selectedDate2);
      dataform3.append("event_name", 'repeatButtonOn');
      axios({
        url: `${backend_url}/statistics/getCounterFrombig`,
        data: dataform3,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setRepeatButtonOn(res.data.sum)
      });

      let getCounter3 = new FormData();
      getCounter3.append("dateFilter", selectedDate);
      getCounter3.append("dateFilter2", selectedDate2);
      getCounter3.append("event_name", 'menuThreeDotsClicked');
      axios({
        url: `${backend_url}/statistics/getCounterFrombig`,
        data: getCounter3,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setmenuThreeDotsClicked(res.data.sum)
      });

      let getCounter4 = new FormData();
      getCounter4.append("dateFilter", selectedDate);
      getCounter4.append("dateFilter2", selectedDate2);
      getCounter4.append("event_name", 'settingsButtonClicked');
      axios({
        url: `${backend_url}/statistics/getCounterFrombig`,
        data: getCounter4,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setsettingsButtonClicked(res.data.sum)
      });

      let getCounter5 = new FormData();
      getCounter5.append("dateFilter", selectedDate);
      getCounter5.append("dateFilter2", selectedDate2);
      getCounter5.append("event_name", 'subscribeButtonClicked');
      axios({
        url: `${backend_url}/statistics/getCounterFrombig`,
        data: getCounter5,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setSubscriptionPlanEvents(res.data.sum)
      });
      let getCounter6 = new FormData();
      getCounter6.append("dateFilter", selectedDate);
      getCounter6.append("dateFilter2", selectedDate2);
      axios({
        url: `${backend_url}/statistics/screen_view`,
        data: getCounter6,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setScreenview(res.data.rows)
      });



      setLoading(true)
      let formfavorite = new FormData();
      formfavorite.append("dateFilter", selectedDate);
      formfavorite.append("dateFilter2", selectedDate2);
      formfavorite.append("type", favoriteInput);
      formfavorite.append("type2", 'AddToFavorite');
      axios({
        url: `${backend_url}/statistics/favorite`,
        data: formfavorite,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setFavorite(res.data.result)
        setLoading(false)
      });

      let formfavorite2 = new FormData();
      formfavorite2.append("dateFilter", selectedDate);
      formfavorite2.append("dateFilter2", selectedDate2);
      formfavorite2.append("type", downloadableInput);
      formfavorite2.append("type2", 'videoDownloaded');
      axios({
        url: `${backend_url}/statistics/favorite`,
        data: formfavorite2,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setdownloadable(res.data.result)
        setLoading(false)
      });

    }
    else {
      let backend_url = process.env.REACT_APP_BACKEND_URL;
      axios({
        url: `${backend_url}/statistics/playlist`,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setPlaylist(res.data.sum)
      });
    }

  }



  function enableFilter4311() {
    if (true) {
      let backend_url = process.env.REACT_APP_BACKEND_URL;

      let regionandcountry = new FormData();
      regionandcountry.append("type", 'country');
      regionandcountry.append("dateFilter", selectedDate);
      regionandcountry.append("dateFilter2", selectedDate2);

      axios({
        url: `${backend_url}/statistics/regionandcountry`,
        data: regionandcountry,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setCountry(res.data.result)
      });


      let regionandcountry2 = new FormData();
      regionandcountry2.append("type", 'region');
      regionandcountry2.append("dateFilter", selectedDate);
      regionandcountry2.append("dateFilter2", selectedDate2);

      axios({
        url: `${backend_url}/statistics/regionandcountry`,
        data: regionandcountry2,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        settopCities(res.data.result)
      });


      // axios({
      //   url: `${backend_url}/statistics/Typesofusers`,
      //   data: regionandcountry2,
      //   method: "post",
      //   headers: { "Content-Type": "application/json" },
      // }).then((res) => {
      //   // console.log(res.data.result)
      //   setTypesofusers(res.data.result)
      // });


      axios({
        url: `${backend_url}/statistics/getbdage`,
        data: regionandcountry2,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        // console.log(res.data.result)
        setBdage(res.data.result)
      });
      axios({
        url: `${backend_url}/statistics/gender`,
        data: regionandcountry2,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        // console.log(res.data.result)
        setGender(res.data.result)
      });

      axios({
        url: `${backend_url}/statistics/lang`,
        data: regionandcountry2,
        method: "post",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        // console.log(res.data.result)
        setLang(res.data.result)
      });


    }

  }
  // if (loadingAll) {
  //   return (<LoadingDiv />)
  // }
  return (
    <FusePageSimple
      classes={{
        header: 'min-h-160 h-160',
        toolbar: 'min-h-48 h-48',
        rightSidebar: 'w-288',
        content: classes.content
      }}
      header={
        <div className="flex flex-col justify-between flex-1 px-24 pt-24">
          <div className="flex justify-between items-start">
            <Typography className="py-0 sm:py-24" variant="h4">
              Welcome back, {user.data.displayName}
            </Typography>
            <Hidden lgUp>
              <IconButton
                onClick={ev => pageLayout.current.toggleRightSidebar()}
                aria-label="open left sidebar"
              >
                <Icon>menu</Icon>
              </IconButton>
            </Hidden>
          </div>
          <div className="flex items-end">
            <div className="flex items-center">
              <div className={clsx(classes.selectedProject, 'flex items-center h-40 px-16 text-16')}>
                Home
              </div>
              <IconButton
                className={clsx(classes.projectMenuButton, 'h-40 w-40 p-0')}
                aria-owns={selectedProject.menuEl ? 'project-menu' : undefined}
                aria-haspopup="true"
                onClick={handleOpenProjectMenu}
              >
                <Icon>more_horiz</Icon>
              </IconButton>
              {/* <Menu
                id="project-menu"
                anchorEl={selectedProject.menuEl}
                open={Boolean(selectedProject.menuEl)}
                onClose={handleCloseProjectMenu}
              >
                <MenuItem
                  onClick={ev => {
                    'handleChangeProject(project.id);'
                  }}
                >
                  ss
                </MenuItem>
              </Menu> */}
            </div>
          </div>
        </div>
      }
      contentToolbar={
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="off"
          className="w-full border-b-1 px-24"
        >
          <Tab className="text-14 font-600 normal-case" label="Users" />
          <Tab className="text-14 font-600 normal-case" label="Contents" />
          <Tab className="text-14 font-600 normal-case" label="Games" />
          <Tab className="text-14 font-600 normal-case" label="App Performance" />
          <Tab className="text-14 font-600 normal-case" label="FireBase Events" />
          <Tab className="text-14 font-600 normal-case" label="Users subscriptions" />

        </Tabs>
      }

      content={
        < div className="p-12">
          <Backdrop style={{ zIndex: 999 }} open={loading2} onClick={''}>
            <CircularProgress color="inherit" />
          </Backdrop>
          {tabValue === 0 && (
            <FuseAnimateGroup
              className="flex flex-wrap"
              enter={{
                animation: 'transition.slideUpBigIn'
              }}
            >
              <div className="widget flex w-full p-12 m-12" style={{ backgroundColor: '#fff', borderRadius: '5px', border: '1px rgba(0, 0, 0, 0.27) solid' }}>

                <Typography variant="button" display="block" gutterBottom className="mr-24">
                  <br></br>
                  Filter by date
                </Typography>
                <KeyboardDatePicker
                  disableToolbar
                  margin="normal"
                  variant="inline"
                  label="From"
                  format="D/M/YYYY"
                  value={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  margin="normal"
                  variant="inline"
                  label="To"
                  format="D/M/YYYY"
                  value={selectedDate2}
                  onChange={(date) => setSelectedDate2(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}

                />
                <div className="ml-24">
                  <br></br>
                  <Button variant="outlined" color="primary" onClick={enableFilter4311}>
                    update
                  </Button>
                </div>

              </div>

              <div className="widget flex w-full sm:w-1/2 p-12">
                {Typesofusers !== null ? <Widget6 data={Typesofusers} /> : <>loading top region...</>}
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">

                {topCities.length != 0 ? <Widget7 widget={topCities} /> : ''}
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {country.length != 0 ? <Widget7 widget={country} /> : ''}
              </div>

              <div className="widget flex w-full sm:w-1/2 p-12">
                {gender.length != 0 ?
                  <Widget8 widget={gender} />
                  :
                  null
                }
              </div>
              <div className="widget flex w-full sm:w-1/2 p-12">
                {bdage.length != 0 ?
                  <Widget5 widget={bdage} />
                  :
                  null
                }
              </div>

              <div className="widget flex w-full sm:w-1/2 md:w-1/5 p-12">
                {lang.length != 0 ?
                  <Widget2 widget={lang[0]} />
                  :
                  null
                }
              </div>
              <div className="widget flex w-full sm:w-1/2 md:w-1/5 p-12">
                {lang.length != 0 ?
                  <Widget2 widget={lang[1]} />
                  :
                  null
                }
              </div>

            </FuseAnimateGroup>
          )}
          {tabValue === 1 && (
            <FuseAnimateGroup
              className="flex flex-wrap"
              enter={{
                animation: 'transition.slideUpBigIn'
              }}
            >
              <div className="widget flex w-full p-12 m-12" style={{ backgroundColor: '#fff', borderRadius: '5px', border: '1px rgba(0, 0, 0, 0.27) solid' }}>

                <Typography variant="button" display="block" gutterBottom className="mr-24">
                  <br></br>
                  Filter by date
                </Typography>
                <KeyboardDatePicker
                  disableToolbar
                  margin="normal"
                  variant="inline"
                  label="From"
                  format="D/M/YYYY"
                  value={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  margin="normal"
                  variant="inline"
                  label="To"
                  format="D/M/YYYY"
                  value={selectedDate2}
                  onChange={(date) => setSelectedDate2(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}

                />
                <div className="ml-24">
                  <br></br>
                  <Button variant="outlined" color="primary" onClick={enableFilter43}>
                    update
                  </Button>
                </div>

              </div>
              <div className="widget flex w-full sm:w-1/3 p-12">
                {topSongs.length != 0 ? <ContentTop widget={topSongs} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/3 p-12">
                {topStories.length != 0 ? <ContentTop widget={topStories} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/3 p-12">
                {topShows.length != 0 ? <ContentTop widget={topShows} /> : null}
              </div>


              <div className="widget flex w-full sm:w-1/3 p-12">
                {CompletedSongs.length != 0 ? <ContentTop widget={CompletedSongs} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/3 p-12">
                {CompletedStories.length != 0 ? <ContentTop widget={CompletedStories} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/3 p-12">
                {CompletedShows.length != 0 ? <ContentTop widget={CompletedShows} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/3 p-12">
                {CompletedSongs.length != 0 ? <Widget1 widget={{ counter: (topSongs.counter - CompletedSongs.counter), label: 'Number of dropped songs' }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/3 p-12">
                {CompletedStories.length != 0 ? <Widget1 widget={{ counter: (topStories.counter - CompletedStories.counter), label: 'Number of dropped stories' }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/3 p-12">
                {CompletedShows.length != 0 ? <Widget1 widget={{ counter: (topShows.counter - CompletedShows.counter), label: 'Number of dropped shows' }} /> : null}
              </div>
            </FuseAnimateGroup>

          )}
          {tabValue === 2 && (
            <FuseAnimateGroup
              className="flex flex-wrap"
              enter={{
                animation: 'transition.slideUpBigIn'
              }}
            >
              <div className="widget flex w-full p-12 m-12" style={{ backgroundColor: '#fff', borderRadius: '5px', border: '1px rgba(0, 0, 0, 0.27) solid' }}>

                <Typography variant="button" display="block" gutterBottom className="mr-24">
                  <br></br>
                  Filter by date
                </Typography>
                <KeyboardDatePicker
                  disableToolbar
                  margin="normal"
                  variant="inline"
                  label="From"
                  format="D/M/YYYY"
                  value={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  margin="normal"
                  variant="inline"
                  label="To"
                  format="D/M/YYYY"
                  value={selectedDate2}
                  onChange={(date) => setSelectedDate2(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}

                />
                <div className="ml-24">
                  <br></br>
                  <Button variant="outlined" color="primary" onClick={enableFilter42}>
                    update
                  </Button>
                </div>

              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {CompletedShows.length != 0 ? <Widget1 widget={{ counter: (games[0].whack_a_mole_game_started), label: 'Number of finished whack a mole' }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {CompletedShows.length != 0 ? <Widget1 widget={{ counter: (games[0].memory_game_started), label: 'Number of finished memory game' }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {CompletedShows.length != 0 ? <Widget1 widget={{ counter: (games[0].find_differences_game_started), label: 'Number of finished find differences' }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {CompletedShows.length != 0 ? <Widget1 widget={{ counter: (games[0].puzzle_game_started), label: 'Number of finished puzzle game' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/4 p-12">
                {CompletedShows.length != 0 ? <Widget1 widget={{ counter: (games[0].whack_a_mole_game_started - games[0].puzzle_game_completed), label: 'Number of unfinished whack a mole' }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {CompletedShows.length != 0 ? <Widget1 widget={{ counter: (games[0].memory_game_started - games[0].memory_game_completed), label: 'Number of unfinished memory game' }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {CompletedShows.length != 0 ? <Widget1 widget={{ counter: (games[0].find_differences_game_started - games[0].find_differences_game_completed), label: 'Number of unfinished find differences' }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {CompletedShows.length != 0 ? <Widget1 widget={{ counter: (games[0].puzzle_game_started - games[0].puzzle_game_completed), label: 'Number of unfinished puzzle game' }} /> : null}
              </div>
            </FuseAnimateGroup>
          )}
          {tabValue === 3 && (
            <FuseAnimateGroup
              className="flex flex-wrap"
              enter={{
                animation: 'transition.slideUpBigIn'
              }}
            >
              <div className="widget flex w-full sm:w-1/3 p-12">
                {platform.length != 0 ? <Widget3 widget={{ counter: (platform[0].counter), label: platform[0].platform }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/3 p-12">
                {platform.length != 0 ? <Widget3 widget={{ counter: (platform[1].counter), label: platform[1].platform }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/3 p-12">
                {uninstalled != 0 ? <Widget1 widget={{ counter: uninstalled, label: 'Number of uninstalled' }} /> : null}

              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {devicecategory.length != 0 ?
                  <Widget8 widget={devicecategory} />
                  :
                  null
                }
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {devicesinfo.length != 0 ?
                  <WidgetCount count={{ counter: devicesinfo, title: 'Device operating system version' }} />
                  :
                  null
                }
              </div>


              <div className="widget flex w-full sm:w-1/2 p-12">
                {infoversion.length != 0 ?
                  <Widget5 widget={infoversion} />
                  :
                  null
                }
              </div>


            </FuseAnimateGroup>
          )}


          {tabValue === 4 && (
            <FuseAnimateGroup
              className="flex flex-wrap"
              enter={{
                animation: 'transition.slideUpBigIn'
              }}
            >
              <div className="widget flex w-full p-12 m-12" style={{ backgroundColor: '#fff', borderRadius: '5px', border: '1px rgba(0, 0, 0, 0.27) solid' }}>

                <Typography variant="button" display="block" gutterBottom className="mr-24">
                  <br></br>
                  Filter by date
                </Typography>
                <KeyboardDatePicker
                  disableToolbar
                  margin="normal"
                  variant="inline"
                  label="From"
                  format="D/M/YYYY"
                  value={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  margin="normal"
                  variant="inline"
                  label="To"
                  format="D/M/YYYY"
                  value={selectedDate2}
                  onChange={(date) => setSelectedDate2(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}

                />
                <div className="ml-24">
                  <br></br>
                  <Button variant="outlined" color="primary" onClick={enableFilter4}>
                    update
                  </Button>
                </div>

              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {playlist != 0 ? <Widget1 widget={{ counter: playlist, label: 'Create playlist' }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {clickOnHome != 0 ? <Widget1 widget={{ counter: clickOnHome, label: 'Click on "Home" icon inside the video' }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {videoLocked != 0 ? <Widget1 widget={{ counter: videoLocked, label: 'Lock screen icon inside the video' }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {repeatButtonOn != 0 ? <Widget1 widget={{ counter: repeatButtonOn, label: 'Repeat icon inside the video' }} /> : null}
              </div>


              <div className="widget flex w-full sm:w-1/3 p-12">
                {menuThreeDotsClicked != 0 ? <Widget1 widget={{ counter: menuThreeDotsClicked, label: 'Event on 3 dots icon' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/3 p-12">
                {settingsButtonClicked != 0 ? <Widget1 widget={{ counter: settingsButtonClicked, label: 'Event on Settings' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/3 p-12">
                {SubscriptionPlanEvents != 0 ? <Widget1 widget={{ counter: SubscriptionPlanEvents, label: 'Subscription Plan events ' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/2 p-12">
                {screenview.length != 0 ?
                  <WidgetCount count={{ counter: screenview, title: 'Screen View' }} />
                  :
                  null
                }
              </div>
              <div className="widget flex w-full sm:w-1/2 p-12">
                <Backdrop style={{ zIndex: 999 }} open={loading} onClick={''}>
                  <CircularProgress color="inherit" />
                </Backdrop>
                {favorite.length != 0 ? <Favorite widget={favorite} setFavoriteInput={setFavoite} favoriteInput={favoriteInput} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/2 p-12">
                <Backdrop style={{ zIndex: 999 }} open={loading} onClick={''}>
                  <CircularProgress color="inherit" />
                </Backdrop>
                {downloadable.length != 0 ? <Favorite widget={downloadable} setFavoriteInput={setFavoite} favoriteInput={downloadableInput} /> : null}
              </div>
            </FuseAnimateGroup>
          )}

          {tabValue === 5 && (
            <FuseAnimateGroup
              className="flex flex-wrap"
              enter={{
                animation: 'transition.slideUpBigIn'
              }}
            >

              <div className="widget flex w-full p-12 m-12" style={{ backgroundColor: '#fff', borderRadius: '5px', border: '1px rgba(0, 0, 0, 0.27) solid' }}>

                <Typography variant="button" display="block" gutterBottom className="mr-24">
                  <br></br>
                  Filter by date
                </Typography>
                <KeyboardDatePicker
                  disableToolbar
                  margin="normal"
                  variant="inline"
                  label="From"
                  format="D/M/YYYY"
                  value={selectedDateSubscribers}
                  onChange={(date) => setSelectedDateSubscribers(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  margin="normal"
                  variant="inline"
                  label="To"
                  format="D/M/YYYY"
                  value={selectedDateSubscribers2}
                  onChange={(date) => setSelectedDateSubscribers2(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}

                />
                <div className="ml-24">
                  <br></br>
                  <Button variant="outlined" color="primary" onClick={(e) => handleChangeTab(e, 5)}>
                    update
                  </Button>
                </div>

              </div>


              <div className="widget flex w-full sm:w-1/1 p-12" style={{ borderBottom: '1px #999 solid' }}>

                <Typography variant="h5" gutterBottom>
                  <Android /> Android
                </Typography>
              </div>

              <div className="widget flex w-full sm:w-1/1 p-12" style={{ paddingLeft: '2vw' }}>

                <Typography variant="h6" gutterBottom>
                  Subscribers -  {usersPlanCount != null ? (usersPlanCount.really.bm + usersPlanCount.really.ba + usersPlanCount.really.pm + usersPlanCount.really.pa) : ''}
                </Typography>
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount.really.bm), label: 'Basic Plan - Monthly' }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount.really.ba), label: 'Basic Plan - Annually' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount.really.pm), label: 'Premium Plan - Monthly' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount.really.pa), label: 'Premium Plan - Annually' }} /> : null}
              </div>


              <div className="widget flex w-full sm:w-1/1 p-12" style={{ paddingLeft: '2vw' }}>

                <Typography variant="h6" gutterBottom>
                  Offer code  -  {usersPlanCount != null ? (usersPlanCount.promo.bm + usersPlanCount.promo.ba + usersPlanCount.promo.pm + usersPlanCount.promo.pa) : ''}
                </Typography>
              </div>


              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount.promo.bm), label: 'Basic Plan - Monthly' }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount.promo.ba), label: 'Basic Plan - Annually' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount.promo.pm), label: 'Premium Plan - Monthly' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount.promo.pa), label: 'Premium Plan - Annually' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/1 p-12" style={{ paddingLeft: '2vw' }}>

                <Typography variant="h6" gutterBottom>
                  Test   -  {usersPlanCount != null ? (usersPlanCount.test.bm + usersPlanCount.test.ba + usersPlanCount.test.pm + usersPlanCount.test.pa) : ''}
                </Typography>
              </div>


              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount.test.bm), label: 'Basic Plan - Monthly' }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount.test.ba), label: 'Basic Plan - Annually' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount.test.pm), label: 'Premium Plan - Monthly' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount.test.pa), label: 'Premium Plan - Annually' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/1 p-12" style={{ borderTop: '1px #999 solid' }}> </div>



              <div className="widget flex w-full sm:w-1/1 p-12" style={{ borderBottom: '1px #999 solid' }}>

                <Typography variant="h5" gutterBottom>
                  IOS
                </Typography>
              </div>


              <div className="widget flex w-full sm:w-1/1 p-12" style={{ paddingLeft: '2vw' }}>

                <Typography variant="h6" gutterBottom>
                  Subscribers -  {usersPlanCount4 != null ? (usersPlanCount4.p1 + usersPlanCount4.p2 + usersPlanCount4.p3 + usersPlanCount4.p4) : ''}
                </Typography>
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount4 != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount4.p1), label: 'Basic Plan - Monthly' }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount4 != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount4.p2), label: 'Basic Plan - Annually' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount4 != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount4.p3), label: 'Premium Plan - Monthly' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount4 != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount4.p4), label: 'Premium Plan - Annually' }} /> : null}
              </div>


              <div className="widget flex w-full sm:w-1/1 p-12" style={{ paddingLeft: '2vw' }}>

                <Typography variant="h6" gutterBottom>
                  Offer code  -  {usersPlanCount4 != null ? (usersPlanCount4.p1off + usersPlanCount4.p2off + usersPlanCount4.p3off + usersPlanCount4.p4off) : ''}
                </Typography>
              </div>


              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount4 != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount4.p1off), label: 'Basic Plan - Monthly' }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount4 != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount4.p2off), label: 'Basic Plan - Annually' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount4 != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount4.p3off), label: 'Premium Plan - Monthly' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount4 != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount4.p4off), label: 'Premium Plan - Annually' }} /> : null}
              </div>


              <div className="widget flex w-full sm:w-1/1 p-12" style={{ borderTop: '1px #999 solid' }}> </div>

              <div className="widget flex w-full sm:w-1/1 p-12" style={{ borderBottom: '1px #999 solid' }}>

                <Typography variant="h5" gutterBottom>
                  Web
                </Typography>
              </div>

              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount2 != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount2.bm), label: 'Basic Plan - Monthly' }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount2 != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount2.ba), label: 'Basic Plan - Annually' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount2 != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount2.pm), label: 'Premium Plan - Monthly' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount2 != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount2.pa), label: 'Premium Plan - Annually' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/1 p-12" style={{ borderTop: '1px #999 solid' }}> </div>

              <div className="widget flex w-full sm:w-1/1 p-12" style={{ borderBottom: '1px #999 solid' }}>

                <Typography variant="h5" gutterBottom>
                  Huawei
                </Typography>
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount3 != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount3.p1), label: 'Basic Plan - Monthly' }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount3 != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount3.p2), label: 'Basic Plan - Annually' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount3 != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount3.p3), label: 'Premium Plan - Monthly' }} /> : null}
              </div>

              <div className="widget flex w-full sm:w-1/4 p-12">
                {usersPlanCount3 != null ? <Widget1 widget={{ counter: parseInt(usersPlanCount3.p4), label: 'Premium Plan - Annually' }} /> : null}
              </div>
              <div className="widget flex w-full sm:w-1/1 p-12" style={{ borderTop: '1px #999 solid' }}> </div>

            </FuseAnimateGroup>
          )}



        </div>
      }
      rightSidebarContent={
        < FuseAnimateGroup
          className="w-full"
          enter={{
            animation: 'transition.slideUpBigIn'
          }}
        >

          <div className="widget w-full p-12">
            <WidgetNow />
          </div>
          <div className="widget w-full p-12">
            {/* <WidgetCount count={{ counter, title: 'Available content' }} /> */}
          </div>
        </FuseAnimateGroup >
      }
      ref={pageLayout}
    />
  );
}

export default Dashboard;
