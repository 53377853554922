import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { Button } from "@material-ui/core";
import { TextFieldFormsy, } from "@fuse";

import Formsy from "formsy-react";
import Breadcrumb from "./../components/Breadcrumb";
import axios from "axios";

import SaveIcon from "@material-ui/icons/Save";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import PermMedia from '@material-ui/icons/PermMedia';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import clsx from 'clsx';
import Alert from '@material-ui/lab/Alert';


import { DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';

import Avatar from '@material-ui/core/Avatar';
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Snackbar from '@material-ui/core/Snackbar';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from '@material-ui/core/Switch';
import FileList from '../file-manager/FileList';


import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import Popup from "./../components/popup";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/AddBox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import Icon from '@material-ui/core/Icon';
const styles = (theme) => ({
    layoutRoot: {},
});

const breadCurmbData = [
    {
        title: "Shows",
        link: `${process.env.REACT_APP_FRONT_URL}shows/list`,
    },
    {
        title: "Season",
        link: "",
    },
    {
        title: "Episodes",
        link: "",
    },

];

class Add extends Component {
    state = {
        formValid: false,
        titleEn: "",
        titleAr: "",
        disabled: false,
        coverImg: "",
        rowsPerPage: 151,
        page: 0,
        seasonsData: [],
        titleEn_season: "",
        titleAr_season: "",
        descriptionEn_season: "",
        descriptionAr_season: "",
        addSeasionDev: false,
        episodesData: [],
        idSeason: '',
        ErrorMsg1: '',
        showErrorMsg1: false,
        EditSeasionDev: false,
        titleEn_edit: "",
        titleAr_edit: "",
        descriptionEn_edit: "",
        descriptionAr_edit: "",
        coverImg_edit: "",
        id_edit: "",
        showName: "",
        displayBtnFile: "block",
        progVal: 0,
        openList: false,

        displayDatFile: "none",
        loadingProgress: false,
        successMsg: "",
        s3Information: [],
        s3Information2: [],
        fileSrc: "",
        scheduling: moment(new Date(), 'MM/DD/YYYY'),
        schedulingShow: false

    };
    setFile = (val) => {
        this.setState({
            fileSrc: val.fileSrc,
            s3Information: val.s3Information,
            openList: false,
            successMsg: "The chosen file name: " + val.name

        })
        console.log(this.state.fileSrc)
        console.log(this.state.s3Information)
    }

    changeStatus = (v, id) => {
        let st = v;
        if (st == "Published") {
            st = "Not Published";
        } else {
            st = "Published";
        }
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();
        formData.append("id", id);
        formData.append("Status", st);
        axios({
            url: `${backend_url}/shows/changestatusEpisodes`,
            data: formData,
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            this.componentDidMount();
        });
    };

    handleChange = (e, name) => {
        if (name == 'schedulingShow')
            this.setState({
                [name]: e.target.checked,
            });
        else
            this.setState({
                [name]: e.target.value,
            });
    };


    handleChangeisFreePaid = (e, id, type) => {
        ///shows/changeisFreePaid 
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();

        formData.append("id", id);
        formData.append("setValue", e.target.value);
        formData.append("type", type);
        formData.append("collectonName", 'episodes');
        axios({
            url: `${backend_url}/shows/changeisFreePaid`,
            data: formData,
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
        });


    }


    backtoseason = () => {
        this.setState({
            stepactive: 1
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };
    handleChangeRowsPerPage = () => { };
    sortData = (name) => {
        let data = this.state.categoryData;

        data = data.sort(function (a, b) {
            if (a[name] < b[name]) {
                return -1;
            }
            if (a[name] > b[name]) {
                return 1;
            }
            return 0;
        });

        this.setState({
            categoryData: data,
        });
    };

    handleChangetags = (val, name) => {
        let ids = val.map((item) => {
            return item._id
        })
        this.setState({
            [name]: ids,
        });

    };




    addSeason = () => {
        this.setState({
            addSeasionDev: !this.state.addSeasionDev
        });
    };


    handleSubmit = (model) => {

        if (this.state.coverImg == "") {
            this.setState({
                ErrorMsg1: 'You did not choose an image!',
                showErrorMsg1: true
            })
        }
        else {
            this.setState({
                ErrorMsg1: '',
                showErrorMsg1: false
            });
        }


        if (this.state.fileSrc == "") {
            this.setState({
                ErrorMsg2: 'You not choose a file (video / audio)',
                showErrorMsg2: true
            })
        }
        else {
            this.setState({
                ErrorMsg2: '',
                showErrorMsg2: false
            });
        }
        if (!this.state.showErrorMsg2 && !this.state.showErrorMsg1) {
            if (this.state.successMsg == '') {

                if ((this.state.fileSrc.size / (1024 * 1024)).toFixed(2) > 0) {
                    this.uploadfiles3(2)
                }
            }
            else {
                let backend_url = process.env.REACT_APP_BACKEND_URL;
                let formData = new FormData();
                formData.append("seasonId", this.props.match.params.id);
                formData.append("showId", this.props.match.params.id2);
                formData.append("titleEn", this.state.titleEn_season);
                formData.append("titleAr", this.state.titleAr_season);
                formData.append("fileSrc", this.state.fileSrc);
                formData.append("typeInput", 'Video');
                formData.append("coverImg", this.state.coverImg);
                formData.append("descriptionEn", this.state.descriptionEn_season);
                formData.append("descriptionAr", this.state.descriptionAr_season);
                formData.append("s3Information", JSON.stringify(this.state.s3Information));
                formData.append("scheduling", this.state.scheduling);
                formData.append("schedulingStatus", this.state.schedulingShow);
                formData.append("isFree", false);
                formData.append("isPaid", true);
                axios({
                    url: `${backend_url}/shows/addepisode`,
                    data: formData,
                    method: "post",
                    headers: { "Content-Type": "multipart/form-data" },
                }).then((res) => {
                    this.componentDidMount();

                    this.setState({
                        addSeasionDev: false,
                        titleEn_season: '',
                        titleAr_season: '',
                        descriptionEn_season: '',
                        descriptionAr_season: '',
                        coverImg: '',
                        s3Information: '',
                        fileSrc: '',
                        successMsg: '',
                        displayBtnFile: "block",

                    });
                });
            }

        }


    };

    handleDelete = () => {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();
        formData.append("id", this.state.idremove);
        formData.append("token", localStorage.getItem('jwt_access_token'));
        axios({
            url: `${backend_url}/shows/deletepisodes`,
            data: formData,
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            this.componentDidMount();
            this.setState({
                open: false,
            });
        });
    };



    handleSubmitEdit = () => {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();
        formData.append("id", this.state.id_edit);
        formData.append("titleEn", this.state.titleEn_edit);
        formData.append("titleAr", this.state.titleAr_edit);
        formData.append("descriptionEn", this.state.descriptionEn_edit);
        formData.append("descriptionAr", this.state.descriptionAr_edit);
        formData.append("coverImg", this.state.coverImg_edit);
        formData.append("fileSrc", this.state.fileSrc);
        formData.append("typeInput", 'Video');
        formData.append("s3Information", JSON.stringify(this.state.s3Information));



        axios({
            url: `${backend_url}/shows/editepisodes`,
            data: formData,
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            this.setState({
                EditSeasionDev: false,
                titleEn_edit: '',
                titleAr_edit: '',
                descriptionEn_edit: '',
                descriptionAr_edit: '',
                coverImg_edit: '',
                id_edit: '',
                fileSrc: '',
                s3Information: '',
            });
            this.componentDidMount()
        })


    }

    getEditSeason = (id) => {
        let backend_url = process.env.REACT_APP_BACKEND_URL;

        let data = {
            id: id,
        };

        axios({
            url: `${backend_url}/shows/getepisodesone`,
            data: data,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            this.setState({
                EditSeasionDev: true,
                titleEn_edit: res.data.result.titleEn,
                titleAr_edit: res.data.result.titleAr,
                descriptionEn_edit: res.data.result.descriptionEn,
                descriptionAr_edit: res.data.result.descriptionAr,
                coverImg_edit: res.data.result.coverImg,
                id_edit: id,
                s3Information2: res.data.result.s3Information
            })
            let image = document.getElementById('cover-image-edit');
            image.src = process.env.REACT_APP_BACKEND_URL + '/public/' + this.state.coverImg_edit;

        });

    }
    enableButton = () => {
        this.setState({
            formValid: true,
        });
    };

    disableButton = () => {
        this.setState({
            formValid: false,
        });
    };

    fileUpload = (e, id) => {
        let image = document.getElementById(id);
        image.src = URL.createObjectURL(e.target.files[0]);
        if (id == 'cover-image-edit') {
            this.setState({
                coverImg_edit: e.target.files[0],
            });
        }
        else {
            this.setState({
                coverImg: e.target.files[0],
            });
        }

    };
    fileUpload2 = (e) => {
        // console.log(e.target.files[0])
        if ((e.target.files[0].type).toString().substring(0, 5) == 'video') {
            this.setState({
                fileSrc: e.target.files[0],
                displayDatFile: "block",
                ErrorMsg2: '',
                showErrorMsg2: false,
                typeInput: 'Video'
            });
        }
        else if ((e.target.files[0].type).toString().substring(0, 5) == 'audio') {
            this.setState({
                fileSrc: e.target.files[0],
                displayDatFile: "block",
                ErrorMsg2: '',
                showErrorMsg2: false,
                typeInput: 'Audio'
            });
        }
        else {
            this.setState({
                ErrorMsg2: 'The file type is wrong',
                showErrorMsg2: true,
                fileSrc: '',
                displayDatFile: "none",
            })
        }


    };
    uploadfiles3 = (id) => {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();

        formData.append("fileSrc", this.state.fileSrc);
        formData.append("typeInput", this.state.typeInput);
        formData.append("titleEn", this.state.titleEn);

        this.setState({
            loadingProgress: true,
        });

        axios.post(`${backend_url}/uploadfiletos3`, formData, {
            onUploadProgress: (progressEvent) => {
                const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                console.log("onUploadProgress", totalLength);
                if (totalLength !== null) {
                    this.progressData = Math.round((progressEvent.loaded * 100) / totalLength);

                    this.setState({
                        progVal: this.progressData
                    })
                }
            },
            onDownloadProgress: (progressEvent) => {
                const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                console.log("onDownloadProgress", totalLength);
                if (totalLength !== null) {
                    this.progressData = Math.round((progressEvent.loaded * 100) / totalLength);
                    console.log("onUploadProgress", this.progressData);

                }
            },
        }).then((res) => {
            console.log(res);
            this.setState({
                s3Information: res.data.result,
                loadingProgress: false,
                fileSrc: res.data.namefile,
                successMsg: res.data.message,
                displayBtnFile: "none",
            });
            if (res.data.message == 'Uploaded successfully') {
                if (id == 1)
                    this.handleSubmitEdit();
                else
                    this.handleSubmit();
            }
        });





    };

    componentDidMount() {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let id = this.props.match.params.id;

        let data = {
            id: id,
        };

        axios({
            url: `${backend_url}/shows/getepisodes`,
            data: data,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            // console.log(res.data.result)
            this.setState({
                seasonsData: res.data.result
            })

        });

    }
    upwardMethod = (id, order, backID, backOrder) => {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();
        formData.append("id", id);
        if (order == undefined) {
            formData.append("order", '1');
        }
        else {
            formData.append("order", order);
        }
        formData.append("backID", backID);
        formData.append("backOrder", backOrder);
        axios({
            url: `${backend_url}/shows/mainScreen/upward`,
            data: formData,
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            this.componentDidMount();
        });
    }



    downwardMethod = (id, order, nextID, nextOrder) => {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();
        formData.append("id", id);
        if (order == undefined) {
            formData.append("order", '1');
        } else {
            formData.append("order", order);
        } formData.append("nextID", nextID);
        formData.append("nextOrder", nextOrder);
        axios({
            url: `${backend_url}/shows/mainScreen/downward`,
            data: formData,
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            this.componentDidMount();
        });
    }

    pluscount = (index) => {
        let newindex = index + 1;
        return newindex;
    }
    minuscount = (index) => {
        let bacindex = index - 1;
        return bacindex;
    }

    render() {
        const { classes } = this.props;
        return (
            <FusePageCarded
                classes={{
                    root: classes.layoutRoot,
                }}
                header={
                    <div className="p-24">
                        <Breadcrumb data={breadCurmbData} />

                    </div>
                }
                contentToolbar={
                    <div className="px-24">
                        <h2>{this.state.seasonsData.map((item, index) => (
                            (index == 0) && (
                                item.showName + ' / ' + item.seasonName
                            )

                        ))}</h2>

                        <div
                        >

                        </div>
                    </div>
                }
                content={
                    <div className="p-24 max-w-lg">
                        <Backdrop
                            className={classes.backdrop}
                            open={this.state.loadingProgress}
                            style={{
                                zIndex: 999999,
                                color: "#fff",
                            }}
                        >

                            <Box position="relative" display="inline-flex">
                                <CircularProgress color="secondary" />

                                <Box
                                    top={0}
                                    left={0}
                                    bottom={0}
                                    right={0}
                                    position="absolute"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography variant="caption" component="div" color="textSecondary">{this.state.progVal}%</Typography>
                                </Box>
                            </Box>

                        </Backdrop>
                        <div style={{ width: '70vw' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                className="ml-auto my-16"
                                variant="contained"
                                onClick={() => {
                                    this.addSeason()
                                }}
                            >
                                Add Episodes
                            </Button>
                            {this.state.addSeasionDev && (
                                <div style={{
                                    backgroundColor: '#f3f3f3',
                                    padding: '2vw',
                                    marginBottom: '2vw',
                                    borderRadius: '5px',
                                    boxShadow: '-1px 1px 3px #d0d0d0'
                                }} >
                                    <Formsy
                                        onValidSubmit={this.handleSubmit}
                                        onValid={this.enableButton}
                                        onInvalid={this.disableButton}
                                        ref={"song_add"}
                                        className="flex flex-col p6-12"
                                    >
                                        <div className="flex flex-row justify-between py-20">
                                            <TextFieldFormsy
                                                className="w-5/12"
                                                label="Title En"
                                                autoFocus
                                                id="titleEn"
                                                name="titleEn"
                                                variant="outlined"
                                                value={this.state.titleEn_season}
                                                onChange={(e) => {
                                                    this.handleChange(e, "titleEn_season");
                                                }}
                                                required
                                            />
                                            <TextFieldFormsy
                                                className="w-5/12"
                                                label="Title Ar"
                                                id="titleAr"
                                                name="titleAr"
                                                variant="outlined"
                                                value={this.state.titleAr_season}
                                                onChange={(e) => {
                                                    this.handleChange(e, "titleAr_season");
                                                }}
                                                required
                                            />
                                        </div>




                                        <div className="flex flex-row justify-between py-10">
                                            <TextFieldFormsy
                                                id="outlined-multiline-static"
                                                label="Description En"
                                                multiline
                                                rows={4}
                                                value={this.state.descriptionEn_season}
                                                variant="outlined"
                                                className="w-5/12"
                                                id="descriptionEn_season"
                                                name="descriptionEn_season"
                                                onChange={(e) => {
                                                    this.handleChange(e, "descriptionEn_season");
                                                }}
                                                required
                                            />
                                            <TextFieldFormsy
                                                id="outlined-multiline-static"
                                                label="Description Ar"
                                                multiline
                                                rows={4}
                                                value={this.state.descriptionAr_season}
                                                variant="outlined"
                                                className="w-5/12"
                                                id="descriptionAr_season"
                                                name="descriptionAr_season"
                                                onChange={(e) => {
                                                    this.handleChange(e, "descriptionAr_season");
                                                }}
                                                required
                                            />
                                        </div>
                                        <div className="flex flex-row justify-between ">
                                            <div className="w-full"
                                                style={{
                                                    marginBottom: '2vw'
                                                }}>

                                                <FormControlLabel
                                                    control={<Switch checked={this.state.schedulingShow}

                                                        color="primary"
                                                        onChange={(e) => {
                                                            this.handleChange(e, "schedulingShow");
                                                        }}
                                                        name="Scheduling" />}
                                                    label="Scheduling"
                                                    labelPlacement="left"

                                                />
                                                {this.state.schedulingShow && (
                                                    <DateTimePicker
                                                        label="Scheduling"
                                                        inputVariant="outlined"
                                                        value={this.state.scheduling}
                                                        onChange={date => this.setState({ scheduling: date })}


                                                        className="mt-8 mb-16 w-full"
                                                        minDate={this.state.scheduling}

                                                    />
                                                )}




                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-between py-20"
                                            style={{
                                                backgroundColor: "#f8f8f8",
                                                padding: '2vw',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-around',
                                                borderRadius: '5px',
                                                boxShadow: '0 0 5px #e7e7e7'

                                            }}
                                        >
                                            <div className="flex justify-between items-center w-8/12">
                                                <div className="flex justify-between items-center w-8/12">
                                                    <Button variant="contained" component="label">
                                                        Choose image

                                                        <input accept="image/x-png,image/gif,image/jpeg"
                                                            type="file"
                                                            onChange={(e) => {
                                                                this.fileUpload(e, "cover-image");
                                                            }}
                                                            style={{ display: "none" }}
                                                        />
                                                    </Button>

                                                </div>
                                                <div
                                                    role="button"
                                                    className={clsx(
                                                        classes.productImageItem,
                                                        'flex items-center justify-center relative w-128 h-128 rounded-4 mx-8 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5',

                                                    )}
                                                >
                                                    <img id="cover-image" className="max-w-none w-auto h-full" src="frontend/assets/images/custom/empty-image-thumb.png" />
                                                </div>


                                            </div>

                                        </div>
                                        {this.state.showErrorMsg1 && (
                                            <Alert severity="error">{this.state.ErrorMsg1}</Alert>
                                        )}
                                        <hr style={{
                                            border: 0,
                                            backgroundColor: '#fff',
                                            borderTop: '2px dotted #8c8c8c'
                                        }}></hr>
                                        <div
                                            className="flex justify-between items-center"
                                            style={{ paddingTop: "10px" }}
                                        >
                                            <Button variant="contained" component="label" style={{ display: this.state.displayBtnFile }}>
                                                <CloudUploadIcon />&nbsp;	&nbsp;	Upload Meida file
                                                <input
                                                    accept="video/mp4"
                                                    type="file"
                                                    onChange={(e) => {
                                                        this.fileUpload2(e);
                                                    }}
                                                    style={{ display: "none" }}
                                                />
                                            </Button>
                                            <Button variant="contained" component="label"
                                                onClick={() => {
                                                    this.setState({
                                                        openList: this.state.openList ? false : true
                                                    })

                                                }}
                                            >
                                                <PermMedia />&nbsp;	&nbsp;	Choose from library

                                            </Button>



                                        </div>
                                        <br></br>
                                        <h2>{this.state.successMsg}</h2>
                                        <FileList openList={this.state.openList} setFile={this.setFile} />

                                        <div
                                            className="flex justify-between items-center w-5/12"
                                            style={{ paddingTop: "10px" }}
                                        >
                                            <h3 style={{ display: this.state.displayDatFile }}>
                                                <div style={{ display: this.state.displayBtnFile }}>
                                                    File Name: {(this.state.fileSrc.name)}
                                                    <br></br>
                                                    File Size: {(this.state.fileSrc.size / (1024 * 1024)).toFixed(2)} MB

                                                </div>
                                                <h2>{this.state.successMsg}</h2>


                                            </h3>

                                        </div>
                                        {this.state.showErrorMsg2 && (
                                            <Alert severity="error">{this.state.ErrorMsg2}</Alert>
                                        )}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className={classes.button}
                                            startIcon={<SaveIcon />}
                                            className="ml-auto my-16"
                                            disabled={!this.state.formValid}
                                            type="submit"
                                            variant="contained"
                                        >
                                            Add
                                        </Button>
                                    </Formsy>
                                </div>
                            )}





                            {this.state.EditSeasionDev && (
                                <div style={{
                                    backgroundColor: '#f3f3f3',
                                    padding: '2vw',
                                    marginBottom: '2vw',
                                    borderRadius: '5px',
                                    boxShadow: '-1px 1px 3px #d0d0d0'
                                }}
                                    className="p-24 max-w-lg">
                                    <Formsy
                                        onValidSubmit={this.handleSubmitEdit}
                                        onValid={this.enableButton}
                                        onInvalid={this.disableButton}
                                        ref={"song_add2222"}
                                        className="flex flex-col p6-12"
                                    >
                                        <div className="flex flex-row justify-between py-20">
                                            <TextFieldFormsy
                                                className="w-5/12"
                                                label="Title En"
                                                autoFocus
                                                id="titleEn"
                                                name="titleEn"
                                                variant="outlined"
                                                value={this.state.titleEn_edit}
                                                onChange={(e) => {
                                                    this.handleChange(e, "titleEn_edit");
                                                }}
                                                required
                                            />
                                            <TextFieldFormsy
                                                className="w-5/12"
                                                label="Title Ar"
                                                id="titleAr"
                                                name="titleAr"
                                                variant="outlined"
                                                value={this.state.titleAr_edit}
                                                onChange={(e) => {
                                                    this.handleChange(e, "titleAr_edit");
                                                }}
                                                required
                                            />
                                        </div>




                                        <div className="flex flex-row justify-between py-10">
                                            <TextFieldFormsy
                                                id="outlined-multiline-static"
                                                label="Description En"
                                                multiline
                                                rows={4}
                                                value={this.state.descriptionEn_edit}
                                                variant="outlined"
                                                className="w-5/12"
                                                id="descriptionEn_edit"
                                                name="descriptionEn_edit"
                                                onChange={(e) => {
                                                    this.handleChange(e, "descriptionEn_edit");
                                                }}
                                                required
                                            />
                                            <TextFieldFormsy
                                                id="outlined-multiline-static"
                                                label="Description Ar"
                                                multiline
                                                rows={4}
                                                value={this.state.descriptionAr_edit}
                                                variant="outlined"
                                                className="w-5/12"
                                                id="descriptionAr_edit"
                                                name="descriptionAr_edit"
                                                onChange={(e) => {
                                                    this.handleChange(e, "descriptionAr_edit");
                                                }}
                                                required
                                            />
                                        </div>
                                        <div className="flex flex-row justify-between py-20"
                                            style={{
                                                backgroundColor: "#f8f8f8",
                                                padding: '2vw',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-around',
                                                borderRadius: '5px',
                                                boxShadow: '0 0 5px #e7e7e7'

                                            }}
                                        >

                                            <div className="flex justify-between items-center w-8/12">
                                                <div className="flex justify-between items-center w-8/12">
                                                    <Button variant="contained" component="label">
                                                        Choose image
                                                        <input
                                                            type="file"
                                                            onChange={(e) => {
                                                                this.fileUpload(e, "cover-image-edit");
                                                            }}
                                                            style={{ display: "none" }}
                                                        />
                                                    </Button>

                                                </div>
                                                <div
                                                    role="button"
                                                    className={clsx(
                                                        classes.productImageItem,
                                                        'flex items-center justify-center relative w-128 h-128 rounded-4 mx-8 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5',

                                                    )}
                                                >
                                                    <img id="cover-image-edit" className="max-w-none w-auto h-full" src="frontend/assets/images/custom/empty-image-thumb.png" />
                                                </div>


                                            </div>
                                            <div style={{
                                                backgroundColor: "#f8f8f8",
                                                padding: '2vw',
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                                borderRadius: '5px',
                                                boxShadow: '0 0 5px #e7e7e7',
                                                flexDirection: 'column',
                                                marginTop: '2vw'

                                            }}>


                                                <video
                                                    ref="vidRef"
                                                    controls
                                                    src={this.state.s3Information2.Location}
                                                />








                                            </div>
                                            <div
                                                className="flex justify-between items-center w-5/12"
                                                style={{ paddingTop: "10px" }}
                                            >
                                                <Button variant="contained" component="label" style={{ display: this.state.displayBtnFile }}>
                                                    <CloudUploadIcon />&nbsp;	&nbsp;	Choose other file
                                                    <input
                                                        type="file"
                                                        onChange={(e) => {
                                                            this.fileUpload2(e);
                                                        }}
                                                        style={{ display: "none" }}

                                                    />
                                                </Button>
                                            </div>
                                        </div>
                                        {this.state.showErrorMsg1 && (
                                            <Alert severity="error">{this.state.ErrorMsg1}</Alert>
                                        )}
                                        <hr style={{
                                            border: 0,
                                            backgroundColor: '#fff',
                                            borderTop: '2px dotted #8c8c8c'
                                        }}></hr>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className={classes.button}
                                            startIcon={<SaveIcon />}
                                            className="ml-auto my-16"
                                            disabled={!this.state.formValid}
                                            type="submit"
                                            variant="contained"
                                        >
                                            Edit
                                        </Button>
                                    </Formsy>
                                </div>
                            )}








                            {!this.state.addSeasionDev && (
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell onClick={() => this.sortData("titleEn")}>
                                                    Title EN
                                                </TableCell>
                                                <TableCell align="center">Title AR</TableCell>
                                                <TableCell align="center">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.seasonsData
                                                .slice(
                                                    this.state.page * this.state.rowsPerPage,
                                                    this.state.page * this.state.rowsPerPage +
                                                    this.state.rowsPerPage
                                                )
                                                .map((row, index, ahmad) => (
                                                    <TableRow key={row._id}>
                                                        <TableCell align="left">{(index + 1)} &nbsp;

                                                            {(index + 1) > 1 && (
                                                                <ArrowUpward style={{
                                                                    cursor: 'pointer'
                                                                }} fontSize="small"
                                                                    onClick={() => {
                                                                        let backValId = index == 0 ? 0 : ahmad[this.minuscount(index)]._id
                                                                        let backValOrder = index == 0 ? 0 : ahmad[this.minuscount(index)].created
                                                                        this.upwardMethod(row._id, row.created, backValId, backValOrder)
                                                                    }}
                                                                />
                                                            )}

                                                            {(index + 1) != Object.keys(this.state.seasonsData).length && (
                                                                <ArrowDownward style={{
                                                                    cursor: 'pointer'
                                                                }} fontSize="small"
                                                                    onClick={() => {
                                                                        let nextValId = (index + 1) == Object.keys(this.state.seasonsData).length ? 0 : ahmad[this.pluscount(index)]._id
                                                                        let nextValOrder = (index + 1) == Object.keys(this.state.seasonsData).length ? 0 : ahmad[this.pluscount(index)].created
                                                                        this.downwardMethod(row._id, row.created, nextValId, nextValOrder)
                                                                    }}
                                                                />
                                                            )}

                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Avatar alt={row.titleEn} src={process.env.REACT_APP_BACKEND_URL + '/public/' + row.coverImg} />

                                                        </TableCell>
                                                        <TableCell align="left">{row.titleEn}</TableCell>
                                                        <TableCell align="left">{row.titleAr}</TableCell>



                                                        <TableCell
                                                            align="center"
                                                        >
                                                            <IconButton
                                                                aria-label="VisibilityOff"
                                                                className={classes.margin}
                                                                onClick={() => {
                                                                    this.changeStatus(row.Status, row._id);
                                                                }}
                                                            >
                                                                {row.Status == "Published" ? (
                                                                    <Visibility fontSize="medium" />
                                                                ) : (
                                                                    <VisibilityOff fontSize="medium" />
                                                                )}
                                                            </IconButton>


                                                            <IconButton
                                                                aria-label="edit"
                                                                className={classes.margin}
                                                                onClick={() => {
                                                                    this.getEditSeason(row._id)
                                                                }}
                                                            >
                                                                <EditIcon fontSize="large" />
                                                            </IconButton>


                                                            <IconButton
                                                                aria-label="delete"
                                                                className={classes.margin}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        open: true,
                                                                        idremove: row._id
                                                                    });
                                                                }}
                                                            >
                                                                <DeleteIcon fontSize="large" />
                                                            </IconButton>
                                                            <FormControlLabel
                                                                control={<Switch checked={row.isPaid}
                                                                    value={row.isPaid}
                                                                    color="primary"
                                                                    onChange={(e) => {
                                                                        this.handleChangeisFreePaid(e, row._id, 2);

                                                                        // 1. Make a shallow copy of the items
                                                                        let items = [...this.state.seasonsData];
                                                                        // 2. Make a shallow copy of the item you want to mutate
                                                                        let item = { ...items[index] };
                                                                        // 3. Replace the property you're intested in
                                                                        item.isPaid = !item.isPaid;
                                                                        // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                                                                        items[index] = item;
                                                                        // 5. Set the state to our new copy
                                                                        this.setState({ seasonsData: items });

                                                                    }}
                                                                    name="isPaid" />}
                                                                label="Exclusive"
                                                                labelPlacement="left"

                                                            />

                                                            <FormControlLabel
                                                                control={<Switch checked={row.isFree}
                                                                    value={row.isFree}

                                                                    color="primary"
                                                                    onChange={(e) => {
                                                                        this.handleChangeisFreePaid(e, row._id, 1);
                                                                        // 1. Make a shallow copy of the items
                                                                        let items = [...this.state.seasonsData];
                                                                        // 2. Make a shallow copy of the item you want to mutate
                                                                        let item = { ...items[index] };
                                                                        // 3. Replace the property you're intested in
                                                                        item.isFree = !item.isFree;
                                                                        // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                                                                        items[index] = item;
                                                                        // 5. Set the state to our new copy
                                                                        this.setState({ seasonsData: items });
                                                                    }}
                                                                    name="IsFree" />}
                                                                label="Is Free"
                                                                labelPlacement="left"

                                                            />
                                                            <Dialog
                                                                open={this.state.open}
                                                                keepMounted
                                                                aria-labelledby="alert-dialog-slide-title"
                                                                aria-describedby="alert-dialog-slide-description"
                                                            >
                                                                <DialogTitle id="alert-dialog-slide-title">
                                                                    <DeleteIcon fontSize="large" />{" "}
                                                                    {"Are you sure to delete this episode?"}
                                                                </DialogTitle>
                                                                <DialogActions>
                                                                    <Button
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                open: false,
                                                                            });
                                                                        }}
                                                                        color="primary"
                                                                    >
                                                                        No
                                                                    </Button>
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            this.handleDelete()
                                                                        }}
                                                                    >
                                                                        OK
                                                                    </Button>
                                                                </DialogActions>
                                                            </Dialog>
                                                        </TableCell>



                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[10]}
                                        component="div"
                                        count={this.state.seasonsData.length}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        backIconButtonProps={{
                                            "aria-label": "previous page",
                                        }}

                                        nextIconButtonProps={{
                                            "aria-label": "next page",
                                        }}
                                        onChangePage={this.handleChangePage}
                                    />
                                </TableContainer>
                            )}


                            <Popup />
                        </div>

                    </div>
                }


            />
        );
    }
}

export default withStyles(styles, { withTheme: true })(Add);
