import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React from 'react';

function Widget2(props) {
	return (
		<Paper className="w-full rounded-8 shadow-none border-1">
			<div className="flex items-center justify-between px-4 pt-4">
				<Typography className="text-16 px-12">{props.widget.title}</Typography>
				 
			</div>
			<div className="text-center pt-12 pb-28">
				<Typography className="text-72 leading-none text-red">{props.widget.data.count}</Typography>
				<Typography className="text-16" color="textSecondary">
					{props.widget.data.label}
				</Typography>
			</div>
		 
		</Paper>
	);
}

export default React.memo(Widget2);
