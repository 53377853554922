import React from 'react';
import { connect } from 'react-redux';

class SpansTable extends React.Component {
    checkSelected = (val) => {
 
    }
    render() { 
        let th = this;

        return(<div id="spansTable">
            <table border="1" cellPadding="10" cellSpacing="0">
                <tr>
                    <th>Id</th>
                    <th>Text</th>
                </tr>
                {this.props.selectedIds.map(function(item,index)
                {
                    if(item.value == "ﬀ") {
                        return;
                    }
                    return( <tr className={(th.props.selectedIndex == index)?"active":""}>
                        <td align="center">{item.id}</td>
                        <td align="center">{item.value}</td>
                    </tr>);
                })}
                
            </table>
        </div>);
    } 
}
const mapStateToProps = state => ({
    selectedIds:state.selectedIds,
    selectedIndex:state.selectedIndex
});
export default connect(mapStateToProps) (SpansTable);