import React, {useMemo,useState} from 'react';
import {useDropzone} from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#dddddd',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


const thumb = {
  display: 'inline-flex',
  marginBottom: 15,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function StyledDropzone(props) {

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: "image/*",
    onDrop: acceptedFiles => {

      props.submit(acceptedFiles)
      // setFiles(acceptedFiles.map(file => Object.assign(file, {
      //   preview: URL.createObjectURL(file)
      // })));
    }
  });


  const [files, setFiles] = useState([]);

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <audio src={file.preview} controls />
      </div>
    </div>
  ));



  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <div className="container">
      
      <div {...getRootProps({style})}>

      {props.value !== undefined && props.value !== "" && <p>
        <img src={`${process.env.REACT_APP_BACKEND_URL}/${props.value}`} style={{width:70,height:70}} />

      </p>}
      {props.value !== undefined && props.value !== "" && <p><HighlightOffIcon style={{fontSize:"2.5em",color:"#192d3e",marginTop:15}} onClick={props.clear} /></p>}
      {(props.value === undefined || props.value === "") && <p><input {...getInputProps()} /><CloudUploadIcon style={{fontSize:"4em"}} /></p>}
      {(props.value === undefined || props.value === "") && <p>{props.title}</p>}

        {/* {props.value === undefined && <div>
          <div>{thumbs}</div>
          {thumbs.length === 0 && <input {...getInputProps()} />}
          {thumbs.length === 0 && <p><CloudUploadIcon style={{fontSize:"4em"}} /></p>}
          {thumbs.length === 0 && <p>{props.title}</p>}
          {thumbs.length !== 0 && <p><HighlightOffIcon style={{fontSize:"2.5em",color:"#192d3e"}} onClick={() => { setFiles([]) }} /></p>}
        </div>}

        {props.value !== undefined && thumbs.length === 0 && <div align="center">
          <p><audio src={`${process.env.REACT_APP_BACKEND_URL}/${props.value}`} controls /></p>
          <p><HighlightOffIcon style={{fontSize:"2.5em",color:"#192d3e",cursor:"pointer"}} onClick={props.clear} /></p>
        </div>} */}
        
        
      </div>
    </div>
  );
}

export default StyledDropzone;