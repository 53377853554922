import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {

 /* const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };*/

  return (
      <Dialog
      
        open={props.open}
       // onClose={handleClose}
      >
        {props.title !== undefined && props.title !== "" && <DialogTitle align={props.titleCenter && "center"}>{props.title}</DialogTitle>}
        <DialogContent >
        {props.body !== undefined && props.body !== "" && <DialogContentText><div style={props.width}>{props.body}</div></DialogContentText>}
        </DialogContent>
      </Dialog>
  );
}