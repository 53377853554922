/**
 * Authorization Roles
 */
const authRoles = {
    admin    : ['Super admin'],
    editor   : ['Editor'],
    staff    : ['admin', 'staff'],
    user     : ['admin', 'staff', 'user'],
    onlyGuest: []
};

export default authRoles;
