
import { authRoles } from 'app/auth';

const navigationConfig = [
  {
    id: "applications",
    title: "",
    type: "group",
    icon: "apps",
    auth: authRoles.admin,
    children: [
      {
        id: "singers-item",
        title: "Songs",
        type: "item",
        icon: "music_note",
        url: `${process.env.REACT_APP_FRONT_URL}songs/list`,
      },
      {
        id: "singers-item",
        title: "Stories",
        type: "item",
        icon: "view_column",
        url: `${process.env.REACT_APP_FRONT_URL}stories/list`,
      },
      // {
      //   id: "singers-item",
      //   title: "Books",
      //   type: "item",
      //   icon: "book",
      //   url: `${process.env.REACT_APP_FRONT_URL}books/list`,
      // },
      {
        id: "singers-item",
        title: "Shows",
        type: "item",
        icon: "slideshow",
        url: `${process.env.REACT_APP_FRONT_URL}shows/list`,
      },
      {
        id: "singers-item",
        title: "Media library",
        type: "item",
        icon: "perm_media",
        url: `${process.env.REACT_APP_FRONT_URL}file-manager`,
      },
      {
        id: "singers-item",
        title: "Singers",
        type: "item",
        icon: "playlist",
        url: `${process.env.REACT_APP_FRONT_URL}singers/list`,
      },
      {
        id: "users-item",
        title: "Interests",
        type: "item",
        icon: "category",
        url: `${process.env.REACT_APP_FRONT_URL}categories/list`,
      },
      {
        id: "users-item",
        title: "Composers",
        type: "item",
        icon: "music_note",
        url: `${process.env.REACT_APP_FRONT_URL}composers/list`,
      },
      {
        id: "users-item",
        title: "Authors",
        type: "item",
        icon: "queue_music",
        url: `${process.env.REACT_APP_FRONT_URL}authors/list`,
      },
      {
        id: "users-item",
        title: "Genre",
        type: "item",
        icon: "playlist",
        url: `${process.env.REACT_APP_FRONT_URL}genre/list`,
      },
      {
        id: "users-item",
        title: "Tags",
        type: "item",
        icon: "playlist",
        url: `${process.env.REACT_APP_FRONT_URL}tags/list`,
      },

      {
        id: "users-item",
        title: "Age rate",
        type: "item",
        icon: "rate_review",
        url: `${process.env.REACT_APP_FRONT_URL}age_rate/list`,
      },
      {
        id: "users-item",
        title: "Subscription plans",
        type: "item",
        icon: "subscriptions",
        url: `${process.env.REACT_APP_FRONT_URL}subscription/list`,
      },
      {
        id: "users-item",
        title: "Promo Codes",
        type: 'collapse',
        icon: "code",
        children: [
          {
            id: 'app-section-content',
            title: 'Android',
            type: 'item',
            url: `${process.env.REACT_APP_FRONT_URL}promoCode/android`,
            exact: true
          },
          {
            id: 'app-version-content',
            title: 'IOS',
            type: 'item',
            url: `${process.env.REACT_APP_FRONT_URL}promoCode/ios`,
            exact: true
          },
        ]
      },

      {
        id: "users-item",
        title: "Ads Banners",
        type: "item",
        icon: "chat",
        url: `${process.env.REACT_APP_FRONT_URL}banners/list`,
      },
      {
        id: "users-item",
        title: "Logs",
        type: "item",
        icon: "library_books",
        url: `${process.env.REACT_APP_FRONT_URL}logs/list`,
      },
      {
        id: "users-item",
        title: "App settings",
        type: 'collapse',
        icon: "settings",
        children: [
          {
            id: 'app-section-content',
            title: 'App Section Content',
            type: 'item',
            url: `${process.env.REACT_APP_FRONT_URL}appsettings/rowsapp`,
            exact: true
          },
          {
            id: 'application-backgrounds',
            title: 'Application backgrounds',
            type: 'item',
            url: `${process.env.REACT_APP_FRONT_URL}application-backgrounds`,
            exact: true
          },
          {
            id: 'application-focus',
            title: 'Focus on Homepage',
            type: 'item',
            url: `${process.env.REACT_APP_FRONT_URL}application-focus`,
            exact: true
          },
          {
            id: 'app-version-content',
            title: 'Competitions',
            type: 'item',
            url: `${process.env.REACT_APP_FRONT_URL}competitions/list`,
            exact: true
          },
          {
            id: 'app-version-content',
            title: 'App Labels/Messages',
            type: 'item',
            url: `${process.env.REACT_APP_FRONT_URL}appsettings/listAppMessages`,
            exact: true
          },

        ]
      },
      {
        id: "users-item",
        title: "Games",
        type: "item",
        icon: "games",
        url: `${process.env.REACT_APP_FRONT_URL}games/list`,
      },
      {
        id: "users-item",
        title: "Users",
        type: "item",
        icon: "people",
        url: `${process.env.REACT_APP_FRONT_URL}users/list`,
      },

      {
        id: "users-item",
        title: "Push notification",
        type: "item",
        icon: "notifications",
        url: `${process.env.REACT_APP_FRONT_URL}notification/push`,
      },
      {
        id: "users-item",
        title: "Avatar",
        type: "item",
        icon: "account_circle",
        url: `${process.env.REACT_APP_FRONT_URL}avatar/list`,
      },
      {
        id: "users-item",
        title: "Privacy policy",
        type: "item",
        icon: "pages",
        url: `${process.env.REACT_APP_FRONT_URL}privacypolicy/view`,
      },
      {
        id: "users-item",
        title: "Terms Conditions",
        type: "item",
        icon: "pages",
        url: `${process.env.REACT_APP_FRONT_URL}termsConditions/view`,
      },
      {
        id: "users-item",
        title: "About us",
        type: "item",
        icon: "pages",
        url: `${process.env.REACT_APP_FRONT_URL}about/view`,
      }, {
        id: "users-item",
        title: "Contact Us Form",
        type: "item",
        icon: "pages",
        url: `${process.env.REACT_APP_FRONT_URL}contact/list`,
      },
    ],
  },
  {
    id: "applications",
    title: "",
    type: "group",
    icon: "apps",
    auth: authRoles.editor,
    children: [
      {
        id: "singers-item",
        title: "Songs",
        type: "item",
        icon: "music_note",
        url: `${process.env.REACT_APP_FRONT_URL}songs/list`,
      },
      {
        id: "singers-item",
        title: "Stories",
        type: "item",
        icon: "view_column",
        url: `${process.env.REACT_APP_FRONT_URL}stories/list`,
      },
      {
        id: "singers-item",
        title: "Shows",
        type: "item",
        icon: "slideshow",
        url: `${process.env.REACT_APP_FRONT_URL}shows/list`,
      },
      {
        id: "singers-item",
        title: "Media library",
        type: "item",
        icon: "perm_media",
        url: `${process.env.REACT_APP_FRONT_URL}file-manager`,
      },
      {
        id: "singers-item",
        title: "Singers",
        type: "item",
        icon: "playlist",
        url: `${process.env.REACT_APP_FRONT_URL}singers/list`,
      },
      {
        id: "users-item",
        title: "Interests",
        type: "item",
        icon: "category",
        url: `${process.env.REACT_APP_FRONT_URL}categories/list`,
      },
      {
        id: "users-item",
        title: "Composers",
        type: "item",
        icon: "music_note",
        url: `${process.env.REACT_APP_FRONT_URL}composers/list`,
      },
      {
        id: "users-item",
        title: "Authors",
        type: "item",
        icon: "queue_music",
        url: `${process.env.REACT_APP_FRONT_URL}authors/list`,
      },
      {
        id: "users-item",
        title: "Genre",
        type: "item",
        icon: "playlist",
        url: `${process.env.REACT_APP_FRONT_URL}genre/list`,
      },
      {
        id: "users-item",
        title: "Tags",
        type: "item",
        icon: "playlist",
        url: `${process.env.REACT_APP_FRONT_URL}tags/list`,
      },

      {
        id: "users-item",
        title: "Age rate",
        type: "item",
        icon: "rate_review",
        url: `${process.env.REACT_APP_FRONT_URL}age_rate/list`,
      },
      {
        id: "users-item",
        title: "Games",
        type: "item",
        icon: "games",
        url: `${process.env.REACT_APP_FRONT_URL}games/list`,
      },

      {
        id: "users-item",
        title: "App settings",
        type: 'collapse',
        icon: "settings",
        children: [
          {
            id: 'app-section-content',
            title: 'App Section Content',
            type: 'item',
            url: `${process.env.REACT_APP_FRONT_URL}appsettings/rowsapp`,
            exact: true
          },

        ]
      },

      {
        id: "users-item",
        title: "Avatar",
        type: "item",
        icon: "account_circle",
        url: `${process.env.REACT_APP_FRONT_URL}avatar/list`,
      },

    ],
  },
];


////

export default navigationConfig;
