import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import Button from "@material-ui/core/Button";

// import Alert from '@material-ui/lab/Alert';

import { TextFieldFormsy, SelectFormsy } from "@fuse";
import Formsy from "formsy-react";
import Paper from "@material-ui/core/Paper";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";
import { Link } from "react-router-dom";
import Popup from "../components/popup";
import { Color } from "react-input-color";

const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "Privacy policy",
    link: "",
  },
];
class List extends Component {
  state = {
    formValid: false,
    newpassword: "",
    newpassword2: "",
    disabled: false,
    categoryData: [],
    openpage: 0
  };

  handleSubmit = (model) => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;

    if (this.state.newpassword == this.state.newpassword2) {
      let formData = new FormData();

      formData.append("password", this.state.newpassword);
      formData.append("code", this.props.match.params.id);
      formData.append("id", this.state.iduser);

      axios({
        url: `${backend_url}/resetpass/update`,
        data: formData,
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        alert('Password changed successfully!')
        window.open("http://royakids.com/", "_self", "");
      });
    }

    else{
      alert('password does not match!')
    }

  };
  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };


  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
  };



  componentDidMount() {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let code = this.props.match.params.id;

    let data = {
      code: code,
    };

    axios({
      url: `${backend_url}/resetpass/get`,
      data: data,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({ openpage: res.data.open, iduser: res.data.iduser });
    });


  }



  render() {
    const { classes } = this.props;
    return (

      <FusePageCarded
        contentToolbar={
          <div className="w-full flex items-center justify-between p-24">
            <h2>Change your password</h2>
            <div className="flex items-center">

            </div>
          </div>
        }
        content={
          <div  >

            <div className="p-24">

              {this.state.openpage == 1 && (
                <div className=" ">
                  <Formsy
                    onValidSubmit={this.handleSubmit}
                    onValid={this.enableButton}
                    onInvalid={this.disableButton}
                    ref={"resetpassword"}

                  >


                    <div>
                      <TextFieldFormsy
                        className="mt-8 mb-16"
                        required
                        label="New Password"
                        autoFocus
                        id="newPassword"
                        name="newPassword"
                        type="password"
                        onChange={(e) => {
                          this.handleChange(e, "newpassword");
                        }}
                        variant="outlined"
                        fullWidth
                      />

                      <TextFieldFormsy
                        className="mt-8 mb-16"
                        id="repeatPassword"
                        name="repeatPassword"
                        onChange={(e) => {
                          this.handleChange(e, "newpassword2");
                        }}
                        label="Repeat password"
                        type="password"
                        rows={5}
                        variant="outlined"
                        fullWidth
                        required
                      />



                    </div>
                    <div className="flex flex-row justify-between py-20">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="ml-auto my-16"
                        aria-label="Add"
                        disabled={!this.state.formValid}
                      >
                        Change
             </Button>
                    </div>

                  </Formsy>
                </div>
              )}


              {this.state.openpage == 0 && (
                <h1>Looks like something went wrong!</h1>
              )}


            </div>





            <Popup />
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(List);
