import Add from "./Add";
import Edit from "./Edit";
import List from "./List";
import level from "./level";
import addLevel from "./addLevel";
import addAssets from "./addAssets";
import editAssets from "./editAssets";
import levelMemory from './levelMemory';
import EditLevel from './EditLevel';
import settingswhackamole from './settingswhackamole';
import addLevelwhackamole from './addLevelwhackamole';
import EditLevelwhackamole from './EditLevelwhackamole';
import levelPuzzle from './levelPuzzle';
import addLevellevelPuzzle from './addLevellevelPuzzle';
import levelDiff from './levelDiff';
import addLevelDiff from './addLevelDiff';
import levelcoloring from './levelcoloring';
import addLevelColoring from './addLevelColoring';

import Missing_letters from './Missing_letters';
import Missing_letters_add from './Missing_letters_add';
import Missing_letters_edit from './Missing_letters_edit';
import Jigsaw from './jigsaw';
import JigsawAdd from './jigsaw_add';
import JigsawEdit from './jigsaw_edit';



export const GamesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/add`,
      component: Add,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/:id/edit`,
      component: Edit,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/list`,
      component: List,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/assets`,
      component: level,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/levelMemory/addLevel`,
      component: addLevel,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/level/addAssets`,
      component: addAssets,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/level/:id/editassets`,
      component: editAssets,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/levelMemory`,
      component: levelMemory,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/:id/level`,
      component: EditLevel,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/settingswhackamole`,
      component: settingswhackamole,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/addLevelwhackamole`,
      component: addLevelwhackamole,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/:id/levelwhackamole`,
      component: EditLevelwhackamole,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/levelPuzzle`,
      component: levelPuzzle,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/Puzzle/add`,
      component: addLevellevelPuzzle,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/levelDiffs`,
      component: levelDiff,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/levelDiff/add`,
      component: addLevelDiff,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/levelcoloring/list`,
      component: levelcoloring,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/levelcoloring/add`,
      component: addLevelColoring,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/Missing_letters/list`,
      component: Missing_letters,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/Missing_letters/add`,
      component: Missing_letters_add,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/Missing_letters/:id/edit`,
      component: Missing_letters_edit,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/jigsaw/list`,
      component: Jigsaw,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/jigsaw/add`,
      component: JigsawAdd,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}games/jigsaw/:id/edit`,
      component: JigsawEdit,
    },
  ],
};
