import _ from '@lodash';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';

function Widget7(props) {
	const theme = useTheme();
	const [dataset, setDataset] = useState('Today');
	const data = _.merge({}, props.data);

	return (
		<Card className="w-full rounded-8 shadow-none border-1">
			<div className="p-16 border-b-1">
				<Typography className="h1 font-500">Types of users</Typography>
			</div>
			<div className="p-16 flex flex-row items-center justify-between">
				<div>
					<FormControl className="">
						{/* <Select value={dataset} onChange={ev => setDataset(ev.target.value)}>
							{Object.keys(data.datasets).map(key => (
								<MenuItem key={key} value={key}>
									{key}
								</MenuItem>
							))}
						</Select> */}
					</FormControl>
				</div>
				{/* <Button size="small">OVERVIEW</Button> */}
			</div>

			<div className="h-224 relative">
				<Doughnut
					data={{
						labels: data.labels,
						datasets: data.datasets[dataset]
					}}
					options={data.options}
				/>
			</div>

			<div className="p-16 flex flex-row items-center justify-center">
				{data.labels.map((label, index) => (
					<div key={label} className="px-16 flex flex-col items-center">
						<Typography className="h4" color="textSecondary">
							{label}
						</Typography>
						<Typography className="h2 font-300 py-8">{Math.round(data.datasets[dataset][0].data[index] / data.options.sum * 100)}%</Typography>


					</div>
				))}
			</div>

			<div className="flex items-center p-8 border-t-1">
				<div className="flex flex-1 flex-col items-center justify-center p-16 border-r-1">
					<Typography className="text-32 leading-none">{data.options.sum}</Typography>
					<Typography className="text-15" color="textSecondary">
						Total of registered users
					</Typography>
				</div>

			</div>

		</Card>
	);
}

export default React.memo(Widget7);
