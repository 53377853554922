import React from 'react';
import { connect } from 'react-redux'; 
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { setTimeout } from 'timers';

class story extends React.Component {
    checkBook = false;
    constructor() {
        super();
        this.state = {
            open:false
        }
        this.audio = this.audio.bind(this);
    }
    
    componentDidMount() {
       /* document.querySelectorAll("span").forEach(function(item) {
            console.log(item);
            item.addEventListqener("mouseenter",function(e) {
                console.log(e.target);
            });
        });*/
        document.getElementById("spansTable").style.display = "none";
        let th = this;
        let opf_link = localStorage.getItem("opf_path");
        let opf_dir = localStorage.getItem("opf_dir");

        var url_string = window.location.href;

        var url = new URL(url_string);
        opf_link = url.searchParams.get("opf_file");
        opf_dir = opf_link.replace("content.opf","");
        var id = url.searchParams.get("id");


        
        this.props.dispatch({type:"update_opf",opf_dir:opf_dir,opf_link:opf_link});
        
        axios.get(opf_dir+ "data.json?id=" + Math.floor(Math.random() * 9000)).then(function(res) {
            

            
            let type = typeof res.data;
            if(type == "object") {
                th.checkBook = true;
                th.props.dispatch({type:"update_data",book:res.data})
            }
            axios.get(opf_link).then(function(res2) {
                th.perpareFiles(res2.data);
            });
        }).catch(function (error) {
            axios.get(opf_link).then(function(res) {
                th.perpareFiles(res.data);
            });
        })
        
        
        document.addEventListener('mouseenter', function(e) {
            th.selectText(e);
        });

        document.addEventListener('selectionchange', function(e) {
            th.selectText(e);
        });
    }
    currentWord1 = 0;
    currentSpan1 = 0;
    current1 = 0;
    audio2 = () => {
        let th = this;
        let page_count = this.props.page_count;
        let smilAudios = this.props.book.pages[page_count].smilAudios;
        let audios = document.querySelectorAll("#page-audios audio");

        this.currentWord1 = 0;
        this.currentSpan = 0;
        this.current1 = 0;


        let currentAudio = audios[this.currentSpan];
        let audioLink = smilAudios[this.currentSpan].audioLink;
        let currentPoints = smilAudios[this.currentSpan].spans[0].points;
        let selectedId = currentPoints[0].textId;

        document.getElementById(selectedId).style.background = "yellow"; 

        currentAudio.play();
        currentAudio.addEventListener("timeupdate",this.audioUpdate);
        currentAudio.addEventListener("ended",this.audioEnded);
/*
        currentAudio.addEventListener("timeupdate", function(e) {
            if(currentPoints[th.current1 + 1] != undefined) {
                if(currentPoints[th.current1].end < currentAudio.currentTime) {
                    document.getElementById(selectedId).style.background = ""; 
                    this.current1++;
                    selectedId = currentPoints[th.current1].textId;
                    console.log("selectId : " +  selectedId)

                    document.getElementById(selectedId).style.background = "yellow"; 

                    if(selectedId != undefined) {
                       // document.getElementById(selectedId).style.background = "yellow"; 
                    }
                }

                //console.log(currentPoints[i]);
            }
            
        });
        
        currentAudio.addEventListener("ended", function(e) {
            
            this.current1 = 0;
            this.ccurrentSpan++;
            this.ccurrentWord = 0;
            //console.log(selectedId);
            document.getElementById(selectedId).style.background = ""; 

            currentPoints = smilAudios[th.currentSpan].spans[0].points;

            selectedId = currentPoints[th.current1].textId;

            
            document.getElementById(selectedId).style.background = "yellow"; 

           // currentAudio.play();
            //audioLink = smilAudios[currentSpan].audioLink;

            //let currentAudioSrc = audios[currentSpan].src;

        });*/


    }
    audioEnded = (e) => {

    }
    audioUpdate = (e) => {
      //  console.log(e.srcElement.currentTime);
        let page_count = this.props.page_count;
        let smilAudios = this.props.book.pages[page_count].smilAudios;
        let audios = document.querySelectorAll("#page-audios audio");

        let currentAudio = audios[this.currentSpan];
        let audioLink = smilAudios[this.currentSpan].audioLink;
        let currentPoints = smilAudios[this.currentSpan].spans[0].points;
        let selectedId = currentPoints[0].textId;

    }
    audioCount = 0;
    audio3 = (count) => {
        
        let th = this;
        th.audioCount = count;
        let count2 = th.audioCount;
        let mode = this.props.pageType;
        //let audios = [];
        let page_count = this.props.page_count;

        let smilAudios = this.props.book.pages[page_count].smilAudios;
        //console.log(this.props);

        let currentAudio = 0;
        let audios = document.querySelectorAll("#page-audios audio");
        if(smilAudios[0] == undefined) {
            return;
        }
        if(smilAudios[0].spans.length == 0) {
            return;
        }

        if(count == 0) {
            let buttons = document.querySelectorAll(".main-bt");
            buttons.forEach(function(bt) {
                bt.disabled = true;
            });
        }
     //   if(mode == 'oneAudio') {
            audios[count2].play();
            var currentSpan = 0;
            var currentWord = 0;
            if(smilAudios[count2] == undefined) {
                let buttons = document.querySelectorAll(".main-bt");
                buttons.forEach(function(bt) {
                    bt.disabled = false;
                });
    
                return;
            }
            var wordId = smilAudios[count].spans[0].points[0].textId;


          
            // console.log(wordId);
            var spanElement = document.getElementById(smilAudios[count2].spans[0].id);
            var spanText = spanElement.innerText;
            var spanHtml = spanElement.innerHTML;
            var originalHtml = spanHtml;
            spanElement.innerHTML = spanHtml;
            var splitText = spanText.split(' ');
            var text = splitText[0];
            var index = spanHtml.indexOf(text);     
            if (index >= 0) {
            // spanHtml = spanHtml.substring(0,index) + "<span class='audio-highlight'>" + spanHtml.substring(index,index+text.length) + "</span>" + spanHtml.substring(index + text.length);
            // spanElement.innerHTML = spanHtml;
            document.getElementById(wordId).classList.add('audio-highlight')
            }
            if(smilAudios[count].spans[currentSpan].points[currentWord+1] != undefined){
                currentWord++;
            }
            audios[count2].addEventListener("timeupdate", function(e) {
            //console.log(audios[0].currentTime, parseFloat(smilAudios.spans[currentSpan].points[currentWord].start));
           // if(smilAudios.spans[currentSpan].points[currentWord] != undefined) {
            //    console.log("currentWord: " + currentWord);
            //    console.log("count2: " + count2);
            //    console.log("currentSpan: " + currentSpan);
            //    console.log(smilAudios[count2]);
            // console.log(currentSpan,currentSpan)
            // console.log(smilAudios[count2].spans[currentSpan].points[currentSpan]);
            
            if(audios[count2].currentTime >= parseFloat(smilAudios[count2].spans[currentSpan].points[currentWord].start)){
                //console.log(smilAudios.spans[currentSpan].id);
                console.log('wordid',wordId);

                 console.log('count',count2);    
                document.getElementById(wordId).classList.remove('audio-highlight')
                spanElement = document.getElementById(smilAudios[count2].spans[currentSpan].id);
                spanText = spanElement.innerText;
                spanHtml = spanElement.innerHTML;
                originalHtml = spanHtml;
                var splitText = spanText.split(' ');
                var text = splitText[currentWord];
                var index = spanHtml.indexOf(text);     
                if (index >= 0) { 
                //console.log(spanHtml);
                if(smilAudios[count2] == undefined) {
                    return;
                }
                wordId = smilAudios[count2].spans[currentSpan].points[currentWord].textId;
                // spanHtml = spanHtml.substring(0,index) + "<span class='audio-highlight'>" + spanHtml.substring(index,index+text.length) + "</span>" + spanHtml.substring(index + text.length);
                // spanElement.innerHTML = spanHtml;
                document.getElementById(wordId).classList.add('audio-highlight')
                }
                if(smilAudios[count2].spans[currentSpan].points[currentWord+1] != undefined){
                currentWord++;
                }else{
                    if(smilAudios[count2].spans[currentSpan+1] !=undefined){
                        currentSpan++;
                        currentWord = 0;
                    }
                }
            }
      //  }
            })
            audios[count2].addEventListener("ended", function(e){
            document.getElementById(wordId).classList.remove('audio-highlight')
            // th.audioCount++;
            if(audios[th.audioCount+1] !=undefined) {
                th.audioCount++;
                th.audio3(th.audioCount);
                currentWord = 0;
            }
            else {
                let buttons = document.querySelectorAll(".main-bt");
            buttons.forEach(function(bt) {
                bt.disabled = false;
            });
            }
            
            // var message = {
            //     message:'nextPagePlay',
            // };
            // window.postMessage(JSON.stringify(message));
            // playAudio = false;
            })
    //    }

    }

    audio() {
        let mode = this.props.pageType;
        //let audios = [];
        let page_count = this.props.page_count;

        let smilAudios = this.props.book.pages[page_count].smilAudios;
        //console.log(this.props);

        let currentAudio = 0;
        let audios32 = document.querySelectorAll("#page-audios audio");

        if(smilAudios.spans == undefined) {
            return;
        }

        let buttons = document.querySelectorAll(".main-bt");
            buttons.forEach(function(bt) {
                bt.disabled = true;
            });

     //   if(mode == 'oneAudio') {
        audios32[0].play();
        
            var currentSpan = 0;
            var currentWord = 0;
            var wordId = smilAudios.spans[0].points[0].textId;
            // var spanElement = document.getElementById(smilAudios.spans[0].id);
            // var spanText = spanElement.innerText;
            // var spanHtml = spanElement.innerHTML;
            // var originalHtml = spanHtml;
            // spanElement.innerHTML = spanHtml;
            // var splitText = spanText.split(' ');
            // var text = splitText[0];
            // var index = spanHtml.indexOf(text);     
            // if (index >= 0) {
            // spanHtml = spanHtml.substring(0,index) + "<span class='audio-highlight'>" + spanHtml.substring(index,index+text.length) + "</span>" + spanHtml.substring(index + text.length);
            // spanElement.innerHTML = spanHtml;
            document.getElementById(wordId).classList.add('audio-highlight')
            // }
            if(smilAudios.spans[currentSpan].points[currentWord+1] != undefined){
                currentWord++;
            }
            audios32[0].addEventListener("timeupdate", function(e){
            //console.log(audios[0].currentTime, parseFloat(smilAudios.spans[currentSpan].points[currentWord].start));
           // if(smilAudios.spans[currentSpan].points[currentWord] != undefined) {
            console.log('currentWord: ',currentWord,'currentSpan: ',currentSpan,'wordId: ',wordId)
            console.log(smilAudios.spans);

            if(audios32[0].currentTime >= parseFloat(smilAudios.spans[currentSpan].points[currentWord].start)){
                //console.log(smilAudios.spans[currentSpan].id);
                // spanElement.innerHTML = originalHtml;
                
                console.log('currentWord: ',currentWord,'currentSpan: ',currentSpan,'wordId: ',wordId)
                // spanElement = document.getElementById(smilAudios.spans[currentSpan].id);
                // spanText = spanElement.innerText;
                // spanHtml = spanElement.innerHTML;
                // originalHtml = spanHtml;
                // var splitText = spanText.split(' ');
                // var text = splitText[currentWord];
                // var index = spanHtml.indexOf(text);     
                // if (index >= 0) { 
                //console.log(spanHtml);
                // spanHtml = spanHtml.substring(0,index) + "<span class='audio-highlight'>" + spanHtml.substring(index,index+text.length) + "</span>" + spanHtml.substring(index + text.length);
                // spanElement.innerHTML = spanHtml;
                    
                //  document.getElementById(wordId).classList.remove('audio-highlight')
                 if(document.getElementById(wordId) != null){
                    document.getElementById(wordId).classList.remove('audio-highlight')
                    wordId = smilAudios.spans[currentSpan].points[currentWord].textId;
                 }
                 
                    if(document.getElementById(wordId) != null){
                        
                        
                        document.getElementById(wordId).classList.add('audio-highlight')
                        if(smilAudios.spans[currentSpan].points[currentWord+1] != undefined){
                            currentWord++;
                        }else{
                            if(smilAudios.spans[currentSpan+1] !=undefined){
                                currentSpan++;
                                currentWord = 0;
                            }
                        }
                    }
                // }
                
            }
      //  }
            })
            audios32[0].addEventListener("ended", function(e){
            
                if(document.getElementById(wordId) != null){
                    document.getElementById(wordId).classList.remove('audio-highlight')
                }
                currentWord = 0;
                // var message = {
                //     message:'nextPagePlay',
                // };
                // window.postMessage(JSON.stringify(message));
                // playAudio = false;

                let buttons = document.querySelectorAll(".main-bt"); 
                buttons.forEach(function(bt) {
                    bt.disabled = false;
                });
            })
      //  }
}
    selectAudio = (e) => {
        let page_count = this.props.page_count;
        let book = this.props.book;

       // console.log(book.pages[page_count]);      
       
       if(e.target.value == "oneAudio") {
        book.pages[page_count].smilAudios = {};
       }
       else {
        book.pages[page_count].smilAudios = [];
       }
       book.pages[page_count].pageAudioType = e.target.value;


        this.props.dispatch({type:"page_type",pageType:e.target.value,book:book});
        if(e.target.value != "paragraphs") {
            document.getElementById("spansTable").style.display = "none";
        }
        else {
            document.getElementById("spansTable").style.display = "block";
        }
        if(e.target.value == "") {
            document.querySelectorAll(".upload-bt")[0].classList.add("disabled");
           /* let buttons = document.querySelectorAll(".main-bt");
            buttons.forEach(function(bt) {
                bt.disabled = true;
            });*/
        }
        else {
            document.querySelectorAll(".upload-bt")[0].classList.remove("disabled");
          /*  let buttons = document.querySelectorAll(".main-bt");
            buttons.forEach(function(bt) {
                bt.disabled = false;
            });*/
        }

    }
    oneAudio = (type) => {
       /* let spansList = this.props.spansList;
        let page_count = this.props.page_count;

        let selectedSpans = spansList[page_count];*/
    }
    mouneEnter = (e) => {
        if(document.getSelection().anchorNode != null) {
            console.log(document.getSelection());
        }
        /*
        let currentAudio = "";
        if(currentPage.pageAudioType == "paragraphs") {
            currentPage.smilAudios.forEach(function(item) {
                item.spans.forEach(function(miniSpan) {
                    if(miniSpan.id == startId) {    
                        console.log(startId);
                    }
                });
                //currentAudio = item.audioLink;
            });
        }*/

    }
    selectText = (e) => {
        let th = this;
        let book = this.props.book;
        let page_count = this.props.page_count;
        let spansList = this.props.spansList;
        //console.log(spansList);

        let noAllowed = new Array("header-region","story-page");
        //console.log(document.getSelection().anchorNode.parentNode.parentNode)
        if(document.getSelection().anchorNode != null) {
            //console.log(document.getSelection().anchorNode.parentNode.parentNode.id);
            //console.log(document.getSelection().focusNode.parentNode.parentNode.id);

            
            if(document.getSelection().anchorNode.id != undefined) {
                let id = document.getSelection().anchorNode.parentElement.id;
                if(noAllowed.indexOf(id) != -1) {
                    return;
                }
            }            
            
            let baseOffset = document.getSelection().baseOffset;
           // let extentOffset = document.getSelection().extentOffset;
           // let text = document.getSelection().anchorNode.nodeValue;
            //let selectedTxt = text.substring(baseOffset,extentOffset);
            let id = document.getSelection().anchorNode.parentNode.parentNode.id;
            let selectid = th.props.selectid;
            if(document.getElementById(id) != null) {
                //console.log("start: " + document.getSelection().anchorNode.parentElement.id);
                //console.log("end: " + document.getSelection().focusNode.parentElement.id);
                let startId = document.getSelection().anchorNode.parentNode.parentNode.id;
                let endId = document.getSelection().focusNode.parentNode.parentNode.id;
                
                /* view slicing */
                //console.log(document.getElementById(endId));
                let currentPage = book.pages[page_count];
                //

                let selectedIds = [];
                let currentSpans = spansList[page_count];
                let insert = false;
                currentSpans.forEach(function(item) {
                        if(item.id == startId) {
                            insert = true;
                        }
                        if(insert == true) {
                            //console.log(item);
                            if(item.value == "ff") {
                                return;
                            }
                            selectedIds.push(item);
                            if(startId == endId) {
                                insert = false;
                            }
                        }
                        if(item.id == endId) {
                            insert = false;
                        }

                        
                    });

                
                this.props.dispatch({type:"update_ids",selectedIds:selectedIds,selectedIndex:0})
                
                if(selectid != "") {
                    //document.getElementById(id).style.backgroundColor = "";
                    //this.props.dispatch({type:"update_selection",selectid:""});
                }
                //document.getElementById(id).style.backgroundColor = "red";
                //this.props.dispatch({type:"update_selection",selectid:id});
            }  
        }
    }

    prevPage = (e) => {
        let page_count = this.props.page_count;
        let next_page = page_count - 1;
        let pages = this.props.pages2;
        let pageHeaders = this.props.pageHeaders;
        if(next_page < 0){ 
            return;
        }

        var url_string = window.location.href;
        var url = new URL(url_string);
        let bookpageId = url.searchParams.get("id");

        let htmlpage = pages[next_page].trim();
        let htmlPageDom = new DOMParser().parseFromString(htmlpage, "text/html");


        let audios = this.props.audios;
        audios = [];
        htmlPageDom.querySelectorAll("#page-audios audio").forEach(function(item) {
            let src = item.getAttribute("src");
            src = src.replace("../","/static/"+ bookpageId +"/OEBPS/");
            audios.push(src);
        });

        this.props.dispatch({type:"update_audios",audios:audios});


        let page_ids = this.props.page_ids;
        this.setState({
            open:false
        });

        let th = this;




    
        htmlPageDom.querySelectorAll("img").forEach(function(item) {
            htmlpage = htmlpage.replace('../Images/','/static/'+ bookpageId +'/OEBPS/Images/');
        });
        htmlPageDom.querySelectorAll("audio").forEach(function(item) {
            htmlpage = htmlpage.replace('../','/static/'+ bookpageId +'/OEBPS/');
        });

        th.props.dispatch({type:"update_point_index",pointIndex:false});

        th.props.dispatch({type : "load_page",htmlpage:"",page_count:next_page,selectid:"",page_ids:page_ids});
        setTimeout(() => {

        th.props.dispatch({type : "load_page",htmlpage:htmlpage,page_count:next_page,selectid:"",page_ids:page_ids});

        let header2 = new DOMParser().parseFromString(pageHeaders[next_page], "text/xml");
        let cssLinks = [];



        header2.querySelectorAll("link").forEach(function(cssfile) {

            var link = cssfile.href;
            link = link.replace("/book/","/static/"+ bookpageId +"/OEBPS/");


            cssLinks.push(<link href={link} rel='stylesheet' />);
        });
       /* header2.querySelectorAll("script").forEach(function(scriptFile) {

            var link = scriptFile.src;
            link = link.replace("/book/","/static/"+ bookpageId +"/OEBPS/");


            cssLinks.push(<script src={link} type='text/javascript' ></script>);
        });*/
        header2.querySelectorAll("style").forEach(function(cssfile) {
            cssLinks.push(<style>{cssfile.innerHTML}</style>);
        });
        th.setState({
            cssLinks:cssLinks
        });
        }, 50);

        let type = th.props.book.pages[next_page].pageAudioType;
        let smilAudios = th.props.book.pages[next_page].smilAudios;

    }
    buttonsStatus = (e) => {
        
    }
    nextPage = (e) => {
        let id = this.props.selectid; 

        if(id != "" ) {
            document.getElementById(id).style.backgroundColor = "";
        }
        let page_count = this.props.page_count;
        let next_page = page_count + 1;
        let pages = this.props.pages2;
        let pageHeaders = this.props.pageHeaders;
        if(next_page == pages.length){
            return;
        }
        let htmlpage = pages[next_page].trim();
        let htmlPageDom = new DOMParser().parseFromString(htmlpage, "text/html");
        
        let audios = this.props.audios;
        audios = [];
        //console.log(htmlPageDom.querySelectorAll("#page-audios audio"));
        var url_string = window.location.href;

        var url = new URL(url_string);
    
        let bookpageId = url.searchParams.get("id");
    
        
        htmlPageDom.querySelectorAll("#page-audios audio").forEach(function(item) {
            let src = item.getAttribute("src");
            src = src.replace("../","/static/"+ bookpageId +"/OEBPS/Images/");

            audios.push(src);
        });
        
        this.props.dispatch({type:"update_audios",audios:audios});
        let page_ids = this.props.page_ids;
        this.setState({
            open:false
        });
        let th = this;
        th.props.dispatch({type:"update_point_index",pointIndex:false});
        

       // th.props.dispatch({type : "load_page",htmlpage:"",page_count:next_page,selectid:"",page_ids:page_ids});

        
    //th.props.dispatch({type : "load_page",htmlpage:htmlpage,page_count:next_page,selectid:"",page_ids:page_ids});

    htmlPageDom.querySelectorAll("img").forEach(function(item) {
        htmlpage = htmlpage.replace('../Images/','/static/'+ bookpageId +'/OEBPS/Images/');
    });
    htmlPageDom.querySelectorAll("audio").forEach(function(item) {
        htmlpage = htmlpage.replace('../','/static/'+ bookpageId +'/OEBPS/');
    });
        th.props.dispatch({type : "load_page2",htmlpage:htmlpage,page_count:next_page,selectid:""}); 
    
            let header2 = new DOMParser().parseFromString(pageHeaders[next_page], "text/xml");
            let cssLinks = [];
    
            header2.querySelectorAll("link").forEach(function(cssfile) {
                var link = cssfile.href;
                link = link.replace("/book/","/static/"+ bookpageId +"/OEBPS/");
    
                cssLinks.push(<link href={link} rel='stylesheet' />);
            });
            header2.querySelectorAll("style").forEach(function(cssfile) {
                cssLinks.push(<style>{cssfile.innerHTML}</style>);
            });
            
           /* header2.querySelectorAll("script").forEach(function(scriptFile) {

                var link = scriptFile.src;
                link = link.replace("/book/","/static/"+ bookpageId +"/OEBPS/");
    
    
                cssLinks.push(<script src={link} type='text/javascript' />);
            });*/
            th.setState({
                cssLinks:cssLinks
            });            
    }
    paragraphSlicing = (next_page = "") => {
  /*     let page_count = this.props.page_count;
        let book = this.props.book;
        if(next_page != "") {
            page_count = next_page;
        }
    
        let currentPage = book.pages[page_count];
        currentPage.smilAudios.forEach(function(smilItem) {
            smilItem.spans.forEach(function(minSpan) {
                let selectedSpan = minSpan.id.replace("#","");
                setTimeout(() => {
                    console.log();
                    document.getElementById(selectedSpan).style.backgroundColor = "green";
                    let coordinates = document.getElementById(selectedSpan).getBoundingClientRect();
                    let dom2 = document.createElement("div");
                    console.log(coordinates)
                    dom2.style.position = "absolute";
                    dom2.style.zIndex = "111111";
                    
                    console.log(document.getElementById(selectedSpan).style.top);

                    dom2.style.top = document.getElementById(selectedSpan).style.top;
                    dom2.style.left = document.getElementById(selectedSpan).style.left;
                    dom2.innerHTML = "<a href='#'>Edit</a>";
                    document.getElementById("story-page").appendChild(dom2);
                }, 1000);
            })

        });*/
    }
    perpareFiles = (xmlString) => {
        let th = this;
        setTimeout(() => {
            th.setState({
                open:false
            });
        }, 600);
       
        let spine_list = "";
        let manifest_list = "";
        let spines = [];
        let page_ids = [];
        let pages2 = [];
        let pagesWidth = [];
        let pageHeaders = [];
        let count = 0;
        let htmlpage = [];
        let pagedims = [];
        let cssLinks = [];
        let book = this.props.book;
        let inde = 0;
        var url_string = window.location.href;
        let tempPages = []
        

        var url = new URL(url_string);
        let opf_link = url.searchParams.get("opf_file");
        let opf_dir = opf_link.replace("content.opf","");
        //let opf_dir = localStorage.getItem("opf_dir");
        let spansList = [];
        let spanCount = 0;
        let checkBook = this.checkBook;
        let pageDirs = [];



        let doc = new DOMParser().parseFromString(xmlString, "text/xml");

        let spine_list2 = doc.querySelector("spine");
        let page_direct = spine_list2.getAttribute("page-progression-direction");

        if(page_direct == null) {
            page_direct = "ltr";
        }

        spine_list = doc.querySelectorAll("spine itemref");
        manifest_list = doc.querySelectorAll("manifest item");
        
        for(let i=0;i<spine_list.length;i++) {
            spines.push(spine_list[i].getAttribute("idref"));
            let properties = spine_list[i].getAttribute("properties");
            
            if(properties != null) {
                pageDirs[i] = {};
                pageDirs[i].properties = properties;
            }
            else {
                if(page_direct == "rtl") {
                    if(i % 2 == 0) {
                        pageDirs[i] = {};
                        pageDirs[i].properties = "page-spread-left";
                    }
                    else {
                        pageDirs[i] = {};
                        pageDirs[i].properties = "page-spread-right";
                    }
                }
                else if(page_direct == "ltr") {
                    if(i % 2 == 0) {
                        pageDirs[i] = {};
                        pageDirs[i].properties = "page-spread-right";
                    }
                    else {
                        pageDirs[i] = {};
                        pageDirs[i].properties = "page-spread-left";
                    }
                }
            }

            
        }

            manifest_list.forEach(function(manifest_item,pageIndex) {
            let id = manifest_item.id;
            let href = manifest_item.getAttribute("href");
            let media_type = manifest_item.getAttribute("media-type");

            if(media_type == "text/css") {
                //cssLinks.push(<link href={opf_dir + href} rel='stylesheet' />);
            }

            if(spines.indexOf(id) != -1) {
                
                let page_id = opf_dir + manifest_item.getAttribute("href");
                let page_id2 = manifest_item.getAttribute("href");

                page_ids.push(page_id);

                
                if(checkBook == false) {
                    
                    book.pages[inde] = {};
                book.pages[inde].pageId = inde;
                book.pages[inde].pagePath = "/" + page_id2;
                
                book.pages[inde].smilAudios = {};
                book.pages[inde].voiceRecords = [];
                book.pages[inde].notes = [];
                book.pages[inde].pageAudioType = "builtInAudio";
                book.pages[inde].drawings = [];
                book.pages[inde].highlights = [];
                //book.pages[inde]['smilAudios']['audioLink'] = "";
                //book.pages[inde]['smilAudios']['spans'] = [];


                inde++;
                }
                
                // axios.get(page_id).then(function(res) {
                
                // }).catch(error => {
                // console.log(error)
                // });

            }
        });
        if(checkBook == false){
            th.props.dispatch({type:"update_book_first",book:book});
        }
        page_ids.forEach(page=>{
            tempPages.push('')
        })
        let countPages = 0
        // page_ids.forEach((page_id,pageIndex) => {
        this.setState({
            open:true
        })
        this._loadPage(countPages,tempPages,spines,pageDirs,page_ids,page_direct)
            // axios.get(page_id).then(function(res) {
            //     if(pageIndex == page_ids.length-1){
            //         tempPages[pageIndex] = res.data
            //         // tempPages.push(res.data)
            //         th._editPages(tempPages,spines,pageDirs,page_ids)
            //         console.log(tempPages)
            //     }else{
            //         tempPages[pageIndex] = res.data
            //         // console.log(tempPages)
            //     }
            // })
        // });
        th.setState({
            spines:spines
        });
    }
    _loadPage(count,tempPages,spines,pageDirs,page_ids,page_direct) {
        axios.get(page_ids[count]).then(res=> {
            if(count == page_ids.length-1){
                tempPages[count] = res.data;



                this._editPages(tempPages,spines,pageDirs,page_ids,page_direct)
                this.setState({
                    open:false
                })
              //  console.log(tempPages)
            } else {
                tempPages[count] = res.data
                let doc2 = new DOMParser().parseFromString(res.data, "text/xml");

                count++;
                this._loadPage(count,tempPages,spines,pageDirs,page_ids,page_direct);
                // console.log(tempPages)
            }
        })
    }
    playAudio = (e) => {
        

        let page_count = this.props.page_count;
        let book = this.props.book;
        let audioLink = book.pages[page_count].smilAudios.audioLink;
        let smilAudios = book.pages[page_count].smilAudios;
        let pageAudios = document.getElementsByTagName("audio");
        let currentWord = 0;
        if(smilAudios[0].spans[0].points[currentWord] == undefined) {
            return;
        }
        let buttons = document.querySelectorAll("button");
        buttons.forEach(function(buttonItem) {
            buttonItem.disabled = true; 
        });
            document.getElementById(smilAudios[0].spans[0].points[currentWord].textId).classList.add("yellow-hilight");
            pageAudios[0].currentTime = smilAudios[0].spans[0].points[currentWord].start;
            pageAudios[0].play();
        



        pageAudios[0].addEventListener("timeupdate",function(e) {
            
            console.log(smilAudios[0].spans[0].points[currentWord].textId);
            console.log(smilAudios[0].spans[0].points[currentWord].textId);

            if(smilAudios[0].spans[0].points[currentWord].end < pageAudios[0].currentTime) {
                document.getElementById(smilAudios[0].spans[0].points[currentWord].textId).classList.remove("yellow-hilight");
                if(smilAudios[0].spans[0].points[currentWord + 1] == undefined) {
                    pageAudios[0].pause();
                    buttons.forEach(function(buttonItem) {
                        buttonItem.disabled = false; 
                    });
                }
                else {
                    currentWord++;
                    document.getElementById(smilAudios[0].spans[0].points[currentWord].textId).classList.add("yellow-hilight");    
                }
            }
        });
        pageAudios[0].addEventListener("ended",function(e) {
        });

    }
    _editPages = (pages,spines,pageDirs,page_ids,page_direct) => {
        let th = this;
        let spine_list = "";
        let manifest_list = "";
        // let spines = [];
        //let page_ids = [];
        let pages2 = [];
        let pagesWidth = [];
        let pageHeaders = [];
        let count = 0;
        let htmlpage = [];
        let pagedims = [];
        let cssLinks = [];
        let classesList = [];
        let book = this.props.book;
        var url_string = window.location.href;

        

        var url = new URL(url_string);
        let opf_link = url.searchParams.get("opf_file");
        let bookpageId = url.searchParams.get("id");

        let opf_dir = opf_link.replace("content.opf","");
     
        //let opf_dir = localStorage.getItem("opf_dir");
        let spansList = [];
        let spanCount = 0;
        let checkBook = this.checkBook;
        // let pageDirs = [];
        let pageCount = 0;
        pages.forEach(page=>{
        let doc2 = new DOMParser().parseFromString(page, "text/xml");


        let spans = doc2.querySelectorAll("span");
        let pTags = doc2.querySelectorAll("p");

            let generateSpans = false;
        let spansTxt = "";

        pageCount++;
        spansList[spanCount] = [];
        if(doc2.querySelectorAll(".parent-list").length != 0) {
            spans = doc2.querySelectorAll("span");
        }

        spans.forEach(function(item) {
            if(doc2.querySelectorAll(".parent-list").length != 0) {
                if(item.classList.value == "parent-list") {
                    let spanItem = {};
                    spanItem.id = item.id;
                    spanItem.value = item.innerText;
                    if(spanItem.value.search("<!--") == -1) {
                        if(spanItem.innerText != "") {
                            spansList[spanCount].push(spanItem);
                        }
                    }

                }
            }
            else if(doc2.querySelectorAll("#parent-p1").length != 0) {
                if(item.id.search("textid") != -1) {
                    let spanItem = {};
                    spanItem.id = item.id;
                    spanItem.value = item.innerText;
                    if(spanItem.value.search("<!--") == -1) {
                        if(spanItem.innerText != "") {
                            spansList[spanCount].push(spanItem);
                        }
                    }

                }
            }
            else if(item.classList.value != "word-syncing") {
                let spanItem = {};
                spanItem.id = item.id;
                spanItem.value = item.innerText;
                if(spanItem.value.search("<!--") == -1) {
                    if(spanItem.innerText != "") {

                        spansList[spanCount].push(spanItem);
                    }
                }
            }
            
        });
        spanCount++;
        
        let body = doc2.querySelectorAll("body")[0];

        let bodyHtml = doc2.querySelectorAll("body")[0].innerHTML;
        let bodyHeader = doc2.querySelectorAll("head")[0].outerHTML;
        let classes = "";
        if(body.classList.length != 0) {
            classes = body.classList.value;
        }
        classesList.push(classes);
        let width = body.style.width;
        let height = body.style.height;


        document.getElementById("story-page").style.width = width;
        document.getElementById("story-page").style.height = height;
        pages2.push(bodyHtml);
        pageHeaders.push(bodyHeader);


    
        pagedims[count] = {};
        pagedims[count].width = width;
        pagedims[count].height = height;
        //console.log(pagedims);
        count++;
        //book.bookDirection = page_direct;


            book.pages.forEach(function(item,index) {


                if(book.pages[index+1] !=undefined){
                    if(index == 0) {
                        //cover direction
                        book.pages[index].pageSpread = "";
                        if(page_direct == "ltr") {
                            book.pages[index].pageSpreadDirection = "right";
                        }
                        else {
                            book.pages[index].pageSpreadDirection = "left";
                        }
                    }
                    else {
                        
                        //console.log(book.pages);
                        //console.log(pageDirs);
                        
                        if(pageDirs[index].properties == "page-spread-left") {
                            book.pages[index].pageSpreadDirection = "left";
                        }
                        else if(pageDirs[index].properties == "page-spread-right") {
                            book.pages[index].pageSpreadDirection = "right";
                        }
                        if(page_direct == "rtl") {
                            if(book.pages[index].pageSpreadDirection == "right") {
                                book.pages[index].pageSpread = book.pages[index + 1].pagePath;
                            } else if(book.pages[index].pageSpreadDirection == "left") {
                                book.pages[index].pageSpread = book.pages[index - 1].pagePath;
                            }
                        } else if(page_direct == "ltr") {
                            if(book.pages[index].pageSpreadDirection == "left") {
                                book.pages[index].pageSpread = book.pages[index + 1].pagePath;
                            } else if(book.pages[index].pageSpreadDirection == "right") {
                                book.pages[index].pageSpread = book.pages[index - 1].pagePath;
                            }
                        }
                        
                        
                    }
                }

            });

            

            htmlpage = pages2[0].trim();


            let di1 = new DOMParser().parseFromString(htmlpage, "text/html");


            let audios = [];
            audios = [];
            di1.querySelectorAll("#page-audios audio").forEach(function(item) {
                let src = item.getAttribute("src");
                src = src.replace("../",'/static/'+ bookpageId +'/OEBPS/');
                audios.push(src);
            });
            th.props.dispatch({type:"update_audios",audios:audios});
            //console.log('htmlpage')
            //console.log(htmlpage)
            di1.querySelectorAll("img").forEach(function(item) {
                htmlpage = htmlpage.replace('../Images/','/static/'+ bookpageId +'/OEBPS/Images/');
            });
            di1.querySelectorAll("audio").forEach(function(item) {
                htmlpage = htmlpage.replace('../','/static/'+ bookpageId +'/OEBPS/');
            });

            th.props.dispatch({type : "load_page",htmlpage:htmlpage,page_count:0,selectid:"",page_ids:page_ids});
            th.props.dispatch({type: "update_book",book:book,cssLinks:cssLinks,spansList:spansList,pages2:pages2,pageHeaders:pageHeaders});
            localStorage.setItem("htmlpage",htmlpage);
            let storyPage = document.querySelectorAll("#story-page")[0];
           /* let storyPageWidth = window.getComputedStyle(storyPage).getPropertyValue("width");
            let storyPageHeight =window.getComputedStyle(storyPage).getPropertyValue("height");
            obj.width = storyPageWidth;
            obj.height = storyPageHeight;*/


            th.props.dispatch({type: "update_page_width",pagesWidth:pagesWidth,classesList:classesList});
            
            //let header2 =  pageHeaders[0];
            let header2 = new DOMParser().parseFromString(pageHeaders[0], "text/xml");
            let cssLinks = [];

            header2.querySelectorAll("link").forEach(function(cssfile) {
                var link = cssfile.href;
                link = link.replace("/book/","/static/"+ bookpageId +"/OEBPS/");

                cssLinks.push(<link href={link} rel='stylesheet' />);
            });
           /* header2.querySelectorAll("script").forEach(function(scriptFile) {

                var link = scriptFile.src;
                link = link.replace("/book/","/static/"+ bookpageId +"/OEBPS/");
    
    
                cssLinks.push(<script src={link} type='text/javascript' />);
            });*/

            let obj = {};
            let pageDimentions = header2.querySelectorAll("meta[name=viewport]")[0].content;

            let PageWidth = pageDimentions.split(",")[0].trim();
            let PageHeight = pageDimentions.split(",")[1].trim();
            obj.width = PageWidth.replace("width=","") + "px";
            obj.height = PageHeight.replace("height=","") + "px";
            pagesWidth.push(obj)


            document.getElementById("story-page").classList.value = classesList[0];
            document.getElementById("story-page").style.width = obj.width;
            document.getElementById("story-page").style.height = obj.height;

           // console.log(htmlpage);
            th.setState({
                htmlpage:htmlpage,
                cssLinks:cssLinks,
                page_ids:page_ids
                //doc:doc
            });


            setTimeout(() => {

                
                if(checkBook == true) {
                    if(book.pages[0].pageAudioType != "") {

                        


                    }
                    else {
                      //  document.querySelectorAll(".upload-bt")[0].classList.add("disabled");
          
                        let buttons = document.querySelectorAll(".main-bt");
                        buttons.forEach(function(bt) {
                            //bt.disabled = true;
                        });
                    }
                }
            }, 500);

        })
//        th.paragraphSlicing();
    }
    clearSlicing = () => {
        let page_count = this.props.page_count;
        let book = this.props.book;
        book.pages[page_count].smilAudios = {};
        book.pages[page_count].pageAudioType = "";
        this.props.dispatch({type:"update_data",book:book});
        this.props.dispatch({type:"update_audios",audios:[]});
        this.props.dispatch({type:"update_slicing",clearSlicing:1});
        this.props.dispatch({type:"update_slicing1",clearSlicing1:1});
        this.props.dispatch({type:"update_page_type",pageType:""});
    
          
                    let buttons = document.querySelectorAll(".main-bt");
                      buttons.forEach(function(bt) {
                          bt.disabled = true;
                      });
    }
    render() {
        return(<div>
            <div id="links-container">
            <div className="links">
                    <button onClick={this.prevPage}>Prev page</button>
                    <button onClick={this.nextPage}>Next page</button>
                    <button className="main-bt" onClick={this.playAudio}>Preview</button>
                </div>

                <div className="links-right">
                <div>{this.props.page_count + 1} / {this.props.page_ids.length}</div>                        
                </div>
            </div>
                <div id="story-page">{this.state.cssLinks}
                <div dangerouslySetInnerHTML={{ __html: this.props.htmlpage }}></div>
</div> 

                <Dialog open={this.state.open}>
        <DialogContent>
          <DialogContentText>
          <CircularProgress />

          </DialogContentText>
        </DialogContent>
      </Dialog>   
        </div>); 
    }
}
const mapStateToProps = state => ({
    audioLink: state.audioLink, 
    audioObj:state.audioObj,
    audioName:state.audioName,
    startTime:state.startTime,
    htmlpage:state.htmlpage,
    page_count:state.page_count,
    selectid:state.selectid,
    book:state.book,
    page_ids:state.page_ids,
    cssLinks:state.cssLinks,
    spansList:state.spansList,
    pageType:state.pageType,
    showTxts:state.showTxts,
    opf_dir:state.opf_dir,
    pages2:state.pages2,
    pageHeaders:state.pageHeaders,
    pagesWidth:state.pagesWidth,
    audios:state.audios, 
    classesList:state.classesList
}); 
export default connect(mapStateToProps) (story); 