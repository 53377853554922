import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';

// import Alert from '@material-ui/lab/Alert';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Paper from "@material-ui/core/Paper";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";
import { Link } from "react-router-dom";
import Popup from "../components/popup";
import { Color } from "react-input-color";

const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "Terms Conditions",
    link: "",
  },
];
class List extends Component {
  state = {
    open: false,
    formValid: false,
    rowsPerPage: 10,
    page: 0,
    data: [],
    en: '',
    arab: '',
    diplaymgs: 'none',
    widthmsg: '10vw',
    categoryData: [],
  };


  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
  };

  submitFunction = (e) => {

    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();

    formData.append("english", this.state.en);
    formData.append("arabic", this.state.arab);

    axios({
      url: `${backend_url}/termsConditions/update`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.setState({
        diplaymgs: 'block',
        widthmsg: '50vw'
      })
    });


  };

  componentDidMount() {
    let backend_url = process.env.REACT_APP_BACKEND_URL;

    axios({
      url: `${backend_url}/termsConditions/list`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        en: res.data.result.english,
        arab: res.data.result.arabic,
      })

    });
  }



  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="w-full flex items-center justify-between p-24">
            <h2>Terms Conditions</h2>
            <div className="flex items-center">
              <Button color="primary" variant="contained"
                onClick={(e) => {
                  this.submitFunction(e)
                }}
              >
                Save
                </Button>
            </div>
          </div>
        }
        content={
          <div className="p-24" style={{
            flex: 1,
          }}>
            <div style={{
              flexGrow: 1,
            }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div
                    style={{
                      color: '#fff',
                      fontSize: 20,
                      borderWidth: '1px',
                      borderColor: '#a5c73c',
                      backgroundColor:'#86a01a',
                      borderRadius: 5,
                      padding: 10,
                      width: this.state.widthmsg,
                      marginBottom: 15,
                      translate: 'all 2s',
                      display: this.state.diplaymgs,

                      width: '100%',
                    }}> ✓ Saved successfully!</div>
                </Grid>
                <Grid item xs={12}>

                  <TextareaAutosize
                    style={{
                      width: '100%',
                      height: '20vw',
                      marginRight: '5vw',
                      borderWidth: 1,
                      borderColor: '#111',
                      borderRadius: 5,
                      padding: 8,
                      fontSize: '18px'
                    }}
                    aria-label="maximum height"
                    defaultValue={this.state.en}
                    onChange={(e) => {
                      this.handleChange(e, "en");
                    }}

                  />
                </Grid>
                <Grid item xs={12}>
                  <TextareaAutosize
                    style={{
                      width: '100%',
                      height: '20vw',
                      marginRight: '2vw',
                      textAlign: 'right',
                      borderColor: '#111',
                      borderWidth: 1,
                      borderRadius: 5,
                      padding: 8,
                      direction: 'rtl',
                      fontSize: '18px'
                    }}
                    aria-label="maximum height"
                    defaultValue={this.state.arab}
                    onChange={(e) => {
                      this.handleChange(e, "arab");
                    }}

                  />
                </Grid>

              </Grid>
            </div>




            <Popup />
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(List);
