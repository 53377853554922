import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import Formsy from "formsy-react";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";

import Alert from '@material-ui/lab/Alert';

const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "Avatar",
    link: `${process.env.REACT_APP_FRONT_URL}avatar/list`,
  },
  {
    title: "Add",
    link: "",
  },
];

class Add extends Component {
  state = {
    formValid: false,
    hexcode: "#222",
    gender: "",
    disabled: false,
    avatar: "",
    showErrorMsg2: false,
    ErrorMsg: '',
    ErrorMsg2: ''

  };
  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
  };
  handleSubmit = (model) => {

    if (this.state.gender == "") {
      this.setState({
        ErrorMsg2: 'Please choose a gender',
        showErrorMsg2: true
      })
    }
    else {
      this.setState({
        ErrorMsg2: '',
        showErrorMsg2: false
      })
    }



    if (this.state.avatar == "") {
      this.setState({
        ErrorMsg: 'Please select avatar!',
        showErrorMsg2: true
      })
    }
    else {
      this.setState({
        ErrorMsg: '',
        showErrorMsg2: false
      })
    }


    if (!this.state.showErrorMsg2) {
      let backend_url = process.env.REACT_APP_BACKEND_URL;
      let formData = new FormData();

      formData.append("hexcode", this.state.hexcode);
      formData.append("gender", this.state.gender);
      formData.append("imgUpload", this.state.avatar);

      axios({
        url: `${backend_url}/avatar/add`,
        data: formData,
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        this.props.history.push('/frontend/avatar/list');
      });
    }

  };
  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };

  fileUpload = (e, id) => {
    let image = document.getElementById(id);
    image.src = URL.createObjectURL(e.target.files[0]);
    this.setState({
      avatar: e.target.files[0],
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="px-24">
            <h2>Add Avatar</h2>
          </div>
        }
        content={
          <div className="p-24">
            <div className="max-w-lg">
              <Formsy
                onValidSubmit={this.handleSubmit}
                onValid={this.enableButton}
                onInvalid={this.disableButton}
                ref={"cateogry_add"}
                className="flex flex-col p6-12"
              >
                {this.state.showErrorMsg2 && (
                  <Alert severity="error">
                    <ul>
                      <li>
                        {this.state.ErrorMsg2}
                      </li>
                      <li>
                        {this.state.ErrorMsg}
                      </li>
                    </ul>
                  </Alert>
                )}
                <div className="flex flex-row justify-between py-20">

                  <SelectFormsy
                    className="w-5/12"
                    name="gender"
                    label="Gender"
                    value={this.state.gender}
                    onChange={(e) => {
                      this.handleChange(e, "gender");
                    }}
                  >

                    <MenuItem value="m">
                      Male
                  </MenuItem>
                    <MenuItem value="f">
                      Female
                  </MenuItem>
                  </SelectFormsy>
                </div>
                <input
                  type="color"
                  value={this.state.hexcode}
                  onChange={(e) => {
                    this.handleChange(e, "hexcode");
                  }}
                />

                <div className="flex flex-row justify-between py-20">
                  <div className="flex justify-between items-center w-5/12">
                    <Button variant="contained" component="label">
                      Upload avatar
                      <input
                        type="file"
                        onChange={(e) => {
                          this.fileUpload(e, "cover-image");
                        }}
                        style={{ display: "none" }}
                      />
                    </Button>
                    <img
                      alt=""
                      id="cover-image"
                      title="cover-image"
                      style={{ maxWidth: "30%" }}
                      src="frontend/assets/images/custom/empty-image-thumb.png"
                    />
                  </div>
                </div>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="ml-auto my-16"
                  aria-label="Add"
                  disabled={!this.state.formValid}
                >
                  Add
                </Button>
              </Formsy>
            </div>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(Add);
