import Login from "./Login"; 

export const UserConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${process.env.REACT_APP_FRONT_URL}`,
      component: Login,
    } 
  ],
};
