import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import Formsy from "formsy-react";
import Breadcrumb from "./../components/Breadcrumb";
import axios from "axios";

const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "Avatar",
    link: `${process.env.REACT_APP_FRONT_URL}avatar/list`,
  },
  {
    title: "Edit",
    link: "",
  },
];

class Edit extends Component {
  state = {
    formValid: false,
    hexcode: "#222",
    gender: "",
    disabled: false,
    avatar1: "",
  };
  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
  };


  handleSubmit = (model) => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();

    formData.append("hexcode", this.state.hexcode);
    formData.append("gender", this.state.gender);
    if (this.state.avatarUpload != undefined)
      formData.append("imgUpload", this.state.avatarUpload);
    formData.append("id", this.props.match.params.id);

    axios({
      url: `${backend_url}/avatar/update`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.props.history.push('/frontend/avatar/list');
    });
  };

  fileUpload = (e, id) => {
    let image = document.getElementById(id);
    image.src = URL.createObjectURL(e.target.files[0]);
    this.setState({
      avatarUpload: e.target.files[0],
    });
  };
  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };

  componentDidMount() {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let id = this.props.match.params.id;

    let data = {
      id: id,
    };

    axios({
      url: `${backend_url}/avatar/get`,
      data: data,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState(res.data.result);
      let image = document.getElementById('cover-image');
      image.src = process.env.REACT_APP_BACKEND_URL + '/public/' + this.state.imgUpload;
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="px-24">
            <h2>Edit Avatar</h2>
          </div>
        }
        content={
          <div className="p-24">
            <div className="max-w-lg">
              <Formsy
                onValidSubmit={this.handleSubmit}
                onValid={this.enableButton}
                onInvalid={this.disableButton}
                ref={"avatar_add"}
                className="flex flex-col p6-12"
              >
                <div className="flex flex-row justify-between py-20">

                  <SelectFormsy
                    className="w-5/12"
                    name="gender"
                    label="Gender"
                    value={this.state.gender}
                    onChange={(e) => {
                      this.handleChange(e, "gender");
                    }}
                  >
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="m">
                      Male
</MenuItem>
                    <MenuItem value="f">
                      Female
</MenuItem>
                  </SelectFormsy>
                </div>
                <input
                  type="color"
                  value={this.state.hexcode}
                  onChange={(e) => {
                    this.handleChange(e, "hexcode");
                  }}
                />

                <div className="flex flex-row justify-between py-20">
                  <div className="flex justify-between items-center w-5/12">
                    <Button variant="contained" component="label">
                      Upload avatar
    <input
                        type="file"
                        onChange={(e) => {
                          this.fileUpload(e, "cover-image");
                        }}
                        style={{ display: "none" }}
                      />
                    </Button>
                    <img
                      alt=""
                      id="cover-image"
                      title="cover-image"
                      style={{
                        width: '100px',
                        height: '100px',
                        borderRadius: '50%',
                        overflow: 'hidden',
                        // backgroundColor: row.hexcode,
                        objectFit: 'cover'
                      }}
                      src=""
                    />

                  </div>
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="ml-auto my-16"
                  aria-label="Add"
                  disabled={!this.state.formValid}
                >
                  Edit
                </Button>
              </Formsy>
            </div>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(Edit);
