import React from 'react';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { Collapse } from '@material-ui/core';
//import DownloadData from './export'
import FusePageCarded from '@fuse/core/FusePageCarded';
import TablePagination from '@material-ui/core/TablePagination';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

const baseLink = process.env.REACT_APP_BACKEND_URL;
const baseLink2 = 'frontend';

let breadcrumbAdd = [
    {
        title: "Book",
        link: "/book/list"
    },
    {
        title: "List",
        link: ""
    }
]
class bookList extends React.Component {
    state = {
        data: [],
        jsonData: {},
        selectedId: "",
        openPopup: false,
        datanew: [],
        filterData: [],
        publisherList: [],
        categorySelect: [],
        ageCatList: [],
        level21List: [],
        pypList: [],
        publisherSelect: [],
        bookSearchText: "",
        showFilter: false,
        notCat: false,
        bookExportList: [],
        page: 0,
        rowsPerPage: 12
    }
    bookCount = 977;
    exportAllBooks = (e) => {
        let datanew = this.state.datanew;

        datanew = datanew.filter((item) => {
            return (item.opf_file !== undefined && item.opf_file !== "" && item.opf_file !== null);
        });
        this.exportBookList = datanew;
        this.doneExportBookList = [];

        // console.log(this.exportBookList)

        //datanew.forEach((bookItem,indx) => {
        let currentCount = this.bookCount;
        this._spreadView(this.exportBookList[currentCount]._id, this.exportBookList[currentCount].opf_file, 0, this.bookCount, "multiple");
        // });
        this.setState({
            openPopup: true
        });

        //  this._spreadViewAll(datanew[0]._id,datanew[0].opf_file,0,datanew,"multiple");

        /* datanew.forEach((bookItem) => {
             //
         });*/

    }
    exportBookList = 0;
    doneExportBookList = [];
    componentDidMount() {

        let th = this;

        // document.getElementById("header-search").addEventListener("keyup",function(e) {
        //     th.searchText(e.target.value);
        // });

        // axios({
        //     method:'post',
        //     url:baseLink + "/publisher/list",
        //     config: { headers: {'Content-Type': 'multipart/form-data' }}
        //         }).then((res) => {
        //             let publisherData = [];
        //             res.data.forEach(function(item) {
        //                 let obj = {};
        //                 obj.label = item.name;
        //                 obj.value = item._id;
        //                 publisherData.push(obj)
        //             });
        //             this.setState({
        //                 publisherList:publisherData
        //             });
        //     });


        //     axios({
        //         method:'post',
        //         url:baseLink + "/category/list2",
        //         config: { headers: {'Content-Type': 'multipart/form-data' }}
        //             }).then((res) => {
        //                 let ar12Cateogry = [];
        //                 let ageCategory = [];
        //                 let pypCategory = [];
        //                 res.data.cat_50.forEach(function(item) {
        //                     let obj = {};
        //                     obj.label = item.nameEn;
        //                     obj.value = item._id;
        //                     ar12Cateogry.push(obj)
        //                 });
        //                 res.data.cat_16.forEach(function(item) {
        //                     let obj = {};
        //                     obj.label = item.nameEn;
        //                     obj.value = item._id;
        //                     pypCategory.push(obj)
        //                 });
        //                 res.data.cat_25.forEach(function(item) {
        //                     let obj = {};
        //                     obj.label = item.nameEn;
        //                     obj.value = item._id;
        //                     ageCategory.push(obj)
        //                 });
        //                 this.setState({
        //                     ar12Cateogry:ar12Cateogry,
        //                     pypCategory:pypCategory,
        //                     ageCategory:ageCategory
        //                 });
        //         });

        axios({
            method: 'get',
            url: baseLink + "/book/list",
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        }).then(res => {
            let output = [];
            let bookExportList = [];

            res.data.forEach(function (item, i) {
                bookExportList[i] = {};
                bookExportList[i].id = item._id;
                bookExportList[i].name = item.name;

                bookExportList[i].minTimeSpent = item.minTimeSpent;


                //console.log(item.exportedDate,item.updatedDate);
                if (item.exportedDate !== undefined && item.updatedDate !== undefined) {
                    if (item.updatedDate > item.exportedDate) {
                        res.data[i].publish_status = 0;
                    }
                    else if (item.updatedDate < item.exportedDate) {
                        res.data[i].publish_status = 1;
                    }
                } else {

                    if (item.exportedDate !== undefined) {
                        res.data[i].publish_status = 1;
                    }
                    else {
                        res.data[i].publish_status = -1;
                    }

                }
            });


            this.setState({
                datanew: res.data,
                filterData: res.data,
                bookExportList: bookExportList
            });

        });

    }
    deleteBook = (e, id) => {
        e.preventDefault();
        this._popupOpen(true, id);
    }


    _spreadView(bookId, opfFile, e, indx, modeType = "") {
        e.preventDefault();



        let th = this;


        if (modeType !== "multiple") {
            this.setState({
                openPopup: true
            });
        }


        let jsonFilePath = opfFile.replace('content.opf', '') + 'data.json?id=' + Math.floor(Math.random() * 9000);
        let jsonFilePath2 = opfFile.replace('content.opf', '') + 'data.json?id=' + Math.floor(Math.random() * 9000);

        let pageUrls = [];
        const formData = new FormData();
        formData.append('id', bookId);
        axios.post(baseLink + '/book/app/get', formData)

            .then(bookData => {
                axios.get(jsonFilePath)
                    .then(json => {

                        // res.data.pages.forEach(page=>{
                        // })
                        // pageIds.push

                        let type = typeof json.data;

                        if (type === "string") {

                            this._loadOpf(opfFile, bookId, jsonFilePath2, indx, modeType);


                        } else {
                            this.setState({
                                jsonData: json.data
                            })
                            let contentOpfPath = bookData.data[0].opf_file

                            if (window.location.hostname === "localhost") {
                                contentOpfPath = contentOpfPath.replace('/content.opf', '').replace(':4000', ':3000')
                            } else {
                                contentOpfPath = contentOpfPath.replace('/content.opf', '').replace(':4000', '')
                            }
                            json.data.pages.forEach(page => {
                                pageUrls.push(contentOpfPath + page.pagePath)
                            })
                            let pagesHtml = []
                            this._loadPage(pageUrls, 0, pagesHtml, bookId, indx, modeType)

                        }


                    }).catch(err => {

                        this._loadOpf(opfFile, bookId, jsonFilePath2, indx, modeType);



                    });
            })
        if (modeType !== "multiple") {
            e.preventDefault();
        }


    }
    _loadOpf = (opfFile, bookId, jsonFilePath, indx, type) => {
        let pageUrls = [];
        let th = this;
        let book = {};
        book.pages = [];
        book.bookId = bookId;
        book.title = "book title";


        axios.get(opfFile + "?id=" + Math.floor(Math.random() * 9000)).then(res => {
            let doc = new DOMParser().parseFromString(res.data, "text/xml");
            let spine_list = doc.querySelectorAll("spine itemref");
            let spine_list2 = doc.querySelector("spine");

            let manifest_list = doc.querySelectorAll("manifest item");
            let spines = [];
            let page_ids = [];
            let inde = 0;
            let page_direct = spine_list2.getAttribute("page-progression-direction");

            if (page_direct === null) {
                page_direct = "ltr";
            }
            let pageDirs = [];

            for (let i = 0; i < spine_list.length; i++) {
                spines.push(spine_list[i].getAttribute("idref"));

                let properties = spine_list[i].getAttribute("properties");

                if (properties !== null) {
                    pageDirs[i] = {};
                    pageDirs[i].properties = properties;
                }
                else {
                    if (page_direct === "rtl") {
                        if (i % 2 === 0) {
                            pageDirs[i] = {};
                            pageDirs[i].properties = "page-spread-left";
                        }
                        else {
                            pageDirs[i] = {};
                            pageDirs[i].properties = "page-spread-right";
                        }
                    }
                    else if (page_direct === "ltr") {
                        if (i % 2 === 0) {
                            pageDirs[i] = {};
                            pageDirs[i].properties = "page-spread-right";
                        }
                        else {
                            pageDirs[i] = {};
                            pageDirs[i].properties = "page-spread-left";
                        }
                    }
                }

            }
            manifest_list.forEach((manifest_item, pageIndex) => {
                let id = manifest_item.id;
                if (spines.indexOf(id) !== -1) {
                    page_ids.push(manifest_item.getAttribute("href"));
                    book.pages[inde] = {};
                    book.pages[inde].pageId = inde;
                    book.pages[inde].pagePath = "/" + manifest_item.getAttribute("href");

                    book.pages[inde].smilAudios = [];
                    book.pages[inde].voiceRecords = [];
                    book.pages[inde].notes = [];
                    book.pages[inde].pageAudioType = "";
                    book.pages[inde].drawings = [];
                    book.pages[inde].highlights = [];
                    inde++;
                }
            });

            book.pages.forEach(function (item, index) {


                if (book.pages[index + 1] != undefined) {
                    if (index === 0) {
                        //cover direction
                        book.pages[index].pageSpread = "";
                        if (page_direct === "ltr") {
                            book.pages[index].pageSpreadDirection = "right";
                        }
                        else {
                            book.pages[index].pageSpreadDirection = "left";
                        }
                    }
                    else {

                        //console.log(book.pages);
                        //console.log(pageDirs);

                        if (pageDirs[index].properties === "page-spread-left") {
                            book.pages[index].pageSpreadDirection = "left";
                        }
                        else if (pageDirs[index].properties === "page-spread-right") {
                            book.pages[index].pageSpreadDirection = "right";
                        }
                        if (page_direct === "rtl") {
                            if (book.pages[index].pageSpreadDirection === "right") {
                                book.pages[index].pageSpread = book.pages[index + 1].pagePath;
                            } else if (book.pages[index].pageSpreadDirection === "left") {
                                book.pages[index].pageSpread = book.pages[index - 1].pagePath;
                            }
                        } else if (page_direct === "ltr") {
                            if (book.pages[index].pageSpreadDirection === "left") {
                                book.pages[index].pageSpread = book.pages[index + 1].pagePath;
                            } else if (book.pages[index].pageSpreadDirection === "right") {
                                book.pages[index].pageSpread = book.pages[index - 1].pagePath;
                            }
                        }


                    }
                }

            });
            let opfFile_link = opfFile.replace("content.opf", "");
            let bodyFormData = new FormData();
            bodyFormData.set("base_path", opfFile_link);
            bodyFormData.set("book", JSON.stringify(book));

            axios({
                method: 'post',
                url: baseLink + '/book/update_json',
                data: bodyFormData,
                config: { headers: { 'Content-Type': 'multipart/form-data' } }
            }).then(function (res) {


                const formData = new FormData();
                formData.append('id', bookId);
                axios.post(baseLink + '/book/app/get', formData)

                    .then(bookData => {
                        axios.get(jsonFilePath)
                            .then(json => {
                                th.setState({
                                    jsonData: json.data
                                })
                                // res.data.pages.forEach(page=>{
                                // })
                                // pageIds.push
                                let contentOpfPath = bookData.data[0].opf_file
                                if (window.location.hostname === "localhost") {
                                    contentOpfPath = contentOpfPath.replace('/content.opf', '').replace(':4000', ':3000')
                                } else {
                                    contentOpfPath = contentOpfPath.replace('/content.opf', '').replace(':4000', '')
                                }

                                // contentOpfPath = contentOpfPath.replace('/content.opf','').replace(':4000','')
                                json.data.pages.forEach(page => {
                                    pageUrls.push(contentOpfPath + page.pagePath)
                                })
                                let pagesHtml = []
                                th._loadPage(pageUrls, 0, pagesHtml, bookId, indx)
                            });
                    });

            });



        });
    }
    _loadPages(pageUrls) {
        // const formData = new FormData();
        // formData.append('id', this.props.bookId);
        // axios.post('http://localhost:4000/book/app/get')
        // .then
        // axios.get(page_ids[count]).then(res=> {
        //     if(count === page_ids.length-1){
        //         tempPages[count] = res.data
        //         this._editPages(tempPages,spines,pageDirs,page_ids,page_direct)
        //         this.setState({
        //             open:false
        //         })
        //         console.log(tempPages)
        //     }else{
        //         tempPages[count] = res.data

        //         count++;
        //         this._loadPage(count,tempPages,spines,pageDirs,page_ids,page_direct);
        //     }
        // })
    }
    _loadPage(pageUrls, count, pagesHtml, bookId, indx, modeType) {
        pageUrls[count] = pageUrls[count].replace("OEBPSText", "OEBPS/Text")
        axios.get(pageUrls[count] + "?id=" + Math.floor(Math.random() * 9000)).then(res => {
            if (count === pageUrls.length - 1) {
                pagesHtml.push(res.data)
                //  console.log(tempPages)
                // console.log(pagesHtml)
                this._editPages(pagesHtml, bookId, pageUrls, indx, modeType);
            } else {
                pagesHtml.push(res.data)

                count++;
                this._loadPage(pageUrls, count, pagesHtml, bookId, indx, modeType);
                // console.log(tempPages)
            }
        })
    }
    _editPages(pagesHtml, bookId, pageUrls, indx, modeType) {
        let pagesAfterEdit = [];
        pagesHtml.forEach((page, pageIndex) => {
            if (pageIndex === 0) {
                let parser = new DOMParser;
                let thisPage = parser.parseFromString(page, "text/html");
                let pagesWrapper = thisPage.createElement("div");
                pagesWrapper.setAttribute('id', 'pages-wrapper');
                if (thisPage.getElementById('page-audios') !== null) {
                    let thisPageAudios = thisPage.getElementById('page-audios');
                    thisPageAudios.setAttribute('id', 'page-audios-1');
                }
                let firstPageWrapper = thisPage.createElement("div");
                firstPageWrapper.setAttribute('id', 'page-wrapper-1');
                // if(portraitPage === 0){
                firstPageWrapper.classList.add('page-custom-wrapper', 'portrait-page')
                // }
                let bodyContent = thisPage.body.innerHTML;
                firstPageWrapper.innerHTML = bodyContent;
                pagesWrapper.innerHTML = firstPageWrapper.outerHTML;
                thisPage.body.innerHTML = pagesWrapper.outerHTML;
                if (thisPage.getElementById('script-codes') != undefined) {
                    thisPage.getElementById('script-codes').outerHTML = '';
                }
                if (thisPage.getElementById("script-codes") === null) {
                    let scriptdiv = thisPage.createElement("div");
                    scriptdiv.id = "script-codes";
                    let script = thisPage.createElement("script");
                    script.src = '../custom_frontend.js';
                    // script.src = 'http://3.120.84.239/js/custom_frontend.js';

                    scriptdiv.appendChild(script);
                    thisPage.querySelector("body").appendChild(scriptdiv);
                }
                if (thisPage.querySelector('head script[src="../Misc/jquery.ka.browserinfo.min.js"]') != undefined) {
                    thisPage.querySelector('head script[src="../Misc/jquery.ka.browserinfo.min.js"]').outerHTML = ''
                }
                if (thisPage.querySelector('head script[src="../Misc/jquery.ka.browserinfo.css.js"]') != undefined) {
                    thisPage.querySelector('head script[src="../Misc/jquery.ka.browserinfo.css.js"]').outerHTML = ''
                }
                thisPage.body.style.opacity = 0;
                thisPage.body.style.transition = "ease-in-out .15s .25s opacity";
                pagesAfterEdit.push('<?xml version="1.0" encoding="utf-8"?><!DOCTYPE html>' + thisPage.documentElement.outerHTML);

            } else if (pageIndex === pagesHtml.length - 1) {
                if (pageIndex % 2 === 0) {
                    let parser = new DOMParser;
                    let thisPage = parser.parseFromString(page, "text/html");
                    if (pageIndex % 2 == 0) {
                        var spreadPage = parser.parseFromString(pagesHtml[pageIndex - 1], "text/html");
                    } else {
                        var spreadPage = parser.parseFromString(pagesHtml[pageIndex + 1], "text/html");
                    }

                    var firstPageScripts = thisPage.scripts;
                    var secondPageScripts = spreadPage.scripts
                    let allScripts = [];
                    let checkExist = false;
                    for (var i = 0; i < firstPageScripts.length; i++) {
                        allScripts.push(firstPageScripts[i]);
                    }
                    for (var i = 0; i < secondPageScripts.length; i++) {
                        checkExist = false;
                        for (var c = 0; c < allScripts.length && checkExist === false; c++) {
                            if (secondPageScripts[i].src === allScripts[c].src) {
                                //allScripts.push(firstPageScripts[i]);
                                checkExist = true;
                            }
                        }
                        if (checkExist === false) {
                            allScripts.push(secondPageScripts[i]);
                        }
                    }
                    thisPage.querySelectorAll("script").forEach(function (element) {
                        element.remove();
                    });


                    allScripts.forEach(script => {
                        thisPage.querySelector('head').appendChild(script)
                    })

                    var customJs = thisPage.querySelector('head script[src*="../custom_frontend.js"]')
                    if (customJs != null) {
                        customJs.parentNode.removeChild(customJs);
                    }

                    var firstPageStyles = thisPage.querySelectorAll('link[type="text/css"]');
                    var secondPageStyles = spreadPage.getElementsByTagName('link');
                    let allStyles = [];
                    let checkStyleExist = false;
                    for (var i = 0; i < firstPageStyles.length; i++) {
                        allStyles.push(firstPageStyles[i]);
                    }
                    for (var i = 0; i < secondPageStyles.length; i++) {
                        checkStyleExist = false;
                        for (var c = 0; c < allStyles.length && checkStyleExist === false; c++) {
                            if (secondPageStyles[i].href === allStyles[c].href) {
                                //allScripts.push(firstPageScripts[i]);
                                checkStyleExist = true;
                            }
                        }
                        if (checkStyleExist === false) {
                            allStyles.push(secondPageStyles[i]);
                        }
                    }
                    thisPage.querySelectorAll("link").forEach(function (element) {
                        element.remove();
                    });
                    // console.log(allScripts,thisPage);
                    allStyles.forEach(style => {
                        thisPage.querySelector('head').appendChild(style)
                    })



                    let pagesWrapper = thisPage.createElement("div");
                    pagesWrapper.setAttribute('id', 'pages-wrapper');

                    if (spreadPage.getElementById('page-audios') != undefined) {
                        let spreadPageAudios = spreadPage.getElementById('page-audios');
                        spreadPageAudios.setAttribute('id', 'page-audios-1');
                    }
                    if (thisPage.getElementById('page-audios') != undefined) {
                        let thisPageAudios = thisPage.getElementById('page-audios');
                        thisPageAudios.setAttribute('id', 'page-audios-2');
                    }

                    if (thisPage.getElementById('script-codes') != undefined) {
                        thisPage.getElementById('script-codes').outerHTML = '';
                    }
                    if (spreadPage.getElementById('script-codes') != undefined) {
                        spreadPage.getElementById('script-codes').outerHTML = '';
                    }

                    let firstPageWrapper = thisPage.createElement("div");
                    firstPageWrapper.setAttribute('id', 'page-wrapper-1');

                    let secondPageWrapper = spreadPage.createElement("div");
                    secondPageWrapper.setAttribute('id', 'page-wrapper-2');
                    if (pageIndex % 2 == 0) {
                        firstPageWrapper.classList.add('page-custom-wrapper');
                        secondPageWrapper.classList.add('page-custom-wrapper', 'portrait-page');
                    } else {
                        firstPageWrapper.classList.add('page-custom-wrapper', 'portrait-page');
                        secondPageWrapper.classList.add('page-custom-wrapper');
                    }
                    let firstBodyContent = thisPage.body.innerHTML;
                    let spreadBodyContent = spreadPage.body.innerHTML;
                    firstPageWrapper.innerHTML = spreadBodyContent;
                    secondPageWrapper.innerHTML = firstBodyContent;
                    pagesWrapper.innerHTML = firstPageWrapper.outerHTML + secondPageWrapper.outerHTML;
                    thisPage.body.innerHTML = pagesWrapper.outerHTML;
                    if (thisPage.getElementById("script-codes") === null) {
                        let scriptdiv = thisPage.createElement("div");
                        scriptdiv.id = "script-codes";
                        let script = thisPage.createElement("script");
                        script.src = '../custom_frontend.js';

                        scriptdiv.appendChild(script);
                        thisPage.querySelector("body").appendChild(scriptdiv);
                    }
                    if (thisPage.querySelector('head script[src="../Misc/jquery.ka.browserinfo.min.js"]') != undefined) {
                        thisPage.querySelector('head script[src="../Misc/jquery.ka.browserinfo.min.js"]').outerHTML = ''
                    }
                    if (thisPage.querySelector('head script[src="../Misc/jquery.ka.browserinfo.css.js"]') != undefined) {
                        thisPage.querySelector('head script[src="../Misc/jquery.ka.browserinfo.css.js"]').outerHTML = ''
                    }
                    thisPage.body.style.opacity = 0;
                    thisPage.body.style.transition = "ease-in-out .15s .25s opacity";
                    pagesAfterEdit.push('<?xml version="1.0" encoding="utf-8"?><!DOCTYPE html>' + thisPage.documentElement.outerHTML);
                } else {
                    let parser = new DOMParser;
                    let thisPage = parser.parseFromString(page, "text/html");

                    let pagesWrapper = thisPage.createElement("div");
                    pagesWrapper.setAttribute('id', 'pages-wrapper');
                    if (thisPage.getElementById('page-audios') != undefined) {
                        let thisPageAudios = thisPage.getElementById('page-audios');
                        thisPageAudios.setAttribute('id', 'page-audios-1');
                    }
                    let firstPageWrapper = thisPage.createElement("div");
                    firstPageWrapper.setAttribute('id', 'page-wrapper-1');
                    // if(portraitPage === 0){
                    firstPageWrapper.classList.add('page-custom-wrapper', 'portrait-page')
                    // }
                    let bodyContent = thisPage.body.innerHTML;
                    firstPageWrapper.innerHTML = bodyContent;
                    pagesWrapper.innerHTML = firstPageWrapper.outerHTML;
                    thisPage.body.innerHTML = pagesWrapper.outerHTML;
                    if (thisPage.getElementById('script-codes') != undefined) {
                        thisPage.getElementById('script-codes').outerHTML = '';
                    }
                    if (thisPage.getElementById("script-codes") === null) {
                        let scriptdiv = thisPage.createElement("div");
                        scriptdiv.id = "script-codes";
                        let script = thisPage.createElement("script");
                        script.src = '../custom_frontend.js';

                        scriptdiv.appendChild(script);
                        thisPage.querySelector("body").appendChild(scriptdiv);
                    }
                    if (thisPage.querySelector('head script[src="../Misc/jquery.ka.browserinfo.min.js"]') != undefined) {
                        thisPage.querySelector('head script[src="../Misc/jquery.ka.browserinfo.min.js"]').outerHTML = ''
                    }
                    if (thisPage.querySelector('head script[src="../Misc/jquery.ka.browserinfo.css.js"]') != undefined) {
                        thisPage.querySelector('head script[src="../Misc/jquery.ka.browserinfo.css.js"]').outerHTML = ''
                    }
                    thisPage.body.style.opacity = 0;
                    thisPage.body.style.transition = "ease-in-out .15s .25s opacity";
                    pagesAfterEdit.push('<?xml version="1.0" encoding="utf-8"?><!DOCTYPE html>' + thisPage.documentElement.outerHTML);
                }
            } else {
                let parser = new DOMParser;
                let thisPage = parser.parseFromString(page, "text/html");

                if (pageIndex % 2 == 0) {
                    var spreadPage = parser.parseFromString(pagesHtml[pageIndex - 1], "text/html");
                } else {
                    var spreadPage = parser.parseFromString(pagesHtml[pageIndex + 1], "text/html");
                }

                var firstPageScripts = thisPage.scripts;
                var secondPageScripts = spreadPage.scripts
                let allScripts = [];
                let checkExist = false;
                for (var i = 0; i < firstPageScripts.length; i++) {
                    allScripts.push(firstPageScripts[i]);
                }
                for (var i = 0; i < secondPageScripts.length; i++) {
                    checkExist = false;
                    for (var c = 0; c < allScripts.length && checkExist === false; c++) {
                        if (secondPageScripts[i].src === allScripts[c].src) {
                            //allScripts.push(firstPageScripts[i]);
                            checkExist = true;
                        }
                    }
                    if (checkExist === false) {
                        allScripts.push(secondPageScripts[i]);
                    }
                }
                let findFrontJs = false;

                thisPage.querySelectorAll("script").forEach(function (element) {
                    element.remove();
                });

                // console.log(allScripts,thisPage);
                allScripts.forEach(script => {
                    thisPage.querySelector('head').appendChild(script)
                })

                var customJs = thisPage.querySelector('head script[src*="../custom_frontend.js"]')
                if (customJs != null) {
                    customJs.parentNode.removeChild(customJs);
                }

                var firstPageStyles = thisPage.querySelectorAll('link[type="text/css"]');
                var secondPageStyles = spreadPage.getElementsByTagName('link');
                let allStyles = [];
                let checkStyleExist = false;
                for (var i = 0; i < firstPageStyles.length; i++) {
                    allStyles.push(firstPageStyles[i]);
                }
                for (var i = 0; i < secondPageStyles.length; i++) {
                    checkStyleExist = false;
                    for (var c = 0; c < allStyles.length && checkStyleExist === false; c++) {
                        if (secondPageStyles[i].href === allStyles[c].href) {
                            //allScripts.push(firstPageScripts[i]);
                            checkStyleExist = true;
                        }
                    }
                    if (checkStyleExist === false) {
                        allStyles.push(secondPageStyles[i]);
                    }
                }
                thisPage.querySelectorAll("link").forEach(function (element) {
                    element.remove();
                });
                // console.log(allScripts,thisPage);
                allStyles.forEach(style => {
                    thisPage.querySelector('head').appendChild(style)
                })


                let pagesWrapper = thisPage.createElement("div");
                pagesWrapper.setAttribute('id', 'pages-wrapper');
                if (pageIndex % 2 == 0) {
                    if (spreadPage.getElementById('page-audios') != undefined) {
                        let spreadPageAudios = spreadPage.getElementById('page-audios');
                        spreadPageAudios.setAttribute('id', 'page-audios-1');
                    }
                    if (thisPage.getElementById('page-audios') != undefined) {
                        let thisPageAudios = thisPage.getElementById('page-audios');
                        thisPageAudios.setAttribute('id', 'page-audios-2');
                    }
                } else {
                    if (thisPage.getElementById('page-audios') != undefined) {
                        let thisPageAudios = thisPage.getElementById('page-audios');
                        thisPageAudios.setAttribute('id', 'page-audios-1');
                    }
                    if (spreadPage.getElementById('page-audios') != undefined) {
                        let spreadPageAudios = spreadPage.getElementById('page-audios');
                        spreadPageAudios.setAttribute('id', 'page-audios-2');
                    }
                }


                if (thisPage.getElementById('script-codes') != undefined) {
                    thisPage.getElementById('script-codes').outerHTML = '';
                }
                if (spreadPage.getElementById('script-codes') != undefined) {
                    spreadPage.getElementById('script-codes').outerHTML = '';
                }

                let firstPageWrapper = thisPage.createElement("div");
                firstPageWrapper.setAttribute('id', 'page-wrapper-1');

                let secondPageWrapper = spreadPage.createElement("div");
                secondPageWrapper.setAttribute('id', 'page-wrapper-2');
                if (pageIndex % 2 == 0) {
                    firstPageWrapper.classList.add('page-custom-wrapper');
                    secondPageWrapper.classList.add('page-custom-wrapper', 'portrait-page');
                } else {
                    firstPageWrapper.classList.add('page-custom-wrapper', 'portrait-page');
                    secondPageWrapper.classList.add('page-custom-wrapper');
                }
                if (pageIndex % 2 == 0) {
                    var firstBodyContent = spreadPage.body.innerHTML;
                    var spreadBodyContent = thisPage.body.innerHTML;
                } else {
                    var firstBodyContent = thisPage.body.innerHTML;
                    var spreadBodyContent = spreadPage.body.innerHTML;
                }

                firstPageWrapper.innerHTML = firstBodyContent;
                secondPageWrapper.innerHTML = spreadBodyContent;
                pagesWrapper.innerHTML = firstPageWrapper.outerHTML + secondPageWrapper.outerHTML;
                thisPage.body.innerHTML = pagesWrapper.outerHTML;
                if (thisPage.getElementById("script-codes") === null) {
                    let scriptdiv = thisPage.createElement("div");
                    scriptdiv.id = "script-codes";
                    let script = thisPage.createElement("script");
                    script.src = '../custom_frontend.js';

                    scriptdiv.appendChild(script);
                    thisPage.querySelector("body").appendChild(scriptdiv);
                }
                if (thisPage.querySelector('head script[src="../Misc/jquery.ka.browserinfo.min.js"]') != undefined) {
                    thisPage.querySelector('head script[src="../Misc/jquery.ka.browserinfo.min.js"]').outerHTML = ''
                }
                if (thisPage.querySelector('head script[src="../Misc/jquery.ka.browserinfo.css.js"]') != undefined) {
                    thisPage.querySelector('head script[src="../Misc/jquery.ka.browserinfo.css.js"]').outerHTML = ''
                }
                thisPage.body.style.opacity = 0;
                thisPage.body.style.transition = "ease-in-out .15s .25s opacity";
                pagesAfterEdit.push('<?xml version="1.0" encoding="utf-8"?><!DOCTYPE html>' + thisPage.documentElement.outerHTML);
            }
        });
        this._finishPagesEdit(pagesAfterEdit, bookId, pageUrls, indx, modeType);
    }
    _finishPagesEditBig(pages, bookId, pageUrls, indx, modeType, selected) {
        if (selected < pages.length) {
            /* var bodyFormData = new FormData();
             bodyFormData.set("pages",JSON.stringify(pages));
             bodyFormData.set("bookId",bookId);
             bodyFormData.set("pageUrls",pageUrls);*/
            let selected_pages = [];
            let target = selected_pages + 15;
            let selected_page = [];
            while (selected_pages < target) {
                // selected_page.push(pages[])
            }
            pages.forEach(function (pageItem) {
                // pages[selected];
            });
        }
        else {

        }



    }
    _finishPagesEdit(pages, bookId, pageUrls, indx, modeType) {
        let th = this;

        // console.log(pages);
        // console.log(pageUrls);
        //console.log(JSON.stringify(pages));


        var bodyFormData = new FormData();


        pages.forEach((page, indx1) => {
            bodyFormData.set(`pages_${indx1}`, page);
        });
        bodyFormData.set("limit", pages.length);

        bodyFormData.set("pages", JSON.stringify(pages));
        bodyFormData.set("bookId", bookId);
        bodyFormData.set("pageUrls", pageUrls);
        axios.post(baseLink + '/spread/edit', bodyFormData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                if (res.data.message === "success") {
                    //  console.log(modeType);

                    if (modeType === "multiple") {
                        //  console.log("bookList3",th.bookCount,th.exportBookList.length - 1,bookId);
                        th.bookCount--;
                        //if(th.bookCount < th.exportBookList.length) {
                        if (th.bookCount !== -1) {
                            th._spreadView(th.exportBookList[th.bookCount]._id, th.exportBookList[th.bookCount].opf_file, 0, th.bookCount, "multiple");
                        }
                        else {
                            th.setState({
                                openPopup: false
                            });
                        }
                    } else {
                        let filterData2 = this.state.filterData;
                        let datanew = this.state.datanew;
                        let mainIndx = datanew.findIndex((item) => {
                            return item._id === bookId;
                        });

                        datanew[mainIndx].publish_status = 1;
                        filterData2[indx].publish_status = 1;

                        th.setState({
                            openPopup: false,
                            filterData: filterData2,
                            datanew: datanew
                        });
                    }

                }

            }).catch(err => {
                if (modeType === "multiple") {

                    console.log("bookList3", th.bookCount, th.exportBookList.length - 1, bookId);
                    th.bookCount--;
                    //if(th.bookCount < th.exportBookList.length) {
                    if (th.bookCount !== -1) {

                        th._spreadView(this.exportBookList[th.bookCount]._id, this.exportBookList[th.bookCount].opf_file, 0, th.bookCount, "multiple");
                    }
                    else {
                        th.setState({
                            openPopup: false
                        });
                    }

                }
            });

    }
    _popupOpen(answer, id) {
        this.setState({
            open: answer,
            selectedId: id
        });
    }
    _deleteAction(answer) {
        let selectedId = this.state.selectedId;
        if (answer === "yes") {
            var bodyFormData = new FormData();

            bodyFormData.set("id", selectedId);


            axios({
                method: 'post',
                url: baseLink + '/book/delete',
                data: bodyFormData,
                config: { headers: { 'Content-Type': 'multipart/form-data' } }
            }).then(function (res) {
                if (res.data.message === "done") {
                    document.getElementById(selectedId).outerHTML = "";
                }
            });


        } else if (answer === "no") {

        }
        this.setState({
            open: false
        });
    }

    ageCatHandle = (selectedObj) => {
        let selected = [];
        selectedObj.forEach(function (selectItem) {
            selected.push(selectItem.value);
        });
        this.setState({
            ageCatList: selected
        });

        let publiserSelect = this.state.publisherSelect;
        let pypList = this.state.pypList;
        let level21List = this.state.level21List;
        let ageCatList = selected;
        let bookSearchText = this.state.bookSearchText;
        let notCat = this.state.notCat;

        this.bookSearch(bookSearchText, pypList, level21List, ageCatList, publiserSelect, notCat);
    }
    level21Handle = (selectedObj) => {
        let selected = [];
        selectedObj.forEach(function (selectItem) {
            selected.push(selectItem.value);
        });
        this.setState({
            level21List: selected
        });

        let publiserSelect = this.state.publisherSelect;
        let pypList = this.state.pypList;
        let level21List = selected;
        let ageCatList = this.state.ageCatList;
        let bookSearchText = this.state.bookSearchText;
        let notCat = this.state.notCat;


        this.bookSearch(bookSearchText, pypList, level21List, ageCatList, publiserSelect, notCat);
    }
    pypHandle = (selectedObj) => {
        let selected = [];
        selectedObj.forEach(function (selectItem) {
            selected.push(selectItem.value);
        });
        this.setState({
            pypList: selected
        });

        let publiserSelect = this.state.publisherSelect;
        let pypList = selected;
        let level21List = this.state.level21List;
        let ageCatList = this.state.ageCatList;
        let bookSearchText = this.state.bookSearchText;
        let notCat = this.state.notCat;


        this.bookSearch(bookSearchText, pypList, level21List, ageCatList, publiserSelect, notCat);
    }

    publisherHandle = (selectedObj) => {

        let selected = [];
        selectedObj.forEach(function (selectItem) {
            selected.push(selectItem.value);
        });
        this.setState({
            publisherSelect: selected
        });

        let publiserSelect = selected;

        let pypList = this.state.pypList;
        let level21List = this.state.level21List;
        let ageCatList = this.state.ageCatList;
        let bookSearchText = this.state.bookSearchText;
        let notCat = this.state.notCat;

        this.bookSearch(bookSearchText, pypList, level21List, ageCatList, publiserSelect, notCat);


    }
    bookSearch = (searchTxt, pypList, level21List, ageCatList, publiserSelect, notCat) => {
        searchTxt = searchTxt.toLowerCase();
        searchTxt = searchTxt.replace(/(ة)/g, 'ه');
        searchTxt = searchTxt.replace(/(ى)/g, 'ي');
        searchTxt = searchTxt.replace(/(آ|إ|أ)/g, 'ا');

        let filterData = this.state.datanew;

        if (publiserSelect.length !== 0) {
            filterData = filterData.filter((item) => {
                if (item.publisher !== undefined) {
                    let find = publiserSelect.indexOf(item.publisher);
                    return find !== -1;
                }
            });
        }

        if (level21List.length !== 0) {
            filterData = filterData.filter((item) => {
                let check = 0;
                item.category.forEach(function (subItem) {
                    if (level21List.indexOf(subItem.id) !== -1) {
                        check = 1;
                    }
                });
                return check === 1;
            });
        }
        if (ageCatList.length !== 0) {
            filterData = filterData.filter((item) => {
                let check = 0;
                item.category.forEach(function (subItem) {
                    if (ageCatList.indexOf(subItem.id) !== -1) {
                        check = 1;
                    }
                });
                return check === 1;
            });
        }

        if (pypList.length !== 0) {
            filterData = filterData.filter((item) => {
                let check = 0;
                item.category.forEach(function (subItem) {
                    if (pypList.indexOf(subItem.id) !== -1) {
                        check = 1;
                    }
                });
                return check === 1;
            });
        }

        if (searchTxt.trim() !== "") {
            filterData = filterData.filter((item) => {
                let bookName = item.nameEn;
                bookName = bookName.toLowerCase();
                bookName = bookName.replace(/(ة)/g, 'ه');
                bookName = bookName.replace(/(ى)/g, 'ي');
                bookName = bookName.replace(/(آ|إ|أ)/g, 'ا');

                //  console.log(searchTxt," - ",bookName," - ",bookName.search(searchTxt));

                return bookName.search(searchTxt) !== -1
            });
        }
        if (notCat === true) {
            filterData = this.state.datanew;
            filterData = filterData.filter((item) => {
                let check = 0;

                if (item.category.length === 0) {
                    check = 1;
                }
                return check === 1;
            });
        }

        this.setState({
            filterData: filterData
        });
    }
    filterStatus = (e) => {
        let showFilter = this.state.showFilter;
        if (showFilter === true) {
            showFilter = false;
        } else {
            showFilter = true;
        }
        this.setState({
            showFilter: showFilter
        });
        e.preventDefault();
    }
    searchText = (value) => {
        this.setState({
            bookSearchText: value
        });

        let searchText = value;

        let pypList = this.state.pypList;
        let level21List = this.state.level21List;
        let ageCatList = this.state.ageCatList;
        let publiserSelect = this.state.publisherSelect;
        let notCat = this.state.notCat;

        this.bookSearch(searchText, pypList, level21List, ageCatList, publiserSelect, notCat);
    }
    uncat = (e) => {
        this.setState({
            notCat: e.target.checked
        })

        let searchText = this.state.bookSearchText;
        let pypList = this.state.pypList;
        let level21List = this.state.level21List;
        let ageCatList = this.state.ageCatList;
        let publiserSelect = this.state.publisherSelect;
        let notCat = e.target.checked;

        this.bookSearch(searchText, pypList, level21List, ageCatList, publiserSelect, notCat);
    }
    hideBook = (id, status, indx) => {
        this.setState({
            openPopup: true
        });
        let filterData = this.state.filterData;

        if (status === undefined) {
            status = true;
        } else if (status === false) {
            status = true;
        }
        else if (status === true) {
            status = false;
        }
        filterData[indx].hideBook = status;
        let bodyFormData = new FormData();
        bodyFormData.set("book_id", id);
        bodyFormData.set("status", status);

        axios({
            method: 'post',
            url: baseLink + '/book/hide',
            data: bodyFormData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        }).then((res) => {
            this.setState({
                filterData: filterData,
                openPopup: false
            });
        });
    }
    exportBooks = (e) => {
        let bookExportList = this.state.bookExportList;
        var csvContent = "data:text/csv;charset=utf-8";

        var BOM = "\uFEFF";
        // csvContent = BOM + csvContent;

        let dataString = "";
        bookExportList.forEach(function (dataMember, index) {
            dataString = dataMember.join(",");
            csvContent += index < bookExportList.length ? dataString + "\n" : dataString;
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "upload_data" + (new Date()).getTime() + ".csv");
        link.click();
    }
    render() {
        let th = this;
        let age_categories = ["cat_19", "cat_28", "cat_18", "cat_29"];
        let age_categories_val = ["3-5", "6-8", "9-11", "12-14"];

        let level21 = ["cat_51", "cat_52", "cat_53", "cat_58", "cat_54", "cat_57", "cat_65", "cat_68", "cat_59", "cat_66", "cat_67", "cat_61", "cat_62", "cat_63", "cat_64", "cat_60", "cat_56", "cat_55"];
        let level21_val = ["أ", "ب", "ج", "ح", "د", "ز", "س", "ص", "ط", "ع", "ف", "ك", "ل", "م", "ن", "ي", "و", "ه"];


        var url_string = window.location.href;
        var url = new URL(url_string);
        let utype = url.searchParams.get("type");



        return (
            <FusePageCarded

                contentToolbar={
                    <div className="flex justify-between w-full p-12">
                        <h2>Books</h2>
                        <div>

                            {/* <a style={{background:"#51C0E5",color:"#fff",padding:"10px 20px",borderRadius:5,marginRight:15}} href={baseLink + "/book/export"} className="yellow-bt">Export</a> */}
                            {/* <a style={{background:"#51C0E5",color:"#fff",padding:"10px 20px",borderRadius:5,marginRight:15}} href={baseLink2 + "/book/update/import"} className="yellow-bt">Import update</a> */}

                            {/* <a style={{background:"#51C0E5",color:"#fff",padding:"10px 20px",borderRadius:5,marginRight:15}} href={baseLink2 + "/book/import"} className="yellow-bt">Import</a> */}

                            {/* <a style={{background:"#51C0E5",color:"#fff",padding:"10px 20px",borderRadius:5,marginRight:15}} href="#" onClick={this.filterStatus} class="filter-cio yellow-bt">Filter</a> */}
                            <Link to="/frontend/book/add"><a style={{ background: "#51C0E5", color: "#fff", padding: "10px 20px", borderRadius: 5, marginRight: 15 }} href="#" class="filter-cio yellow-bt">Add</a></Link>

                        </div>
                        {/* <Button href="/book/add" class="yellow-bt green-button">Add</Button>
                        <Button class="yellow-bt" href="/book/import">Import</Button>
                        <Button class="yellow-bt" href="/book/update/import">Import update</Button> */}
                        {utype === "export" ? <Button class="yellow-bt" href="#" onClick={this.exportAllBooks}>نشر الكتب</Button> : ""}
                        {/* <DownloadData data={this.state.bookExportList} /> */}
                    </div>
                }
                content={
                    <div className="table-style">
                        <Collapse className="colle2" in={this.state.showFilter}>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                // margin: "25px 15px 5px",
                                justifyContent: "space-between",
                                minHeight: 80,
                            }}>

                                <div className="filter-book" style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "25px 15px 5px"
                                }}>
                                    <ReactMultiSelectCheckboxes onChange={this.publisherHandle} style={{ marginRight: 10 }} options={this.state.publisherList} placeholderButtonLabel="الناشر" />
                                    <ReactMultiSelectCheckboxes onChange={this.level21Handle} style={{ marginRight: 10 }} options={this.state.ar12Cateogry} placeholderButtonLabel="المستوى" />
                                    <ReactMultiSelectCheckboxes onChange={this.ageCatHandle} style={{ marginRight: 10 }} options={this.state.ageCategory} placeholderButtonLabel="الفئة العمرية" />
                                    <ReactMultiSelectCheckboxes onChange={this.pypHandle} style={{ marginRight: 10 }} options={this.state.pypCategory} placeholderButtonLabel="PYP - المحاور المتجاوزة" />
                                    <div style={{ marginRight: 10 }} ><input type="checkbox" onChange={this.uncat} /> none selected category</div>
                                </div>
                                <div><TextField placeholder="Search..." onChange={(e) => { this.searchText(e.target.value) }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}

                                /></div>
                            </div>
                        </Collapse>



                        <div className="item-list-cointer">

                            {this.state.filterData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((item, index) => {

                                let link = "";
                                if (item.fid !== undefined) {
                                    link = <div><a href={baseLink + item.fid}>Download</a></div>;
                                }
                                if (item.bookSource === "Build in audio") {
                                    item.bookSource = "build_in_audio";
                                }
                                /* let publish_status = 0;
                                 if(item.exportedDate !== undefined && item.updatedDate !== undefined) {
                                     if(item.updatedDate > item.exportedDate) {
                                         publish_status = 0;
                                     }
                                     else if(item.updatedDate < item.exportedDate) {
                                         publish_status = 1;
                                     }
                                 }else {
             
                                     if(item.exportedDate !== undefined) {
                                         publish_status = 1;
                                     }
                                     else {
                                         publish_status = -1;
                                     }
                                     
                                 }*/




                                return (<div id={item._id} className={"item-list book-item-list"}>
                                    <div className={"book-item-inner-list"}>
                                        <div className="book-details-list">
                                            <div className="book-info">

                                                <div className="book-actions">
                                                    <a href={`/frontend/book/${item._id}/edit`} className="book-action-edit"><img src="/newimages/book/edit.png" /></a>
                                                    <a href='#' className="book-action-delete" onClick={(e) => th.deleteBook(e, item._id)}><img src="/newimages/book/close.png" /></a>
                                                </div>
                                                <div className="bo-list"><div>{item.nameEn} - {item.nameAr}</div>
                                                    {/* <div>{item.publisherName}</div>
                                                    <div>{item.level21}</div>
                                                    <div>{item.age_tag}</div> */}
                                                </div>

                                                <div id="book-pages"><div>{item.minTimeSpent} </div><div><img src="/newimages/book/time.png" /></div> </div>
                                                <div id="book-time"><div>{item.pagesNo != undefined && item.pagesNo !== "" ? item.pagesNo : "0"} Page</div><div><img src="/newimages/book/pages.png" /></div></div>



                                            </div>


                                        </div>
                                        <div className="book-img-list">
                                            <div className={"book-hide"}>
                                                {/* <a href='#' onClick={() => {this.hideBook(item._id,item.hideBook,index)}}>Hide Book</a> */}

                                                {(item.hideBook === true) ? <VisibilityOffIcon onClick={() => { this.hideBook(item._id, item.hideBook, index) }} style={{ color: "#008000" }} /> : <VisibilityOffIcon VisibilityOffIcon style={{ color: "#51C0E5" }} onClick={() => { this.hideBook(item._id, item.hideBook, index) }} />}

                                            </div>

                                            {item.coverPhoto !== "" && item.coverPhoto !== undefined ? <img className="cover-list" src={baseLink + "/public/" + item.coverPhoto[0]} />
                                                :
                                                <img className="cover-list" src={'/newimages/nc-md.gif'} />
                                            }

                                        </div>

                                    </div>
                                    <div className="book-img-list2">
                                        <div className="book-img-list2-inner">
                                        </div>
                                    </div>
                                    <div className="book-img-list3" style={{ paddingTop: 10 }}>



                                        {/* {true ? <div><Button onClick={(e) => {
                                            this._spreadView(item._id, item.opf_file, e, index)

                                        }} href={"#"} style={{ background: "green" }} className='blue-bt edit-audios published'>نشر</Button></div> : ""} */}


                                        {item.type !== "audio" && item.type !== "video" && <>
                                            <div><Button style={{ background: "#51C0E5", color: '#fff' }} href={item.bookSource !== "build_in_audio" ? "/frontend/book/" + item._id + "/edit_audio?id=" + item._id + "&opf_file=" + item.opf_file + "&lang=" + item.language + "&type=" + item.bookSource + "&auto=1" : "/book/" + item._id + "/edit_audio?id=" + item._id + "&opf_file=" + item.opf_file + "&lang=" + item.language + "&type=" + item.bookSource} className='blue-bt edit-audios'>Audio editing</Button></div>






                                        </>}


                                    </div>

                                </div>);

                            })}
                            <TablePagination style={{ width: "100%" }} page={this.state.page} count={this.state.filterData.length}
                                rowsPerPageOptions={[12, 20, 50, 100]}
                                rowsPerPage={this.state.rowsPerPage}
                                onChangePage={(e, page) => {
                                    console.log(page)
                                    this.setState({
                                        page: page
                                    })
                                    //setPage(page)
                                }}
                                onChangeRowsPerPage={(e) => {
                                    // setRowsPerPage(parseInt(e.target.value));
                                    // setPage(0);
                                    this.setState({
                                        page: 0,
                                        rowsPerPage: parseInt(e.target.value)
                                    })
                                }}
                            />
                        </div>
                        <Dialog
                            open={this.state.open}>
                            <DialogContent>
                                <div>Are you sure that want to delete book</div>
                                <div className="actions-bt" style={{ display: "flex", margin: 20, justifyContent: "space-evenly" }}>
                                    <Button variant="contained" label="Yes" onClick={() => this._deleteAction("yes")}>Yes</Button>
                                    <Button variant="contained" label="No" onClick={() => this._deleteAction("no")} >No</Button>
                                </div>
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            open={this.state.openPopup}>
                            <DialogContent><CircularProgress /></DialogContent>
                        </Dialog>

                    </div>
                }
            />
        );

    }
}
export default bookList;