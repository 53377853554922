import FusePageCarded from '@fuse/core/FusePageCarded';
import withReducer from 'app/store/withReducer';
import FuseAnimate from '@fuse/core/FuseAnimate';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import TableHead from '@material-ui/core/TableHead';

import EditIcon from "@material-ui/icons/Edit";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Autocomplete from '@material-ui/lab/Autocomplete';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import axios from "axios";

// import TableHead from './TableHead';
import { useParams } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableContainer from '@material-ui/core/TableContainer';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    container: {
        maxHeight: 550,
    },
}));

function RowList() {
    let history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { id } = useParams();

    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [reaload, setReaload] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [type, setType] = React.useState('');
    const [randomValue, setRandomValue] = React.useState(null);

    const [programList, setProgramList] = useState([]);
    const [programListf, setProgramListf] = useState([]);
    const [storesData, setStoresData] = useState([]);

    const [order, setOrder] = useState({
        direction: 'asc',
        id: null
    });



    useEffect(() => {
        function getFilteredArray() {
            if (true) {
                return programList;
            }
        }

        if (programList) {
            setProgramListf(getFilteredArray());
        }
    }, [programList, reaload]);

    useEffect(() => {
        loaddata();
    }, []);



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClose2 = () => {
        setOpen2(false);
    };
    function loaddata() {
        let backend_url = process.env.REACT_APP_BACKEND_URL;

        let formData = new FormData();

        formData.append("keyword", '');
        formData.append("indexing", 999999);


        let data = {
            id: id,
        };
        axios({
            url: `${backend_url}/mainScreen/get`,
            data: data,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            let isTrueSet = (res.data.result.random == 'true');
            setProgramList(res.data.result.contentRow);
            setRandomValue(isTrueSet);
            setType(res.data.result.typeContent)
            axios({
                url: `${backend_url}/${res.data.result.typeContent}/list`,
                data: formData,
                method: "post",
                headers: { "Content-Type": "application/json" },
            }).then((res) => {
                setStoresData(res.data.result);
            });
        });

    }

    function handleRequestSort(event, property) {
        const id = property;
        let direction = 'desc';

        if (order.id === property && order.direction === 'desc') {
            direction = 'asc';
        }

        setOrder({
            direction,
            id
        });
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            setSelected(data.map(n => n.id));
            return;
        }
        setSelected([]);
    }

    function handleClick(item) {
        // props.history.push(`/apps/e-commerce/products/${item.id}/${item.handle}`);
    }

    function handleCheck(event, id) {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    }

    function handleChangePage(event, value) {
        setPage(value);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value);
    }

    function addtoarry(value) {
        ///delete form old
        // setStoresData({
        //     storesData: storesData.filter(item => item._id != value._id)
        // })
        let array = [];
        storesData.map((item) => {
            if (value._id != item._id) {
                array.push(item);
            }
        })
        setStoresData(array)
        ///add form old 
        setProgramList([value._id, ...programListf])

        setOpen(false);
        setOpen2(true);
        setReaload(reaload + 2)

    }

    function handleDelete(id) {
        let array = [];
        programListf.map((item) => {
            if (id != item) {
                array.push(item);
            }
        })
        setProgramListf(array)
    };

    function saveChange() {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();
        formData.append("id", id);
        formData.append("type", 'choice');
        formData.append("random", randomValue);
        formData.append("contentRow", JSON.stringify(programListf));



        axios({
            url: `${backend_url}/mainScreen/etittitle`,
            data: formData,
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            history.push('/frontend/appsettings/rowsapp');
        });

    }
    const changeRandomInput = () => {
        let isTrueSet = JSON.parse(randomValue);
        setRandomValue(!isTrueSet)
    }
    const reOrder = (id, type) => {
        Array.prototype.move = function (from, to) {
            this.splice(to, 0, this.splice(from, 1)[0]);
        };
        if (type == 0) {
            //up 
            programListf.move(id, (id - 1));
        }
        else if (type == 1) {
            //down  
            programListf.move(id, (id + 1));

        }
        setReaload(reaload + 1)
    }
    const table = () => {
        return <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                key={1}
                                align={'left'}
                            >#
                            </TableCell>

                            <TableCell
                                key={1}
                                align={'center'}
                            >
                            </TableCell>
                            <TableCell
                                key={1}
                                align={'center'}
                            >
                            </TableCell>
                            <TableCell
                                key={1}
                                align={'left'}
                            >
                                Title EN
                            </TableCell>
                            <TableCell
                                key={2}
                                align={'left'}
                            >
                                Title AR
                            </TableCell>
                            <TableCell
                                key={3}
                                align={'left'}
                            >
                                Status
                            </TableCell>
                            <TableCell
                                key={4}
                                align={'center'}
                            >
                            </TableCell>
                            <TableCell
                                key={4}
                                align={'center'}
                            >
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {programListf.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={n}>
                                    <TableCell className="">
                                        {programListf.indexOf(n) + 1}

                                    </TableCell>
                                    <TableCell className="">
                                        <ArrowUpward style={{
                                            cursor: 'pointer',
                                            display: programListf.indexOf(n) == 0 ? 'none' : 'block'
                                        }} fontSize="small"

                                            onClick={() => {
                                                let idItem = programListf.indexOf(n);
                                                reOrder(idItem, 0)
                                            }}
                                        />
                                        <ArrowDownward style={{
                                            cursor: 'pointer',
                                            display: programListf.indexOf(n) == programListf.length - 1 ? 'none' : 'block'
                                        }} fontSize="small"
                                            onClick={() => {
                                                let idItem = programListf.indexOf(n);
                                                reOrder(idItem, 1)
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell className="w-52" component="th" scope="row" padding="none">
                                        {
                                            storesData.map((item) => {
                                                if (n == item._id) {
                                                    return <img className="w-full block rounded" src={process.env.REACT_APP_BACKEND_URL + '/public/' + item.coverImg} />

                                                }
                                            })
                                        }
                                    </TableCell>

                                    <TableCell component="th" scope="row">

                                        {
                                            storesData.map((item) => {
                                                if (n == item._id) {
                                                    return item['titleEn'];
                                                }
                                            })
                                        }
                                    </TableCell>

                                    <TableCell className="truncate" component="th" scope="row">
                                        {
                                            storesData.map((item) => {
                                                if (n == item._id) {
                                                    return item['titleAr'];
                                                }
                                            })
                                        }
                                    </TableCell>

                                    <TableCell component="th" scope="row" align="left">
                                        {
                                            storesData.map((item) => {
                                                if (n == item._id) {
                                                    return item['Status'];
                                                }
                                            })
                                        }
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        <Link
                                            to={`${process.env.REACT_APP_FRONT_URL}${type}/${n}/edit`}
                                            target="_block"
                                        >
                                            <IconButton
                                                aria-label="delete"
                                                className={classes.margin}
                                            >
                                                <EditIcon fontSize="medium" />
                                            </IconButton>
                                        </Link>
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => {
                                                handleDelete(n)
                                            }}
                                        >
                                            <DeleteIcon fontSize="large" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={programListf.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    }




    const table22 = () => {
        return <Table className="min-w-xl" aria-labelledby="tableTitle">
            <TableHead
                numSelected={selected.length}
                order={order}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={programListf.length}
            />

            <TableBody>
                {_.orderBy(
                    programListf,
                    [
                        o => {
                            switch (programListf) {
                                case 'id': {
                                    return o;
                                }
                                default: {
                                    return o[programListf];
                                }
                            }
                        }
                    ],
                    [programListf]
                )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(n => {
                        const isSelected = selected.indexOf(n.id) !== -1;
                        return (
                            <TableRow
                                className="h-64 cursor-pointer"
                                hover
                                role="checkbox"
                                aria-checked={isSelected}
                                tabIndex={-1}
                                key={n.id}
                                selected={isSelected}
                                onClick={event => handleClick(n)}
                            >
                                <TableCell className="">
                                    {programListf.indexOf(n) + 1}

                                </TableCell>
                                <TableCell className="">
                                    <ArrowUpward style={{
                                        cursor: 'pointer',
                                        display: programListf.indexOf(n) == 0 ? 'none' : 'block'
                                    }} fontSize="small"

                                        onClick={() => {
                                            let idItem = programListf.indexOf(n);
                                            reOrder(idItem, 0)
                                        }}
                                    />
                                    <ArrowDownward style={{
                                        cursor: 'pointer',
                                        display: programListf.indexOf(n) == programListf.length - 1 ? 'none' : 'block'
                                    }} fontSize="small"
                                        onClick={() => {
                                            let idItem = programListf.indexOf(n);
                                            reOrder(idItem, 1)
                                        }}
                                    />
                                </TableCell>

                                <TableCell className="w-52" component="th" scope="row" padding="none">
                                    {
                                        storesData.map((item) => {
                                            if (n == item._id) {
                                                return <img className="w-full block rounded" src={process.env.REACT_APP_BACKEND_URL + '/public/' + item.coverImg} />

                                            }
                                        })
                                    }
                                </TableCell>

                                <TableCell component="th" scope="row">

                                    {
                                        storesData.map((item) => {
                                            if (n == item._id) {
                                                return item['titleEn'];
                                            }
                                        })
                                    }
                                </TableCell>

                                <TableCell className="truncate" component="th" scope="row">
                                    {
                                        storesData.map((item) => {
                                            if (n == item._id) {
                                                return item['titleAr'];
                                            }
                                        })
                                    }
                                </TableCell>

                                <TableCell component="th" scope="row" align="right">
                                    {
                                        storesData.map((item) => {
                                            if (n == item._id) {
                                                return item['Status'];
                                            }
                                        })
                                    }
                                </TableCell>

                                <TableCell component="th" scope="row" align="right">


                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                            handleDelete(n)
                                        }}
                                    >
                                        <DeleteIcon fontSize="large" />
                                    </IconButton>
                                </TableCell>


                            </TableRow>
                        );
                    })}
            </TableBody>
        </Table>
    }
    const mainTheme = useSelector(({ fuse }) => fuse.settings.mainTheme);
    return (
        <FusePageCarded
            classes={{
                content: 'flex',
                header: 'min-h-72 h-72 sm:h-136 sm:min-h-136'
            }}
            header={
                <div className="flex flex-1 w-full items-center justify-between">
                    <div className="flex items-center w-full ">
                        <FuseAnimate animation="transition.expandIn" delay={300}>
                            <Icon className="text-32">toc</Icon>
                        </FuseAnimate>
                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                            <Typography className="hidden sm:flex mx-0 sm:mx-12" variant="h6">
                                Row content
                            </Typography>
                        </FuseAnimate>
                        <ThemeProvider theme={mainTheme}>
                            <FuseAnimate animation="transition.slideDownIn" delay={300}>
                                <Paper className="flex items-center w-full max-w-256 px-8 py-4 rounded-8" elevation={1}>
                                    <FormControlLabel
                                        control={<Switch checked={randomValue != null ? randomValue : false} onClick={changeRandomInput} name="gilad" />}
                                        label="Random content"
                                    />
                                </Paper>
                            </FuseAnimate>
                        </ThemeProvider>
                    </div>


                    <FuseAnimate animation="transition.slideRightIn" delay={300}>
                        <Button
                            component={Link}
                            onClick={saveChange}
                            className="whitespace-no-wrap normal-case"
                            variant="contained"
                            color="secondary"
                        >
                            <span className="hidden sm:flex">Save</span>
                            <span className="flex sm:hidden">Save</span>
                        </Button>
                    </FuseAnimate>


                </div>
            }
            content={
                <div className="w-full flex flex-col">
                    <FuseAnimate animation="transition.slideRightIn" delay={300}>
                        <Button
                            component={Link}
                            onClick={handleClickOpen}
                            className="whitespace-no-wrap normal-case"
                            variant="contained"
                            color="secondary"
                        >
                            <span className="hidden sm:flex">Add content to row</span>
                            <span className="flex sm:hidden">Add content to row</span>
                        </Button>
                    </FuseAnimate>
                    <FuseScrollbars className="flex-grow overflow-x-auto">
                        {table()}
                    </FuseScrollbars>

                    {/* <TablePagination
                        className="overflow-hidden"
                        component="div"
                        count={programListf.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page'
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page'
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    /> */}
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
                        fullWidth={true}>
                        <DialogTitle id="form-dialog-title">Add content to row</DialogTitle>
                        <DialogContent className="w-full">
                            <Autocomplete
                                freeSolo
                                disableClearable
                                options={storesData.map((option) => {
                                    return (option)
                                })}
                                onChange={(event, value) => {
                                    addtoarry(value)
                                }}
                                getOptionLabel={(option) => {
                                    return option.titleEn + "-" + option.titleAr
                                }}
                                renderInput={(params) => {
                                    // console.log(params);
                                    return (<TextField
                                        {...params}
                                        label="Search and add (En/Ar)"
                                        margin="normal"
                                        variant="outlined"
                                        InputProps={{ ...params.InputProps, type: 'search' }}
                                    />);
                                }}

                            />


                        </DialogContent>

                    </Dialog>
                    <Snackbar open={open2} autoHideDuration={3000} onClose={handleClose2}>
                        <Alert onClose={handleClose2} severity="success">
                            Added!
                        </Alert>
                    </Snackbar>
                </div>


            }
            innerScroll
        />
    );
}

export default RowList;
