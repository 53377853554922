import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import Formsy from "formsy-react";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";
import clsx from 'clsx';
import Icon from '@material-ui/core/Icon';

import { orange } from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SaveIcon from "@material-ui/icons/Save";

const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "Games",
    link: `${process.env.REACT_APP_FRONT_URL}games/list`,
  },
  {
    title: "Assets",
    link: `${process.env.REACT_APP_FRONT_URL}games/assets`,
  },
  {
    title: "Add",
    link: "",
  },
];

class Add extends Component {
  state = {
    formValid: false,
    nameEn: "",
    nameAr: "",
    disabled: false,
    gamesImg: "",
    fileType: "image",
    categoryData: [],
    images: [],
    thumbnail: ''
  };
  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
  };
  handleSubmit = (model) => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    formData.append("id", this.props.match.params.id);
    formData.append("titleEn", this.state.titleEn);
    formData.append("titleAr", this.state.titleAr);
    formData.append("thumbnail", this.state.thumbnail);
    formData.append("cards", JSON.stringify(this.state.images));

    axios({
      url: `${backend_url}/games/listAsstesMomary/editAssets`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      if (res.data.message)
        this.props.history.push("/frontend/games/assets");
    });
  };
  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };

  fileUpload2 = (e, id) => {
    let image = document.getElementById(id);
    image.src = URL.createObjectURL(e.target.files[0]);
    this.setState({
      thumbnail: e.target.files[0],
    });
  };

  fileUpload = (e, id) => {
    let files = e.target.files;
    for (let i = 0; i < files.length; i++) {

      const reader = new FileReader();
      reader.readAsBinaryString(files[i]);

      reader.onload = () => {

        this.setState({
          images: this.state.images.concat([
            `data:${files[i].type};base64,${btoa(reader.result)}`

          ])
        })
      };

      reader.onerror = () => {
        console.log('error on load image');
      };

    }




  };
  componentDidMount() {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let id = this.props.match.params.id;

    let data = {
      id: id,
    };

    axios({
      url: `${backend_url}/games/listAsstesMomary/getassets`,
      data: data,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState(res.data.result);
      //{process.env.REACT_APP_BACKEND_URL+'/public/'+this.state.singerImg}
      this.setState({
        images: res.data.result.cards
      })
      let image = document.getElementById('thumbnail');
      image.src = process.env.REACT_APP_BACKEND_URL + '/public/' + this.state.thumbnail;
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="px-24">
            <h2>Add Assets</h2>
          </div>
        }
        content={
          <div className="p-24">
            <div className="max-w-lg">
              <Formsy
                onValidSubmit={this.handleSubmit}
                onValid={this.enableButton}
                onInvalid={this.disableButton}
                ref={"cateogry_add"}
                className="flex flex-col p6-12"
              >
                <div className="flex flex-row justify-between py-20">
                  <TextFieldFormsy
                    className="w-5/12"
                    type="text"
                    name="titleEn"
                    label="Title EN"
                    value={this.state.titleEn}
                    onChange={(e) => {
                      this.handleChange(e, "titleEn");
                    }}
                    required
                    variant="outlined"
                  />
                  <TextFieldFormsy
                    className="w-5/12"
                    type="text"
                    name="titleAr"
                    label="Title AR"
                    value={this.state.titleAr}
                    required
                    variant="outlined"
                    onChange={(e) => {
                      this.handleChange(e, "titleAr");
                    }}
                  />
                </div>

                <div className="flex flex-row justify-between py-20">
                  <div className="flex justify-between items-center w-5/12">
                    <Button variant="contained" component="label">
                      upload thumbnail
                      <input
                        accept="image/png"
                        type="file"
                        onChange={(e) => {
                          this.fileUpload2(e, "thumbnail");
                        }}
                        style={{ display: "none" }}
                      />
                    </Button>
                    <img
                      alt=""
                      id="thumbnail"
                      title="thumbnail"
                      style={{ maxWidth: "40%" }}
                      src="frontend/assets/images/custom/empty-image-thumb.png"
                    />
                  </div>
                </div>
                <Paper className="w-full rounded-8 shadow-none border-1">
                  <div className="flex items-center justify-between px-16 h-64 border-b-1 ">
                    <Typography className="text-16">Upload cards </Typography>
                  </div>
                  <div className="flex flex-wrap p-24">

                    <div className="flex justify-center sm:justify-start flex-wrap -mx-8">
                      <label
                        htmlFor="button-file"
                        className={clsx(
                          classes.productImageUpload,
                          'flex items-center justify-center relative w-128 h-128 rounded-4 mx-8 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5'
                        )}
                      >
                        <input
                          accept="image/png"
                          className="hidden"
                          id="button-file"
                          type="file"
                          multiple
                          onChange={(e) => {
                            this.fileUpload(e, "levelGame");
                          }}
                        />
                        <Icon fontSize="large" color="action">
                          cloud_upload
										</Icon>
                    Choose
                      </label>
                      {this.state.images.map(media => (
                        <div

                          role="button"
                          tabIndex={0}
                          className={clsx(
                            classes.productImageItem,
                            'flex items-center justify-center relative w-128 h-128 rounded-4 mx-8 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5'
                          )}
                        >
                          <Icon style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            color: 'red',
                            opacity: 0.9
                          }}
                            onClick={(e) => {
                              this.setState({
                                images: this.state.images.filter(item => item != media)
                              })
                            }}
                          >delete</Icon>
                          {media.length < 50 && (
                            <img className="max-w-none w-auto h-full" src={process.env.REACT_APP_BACKEND_URL + '/public/' + media} alt="product" />
                          )}
                          {media.length > 50 && (
                            <img className="max-w-none w-auto h-full" src={media}  />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>

                </Paper>


                <div>
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  className="ml-auto my-16"
                  disabled={!this.state.formValid}
                  type="submit"
                  variant="contained"
                >
                  Edit
                </Button>
              </Formsy>
            </div>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(Add);
