import FusePageCarded from '@fuse/core/FusePageCarded'; 
import React from 'react'; 
import LogsHeader from './LogsHeader';
import LogsTable from './LogsTable';

function Logs() {
	return (
		<FusePageCarded
			classes={{
				content: 'flex',
				header: 'min-h-72 h-72 sm:h-136 sm:min-h-136'
			}}
			header={<LogsHeader />}
			content={<LogsTable />}
			innerScroll
		/>
	);
}

export default Logs;
