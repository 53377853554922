

import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { TablePagination, TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	layoutRoot: {}
}));



function List(props) {
	const classes = useStyles(props);


	const [data, setData] = useState([])
	const [dataFilter, setDataFilter] = useState([])

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(12);

	const bookSearch = (e) => {
		let searchTxt = e.target.value;
		searchTxt = searchTxt.toLowerCase();
		searchTxt = searchTxt.replace(/(ة)/g, 'ه');
		searchTxt = searchTxt.replace(/(ى)/g, 'ي');
		searchTxt = searchTxt.replace(/(آ|إ|أ)/g, 'ا');


		let dataFilter2 = data;

		dataFilter2 = dataFilter2.filter((item) => {
			let bookName = item.name;
			bookName = bookName.toLowerCase();
			bookName = bookName.replace(/(ة)/g, 'ه');
			bookName = bookName.replace(/(ى)/g, 'ي');
			bookName = bookName.replace(/(آ|إ|أ)/g, 'ا');

			return bookName.search(searchTxt) !== -1
		});

		setDataFilter(dataFilter2);

	}

	useEffect(() => {

		axios({
			method: 'get',
			url: `${process.env.REACT_APP_BACKEND_URL}/book/list`,
			data: {
				type: 'book'
			},
			headers: {
				"content-type": "application/json"
			}
		}).then((res) => {
			setData(res.data)
			setDataFilter(res.data);
		});
		return () => {

		}
	}, [process])

	return (
		<FusePageCarded
			classes={{
				root: classes.layoutRoot
			}}
			header={
				<div className="p-24">
					<h4></h4>
				</div>
			}
			contentToolbar={
				<div className="px-24 w-full">
					<div className="flex justify-between items-center w-full">
						<h4>Books</h4>
						<div className="flex items-center">
							<TextField label={"Search"} className="mr-16" onChange={bookSearch} />

							<Link to="/frontend/book/add"><Button color="primary" variant="contained">Add</Button></Link>
						</div>

					</div>
				</div>
			}
			content={
				<div className="w-full flex flex-wrap p-12">
					{dataFilter.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => {

						return (<div className={"w-full md:w-1/4 p-12"}>



							<div className="flex flex-wrap justify-between p-12" style={{ boxShadow: "rgba(0, 0, 0, 0.55) 1px 1px 4px" }}>

								<div className="w-full pb-12 flex items-center justify-between">
									<div>
										<DeleteIcon style={{ color: "#51C0E5" }} />
										<Link to={`/book/${item._id}/edit`}><EditIcon style={{ color: "#51C0E5" }} /></Link>
									</div>
									<div><Button>Hide book</Button></div>

								</div>

								<div className={"w-1/2 pr-24"}>
									<div align="right">{item.name}</div>
									<div align="right">{item.author}</div>
								</div>
								<div className={"w-1/2"}><img src={`https://school.kutubee.com:4000/${item.coverPhoto}`} /></div>
								<div className="w-full pt-12 flex justify-between items-center">



									<Button color="primary" variant="contained">نشر</Button>
									<Link to={`/book/${item._id}/quizdata`}><Button color="primary" variant="contained">الاسئلة</Button></Link>
									<Link to={item.bookSource !== "build_in_audio" ? "/book/" + item._id + "/edit_audio?id=" + item._id + "&opf_file=" + item.opf_file + "&lang=" + item.language + "&type=" + item.bookSource + "&auto=1" : "/book/" + item._id + "/edit_audio?id=" + item._id + "&opf_file=" + item.opf_file + "&lang=" + item.language + "&type=" + item.bookSource}><Button color="primary" style={{ color: "#fff" }} variant="contained">تحرير الصوت </Button></Link>


								</div>


							</div>
						</div>);
					})}


					<table className="w-full">
						<TablePagination page={page} count={data.length} rowsPerPage={rowsPerPage}
							onChangePage={(e, page) => {
								setPage(page)
							}}
							onChangeRowsPerPage={(e) => {
								setRowsPerPage(parseInt(e.target.value));
								setPage(0);
							}}
						/>
					</table>


				</div>
			}
		/>
	);
}

export default List;
