import React,{ useState , useEffect } from 'react';
import {Redirect} from 'react-router-dom';
import Select from 'react-select';
import { ReactSortable } from "react-sortablejs";


import {
    CheckboxFormsy,
    FuseChipSelectFormsy,
    RadioGroupFormsy,
    SelectFormsy,
    TextFieldFormsy
} from '@fuse/core/formsy';
import Formsy from 'formsy-react';
import Button from '@material-ui/core/Button';
import FusePageCarded from '@fuse/core/FusePageCarded';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';

import DeleteIcon from '@material-ui/icons/Delete';
import Breadcrumb from './../../helper/Breadcrumb';
import StyledDropzone from './../../helper/StyledDropzone'; 
import AudioField from './../../helper/AudioField'; 
import Popup from './../../helper/Popup'; 
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextField } from '@material-ui/core';

const breadCurmbData = [
    {
        title:"Books",
        link:"/books/list"
    }
];


const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };


  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };


function QuizForm(props) {

    const [skillList, setSkillList] = useState([]);
    const [questionList, setQuestionList] = useState([]);
    const [bookData, setBookData] = useState({});
 //   const [counter, setCounter] = useState(0);

    const [popupTitle, setPopupTitle] = useState("");
    const [popupBody, setPopupBody] = useState("");
    const [popup, setPopup] = useState(false);
    const [formValid,setFormValid] = useState(true);

    const [redirect,setRedirect] = useState(false);

    const [wrongAnswers,setWrongAnswers] = useState([]);

    useEffect(() => {
        
        
        axios({
			method: 'post',
			url: `${process.env.REACT_APP_BACKEND_URL}/skills/autolist`,
			data: {
			  type: 'skill'
			},
			headers:{
				"content-type":"application/json"
			}
		  }).then((res) => {
            setSkillList(res.data);
          });
          
          axios({
			method: 'post',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/v2/get`,
			data: {
              id: props.match.params.id,
              type:"book",
              project:{name:1}
			},
			headers:{
				"content-type":"application/json"
			}
		  }).then((res2) => {
            let data = res2.data;
            if(data.length !== 0) {
                setBookData(data[0]);
            }
          });

          axios({
            method: 'post',
			url: `${process.env.REACT_APP_BACKEND_URL}/book/quiz/getdata`,
			data: {
              bookId: props.match.params.id
			},
			headers:{
				"content-type":"application/json"
			}
          }).then((res3) => {
              if(res3.data !== null) {
                setQuestionList(res3.data.quizData)
                if(res3.data.wrongAnswers !== undefined) {
                    setWrongAnswers(res3.data.wrongAnswers);
                }
              //  setCounter(res3.data.counter);
              }
          });

    }, [props])

    const addAnswer = (indx) => {
        let newData = [...questionList];

        let counter = -1;
        newData[indx].answers.forEach((answerItem) => {
            let id = answerItem.id;
            id = parseInt(id.replace(`${newData[indx].id}_ans_`,""));

            if(id > counter) {
                counter = id;
            }
        });
        counter++;

        newData[indx].answers = [
            ...newData[indx].answers,
            {
                name:"",
                id:`${newData[indx].id}_ans_${counter}`,
                correct:false
            }
        ]
        setQuestionList(newData);
    }

    const addWrongAnswer = (index) => {
        let newData = [...questionList];
        if(newData[index].wrongAnswers === undefined) {
            newData[index].wrongAnswers = [];
        }
        newData[index].wrongAnswers.push({name:""})
        setQuestionList(newData);
    }

    const deleteWrongAnswer = (indx,indx2) => {
        let newData = [...questionList];
        newData[indx].wrongAnswers = newData[indx].wrongAnswers.filter((item,index) => {
            return (index !== indx2)
        });
        setQuestionList(newData);
    }

    const updateSkills = (indx,v) => {
        let newData = [...questionList];
        if(v === null) {
            v = [];
        }
        newData[indx].skills = v;
                
        newData[indx].skills_ids = newData[indx].skills.map((item) => {
            return item.value
        });

        if(newData[indx].skills_ids.length === 0) {
            setFormValid(true)
        } else {
            setFormValid(false)

        }

    
        setQuestionList(newData);


    }

    const deleteQuestion = (indx) => {
        let newData = [...questionList];
        newData = newData.filter((item,i) => {
            return(i !== indx)
        });

        setQuestionList(newData);

    }

    const deleteAnswer = (indx,indx2) => {
        let newData = [...questionList];

        newData[indx].answers = newData[indx].answers.filter((item,i) => {
            return(i !== indx2)

        });

        setQuestionList(newData);

    }

    const addQuestion = () => {
        let newData = [...questionList];
        let book_id = props.match.params.id;
      let counter = -1;
    //   if(newData !== undefined) {
    //     counter = newData.length;
    //   }

      newData.forEach((item) => {
        let id = item.id;
        id = parseInt(id.replace(`${book_id}q`,""));

        if(id > counter) {
            counter = id;
        }
      });
      counter++;

        
        let data = {
            id:`${book_id}q${counter}`,
            name:"",
            nameText:"",
            skills:"",
            answerType:"text",
            questionType:"multiple_choice",
            audio:"",
            answers:[],
            wrongAnswers:[]
        }

      //  currentcount = currentcount + 1;
   //     setCounter(currentcount);

        

        setQuestionList(questionList => [...questionList, data]);
    }
    const clearUpload = (indx2,indx) => {
        let newData = [...questionList];
        newData[indx].answers[indx2].name = "";
        setQuestionList(newData);

    }
    const clearAnswerUpload = (indx2,indx) => {
        let newData = [...questionList];
        newData[indx].answers[indx2].audio = "";
        setQuestionList(newData);

    }
    const handleUpload = (files,indx2,indx) => {
        
        let formData = new FormData();
        formData.append("file",files[0]);
        formData.append("dest","quiz/audios_answer");

        axios({
			method: 'post',
			url: `${process.env.REACT_APP_BACKEND_URL}/upload/quiz_audio`,
			data: formData,
			headers:{
				"content-type":"multipart/form-data"
			}
		  }).then((res) => {
            let newData = [...questionList];
            newData[indx].answers[indx2].name = res.data.fileurl;
            setQuestionList(newData);
    
          });

        //setQuestionList(newData);
    }

    const handleAnswerUpload = (files,indx2,indx) => {
        
        let formData = new FormData();
        formData.append("file",files[0]);
        formData.append("dest","quiz/audios_answer");

        axios({
			method: 'post',
			url: `${process.env.REACT_APP_BACKEND_URL}/upload/quiz_audio2`,
			data: formData,
			headers:{
				"content-type":"multipart/form-data"
			}
		  }).then((res) => {
            let newData = [...questionList];
            newData[indx].answers[indx2].audio = res.data.fileurl;
            setQuestionList(newData);
    
          });

        //setQuestionList(newData);
    }


    const handleTxt = (value,indx2,indx) => {
        let newData = [...questionList];
        newData[indx].answers[indx2].name = value;
        setQuestionList(newData);
    }
    const handleTxtV2 = (value,indx2,indx) => {
        let newData = [...questionList];
        newData[indx].answers[indx2].nameText = value;
        setQuestionList(newData);
    }

    const handleTxtV3 = (value,indx2,indx) => {
        let newData = [...questionList];
        newData[indx].answers[indx2].nameText2 = value;
        setQuestionList(newData);
    }
    

    const handleCorrect = (e,indx2,indx) => {
        let newData = [...questionList];
        newData[indx].correctAnswer = indx2;
        // newData[indx].answers[indx2].correct = e.target.checked;
        
        setQuestionList(newData);
    }

    const handleCorrect2 = (e,indx2,indx) => {
        let newData = [...questionList];
        newData[indx].correctAnswer = indx2;
        newData[indx].answers[indx2].correct = e.target.checked;
        
        setQuestionList(newData);
    }


    const handleFixed = (e,indx2,indx) => {
        let newData = [...questionList];
        newData[indx].answers[indx2].fixed = true;

        setQuestionList(newData);
    }

    const updateQuestions = (name,i,value) => {
        let newData = [...questionList];
        newData[i][name] = value;

        if(name === "questionType") {
            if(value === "drag_drop") {
                newData[i].answerType = "image";
            }
        }

        if(name == "answerType") {
            newData[i].answers = [];
        }

    

        

        setQuestionList(newData);
    }

    const clear = (name,i) => {
        let newData = [...questionList];
        newData[i][name] = "";
        setQuestionList(newData);
    }
    const uploadAudio = (name,i,files) => {
        let formData = new FormData();
        formData.append("file",files[0]);
        formData.append("dest","quiz/audios");

        axios({
			method: 'post',
			url: `${process.env.REACT_APP_BACKEND_URL}/upload/quiz_audio2`,
			data: formData,
			headers:{
				"content-type":"multipart/form-data"
			}
		  }).then((res) => {
            let newData = [...questionList];
            newData[i][name] = res.data.fileurl;
            setQuestionList(newData);
    
          });
    }

    const handleSubmit = (e) => {
        let quizData = [...questionList];
        

        let bookId = props.match.params.id;

       // let data  = {quizData,bookId,counter}
       
       let data  = {quizData,bookId}

       let find = 1;
       let location = -1;
       let check_answer_audio = true;

       data.quizData.forEach((item,i) => {

           if(item.correctAnswer === undefined && find === 1 && item.questionType === "multiple_choice") {
                find = 0;
                location = i;
           }
           let audioExists = false;
           item.answers.forEach((answer) => {
               if(answer.audio !== undefined && answer.audio !== null && answer.audio !== "" && answer.audio !== "null" && answer.audio !== "undefined") {
                audioExists = true;
               } else {
                   if(audioExists === true) {
                    check_answer_audio = false;
                   }
               }
           });

       });

       
       

        if(check_answer_audio === false) {
                
            setPopupBody("audio answer must upload on all answers");
            setPopup(true);
            setTimeout(() => {
                setPopup(false);
    
            }, 3500);

        } else {
            if(find === 0) {
                setPopupBody("Correct answer missing in question # " + (location + 1));
                setPopup(true);
                setTimeout(() => {
                    setPopup(false);
        
                }, 3500);
           } else {
    
                setPopupBody(<CircularProgress />);
                setPopup(true);
                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_BACKEND_URL}/book/quiz/update`,
                    data: data,
                    headers:{
                        "content-type":"application/json"
                    }
                }).then((res) => {
    
                    setPopupBody("Book data saved successfully");
    
                    setTimeout(() => {
                        setPopup(false);
                        setRedirect(true);
                    }, 3500);
    
                });
    
           }
        }



    }
    const checkValid = () => {

        let checkQuiz = true;


        questionList.forEach((item) => {
            if(item.questionType !== "open_question") {
                if(item.skills.length === 0) {
                    checkQuiz = false;
                }
            }

        })
        
        if(checkQuiz === false) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }



    }
    return(
    
    <FusePageCarded
    classes={{
        root: ""
    }}
    header={
        <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
        </div>
    }
    contentToolbar={
        <div className="px-24 flex justify-between items-center w-full flex-wrap">
            <h4>Book quiz</h4>
            
        </div>
    }
    content={<Formsy
        //ref="form"
        onInvalid={() => {setFormValid(true)}}
        onValid={checkValid}
        className="flex flex-wrap w-full">

                <div className="w-full p-24">
                    Book name: {bookData.name}
                </div>

                {questionList.map((item,indx) => {
                    return(         <div className="w-full md:w-10/12 flex flex-wrap p-48 pl-0 pr-0">
                        <div className="w-full p-12"><SelectFormsy
                        className="w-1/3"
                                                label="Question type"
                                                value={item.questionType}
                                                onChange={(e) => { updateQuestions("questionType",indx,e.target.value)}}
                        name="name"
                        >
                            <MenuItem value="multiple_choice">Multiple choice</MenuItem>
                            <MenuItem value="multiple_answers">Multiple answers</MenuItem>

                            <MenuItem value="matching">matching</MenuItem>
                            <MenuItem value="sorting">Sorting</MenuItem>
                            <MenuItem value="open_question">Open Question</MenuItem>
                            <MenuItem value="memory_game">Memory Game</MenuItem>
                            <MenuItem value="drag_drop">Drag & drop</MenuItem>

                            </SelectFormsy></div>
                        <div className="w-full p-12" align="right"><DeleteIcon onClick={(e) => { deleteQuestion(indx) }} style={{cursor:"pointer"}} /></div>


                        {item.questionType === "open_question" && <>

                        <div className="form-item w-1/2 p-12">
                    <TextFieldFormsy
                        label="Question name"
                        className="w-full"
                      //  onChange={(e) => {setNameEn(e.target.value)}}
                      name="name"
                        value={item.name}
                        onChange={(e,i) => { updateQuestions("name",indx,e.target.value)}}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Field required']}
                        multiline={true}
                        required
                    />
                    </div>

                  

                        </>}
                    
                    {<>

                        {item.questionType !== "open_question" && 

                    <div className="form-item w-1/2 p-12">
                    <TextFieldFormsy
                        label="Question name"
                        className="w-full"
                      //  onChange={(e) => {setNameEn(e.target.value)}}
                      name="name"
                        value={item.name}
                        onChange={(e,i) => { updateQuestions("name",indx,e.target.value)}}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Field required']}
                        required
                    />
                    </div>
                }
    
                    <div className="form-item w-1/2 p-12">
                    <Select
                        label="Skills"
                        className="w-full"
                        name="name"
                        isMulti
                        variant="outlined"
                        placeholder={"Skills"}
                        options={skillList}
                        onChange={(v) => {
                            updateSkills(indx,v);
                        }}
                        hideSelectedOptions={true}
                        value={item.skills}
                    />
                    </div>

                        {item.questionType !== "open_question" && item.questionType !== "drag_drop" && 
                    <div className="form-item w-1/2 p-12">
                    <SelectFormsy
                        label="Answer type"
                        className="w-full"
                        name="name"
                        variant="outlined"
                        value={item.answerType}
                        required
                        onChange={(e,i) => { updateQuestions("answerType",indx,e.target.value)}}

                        required
                    >
                        {item.questionType !== "memory_game" && <MenuItem value={"text"}>text</MenuItem>}
                        
                        {item.questionType !== "matching" && <MenuItem value={"image"}>image</MenuItem>}
                        
                    </SelectFormsy>
                    </div>}

                    <div className="form-item w-1/2 p-12">
                   
                   <AudioField title={"Drag or upload question audio"} value={item.audio} type="audio" clear={() => {
                       clear("audio",indx);
                   }} submit={(e) => {
                       uploadAudio("audio",indx,e)
                   }} />
               </div>

               {item.questionType === "drag_drop" && 
               <>
               <div className="form-item w-full p-12">
                   <label className='p-12'><strong>Wrong answers</strong></label>

                   {item.wrongAnswers !== undefined && <>
                    {item.wrongAnswers.map((item,indx2) => {
                       return(<div className="form-item w-1/2 p-12 flex items-center">   
                       <TextFieldFormsy value={item.name} required variant="outlined" className="w-full" onChange={(e) => {
                           let newData = [...questionList];
                            newData[indx].wrongAnswers[indx2].name = e.target.value;
                            setQuestionList(newData);
                       }} label="Text" name="answer" />   
                       <div className="pl-12"><DeleteIcon onClick={(e) => {deleteWrongAnswer(indx,indx2)}} style={{cursor:"pointer"}} /></div>
                       </div>)
                })} 
                   </>}
                    

                   
                <div className="form-item w-1/2 mt-12">
                        <Button color="primary" variant="contained" onClick={(e) => {addWrongAnswer(indx)}}>Add wrong Answer</Button>
                    </div>
                </div>
               </>
                }
              

               {item.questionType !== "open_question" && <>

               {item.answers.map((subItem,indx2) => {
                   return(<div className="form-item w-full p-12 flex items-center">

                   
                  {item.questionType !== "matching" && 
                  <div className="form-item w-1/3 pr-12">
                       {item.answerType == "text"  && <TextFieldFormsy required onChange={(e) => { handleTxt(e.target.value,indx2,indx) }} variant="outlined" value={subItem.name} className="w-full" label="Text" name="answer" />}    
                       {item.answerType == "image" && <div className="w-full" align="center">

                       <StyledDropzone title={"Drag or upload image"} value={subItem.name} type="audio" clear={() => {
                       clearUpload(indx2,indx);
                   }} submit={(e) => {
                       handleUpload(e,indx2,indx)
                   }} />

                           
                           </div>}    

                          

                   </div>
                  } 
                    

                    {item.questionType === "matching" && 
                    <>
                    <div className="form-item w-1/3 pr-12">
                        <TextFieldFormsy required onChange={(e) => { handleTxtV2(e.target.value,indx2,indx) }} variant="outlined" value={subItem.nameText} className="w-full" label="Text" name="answer" />   
</div>
<div className="form-item w-1/3 pr-12">
                        <TextFieldFormsy required onChange={(e) => { handleTxtV3(e.target.value,indx2,indx) }} variant="outlined" value={subItem.nameText2} className="w-full" label="Text" name="answer" />   
</div>
                    </>}


                    
{item.questionType === "drag_drop" && 
<>
<div className="form-item w-1/3 pr-12">
                        <TextFieldFormsy required onChange={(e) => { handleTxtV2(e.target.value,indx2,indx) }} variant="outlined" value={subItem.nameText} className="w-full" label="Text" name="answer" />   
</div>
{/* <div className="form-item w-1/3 pr-12">
                        <div className="w-full" align="center">

                       <StyledDropzone title={"Drag or upload image"} value={subItem.name} type="audio" clear={() => {
                       clearUpload(indx2,indx)
                   }} submit={(e) => {
                       handleUpload(e,indx2,indx)
                   }} />

                           
                           </div>

                          

                   </div> */}


</>
                  } 




                   {/* {item.questionType == "drag_drop" && 
                           <>

<div className="form-item w-1/3 pr-12">

                           <TextFieldFormsy required onChange={(e) => { handleTxtV2(e.target.value,indx2,indx) }} variant="outlined" value={subItem.nameText} className="w-full" label="Text" name="answer" />
</div>
<div className="form-item w-1/3 pr-12">

                           <div className="w-full" align="center">

<StyledDropzone title={"Drag or upload image"} value={subItem.name} type="audio" clear={() => {
clearUpload(indx2,indx);
}} submit={(e) => {
handleUpload(e,indx2,indx)
}} />

    
    </div>

                           </div>
                          
                           </>
                         }   */}

                   {item.questionType !== "matching" && item.questionType !== "sorting" && item.questionType !== "memory_game" && item.questionType !== "drag_drop" &&
                   <div className="form-item w-1/3 pr-12">
                   <AudioField title={"Drag or upload answer audio"} value={subItem.audio} type="audio" clear={() => {
                       clearAnswerUpload(indx2,indx);
                   }} submit={(e) => {
                     //  handleUpload(e,indx2,indx)
                     handleAnswerUpload(e,indx2,indx);
                   }} />
                   </div>}
                   <div className="form-item w-1/3">
                       {(item.questionType === "multiple_choice" || item.questionType === "multiple_answers") && <>

                       {item.questionType === "multiple_choice" && <Radio style={{color:"#192D3E"}} name="correct" label="test" onChange={(e) => { handleCorrect(e,indx2,indx) }} checked={(indx2 === item.correctAnswer)?true:false} value="1" />}
                       
                       {item.questionType === "multiple_answers" && <Checkbox style={{color:"#192D3E"}} name="correct" label="test" onChange={(e) => { handleCorrect2(e,indx2,indx) }} checked={(subItem.correct === true)?true:false} value="1" />}

                       <Checkbox style={{color:"#192D3E"}} name="correct" label="test" onChange={(e) => { handleFixed(e,indx2,indx) }} checked={subItem.fixed} value="1" />

                       </>}

                       <DeleteIcon onClick={(e) => { deleteAnswer(indx,indx2) }} style={{cursor:"pointer"}} />
                   </div>
               </div>);
               })}

<div className="form-item w-full p-12">
                        <Button color="primary" variant="contained" onClick={(e) => {addAnswer(indx)}}>Add Answer</Button>
                    </div>


               </>}

        


                    
                    </>}
                   
    
    
                 
    
                    {/* <div className="form-item w-1/2 p-12 flex">
                        <label style={{display:"block",padding:"22px 15px 15px 0px"}}>Audio</label>
                        <div>
                            {item.audio !== "" && <audio controls src={`${process.env.REACT_APP_BACKEND_URL}/${item.audio}`} />}
                            
                            <div style={{marginTop:15}} className="flex items-center justify-between">

                                <Button color="primary" variant="contained" style={{position:"relative"}}>
                                    Upload File
                                    <input style={{position:"absolute",left:"0px",top:"0px",opacity:0}}  type="file" onChange={(e,i) => { uploadAudio("audio",indx,e)}} />

                                </Button>
                            </div>
                        </div>
                    </div> */}
                  


                    
             </div>);
                })}

<div className="form-item w-full p-12" align="right">
                <div className="form-item p-12 pr-0">
                        <Button style={{marginRight:"15px"}} color={"primary"} variant="contained" onClick={addQuestion}>Add question</Button>
                        <Button color={"primary"} variant="contained" disabled={formValid} onClick={handleSubmit}>Save</Button>
                </div>
            </div>



                <Popup body={popupBody} title={popupTitle} open={popup} />
                {redirect === true && <Redirect to="/books/list" />}

    </Formsy>}
    />
    );
}

export default QuizForm;