import view from "./view";
import forgetpassword from "./forgetpassword";

export const resetpasswordApp = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${process.env.REACT_APP_FRONT_URL}resetpassword/:id`,
      component: view,
    }, {
      path: `${process.env.REACT_APP_FRONT_URL}forgetpassword`,
      component: forgetpassword,
    },
  ],
};
