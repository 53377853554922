import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import Checkbox from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Formsy from "formsy-react";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";
const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "Subscription",
    link: `${process.env.REACT_APP_FRONT_URL}subscription/list`,
  },
  {
    title: "Edit",
    link: "",
  },
];

class Edit extends Component {
  state = {
    formValid: false,
    disabled: false,
    titleEn: "",
    titleAr: "",
    swn: "1",
    planType: "free",
    exclusive: false,
    watch: false,
    cancel: false,
    hexcode: '',
    monthly: '',
    annualy: '',
    googlePlayId: '',
    appStoreId: '',
    priceLabel: '',
    numProfile: ''
  };
  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
  };
  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.checked,
    });
  };
  handleSubmit = (model) => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();

    formData.append("titleEn", this.state.titleEn);
    formData.append("titleAr", this.state.titleAr);
    formData.append("hexcode", this.state.hexcode);
    formData.append("planType", this.state.planType);
    if (this.state.planType != 'free') {
      formData.append("monthly", this.state.monthly);
      formData.append("annualy", this.state.annualy);
    }
    formData.append("swn", this.state.swn);

    formData.append("googlePlayId", this.state.googlePlayId);
    formData.append("appStoreId", this.state.appStoreId);

    formData.append("exclusive", this.state.exclusive);
    formData.append("watch", this.state.watch);
    formData.append("numProfile", this.state.numProfile);
    
    formData.append("cancel", this.state.cancel);
    formData.append("priceLabel", this.state.priceLabel);
    formData.append("id", this.props.match.params.id);

    axios({
      url: `${backend_url}/bundles/update`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.props.history.push('/frontend/subscription/list');
    });
  };
  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };

  fileUpload = (e, id) => {
    let image = document.getElementById(id);
    image.src = URL.createObjectURL(e.target.files[0]);
    this.setState({
      imgUpload: e.target.files[0],
    });
  };
  componentDidMount() {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let id = this.props.match.params.id;

    let data = {
      id: id,
    };

    axios({
      url: `${backend_url}/bundles/get`,
      data: data,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState(res.data.result);
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="px-24">
            <h2>Edit Subscription</h2>
          </div>
        }
        content={

          <div className="p-24">
            <div className="max-w-lg">
              <Formsy
                onValidSubmit={this.handleSubmit}
                onValid={this.enableButton}
                onInvalid={this.disableButton}
                ref={"cateogry_add"}
                className="flex flex-col p6-12"
              >
                <div className="flex flex-row justify-between py-20">
                  <TextFieldFormsy
                    className="w-5/12"
                    type="text"
                    name="titleEn"
                    label="Plan title EN"
                    value={this.state.titleEn}
                    onChange={(e) => {
                      this.handleChange(e, "titleEn");
                    }}
                    variant="outlined"
                    required
                  />
                  <TextFieldFormsy
                    className="w-5/12"
                    type="text"
                    name="titleAr"
                    label="Plan title AR"
                    value={this.state.titleAr}
                    required
                    onChange={(e) => {
                      this.handleChange(e, "titleAr");
                    }}
                    variant="outlined"
                  />
                  <input
                    type="color"
                    value={this.state.hexcode}
                    onChange={(e) => {
                      this.handleChange(e, "hexcode");
                    }}
                  />
                </div>

                <div className="flex flex-row justify-between py-20">
                  <SelectFormsy
                    className="w-5/12"
                    name="planType"
                    label="Plan Type"
                    value={this.state.planType}
                    onChange={(e) => {
                      this.handleChange(e, "planType");
                    }}
                    required
                    variant="outlined"
                  >
                    <MenuItem value="free">Free</MenuItem>
                    <MenuItem value="paid">Paid</MenuItem>
                  </SelectFormsy>
                  {this.state.planType == 'paid' ?
                    <b>
                      <TextFieldFormsy
                        className="w-6/12 pr-20"
                        type="text"
                        name="monthly"
                        label="Monthly"
                        value={this.state.monthly}
                        onChange={(e) => {
                          this.handleChange(e, "monthly");
                        }}
                        required
                        variant="outlined"
                      />
                      <TextFieldFormsy
                        className="w-6/12"
                        type="text"
                        name="annualy"
                        label="Annualy"
                        value={this.state.annualy}
                        onChange={(e) => {
                          this.handleChange(e, "annualy");
                        }}
                        required
                        variant="outlined"
                      /> </b> :

                    <TextFieldFormsy
                      type="text"
                      name="priceLabel"
                      label="Price Label"
                      value={this.state.priceLabel}
                      onChange={(e) => {
                        this.handleChange(e, "priceLabel");
                      }}
                      required
                      variant="outlined"
                    />
                  }

                </div>




                <div className="flex flex-coll  py-20">

                  <TextFieldFormsy
                    className="w-2/12 mr-24"
                    type="number"
                    name="swn"
                    label="Screens Number"
                    value={this.state.swn}
                    onChange={(e) => {
                      this.handleChange(e, "swn");
                    }}
                    required
                    variant="outlined"
                  />
                  <TextFieldFormsy
                    className="w-2/12"
                    type="number"
                    name="nofp"
                    label="Number of profiles"
                    value={this.state.numProfile}
                    onChange={(e) => {
                      this.handleChange(e, "numProfile");
                    }}
                    required
                    variant="outlined"
                  />
                </div>



                <div className="flex flex-row justify-between py-.">
                  <FormControlLabel
                    control={<Checkbox checked={JSON.parse(this.state.exclusive)}
                      onChange={(e) => {
                        this.handleChange2(e, "exclusive");
                      }}
                      name="exclusive" />}
                    label="Exclusive shows, stories,
                    songs and games
                     "
                  />

                </div>

                <div className="flex flex-row justify-between py-0">
                  <FormControlLabel
                    control={<Checkbox checked={JSON.parse(this.state.watch)}
                      onChange={(e) => {
                        this.handleChange2(e, "watch");
                      }}
                      name="watch" />}
                    label="Watch on mobile, tablet  "
                  />

                </div>

                <div className="flex flex-row justify-between py-0">
                  <FormControlLabel
                    control={<Checkbox checked={JSON.parse(this.state.cancel)}
                      onChange={(e) => {
                        this.handleChange2(e, "cancel");
                      }}
                      name="cancel" />}
                    label="Cancel anytime "
                  />

                </div>

                <div className="flex flex-row justify-between py-20">
                  <TextFieldFormsy
                    className="w-5/12"
                    type="text"
                    name="appStoreId"
                    label="App Store ID"
                    onChange={(e) => {
                      this.handleChange(e, "appStoreId");
                    }}
                    variant="outlined"
                    required
                    value={this.state.appStoreId}
                  />
                  <TextFieldFormsy
                    className="w-5/12"
                    type="text"
                    name="googlePlayId"
                    label="Google Play ID"
                    required
                    onChange={(e) => {
                      this.handleChange(e, "googlePlayId");
                    }}
                    variant="outlined"
                    value={this.state.googlePlayId}
                  />

                </div>


                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="ml-auto my-16"
                  aria-label="Add"
                  disabled={!this.state.formValid}
                >
                  Edit Plan
                </Button>


              </Formsy>
            </div>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(Edit);
