import Add from "./Add";
import Edit from "./Edit";
import List from "./List";
import Season from "./season";
import Episodes from "./Episodes";

import { authRoles } from 'app/auth';


export const showsConfig = {
  settings: {
    layout: {
      config: {},
    },
  }, 
  routes: [
    {
      path: `${process.env.REACT_APP_FRONT_URL}shows/add`,
      component: Add,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}shows/:id/edit`,
      component: Edit,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}shows/list`,
      component: List,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}shows/:id/season`,
      component: Season,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}shows/:id/episodes/:id2`,
      component: Episodes,
    },
  ],
};
