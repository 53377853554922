import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import Formsy from "formsy-react";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";
import clsx from 'clsx';
import Icon from '@material-ui/core/Icon';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SaveIcon from "@material-ui/icons/Save";

const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "Games",
    link: `${process.env.REACT_APP_FRONT_URL}games/list`,
  },
  {
    title: "Coloring Games Levels",
    link: `${process.env.REACT_APP_FRONT_URL}games/levelcoloring/list`,
  },
  {
    title: "Add",
    link: "",
  },
];

class Add extends Component {
  state = {
    formValid: false,
    disabled: false,
    thumbnail: '',
    svgFile: '',
    svgFile2: '',
    titleEn: '',
    titleAr: '',

  };
  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
  };
  handleSubmit = (model) => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();

    formData.append("titleEn", this.state.titleEn);
    formData.append("titleAr", this.state.titleAr);
    formData.append("thumbnail", this.state.thumbnail);
    formData.append("svgFile", this.state.svgFile);  
    formData.append("svgFile2", this.state.svgFile2);  

    axios({
      url: `${backend_url}/games/coloring/add`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.props.history.push("/frontend/games/levelcoloring/list");
    });
  };
  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };
  componentDidMount() {
    let backend_url = process.env.REACT_APP_BACKEND_URL;

    axios({
      url: `${backend_url}/games/listAsstesMomary`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        categoryData: res.data.result,
      });
    });
  }


  fileUpload2 = (e, id) => {
    if (id === 'thumbnail') {
      let image = document.getElementById(id);
      image.src = URL.createObjectURL(e.target.files[0]);
    }

    this.setState({
      [id]: e.target.files[0],
    });
  };


  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="px-24">
            <h2>Add Level</h2>
          </div>
        }
        content={
          <div className="p-24">
            <div className="max-w-lg">
              <Formsy
                onValidSubmit={this.handleSubmit}
                onValid={this.enableButton}
                onInvalid={this.disableButton}
                ref={"cateogry_add"}
                className="flex flex-col p6-12"
              >
                <div className="flex flex-row justify-between py-20">
                  <TextFieldFormsy
                    className="w-5/12"
                    type="text"
                    name="titleEn"
                    label="Title EN"
                    onChange={(e) => {
                      this.handleChange(e, "titleEn");
                    }}
                    required
                    variant="outlined"
                  />
                  <TextFieldFormsy
                    className="w-5/12"
                    type="text"
                    name="titleAr"
                    label="Title AR"
                    required
                    variant="outlined"
                    onChange={(e) => {
                      this.handleChange(e, "titleAr");
                    }}
                  />
                </div>


                <div className="flex flex-row justify-between py-20">
                  <div className="flex justify-between items-center w-5/12">
                    <Button variant="contained" component="label">
                      upload SVG File (with color)
                      <input
                        accept="image/svg+xml"
                        type="file"
                        required
                        onChange={(e) => {
                          this.fileUpload2(e, "svgFile");
                        }}
                        style={{ display: "none" }}
                      />
                    </Button>

                  </div>
                  <div className="flex justify-between items-center w-5/12">

                    <div>
                      {
                        this.state.svgFile !== '' && (
                          <div>
                            <p>File Name: {this.state.svgFile.name}</p>
                            <p>File Size: {this.state.svgFile.size / 100000}MB</p>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>

                <div className="flex flex-row justify-between py-20">
                  <div className="flex justify-between items-center w-5/12">
                    <Button variant="contained" component="label">
                      upload SVG File (without color)
                      <input
                        accept="image/svg+xml"
                        type="file"
                        required
                        onChange={(e) => {
                          this.fileUpload2(e, "svgFile2");
                        }}
                        style={{ display: "none" }}
                      />
                    </Button>

                  </div>
                  <div className="flex justify-between items-center w-5/12">

                    <div>
                      {
                        this.state.svgFile2 !== '' && (
                          <div>
                            <p>File Name: {this.state.svgFile2.name}</p>
                            <p>File Size: {this.state.svgFile2.size / 100000}MB</p>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>


                <div className="flex flex-row justify-between py-20">
                  <div className="flex justify-between items-center w-5/12">
                    <Button variant="contained" component="label">
                      upload thumbnail
                      <input
                        accept="image/png"
                        type="file"
                        required
                        onChange={(e) => {
                          this.fileUpload2(e, "thumbnail");
                        }}
                        style={{ display: "none" }}
                      />
                    </Button>

                  </div>
                  <div className="flex justify-between items-center w-5/12">

                    <img
                      alt=""
                      id="thumbnail"
                      title="thumbnail"
                      style={{ maxWidth: "40%" }}
                      src="frontend/assets/images/custom/empty-image-thumb.png"
                    />
                  </div>
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  className="ml-auto my-16"
                  disabled={!this.state.formValid}
                  type="submit"
                  variant="contained"
                >
                  Add
                </Button>
              </Formsy>
            </div>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(Add);
