import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import Formsy from "formsy-react";
import Breadcrumb from "./../components/Breadcrumb";
import axios from "axios";

import TextField from '@material-ui/core/TextField';
const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "App Messages",
    link: `${process.env.REACT_APP_FRONT_URL}appsettings/listAppMessages`,
  },
  {
    title: "Edit",
    link: "",
  },
];

class Edit extends Component {
  state = {
    formValid: false,
    en: "",
    ar: "",
    disabled: false,
  };
  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
  };
  handleSubmit = (model) => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();

    formData.append("en", this.state.en);
    formData.append("ar", this.state.ar);
    formData.append("id", this.props.match.params.id);

    axios({
      url: `${backend_url}/appmsg/update`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.props.history.push('/frontend/appsettings/listAppMessages');
    });
  };
  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };

  componentDidMount() {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let id = this.props.match.params.id;

    let data = {
      id: id,
    };

    axios({
      url: `${backend_url}/appmsg/get`,
      data: data,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState(res.data.result);
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="px-24">
            <h2>Edit App Message</h2>
          </div>
        }
        content={
          <div className="p-24">
            <div className="max-w-lg">
              <Formsy
                onValidSubmit={this.handleSubmit}
                onValid={this.enableButton}
                onInvalid={this.disableButton}
                ref={"appMsg_add"}
                className="flex flex-col p6-12"
              >
                <div className="flex flex-row justify-between py-20">
                <TextField
                    id="outlined-multiline-static"
                    label="EN"
                    className="w-6/12"
                    multiline
                    rows={4}
                    value={this.state.en}
                    variant="outlined"
                    required
                    onChange={(e) => {
                      this.handleChange(e, "en");
                    }}
                  />

                </div>
            

                <div className="flex flex-row justify-between py-20">
                  <TextField
                    id="outlined-multiline-static"
                    label="AR"
                    className="w-6/12"
                    multiline
                    rows={4}
                    value={this.state.ar}
                    variant="outlined"
                    required
                    onChange={(e) => {
                      this.handleChange(e, "ar");
                    }}
                    style={{
                      direction:'rtl'
                    }}
                  />

                </div>


                <Button
                  type="submit"
                  variant="contained"
                  color="primary" 
                  aria-label="Add"
                  
                  className="w-6/12" 
                >
                  Edit
                </Button>
              </Formsy>
            </div>
          </ div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(Edit);
