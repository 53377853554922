import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';

function Widget1(props) {
	const [currentRange, setCurrentRange] = useState(props.widget.currentRange);

	function handleChangeRange(ev) {
		setCurrentRange(ev.target.value);
	}

	return (
		<Paper className="w-full rounded-8 shadow-none border-1">
		 <div className="flex items-center justify-between px-4 pt-4">
				<Typography className="text-16 px-12">{props.widget.label}</Typography>
				<IconButton aria-label="more">
					<Icon>more_vert</Icon>
				</IconButton>
			</div>
			<div className="text-center pt-12 pb-28">
				<Typography className="text-72 leading-none text-blue">
					{props.widget.counter}
				</Typography>
				<Typography className="text-16" color="textSecondary">
					
				</Typography>
			</div>
			<div className="flex items-center px-16 h-52 border-t-1  text-center">
				<Typography className="text-15 flex w-full  text-center" color="textSecondary"> 
					<b className="px-8">{props.widget.label}</b>
				</Typography>
			</div>
		</Paper>
	);
}

export default React.memo(Widget1);
