import React from 'react'; 

import PageAudio from './bookComponents/pageAudio';
import PageAudio2 from './bookComponents/pageAudio2';
import Story from './bookComponents/story';
import Story2 from './bookComponents/story2';
import StoryNew from './bookComponents/story_new';

import SpansTable from './bookComponents/spansTable';
import { createStore } from 'redux';
import { Provider } from 'react-redux';

const initialState = {
    diff:0,
    audioLink:"",
    audios:[],
    audioObj:{},
    audioName:"",
    startTime:0,
    htmlpage:"",
    page_count:0,
    pointIndex:false,
    selectid:"",
    pagePath:"",
    waverColor:"dark",
    page_ids:[],
    cssLinks:[],
    showTxts:"hide-bt",
    checkBook:false,
    selectedIds:[],
    selectedIndex:0,
    selectedPara:0,
    pagesWidth:[],
    saveTextCheck:false,
    editLineText:false,
    book:{
        bookId:"fish",
        title:'book1',
        version:'',
        pages:[]
    },
    pageType:"",
    clearSlicing:0,
    clearSlicing1:0,
    selected_sp:[],
    paraSmilId:"",
    paraSpanId:"",
    regions:{},
    slicing:[],
    updateText:false,
    saveDisabled:"disabled"
};

function rootReducer(state = initialState, action) {
    /*if(action.type === "inc") {
        return {
            count2:state.count2 + 1,
            count:state.count
        }
    }
    if(action.type === "dec") {
        return {
            count2:state.count2 - 1,
            count:state.count
        }
    }*/ 
    if(action.type === "update_disbaled") {
        state.saveDisabled = action.saveDisabled;
        return {
            ...state,
            saveDisabled:action.saveDisabled,
        }
    }
    if(action.type === "updateText") {
        state.updateText = action.updateText;
        return {
            ...state,
            updateText:action.updateText,
        }
    }
    
    if(action.type === "slicing_update") {
        state.slicing = action.slicing;
        return {
            ...state,
            slicing:action.slicing,
        }
    }
    if(action.type === "update_regions") {
        state.regions = action.regions;
        return {
            ...state,
            regions:action.regions,
        }
    }
    if(action.type === "update_para") {
        state.paraSmilId = action.paraSmilId;
        state.paraSpanId = action.paraSpanId;
        return {
            ...state,
            paraSmilId:action.paraSmilId,
            paraSpanId:action.paraSpanId
        }
    }
    if(action.type === "update_selected_sp") {
        state.selected_sp = action.selected_sp;
        return {
            ...state,
            selected_sp:action.selected_sp
        }
    }
    
    if(action.type === "update_opf") {
        state.opf_dir = action.opf_dir;
        state.opf_link = action.opf_link;
        return {
            ...state,
            opf_link:action.opf_link,
            opf_dir:action.opf_dir
        }
    }
    if(action.type === "update_color") {
        state.waverColor = action.waverColor;
        return {
            ...state,
            waverColor:action.waverColor
        }
    }
    
    if(action.type === "edit_line") {
        state.editLineText = action.editLineText;
        return {
            ...state,
            editLineText:action.editLineText
        }
    }
    if(action.type === "select_audio") {
        state.audioObj = action.audioObj;
        state.audioName = action.audioObj.name;
        return {
            ...state,
            audioObj:action.audioObj,
            audioName:action.audioName,
        }
    }
    if(action.type === "add_region") {
        state.startTime = action.startTime;
        return {
            ...state,
            startTime:state.startTime
        }
    }
    if(action.type === "point_selected") {
        state.spanIndx = action.spanIndx;
        return {
            ...state,
            spanIndx:state.spanIndx
        }
    }
    
    if(action.type === "update_point_index") {
        state.pointIndex = action.pointIndex;
        return {
            ...state,
            pointIndex:state.pointIndex
        }
    }
    if(action.type === "update_data") {
        state.book = action.book;
        return {
            ...state,
            book:state.book
        }
    }
    if(action.type === "update_audios") {
        state.audios = action.audios;
        return {
            ...state,
            audios:state.audios
        }
    }
    if(action.type === "update_slicing") {
        state.clearSlicing = action.clearSlicing;
        return {
            ...state,
            clearSlicing:state.clearSlicing
        }
    }
    if(action.type === "update_slicing1") {
        state.clearSlicing1 = action.clearSlicing1;
        return {
            ...state,
            clearSlicing1:state.clearSlicing1
        }
    }
    
    
    if(action.type === "load_page2") {
        state.htmlpage = action.htmlpage;
        state.page_count = action.page_count;
        state.selectid = action.selectid;
        return {
            ...state,
            htmlpage:state.htmlpage,
            page_count:state.page_count,
            selectid: state.selectid
        }
    }
    if(action.type === "load_page") {
        state.htmlpage = action.htmlpage;
        state.page_count = action.page_count;
        state.selectid = action.selectid;
        state.page_ids = action.page_ids;
        return {
            ...state,
            htmlpage:state.htmlpage,
            page_count: state.page_count,
            selectid: state.selectid,
            page_ids:state.page_ids
        }
    }
    if(action.type === "update_txt") {
        state.selectid = action.selectid;
        return {
            ...state,
            selectid:state.selectid
        }
    }
    if(action.type === "update_selection") {
        state.selectid = action.selectid;
        return {
            ...state,
            selectid: state.selectid
        }
    }
    if(action.type === "update_book_first") {
        state.book = action.book; 
        return {
            ...state,
            book: state.book
        }
    }
    if(action.type === "update_spanslist") {
        state.spansList = action.spansList; 
        return {
            ...state,
            spansList: state.spansList
        }
        
    }
    
    if(action.type === "update_book") {
        state.book = action.book; 
        state.cssLinks = action.cssLinks;
        state.spansList = action.spansList;
        state.pages2 = action.pages2;
        state.pageHeaders = action.pageHeaders;
        // state.pagesWidth = action.pagesWidth;
        return {
            ...state,
            book: state.book,
            cssLinks:state.cssLinks,
            spansList:state.spansList,
            pages2:state.pages2,
            pageHeaders:state.pageHeaders,
            // pagesWidth:state.pagesWidth
        }
    }

    if(action.type === "update_page_width") {

        state.pagesWidth = action.pagesWidth;
        state.classesList = action.classesList;
        return {
            ...state,
            pagesWidth:state.pagesWidth,
            classesList:state.classesList
        }
    }

    if(action.type === "update_book2") {
        state.book = action.book; 
        return {
            ...state,
            book: state.book
        }
    }

    if(action.type === "page_type") {
        state.pageType = action.pageType;
        state.book = action.book;
        return {
            ...state,
            pageType: state.pageType,
            book: state.book
        }
    }
    if(action.type === "update_page_type") {
        state.pageType = action.pageType;
        return {
            ...state,
            pageType: state.pageType
        }
    }
    if(action.type === "update_ids") {
        state.selectedIds = action.selectedIds;
        state.selectedIndex = action.selectedIndex;
        return {
            ...state,
            selectedIds: state.selectedIds,
            selectedIndex: state.selectedIndex
        }
    }
    if(action.type === "update_select") {
        state.selectedIndex = action.selectedIndex;
        return {
            ...state,
            selectedIndex: state.selectedIndex
        } 
    }
    if(action.type === "update_audios") {
        state.audios  = action.audios;
        return {
            ...state,
            audios: state.audios 
        }
    }
    return state;
}
const store = createStore(rootReducer,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
//const store = createStore(rootReducer);
 

 

var wavesurfer = "";  
class App extends React.Component {
    constructor() {
        super(); 
        this.state = {
            spines:[],
            manifests:[],
            pages:[],
            page_count:0,
            htmlpage:"",
            pagedims:"",
            bodyHeader:"",
            uploadAudio:"",
            audioDuration:0,
            pageAudio:[],
            selectid:"",
            saveDisabled:"disabled",
            startTime:0,
            selectedRegion:"",
            id2:"1",
            id:0,
            open:true,
            book:{
                bookId:'pea',
                title:'احمد وشجرة البازلاء',
                version:'',
                bookDirection:'rtl',
                pages:[
                    {   
                        pageSpread:'/Untitled-1-3.xhtml',
                        pageSpreadDirection:'right',
                        pageId:1,
                        pagePath:'/Untitled-1-2.xhtml',
                        voiceRecords:[],
                        notes:[],
                        drawings:[],
                        highlights:[],
                        pageAudioType:"oneAudio",// or paragraphs 
                        smilAudios:{
                            audioLink:'',
                            spans:[
                                {
                                    id:'elemet id',
                                    points:[
                                        {
                                            start:'0.0', 
                                            end:'0.600'
                                        },
                                        {
                                            start:'0.600',
                                            end:'1.200'
                                        },
                                        {
                                            start:'1.200',
                                            end:'1.800'
                                        },
                                        {
                                            start:'1.800',
                                            end:'2.600'
                                        }
                                    ]   
                                },
                            ]
                        }
                    }
                ]
            }
        };


        
    }
    handleClose() {
    }
    componentDidMount() {


        //let opf_link = localStorage.getItem("opf_path");
        /*let book = this.state.book;
        let th = this;
        let opf_link = localStorage.getItem("opf_path");

        axios.get(opf_link).then(function(res) {
            th.perpareFiles(res.data);
        });
        document.addEventListener('selectionchange', function(e) {
            th.selectText(e);
        });
*/
    }
    componentWillMount() {
    } 

    saveStory = (e) => {
        let audioDuration = this.state.audioDuration;
        let page_count = this.state.page_count;
        let page_ids = this.state.page_ids;
        let selectid= this.state.selectid;

        //console.log(page_ids[page_count]);
        //console.log(selectid);
        let doc = this.state.doc;
        let audioSrc = this.state.audio2;

        let current = page_ids[page_count];


        audioDuration = audioDuration.toPrecision(4); 

        //smil
        let smil = document.createElement("smil");
        smil.setAttribute("xmlns","http://www.w3.org/ns/SMIL");
        smil.setAttribute("xmlns:epub","http://www.idpf.org/2007/ops");
        smil.setAttribute("version","3.0");


        //body
        let body = document.createElement("body");

        //seq
        let seq = document.createElement("seq");
        seq.setAttribute("id","seq1");
        seq.setAttribute("epub:textref",current);
        seq.setAttribute("epub:type","bodymatter chapter");
        
        
        //par
        let par = document.createElement("par");
        par.setAttribute("id","1");

        let text = document.createElement("text");
        text.setAttribute("src",current + "#" + selectid);

        let audio = document.createElement("audio");
        audio.setAttribute("src",audioSrc);
        audio.setAttribute("clipBegin","0:00:00.000");
        audio.setAttribute("clipEnd","0:00:0" + audioDuration);
        

        par.append(text);
        par.append(audio);

        seq.append(par);
        body.append(seq);
        smil.append(body);
        

        let item = document.createElement("item");
        item.setAttribute("id",current + "-smil");
        item.setAttribute("href",current + ".smil");
        item.setAttribute("media-type","application/smil+xml");

        let main = doc.querySelector("manifest");
        main.appendChild(item);


        let bodyFormData = new FormData();

/*

        bodyFormData.set("base_path",window.location.pathname);
        bodyFormData.set("page_id",page_ids[page_count]);
        bodyFormData.set("smil",smil);
        bodyFormData.set("opf",doc);

        axios({
            method: 'post',
            url: 'http://35.159.51.71:4000/assign_audio',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(function (res) {

            }).catch(function (response) {

            });*/
    }


    assignAudioTxt = (e) => {
        let audioDuration = this.state.audioDuration;
        let page_count = this.state.page_count;
        let page_ids = this.state.page_ids;
        let selectid= this.state.selectid;

        //console.log(page_ids[page_count]);
        //console.log(selectid);
        let doc = this.state.doc;
        let audioSrc = this.state.audio2;

        let current = page_ids[page_count];


        audioDuration = audioDuration.toPrecision(4);

        //smil
        let smil = document.createElement("smil");
        smil.setAttribute("xmlns","http://www.w3.org/ns/SMIL");
        smil.setAttribute("xmlns:epub","http://www.idpf.org/2007/ops");
        smil.setAttribute("version","3.0");


        //body
        let body = document.createElement("body");

        //seq
        let seq = document.createElement("seq");
        seq.setAttribute("id","seq1");
        seq.setAttribute("epub:textref",current);
        seq.setAttribute("epub:type","bodymatter chapter");
        
        
        //par
        let par = document.createElement("par");
        par.setAttribute("id","1");

        let text = document.createElement("text");
        text.setAttribute("src",current + "#" + selectid);

        let audio = document.createElement("audio");
        audio.setAttribute("src",audioSrc);
        audio.setAttribute("clipBegin","0:00:00.000");
        audio.setAttribute("clipEnd","0:00:0" + audioDuration);
        

        par.append(text);
        par.append(audio);

        seq.append(par);
        body.append(seq);
        smil.append(body);
        

        let item = document.createElement("item");
        item.setAttribute("id",current + "-smil");
        item.setAttribute("href",current + ".smil");
        item.setAttribute("media-type","application/smil+xml");

        let main = doc.querySelector("manifest");
        main.appendChild(item);


        let bodyFormData = new FormData();

/*

        bodyFormData.set("base_path",window.location.pathname);
        bodyFormData.set("page_id",page_ids[page_count]);
        bodyFormData.set("smil",smil);
        bodyFormData.set("opf",doc);

        axios({
            method: 'post',
            url: 'http://35.159.51.71:4000/assign_audio',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(function (res) {

            }).catch(function (response) {

            });*/        
    }
    playAll = (e) => {
        
        this.setState({
            startTime:wavesurfer.getCurrentTime()
        });
        wavesurfer.play();
    }

    render() {
        var url_string = window.location.href;
        var url = new URL(url_string);
        let type = url.searchParams.get("type");
        let auto = url.searchParams.get("auto");
        
        let hostname = window.location.hostname;

        if(auto === "1") {
            return(
                <Provider store={store}>
                <div>
                <PageAudio2 id={this.state.id2} />
                <SpansTable />
                <StoryNew />             
                  
                </div></Provider>);
        }
        else if(type === "build_in_audio") {
            return(
                <Provider store={store}>
            <div>                
                <SpansTable />
                <Story2 />             
                  
                </div></Provider>);
        }
        else {
            return(
                <Provider store={store}>
            <div>
                
                <PageAudio id={this.state.id2} />
                <SpansTable />
                <Story />             
                  
                </div></Provider>);
        }

    }
} 
export default App;