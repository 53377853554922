import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import OrdersTableHead from './LogsTableHead';
import axios from "axios";
import OrdersStatus from './OrdersStatus';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Sync';
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
	margin: {
		margin: theme.spacing(1),
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
}));
function OrdersTable(props) {
	const dispatch = useDispatch();
	const searchText = useState('');

	const [selected, setSelected] = useState([]);
	const [data, setData] = useState([]);
	const [page, setPage] = useState(0);
	const [msgAlert, setMsgAlert] = useState('');
	const [openMsgAlert, setOpenMsgAlert] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [order, setOrder] = useState({
		direction: 'asc',
		id: null
	});

	useEffect(() => {
		loaddata()
	}, []);



	function loaddata() {
		let backend_url = process.env.REACT_APP_BACKEND_URL;
		let formData = new FormData();
		axios({
			url: `${backend_url}/logs/list`,
			data: formData,
			method: "post",
			headers: { "Content-Type": "application/json" },
		}).then((res) => {
			setData(res.data.all)
		});
	}

	function handleRequestSort(event, property) {
		const id = property;
		let direction = 'desc';

		if (order.id === property && order.direction === 'desc') {
			direction = 'asc';
		}

		setOrder({
			direction,
			id
		});
	}

	function handleSelectAllClick(event) {
		if (event.target.checked) {
			setSelected(data.map(n => n.id));
			return;
		}
		setSelected([]);
	}

	function handleClick(item) {
		let backend_url = process.env.REACT_APP_BACKEND_URL;
		let formData = new FormData();
		formData.append("id", item._id);
		formData.append("collection", item.deleteType);
		axios({
			url: `${backend_url}/logs/recovery`,
			data: formData,
			method: "post",
			headers: { "Content-Type": "multipart/form-data" },
		}).then((res) => {
			setMsgAlert(item._id + ' has been retrieved successfully!')
			setOpenMsgAlert(true);
			setTimeout(function () { 
				setOpenMsgAlert(false);
			}.bind(this), 2500);
			loaddata();
		});
	}

	function handleCheck(event, id) {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	}

	function handleChangePage(event, value) {
		setPage(value);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(event.target.value);
	}
	function prettyDate(date) {

		var d = new Date(date);

		return d.toDateString() + " " + d.toLocaleTimeString();
	}

	const classes = useStyles();

	return (
		<div className="w-full flex flex-col">
			{ openMsgAlert ? <Alert variant="filled" severity="success">
				{msgAlert}
			</Alert> : ''}
			<FuseScrollbars className="flex-grow overflow-x-auto">
				<Table className="min-w-xl" aria-labelledby="tableTitle">
					<OrdersTableHead
						numSelected={selected.length}
						order={'order'}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
						rowCount={data.length}
					/>

					<TableBody>
						{_.orderBy(
							data,
							[
								o => {
									switch (data.id) {
										case '_id': {
											return parseInt(o._id, 10);
										}
										case 'titleEn': {
											return o.titleEn;
										}
										case 'titleAr': {
											return o.titleAr;
										}
										case 'deleteType': {
											return o.deleteType;
										}
										case 'deleteEmail': {
											return o.deleteEmail;
										}
										default: {
											return o[order.id];
										}
									}
								}
							],
							[data._id]
						)
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map(n => {

								const isSelected = selected.indexOf(n.id) !== -1;
								return (
									<TableRow
										className="h-64 cursor-pointer"
										hover
										role="checkbox"
										aria-checked={isSelected}
										tabIndex={-1}
										key={n.id}
										selected={isSelected}
									>


										<TableCell component="th" scope="row">
											{n._id}
										</TableCell>

										<TableCell component="th" scope="row">
											{n.titleEn}
										</TableCell>

										<TableCell className="truncate" component="th" scope="row">
											{n.titleAr}
										</TableCell>

										<TableCell component="th" scope="row"  >
											<OrdersStatus name={n.deleteType} />

										</TableCell>

										<TableCell component="th" scope="row">
											{n.deleteby} - {n.deleteEmail}
										</TableCell>

										<TableCell component="th" scope="row">
											{prettyDate(n.deleteDate)}
										</TableCell>

										<TableCell component="th" scope="row">
											<IconButton aria-label="delete" className={classes.margin} onClick={() => handleClick(n)}>
												<DeleteIcon fontSize="large" />
											</IconButton>
										</TableCell>
									</TableRow>
								);
							}
							)

						}
					</TableBody>
				</Table>
			</FuseScrollbars>

			<TablePagination
				className="overflow-hidden"
				component="div"
				count={data.length}
				rowsPerPage={rowsPerPage}
				page={page}
				backIconButtonProps={{
					'aria-label': 'Previous Page'
				}}
				nextIconButtonProps={{
					'aria-label': 'Next Page'
				}}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
		</div>
	);
}

export default withRouter(OrdersTable);
