import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import Button from "@material-ui/core/Button";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

import Paper from "@material-ui/core/Paper";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";
import { Link } from "react-router-dom";
import Popup from "../components/popup";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "Age Rate",
    link: "",
  },
];
class List extends Component {
  state = {
    open: false,
    formValid: false,
    rowsPerPage: 10,
    page: 0,
    data: [],
    categoryData: [],
    idremove: '0'
  };

  handleSubmit = (model) => {};
  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };
  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };
  handleChangeRowsPerPage = () => {};
  sortData = (name) => {
    let data = this.state.data;

    data = data.sort(function (a, b) {
      if (a[name] < b[name]) {
        return -1;
      }
      if (a[name] > b[name]) {
        return 1;
      }
      return 0;
    });

    this.setState({
      data: data,
    });
  };
  componentDidMount() {
    let backend_url = process.env.REACT_APP_BACKEND_URL;

    axios({
      url: `${backend_url}/agerate/list`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        categoryData: res.data.result,
      });
    });
  }

  handleDelete = () => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData(); 
    formData.append("id", this.state.idremove);
    axios({
      url: `${backend_url}/agerate/delete`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.componentDidMount();
      this.setState({
        open: false,
        page:0
      });
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="w-full flex items-center justify-between p-24">
            <h2>Age Rate</h2>
            <div className="flex items-center">
              <Link to={`${process.env.REACT_APP_FRONT_URL}age_rate/add`}>
                <Button color="primary" variant="contained">
                  Add
                </Button>
              </Link>
            </div>
          </div>
        }
        content={
          <div className="p-24">
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell onClick={() => this.sortData("name")}>
                    Age Rate
                    </TableCell> 
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.categoryData
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((row) => (
                      <TableRow key={row._id}>
                        <TableCell component="th" scope="row">
                          {row.titleEn} 
                        </TableCell> 
                        <TableCell
                          align="center"
                        >
                          <Link
                            to={`${process.env.REACT_APP_FRONT_URL}age_rate/${row._id}/edit`}
                          >
                            <IconButton
                              aria-label="delete"
                              className={classes.margin}
                            >
                              <EditIcon fontSize="large" />
                            </IconButton>
                          </Link>
                          <IconButton
                            aria-label="delete"
                            className={classes.margin}
                            onClick={() => {
                              this.setState({
                                open: true,
                                idremove: row._id
                              });
                            }}
                          >
                            <DeleteIcon fontSize="large" />
                          </IconButton>
                          <Dialog
                            open={this.state.open}
                            keepMounted
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                          >
                            <DialogTitle id="alert-dialog-slide-title">
                              <DeleteIcon fontSize="large" />{" "}
                              {" Are you sure you want to delete it?"}
                            </DialogTitle>
                            <DialogActions>
                              <Button
                                onClick={() => {
                                  this.setState({
                                    open: false,
                                  });
                                }}
                                color="primary"
                              >
                                No
                              </Button>
                              <Button
                                color="primary"
                                onClick={() => {
                                  this.handleDelete()
                                }}
                              >
                                OK
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={this.state.categoryData.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                backIconButtonProps={{
                  "aria-label": "previous page",
                }}
                nextIconButtonProps={{
                  "aria-label": "next page",
                }}
                onChangePage={this.handleChangePage}
              />
            </TableContainer>

            <Popup />
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(List);
