import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React from 'react';

function WidgetWeather(props) {
	return (
		<Paper className="w-full rounded-8 shadow-none border-1">
			<div className="flex items-center justify-between px-4 pt-4">
				<div className="flex items-center px-12">
					<Typography className="h1 font-500">
					{props.count.title}
					</Typography>
				</div>
				<IconButton aria-label="more">
					{/* <Icon>more_vert</Icon> */}
				</IconButton>
			</div>

			<Divider />
			<div className="w-full py-16"  style={{
				 width: '100%', 
				 position: 'relative',
				 overflow: 'auto',
				 maxHeight: 400,
			}}>
				{props.count.counter.map(c => 
				<div className="flex items-center justify-between w-full py-16 px-24" key={c.label} >
					<Typography className="text-15">{c.label.toUpperCase()}</Typography>
					<div className="flex items-center">

						<Typography className="text-20" color="textSecondary">
							{c.counts}
						</Typography>
					</div>
				</div>
				)}
				
				{/* <div className="flex items-center justify-between w-full py-16 px-24"  >
					<Typography className="text-15">Songs</Typography>
					<div className="flex items-center">

						<Typography className="text-20" color="textSecondary">
							{props.count.songs}
						</Typography>
					</div>
				</div>
				<div className="flex items-center justify-between w-full py-16 px-24"  >
					<Typography className="text-15">Songs</Typography>
					<div className="flex items-center">

						<Typography className="text-20" color="textSecondary">
							{props.count.songs}
						</Typography>
					</div>
				</div> */}
			</div>
		</Paper>
	);
}

export default React.memo(WidgetWeather);
