import React,{ useState , useEffect } from 'react';
import {Redirect} from 'react-router-dom';
import Select from 'react-select';
import { ReactSortable } from "react-sortablejs";
//import * as userActions from 'app/auth/store/actions';
import * as Actions from 'app/store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    CheckboxFormsy,
    FuseChipSelectFormsy,
    RadioGroupFormsy,
    SelectFormsy,
    TextFieldFormsy
} from '@fuse/core/formsy';
import Formsy from 'formsy-react';
import Button from '@material-ui/core/Button';
import FusePageCarded from '@fuse/core/FusePageCarded';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import Radio from '@material-ui/core/Radio';
import DeleteIcon from '@material-ui/icons/Delete';
import Breadcrumb from './../../helper/Breadcrumb';
import StyledDropzone from './../../helper/StyledDropzone'; 
import AudioField from './../../helper/AudioField'; 
import Popup from './../../helper/Popup'; 
import CircularProgress from '@material-ui/core/CircularProgress';
import DragHandleIcon from '@material-ui/icons/DragHandle';

const breadCurmbData = [
    {
        title:"Categories",
        link:"/categories/list"
    }
];


const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };


  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };


function QuizForm(props) {

    const [data,setData] = useState([]);

    useEffect(() => {
        let bodyFormData = new FormData();
        bodyFormData.set("cat_id",props.match.params.id); 



        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/book/app/list2`,
            data: bodyFormData,

            config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(res=> {
                setData(res.data);
                
            });

        
      
    }, [props]);

    const saveData = (e) => {

        let ids = [];

        data.forEach(function(item) {
            ids.push(item._id);
        });

        let bodyFormData = new FormData();
        bodyFormData.set("cat_id",props.match.params.id); 
        bodyFormData.set("ids",JSON.stringify(ids)); 

        axios({
            method: 'post',
            url:  `${process.env.REACT_APP_BACKEND_URL}/book/order_save`,
            data: bodyFormData,

            config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(res=> {
                props.showMessage({ message: 'Book order save' });

            });
    }

  

    const handleSubmit = (e) => {
        // let quizData = [...questionList];

        // let bookId = props.match.params.id;

        // let data  = {quizData,bookId,counter}

        // setPopupBody(<CircularProgress />);
        // setPopup(true);
    

        // axios({
		// 	method: 'post',
		// 	url: `${process.env.REACT_APP_BACKEND_URL}/book/quiz/update`,
		// 	data: data,
		// 	headers:{
		// 		"content-type":"application/json"
		// 	}
		//   }).then((res) => {

        //     setPopupBody("Book data saved successfully");

        //     setTimeout(() => {
        //         setPopup(false);
        //         setRedirect(true);
        //     }, 5000);

        //   });
    }
    return(
    
    <FusePageCarded
    classes={{
        root: ""
    }}
    header={
        <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
        </div>
    }
    contentToolbar={
        <div className="px-24 flex justify-between items-center w-full flex-wrap">
            <h4>Book quiz</h4>
            <div>
                <Button onClick={saveData} color="primary" variant="contained">Save</Button>
            </div>
        </div>
    }
    content={<div
        //ref="form"
        //onError={errors => console.log(errors)}
        className="flex flex-wrap w-full p-24">
            <ReactSortable
            group="groupName"
            animation={200}
            sort={true}
            className={"w-full flex-wrap"}
            delayOnTouchStart={true}
            delay={2}
            list={data}
            setList={setData}
            >
            {data.map((item,i) => {
                
                let style = {borderBottom:"1px solid #192D3E",borderTop:"1px solid #192D3E",padding:10,cursor:"pointer",width:"25%",float:"left",height:150};
                if(i === 0) {
                    style = {borderBottom:"1px solid #192D3E",borderTop:"1px solid #192D3E",padding:10,cursor:"pointer",width:"25%",float:"left",height:150};
                }
                return(<div className="flex items-center" style={style}>
                    <div style={{marginRight:10}}><DragHandleIcon /></div>
                    <div><img style={{width:100,marginRight:10}} src={"https://school.kutubee.com:4000/" + item.coverPhoto} /></div>
                    <div>{item.name}</div>
                </div>)
            })}
            </ReactSortable>
            
            
    </div>}
    />
    );
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			showMessage: Actions.showMessage,
			hideMessage: Actions.hideMessage
		},
		dispatch
	);
}
export default connect(null, mapDispatchToProps)(QuizForm);

//export default QuizForm;