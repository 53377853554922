import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded, FuseAnimateGroup } from "@fuse";
import Button from "@material-ui/core/Button";

import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Icon from '@material-ui/core/Icon';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

import Paper from "@material-ui/core/Paper";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";
import { Link } from "react-router-dom";
import Popup from "../components/popup";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FormatListNumbered from "@material-ui/icons/FormatListNumbered";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Avatar from "@material-ui/core/Avatar";
const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "Games",
    link: "",
  },
];
class List extends Component {
  state = {
    open: false,
    formValid: false,
    rowsPerPage: 15,
    page: 0,
    data: [],
    categoryData: [],
    idremove: "0",
  };

  handleSubmit = (model) => { };
  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };
  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };
  handleChangeRowsPerPage = () => { };
  sortData = (name) => {
    let data = this.state.data;

    data = data.sort(function (a, b) {
      if (a[name] < b[name]) {
        return -1;
      }
      if (a[name] > b[name]) {
        return 1;
      }
      return 0;
    });

    this.setState({
      data: data,
    });
  };
  componentDidMount() {
    let backend_url = process.env.REACT_APP_BACKEND_URL;

    axios({
      url: `${backend_url}/games/list`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        categoryData: res.data.result,
      });
    });
  }

  handleDelete = () => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    formData.append("id", this.state.idremove);
    axios({
      url: `${backend_url}/singer/delete`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.componentDidMount();
      this.setState({
        open: false,
      });
    });
  };


  hideGame = (id, Status) => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    formData.append("id", id);
    let st = Status == 'true' ? false : true;
    formData.append("Status", st);
    axios({
      url: `${backend_url}/games/hide`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      // console.log(res)
      this.componentDidMount();

    });
  };


  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="w-full flex items-center justify-between p-24">
            <h2>Games</h2>
            <div className="flex items-center">
              {/* <Link to={`${process.env.REACT_APP_FRONT_URL}games/add`}>
                <Fab color="primary" aria-label="add">
                  <AddIcon />
                </Fab>
              </Link> */}
            </div>
          </div>
        }
        content={
          <div className="p-24">
            <FuseAnimateGroup
              className="flex flex-wrap"
              enter={{
                animation: "transition.slideUpBigIn",
              }}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>

                      <TableCell align="left">Game Name EN</TableCell>
                      <TableCell align="left">Game Name AR</TableCell>
                      <TableCell align="left">Image</TableCell>
                      <TableCell align="left">Show/Hide</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.categoryData
                      .slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                      )
                      .map((row) => (
                        <TableRow key={row._id}>
                          <TableCell align="left">{row.nameEn}</TableCell>
                          <TableCell align="left">{row.nameAr}</TableCell>
                          <TableCell align="left">
                            <img
                              style={
                                {
                                  width: '5vw',
                                  height: '5vw'
                                }
                              }
                              src={
                                process.env.REACT_APP_BACKEND_URL +
                                "/public/" +
                                row.gamesImg
                              } />

                          </TableCell>
                          <TableCell>
                            <IconButton
                              aria-label="VisibilityOff"
                              className={classes.margin}
                              onClick={() => {
                                this.hideGame(row._id, row.Status)
                              }}
                            >
                              {
                                row.Status == 'true' ? <Visibility fontSize="medium" /> : <VisibilityOff fontSize="medium" />
                              }
                            </IconButton>
                          </TableCell>
                          <TableCell align="left">



                            {row._id == 'game_3' && (
                              <div>
                                <Link
                                  to={`${process.env.REACT_APP_FRONT_URL}games/${row._id}/edit`}
                                >
                                  <IconButton
                                    aria-label="delete"
                                    className={classes.margin}
                                  >
                                    <EditIcon fontSize="large" />
                                  </IconButton>
                                </Link>

                                <Link
                                  to={`${process.env.REACT_APP_FRONT_URL}games/assets`}
                                >
                                  <IconButton
                                    aria-label="delete"
                                    className={classes.margin}
                                  >
                                    <FormatListNumbered fontSize="large" />
                                  </IconButton>
                                </Link>

                                <Link
                                  to={`${process.env.REACT_APP_FRONT_URL}games/levelMemory`}
                                >
                                  <IconButton
                                    aria-label=""
                                    className={classes.margin}
                                  >
                                    <Icon fontSize="large">art_track</Icon>
                                  </IconButton>
                                </Link>


                                <Dialog
                                  open={this.state.open}
                                  keepMounted
                                  aria-labelledby="alert-dialog-slide-title"
                                  aria-describedby="alert-dialog-slide-description"
                                >
                                  <DialogTitle id="alert-dialog-slide-title">
                                    <DeleteIcon fontSize="large" />{" "}
                                    {" Are you sure you want to delete it?"}
                                  </DialogTitle>
                                  <DialogActions>
                                    <Button
                                      onClick={() => {
                                        this.setState({
                                          open: false,
                                        });
                                      }}
                                      color="primary"
                                    >
                                      No
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        this.handleDelete();
                                      }}
                                    >
                                      OK
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            )}


                            {row._id == 'game_4' && (
                              <div>
                                <Link
                                  to={`${process.env.REACT_APP_FRONT_URL}games/${row._id}/edit`}
                                >
                                  <IconButton
                                    aria-label="delete"
                                    className={classes.margin}
                                  >
                                    <EditIcon fontSize="large" />
                                  </IconButton>
                                </Link>


                                <Link
                                  to={`${process.env.REACT_APP_FRONT_URL}games/settingswhackamole`}
                                >
                                  <IconButton
                                    aria-label=""
                                    className={classes.margin}
                                  >
                                    <Icon fontSize="large">art_track</Icon>
                                  </IconButton>
                                </Link>
                              </div>
                            )}

                            {
                              row._id == 'game_2' && (
                                <div>
                                  <Link
                                    to={`${process.env.REACT_APP_FRONT_URL}games/${row._id}/edit`}
                                  >
                                    <IconButton
                                      aria-label="delete"
                                      className={classes.margin}
                                    >
                                      <EditIcon fontSize="large" />
                                    </IconButton>
                                  </Link>


                                  <Link
                                    to={`${process.env.REACT_APP_FRONT_URL}games/Missing_letters/list`}
                                  >
                                    <IconButton
                                      aria-label=""
                                      className={classes.margin}
                                    >
                                      <Icon fontSize="large">art_track</Icon>
                                    </IconButton>
                                  </Link>
                                </div>
                              )
                            }


                            {row._id == 'game_5' && (
                              <div>
                                <Link
                                  to={`${process.env.REACT_APP_FRONT_URL}games/${row._id}/edit`}
                                >
                                  <IconButton
                                    aria-label="delete"
                                    className={classes.margin}
                                  >
                                    <EditIcon fontSize="large" />
                                  </IconButton>
                                </Link>


                                <Link
                                  to={`${process.env.REACT_APP_FRONT_URL}games/levelPuzzle`}
                                >
                                  <IconButton
                                    aria-label=""
                                    className={classes.margin}
                                  >
                                    <Icon fontSize="large">art_track</Icon>
                                  </IconButton>
                                </Link>
                              </div>
                            )}
                            {row._id == 'game_7' && (
                              <div>
                                <Link
                                  to={`${process.env.REACT_APP_FRONT_URL}games/${row._id}/edit`}
                                >
                                  <IconButton
                                    aria-label="delete"
                                    className={classes.margin}
                                  >
                                    <EditIcon fontSize="large" />
                                  </IconButton>
                                </Link>


                                <Link
                                  to={`${process.env.REACT_APP_FRONT_URL}games/levelcoloring/list`}
                                >
                                  <IconButton
                                    aria-label=""
                                    className={classes.margin}
                                  >
                                    <Icon fontSize="large">art_track</Icon>
                                  </IconButton>
                                </Link>
                              </div>
                            )}
                            {row._id == 'game_6' && (
                              <div>
                                <Link
                                  to={`${process.env.REACT_APP_FRONT_URL}games/${row._id}/edit`}
                                >
                                  <IconButton
                                    aria-label="delete"
                                    className={classes.margin}
                                  >
                                    <EditIcon fontSize="large" />
                                  </IconButton>
                                </Link>


                                <Link
                                  to={`${process.env.REACT_APP_FRONT_URL}games/levelDiffs`}
                                >
                                  <IconButton
                                    aria-label=""
                                    className={classes.margin}
                                  >
                                    <Icon fontSize="large">art_track</Icon>
                                  </IconButton>
                                </Link>
                              </div>
                            )}


                            {row._id == 'game_8' && (
                              <div>
                                <Link
                                  to={`${process.env.REACT_APP_FRONT_URL}games/${row._id}/edit`}
                                >
                                  <IconButton
                                    aria-label="delete"
                                    className={classes.margin}
                                  >
                                    <EditIcon fontSize="large" />
                                  </IconButton>
                                </Link>


                                <Link
                                  to={`${process.env.REACT_APP_FRONT_URL}games/jigsaw/list`}
                                >
                                  <IconButton
                                    aria-label=""
                                    className={classes.margin}
                                  >
                                    <Icon fontSize="large">art_track</Icon>
                                  </IconButton>
                                </Link>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  component="div"
                  count={this.state.categoryData.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={this.handleChangePage}
                />
              </TableContainer>
            </FuseAnimateGroup>
            <Popup />
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(List);
