import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import Formsy from "formsy-react";
import Breadcrumb from "./../components/Breadcrumb";
import axios from "axios";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Icon from '@material-ui/core/Icon';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/Delete';
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link, useParams } from 'react-router-dom';
import { hr } from "date-fns/locale";


import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from '@material-ui/core/Switch';


const styles = (theme) => ({
    layoutRoot: {},
});


class Add extends Component {
    state = {
        formValid: false,
        titleEn: "",
        titleAr: "",
        tabIndex: 0,
        status: false

    };
    handleChange = (e, name) => {
        this.setState({
            [name]: e.target.value,
        });
        if (name == 'typeContent') {
            let backend_url = process.env.REACT_APP_BACKEND_URL;
            axios({
                url: `${backend_url}/${e.target.value}/list`,
                method: "post",
                headers: { "Content-Type": "application/json" },
            }).then((res) => {
                this.setState({
                    songsData2: res.data.result,
                    setSongsData2: []
                });
            });
        }
        //////

        if (name == 'Quantity') {
            if (e.target.value < 0) {
                this.setState({
                    [name]: 1,
                });
            }
        }
    };

    enableButton = () => {
        this.setState({
            formValid: true,
        });
    };

    disableButton = () => {
        this.setState({
            formValid: false,
        });
    };


    addtoarry = (value, name, name2) => {
        // console.log(value._id)
        let sta = this.state;
        ///delete form old
        this.setState({
            [name2]: sta[name2].filter(item => item._id != value._id)
        })
        ///add form old
        this.setState({
            [name]: sta[name].concat([value])
        })
    }

    backAddItem = (val) => {
        let sta = this.state;
        ///add form 
        let name = 'setSongsData2';
        let name2 = 'songsData2';
        let obj = val;

        this.setState({
            [name2]: sta[name2].concat([obj])
        })

        this.setState({
            [name]: sta[name].filter(item => item._id != obj._id),
            openpopupDelete: false,
        })
    }
    handleChangetags = (val, name) => {
        let ids = val.map((item) => {
            return item._id
        })
        this.setState({
            [name]: ids,
        });

    };

    submitAdd = () => {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData = new FormData();

        formData.append("id", this.props.match.params.id);
        formData.append("titleEn", this.state.titleEn);
        formData.append("titleAr", this.state.titleAr);
       

        axios({
            url: `${backend_url}/mainScreen/etittitle`,
            data: formData,
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            this.props.history.push('/frontend/appsettings/rowsapp');
        });
    }

    componentDidMount() {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        axios({
            url: `${backend_url}/category/list`,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            this.setState({
                tagsData: res.data.result,
            });
        });
        ///

        let id = this.props.match.params.id;

        let data = {
            id: id,
        };
        axios({
            url: `${backend_url}/mainScreen/get`,
            data: data,
            method: "post",
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            this.setState(
                {
                    titleEn: res.data.result.titleEn,
                    titleAr: res.data.result.titleAr
                }
            );
        });


    }
    render() {
        const { classes } = this.props;
        return (
            <FusePageCarded
                classes={{
                    root: classes.layoutRoot,
                }}
                header={
                    <div className="flex flex-1 w-full items-center justify-between">
                        <div className="flex flex-col items-start max-w-full">
                            <Typography
                                className="normal-case flex items-center sm:mb-12"
                                component={Link}
                                role="button"
                                to="/frontend/appsettings/rowsapp"
                                color="inherit"
                            >
                                <Icon className="text-20">
                                    {'ltr' === 'ltr' ? 'arrow_back' : 'arrow_forward'}
                                </Icon>
                                <span className="mx-4">App Section Content</span>
                            </Typography>

                            <div className="flex items-center max-w-full">

                                <div className="flex flex-col min-w-0 mx-8 sm:mc-16">
                                    <Typography className="text-16 sm:text-20 truncate">
                                        Etit title

										</Typography>
                                </div>
                            </div>
                        </div>
                        <Button
                            className="whitespace-no-wrap normal-case"
                            variant="contained"
                            color="secondary"
                            disabled={!this.state.formValid}
                            onClick={() => {
                                this.submitAdd();
                            }}
                        >
                            Edit
							</Button>
                    </div>
                }
                contentToolbar={
                    <Tabs
                        value={this.state.tabIndex}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        classes={{ root: 'w-full h-64' }}
                    >
                        <Tab className="h-64 normal-case" label="Basic Info" onClick={() => {
                            this.setState({
                                tabIndex: 0
                            })
                        }} />


                    </Tabs>
                }
                content={


                    <div className="p-16 sm:p-24 max-w-2xl">
                        {this.state.tabIndex === 0 && (
                            <div>
                                <Formsy
                                    onValidSubmit={this.handleSubmit}
                                    onValid={this.enableButton}
                                    onInvalid={this.disableButton}
                                    ref={"cateogry_add"}
                                    className="flex flex-col p6-12"
                                >
                                    <div className="w-full" >
                                        <FormControlLabel
                                            control={<Switch checked={this.state.status}

                                                color="primary"
                                                onChange={(e) => {
                                                    this.setState({
                                                        status: e.target.checked,
                                                    });
                                                }}
                                                name="status" />}
                                            label={this.state.status ? 'Enable' : 'Disable'}
                                            labelPlacement="left"

                                        />

                                        <Divider />
                                    </div>
                                    <div className="flex flex-row justify-between py-20">



                                        <TextFieldFormsy
                                            className="w-5/12"
                                            type="text"
                                            name="nameEn"
                                            label="Title EN"
                                            onChange={(e) => {
                                                this.handleChange(e, "titleEn");
                                            }}
                                            value={this.state.titleEn}
                                            variant="outlined"
                                            required
                                        />
                                        <TextFieldFormsy
                                            className="w-5/12"
                                            type="text"
                                            name="nameAr"
                                            label="Title AR"
                                            required
                                            variant="outlined"

                                            value={this.state.titleAr}
                                            onChange={(e) => {
                                                this.handleChange(e, "titleAr");
                                            }}
                                        />

                                    </div>


                                </Formsy>
                            </div>
                        )}



                    </div>



                }
            />
        );
    }
}

export default withStyles(styles, { withTheme: true })(Add);
