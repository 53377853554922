import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import { MenuItem, Button, Radio } from "@material-ui/core";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import Formsy from "formsy-react";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";
import clsx from 'clsx';
import Icon from '@material-ui/core/Icon';

import Backdrop from "@material-ui/core/Backdrop";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SaveIcon from "@material-ui/icons/Save";
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
const styles = (theme) => ({
  layoutRoot: {},
});

const breadCurmbData = [
  {
    title: "Games",
    link: `${process.env.REACT_APP_FRONT_URL}games/list`,
  },
  {
    title: "Levels",
    link: `${process.env.REACT_APP_FRONT_URL}games/levelPuzzle/`,
  },
  {
    title: "Add",
    link: "",
  },
];

class Add extends Component {
  state = {
    formValid: false,
    disabled: false,
    contentNumber: 9,
    images0: '',
    images1: '',
    images2: '',
    images3: '',
    images4: '',
    images5: '',
    images6: '',
    images7: '',
    images8: '',
    titleEn: '',
    titleAr: '',
    lengthOfFiles: 0,
    flagbooimage: false,
    progressshow: false,

    thumbnail: '',

  };
  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
  };


  handleSubmit = (model) => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();


    let go = true;
    if (this.state.images0 == '' || this.state.images1 == '' || this.state.images2 == '' || this.state.images3 == '' || this.state.images4 == '' || this.state.images5 == '' || this.state.images6 == '' || this.state.images7 == '' || this.state.images8 == '') {
      go = false;
    }

    if (go) {
      this.setState({
        progressshow: true,
        flagbooimage: false,
      })
      formData.append("titleEn", this.state.titleEn);
      formData.append("titleAr", this.state.titleAr);
      formData.append("images0", this.state.images0);
      formData.append("images1", this.state.images1);
      formData.append("images2", this.state.images2);
      formData.append("images3", this.state.images3);
      formData.append("images4", this.state.images4);
      formData.append("images5", this.state.images5);
      formData.append("images6", this.state.images6);
      formData.append("images7", this.state.images7);
      formData.append("images8", this.state.images8);
      formData.append("thumbnail", this.state.thumbnail);
      formData.append("lengthOfFiles", 9);

      axios({
        url: `${backend_url}/games/levelPuzzle/add`,
        data: formData,
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        if (res.data.message) {
          this.setState({
            progressshow: false,
          })
          this.props.history.push("/games/levelPuzzle");
        }

      });
    }
    else {
      this.setState({
        flagbooimage: true,
      })
    }

  };


  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };

  fileUpload2 = (e, id) => {
    let image = document.getElementById(id);
    image.src = URL.createObjectURL(e.target.files[0]);


    this.setState({
      [id]: e.target.files[0]
    })

  };


  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="px-24">
            <h2>Add Level</h2>
          </div>
        }
        content={
          <div className="p-24">
            <div className="max-w-lg">
              <Formsy
                onValidSubmit={this.handleSubmit}
                onValid={this.enableButton}
                onInvalid={this.disableButton}
                ref={"cateogry_add"}
                className="flex flex-col p6-12"
              >

                <div className="flex flex-row justify-between py-20">
                  <TextFieldFormsy
                    className="w-5/12"
                    type="text"
                    name="titleEn"
                    label="Title EN"
                    onChange={(e) => {
                      this.handleChange(e, "titleEn");
                    }}
                    required
                    variant="outlined"
                  />
                  <TextFieldFormsy
                    className="w-5/12"
                    type="text"
                    name="titleAr"
                    label="Title AR"
                    required
                    variant="outlined"
                    onChange={(e) => {
                      this.handleChange(e, "titleAr");
                    }}
                  />
                </div>
                <div className="flex flex-row justify-between py-20">
                  <div className="flex justify-between items-center w-5/12">
                    <Button variant="contained" component="label">
                      upload thumbnail
                      <input
                        accept="image/png"
                        type="file"
                        required
                        onChange={(e) => {
                          this.fileUpload2(e, "thumbnail");
                        }}
                        style={{ display: "none" }}
                      />
                    </Button>
                    <img
                      alt=""
                      id="thumbnail"
                      title="thumbnail"
                      style={{ maxWidth: "40%" }}
                      src="frontend/assets/images/custom/empty-image-thumb.png"
                    />
                  </div>
                </div>
                {/* <div className="flex flex-row justify-between py-20">

                  <SelectFormsy
                    className="w-5/12"
                    name="number"
                    label="Row × Column"
                    value={this.state.contentNumber}
                    onChange={(e) => {
                      this.handleChange(e, "contentNumber");
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="4">2×2 </MenuItem>
                    <MenuItem value="9">3×3 </MenuItem>

                  </SelectFormsy>
                </div> */}



                {this.state.contentNumber == 9 && (
                  <div>
                    {this.state.flagbooimage && (
                      <Alert variant="filled" severity="error">
                        9 images are required
                      </Alert>
                    )}
                    <div className="flex flex-row py-20">
                      <div
                        role="button"
                        tabIndex={0}
                        className={clsx(
                          classes.productImageItem,
                          'flex row items-center justify-center relative w-128 h-128 rounded-4 mx-8 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5',
                          'featured'
                        )}
                      >
                        <input accept="image/*" style={{ display: 'none' }}

                          onChange={(e) => {
                            this.fileUpload2(e, "images0");
                          }}
                          id="icon-button-file0" type="file" />
                        <label htmlFor="icon-button-file0">
                          <IconButton color="primary" aria-label="upload picture" component="span">
                            <img className="max-w-none w-auto " id="images0" src="frontend/assets/images/custom/empty-image-thumb.png" />
                          </IconButton>
                        </label>
                      </div>


                      <div
                        role="button"
                        tabIndex={0}
                        className={clsx(
                          classes.productImageItem,
                          'flex row items-center justify-center relative w-128 h-128 rounded-4 mx-8 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5',
                          'featured'
                        )}
                      >
                        <input accept="image/*" style={{ display: 'none' }}

                          onChange={(e) => {
                            this.fileUpload2(e, "images1");
                          }}
                          id="icon-button-file1" type="file" />
                        <label htmlFor="icon-button-file1">
                          <IconButton color="primary" aria-label="upload picture" component="span">
                            <img className="max-w-none w-auto " id="images1" src="frontend/assets/images/custom/empty-image-thumb.png" />
                          </IconButton>
                        </label>
                      </div>

                      <div
                        role="button"
                        tabIndex={0}
                        className={clsx(
                          classes.productImageItem,
                          'flex row items-center justify-center relative w-128 h-128 rounded-4 mx-8 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5',
                          'featured'
                        )}
                      >
                        <input accept="image/*" style={{ display: 'none' }}

                          onChange={(e) => {
                            this.fileUpload2(e, "images2");
                          }}
                          id="icon-button-file2" type="file" />
                        <label htmlFor="icon-button-file2">
                          <IconButton color="primary" aria-label="upload picture" component="span">
                            <img className="max-w-none w-auto" id="images2" src="frontend/assets/images/custom/empty-image-thumb.png" />
                          </IconButton>
                        </label>
                      </div>
                    </div>

                    <div className="flex flex-row py-20">
                      <div
                        role="button"
                        tabIndex={0}
                        className={clsx(
                          classes.productImageItem,
                          'flex row items-center justify-center relative w-128 h-128 rounded-4 mx-8 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5',
                          'featured'
                        )}
                      >
                        <input accept="image/*" style={{ display: 'none' }}

                          onChange={(e) => {
                            this.fileUpload2(e, "images3");
                          }}
                          id="icon-button-file3" type="file" />
                        <label htmlFor="icon-button-file3">
                          <IconButton color="primary" aria-label="upload picture" component="span">
                            <img className="max-w-none w-auto " id="images3" src="frontend/assets/images/custom/empty-image-thumb.png" />
                          </IconButton>
                        </label>
                      </div>


                      <div
                        role="button"
                        tabIndex={0}
                        className={clsx(
                          classes.productImageItem,
                          'flex row items-center justify-center relative w-128 h-128 rounded-4 mx-8 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5',
                          'featured'
                        )}
                      >
                        <input accept="image/*" style={{ display: 'none' }}

                          onChange={(e) => {
                            this.fileUpload2(e, "images4");
                          }}
                          id="icon-button-file4" type="file" />
                        <label htmlFor="icon-button-file4">
                          <IconButton color="primary" aria-label="upload picture" component="span">
                            <img className="max-w-none w-auto " id="images4" src="frontend/assets/images/custom/empty-image-thumb.png" />
                          </IconButton>
                        </label>
                      </div>

                      <div
                        role="button"
                        tabIndex={0}
                        className={clsx(
                          classes.productImageItem,
                          'flex row items-center justify-center relative w-128 h-128 rounded-4 mx-8 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5',
                          'featured'
                        )}
                      >
                        <input accept="image/*" style={{ display: 'none' }}

                          onChange={(e) => {
                            this.fileUpload2(e, "images5");
                          }}
                          id="icon-button-file5" type="file" />
                        <label htmlFor="icon-button-file5">
                          <IconButton color="primary" aria-label="upload picture" component="span">
                            <img className="max-w-none w-auto" id="images5" src="frontend/assets/images/custom/empty-image-thumb.png" />
                          </IconButton>
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-row py-20">
                      <div
                        role="button"
                        tabIndex={0}
                        className={clsx(
                          classes.productImageItem,
                          'flex row items-center justify-center relative w-128 h-128 rounded-4 mx-8 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5',
                          'featured'
                        )}
                      >
                        <input accept="image/*" style={{ display: 'none' }}

                          onChange={(e) => {
                            this.fileUpload2(e, "images6");
                          }}
                          id="icon-button-file6" type="file" />
                        <label htmlFor="icon-button-file6">
                          <IconButton color="primary" aria-label="upload picture" component="span">
                            <img className="max-w-none w-auto " id="images6" src="frontend/assets/images/custom/empty-image-thumb.png" />
                          </IconButton>
                        </label>
                      </div>


                      <div
                        role="button"
                        tabIndex={0}
                        className={clsx(
                          classes.productImageItem,
                          'flex row items-center justify-center relative w-128 h-128 rounded-4 mx-8 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5',
                          'featured'
                        )}
                      >
                        <input accept="image/*" style={{ display: 'none' }}

                          onChange={(e) => {
                            this.fileUpload2(e, "images7");
                          }}
                          id="icon-button-file7" type="file" />
                        <label htmlFor="icon-button-file7">
                          <IconButton color="primary" aria-label="upload picture" component="span">
                            <img className="max-w-none w-auto " id="images7" src="frontend/assets/images/custom/empty-image-thumb.png" />
                          </IconButton>
                        </label>
                      </div>

                      <div
                        role="button"
                        tabIndex={0}
                        className={clsx(
                          classes.productImageItem,
                          'flex row items-center justify-center relative w-128 h-128 rounded-4 mx-8 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5',
                          'featured'
                        )}
                      >
                        <input accept="image/*" style={{ display: 'none' }}

                          onChange={(e) => {
                            this.fileUpload2(e, "images8");
                          }}
                          id="icon-button-file8" type="file" />
                        <label htmlFor="icon-button-file8">
                          <IconButton color="primary" aria-label="upload picture" component="span">
                            <img className="max-w-none w-auto" id="images8" src="frontend/assets/images/custom/empty-image-thumb.png" />
                          </IconButton>
                        </label>
                      </div>
                    </div>
                  </div>
                )}




                {/* <div className="flex justify-between items-center w-5/12">
                    <Button variant="contained" component="label">
                      upload thumbnail
                      <input
                        accept="image/png"
                        type="file"
                        required
                        onChange={(e) => {
                          this.fileUpload2(e, "thumbnail");
                        }}
                        style={{ display: "none" }}
                      />
                    </Button>
                    <img
                      alt=""
                      id="thumbnail"
                      title="thumbnail"
                      style={{ maxWidth: "40%" }}
                      src="frontend/assets/images/custom/empty-image-thumb.png"
                    />
                  </div> */}

                <Backdrop
                  className={classes.backdrop}
                  open={this.state.progressshow}
                  style={{
                    zIndex: 9999,
                    color: "#fff",
                  }}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  className="ml-auto my-16"
                  disabled={!this.state.formValid}
                  type="submit"
                  variant="contained"
                >
                  Add
                </Button>
              </Formsy>
            </div>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(Add);
