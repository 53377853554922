import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded } from "@fuse";
import Button from "@material-ui/core/Button";

import Paper from "@material-ui/core/Paper";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";
import { Link } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
const styles = (theme) => ({
  layoutRoot: {},
});
const breadCurmbData = [
  {
    title: "Users",
    link: `${process.env.REACT_APP_FRONT_URL}users/list`,
  },
  {
    title: "Profile List",
    link: "",
  },
];


class List extends Component {
  state = {
    open: false,
    formValid: false,
    rowsPerPage: 5,
    page: 0,
    data: [],
    categoryData: [],
    idremove: "0",
    counterOfProfile: 0,
    avatarlist: []
  };

  handleSubmit = (model) => { };
  enableButton = () => {
    this.setState({
      formValid: true,
    });
  };

  disableButton = () => {
    this.setState({
      formValid: false,
    });
  };
  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };
  handleChangeRowsPerPage = () => { };
  sortData = (name) => {
    let data = this.state.data;

    data = data.sort(function (a, b) {
      if (a[name] < b[name]) {
        return -1;
      }
      if (a[name] > b[name]) {
        return 1;
      }
      return 0;
    });

    this.setState({
      data: data,
    });
  };
  componentDidMount() {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let id = this.props.match.params.id;

    let data = {
      id: id,
    };
    axios({
      url: `${backend_url}/user/getprofile`,
      method: "post",
      data: data,
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
   
      console.log(res.data.items[0].pimage)
      // var obj = JSON.parse(res.data.items);

      if(res.data.items[0].pimage === undefined ){
        alert('This account is not linked to any profile!')
        this.props.history.push('/frontend/users/list');
      }
      this.setState({
        categoryData: res.data.items,
        counterOfProfile: res.data.counter
      });
    });

    axios({
      url: `${backend_url}/avatar/list`,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      this.setState({
        avatarlist: res.data.result,
      });
    });
  }

  prettyDate(date) {

    var d = new Date(date);

    return d.toDateString() + " ";
  }


  getavater = (id) => {
    // return this.state.avatarlist[Math.floor(Math.random() * (5 - 0 + 1)) + 0].imgUpload;
    this.state.avatarlist.map((item) => {
      if (item._id === id) {
        return item.imgUpload;
      }

    })
  }
  handleDelete = () => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    formData.append("id", this.state.idremove);
    axios({
      url: `${backend_url}/songs/delete`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.componentDidMount();
      this.setState({
        open: false,
      });
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
        }}
        header={
          <div className="p-24">
            <Breadcrumb data={breadCurmbData} />
          </div>
        }
        contentToolbar={
          <div className="w-full flex items-center justify-between p-24">
            <h2>Profile List</h2>
            <div className="flex items-center">
              <Link to={`${process.env.REACT_APP_FRONT_URL}songs/add`}>
                <Fab color="primary" aria-label="add">
                  <AddIcon />
                </Fab>
              </Link>
            </div>
          </div>
        }
        content={
          <div style={{ padding: "30px" }}>
            <Grid container spacing={1}>
              <Grid container item xs={12} spacing={2}>
                {this.state.categoryData.map((row) => (

                  <React.Fragment>
                    <Grid item xs={3} style={{ paddingBottom: "20px" }}>
                      <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                          <Grid item>
                         
                          </Grid>
                          <Grid item xs={12} sm container>
                            <Grid
                              item
                              xs
                              container
                              direction="column"
                              spacing={2}
                            >
                              <Grid item xs>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  style={{
                                    textTransform: "uppercase",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {row.fname}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  Gender: {row.gender == 'm' ? 'Male' : 'Female'}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  Date of Birth: {row.bdate}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Profile ID: {row._id}
                                  <br></br>
                                  {/* Publication date: {this.prettyDate(row.created)} */}
                                </Typography>
                              </Grid>
                              {/* <Grid item>
                                <Link
                                  to={`${process.env.REACT_APP_FRONT_URL}songs/${row._id}/edit`}
                                >
                                  <IconButton
                                    aria-label="delete"
                                    className={classes.margin}
                                  >
                                    <EditIcon fontSize="medium" />
                                  </IconButton>
                                </Link>
                               

                                <IconButton
                                  aria-label="delete"
                                  className={classes.margin}
                                  onClick={() => {
                                    this.setState({
                                      open: true,
                                      idremove: row._id,
                                    });
                                  }}
                                >
                                  <DeleteIcon fontSize="medium" />
                                </IconButton>
                                <Dialog
                                  open={this.state.open}
                                  keepMounted
                                  aria-labelledby="alert-dialog-slide-title"
                                  aria-describedby="alert-dialog-slide-description"
                                >
                                  <DialogTitle id="alert-dialog-slide-title">
                                    <DeleteIcon fontSize="large" />{" "}
                                    {" Are you sure you want to delete it?"}
                                  </DialogTitle>
                                  <DialogActions>
                                    <Button
                                      onClick={() => {
                                        this.setState({
                                          open: false,
                                        });
                                      }}
                                      color="primary"
                                    >
                                      No
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        this.handleDelete();
                                      }}
                                    >
                                      OK
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </Grid> */}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(List);
