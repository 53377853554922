import Add from "./Add";
import Edit from "./Edit";
import List from "./List";

export const ageRateConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${process.env.REACT_APP_FRONT_URL}age_rate/add`,
      component: Add,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}age_rate/:id/edit`,
      component: Edit,
    },
    {
      path: `${process.env.REACT_APP_FRONT_URL}age_rate/list`,
      component: List,
    },
  ],
};
