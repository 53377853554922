import React from 'react';

import { connect } from 'react-redux';

import axios from 'axios';

import Dialog from '@material-ui/core/Dialog';

import DialogContent from '@material-ui/core/DialogContent';

import DialogContentText from '@material-ui/core/DialogContentText';

import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';

import { setTimeout } from 'timers';

import { baseLink } from "./helper";



class story extends React.Component {

    checkBook = false;

    constructor() {

        super();

        this.state = {

            open: false

        }

    }

    componentDidUpdate(prevProps, prevState) {

        let th = this;



        if (this.props.updateText == true) {

            let page_count = this.props.page_count;

            let book = this.props.book;

            let spansList = th.props.spansList;

            console.log('111111111111spansList',spansList)

            let selectedSpans = spansList[page_count];


            if (selectedSpans !== undefined) {
                selectedSpans.forEach((element, indx) => {

                    // document.getElementById(element.id).innerHTML += `<span><input class='checkbox-count' value='${element.id}' type='checkbox' /></span>`;



                    if (document.getElementById(element.id) != null) {

                        document.getElementById(element.id).innerHTML += `<span class='checkbox-count' value='${element.id}'>&cross;</span>`;

                    }

                    else {

                        //  spansList[page_count].splice(indx,1);

                        // ظth.props.dispatch({type:"update_spanslist",spansList:spansList})

                    }



                });
            }





            book.pages[page_count].smilAudios.forEach(function (item, itemIndx) {

                item.spans.forEach(function (span, spanIndx) {

                    // let mainId = span.id;                

                    if (document.getElementById(span.id) != null || document.getElementById(span.id) != undefined) {

                        var aTag = document.createElement("a");

                        aTag.id = `img-${span.id}---${itemIndx}---${spanIndx}`;

                        aTag.innerHTML = "edit";

                        aTag.classList.add('edit-text');

                        aTag.href = '#';

                        //   aTag.addEventListener("click", th.editLine2);



                        document.getElementById(span.id).appendChild(aTag);



                        document.querySelector(`#${span.id} .checkbox-count`).remove();



                        // document.getElementById(span.id).innerHTML += `<a class='edit-text' href='#' id='img-${span.id}---${itemIndx}---${spanIndx}'>edit</a>`;

                    }



                });

            });

            this.props.dispatch({ type: "updateText", updateText: false });

        }

    }



    componentDidMount() {

        /* document.querySelectorAll("span").forEach(function(item) {
 
             console.log(item);
 
             item.addEventListqener("mouseenter",function(e) {
 
                 console.log(e.target);
 
             });
 
         });*/

        document.getElementById("spansTable").style.display = "none";

        let th = this;

        let opf_link = window.location.href;

        let opf_dir = window.location.href;




        var url_string = window.location.href;



        var url = new URL(url_string);

        opf_link = url.searchParams.get("opf_file");

        opf_dir = opf_link.replace("content.opf", "");

        var id = url.searchParams.get("id");





        this.props.dispatch({ type: "update_opf", opf_dir: opf_dir, opf_link: opf_link });



        axios.get('https://royakids.tv/frontend/static/' + id + "/OEBPS/data.json?id=" + Math.floor(Math.random() * 9000)).then(function (res) {

            let type = typeof res.data;



            if (type == "object") {

                th.checkBook = true;

                th.props.dispatch({ type: "update_data", book: res.data });

            }

            axios.get('https://royakids.tv/frontend/static/' + id + "/OEBPS/content.opf?id=" + Math.floor(Math.random() * 9000)).then(function (res2) {

                th.perpareFiles(res2.data);

            });

        }).catch(function (error) {

            axios.get('https://royakids.tv/frontend/static/' + id + "/OEBPS/content.opf?id=" + Math.floor(Math.random() * 9000)).then(function (res) {

                th.perpareFiles(res.data);

            });

        })





        /*     document.addEventListener('mouseenter', function(e) {
     
                 th.selectText(e);
     
             });*/



        document.addEventListener('selectionchange', function (e) {

            // th.selectText(e);

        });

    }

    currentWord1 = 0;

    currentSpan1 = 0;

    current1 = 0;

    audio2 = () => {

        let th = this;

        let page_count = this.props.page_count;

        let smilAudios = this.props.book.pages[page_count].smilAudios;

        let audios = document.querySelectorAll("#page-audios audio");



        this.currentWord1 = 0;

        this.currentSpan = 0;

        this.current1 = 0;



        let currentAudio = audios[this.currentSpan];

        let audioLink = smilAudios[this.currentSpan].audioLink;

        let currentPoints = smilAudios[this.currentSpan].spans[0].points;

        let selectedId = currentPoints[0].textId;



        document.getElementById(selectedId).style.background = "yellow";



        currentAudio.play();

        currentAudio.addEventListener("timeupdate", this.audioUpdate);

        currentAudio.addEventListener("ended", this.audioEnded);

        /*
        
                currentAudio.addEventListener("timeupdate", function(e) {
        
                    if(currentPoints[th.current1 + 1] != undefined) {
        
                        if(currentPoints[th.current1].end < currentAudio.currentTime) {
        
                            document.getElementById(selectedId).style.background = "";
        
                            this.current1++;
        
                            selectedId = currentPoints[th.current1].textId;
        
                            console.log("selectId : " +  selectedId)
        
         
        
                            document.getElementById(selectedId).style.background = "yellow";
        
         
        
                            if(selectedId != undefined) {
        
                               // document.getElementById(selectedId).style.background = "yellow";
        
                            }
        
                        }
        
         
        
                        //console.log(currentPoints[i]);
        
                    }
        
                   
        
                });
        
               
        
                currentAudio.addEventListener("ended", function(e) {
        
                   
        
                    this.current1 = 0;
        
                    this.ccurrentSpan++;
        
                    this.ccurrentWord = 0;
        
                    //console.log(selectedId);
        
                    document.getElementById(selectedId).style.background = "";
        
         
        
                    currentPoints = smilAudios[th.currentSpan].spans[0].points;
        
         
        
                    selectedId = currentPoints[th.current1].textId;
        
         
        
                   
        
                    document.getElementById(selectedId).style.background = "yellow";
        
         
        
                   // currentAudio.play();
        
                    //audioLink = smilAudios[currentSpan].audioLink;
        
         
        
                    //let currentAudioSrc = audios[currentSpan].src;
        
         
        
                });*/



    }

    audioEnded = (e) => {



    }

    audioUpdate = (e) => {

        //  console.log(e.srcElement.currentTime);

        let page_count = this.props.page_count;

        let smilAudios = this.props.book.pages[page_count].smilAudios;

        let audios = document.querySelectorAll("#page-audios audio");



        let currentAudio = audios[this.currentSpan];

        let audioLink = smilAudios[this.currentSpan].audioLink;

        let currentPoints = smilAudios[this.currentSpan].spans[0].points;

        let selectedId = currentPoints[0].textId;



    }

    selectAudio = (e) => {

        let page_count = this.props.page_count;

        let book = this.props.book;

        let th = this;



        // console.log(book.pages[page_count]);      



        if (e.target.value == "oneAudio") {

            book.pages[page_count].smilAudios = [];

        }

        else {

            book.pages[page_count].smilAudios = [];

        }

        book.pages[page_count].pageAudioType = e.target.value;



        if (e.target.value == "paragraphs") {

            th.setState({

                para_open: true

            });



            setTimeout(() => {

                th.setState({

                    para_open: false

                });

            }, 1000);

        }



        this.props.dispatch({ type: "page_type", pageType: e.target.value, book: book });

        if (e.target.value != "paragraphs") {

            document.getElementById("spansTable").style.display = "none";

        }

        else {



            document.getElementById("spansTable").style.display = "block";

        }

        if (e.target.value == "") {

            // document.querySelectorAll(".upload-bt")[0].classList.add("disabled");

            let buttons = document.querySelectorAll(".main-bt");

            buttons.forEach(function (bt) {

                // bt.disabled = true;

            });

        }

        else {

            //  document.querySelectorAll(".upload-bt")[0].classList.remove("disabled");

            let buttons = document.querySelectorAll(".main-bt");

            buttons.forEach(function (bt) {

                bt.disabled = false;

            });

        }



    }

    oneAudio = (type) => {

        /* let spansList = this.props.spansList;
 
         let page_count = this.props.page_count;
 
  
 
         let selectedSpans = spansList[page_count];*/

    }

    mouneEnter = (e) => {

        if (document.getSelection().anchorNode != null) {

            //console.log(document.getSelection());

        }

        /*

        let currentAudio = "";

        if(currentPage.pageAudioType == "paragraphs") {

            currentPage.smilAudios.forEach(function(item) {

                item.spans.forEach(function(miniSpan) {

                    if(miniSpan.id == startId) {    

                        console.log(startId);

                    }

                });

                //currentAudio = item.audioLink;

            });

        }*/



    }



    buttonsStatus = (e) => {



    }

    paragraphSlicing = (next_page = "") => {

        /*     let page_count = this.props.page_count;
      
              let book = this.props.book;
      
              if(next_page != "") {
      
                  page_count = next_page;
      
              }
      
         
      
              let currentPage = book.pages[page_count];
      
              currentPage.smilAudios.forEach(function(smilItem) {
      
                  smilItem.spans.forEach(function(minSpan) {
      
                      let selectedSpan = minSpan.id.replace("#","");
      
                      setTimeout(() => {
      
                          console.log();
      
                          document.getElementById(selectedSpan).style.backgroundColor = "green";
      
                          let coordinates = document.getElementById(selectedSpan).getBoundingClientRect();
      
                          let dom2 = document.createElement("div");
      
                          console.log(coordinates)
      
                          dom2.style.position = "absolute";
      
                          dom2.style.zIndex = "111111";
      
                         
      
                          console.log(document.getElementById(selectedSpan).style.top);
      
       
      
                          dom2.style.top = document.getElementById(selectedSpan).style.top;
      
                          dom2.style.left = document.getElementById(selectedSpan).style.left;
      
                          dom2.innerHTML = "<a href='#'>Edit</a>";
      
                          document.getElementById("story-page").appendChild(dom2);
      
                      }, 1000);
      
                  })
      
       
      
              });*/

    }

    perpareFiles = (xmlString) => {

        let th = this;

        setTimeout(() => {

            th.setState({

                open: false

            });

        }, 600);



        let spine_list = "";

        let manifest_list = "";

        let spines = [];

        let page_ids = [];

        let pages2 = [];

        let pagesWidth = [];

        let pageHeaders = [];

        let count = 0;

        let htmlpage = [];

        let pagedims = [];

        let cssLinks = [];

        let book = this.props.book;

        let inde = 0;

        var url_string = window.location.href;

        let tempPages = []





        var url = new URL(url_string);

        let opf_link = url.searchParams.get("opf_file");

        let opf_dir = opf_link.replace("content.opf", "");

        let lang = url.searchParams.get("lang");

        if (lang == "ar") {

            document.getElementById("story-page-inner").style.direction = "rtl";

            document.getElementById("story-page-inner").classList.add("page-rtl");

        }

        else {

            document.getElementById("story-page-inner").style.direction = "ltr";

            document.getElementById("story-page-inner").classList.add("page-ltr");

        }

        //let opf_dir = localStorage.getItem("opf_dir");

        let spansList = [];

        let spanCount = 0;

        let checkBook = this.checkBook;

        let pageDirs = [];




        let doc = new DOMParser().parseFromString(xmlString, "text/xml");



        let spine_list2 = doc.querySelector("spine");

        let page_direct = spine_list2.getAttribute("page-progression-direction");



        if (page_direct == null) {

            page_direct = "ltr";

        }



        spine_list = doc.querySelectorAll("spine itemref");

        manifest_list = doc.querySelectorAll("manifest item");



        for (let i = 0; i < spine_list.length; i++) {

            spines.push(spine_list[i].getAttribute("idref"));

            let properties = spine_list[i].getAttribute("properties");



            if (properties != null) {

                pageDirs[i] = {};

                pageDirs[i].properties = properties;

            }

            else {

                if (page_direct == "rtl") {

                    if (i % 2 == 0) {

                        pageDirs[i] = {};

                        pageDirs[i].properties = "page-spread-left";

                    }

                    else {

                        pageDirs[i] = {};

                        pageDirs[i].properties = "page-spread-right";

                    }

                }

                else if (page_direct == "ltr") {

                    if (i % 2 == 0) {

                        pageDirs[i] = {};

                        pageDirs[i].properties = "page-spread-right";

                    }

                    else {

                        pageDirs[i] = {};

                        pageDirs[i].properties = "page-spread-left";

                    }

                }

            }





        }



        manifest_list.forEach(function (manifest_item, pageIndex) {

            let id = manifest_item.id;

            let href = manifest_item.getAttribute("href");

            let media_type = manifest_item.getAttribute("media-type");



            if (media_type == "text/css") {

                //cssLinks.push(<link href={opf_dir + href} rel='stylesheet' />);

            }



            if (spines.indexOf(id) != -1) {



                let page_id = opf_dir + manifest_item.getAttribute("href");

                let page_id2 = manifest_item.getAttribute("href");



                page_ids.push(page_id);





                if (checkBook == false) {



                    book.pages[inde] = {};

                    book.pages[inde].pageId = inde;

                    book.pages[inde].pagePath = "/" + page_id2;



                    book.pages[inde].smilAudios = [];

                    book.pages[inde].voiceRecords = [];

                    book.pages[inde].notes = [];

                    book.pages[inde].pageAudioType = "";

                    book.pages[inde].drawings = [];

                    book.pages[inde].highlights = [];

                    if (lang == "ar") {

                        book.pages[inde].direction = "rtl";

                    }

                    else {

                        book.pages[inde].direction = "ltr";

                    }



                    //book.pages[inde]['smilAudios']['audioLink'] = "";

                    //book.pages[inde]['smilAudios']['spans'] = [];



                    inde++;

                }



                // axios.get(page_id).then(function(res) {



                // }).catch(error => {

                // console.log(error)

                // });



            }

        });

        if (checkBook == false) {

            th.props.dispatch({ type: "update_book_first", book: book });

        }

        page_ids.forEach(page => {

            tempPages.push('')

        })

        let countPages = 0

        // page_ids.forEach((page_id,pageIndex) => {

        this.setState({

            open: true

        })

        this._loadPage(countPages, tempPages, spines, pageDirs, page_ids, page_direct)

        // axios.get(page_id).then(function(res) {

        //     if(pageIndex == page_ids.length-1){

        //         tempPages[pageIndex] = res.data

        //         // tempPages.push(res.data)

        //         th._editPages(tempPages,spines,pageDirs,page_ids)

        //         console.log(tempPages)

        //     }else{

        //         tempPages[pageIndex] = res.data

        //         // console.log(tempPages)

        //     }

        // })

        // });

        th.setState({

            spines: spines

        });

    }

    _loadPage(count, tempPages, spines, pageDirs, page_ids, page_direct) {
        console.log(page_ids[count])
        axios.get("https://royakids.tv/" + page_ids[count] + "?id=" + Math.floor(Math.random() * 9000)).then(res => {

            if (count == page_ids.length - 1) {

                tempPages[count] = res.data



                this._editPages(tempPages, spines, pageDirs, page_ids, page_direct)



                //  console.log(tempPages)

            } else {

                tempPages[count] = res.data



                // let pageDom = new DOMParser().parseFromString(res.data, "application/xml");

                // console.log(pageDom);



                count++;

                this._loadPage(count, tempPages, spines, pageDirs, page_ids, page_direct);

                // console.log(tempPages)

            }

        })

    }

    checkSpans = false;

    _editPages = (pages, spines, pageDirs, page_ids, page_direct) => {

        let th = this;

        let spine_list = "";

        let manifest_list = "";

        // let spines = [];

        //let page_ids = [];

        let pages2 = [];

        let pagesWidth = [];

        let pageHeaders = [];

        let count = 0;

        let htmlpage = [];

        let pagedims = [];

        let cssLinks = [];

        let classesList = [];

        let book = this.props.book;

        var url_string = window.location.href;





        var url = new URL(url_string);

        let opf_link = url.searchParams.get("opf_file");

        let bookType = url.searchParams.get("type");

        let opf_dir = opf_link.replace("content.opf", "");

        //let opf_dir = localStorage.getItem("opf_dir");

        let spansList = [];

        let spanCount = 0;

        let checkBook = this.checkBook;

        // let pageDirs = [];

        let pageCount = 0;


        console.log(pages)
        pages.forEach((page, pageId2) => {


            page = page.replaceAll("&#160;", " ");



            let doc2 = new DOMParser().parseFromString(page, "application/xml");



            let spans = doc2.querySelectorAll("span");



            spans.forEach(function (item) {

                let txt = item.innerText;

                txt = txt.trim();

                if (txt.length == 0) {

                    item.remove();

                }

            });

            let pTags = doc2.querySelectorAll("p");



            let generateSpans = false;

            let spansTxt = "";

            pTags.forEach(function (pItem) {

                if (pItem.querySelectorAll("span").length == 0 && pItem.children.length == 0) {

                    generateSpans = true;

                    // console.log(pItem.innerHTML.split(" "));

                    let mainSpan = document.createElement("span");

                    let id = "text-spans-" + pageCount;



                    mainSpan.id = id;

                    mainSpan.classList.value = "parent-list";



                    pItem.innerHTML.split(" ").forEach(function (it, minspan) {



                        let span = document.createElement("span");



                        if (minspan == 0) {

                            span.innerHTML = it;

                        }

                        else {

                            span.innerHTML = " " + it;

                        }

                        span.id = id + "-" + minspan;

                        mainSpan.appendChild(span);



                    })

                    pItem.innerHTML = mainSpan.outerHTML;

                }

                /*                console.log(pItem.innerHTML);
                
                 
                
                                if(pItem.innerHTML.search("<span") == -1) {
                
                                    generateSpans = true;
                
                                }*/



            });

            if (doc2.querySelectorAll(".parent-list").length != 0 && bookType == "pdf") {

                spansTxt = "";

                doc2.querySelectorAll(".parent-list").forEach(function (el2, indx2) {

                    let spans21 = el2.querySelectorAll("span");



                    spans21.forEach(function (el, indx) {

                        //console.log(el);



                        if (el.innerText == "ﬀ") {



                            let firstWordArray = spans21[indx - 1].querySelectorAll("span");

                            let firstwordPos = firstWordArray.length - 1;

                            let lastWordArray = spans21[indx + 1].querySelectorAll("span");





                            //      console.log(firstWordArray[firstwordPos].innerText);

                            //      console.log(el.innerText);

                            //      console.log(spans21[indx+1].innerText.split(" ")[0]);



                            firstWordArray[firstwordPos].innerText += el.innerText + spans21[indx + 1].innerText.split(" ")[0];

                            //   console.log(spans21[indx-1].innerText);

                            el.outerHTML = "";

                            lastWordArray[0].outerHTML = "";



                            spans21[indx - 1].innerHTML += spans21[indx + 1].innerHTML;

                            spans21[indx + 1].outerHTML = "";

                        }

                        //var t = new DOMParser().parseFromString(spans21[indx]);



                        // spans = spans21[indx].querySelectorAll("[id*='word']");

                        // spans = spans21[indx];





                        if (indx != 0) {

                            //    let selectedSpan = spans21[indx].innerHTML;

                            //    spans21[0].innerHTML += selectedSpan;

                            //    spans21[indx].outerHTML = "";

                        }

                        if (indx == spans21.length - 1) {

                            setTimeout(() => {

                                spans21.forEach(function (it) {

                                    //console.log(it);



                                });



                            }, 500);

                        }

                    });



                });

                /* let spans21 = doc2.querySelectorAll(".lh1");

                spans21.forEach(function(el,indx) {

                    if(indx != 0) {

                        let selectedSpan = spans21[indx].innerHTML;

                        //  spans21[0].innerHTML += selectedSpan;

                        //   spans21[indx].outerHTML = "";

                    }

                });*/

            }

            else if (doc2.querySelectorAll("#parent-p1").length != 0) {

                spansTxt = "";

                doc2.querySelectorAll("#parent-p1 p").forEach(function (el2, indx2) {

                    let spans21 = el2.querySelectorAll("span.lh1");



                    spans21.forEach(function (el, indx) {

                        if (el.innerText == "ﬀ") {



                            let firstWordArray = spans21[indx - 1].querySelectorAll("span");

                            let firstwordPos = firstWordArray.length - 1;

                            let lastWordArray = spans21[indx + 1].querySelectorAll("span");





                            //      console.log(firstWordArray[firstwordPos].innerText);

                            //      console.log(el.innerText);

                            //      console.log(spans21[indx+1].innerText.split(" ")[0]);



                            firstWordArray[firstwordPos].innerText += el.innerText + spans21[indx + 1].innerText.split(" ")[0];

                            //   console.log(spans21[indx-1].innerText);

                            el.outerHTML = "";

                            lastWordArray[0].outerHTML = "";



                            spans21[indx - 1].innerHTML += spans21[indx + 1].innerHTML;

                            spans21[indx + 1].outerHTML = "";

                        }

                        //var t = new DOMParser().parseFromString(spans21[indx]);



                        // spans = spans21[indx].querySelectorAll("[id*='word']");

                        // spans = spans21[indx];





                        if (indx != 0) {

                            //    let selectedSpan = spans21[indx].innerHTML;

                            //    spans21[0].innerHTML += selectedSpan;

                            //    spans21[indx].outerHTML = "";

                        }

                        if (indx == spans21.length - 1) {

                            setTimeout(() => {

                                spans21.forEach(function (it) {

                                    //console.log(it);



                                });



                            }, 500);

                        }

                    });



                });

                /* let spans21 = doc2.querySelectorAll(".lh1");
    
                spans21.forEach(function(el,indx) {
    
                    if(indx != 0) {
    
                        let selectedSpan = spans21[indx].innerHTML;
    
                        //  spans21[0].innerHTML += selectedSpan;
    
                        //   spans21[indx].outerHTML = "";
    
                    }
    
                });*/

            }

            else if (doc2.getElementsByClassName("word-syncing").length == 0) {



                for (let i = 0; i < spans.length; i++) {

                    let spanMinis = "";

                    if (spans[i] != undefined) {

                        //if(spans[i].innerText != "") {

                        let txt = spans[i].innerText;

                        txt.replace("   ", "");



                        let findStr = txt.includes("&#160;");

                        console.log("tttt", findStr, txt);



                        txt = txt.replace("&amp;", "");

                        txt = txt.replace("&", "");



                        txt = txt.replace("&#160;", " ");

                        txt = txt.replace("&nbsp;", " ");



                        let spanlist = txt.split(" ");



                        let check_txt = spans[i].innerHTML;

                        let id = spans[i].id;

                        if (id == "") {

                            id = "text-spans-" + pageCount + "-" + i;

                            spans[i].id = "text-spans-" + pageCount + "-" + i;

                        }

                        if (spans[i].innerText.length == 0) {

                            return;

                        }

                        let checkTxt = spans[i].innerText;

                        spans[i].innerHTML = "";

                        checkTxt = checkTxt.trim();



                        // console.log(spanlist);



                        let miniCount = 0;

                        spanlist.forEach(function (spanMini, index) {

                            spanMini = spanMini.trim();

                            //console.log(spanMini.search("&"),spanMini);



                            if (spanMini == "" || spanMini == "&amp;" || spanMini == "&" || spanMini == " ") {

                                return;

                            }



                            let special_chars = ["+", "-", "–", "&&", "||", "!", "(", ")", "{", "}", "[", "]", "^",

                                "~", "*", "?", ":", "”", "“"];



                            var format = /[ !–@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

                            //console.log( + " " + spanMini + " " + spanMini.length)



                            if (format.test(spanMini) == true && spanMini.length == 1) {

                                spanMinis += `<span class='word-syncing'>${spanMini} </span>`;

                                return;

                            }

                            else if (spanMini == "…") {

                                spanMinis += `<span class='word-syncing'>${spanMini} </span>`;

                                return;

                            }

                            else {

                                spanMinis += `<span id='${id}-${miniCount}' class='word-syncing'>${spanMini} </span>`;

                            }

                            miniCount++;

                        });

                        if (pageId2 < 16) {

                            //  console.log(spans[i]," -- ",spanMinis);

                            spans[i].innerHTML = spanMinis;

                        }

                        else {

                            //console.log(spans[i]," -- ",spanMinis);

                            spans[i].innerHTML = spanMinis;



                        }

                        //  





                        // console.log(spans[i].innerText.split(" "))

                        // }

                    }

                    //console.log(spanMinis);

                }



            }

            pageCount++;

            spansList[spanCount] = [];

            if (doc2.querySelectorAll(".parent-list").length != 0 && bookType == "pdf") {

                spans = doc2.querySelectorAll("span");

            }



            spans.forEach(function (item) {

                if (doc2.querySelectorAll(".parent-list").length != 0 && bookType == "pdf") {

                    if (item.classList.value == "parent-list") {

                        let spanItem = {};

                        spanItem.id = item.id;

                        spanItem.value = item.innerText;

                        if (spanItem.value.search("<!--") == -1) {

                            if (spanItem.innerText != "") {

                                spansList[spanCount].push(spanItem);

                            }

                        }



                    }

                }

                else if (doc2.querySelectorAll("#parent-p1").length != 0 && bookType == "pdf") {

                    if (item.id.search("textid") != -1) {

                        let spanItem = {};

                        spanItem.id = item.id;

                        spanItem.value = item.innerText;

                        if (spanItem.value.search("<!--") == -1) {

                            if (spanItem.innerText != "") {

                                spansList[spanCount].push(spanItem);

                            }

                        }



                    }

                }

                else if (item.classList.value != "word-syncing") {

                    let spanItem = {};

                    spanItem.id = item.id;

                    spanItem.value = item.innerText;

                    if (spanItem.value.search("<!--") == -1) {

                        if (spanItem.innerText != "") {



                            spansList[spanCount].push(spanItem);

                        }

                    }

                }



            });

            //  console.log(spansList)



            spanCount++;



            let body = doc2.querySelectorAll("body")[0];

            let bodyHtml = doc2.querySelectorAll("body")[0].innerHTML;
            let bodyHeader = doc2.querySelectorAll("head")[0].outerHTML;

            let classes = "";

            if (body.classList.length != 0) {

                classes = body.classList.value;

            }

            classesList.push(classes);

            let width = body.style.width;

            let height = body.style.height;

            // alert(width);





            document.getElementById("story-page").style.width = width;

            document.getElementById("story-page").style.height = height;





            pages2.push(bodyHtml);

            pageHeaders.push(bodyHeader);





            pagedims[count] = {};

            pagedims[count].width = width;

            pagedims[count].height = height;

            //console.log(pagedims);

            count++;

            //book.bookDirection = page_direct;



            book.pages.forEach(function (item, index) {

                if (book.pages[index + 1] != undefined) {

                    if (index == 0) {

                        //cover direction

                        book.pages[index].pageSpread = "";

                        if (page_direct == "ltr") {

                            book.pages[index].pageSpreadDirection = "right";

                        }

                        else {

                            book.pages[index].pageSpreadDirection = "left";

                        }

                    }

                    else {



                        //console.log(book.pages);

                        //console.log(pageDirs);



                        if (pageDirs[index].properties == "page-spread-left") {

                            book.pages[index].pageSpreadDirection = "left";

                        }

                        else if (pageDirs[index].properties == "page-spread-right") {

                            book.pages[index].pageSpreadDirection = "right";

                        }

                        if (page_direct == "rtl") {

                            if (book.pages[index].pageSpreadDirection == "right") {

                                book.pages[index].pageSpread = book.pages[index + 1].pagePath;

                            } else if (book.pages[index].pageSpreadDirection == "left") {

                                book.pages[index].pageSpread = book.pages[index - 1].pagePath;

                            }

                        } else if (page_direct == "ltr") {

                            if (book.pages[index].pageSpreadDirection == "left") {

                                book.pages[index].pageSpread = book.pages[index + 1].pagePath;

                            } else if (book.pages[index].pageSpreadDirection == "right") {

                                book.pages[index].pageSpread = book.pages[index - 1].pagePath;

                            }

                        }





                    }

                }



            });







            htmlpage = pages2[0].trim();

            var url_string = window.location.href;

            var url = new URL(url_string);

            let bookpageId = url.searchParams.get("id");











            let di1 = new DOMParser().parseFromString(htmlpage, "text/html");



            di1.querySelectorAll("img").forEach(function (item) {

                // src = item.src;

                //item.src = item.src.replace('../Images/','/static/'+ bookpageId +'/OEBPS/Images/');

                //src = src.replace('../Images/','/static/'+ bookpageId +'/OEBPS/Images/');

                //item.src = src;

                // console.log(item);
                console.log('item11111222', item);

                htmlpage = htmlpage.replace('../Images/', 'https://royakids.tv/frontend/static/' + bookpageId + '/OEBPS/Images/');
            });



            di1.querySelectorAll("audio").forEach(function (item) {

                htmlpage = htmlpage.replace('../audio/', 'https://royakids.tv/frontend/static/' + bookpageId + '/OEBPS/audio/');

            });



            // htmlpage = htmlpage.replace('../Images/','/static/'+ bookpageId +'/OEBPS/Images/');



            // htmlpage = htmlpage.replace('../audio/','/static/'+ bookpageId +'/OEBPS/audio/');





            let audios = [];

            audios = [];

            di1.querySelectorAll("#page-audios audio").forEach(function (item) {

                let src = item.getAttribute("src");

                src = src.replace("../", "/static/" + bookpageId + "/OEBPS/");

                audios.push(src);

            });

            th.props.dispatch({ type: "update_audios", audios: audios });



            th.props.dispatch({ type: "load_page", htmlpage: htmlpage, page_count: 0, selectid: "", page_ids: page_ids });

            th.props.dispatch({ type: "update_book", book: book, cssLinks: cssLinks, spansList: spansList, pages2: pages2, pageHeaders: pageHeaders });



            let storyPage = document.querySelectorAll("#story-page")[0];



            if (th.checkSpans == false) {

                let selectedSpans = spansList[0];

                selectedSpans.forEach((element, indx) => {

                    //   document.getElementById(element.id).innerHTML += `<div><input class='checkbox-count' value='${element.id}' type='checkbox' name='trest' >test</input></div>`;

                    //document.getElementById(element.id).innerHTML += `<span class='checkbox-count'>(&cross;)</span>`;





                    document.getElementById(element.id).innerHTML += `<span class='checkbox-count' value='${element.id}'>&cross;</span>`;

                    //document.getElementById(element.id).innerHTML += `<span class='checkbox-count' value='${element.id}'>&cross;</span>`;

                    //  document.getElementById(element.id).innerHTML += "<a class='edit-text' href='#' id='img-" + element.id +"'>edit</a>";



                });



                book.pages[0].smilAudios.forEach(function (item, itemIndx) {

                    item.spans.forEach(function (span, spanIndx) {

                        // let mainId = span.id;                

                        if (document.getElementById(span.id) != null || document.getElementById(span.id) != undefined) {

                            var aTag = document.createElement("a");

                            aTag.id = `img-${span.id}---${itemIndx}---${spanIndx}`;

                            aTag.innerHTML = "edit";

                            aTag.classList.add('edit-text');

                            aTag.href = '#';

                            //   aTag.addEventListener("click", th.editLine2);



                            document.getElementById(span.id).appendChild(aTag);

                            // let buttonsPosition = {

                            //    x: document.getElementById('story-buttons-custom').getClientRects()[0].left,

                            //    y: document.getElementById('story-buttons-custom').getClientRects()[0].left,

                            // }

                            // let spanPosition ={

                            //     x:buttonsPosition.x - document.getElementById(span.id).getClientRects()[0].left,

                            //     y:buttonsPosition.y - document.getElementById(span.id).getClientRects()[0].top,

                            // }

                            // aTag.style.top = spanPosition.y+'px';

                            // aTag.style.left = spanPosition.x+'px';

                            document.querySelector(`#${span.id} .checkbox-count`).remove();



                            // document.getElementById(span.id).innerHTML += `<a class='edit-text' href='#' id='img-${span.id}---${itemIndx}---${spanIndx}'>edit</a>`;

                        }



                    });

                });

                th.checkSpans = true;

            }



            /* let storyPageWidth = window.getComputedStyle(storyPage).getPropertyValue("width");

            let storyPageHeight =window.getComputedStyle(storyPage).getPropertyValue("height");

            obj.width = storyPageWidth;

            obj.height = storyPageHeight;*/





            //let header2 =  pageHeaders[0];

            let header2 = new DOMParser().parseFromString(pageHeaders[0], "text/xml");

            let cssLinks = [];



            header2.querySelectorAll("link").forEach(function (cssfile) {

                var link = cssfile.href;

                link = link.replace("/book/", "/static/" + bookpageId + "/OEBPS/");
                link = link.replace(window.location.origin, "https://royakids.tv");


                cssLinks.push(<link href={link + "?id=" + Math.floor(Math.random() * 9000)} rel='stylesheet' />);

            });

            th.props.dispatch({ type: "update_page_width", pagesWidth: pagesWidth, classesList: classesList });



            let obj = {};

            let pageDimentions = header2.querySelectorAll("meta[name=viewport]")[0].content;



            let PageWidth = pageDimentions.split(",")[0].trim();

            let PageHeight = pageDimentions.split(",")[1].trim();

            obj.width = PageWidth.replace("width=", "") + "px";

            obj.height = PageHeight.replace("height=", "") + "px";

            pagesWidth.push(obj)



            document.getElementById("story-page").classList.value = classesList[0];

            document.getElementById("story-page").style.width = obj.width;

            document.getElementById("story-page").style.height = obj.height;







            // console.log(htmlpage);

            th.setState({

                htmlpage: htmlpage,

                cssLinks: cssLinks,

                page_ids: page_ids

                //doc:doc

            });



            setTimeout(() => {





                if (checkBook == true) {

                    if (book.pages[0].pageAudioType != "") {





                    }

                    else {

                        //document.querySelectorAll(".upload-bt")[0].classList.add("disabled");



                        let buttons = document.querySelectorAll(".main-bt");

                        buttons.forEach(function (bt) {

                            //      bt.disabled = true;

                        });

                    }

                }





            }, 500);





            if (pageId2 == page.length - 1) {

                this.setState({

                    open: false

                })

            }

        })










        //        th.paragraphSlicing();

    }







    selectPage = (e) => {



        this.props.dispatch({ type: "update_disbaled", saveDisabled: "disabled" })



        if (e.target.value == "none") {

            return;

        }




        this.props.dispatch({ type: "update_para", paraSmilId: "", paraSpanId: "" });



        let id = this.props.selectid;



        if (id != "") {

            document.getElementById(id).style.backgroundColor = "";

        }



        let page_count = this.props.page_count;

        let book = this.props.book;

        let next_page = parseInt(e.target.value);

        let pages = this.props.pages2;

        let pageHeaders = this.props.pageHeaders;

        if (next_page == pages.length) {

            return;

        }

        let htmlpage = pages[next_page];

        let htmlPageDom = new DOMParser().parseFromString(htmlpage, "text/html");



        var url_string = window.location.href;

        var url = new URL(url_string);

        let bookpageId = url.searchParams.get("id");



        htmlPageDom.querySelectorAll("img").forEach(function (item) {

            // src = item.src;

            //item.src = item.src.replace('../Images/','/static/'+ bookpageId +'/OEBPS/Images/');

            //src = src.replace('../Images/','/static/'+ bookpageId +'/OEBPS/Images/');

            //item.src = src;

            console.log('item11111', item);

            htmlpage = htmlpage.replace('../Images/', 'https://royakids.tv/frontend/static/' + bookpageId + '/OEBPS/Images/');


        });



        htmlPageDom.querySelectorAll("audio").forEach(function (item) {

            htmlpage = htmlpage.replace('../audio/', '/static/' + bookpageId + '/OEBPS/audio/');

        });



        //htmlpage = htmlpage.replace('../Images/','/static/'+ bookpageId +'/OEBPS/Images/');



        //




        let audios = this.props.audios;

        audios = [];

        //console.log(htmlPageDom.querySelectorAll("#page-audios audio"));





        htmlPageDom.querySelectorAll("#page-audios audio").forEach(function (item) {

            let src = item.getAttribute("src");

            //src = src.replace("../","",src);

            src = src.replace("../", "/static/" + bookpageId + "/OEBPS/");



            audios.push(src);

        });

        this.props.dispatch({ type: "update_audios", audios: audios });

        let page_ids = this.props.page_ids;

        this.setState({

            open: false

        });

        let th = this;

        th.props.dispatch({ type: "update_point_index", pointIndex: false });





        // th.props.dispatch({type : "load_page",htmlpage:"",page_count:next_page,selectid:"",page_ids:page_ids});





        //th.props.dispatch({type : "load_page",htmlpage:htmlpage,page_count:next_page,selectid:"",page_ids:page_ids});



        // th.props.dispatch({type : "load_page2",htmlpage:htmlpage,page_count:next_page,selectid:""});



        let header2 = new DOMParser().parseFromString(pageHeaders[next_page], "text/xml");

        let cssLinks = [];





        header2.querySelectorAll("link").forEach(function (cssfile) {

            var link = cssfile.href;

            link = link.replace("/book/", "/static/" + bookpageId + "/OEBPS/");
            link = link.replace(window.location.origin, "https://royakids.tv");

            cssLinks.push(<link href={link + "?id=" + Math.floor(Math.random() * 9000)} rel='stylesheet' />);

        });

        header2.querySelectorAll("style").forEach(function (cssfile) {

            cssLinks.push(<style>{cssfile.innerHTML}</style>);

        });

        th.setState({

            cssLinks: cssLinks

        });



        setTimeout(() => {

            th.props.dispatch({ type: "load_page2", htmlpage: htmlpage, page_count: next_page, selectid: "" });



            this.props.dispatch({ type: "updateText", updateText: true });



            /*
            
                                let spansList = th.props.spansList;
            
                                let selectedSpans = spansList[next_page];
            
                   
            
                                selectedSpans.forEach((element,indx) => {
            
                                       // document.getElementById(element.id).innerHTML += `<span><input class='checkbox-count' value='${element.id}' type='checkbox' /></span>`;
            
                                        document.getElementById(element.id).innerHTML += `<span class='checkbox-count' value='${element.id}'>&cross;</span>`;
            
             
            
                                });
            
             
            
                               
            
                            book.pages[next_page].smilAudios.forEach(function(item,itemIndx) {
            
                                item.spans.forEach(function(span,spanIndx) {
            
                                    // let mainId = span.id;                
            
                                     if(document.getElementById(span.id) != null || document.getElementById(span.id) != undefined) {
            
                                         var aTag = document.createElement("a");
            
                                         aTag.id = `img-${span.id}---${itemIndx}---${spanIndx}`;
            
                                         aTag.innerHTML = "edit";
            
                                         aTag.classList.add('edit-text');
            
                                         aTag.href='#';
            
                                      //   aTag.addEventListener("click", th.editLine2);
            
                                         
            
                                         document.getElementById(span.id).appendChild(aTag);
            
                 
            
                                         document.querySelector(`#${span.id} .checkbox-count`).remove();
            
                                             
            
                                       // document.getElementById(span.id).innerHTML += `<a class='edit-text' href='#' id='img-${span.id}---${itemIndx}---${spanIndx}'>edit</a>`;
            
                                     }
            
                 
            
                                 });
            
                                });*/







        }, 1000);



        let type = th.props.book.pages[next_page].pageAudioType;

        let smilAudios = th.props.book.pages[next_page].smilAudios;



        th.props.dispatch({ type: "update_selected_sp", selected_sp: [] });



    }



    render() {

        return (<div>

            <div id="links-container">

                <div className="links-left">

                </div>

                <div className="links-right">

                    <div>{this.props.page_count + 1} / {this.props.page_ids.length}</div>





                </div>

                <select onChange={this.selectPage} className="select-page"
                    style={{
                        fontSize: "14px",
                    }}
                >

                    <option value="none">Select page</option>

                    {this.props.page_ids.map(function (name, index) {

                        return <option value={index}>{index + 1}</option>

                    })}

                </select>

            </div>

            <div id="story-page">{this.state.cssLinks}

                <div id="story-buttons-custom"></div>

                <div id="story-page-inner" dangerouslySetInnerHTML={{ __html: this.props.htmlpage }}></div>

            </div>





            <Dialog open={this.state.open}>

                <DialogContent>

                    <DialogContentText><CircularProgress /></DialogContentText>

                </DialogContent>

            </Dialog>



            <Dialog open={this.state.para_open}>

                <DialogContent>

                    <DialogContentText><b>Please select a paragraph first then upload an audio</b></DialogContentText>

                </DialogContent>

            </Dialog>

        </div>);

    }

}

const mapStateToProps = state => ({

    audioLink: state.audioLink,

    audioObj: state.audioObj,

    audioName: state.audioName,

    startTime: state.startTime,

    htmlpage: state.htmlpage,

    page_count: state.page_count,

    selectid: state.selectid,

    book: state.book,

    page_ids: state.page_ids,

    cssLinks: state.cssLinks,

    spansList: state.spansList,

    pageType: state.pageType,

    showTxts: state.showTxts,

    opf_dir: state.opf_dir,

    pages2: state.pages2,

    pageHeaders: state.pageHeaders,

    pagesWidth: state.pagesWidth,

    audios: state.audios,

    classesList: state.classesList,

    paraSmilId: state.paraSmilId,

    paraSpanId: state.paraSpanId,

    selected_sp: state.selected_sp,

    updateText: state.updateText,

    diff: state.diff,

    saveDisabled: state.saveDisabled

});

export default connect(mapStateToProps)(story);