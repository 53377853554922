import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';

function Widget8(props) { 
	return (
		<Paper className="w-full rounded-8 shadow-none border-1">
			<div className="flex items-center justify-between px-16 h-64 border-b-1">
				<Typography className="h1 font-500">{props.widget != undefined ? props.widget.title : ''}</Typography>
			</div>
			<div className="h-400 w-full p-32">
				<Doughnut
					data={{
						labels: props.widget != undefined ? props.widget.mainChart.labels : null,
						datasets: props.widget != undefined ? props.widget.mainChart.datasets : null
					}}
					options={props.widget != undefined ? props.widget.mainChart.options : null}
				/>
			</div>
		</Paper>
	);
}

export default React.memo(Widget8);
