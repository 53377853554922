/*var baseLink = "";
var frontLink = "";
if(window.location.hostname == "localhost" && window.location.port == "3000" ) {
    baseLink = "http://localhost:4000";
    frontLink = "http://localhost:3000";
}
else if(window.location.hostname == "localhost" && window.location.port == "3000" ) {
    baseLink = "http://localhost:4000";
    frontLink = "http://localhost";
}
else if(window.location.hostname == "192.168.1.144") {
    baseLink = "http://192.168.1.144:4000";
    frontLink = "http://192.168.1.144:3000";
}
else if(window.location.hostname == "kutubee.com" || window.location.hostname == "school.kutubee.com") {
    baseLink = "https://school.kutubee.com:4000";
    frontLink = "https://school.kutubee.com";
}
else {
    baseLink = "http://3.120.84.239:4000";
    frontLink = "http://3.120.84.239";
}
export const baseLink = baseLink;
export const frontLink = frontLink; */
