import FuseAnimate from '@fuse/core/FuseAnimate';
import FuseAnimateGroup from '@fuse/core/FuseAnimateGroup';
import _ from '@lodash';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Icon from '@material-ui/core/Icon';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import withReducer from 'app/store/withReducer';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from "axios";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from '@material-ui/core/Switch';

import DialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import DialogTitle from '@material-ui/core/DialogTitle';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';

import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
const useStyles = makeStyles(theme => ({
  header: {
    background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
    color: theme.palette.getContrastText(theme.palette.primary.main)
  },
  headerIcon: {
    position: 'absolute',
    top: -20,
    left: 0,
    opacity: 0.04,
    fontSize: 250,
    width: 250,
    height: 250,
    pointerEvents: 'none'
  }
}));
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
function Stories(props) {
  const classes = useStyles(props);
  const theme = useTheme();
  const [filteredData, setFilteredData] = useState(null);

  const [searchText, setSearchText] = useState('');

  const [storiesData, setstoriesData] = useState([]);
  const [index, setIndex] = useState(0);
  const [load, setLoad] = useState(true);
  const [count, setCount] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [idremove, setIdremove] = React.useState('');

  function getdata() {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();

    formData.append("keyword", searchText);
    formData.append("indexing", index);

    axios({
      url: `${backend_url}/stories/list`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setCount(res.data.counter)
      setstoriesData(res.data.result);
      if (res.data.result.length === res.data.counter || res.data.result.length <= 11) {
        setLoad(false)
      }

    });
  }


  useEffect(() => {
    getdata()
  }, [index, searchText]);

  useEffect(() => {
    function getFilteredArray() {
      if (searchText.length === 0) {
        return storiesData;
      }
      return storiesData;
    }
    if (storiesData) {
      setFilteredData(getFilteredArray());
    }

  }, [storiesData, searchText, getdata, index]);



  function handleSearchText(event) {
    setSearchText(event.target.value);
    getdata();
  }

  function changeStatus(v, id) {
    let st = v;
    if (st == "Published") {
      st = "Not Published";
    } else {
      st = "Published";
    }
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    formData.append("id", id);
    formData.append("Status", st);
    axios({
      url: `${backend_url}/stories/changestatus`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      getdata();
    });
  };

  function prettyDate(date) {

    var d = new Date(date);

    return d.toDateString() + " ";
  }
  function loadmore() {
    setIndex(index + 1)
    getdata();
  }

  const handleClickOpen = (id) => {
    setIdremove(id)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    formData.append("id", idremove);
    formData.append("token", localStorage.getItem('jwt_access_token'));
    axios({
      url: `${backend_url}/stories/delete`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      getdata();
      setOpen(false);
    });
  }

  function handleChange(e, id) {
    ///
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let formData = new FormData();
    formData.append("itemid", id);
    formData.append("type", 'stories');
    formData.append("isPaid", e.target.checked);

    axios({
      url: `${backend_url}/content/changeisPaid`,
      data: formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      getdata();
    });
  };
  return (
    <div className="flex flex-col flex-auto flex-shrink-0 w-full">
      <div
        className={clsx(
          classes.header,
          'relative overflow-hidden flex flex-col flex-shrink-0 items-center justify-center text-center p-16 sm:p-24 h-200 sm:h-150'
        )}
      >
        <FuseAnimate animation="transition.slideUpIn" duration={400} delay={100}>
          <Typography color="inherit" className="text-24 sm:text-40 font-light uppercase">
            stories LIST
            <Typography color="inherit" className="text-16 sm:text-16 font-light">
              Number of stories uploaded: {count}
            </Typography>
          </Typography>

        </FuseAnimate>

        <Icon className={classes.headerIcon}> view_column </Icon>
        <FuseAnimate animation="transition.slideRightIn" delay={300}>
          <Button
            component={Link}
            to={`${process.env.REACT_APP_FRONT_URL}stories/add`}
            className="whitespace-no-wrap normal-case mt-10"
            variant="contained"
            color="secondary"
          >
            <span className="hidden sm:flex">Add New Story</span>
            <span className="flex sm:hidden">New</span>
          </Button>
        </FuseAnimate>
      </div>
      <div className="flex flex-col flex-1 max-w-2xl w-full mx-auto px-8 sm:px-16 py-24">
        <div className="flex flex-col flex-shrink-0 sm:flex-row items-center justify-between py-24">
          <TextField
            label="Search for a stories (En/Ar)"
            placeholder="Enter a keyword..."
            className="flex w-full"
            value={searchText}
            inputProps={{
              'aria-label': 'Search'
            }}
            onChange={handleSearchText}
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
          />

        </div>
        {useMemo(
          () =>
            filteredData &&
            (storiesData.length > 0 ? (
              <FuseAnimateGroup
                enter={{
                  animation: 'transition.slideUpBigIn'
                }}
                className="flex flex-wrap py-24"
              >
                {storiesData.map(song => {
                  return (
                    <div className="w-full pb-24 sm:w-1/2 lg:w-1/3 sm:p-16" key={song._id}>
                      <Card elevation={1} className="flex flex-col h-356">
                        <div
                          className="flex flex-shrink-0 items-center justify-between px-24 h-64"
                          style={{
                            background: "rgb(44, 86, 123)",
                            color: theme.palette.getContrastText('rgb(44, 86, 123)')
                          }}
                        >
                          <Typography className="font-medium truncate" color="inherit">
                            {song.Status}
                          </Typography>
                          <div className="flex items-center justify-center opacity-75">
                            <Icon className="text-20 mx-8" color="inherit">
                              access_time
                            </Icon>
                            <div className="text-16 whitespace-no-wrap">
                              {prettyDate(song.created)}
                            </div>
                          </div>
                        </div>
                        <CardContent className="flex flex-col flex-auto items-center justify-center">
                          <img
                            className="w-full block rounded"

                            alt={song.titleEn}
                            src={
                              process.env.REACT_APP_BACKEND_URL +
                              "/public/" +
                              song.coverImg
                            }
                          />
                          <Typography
                            className="text-center text-18 font-900 mt-6"
                            color="textSecondary"
                          >
                            {song.titleEn}
                          </Typography>
                          {song.epubShow && <>
                            <div><Button style={{ background: "#51C0E5", color: '#fff' }} href={song.bookSource !== "build_in_audio" ? "/frontend/book/" + song._id + "/edit_audio?id=" + song._id + "&opf_file=" + song.opf_file + "&lang=" + song.language + "&type=" + song.bookSource + "&auto=1" : "/book/" + song._id + "/edit_audio?id=" + song._id + "&opf_file=" + song.opf_file + "&lang=" + song.language + "&type=" + song.bookSource} className='blue-bt edit-audios'>Audio editing</Button></div>
                          </>}
                        </CardContent>
                        <Divider />
                        <CardActions className="justify-center">
                          <IconButton
                            aria-label="VisibilityOff"
                            className={classes.margin}
                            onClick={() => {
                              changeStatus(song.Status, song._id);
                            }}
                          >
                            {song.Status == "Published" ? (
                              <Visibility fontSize="medium" />
                            ) : (
                              <VisibilityOff fontSize="medium" />
                            )}
                          </IconButton>
                          <Link
                            to={`${process.env.REACT_APP_FRONT_URL}stories/${song._id}/edit`}
                          >
                            <IconButton
                              aria-label="delete"
                              className={classes.margin}
                            >
                              <EditIcon fontSize="medium" />
                            </IconButton>
                          </Link>
                          <IconButton
                            aria-label="delete"
                            className="text-14"
                            onClick={() => handleClickOpen(song._id)}
                          >
                            <DeleteIcon fontSize="medium" />
                          </IconButton>
                          <FormControlLabel
                            control={<Switch checked={song.isPaid}

                              color="primary"
                              onChange={(e) => {
                                handleChange(e, song._id);
                              }}
                              name="isPaid" />}
                            label="Exclusive"
                            labelPlacement="left"

                          />
                        </CardActions>
                        <LinearProgress
                          className="w-full"
                          variant="determinate"
                          value={100}
                          color="secondary"
                        />
                      </Card>
                    </div>
                  );
                })}
                <div className="flex flex-1 items-center justify-center">
                  {load ? <Button variant="outlined" size="medium" color="primary" className={classes.margin} onClick={() => loadmore()}>
                    Load more..
                  </Button>
                    :
                    ''
                  }

                </div>
              </FuseAnimateGroup>

            ) : (
              <div className="flex flex-1 items-center justify-center">
                <Typography color="textSecondary" className="text-24 my-24">
                  No stories found!
                </Typography>
              </div>
            )),
          [filteredData, theme.palette]
        )}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Are you sure to delete this item?
        </DialogTitle>

        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleDelete} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Stories;
