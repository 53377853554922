import React, { createElement } from 'react';
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.js"
import { connect } from 'react-redux';
import axios from 'axios';
//import {baseLink} from "./helper";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

//const baseLink = process.env.REACT_APP_BACKEND_URL;
const baseLink = "https://royakids.tv:4000";
var wavesurfer = "";

class PageAudio2 extends React.Component {
    state = { open4: false, open5: false, open6: false, slicePop: false }
    regionCount = 0;
    spansCount = 0;
    speedHandle = (e) => {
        let rate = parseFloat(e.target.value);
        wavesurfer.setPlaybackRate(rate);
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.page_count != this.props.page_count) {
            wavesurfer.empty();
            wavesurfer.clearRegions();

            this.viewSlicing();
        }
        if (this.props.page_count == 0) {
            if (prevProps.book != this.props.book) {
                wavesurfer.empty();
                wavesurfer.clearRegions();
                this.viewSlicing();
            }
        }
        if (this.props.clearSlicing == 1) {
            wavesurfer.empty();
            wavesurfer.clearRegions();
            this.props.dispatch({ type: "update_slicing", clearSlicing: 0 });
        }
        if (this.props.clearSlicing1 == 1) {
            this.savePage("clear");
            this.props.dispatch({ type: "update_slicing1", clearSlicing1: 0 });
        }
    }
    viewSlicing = () => {
        let opf_dir = this.props.opf_dir;
        let page_count = this.props.page_count;
        let book = this.props.book;
        let pageType = book.pages[page_count].pageAudioType;
        let smilAudios = book.pages[page_count].smilAudios;
        let spans = "";
        let th = this;

        let region_count = 0;

        if (pageType == "oneAudio") {
            let audio = opf_dir + smilAudios.audioLink;
            wavesurfer.load(audio);
            //wavesurfer.clearRegions();

            /* spans = smilAudios.spans;
             spans.forEach(function(span) {
                 let mainId = span.id;
                 let points = span.points;
                 points.forEach(function(singlePoint) {
                         let r = Math.random() * (120 - 0) + 0;
                         let g = Math.random() * (120 - 0) + 0;
                         let b = Math.random() * (120 - 0) + 0;
         
                         wavesurfer.addRegion({
                             id: singlePoint.textId,
                             start: singlePoint.start, // time in seconds
                             end: singlePoint.end, // time in seconds
                             //color: 'rgba('+ r +','+ g +','+ b + ',0.3)',
                             color: 'rgba('+ r +','+ g +','+ b + ',0.35)',
                             // Note: we are preventing the first region (green) from being moved or resized.
                             drag: true,
                             resize: true
                         });
                         region_count++;
                         console.log(region_count);
                 });
 
             });*/
        }
        else if (pageType == "paragraphs") {

            wavesurfer.empty();
            wavesurfer.clearRegions();

            book.pages[page_count].smilAudios.forEach(function (item, itemIndx) {
                item.spans.forEach(function (span, spanIndx) {
                    // let mainId = span.id;                
                    if (document.getElementById(span.id) != null || document.getElementById(span.id) != undefined) {
                        var aTag = document.createElement("a");
                        aTag.id = `img-${span.id}---${itemIndx}---${spanIndx}`;
                        aTag.innerHTML = "edit";
                        aTag.classList.add('edit-text');
                        aTag.href = '#';
                        //   aTag.addEventListener("click", th.editLine2);

                        //  document.getElementById(span.id).appendChild(aTag);
                        // document.getElementById(span.id).innerHTML += `<a class='edit-text' href='#' id='img-${span.id}---${itemIndx}---${spanIndx}'>edit</a>`;
                    }

                });
                /*
                                let mainId = span.id;                
                                if(document.getElementById(span.id) != null || document.getElementById(span.id) != undefined) {
                                    document.getElementById(span.id).innerHTML += "<a class='edit-text' href='#' id='img-" + mainId +"'>edit</a>";
                
                                }*/

            });

        }
    }

    editLine2 = (id) => {

        this.props.dispatch({ type: "update_disbaled", saveDisabled: "" })

        //let id = e.target.id;
        id = id.replace("img-", "");

        let params = id.split("---");
        let book = this.props.book;
        let page_count = this.props.page_count;

        var url_string = window.location.href;
        var url = new URL(url_string);
        let bookpageId = url.searchParams.get("id");

        let audioLink = "https://royakids.tv/frontend/static/" + bookpageId + "/OEBPS" + book.pages[page_count].smilAudios[params[1]].audioLink;

        //console.log(book.pages[page_count].smilAudios[params[1]].spans[params[2]])

        wavesurfer.empty();
        wavesurfer.clearRegions();

        this.props.dispatch({ type: "update_para", paraSmilId: params[1], paraSpanId: params[2] });
        wavesurfer.load(audioLink);


        // e.preventDefault();
    }
    editLine = (e) => {
        let th = this;
        let id = e.target.id;
        id = id.replace("img-", "");
        let book = this.props.book;
        let page_count = this.props.page_count;
        let spans = book.pages[page_count].smilAudios.spans;
        wavesurfer.clearRegions();
        spans.forEach(function (item, spanIndx) {
            if (item.id == id) {
                let currentTime = 0;
                item.points.forEach(function (point, pointIndx) {
                    let r = Math.random() * (120 - 0) + 0;
                    let g = Math.random() * (120 - 0) + 0;
                    let b = Math.random() * (120 - 0) + 0;
                    wavesurfer.addRegion({
                        id: "c" + pointIndx,
                        start: point.start, // time in seconds
                        end: point.end, // time in seconds
                        //color: 'rgba('+ r +','+ g +','+ b + ',0.3)',
                        color: 'rgba(' + r + ',' + g + ',' + b + ',0.4)',
                        // Note: we are preventing the first region (green) from being moved or resized.
                        drag: false,
                        resize: true
                    });
                    currentTime = point.end;
                    // th.props.dispatch({type:"point_selected",spanIndx:spanIndx});
                });
                th.props.dispatch({ type: "point_selected", spanIndx: spanIndx });
                th.props.dispatch({ type: "edit_line", editLineText: true })
                th.props.dispatch({ type: "update_point_index", pointIndex: false });
                th.props.dispatch({ type: "add_region", startTime: currentTime });
            }
        });
    }
    addRegions = () => {
        let opf_dir = this.props.opf_dir;
        let page_count = this.props.page_count;
        let book = this.props.book;
        let pageType = book.pages[page_count].pageAudioType;
        let smilAudios = book.pages[page_count].smilAudios;
        let spans = "";
        let region_count = 0;
        let th = this;
        if (pageType == "oneAudio") {
            spans = smilAudios.spans;

            if (spans == undefined) {
                return;
            }
            spans.forEach(function (span) {
                let mainId = span.id;
                if (document.getElementById(span.id) != null || document.getElementById(span.id) != undefined) {
                    document.getElementById(span.id).innerHTML += "<a class='edit-text' href='#' id='img-" + mainId + "'>edit</a>";
                }

                var elems = document.getElementsByClassName("edit-text");
                for (var i = 0; i < elems.length; i++) {
                    // elems[i].onclick = th.editLine2;
                }

                let points = span.points;
                points.forEach(function (singlePoint) {


                    let r = Math.random() * (120 - 0) + 0;
                    let g = Math.random() * (120 - 0) + 0;
                    let b = Math.random() * (120 - 0) + 0;



                    wavesurfer.addRegion({
                        id: singlePoint.textId,
                        start: singlePoint.start, // time in seconds
                        end: singlePoint.end, // time in seconds
                        //color: 'rgba('+ r +','+ g +','+ b + ',0.3)',
                        color: 'rgba(' + r + ',' + g + ',' + b + ',0.4)',
                        // Note: we are preventing the first region (green) from being moved or resized.
                        drag: false,
                        resize: true
                    });
                    region_count++;
                });

            });

        }
        else if (pageType == "paragraphs") {

            let paraSmilId = th.props.paraSmilId;
            let paraSpanId = th.props.paraSpanId;
            if (paraSmilId != "" && paraSpanId != "") {
                let points = book.pages[page_count].smilAudios[paraSmilId].spans[paraSpanId].points;
                points.forEach(function (singlePoint) {


                    let r = Math.random() * (120 - 0) + 0;
                    let g = Math.random() * (120 - 0) + 0;
                    let b = Math.random() * (120 - 0) + 0;



                    wavesurfer.addRegion({
                        id: singlePoint.textId,
                        start: singlePoint.start, // time in seconds
                        end: singlePoint.end, // time in seconds
                        //color: 'rgba('+ r +','+ g +','+ b + ',0.3)',
                        color: 'rgba(' + r + ',' + g + ',' + b + ',0.4)',
                        // Note: we are preventing the first region (green) from being moved or resized.
                        drag: false,
                        resize: true
                    });
                    region_count++;

                    th.props.dispatch({ type: "add_region", startTime: singlePoint.end });

                });

            }




        }
    }
    componentDidMount() {
        let th = this;
        /*
                setTimeout(() => {
        
                    let spansList = th.props.spansList;
                    let page_count = th.props.page_count;
                    let selectedSpans = spansList[page_count];
                    selectedSpans.forEach((element,indx) => {
                        document.getElementById(element.id).innerHTML += `<span><input class='checkbox-count' value='${element.id}' type='checkbox' name='trest' /></span>`;
                    });
            
                }, 1000);*/


        document.addEventListener('change', function (e) {
            if (window.location.pathname.search("/edit_audio") !== -1) {

                //let spansList = th.props.spansList;
                //let page_count = th.props.page_count;
                // let currentSpansList = spansList[page_count];
                let selected_sp = th.props.selected_sp;
                /* if(e.target.classList.contains('checkbox-count')) {
                    // console.log(e.target.checked);
     
                     if(e.target.checked == true) {
                         selected_sp.push(e.target.value);
     
                         if(document.querySelectorAll(`#${e.target.value} .count-txt`).length != 0) {
                             document.querySelectorAll(`#${e.target.value} .count-txt`)[0].remove();
                         }
                         let span = document.createElement("span");
                         span.classList.add("count-txt");
                         span.innerHTML = selected_sp.length;
                         document.getElementById(e.target.value).appendChild(span);
                         //document.getElementById(e.target.value).innerHTML = "<div class='count-txt'>1</div>" + document.getElementById(e.target.value).innerHTML;
     
                     } else {
                         if(document.querySelectorAll(`#${e.target.value} .count-txt`).length != 0) {
                             document.querySelectorAll(`#${e.target.value} .count-txt`)[0].remove();
                         }
                         let selectedId = selected_sp.indexOf(e.target.value);
                         if(selectedId != -1) {
                             selected_sp.splice(selectedId,1);
                         }
                     }
                     th.props.dispatch({type:"update_selected_sp",selected_sp:selected_sp});
                 }*/
            }
        });

        document.addEventListener('click', function (e) {
            if (window.location.pathname.search("/edit_audio") !== -1) {

                let pageType = th.props.pageType;
                let spansList = th.props.spansList;
                let page_count = th.props.page_count;
                let selected_sp = spansList[page_count];

                if (e.target.classList.contains("edit-text")) {
                    e.preventDefault();

                    th.editLine2(e.target.id);
                }
                if (e.target.classList.contains('checkbox-count')) {
                    let selected_sp = th.props.selected_sp;
                    // console.log(e.target.checked);
                    let value = e.target.getAttribute("value");
                    let txt = e.target.innerText;
                    if (txt == "✗") {
                        e.target.innerHTML = "&check;";
                        e.target.style.backgroundColor = "rgba(79, 199, 79, 0.51)";
                    }
                    else {
                        e.target.innerHTML = "&cross;";
                        e.target.style.backgroundColor = "red";
                    }
                    if (e.target.innerHTML != "✗") {
                        selected_sp.push(value);

                        if (document.querySelectorAll(`#${value} .count-txt`).length != 0) {
                            document.querySelectorAll(`#${value} .count-txt`)[0].remove();
                        }
                        let span = document.createElement("span");
                        span.classList.add("count-txt");
                        span.innerHTML = selected_sp.length;
                        document.getElementById(value).appendChild(span);
                        //document.getElementById(e.target.value).innerHTML = "<div class='count-txt'>1</div>" + document.getElementById(e.target.value).innerHTML;

                    } else {
                        if (document.querySelectorAll(`#${value} .count-txt`).length != 0) {
                            document.querySelectorAll(`#${value} .count-txt`)[0].remove();
                        }
                        let selectedId = selected_sp.indexOf(value);
                        if (selectedId != -1) {
                            selected_sp.splice(selectedId, 1);
                        }
                    }
                    th.props.dispatch({ type: "update_selected_sp", selected_sp: selected_sp });
                }
            }

            /*if(e.target.classList.contains("word-syncing")) {
                let parent_id = e.target.parentElement.id;
                if(pageType == "oneAudio") {
                
                    document.getElementById(parent_id).classList.add("yellow-hilight2");*/


            //document.getElementById(parent_id).innerHTML += "<span>1</span>";
            //let id = e.target.id;
            //let value = e.target.innerText;
            //let obj = {};
            //obj.id = parent_id;
            //obj.value = e.target.parentElement.innerText;
            //let check = selected_sp.findIndex((element) => { return element.id == parent_id })

            /*if(check == -1) {
                selected_sp.push(obj);
                th.props.dispatch({type:"update_selected_sp",selected_sp:selected_sp});
            }*/


            /* let yellow_list = document.querySelectorAll(".yellow-hilight2");
             if(yellow_list.length == 2) {
                 var firstindx = selected_sp.findIndex((element) => { return element.id == yellow_list[0].id });
                 
                 var secondindx = selected_sp.findIndex((element) => { return element.id == yellow_list[1].id });

                 var firstobj = selected_sp[firstindx];
                 var secondobj = selected_sp[secondindx];

                 selected_sp[firstindx] = secondobj;
                 selected_sp[secondindx] = firstobj;
                 document.querySelectorAll(".count").forEach(function(elem) {
                     elem.remove();
                 });
                 selected_sp.forEach(function(elem,indx) {
                     document.getElementById(elem.id).innerHTML += "<span class='count'>"+ (indx + 1) +"</span>";
                     document.getElementById(elem.id).classList.remove("yellow-hilight2");
                     document.getElementById(elem.id).classList.add("red-hilight");
                 });
                 spansList[page_count] = selected_sp;
                th.props.dispatch({type:"update_spanslist",spansList:spansList});

             }
         }
         
     }*/
        });



        wavesurfer = WaveSurfer.create({
            container: '#waveform',
            waveColor: '#6CCEFB',
            progressColor: '#6CCEFB',
            currentTime: 0,
            // backend: 'MediaElement',
            audioRate: 1,
            plugins: [
                RegionsPlugin.create({
                    // plugin options ...
                }),
                TimelinePlugin.create({
                    container: "#wave-timeline",
                    timeInterval: 0.05,
                    fontSize: 16
                })
            ]
        });
        document.querySelector('#slider').oninput = function () {
            wavesurfer.zoom(Number(this.value));
            //wavesurfer.params.audioRate = 0.2;
            // console.log(wavesurfer);
        };


        wavesurfer.disableDragSelection();
        wavesurfer.on('ready', function () {
            let slicing = th.props.slicing;
            if (slicing.length != 0) {
                th.addRegionFirst();
            }
            th.addRegions();



            //let slicing = data.slicing;

            //let slicing = JSON.parse('{"result":{"results":[{"alternatives":[{"timestamps":[["they",0.32,0.59],["lost",0.59,1],["their",1,1.17],["cat",1.17,1.82],["they",2.18,2.45],["lost",2.45,2.89],["Kathy",2.92,3.53]],"confidence":0.78,"transcript":"they lost their cat they lost Kathy "}],"final":true}],"result_index":0},"file":"/static/book_1/OEBPS/audio/taffy.mp3","file2":"audio/taffy.mp3"}');
            //slicing = slicing.result.results;


            //wavesurfer.enableDragSelection({});
            // wavesurfer.play(0, 4);
            /*
            wavesurfer.on('region-updated', function(region, e) {
                let list = Object.values(wavesurfer.regions.list);
                let keys = Object.keys(wavesurfer.regions.list);
                let regionId = region.id; 
                regionId = regionId.replace("r","");
                let nextregion = parseInt(regionId);
                let prevregion = parseInt(regionId);
                nextregion++;
                prevregion--;
                let current_key = keys.indexOf(regionId);

                if(current_key != -1) {
                    let next_key= current_key + 1;
                    let prev_key= current_key - 1;
                    console.log(prev_key);
                    console.log(current_key);
                    console.log(next_key);

                    if(prev_key != -1) {
                        wavesurfer.regions.list[keys[prev_key]].end = wavesurfer.regions.list[keys[current_key]].start;
                    }
                    if(next_key != list.length) {
                        wavesurfer.regions.list[keys[next_key]].start = wavesurfer.regions.list[keys[current_key]].end;
                    }
                }
            });*/

            wavesurfer.on('region-update-end', function (region, e) {

                let id = region.id;

                let keys = Object.keys(wavesurfer.regions.list);
                let values = Object.values(wavesurfer.regions.list);
                let indx = keys.indexOf(id);
                let book = th.props.book;
                let page_count = th.props.page_count;

                // let curRegion = wavesurfer.regions.list.indexOf()

                if ((indx - 1) == -1) {
                    return;
                }
                values[indx - 1].update({
                    end: region.start
                })
                let startEnd = values[values.length - 1].end;
                th.props.dispatch({ type: "add_region", startTime: startEnd });
                let editLineText = th.props.editLineText;
                if (book.pages[page_count].pageAudioType == "oneAudio") {




                }


                let paraSmilId = th.props.paraSmilId;
                let paraSpanId = th.props.paraSpanId;

                if (paraSmilId != "" && paraSpanId != "") {

                    book.pages[page_count].smilAudios[paraSmilId].spans[paraSpanId].points = [];
                    //book.pages[page_count].smilAudios.spans[spanIndx].points = [];
                    values.forEach(function (item, indx) {
                        let selectedId = book.pages[page_count].smilAudios[paraSmilId].spans[paraSpanId].id;
                        book.pages[page_count].smilAudios[paraSmilId].spans[paraSpanId].points[indx] = {};
                        book.pages[page_count].smilAudios[paraSmilId].spans[paraSpanId].points[indx].start = item.start;
                        book.pages[page_count].smilAudios[paraSmilId].spans[paraSpanId].points[indx].end = item.end;
                        book.pages[page_count].smilAudios[paraSmilId].spans[paraSpanId].points[indx].textId = selectedId + "-" + indx;

                    });
                    th.props.dispatch({ type: "update_book2", book: book })
                }

                /* if(document.querySelectorAll(".links-right select")[0].disabled == true && editLineText == true) {
                     let book = th.props.book;
                     let page_count = th.props.page_count;
                     let spanIndx = th.props.spanIndx;
                     let smilAudiosSpans = book.pages[page_count].smilAudios.spans[spanIndx];
                     let selectedId = book.pages[page_count].smilAudios.spans[spanIndx].id;
                     book.pages[page_count].smilAudios.spans[spanIndx].points = [];
                     values.forEach(function(item,indx) {
                         if(book.pages[page_count].smilAudios.spans[spanIndx].points[indx] == undefined) {
                             book.pages[page_count].smilAudios.spans[spanIndx].points[indx] = {};
                         }
                         book.pages[page_count].smilAudios.spans[spanIndx].points[indx].start = item.start;
                         book.pages[page_count].smilAudios.spans[spanIndx].points[indx].end = item.end;
                         book.pages[page_count].smilAudios.spans[spanIndx].points[indx].textId = selectedId + "-" + indx;
                     });
                     th.props.dispatch({type:"update_book2",book:book});*/
                /*if(book.pages[page_count].pageAudioType == "oneAudio") {
                    let smilAudiosSpans = book.pages[page_count].smilAudios.spans;
                    let split = id.lastIndexOf("-");
                    let mainId = id.substring(0,split);
                    let idIndx = parseInt(id.substring(split + 1));
                    smilAudiosSpans.forEach(function(spanItem,indx) {
                        if(spanItem.id == mainId) {

                            book.pages[page_count].smilAudios.spans[indx].points[idIndx].start = region.start;
                            book.pages[page_count].smilAudios.spans[indx].points[idIndx].end = region.end;
                            
                            if(spanItem.points[idIndx - 1] != undefined) {
                                //spanItem.points[idIndx - 1].end = region.start;
                                book.pages[page_count].smilAudios.spans[indx].points[idIndx - 1].end = region.start;
                            }
                            if(spanItem.points[idIndx + 1] != undefined) {
                               // spanItem.points[idIndx + 1].start = region.end;
                                book.pages[page_count].smilAudios.spans[indx].points[idIndx + 1].start = region.end;
                            }
                        }
                    });
                    th.props.dispatch({type:"update_book2",book:book})
                }
                */

                // }

            });

            wavesurfer.on('region-click', function (region, e) {
                // console.log(region.start); 
                //console.log(region.end); 

                if (document.getElementById(region.id) != null) {
                    document.querySelectorAll(".yellow-hilight2").forEach(function (item) {
                        item.classList.remove("yellow-hilight2");
                    });
                    document.getElementById(region.id).classList.add("yellow-hilight2");

                }
                /* region.update({
                     color:"rgba(0,0,0,0.35)"
                 });*/
                // region.
                e.stopPropagation();
                th.setState({
                    selectedRegion: region
                });
                let endTime = region.end;
                th.props.dispatch({ type: "add_region", startTime: endTime });
                wavesurfer.play(region.start, region.end);

            });
            wavesurfer.on('pause', function (region, e) {
                document.querySelectorAll(".yellow-hilight2").forEach(function (item) {
                    item.classList.remove("yellow-hilight2");
                });
            });
        });



        document.addEventListener('keydown', function (e) {
            //delete 

            if (window.location.pathname.search("/edit_audio") !== -1) {
                if (e.keyCode == 46) {
                    let regionList = Object.values(wavesurfer.regions.list);
                    let endTime = regionList[regionList.length - 1].end;
                    let selectedRegion = th.state.selectedRegion;
                    if (selectedRegion != "") {
                        selectedRegion.remove();
                        th.setState({
                            selectedRegion: ""
                        });
                    }
                    //th.props.dispatch({type : "add_region",startTime:endTime});


                    let editLineText = th.props.editLineText;
                    let keys = Object.keys(wavesurfer.regions.list);
                    let values = Object.values(wavesurfer.regions.list);
                    let paraSmilId = th.props.paraSmilId;
                    let paraSpanId = th.props.paraSpanId;
                    let page_count = th.props.page_count;
                    let book = th.props.book;

                    if (paraSmilId != "" && paraSpanId != "") {

                        book.pages[page_count].smilAudios[paraSmilId].spans[paraSpanId].points = [];
                        //book.pages[page_count].smilAudios.spans[spanIndx].points = [];
                        values.forEach(function (item, indx) {
                            let selectedId = book.pages[page_count].smilAudios[paraSmilId].spans[paraSpanId].id;
                            book.pages[page_count].smilAudios[paraSmilId].spans[paraSpanId].points[indx] = {};
                            book.pages[page_count].smilAudios[paraSmilId].spans[paraSpanId].points[indx].start = item.start;
                            book.pages[page_count].smilAudios[paraSmilId].spans[paraSpanId].points[indx].end = item.end;
                            book.pages[page_count].smilAudios[paraSmilId].spans[paraSpanId].points[indx].textId = selectedId + "-" + indx;

                        });
                        th.props.dispatch({ type: "update_book2", book: book })
                    }

                    /*  if(document.querySelectorAll(".links-right select")[0].disabled == true && editLineText == true) {
                          let book = th.props.book;
                          let page_count = th.props.page_count;
                          let spanIndx = th.props.spanIndx;
                          let smilAudiosSpans = book.pages[page_count].smilAudios.spans[spanIndx];
                          let selectedId = book.pages[page_count].smilAudios.spans[spanIndx].id;
                          book.pages[page_count].smilAudios.spans[spanIndx].points = [];
                          values.forEach(function(item,indx) {
                              if(book.pages[page_count].smilAudios.spans[spanIndx].points[indx] == undefined) {
                                  book.pages[page_count].smilAudios.spans[spanIndx].points[indx] = {};
                              }
                              book.pages[page_count].smilAudios.spans[spanIndx].points[indx].start = item.start;
                              book.pages[page_count].smilAudios.spans[spanIndx].points[indx].end = item.end;
                              book.pages[page_count].smilAudios.spans[spanIndx].points[indx].textId = selectedId + "-" + indx;
                          });
                          th.props.dispatch({type:"update_book2",book:book});
                          let currentTime = 0;
                          if(values.length != 0) {
                              currentTime = values[values.length - 1].end;
                          }
      
                          th.props.dispatch({type:"add_region",startTime:currentTime});
                      }*/
                    e.preventDefault();

                }

                console.log(e)

                //spacebar
                if (e.code == 'Space') {



                    let startTime = th.props.startTime;
                    let endTime = wavesurfer.getCurrentTime();
                    let regionCount = th.regionCount;
                    //endTime = endTime + 0.05;



                    let r = Math.random() * (120 - 0) + 0;
                    let g = Math.random() * (120 - 0) + 0;
                    let b = Math.random() * (120 - 0) + 0;

                    /* wavesurfer.addRegion({
                         id: "r" + regionCount,
                         start: startTime, // time in seconds
                         end: endTime, // time in seconds
                         color: 'rgba('+ r +','+ g +','+ b + ',0.4)',
                         // Note: we are preventing the first region (green) from being moved or resized.
                         drag: false,
                         resize: true
                     });*/
                    regionCount++;
                    let newRegion = {};
                    newRegion.start = startTime;
                    newRegion.end = endTime;
                    newRegion.color = 'rgba(' + r + ',' + g + ',' + b + ',0.4)';

                    th.regionCount = regionCount;

                    let regions = Object.values(wavesurfer.regions.list);
                    regions.push(newRegion);

                    let regions2 = regions.sort(function (obj1, obj2) {
                        return obj1.start - obj2.start;
                    });

                    regions2.forEach((item, i) => {
                        if (regions2[i - 1] !== undefined) {
                            regions2[i].start = regions2[i - 1].end;
                        }
                        // if(regions2[i + 1] !== undefined) {
                        //     regions2[i].end = regions2[i + 1].start;
                        // }
                    });

                    console.log(regions2)
                    wavesurfer.clearRegions();

                    regions2.forEach(function (regionItem, indx) {
                        let r = Math.random() * (120 - 0) + 0;
                        let g = Math.random() * (120 - 0) + 0;
                        let b = Math.random() * (120 - 0) + 0;

                        wavesurfer.addRegion({
                            id: "r" + indx,
                            start: regionItem.start, // time in seconds
                            end: regionItem.end, // time in seconds
                            color: regionItem.color,
                            // Note: we are preventing the first region (green) from being moved or resized.
                            drag: false,
                            resize: true
                        });
                    });



                    th.props.dispatch({ type: "add_region", startTime: endTime });

                    e.preventDefault();
                    //aud.pause(); 

                    let editLineText = th.props.editLineText;

                    /*if(document.querySelectorAll(".links-right select")[0].disabled == true && editLineText == true) {
                        let values = Object.values(wavesurfer.regions.list);    
                        let book = th.props.book;
                        let page_count = th.props.page_count;
                        let spanIndx = th.props.spanIndx;
                        let smilAudiosSpans = book.pages[page_count].smilAudios.spans[spanIndx];
                        let selectedId = book.pages[page_count].smilAudios.spans[spanIndx].id;
                        book.pages[page_count].smilAudios.spans[spanIndx].points = [];
                        values.forEach(function(item,indx) {
                            if(book.pages[page_count].smilAudios.spans[spanIndx].points[indx] == undefined) {
                                book.pages[page_count].smilAudios.spans[spanIndx].points[indx] = {};
                            }
                            book.pages[page_count].smilAudios.spans[spanIndx].points[indx].start = item.start;
                            book.pages[page_count].smilAudios.spans[spanIndx].points[indx].end = item.end;
                            book.pages[page_count].smilAudios.spans[spanIndx].points[indx].textId = selectedId + "-" + indx;
                        });
                        th.props.dispatch({type:"update_book2",book:book});
                    }*/
                }
            }
        });
    }

    addRegionFirst = (e) => {
        let th = this;
        let odd = true;
        let count = 0;
        let colorCount = 0;

        let slicing = th.props.slicing;

        slicing.forEach(function (sliceItem, indx) {
            sliceItem.alternatives[0].timestamps.forEach(function (item) {

                let startSecond = parseFloat(item[1]);
                let endSecond = parseFloat(item[2]);
                let colors = 'rgba(0, 125, 0, 0.4)';

                if (colorCount == 0) {
                    colors = 'rgba(125, 0, 0, 0.4)';
                }
                if (colorCount == 1) {
                    colors = 'rgba(0, 0, 125, 0.4)';
                }
                if (colorCount == 2) {
                    colors = 'rgba(0, 125, 125, 0.4)';
                }
                if (colorCount == 3) {
                    colors = 'rgba(125, 125, 0, 0.4)';
                    colorCount = -1;
                }
                colorCount++;
                /*
                            if(odd == true) {
                                colors = 'rgba(125, 0, 0, 0.4)';
                                odd = false;
                            }
                            else {
                                odd = true;
                            }*/

                wavesurfer.addRegion({
                    id: "c" + count,
                    start: startSecond, // time in seconds
                    end: endSecond, // time in seconds
                    color: colors,
                    // Note: we are preventing the first region (green) from being moved or resized.
                    drag: false,
                    resize: true
                });
                count++;

            });


        });
        let selected_sp_count = 0;
        let selected_sp = th.props.selected_sp;
        selected_sp.forEach(function (mainSpan) {
            //if(mainSpan.value.length > 2) {
            let minispans = document.querySelectorAll("span[id^='" + mainSpan + "-'").length;
            selected_sp_count += minispans;
        });
        if (count > selected_sp_count) {

            th.setState({
                slicePopMsg: "The auto slice audio returned greater number of words. please check",
                slicePop: true
            });

            let regionsList = wavesurfer.regions.list;
            while (count >= selected_sp_count) {
                if (regionsList[`c${count}`] != undefined) {
                    // regionsList[`c${count}`].remove();
                }
                count--;
            }



        }
        else if (count < selected_sp_count) {
            th.setState({
                slicePopMsg: "The auto slice audio returned less number of words. please check",
                slicePop: true
            });
        }



        let regions = wavesurfer.regions.list;
        th.props.dispatch({ type: "update_regions", regions: regions });
        th.props.dispatch({ type: "slicing_update", slicing: [] });

    }
    selectText2 = (e) => {

    }
    uploadAudio = (e) => {
        let th = this;

        if (e.target.files.length == 0) {
            return;

        }

        th.setState({
            open4: true
        });




        /*this.setState({
            uploadAudio:e.target.files[0],
            audioName:e.target.files[0].name
        });*/
        // this.uploadAudioFile = e.target.files[0];
        //this.props.audioName = e.target.files[0].name;
        /*this.setState({
            audioName:e.target.files[0].name
        })*/
        //this.props.audioName = e.target.files[0].name;
        if (e.target.files[0] == undefined) {
            return;
        }
        this.props.dispatch({ type: "update_point_index", pointIndex: false });

        let filename = "audio_" + Math.floor(new Date().getTime() / 1000) + ".mp3";

        this.props.dispatch({ type: "select_audio", audioObj: e.target.files[0], audioName: filename, selectedPara: 1 });
        setTimeout(() => {
            this.saveUpdates(e, filename);


            document.getElementById("file-upload").value = "";
        }, 500);
    }

    saveUpdates = (e, filename) => {
        let th = this;

        /*  document.querySelectorAll(".checkbox-count").forEach(function(element) {
              element.checked = false;
          });*/
        //th.props.dispatch({type:"update_selected_sp",selected_sp:[]});

        var url_string = window.location.href;
        var url = new URL(url_string);
        let opf_link = url.searchParams.get("opf_file");
        let lang = url.searchParams.get("lang");

        let opf_dir = opf_link.replace("content.opf", "");
        var bodyFormData = new FormData();

        bodyFormData.set('sampleFile', this.props.audioObj);
        bodyFormData.set('base_path', opf_dir);
        bodyFormData.set('lang', lang);
        bodyFormData.set('filename', filename);
        axios({
            method: 'post',
            url: baseLink + '/upload_audio2',
            data: bodyFormData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        }).then(function (res) {
            console.log('111')
            let data = res.data;
            let audioSrc = "https://royakids.tv" + data.file;
            let audio2 = data.file2;
            let slicing = data.result.result.results;

            th.props.dispatch({ type: "slicing_update", slicing: slicing });
            wavesurfer.load(audioSrc);
            wavesurfer.clearRegions();
            th.props.dispatch({ type: "add_region", startTime: 0 })

            th.setState({
                //audioDuration:data.duration,
                audioSrc: audioSrc,
                audio2: audio2
            });
            th.props.dispatch({ type: "update_disbaled", saveDisabled: "" })


            //create regions



            /*
                            slicing.forEach(function(sliceItem,indx) {
                                let startSecond = sliceItem.startTime.seconds + "." + sliceItem.startTime.nanos;
                                let endSecond = sliceItem.endTime.seconds + "." + sliceItem.endTime.nanos;
                                let colors = 'rgba(0, 0, 0, 0.4)';
                                if(odd == true) {
                                    colors = 'rgba(32, 41, 80, 0.4)';
                                    odd = false;
                                }
                                else {
                                    odd = true;
                                }
            
                                    wavesurfer.addRegion({
                                        id: "c" + indx,
                                        start: startSecond, // time in seconds
                                        end: endSecond, // time in seconds
                                        color: colors,
                                        // Note: we are preventing the first region (green) from being moved or resized.
                                        drag: false,
                                        resize: true
                                    });
                               
                                
                        
                            });*/
            //

            let spansList = th.props.spansList;
            let page_count = th.props.page_count;
            let selectedSpans = spansList[page_count];
            th.spansCount = 0;

            let selectedId = selectedSpans[th.spansCount].id;

            selectedSpans.forEach((element, indx) => {
                // if(element.value.length > 2) {
                //document.getElementById(element.id).classList.add("red-hilight")
                // document.getElementById(element.id).innerHTML += `<input class='checkbox-count' value='${element.id}' type='checkbox' />`;
                //   }
            });

            th.props.dispatch({ type: "update_txt", selectid: selectedId })

            /* if(th.props.pageType == "oneAudio") {
                
             }
             else {
                 document.getElementById("root").style.userSelect = "none";
                 th.props.selectedIds.forEach(function(item,indx) {
                     if(indx == 0) {
//                            document.getElementById(item.id).style.backgroundColor = "yellow";
                         document.getElementById(item.id).classList.add("yellow-hilight");        
                     }
                     else {
                         document.getElementById(item.id).classList.add("red-hilight");        

                     }
                 });
             }*/


            setTimeout(() => {
                th.setState({
                    open4: false
                });
            }, 2000);
        })
            .catch(function (response) {
            });
    }
    playAll = (e) => {
        /* this.setState({
             startTime:wavesurfer.getCurrentTime()
         });*/
        wavesurfer.play();
        document.getElementsByClassName("play-bt")[0].style.display = "none";
        document.getElementsByClassName("pause-bt")[0].style.display = "block";
    }
    pauseAll = (e) => {
        /* this.setState({
             startTime:wavesurfer.getCurrentTime()
         });*/
        wavesurfer.pause();
        document.getElementsByClassName("play-bt")[0].style.display = "block";
        document.getElementsByClassName("pause-bt")[0].style.display = "none";
    }
    skipText = (e) => {
        let th = this;
        let selectid = this.props.selectid;
        let page_count = this.props.page_count;
        let spansList = this.props.spansList;
        let selectedIds = this.props.selectedIds;
        let selectedIndex = this.props.selectedIndex;

        if (this.props.pageType == "paragraphs") {
            selectid = selectedIds[selectedIndex].id;
            selectedIndex++;
            this.props.dispatch({ type: "update_select", selectedIndex: selectedIndex });


            let selectedIndex3 = th.props.selectedIndex;
            selectedIndex3++;

            document.getElementById(th.props.selectedIds[selectedIndex3 - 1].id).classList.add("red-hilight");
            document.getElementById(th.props.selectedIds[selectedIndex3 - 1].id).classList.remove("yellow-hilight");

            if (th.props.selectedIds[selectedIndex3] != undefined) {
                document.getElementById(th.props.selectedIds[selectedIndex3].id).classList.add("yellow-hilight");
                document.getElementById(th.props.selectedIds[selectedIndex3].id).classList.remove("red-hilight");
            }
            if (selectedIndex3 == th.props.selectedIds.length) {
                document.getElementById("root").style.userSelect = "auto";
                if (document.querySelectorAll(".red-hilight").length > 0) {
                    document.querySelectorAll(".red-hilight").forEach(function (item) {
                        item.classList.remove("red-hilight");
                    });
                }

            }


            return;
        }

        if (selectid == "") {
            return;
        }


        let selectedSpans = spansList[page_count];


        document.getElementById(selectid).style.backgroundColor = "";
        this.spansCount++;
        if (selectedSpans[this.spansCount] != undefined) {
            let selectedId = selectedSpans[this.spansCount].id;
            document.getElementById(selectedId).style.backgroundColor = "red";
            this.props.dispatch({ type: "update_txt", selectid: selectedId });
        }
    }
    saveText2 = (e) => {
        let th = this;


        let regions = Object.values(wavesurfer.regions.list);
        let regionIndx = 0;
        let spansList = th.props.spansList;
        let pageType = th.props.pageType;



        let selected_sp_count = 0;



        let page_count = th.props.page_count;
        let audioName = this.props.audioName;
        let book = this.props.book;
        let audios = th.props.audios;

        let selected_sp = th.props.selected_sp;

        let paraSmilId = th.props.paraSmilId;
        let paraSpanId = th.props.paraSpanId;



        if (paraSmilId == "" && paraSpanId == "") {
            selected_sp.forEach(function (mainSpan) {
                //if(mainSpan.value.length > 2) {
                let minispans = document.querySelectorAll("span[id^='" + mainSpan + "-'").length;
                selected_sp_count += minispans;
            });

            let diff = 0;

            if (selected_sp_count < regions.length) {
                diff = regions.length - selected_sp_count;
                let message = [];
                message.push(<div>The auto slice audio returned greater number of words. please check<br /><div className="diff-item"><strong>Difference: </strong>{diff}</div></div>);
                th.setState({
                    slicePopMsg: message,
                    slicePop: true
                });
                return;
            } else if (selected_sp_count > regions.length) {
                diff = selected_sp_count - regions.length;
                let message = [];
                message.push(<div>The auto slice audio returned less number of words. please check<br /><div className="diff-item"><strong>Difference: </strong>{diff}</div></div>);

                th.setState({
                    slicePopMsg: message,
                    slicePop: true
                });
                return;
            }

            //  this.props.dispatch({type:"update_dff",diff:})
        }
        th.props.dispatch({ type: "update_disbaled", saveDisabled: "disabled" })



        this.setState({
            open: true
        });





        if (paraSmilId != "" && paraSpanId != "") {
            let values = Object.values(wavesurfer.regions.list);

            book.pages[page_count].smilAudios[paraSmilId].spans[paraSpanId].points = [];
            //book.pages[page_count].smilAudios.spans[spanIndx].points = [];
            values.forEach(function (item, indx) {
                let selectedId = book.pages[page_count].smilAudios[paraSmilId].spans[paraSpanId].id;
                book.pages[page_count].smilAudios[paraSmilId].spans[paraSpanId].points[indx] = {};
                book.pages[page_count].smilAudios[paraSmilId].spans[paraSpanId].points[indx].start = item.start;
                book.pages[page_count].smilAudios[paraSmilId].spans[paraSpanId].points[indx].end = item.end;
                book.pages[page_count].smilAudios[paraSmilId].spans[paraSpanId].points[indx].textId = selectedId + "-" + indx;

            });
            th.props.dispatch({ type: "update_book2", book: book })
            th.props.dispatch({ type: "update_para", paraSmilId: "", paraSpanId: "" });
            wavesurfer.empty();
            wavesurfer.clearRegions();

            this.setState({
                open: false
            });
            return;
        }


        var points = [];
        let pointIndx = 0;
        if (book.pages[page_count].smilAudios == undefined || book.pages[page_count].smilAudios == null) {
            book.pages[page_count].smilAudios = [];
        }
        let mainSpanObj = {};
        mainSpanObj.spans = [];

        //  let selectedSpan = spansList[page_count];

        selected_sp.forEach(function (mainSpan) {
            //if(mainSpan.value.length > 2) {

            let minispans = document.querySelectorAll("span[id^='" + mainSpan + "-'");
            minispans.forEach(function (item) {
                let selectedRegion = regions[regionIndx];
                if (selectedRegion != undefined) {
                    points[pointIndx] = {};
                    let point = {};
                    point.start = selectedRegion.start;
                    point.end = selectedRegion.end;
                    point.textId = item.id;
                    points[pointIndx] = point;
                    pointIndx++;
                    regionIndx++;
                }
            });
            let mainObj = {};
            mainObj.id = mainSpan;
            mainObj.points = points;

            mainSpanObj.spans.push(mainObj);
            //book.pages[page_count].smilAudios.spans.push(mainObj);

            // spans.push(mainObj);
            points = [];
            pointIndx = 0;
            //}
        });

        //  book.pages[page_count].smilAudios.spans = spans;

        mainSpanObj.audioLink = "/audio/" + audioName;
        //  book.pages[page_count].smilAudios.audioLink = "/audio/" + audioName; 
        book.pages[page_count].pageAudioType = "paragraphs";
        book.pages[page_count].smilAudios.push(mainSpanObj);
        // console.log(book.pages[page_count].smilAudios)

        if (audios.indexOf("audio/" + audioName) == -1) {
            audios.push("audio/" + audioName);
            th.props.dispatch({ type: "update_audios", audios: audios });
        }

        th.props.dispatch({ type: "update_book2", book: book });

        wavesurfer.empty();
        wavesurfer.clearRegions();

        document.querySelectorAll(".checkbox-count").forEach(function (element) {
            //element.checked = false;
            element.innerHTML = "&cross;";
            element.style.backgroundColor = "red";
        });
        th.props.dispatch({ type: "update_selected_sp", selected_sp: [] });

        document.querySelectorAll(".count-txt").forEach(function (elem) {
            elem.remove();
        });



        book.pages[page_count].smilAudios.forEach(function (item, itemIndx) {
            item.spans.forEach(function (span, spanIndx) {
                // let mainId = span.id;                
                if (document.getElementById(span.id) != null || document.getElementById(span.id) != undefined) {
                    var aTag = document.createElement("a");
                    aTag.id = `img-${span.id}---${itemIndx}---${spanIndx}`;
                    aTag.innerHTML = "edit";
                    aTag.classList.add('edit-text');
                    aTag.href = '#';
                    //   aTag.addEventListener("click", th.editLine2);

                    document.getElementById(span.id).appendChild(aTag);

                    document.querySelector(`#${span.id} .checkbox-count`).remove();

                    // document.getElementById(span.id).innerHTML += `<a class='edit-text' href='#' id='img-${span.id}---${itemIndx}---${spanIndx}'>edit</a>`;
                }

            });
        });

        setTimeout(() => {
            th.setState({
                open: false
            });
            th.props.dispatch({ type: "update_disbaled", saveDisabled: "disabled" })

        }, 1500);

        //}
    }
    saveText = (e) => {
        let page_count = this.props.page_count;

        let audioName = this.props.audioName;
        let book = this.props.book;
        let selectid = this.props.selectid;
        let selectedIds = this.props.selectedIds;

        let spansList = this.props.spansList;
        let selectedSpans = spansList[page_count];
        let currentindex = 0;
        let th = this;




        if (selectid == "" && this.props.pageType == "oneAudio") {
            return;
        }

        if (selectedIds.length == 0 && this.props.pageType == "paragraphs") {
            return;
        }

        if (this.props.pageType == "paragraphs") {
            let selectedIds = this.props.selectedIds;
            let selectedIndex = this.props.selectedIndex;
            selectid = selectedIds[selectedIndex].id;
            selectedIndex++;
            this.props.dispatch({ type: "update_select", selectedIndex: selectedIndex });
        }
        //  

        //console.log(document.getElementById(selectid).childNodes);
        let region_list = Object.values(wavesurfer.regions.list);

        if (this.props.pageType == "oneAudio") {
            document.getElementById(selectid).style.backgroundColor = "";
            this.spansCount++;
            if (selectedSpans[this.spansCount] != undefined) {
                let selectedId = selectedSpans[this.spansCount].id;
                document.getElementById(selectedId).style.backgroundColor = "red";
                this.props.dispatch({ type: "update_txt", selectid: selectedId });
            }
        }




        let pointIndex = this.props.pointIndex;
        let text_arr = {};
        let textPoints = [];
        text_arr.id = "id1";
        let startPoint = 0;
        let startPoint1 = 0;
        let endPoint = 0;
        let words = [];
        let checkPDf = false;

        if (document.querySelectorAll("#parent-p1").length != 0) {
            checkPDf = true;
            document.getElementById(selectid).childNodes.forEach(function (item) {
                if (item.id.search("word") != -1) {
                    words.push(item.id);
                }
            });
        }
        region_list.forEach(function (regionItem, index) {
            let textPoint = {};
            textPoint.start = regionItem.start;
            textPoint.end = regionItem.end;
            if (pointIndex == false) {
                if (index == 0) {
                    startPoint = textPoint.start;
                }
                if (index == region_list.length - 1) {
                    endPoint = textPoint.end;
                }


                if (checkPDf == true) {
                    textPoint.textId = words[index];
                }
                else {
                    textPoint.textId = selectid + "-" + index;
                }

                textPoints.push(textPoint);
            } else {
                if (index == 0) {
                    startPoint1 = textPoint.start;
                }
                if (index == 1) {
                    startPoint = textPoint.start;
                }

                if (index == region_list.length - 1) {
                    endPoint = textPoint.end;
                }
                if (index != 0) {
                    textPoints.push(textPoint);
                }
                if (checkPDf == true) {
                    textPoint.textId = words[index];
                }
                else {
                    textPoint.textId = selectid + "-" + (index - 1);
                }
            }
        });
        // currentindex = book.pages[page_count]['smilAudios'].length;
        if (th.props.pageType == "oneAudio") {
            //console.log(book.pages[page_count]['smilAudios']['spans']); 
            if (book.pages[page_count]['smilAudios']['spans'] != undefined) {
                currentindex = book.pages[page_count]['smilAudios']['spans'].length;
            }

            /* if(book.pages[page_count]['smilAudios'].spans != []) {
                 currentindex = book.pages[page_count]['smilAudios'].spans.length;
             }*/
        }


        if (th.props.pageType == "paragraphs") {
            //document.getElementById("story-page").style.userSelect = "none";


            //let selected = th.props.selectedIds[selectedIndex];
            let selectedIndex3 = th.props.selectedIndex;
            selectedIndex3++;

            document.getElementById(th.props.selectedIds[selectedIndex3 - 1].id).classList.add("red-hilight");
            document.getElementById(th.props.selectedIds[selectedIndex3 - 1].id).classList.remove("yellow-hilight");

            if (th.props.selectedIds[selectedIndex3] != undefined) {
                document.getElementById(th.props.selectedIds[selectedIndex3].id).classList.add("yellow-hilight");
                document.getElementById(th.props.selectedIds[selectedIndex3].id).classList.remove("red-hilight");
            }
            if (selectedIndex3 == th.props.selectedIds.length) {
                document.getElementById("root").style.userSelect = "auto";
                if (document.querySelectorAll(".red-hilight").length > 0) {
                    document.querySelectorAll(".red-hilight").forEach(function (item) {
                        item.classList.remove("red-hilight");
                    });
                }

            }


        }



        // text_arr.points = textPoints;
        // textPoints['tex'] = text_arr;
        //console.log(book.pages[page_count].smilAudios);
        /*current_page.forEach(function(element,index) {
            if(element.id == selectid) {
                ind1 = index;
            }
        });
        
        if(ind1 == "") {
            ind1 = current_page.length;
        }*/


        //book.pages[page_count].smilAudios[ind1] = {}


        let spanObj = {};
        let spanObj2 = {};

        spanObj.spans = [];

        spanObj.spans[currentindex] = {};
        spanObj.duration = wavesurfer.getDuration();
        spanObj.audioLink = "/audio/" + audioName;

        let audios = th.props.audios;

        if (audios.indexOf("audio/" + audioName) == -1) {
            audios.push("audio/" + audioName);
            th.props.dispatch({ type: "update_audios", audios: audios });
        }

        spanObj.spans[currentindex].points = textPoints;
        spanObj.spans[currentindex].id = selectid;

        spanObj2.points = textPoints;
        spanObj2.id = selectid;

        if (th.props.pageType == "oneAudio") {
            book.pages[page_count]['smilAudios'].duration = wavesurfer.getDuration();
            book.pages[page_count]['smilAudios'].audioLink = "/audio/" + audioName;

            if (book.pages[page_count]['smilAudios'].spans == undefined) {
                book.pages[page_count]['smilAudios'].spans = [];
            }
            book.pages[page_count]['smilAudios']['spans'][currentindex] = {};
            book.pages[page_count]['smilAudios']['spans'][currentindex].points = textPoints;
            book.pages[page_count]['smilAudios']['spans'][currentindex].id = selectid;
        } else {
            /*book.pages[page_count]['smilAudios'].spans[currentindex].duration = wavesurfer.getDuration();
            book.pages[page_count]['smilAudios'].spans[currentindex].audioLink =  "/audio/" + audioName;
            book.pages[page_count]['smilAudios']['duration'] = wavesurfer.getDuration();
            book.pages[page_count]['smilAudios']['audioLink'] = "/audio/" + audioName;*/
            let founded = false;
            let foundIndx = 0;
            book.pages[page_count]['smilAudios'].forEach(function (item, ind) {
                if (item.audioLink == spanObj.audioLink) {
                    founded = true;
                    foundIndx = ind;
                }
            });

            if (founded == true) {
                book.pages[page_count]['smilAudios'][foundIndx]['spans'].push(spanObj2);
            }
            else {
                book.pages[page_count]['smilAudios'].push(spanObj);
            }

        }





        /* if(selectedPara == 1) {
             
         }
         else {
             
             book.pages[page_count]['smilAudios'][]
         }*/
        th.props.dispatch({ type: "update_book2", book: book });
        wavesurfer.clearRegions();
        let r = Math.random() * (120 - 0) + 0;
        let g = Math.random() * (120 - 0) + 0;
        let b = Math.random() * (120 - 0) + 0;

        wavesurfer.addRegion({
            id: "c1",
            start: startPoint1, // time in seconds
            end: endPoint, // time in seconds
            color: 'rgba(' + r + ',' + g + ',' + b + ',0.4)',
            // Note: we are preventing the first region (green) from being moved or resized.
            drag: false,
            resize: true
        });
        this.pauseAll();
        this.props.dispatch({ type: "update_point_index", pointIndex: true });
        wavesurfer.setCurrentTime(endPoint);


        /*if(pages[page_count] == undefined) {
            pages[page_count] = [];
        }*/



        /*let page_audios = document.createElement("div");
        page_audios.id = "page-audios";
        let audios_list =[];
        let index = 0;

        book.pages[page_count].smilAudios.forEach(function(item) {
            let page_audio_list = document.createElement("audio");
            page_audio_list.src= item.audioLink;
            page_audio_list.setAttribute('data-for',item.id);
    
            page_audios.appendChild(page_audio_list);
            audios_list[index] = {};
            audios_list[index].audioLink = item.audioLink;
            audios_list[index].id = item.id;
            index++;
        });

        let opf_dir = localStorage.getItem("opf_dir");
        let page_ids = this.props.page_ids;
        
        
         axios.get(page_ids[page_count]).then(function(res) {
            let doc = new DOMParser().parseFromString(res.data, "text/xml");
            
            doc.querySelector("body").appendChild(page_audios);
            console.log(doc);
        });
       var bodyFormData = new FormData();
        

        bodyFormData.set('book', JSON.stringify(book));
        bodyFormData.set('base_path', opf_dir);
        bodyFormData.set('page_path', page_ids[page_count]);
        bodyFormData.set('page_audios',page_audios.innerHTML);
        axios({ 
            method: 'post',
            url: 'http://35.159.51.71:4000/upload_content',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(function (res) {
            })
            .catch(function (response) {
            });*/

    }
    savePage = (type) => {
        //this.saveText2();
        let book = this.props.book;
        let audios = this.props.audios;
        let spansList = this.props.spansList;
        let page_count = this.props.page_count;

        let selectedSpans = spansList[page_count];

        selectedSpans.forEach(element => {
            if (element.value.length > 2) {
                document.getElementById(element.id).classList.remove("red-hilight");
            }
        });

        if (this.props.pageType == "oneAudio") {

        }

        //let opf_dir = localStorage.getItem("opf_dir");

        var url_string = window.location.href;
        var url = new URL(url_string);
        let opf_link = url.searchParams.get("opf_file");

        let opf_dir = opf_link.replace("content.opf", "");


        //console.log(window.location.pathname);
        let htmlDir = window.location.pathname;
        let currDir = htmlDir.replace(opf_dir, "").split("/");
        let audio_dirs = "";
        if (currDir.length > 1) {
            audio_dirs = "";
            currDir.forEach(function (idx) {
                if (idx == 0) {
                    return;
                }
                audio_dirs += "../";
            });
        }

        let page_ids = this.props.page_ids;


        let page_audios = document.createElement("div");
        // let docSelect = this.props.pages2[page_count];

        if (document.querySelectorAll("#page-audios").length != 0) {
            document.getElementById("page-audios").remove();
        }
        page_audios.id = "page-audios";
        let coords = this.props.pagesWidth;
        let width = coords[page_count].width;
        let height = coords[page_count].height;

        let htmlSavePAge = "<body>" + this.props.pages2[page_count] + "</body>";
        if (width != "" && height != "") {
            htmlSavePAge = "<body style='width:" + width + ";height:" + height + ";'>" + this.props.pages2[page_count] + "</body>";
        }

        let audios_list = [];
        let index = 0;


        audios.forEach(function (audioItem) {
            let page_audio_list = document.createElement("audio");
            //let ca = book.pages[page_count]['smilAudios']['audioLink'].replace("/audio","audio");
            page_audio_list.src = audio_dirs + audioItem;
            page_audios.appendChild(page_audio_list);
        });

        //    page_audio_list.setAttribute('data-for',item.id);

        let th = this;

        th.setState({
            open4: true
        });

        //  axios.get(page_ids[page_count]).then(function(res) {

        //    let doc = new DOMParser().parseFromString(res.data, "text/xml");



        let doc = new DOMParser().parseFromString(htmlSavePAge, "text/html");
        if (doc.querySelectorAll("#page-audios").length != 0) {
            doc.getElementById("page-audios").remove();
        }
        doc.querySelector("body").appendChild(page_audios);
        if (audios.length == 0 && type == "clear") {
            let ele = doc.querySelector("#page-audios");
            ele.parentNode.removeChild(ele);
        }


        let smils = book.pages[page_count]['smilAudios'];
        //let smil_type = typeof book.pages[page_count]['smilAudios'];
        if (smils == undefined) {
            setTimeout(() => {
                th.setState({
                    open4: false
                });
            }, 5000);


            return;
        }

        if (this.props.pageType == "oneAudio") {
            if (smils.spans == undefined) {
                setTimeout(() => {
                    th.setState({
                        open4: false
                    });
                }, 5000);

                return;
            }
            smils.spans.forEach(function (item2) {
                doc.querySelector("#" + item2.id).classList.add('audio-syncing');
            });
        }
        else if (this.props.pageType == "paragraphs") {
            if (smils[0] == undefined) {
                setTimeout(() => {
                    th.setState({
                        open4: false
                    });
                }, 5000);

                return;
            }

            smils.forEach(function (item) {
                item.spans.forEach(function (item2) {
                    doc.querySelector("#" + item2.id).classList.add('audio-syncing');
                });
            });
        }



        if (document.getElementById("script-codes") == null) {


            let scriptdiv = document.createElement("div");
            scriptdiv.id = "script-codes";
            let script = document.createElement("script");
            script.src = "../custom_frontend.js";

            scriptdiv.appendChild(script);
            doc.querySelector("body").appendChild(scriptdiv);

        }

        // console.log(doc);return;

        let htmlpage = doc.getElementsByTagName("body")[0].innerHTML;

        htmlpage = htmlpage.replace(/(<img("[^"]*"|[^\/">])*)>/gi, "$1/>");




        //let htmlpage = "<body>" + th.props.pages2[page_count] + "</body>";

        let pageHeaders = th.props.pageHeaders[page_count];
        let htmlfinal = '<?xml version="1.0" encoding="utf-8"?><!DOCTYPE html><html xmlns="http://www.w3.org/1999/xhtml" xmlns:epub="http://www.idpf.org/2007/ops">' + pageHeaders + "<body>" + htmlpage + "</body></html>";

        if (width != "" && height != "") {
            htmlfinal = '<?xml version="1.0" encoding="utf-8"?><!DOCTYPE html><html xmlns="http://www.w3.org/1999/xhtml" xmlns:epub="http://www.idpf.org/2007/ops">' + pageHeaders + "<body style='width:" + width + ";height:" + height + ";'>" + htmlpage + "</body></html>";

        }
        // console.log(htmlfinal);
        // return;

        var url_string = window.location.href;
        var url = new URL(url_string);

        let bookId = url.searchParams.get("id");

        // console.log('<?xml version="1.0" encoding="utf-8"?><!DOCTYPE html><html xmlns="http://www.w3.org/1999/xhtml" xmlns:epub="http://www.idpf.org/2007/ops">' + th.props.pages2[page_count]+ '</html>');
        var bodyFormData = new FormData();
        bodyFormData.set("id", bookId);

        bodyFormData.set('book', JSON.stringify(book));
        bodyFormData.set('base_path', opf_dir);
        bodyFormData.set('page_path', page_ids[page_count]);
        bodyFormData.set("htmlfinal", htmlfinal);
        bodyFormData.set("clear", "1");
        axios({
            method: 'post',
            url: baseLink + '/upload_content',
            data: bodyFormData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        }).then(function (res) {
            document.getElementById("story-page-inner").appendChild(page_audios);
            //console.log(document.querySelectorAll("page-audios audio"));

            setTimeout(() => {
                th.setState({
                    open4: false
                });
            }, 5000);
            document.getElementById("root").style.userSelect = "";
            document.querySelectorAll(".yellow-hilight").forEach(function (item) {
                item.classList.remove("yellow-hilight");
            });
            document.querySelectorAll(".red-hilight").forEach(function (item) {
                item.classList.remove("red-hilight");
            });
        })
            .catch(function (response) {
                //console.log(response);
            });


        // });
    }
    exportZip = (e) => {
        let th = this;
        th.setState({
            open4: true
        });
        var url_string = window.location.href;
        var url = new URL(url_string);

        let opf_link = url.searchParams.get("opf_file");
        let id = url.searchParams.get("id");

        opf_link = opf_link.replace("content.opf", "");

        var bodyFormData = new FormData();
        bodyFormData.set("epub_dir", opf_link);
        bodyFormData.set("id", id);

        axios({
            method: 'post',
            url: baseLink + '/compress_epub',
            data: bodyFormData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        }).then(function (res) {
            th.setState({
                open4: false
            });

        });
    }
    prevPage = (e) => {
        this.props.dispatch({ type: "update_disbaled", saveDisabled: "disabled" })

        let page_count = this.props.page_count;
        let next_page = page_count - 1;
        let pages = this.props.pages2;
        let pageHeaders = this.props.pageHeaders;
        let spansList = this.props.spansList;
        let book = this.props.book;



        this.setState({
            open: true
        });

        this.props.dispatch({ type: "update_para", paraSmilId: "", paraSpanId: "" });

        if (next_page < 0) {
            return;
        }

        document.getElementsByClassName("select-page")[0].value = next_page;

        let htmlpage = pages[next_page];
        let htmlPageDom = new DOMParser().parseFromString(htmlpage, "text/html");


        let audios = this.props.audios;
        audios = [];

        var url_string = window.location.href;
        var url = new URL(url_string);
        let bookpageId = url.searchParams.get("id");


        htmlPageDom.querySelectorAll("#page-audios audio").forEach(function (item) {
            let src = item.getAttribute("src");
            // src = src.replace("../","",src);
            src = src.replace("../", "https://royakids.tv/frontend/static/" + bookpageId + "/OEBPS/");
            audios.push(src);
        });
        this.props.dispatch({ type: "update_audios", audios: audios });


        let page_ids = this.props.page_ids;

        let th = this;


        th.props.dispatch({ type: "update_point_index", pointIndex: false });




        th.props.dispatch({ type: "load_page", htmlpage: "", page_count: next_page, selectid: "", page_ids: page_ids });
        setTimeout(() => {





            let header2 = new DOMParser().parseFromString(pageHeaders[next_page], "text/xml");
            let cssLinks = [];
            header2.querySelectorAll("link").forEach(function (cssfile) {
                cssLinks.push(<link href={cssfile.href + "?id=" + Math.floor(Math.random() * 9000)} rel='stylesheet' />);
            });
            header2.querySelectorAll("style").forEach(function (cssfile) {
                cssLinks.push(<style>{cssfile.innerHTML}</style>);
            });
            th.setState({
                cssLinks: cssLinks
            });
        }, 50);

        let type = th.props.book.pages[next_page].pageAudioType;
        let smilAudios = th.props.book.pages[next_page].smilAudios;
        th.props.dispatch({ type: "update_selected_sp", selected_sp: [] });

        var url_string = window.location.href;
        var url = new URL(url_string);

        htmlPageDom.querySelectorAll("img").forEach(function (item) {
            htmlpage = htmlpage.replace('../Images/', 'https://royakids.tv/frontend/static/' + bookpageId + '/OEBPS/Images/');
        });

        htmlPageDom.querySelectorAll("audio").forEach(function (item) {
            htmlpage = htmlpage.replace('../audio/', 'https://royakids.tv/frontend/static/' + bookpageId + '/OEBPS/audio/');
        });

        th.props.dispatch({ type: "load_page", htmlpage: htmlpage, page_count: next_page, selectid: "", page_ids: page_ids });

        setTimeout(() => {
            /*
            let selectedSpans = spansList[next_page];

            selectedSpans.forEach((element,indx) => {
                  //  document.getElementById(element.id).innerHTML += `<span><input class='checkbox-count' value='${element.id}' type='checkbox' /></span>`;
                 // document.getElementById(element.id).innerHTML += `<span class='checkbox-count'>&cross;</span>`;
                  //document.getElementById(element.id).innerHTML += `<span class='checkbox-count' value='${element.id}'>&cross;</span>`;
                  document.getElementById(element.id).innerHTML += `<span class='checkbox-count' value='${element.id}'>&cross;</span>`;

                }); 

                book.pages[next_page].smilAudios.forEach(function(item,itemIndx) {
                    item.spans.forEach(function(span,spanIndx) {
                        // let mainId = span.id;                
                         if(document.getElementById(span.id) != null || document.getElementById(span.id) != undefined) {
                             var aTag = document.createElement("a");
                             aTag.id = `img-${span.id}---${itemIndx}---${spanIndx}`;
                             aTag.innerHTML = "edit";
                             aTag.classList.add('edit-text');
                             aTag.href='#';
                          //   aTag.addEventListener("click", th.editLine2);
                             
                             document.getElementById(span.id).appendChild(aTag);
     
                             document.querySelector(`#${span.id} .checkbox-count`).remove();
                                 
                           // document.getElementById(span.id).innerHTML += `<a class='edit-text' href='#' id='img-${span.id}---${itemIndx}---${spanIndx}'>edit</a>`;
                         }
     
                     });
                });*/
            th.props.dispatch({ type: "updateText", updateText: true })

            th.setState({
                open: false
            });
        }, 3000);

    }

    nextPage = (e) => {
        this.props.dispatch({ type: "update_disbaled", saveDisabled: "disabled" })

        let id = this.props.selectid;

        if (id != "") {
            document.getElementById(id).style.backgroundColor = "";
        }

        this.setState({
            open: true
        });


        let page_count = this.props.page_count;
        let next_page = page_count + 1;
        let pages = this.props.pages2;
        let pageHeaders = this.props.pageHeaders;
        let spansList = this.props.spansList;
        let book = this.props.book;
        if (next_page == pages.length) {
            return;
        }
        let htmlpage = pages[next_page];


        let htmlPageDom = new DOMParser().parseFromString(htmlpage, "text/html");

        let audios = this.props.audios;
        audios = [];
        //console.log(htmlPageDom.querySelectorAll("#page-audios audio"));

        this.props.dispatch({ type: "update_para", paraSmilId: "", paraSpanId: "" });

        var url_string = window.location.href;
        var url = new URL(url_string);
        let bookpageId = url.searchParams.get("id");

        htmlPageDom.querySelectorAll("#page-audios audio").forEach(function (item) {
            let src = item.getAttribute("src");
            //            src = src.replace("../","",src);



            src = src.replace("../", "https://royakids.tv/frontend/static/" + bookpageId + "/OEBPS/");

            audios.push(src);
        });
        this.props.dispatch({ type: "update_audios", audios: audios });
        let page_ids = this.props.page_ids;
        let th = this;
        th.props.dispatch({ type: "update_point_index", pointIndex: false });
        document.getElementsByClassName("select-page")[0].value = next_page;

        // th.props.dispatch({type : "load_page",htmlpage:"",page_count:next_page,selectid:"",page_ids:page_ids});


        //th.props.dispatch({type : "load_page",htmlpage:htmlpage,page_count:next_page,selectid:"",page_ids:page_ids});

        htmlPageDom.querySelectorAll("img").forEach(function (item) {
            htmlpage = htmlpage.replace('../Images/', 'https://royakids.tv/frontend/static/' + bookpageId + '/OEBPS/Images/');
        });

        htmlPageDom.querySelectorAll("audio").forEach(function (item) {
            htmlpage = htmlpage.replace('../audio/', 'https://royakids.tv/frontend/static/' + bookpageId + '/OEBPS/audio/');
        });



        th.props.dispatch({ type: "load_page2", htmlpage: htmlpage, page_count: next_page, selectid: "" });

        let header2 = new DOMParser().parseFromString(pageHeaders[next_page], "text/xml");
        let cssLinks = [];
        header2.querySelectorAll("link").forEach(function (cssfile) {
            cssLinks.push(<link href={cssfile.href + "?id=" + Math.floor(Math.random() * 9000)} rel='stylesheet' />);
        });
        header2.querySelectorAll("style").forEach(function (cssfile) {
            cssLinks.push(<style>{cssfile.innerHTML}</style>);
        });
        th.setState({
            cssLinks: cssLinks
        });
        th.props.dispatch({ type: "update_selected_sp", selected_sp: [] });
        setTimeout(() => {

            /*     let selectedSpans = spansList[next_page];
     
                 selectedSpans.forEach((element,indx) => {
                        // document.getElementById(element.id).innerHTML += `<span><input class='checkbox-count' value='${element.id}' type='checkbox' /></span>`;
                        
                         document.getElementById(element.id).innerHTML += `<span class='checkbox-count' value='${element.id}'>&cross;</span>`;
 
                        
 
                 }); 
 
 
                 book.pages[next_page].smilAudios.forEach(function(item,itemIndx) {
                     item.spans.forEach(function(span,spanIndx) {
                         // let mainId = span.id;                
                          if(document.getElementById(span.id) != null || document.getElementById(span.id) != undefined) {
                              var aTag = document.createElement("a");
                              aTag.id = `img-${span.id}---${itemIndx}---${spanIndx}`;
                              aTag.innerHTML = "edit";
                              aTag.classList.add('edit-text');
                              aTag.href='#';
                           //   aTag.addEventListener("click", th.editLine2);
                              if(document.querySelectorAll(`#${span.id} .edit-text`).length == 0) {
                                 document.getElementById(span.id).appendChild(aTag);
                             }
      
                              document.querySelector(`#${span.id} .checkbox-count`).remove();
                                  
                            // document.getElementById(span.id).innerHTML += `<a class='edit-text' href='#' id='img-${span.id}---${itemIndx}---${spanIndx}'>edit</a>`;
                          }
      
                      });
                 });*/
            th.props.dispatch({ type: "updateText", updateText: true })
            th.setState({
                open: false
            });
        }, 3000);





        /* if(type == "paragraphs") {
             th.paragraphSlicing(next_page);
         }*/
    }

    playAudio = (e) => {
        /*document.querySelectorAll(".checkbox-count").forEach(function(element) {
            element.remove();
        });*/
        let page_count = this.props.page_count;
        if (this.props.book.pages[page_count].pageAudioType == "oneAudio") {
            this.audio3(0);
        } else {
            this.audio3(0);
        }
    }

    clearSlicing = () => {
        let page_count = this.props.page_count;
        let book = this.props.book;
        let spansList = this.props.spansList;

        book.pages[page_count].smilAudios = [];
        book.pages[page_count].pageAudioType = "";
        this.props.dispatch({ type: "update_data", book: book });
        this.props.dispatch({ type: "update_audios", audios: [] });
        this.props.dispatch({ type: "update_slicing", clearSlicing: 1 });
        this.props.dispatch({ type: "update_slicing1", clearSlicing1: 1 });
        this.props.dispatch({ type: "update_page_type", pageType: "" });


        document.querySelectorAll(".edit-text").forEach(function (element) {
            element.remove();
        });

        document.querySelectorAll(".checkbox-count").forEach(function (element) {
            /*element.innerHTML = "&cross;";
            element.style.backgroundColor = "red";*/

            element.remove();

            //    element.checked = false;
        });




        let selectedSpans = spansList[page_count];

        selectedSpans.forEach((element, indx) => {
            document.getElementById(element.id).innerHTML += `<span class='checkbox-count' value='${element.id}'>&cross;</span>`;
        });


        document.querySelectorAll(".count-txt").forEach(function (elem) {
            elem.remove();
        });
        this.props.dispatch({ type: "update_selected_sp", selected_sp: [] });
    }

    audio3 = (count) => {
        let th = this;
        th.audioCount = count;
        let count2 = th.audioCount;
        let mode = this.props.pageType;
        //let audios = [];
        let page_count = this.props.page_count;

        let smilAudios = this.props.book.pages[page_count].smilAudios;
        //console.log(this.props);

        let currentAudio = 0;
        let audios = document.querySelectorAll("#page-audios audio");
        if (smilAudios[0] == undefined) {
            return;
        }
        if (smilAudios[0].spans.length == 0) {
            return;
        }

        if (count == 0) {
            let buttons = document.querySelectorAll(".main-bt");
            buttons.forEach(function (bt) {
                // bt.disabled = true;
            });
        }
        //   if(mode == 'oneAudio') {
        audios[count2].play();
        var currentSpan = 0;
        var currentWord = 0;
        if (smilAudios[count2] == undefined) {
            let buttons = document.querySelectorAll(".main-bt");
            buttons.forEach(function (bt) {
                bt.disabled = false;
            });

            return;
        }
        var wordId = smilAudios[count].spans[0].points[0].textId;



        // console.log(wordId);
        var spanElement = document.getElementById(smilAudios[count2].spans[0].id);
        var spanText = spanElement.innerText;
        var spanHtml = spanElement.innerHTML;
        var originalHtml = spanHtml;
        spanElement.innerHTML = spanHtml;
        var splitText = spanText.split(' ');
        var text = splitText[0];
        var index = spanHtml.indexOf(text);
        if (index >= 0) {
            // spanHtml = spanHtml.substring(0,index) + "<span class='audio-highlight'>" + spanHtml.substring(index,index+text.length) + "</span>" + spanHtml.substring(index + text.length);
            // spanElement.innerHTML = spanHtml;
            document.getElementById(wordId).classList.add('audio-highlight')
        }
        if (smilAudios[count].spans[currentSpan].points[currentWord + 1] != undefined) {
            currentWord++;
        }
        audios[count2].addEventListener("timeupdate", function (e) {
            //console.log(audios[0].currentTime, parseFloat(smilAudios.spans[currentSpan].points[currentWord].start));
            // if(smilAudios.spans[currentSpan].points[currentWord] != undefined) {
            //    console.log("currentWord: " + currentWord);
            //    console.log("count2: " + count2);
            //    console.log("currentSpan: " + currentSpan);
            //    console.log(smilAudios[count2]);
            // console.log(currentSpan,currentSpan)
            // console.log(smilAudios[count2].spans[currentSpan].points[currentSpan]);
            console.log(audios[count2].currentTime, smilAudios[count2].spans[currentSpan].points[currentWord].start);
            if (audios[count2].currentTime >= parseFloat(smilAudios[count2].spans[currentSpan].points[currentWord].start)) {
                //console.log(smilAudios.spans[currentSpan].id);
                // console.log('wordid',wordId);

                // console.log('count',count2);    
                document.getElementById(wordId).classList.remove('audio-highlight')
                spanElement = document.getElementById(smilAudios[count2].spans[currentSpan].id);
                spanText = spanElement.innerText;
                spanHtml = spanElement.innerHTML;
                originalHtml = spanHtml;
                var splitText = spanText.split(' ');
                var text = splitText[currentWord];
                var index = spanHtml.indexOf(text);
                console.log(index);

                // if (index >= 0) { 
                //console.log(spanHtml);
                /* if(smilAudios[count2] == undefined) {
                     return;
                 }*/
                wordId = smilAudios[count2].spans[currentSpan].points[currentWord].textId;
                // spanHtml = spanHtml.substring(0,index) + "<span class='audio-highlight'>" + spanHtml.substring(index,index+text.length) + "</span>" + spanHtml.substring(index + text.length);
                // spanElement.innerHTML = spanHtml;
                if (document.getElementById(wordId) == null) {
                    return;
                }
                document.getElementById(wordId).classList.add('audio-highlight')
                //  }
                if (smilAudios[count2].spans[currentSpan].points[currentWord + 1] != undefined) {
                    currentWord++;
                } else {
                    if (smilAudios[count2].spans[currentSpan + 1] != undefined) {
                        currentSpan++;
                        currentWord = 0;
                    }
                }
            }
            //  }
        })
        audios[count2].addEventListener("ended", function (e) {
            document.getElementById(wordId).classList.remove('audio-highlight')
            // th.audioCount++;
            if (audios[th.audioCount + 1] != undefined) {
                th.audioCount++;
                th.audio3(th.audioCount);
                currentWord = 0;
            }
            else {
                let buttons = document.querySelectorAll(".main-bt");
                buttons.forEach(function (bt) {
                    bt.disabled = false;
                });
            }

            // var message = {
            //     message:'nextPagePlay',
            // };
            // window.postMessage(JSON.stringify(message));
            // playAudio = false;
        })
        //    }
        this.props.dispatch({ type: "update_disbaled", saveDisabled: "disabled" })

    }
    audioCount = 0;

    audio = () => {
        let mode = this.props.pageType;
        //let audios = [];
        let page_count = this.props.page_count;

        let smilAudios = this.props.book.pages[page_count].smilAudios;
        //console.log(this.props);

        let currentAudio = 0;
        let audios32 = document.querySelectorAll("#page-audios audio");

        if (smilAudios.spans == undefined) {
            return;
        }

        let buttons = document.querySelectorAll(".main-bt");
        buttons.forEach(function (bt) {
            bt.disabled = true;
        });

        //   if(mode == 'oneAudio') {
        audios32[0].play();

        var currentSpan = 0;
        var currentWord = 0;
        var wordId = smilAudios.spans[0].points[0].textId;
        // var spanElement = document.getElementById(smilAudios.spans[0].id);
        // var spanText = spanElement.innerText;
        // var spanHtml = spanElement.innerHTML;
        // var originalHtml = spanHtml;
        // spanElement.innerHTML = spanHtml;
        // var splitText = spanText.split(' ');
        // var text = splitText[0];
        // var index = spanHtml.indexOf(text);     
        // if (index >= 0) {
        // spanHtml = spanHtml.substring(0,index) + "<span class='audio-highlight'>" + spanHtml.substring(index,index+text.length) + "</span>" + spanHtml.substring(index + text.length);
        // spanElement.innerHTML = spanHtml;
        document.getElementById(wordId).classList.add('audio-highlight')
        // }
        if (smilAudios.spans[currentSpan].points[currentWord + 1] != undefined) {
            currentWord++;
        }
        audios32[0].addEventListener("timeupdate", function (e) {
            //console.log(audios[0].currentTime, parseFloat(smilAudios.spans[currentSpan].points[currentWord].start));
            // if(smilAudios.spans[currentSpan].points[currentWord] != undefined) {
            //  console.log('currentWord: ',currentWord,'currentSpan: ',currentSpan,'wordId: ',wordId)
            //  console.log(smilAudios.spans);

            if (audios32[0].currentTime >= parseFloat(smilAudios.spans[currentSpan].points[currentWord].start)) {
                //console.log(smilAudios.spans[currentSpan].id);
                // spanElement.innerHTML = originalHtml;

                //console.log('currentWord: ',currentWord,'currentSpan: ',currentSpan,'wordId: ',wordId)
                // spanElement = document.getElementById(smilAudios.spans[currentSpan].id);
                // spanText = spanElement.innerText;
                // spanHtml = spanElement.innerHTML;
                // originalHtml = spanHtml;
                // var splitText = spanText.split(' ');
                // var text = splitText[currentWord];
                // var index = spanHtml.indexOf(text);     
                // if (index >= 0) { 
                //console.log(spanHtml);
                // spanHtml = spanHtml.substring(0,index) + "<span class='audio-highlight'>" + spanHtml.substring(index,index+text.length) + "</span>" + spanHtml.substring(index + text.length);
                // spanElement.innerHTML = spanHtml;

                //  document.getElementById(wordId).classList.remove('audio-highlight')
                //  console.log(currentSpan,currentWord);
                if (document.getElementById(wordId) != null) {
                    document.getElementById(wordId).classList.remove('audio-highlight')
                    wordId = smilAudios.spans[currentSpan].points[currentWord].textId;
                }

                if (document.getElementById(wordId) != null) {


                    document.getElementById(wordId).classList.add('audio-highlight')
                    if (smilAudios.spans[currentSpan].points[currentWord + 1] != undefined) {
                        currentWord++;
                    } else {
                        if (smilAudios.spans[currentSpan + 1] != undefined) {
                            currentSpan++;
                            currentWord = 0;
                        }
                    }
                }
                // }

            }
            //  }
        })
        audios32[0].addEventListener("ended", function (e) {

            if (document.getElementById(wordId) != null) {
                document.getElementById(wordId).classList.remove('audio-highlight')
            }
            currentWord = 0;
            // var message = {
            //     message:'nextPagePlay',
            // };
            // window.postMessage(JSON.stringify(message));
            // playAudio = false;

            let buttons = document.querySelectorAll(".main-bt");
            buttons.forEach(function (bt) {
                bt.disabled = false;
            });
        })
        //  }
    }

    savePage2 = (type, e) => {
        e.preventDefault();
        //this.saveText2();
        let book = this.props.book;
        let audios = this.props.audios;




        //let opf_dir = localStorage.getItem("opf_dir");

        var url_string = window.location.href;
        var url = new URL(url_string);
        let opf_link = url.searchParams.get("opf_file");
        let opf_dir = opf_link.replace("content.opf", "");


        //console.log(window.location.pathname);
        let htmlDir = window.location.pathname;
        let currDir = htmlDir.replace(opf_dir, "").split("/");
        let audio_dirs = "";
        if (currDir.length > 1) {
            audio_dirs = "";
            currDir.forEach(function (idx) {
                if (idx == 0) {
                    return;
                }
                audio_dirs += "../";
            });
        }

        let page_ids = this.props.page_ids;
        let page_count = this.props.page_count;


        let page_audios = document.createElement("div");
        let page_audios_preview = document.createElement("div");
        // let docSelect = this.props.pages2[page_count];

        document.querySelectorAll(".count-txt").forEach(function (elem) {
            elem.remove();
        });

        if (document.querySelectorAll("#page-audios").length != 0) {
            document.getElementById("page-audios").remove();
        }
        page_audios.id = "page-audios";
        page_audios_preview.id = "page-audios";
        let coords = this.props.pagesWidth;
        let width = coords[page_count].width;
        let height = coords[page_count].height;

        let htmlSavePAge = "<body>" + this.props.pages2[page_count] + "</body>";
        if (width != "" && height != "") {
            htmlSavePAge = "<body style='width:" + width + ";height:" + height + ";'>" + this.props.pages2[page_count] + "</body>";
        }

        let audios_list = [];
        let index = 0;


        /*   audios.forEach(function(audioItem) {
               let page_audio_list = document.createElement("audio");
               //let ca = book.pages[page_count]['smilAudios']['audioLink'].replace("/audio","audio");
               page_audio_list.src= audio_dirs + audioItem;
               page_audios.appendChild(page_audio_list);
               console.log(audio_dirs + audioItem)
           });*/
        var url_string = window.location.href;
        var url = new URL(url_string);
        let bookpageId = url.searchParams.get("id");

        book.pages[page_count].smilAudios.forEach(function (bookItem) {

            let page_audio_list = document.createElement("audio");
            let page_audio_list_preview = document.createElement("audio");

            //let ca = book.pages[page_count]['smilAudios']['audioLink'].replace("/audio","audio");
            //  console.log(bookItem.audioLink);
            page_audio_list.src = "https://royakids.tv/frontend/static/" + bookpageId + "/OEBPS" + bookItem.audioLink;
            page_audio_list_preview.src = ".." + bookItem.audioLink;
            page_audios.appendChild(page_audio_list);
            page_audios_preview.appendChild(page_audio_list_preview);


        });


        //    page_audio_list.setAttribute('data-for',item.id);

        let th = this;

        th.setState({
            open: true
        });

        //  axios.get(page_ids[page_count]).then(function(res) {

        //    let doc = new DOMParser().parseFromString(res.data, "text/xml");



        let doc = new DOMParser().parseFromString(htmlSavePAge, "text/html");
        if (doc.querySelectorAll("#page-audios").length != 0) {
            doc.getElementById("page-audios").remove();
        }
        doc.querySelector("body").appendChild(page_audios_preview);


        let smils = book.pages[page_count]['smilAudios'];
        //let smil_type = typeof book.pages[page_count]['smilAudios'];
        if (smils == undefined) {
            setTimeout(() => {

                if (type == "preview") {
                    th.playAudio();
                    th.setState({
                        open: false
                    });
                }
                else if (type == "next_page") {
                    th.nextPage();
                }
                else if (type == "pre_page") {
                    th.prevPage();
                }
            }, 5000);


            return;
        }



        if (smils[0] == undefined) {
            setTimeout(() => {
                th.setState({
                    open: false
                });
            }, 5000);

            if (type == "preview") {
                th.playAudio();
            }
            else if (type == "next_page") {
                th.nextPage();
            }
            else if (type == "pre_page") {
                th.prevPage();
            }

            return;
        }

        smils.forEach(function (item) {
            item.spans.forEach(function (item2) {
                doc.querySelector("#" + item2.id).classList.add('audio-syncing');
            });
        });




        if (document.getElementById("script-codes") == null) {


            let scriptdiv = document.createElement("div");
            scriptdiv.id = "script-codes";
            let script = document.createElement("script");
            script.src = "../custom_frontend.js";

            scriptdiv.appendChild(script);
            doc.querySelector("body").appendChild(scriptdiv);

        }

        // console.log(doc);return;

        let htmlpage = doc.getElementsByTagName("body")[0].innerHTML;

        htmlpage = htmlpage.replace(/(<img("[^"]*"|[^\/">])*)>/gi, "$1/>");


        //let htmlpage = "<body>" + th.props.pages2[page_count] + "</body>";

        let pageHeaders = th.props.pageHeaders[page_count];
        let htmlfinal = '<?xml version="1.0" encoding="utf-8"?><!DOCTYPE html><html xmlns="http://www.w3.org/1999/xhtml" xmlns:epub="http://www.idpf.org/2007/ops">' + pageHeaders + "<body>" + htmlpage + "</body></html>";

        if (width != "" && height != "") {
            htmlfinal = '<?xml version="1.0" encoding="utf-8"?><!DOCTYPE html><html xmlns="http://www.w3.org/1999/xhtml" xmlns:epub="http://www.idpf.org/2007/ops">' + pageHeaders + "<body style='width:" + width + ";height:" + height + ";'>" + htmlpage + "</body></html>";
        }
        // console.log(htmlfinal);
        // return;

        // console.log('<?xml version="1.0" encoding="utf-8"?><!DOCTYPE html><html xmlns="http://www.w3.org/1999/xhtml" xmlns:epub="http://www.idpf.org/2007/ops">' + th.props.pages2[page_count]+ '</html>');
        var url_string = window.location.href;
        var url = new URL(url_string);

        let bookId = url.searchParams.get("id");

        // console.log('<?xml version="1.0" encoding="utf-8"?><!DOCTYPE html><html xmlns="http://www.w3.org/1999/xhtml" xmlns:epub="http://www.idpf.org/2007/ops">' + th.props.pages2[page_count]+ '</html>');
        var bodyFormData = new FormData();
        bodyFormData.set("id", bookId);

        bodyFormData.set('book', JSON.stringify(book));
        bodyFormData.set('base_path', opf_dir);
        bodyFormData.set('page_path', page_ids[page_count]);
        bodyFormData.set("htmlfinal", htmlfinal);
        axios({
            method: 'post',
            url: baseLink + '/upload_content',
            data: bodyFormData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        }).then(function (res) {
            document.getElementById("story-page-inner").appendChild(page_audios);
            //console.log(document.querySelectorAll("page-audios audio"));

            setTimeout(() => {
                th.setState({
                    open: false
                });
                if (type == "preview") {
                    th.playAudio();
                }
                else if (type == "next_page") {
                    th.nextPage();
                }
                else if (type == "pre_page") {
                    th.prevPage();
                }
            }, 2000);
            document.getElementById("root").style.userSelect = "";
            document.querySelectorAll(".yellow-hilight").forEach(function (item) {
                item.classList.remove("yellow-hilight");
            });
            document.querySelectorAll(".red-hilight").forEach(function (item) {
                item.classList.remove("red-hilight");
            });



        })
            .catch(function (response) {
                //console.log(response);
            });


        // });
    }

    closePopup = () => {
        this.setState({
            slicePop: false
        })
    }
    _logRegions() {
        //console.log(wavesurfer.regions.list);
        //console.log(this.props.selected_sp)
    }
    render() {


        let currDir = this.props.page_count + 1;
        let pageNo = this.props.page_ids.length;
        return (<div id="waveform-container-v">
            <div id="waveform"></div>
            <div id="wave-timeline"></div>
            <div id="player-control" style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "25px 0px"
            }}>
                <div className="play-bt" onClick={this.playAll}><PlayArrowIcon /></div>
                <div className="pause-bt" onClick={this.pauseAll}><PauseIcon /></div>
                <div className="upload-bt"><PublishIcon /> <input type="file" onChange={this.uploadAudio} id="file-upload" /></div>
                <input data-action="zoom" id="slider" type="range" min="20" max="1000" />

                <select onChange={this.speedHandle}>
                    <option value="1">1</option>
                    <option value="0.9">0.9</option>
                    <option value="0.8">0.8</option>
                    <option value="0.7">0.7</option>
                    <option value="0.6">0.6</option>
                    <option value="0.5">0.5</option>
                    <option value="0.4">0.4</option>
                    <option value="0.3">0.3</option>
                    <option value="0.2">0.2</option>
                    <option value="0.1">0.1</option>
                </select>
            </div>
            <div className="save-bt" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Button className="main-bt" color="primary" variant="contained" disabled={this.props.saveDisabled} onClick={this.saveText2}>Save Text</Button>

                <Button color="primary" variant="contained" disabled={currDir == 1 ? "disabled" : ""} onClick={(e) => this.savePage2("pre_page", e)}>Prev page</Button>
                <Button color="primary" variant="contained" disabled={currDir == pageNo ? "disabled" : ""}
                    onClick={(e) => this.savePage2("next_page", e)}>Next page</Button>
                <Button color="primary" variant="contained" className="main-bt" onClick={(e) => this.savePage2("preview", e)}>Preview</Button>
                <Button color="primary" variant="contained" className="main-bt" onClick={this.clearSlicing}>Clear Slicing</Button>
                {/* <Button color="primary" variant="contained" className="main-bt log-bt" onClick={() => {
                    this._logRegions()
                }}>Log</Button> */}

            </div>


            <Dialog
                open={this.state.open4}>
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            </Dialog>

            <Dialog
                open={this.state.open5}>
                <DialogContent><p>Text save Successfully</p></DialogContent>
            </Dialog>

            <Dialog
                open={this.state.open6}>
                <DialogContent><p>Page save Successfully</p></DialogContent>
            </Dialog>

            <Dialog open={this.state.open}>
                <DialogContent>
                    <DialogContentText>
                        <CircularProgress />

                    </DialogContentText>
                </DialogContent>
            </Dialog>

            <Dialog
                open={this.state.slicePop}>
                <DialogContent><p>{this.state.slicePopMsg}</p>
                    <p align="center"><br /><button onClick={this.closePopup} >Close</button></p>
                </DialogContent>
            </Dialog>

        </div>)
    }
}
const mapStateToProps = state => ({
    audioLink: state.audioLink,
    audioObj: state.audioObj,
    audioName: state.audioName,
    startTime: state.startTime,
    selectid: state.selectid,
    book: state.book,
    page_count: state.page_count,
    page_ids: state.page_ids,
    pageType: state.pageType,
    spansList: state.spansList,
    cssLinks: state.cssLinks,
    pointIndex: state.pointIndex,
    selectedIds: state.selectedIds,
    selectedIndex: state.selectedIndex,
    pages2: state.pages2,
    pageHeaders: state.pageHeaders,
    pagesWidth: state.pagesWidth,
    audios: state.audios,
    selectedPara: state.selectedPara,
    opf_dir: state.opf_dir,
    clearSlicing: state.clearSlicing,
    clearSlicing1: state.clearSlicing1,
    htmlpage: state.htmlpage,
    waverColor: state.waverColor,
    spanIndx: state.spanIndx,
    editLineText: state.editLineText,
    selected_sp: state.selected_sp,
    paraSmilId: state.paraSmilId,
    paraSpanId: state.paraSpanId,
    regions: state.regions,
    slicing: state.slicing,
    saveDisabled: state.saveDisabled
});
export default connect(mapStateToProps)(PageAudio2); 